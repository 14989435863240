import { CommonResponse, KeyWithString } from './shared.interface';
import { Tenant } from './tenants.interface';
import { UserConfigLocalization } from './user-config.interfaces';

export interface SharedUser {
  id: number;
  name: string;
  surname: string;
  userName: string;
  emailAddress: string;
  phoneNumber: string | null;
  isActive: boolean;
}

export interface UserDetails extends SharedUser {
  password: string;
  shouldChangePasswordOnNextLogin: boolean;
  isTwoFactorEnabled: boolean;
  isLockoutEnabled: boolean;
  twoFactorAuthenticationTypeId: number | null;
}

export interface User extends SharedUser {
  roles: SimplifiedRole[];
  creationTime: string;
  lastLoginTime: string;
  isEmailConfirmed: boolean;
  tenantName: string | null;
  isLocked: boolean;
}

export type SimplifiedRole = Pick<UserRole, 'roleId' | 'roleName'>;

export interface UserRole {
  roleId: number;
  roleName: string;
  roleDisplayName: string;
  isAssigned: boolean;
  inheritedFromOrganizationUnit: boolean;
}

export interface UserState {
  data: UserDetails | null;
  roles: UserRole[] | null;
  tenantId: number | null;
  profilePictureId: string | null;
  localization: UserConfigLocalization | null;
  currentTheme: string | null;
  permissions: {
    grantedPermissions: GrantedPermissions | null;
    isManagingUsersAccess: boolean;
  };
}

export interface UserProfilePictureResponse {
  result: {
    mimeType: string;
    profilePicture: string;
  };
}

export enum ThemeConfig {
  dark = '1',
  light = '0',
}

export interface UserConfigResponse {
  // TO DO: add all config values
  result: {
    auth: {
      grantedPermissions: GrantedPermissions;
    };
    localization: UserConfigLocalization;
    setting: {
      values: {
        'App.UserThemeName': ThemeConfig;
      };
    };
    features: Features;
  };
}

export enum Permissions {
  PAGES = 'Pages',
  PAGES_AUDIT_LOGS = 'Pages.AuditLogs',
  PAGES_HOST_DASHBOARD = 'Pages.Host.Dashboard',
  PAGES_HOST_EMAIL_TEMPLATES = 'Pages.Host.EmailTemplates',
  PAGES_HOST_EMAIL_TEMPLATES_CREATE = 'Pages.Host.EmailTemplates.Create',
  PAGES_HOST_EMAIL_TEMPLATES_DELETE = 'Pages.Host.EmailTemplates.Delete',
  PAGES_HOST_EMAIL_TEMPLATES_EDIT = 'Pages.Host.EmailTemplates.Edit',
  PAGES_HOST_EMAIL_TEMPLATES_SEND = 'Pages.Host.EmailTemplates.Send',
  PAGES_HOST_FORGET_TENANT_REQUEST_MANAGE = 'Pages.Host.ForgetTenantRequest.Manage',
  PAGES_HOST_REPORTING = 'Pages.Host.Reporting',
  PAGES_HOST_SUBSCRIPTIONS = 'Pages.Host.Subscriptions',
  PAGES_HOST_SUBSCRIPTIONS_CREATE = 'Pages.Host.Subscriptions.Create',
  PAGES_HOST_SUBSCRIPTIONS_DELETE = 'Pages.Host.Subscriptions.Delete',
  PAGES_HOST_SUBSCRIPTIONS_EDIT = 'Pages.Host.Subscriptions.Edit',
  PAGES_HOST_SUBSCRIPTIONS_MOVE_TENANTS_TO_ANOTHER_SUBSCRIPTION = 'Pages.Host.Subscriptions.MoveTenantsToAnotherSubscription',
  PAGES_HOST_SUPPORT_RESPONSE = 'Pages.Host.Support.Response',
  PAGES_HOST_TENANTS = 'Pages.Host.Tenants',
  PAGES_HOST_TENANTS_DELETE = 'Pages.Host.Tenants.Delete',
  PAGES_HOST_TENANTS_DETAILS = 'Pages.Host.Tenants.Details',
  PAGES_HOST_TENANTS_EDIT = 'Pages.Host.Tenants.Edit',
  PAGES_HOST_TENANTS_ROLES = 'Pages.Host.Tenants.Roles',
  PAGES_HOST_TENANTS_USERS = 'Pages.Host.Tenants.Users',
  PAGES_ROLES = 'Pages.Roles',
  PAGES_ROLES_CREATE = 'Pages.Roles.Create',
  PAGES_ROLES_DELETE = 'Pages.Roles.Delete',
  PAGES_ROLES_EDIT = 'Pages.Roles.Edit',
  PAGES_TENANT_DASHBOARD = 'Pages.Tenant.Dashboard',
  PAGES_TENANT_FORGET_TENANT_REQUEST_CREATE = 'Pages.Tenant.ForgetTenantRequest.Create',
  PAGES_TENANT_FORGET_USER_REQUEST_MANAGE = 'Pages.Tenant.ForgetUserRequest.Manage',
  PAGES_TENANT_INTEGRATIONS = 'Pages.Tenant.Integrations',
  PAGES_TENANT_INTEGRATIONS_CODAT_ACCOUNTING = 'Pages.Tenant.Integrations.CodatAccounting',
  PAGES_TENANT_INTEGRATIONS_CODAT_OPEN_BANKING = 'Pages.Tenant.Integrations.CodatOpenBanking',
  PAGES_TENANT_INTEGRATIONS_DOCUSIGN = 'Pages.Tenant.Integrations.Docusign',
  PAGES_TENANT_INTEGRATIONS_GOCARDLESS = 'Pages.Tenant.Integrations.GoCardless',
  PAGES_TENANT_INTEGRATIONS_NIMBLA = 'Pages.Tenant.Integrations.Nimbla',
  PAGES_TENANT_INTEGRATIONS_PAYPAL = 'Pages.Tenant.Integrations.PayPal',
  PAGES_TENANT_INTEGRATIONS_QUICKBOOKS = 'Pages.Tenant.Integrations.QuickBooks',
  PAGES_TENANT_INTEGRATIONS_SAGE = 'Pages.Tenant.Integrations.Sage',
  PAGES_TENANT_INTEGRATIONS_TRUELAYER = 'Pages.Tenant.Integrations.TrueLayer',
  PAGES_TENANT_INTEGRATIONS_TYPELESS = 'Pages.Tenant.Integrations.Typless',
  PAGES_TENANT_INTEGRATIONS_XERO = 'Pages.Tenant.Integrations.Xero',
  PAGES_TENANT_INVOICES = 'Pages.Tenant.Invoices',
  PAGES_TENANT_INVOICES_CREATE = 'Pages.Tenant.Invoices.Create',
  PAGES_TENANT_INVOICES_DELETE = 'Pages.Tenant.Invoices.Delete',
  PAGES_TENANT_ORGANIZATION_UNITS = 'Pages.Tenant.OrganizationUnits',
  PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_MEMBERS = 'Pages.Tenant.OrganizationUnits.ManageMembers',
  PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_ORGANIZATION_TREE = 'Pages.Tenant.OrganizationUnits.ManageOrganizationTree',
  PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_ROLES = 'Pages.Tenant.OrganizationUnits.ManageRoles',
  PAGES_TENANT_SUBSCRIPTION_MANAGEMENT = 'Pages.Tenant.SubscriptionManagement',
  PAGES_TENANT_SUPPORT_REQUEST = 'Pages.Tenant.Support.Request',
  PAGES_TENANT_TENANT_DETAILS = 'Pages.Tenant.TenantDetails',
  PAGES_TENANT_TENANT_DETAILS_EDIT = 'Pages.Tenant.TenantDetails.Edit',
  PAGES_USERS = 'Pages.Users',
  PAGES_USERS_CHANGE_PERMISSIONS = 'Pages.Users.ChangePermissions',
  PAGES_USERS_CREATE = 'Pages.Users.Create',
  PAGES_USERS_DELETE = 'Pages.Users.Delete',
  PAGES_USERS_EDIT = 'Pages.Users.Edit',
  PAGES_USERS_IMPERSONATION = 'Pages.Users.Impersonation',
  PAGES_USERS_UNLOCK = 'Pages.Users.Unlock',
}

export interface GrantedPermissions extends KeyWithString {
  [Permissions.PAGES]: string;
  [Permissions.PAGES_AUDIT_LOGS]: string;
  [Permissions.PAGES_HOST_DASHBOARD]: string;
  [Permissions.PAGES_HOST_EMAIL_TEMPLATES]: string;
  [Permissions.PAGES_HOST_EMAIL_TEMPLATES_CREATE]: string;
  [Permissions.PAGES_HOST_EMAIL_TEMPLATES_DELETE]: string;
  [Permissions.PAGES_HOST_EMAIL_TEMPLATES_EDIT]: string;
  [Permissions.PAGES_HOST_EMAIL_TEMPLATES_SEND]: string;
  [Permissions.PAGES_HOST_FORGET_TENANT_REQUEST_MANAGE]: string;
  [Permissions.PAGES_HOST_REPORTING]: string;
  [Permissions.PAGES_HOST_SUBSCRIPTIONS]: string;
  [Permissions.PAGES_HOST_SUBSCRIPTIONS_CREATE]: string;
  [Permissions.PAGES_HOST_SUBSCRIPTIONS_DELETE]: string;
  [Permissions.PAGES_HOST_SUBSCRIPTIONS_EDIT]: string;
  [Permissions.PAGES_HOST_SUBSCRIPTIONS_MOVE_TENANTS_TO_ANOTHER_SUBSCRIPTION]: string;
  [Permissions.PAGES_HOST_SUPPORT_RESPONSE]: string;
  [Permissions.PAGES_HOST_TENANTS]: string;
  [Permissions.PAGES_HOST_TENANTS_DELETE]: string;
  [Permissions.PAGES_HOST_TENANTS_DETAILS]: string;
  [Permissions.PAGES_HOST_TENANTS_EDIT]: string;
  [Permissions.PAGES_HOST_TENANTS_ROLES]: string;
  [Permissions.PAGES_HOST_TENANTS_USERS]: string;
  [Permissions.PAGES_ROLES]: string;
  [Permissions.PAGES_ROLES_CREATE]: string;
  [Permissions.PAGES_ROLES_DELETE]: string;
  [Permissions.PAGES_ROLES_EDIT]: string;
  [Permissions.PAGES_TENANT_DASHBOARD]: string;
  [Permissions.PAGES_TENANT_FORGET_TENANT_REQUEST_CREATE]: string;
  [Permissions.PAGES_TENANT_FORGET_USER_REQUEST_MANAGE]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_CODAT_ACCOUNTING]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_CODAT_OPEN_BANKING]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_DOCUSIGN]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_GOCARDLESS]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_NIMBLA]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_PAYPAL]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_QUICKBOOKS]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_SAGE]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_TRUELAYER]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_TYPELESS]: string;
  [Permissions.PAGES_TENANT_INTEGRATIONS_XERO]: string;
  [Permissions.PAGES_TENANT_INVOICES]: string;
  [Permissions.PAGES_TENANT_INVOICES_CREATE]: string;
  [Permissions.PAGES_TENANT_INVOICES_DELETE]: string;
  [Permissions.PAGES_TENANT_ORGANIZATION_UNITS]: string;
  [Permissions.PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_MEMBERS]: string;
  [Permissions.PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_ORGANIZATION_TREE]: string;
  [Permissions.PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_ROLES]: string;
  [Permissions.PAGES_TENANT_SUBSCRIPTION_MANAGEMENT]: string;
  [Permissions.PAGES_TENANT_SUPPORT_REQUEST]: string;
  [Permissions.PAGES_TENANT_TENANT_DETAILS]: string;
  [Permissions.PAGES_TENANT_TENANT_DETAILS_EDIT]: string;
  [Permissions.PAGES_USERS]: string;
  [Permissions.PAGES_USERS_CHANGE_PERMISSIONS]: string;
  [Permissions.PAGES_USERS_CREATE]: string;
  [Permissions.PAGES_USERS_DELETE]: string;
  [Permissions.PAGES_USERS_EDIT]: string;
  [Permissions.PAGES_USERS_IMPERSONATION]: string;
  [Permissions.PAGES_USERS_UNLOCK]: string;
}

export interface Features {
  allFeatures: {
    'App.ManagingUsers': {
      value: string;
    };
    'App.MaxUserCount': {
      value: string;
    };
  };
}
export interface CurrentLoginResponse {
  result: CurrentLoginResult;
}
export interface ImpersonatorUser extends Omit<SharedUser, 'phoneNumber' | 'isActive'> {
  isTermsAndConditionsAgreed: string;
}

export interface CurrentLoginResult {
  user: UserResponse;
  impersonatorUser: ImpersonatorUser | null;
  tenant: Tenant;
}

export interface UserResponse {
  name: string;
  surname: string;
}

export interface CreateUserSettingPayload {
  assignedRoleNames: string[];
  sendActivationEmail: boolean;
  setRandomPassword: boolean;
  user: UserDetails;
}

export interface UpdateProfileThemePayload {
  theme: ThemeConfig;
}

export enum BASE_USER_SETTINGS_FORM_FIELDS {
  name = 'name',
  surname = 'surname',
  email = 'emailAddress',
  phone = 'phoneNumber',
  password = 'password',
  repeatPassword = 'repeatPassword',
  sendActivationEmail = 'sendActivationEmail',
  isActive = 'isActive',
  isTwoFactorEnabled = 'isTwoFactorEnabled',
  isLockoutEnabled = 'isLockoutEnabled',
  roles = 'roles',
  twoFactorAuthenticationTypeId = 'twoFactorAuthenticationTypeId',
  setRandomPassword = 'setRandomPassword',
}

export interface BaseUserSettingsFormValues {
  [BASE_USER_SETTINGS_FORM_FIELDS.name]: string;
  [BASE_USER_SETTINGS_FORM_FIELDS.surname]: string;
  [BASE_USER_SETTINGS_FORM_FIELDS.email]: string;
  [BASE_USER_SETTINGS_FORM_FIELDS.phone]: string | null;
  [BASE_USER_SETTINGS_FORM_FIELDS.password]?: string | null;
  [BASE_USER_SETTINGS_FORM_FIELDS.sendActivationEmail]: boolean;
  [BASE_USER_SETTINGS_FORM_FIELDS.isActive]: boolean;
  [BASE_USER_SETTINGS_FORM_FIELDS.isTwoFactorEnabled]: boolean;
  [BASE_USER_SETTINGS_FORM_FIELDS.isLockoutEnabled]: boolean;
  [BASE_USER_SETTINGS_FORM_FIELDS.roles]: Record<string, string>;
}

export interface CreateUserFormValues extends BaseUserSettingsFormValues {
  [BASE_USER_SETTINGS_FORM_FIELDS.setRandomPassword]: boolean;
}

export interface PersonalInfoToExcel {
  context: string;
  fileName: string;
  fileType: string;
}

export interface PersonalInfoToExcelResponse extends CommonResponse {
  result: PersonalInfoToExcel;
}

export interface ForgetMyUserRequestDetailsResponse extends CommonResponse {
  result: ForgetMyUserRequestDetails;
}

export interface ForgetMyUserRequestDetails extends CommonResponse {
  dateCreated: string;
  isRequested: boolean;
}
