import { sharedTableRowBodyClassName, sharedTableRowLabelClassName } from 'constants/codat-accounting';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { InvoiceData } from 'interfaces/integrations/codat-accounting.interface';
import { LabelWithValue, TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { useFormatPriceFunction } from 'utils/global';

interface Props {
  InvoiceData: InvoiceData;
}

const InvoicesRow = ({ InvoiceData }: Props) => {
  const { issueDate, dueDate, invoiceNumber, amountDue, total, subTotal, totalTax, currency } = InvoiceData;

  return (
    <>
      <TableRow dataCy="invoices-table-row">
        <TableData dataCy="invoices-issue-date">
          {getFormattedDate(issueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="invoices-due-date">
          {getFormattedDate(dueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="invoices-invoice-number">{invoiceNumber}</TableData>
        <TableData dataCy="invoices-amount-due">{useFormatPriceFunction(amountDue, currency)}</TableData>
        <TableData dataCy="invoices-total-amount">
          <div>
            <LabelWithValue
              label="Total"
              bodyClassName={sharedTableRowBodyClassName}
              labelClassName={sharedTableRowLabelClassName}
              value={useFormatPriceFunction(total, currency)}
              dataCy="total"
            />
            <LabelWithValue
              label="SubTotal"
              bodyClassName={sharedTableRowBodyClassName}
              labelClassName={sharedTableRowLabelClassName}
              value={useFormatPriceFunction(subTotal, currency)}
              dataCy="sub-total"
            />
            <LabelWithValue
              label="TaxTotal"
              bodyClassName={sharedTableRowBodyClassName}
              labelClassName={sharedTableRowLabelClassName}
              value={useFormatPriceFunction(totalTax, currency)}
              dataCy="tax-total"
            />
          </div>
        </TableData>
      </TableRow>
    </>
  );
};

export default InvoicesRow;
