import { useCallback } from 'react';

import { useLazyGetUserConfigQuery } from 'apis/user.api';
import i18n from 'configs/i18n';

export const useUserConfig = () => {
  const [fetchUserConfig, { data: userConfigData, isLoading }] = useLazyGetUserConfigQuery();

  const handleGetUserConfig = useCallback(async () => {
    const { result } = await fetchUserConfig().unwrap();

    const { values, currentLanguage } = result?.localization;
    const currentLanguageName = currentLanguage?.name;

    i18n.addResourceBundle(currentLanguageName, 'translation', values?.EasyGo, true, true);
    await i18n.changeLanguage(currentLanguageName);
  }, [fetchUserConfig]);

  return { handleGetUserConfig, isLoading, userConfigData };
};
