import { CommonResponse, DownloadFileResponse } from 'interfaces/shared.interface';
import {
  HistoryResponse,
  SubscriptionForSelectResponse,
  SubscriptionsResponse,
  SubscriptionResponse,
  CreatePaymentPayload,
  CreateStripeSessionPayload,
  ConfigurationResponse,
  PaymentIdResponse,
  CreateSubscriptionResponse,
  ActiveGatewayResponse,
  CreateStripeSessionResponse,
  SharedSubscriptionIdPayload,
  UsedSubscriptionTrialIdsResponse,
  InfoUpgradeSubscriptionResponse,
  LastCompletedResponse,
} from 'interfaces/subscriptions-tenant.interface';

import { coreSplitApis } from './core.api';

export const subscriptionTenantApi = coreSplitApis
  .enhanceEndpoints({ addTagTypes: ['SubscriptionsFeature'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getSubscriptionsFeatures: build.query<SubscriptionsResponse, number>({
        query: (subscriptionId) => ({
          url: `SubscriptionManager/GetFeatures?subscriptionId=${subscriptionId}`,
          method: 'GET',
        }),
        providesTags: ['SubscriptionsFeature'],
      }),
      getMyHistory: build.query<HistoryResponse, string>({
        query: (params) => ({
          url: `SubscriptionPayment/GetMyHistory?${params}`,
          method: 'GET',
        }),
      }),
      getInvoicesPdf: build.query<DownloadFileResponse, number>({
        query: (paymentId) => ({
          url: `SubscriptionPayment/GetInvoicePdf?PaymentId=${paymentId}`,
          method: 'GET',
          responseHandler: (response: Response) => response.blob(),
        }),
      }),
      getSubscriptionForSelect: build.query<SubscriptionForSelectResponse, void>({
        query: () => ({
          url: `TenantRegistration/GetSubscriptionsForSelect`,
          method: 'GET',
        }),
      }),
      getSubscription: build.query<SubscriptionResponse, string>({
        query: (subscriptionId) => ({
          url: `TenantRegistration/GetSubscription?subscriptionId=${subscriptionId}`,
          method: 'GET',
        }),
      }),
      getActiveGateways: build.query<ActiveGatewayResponse, void>({
        query: () => ({
          url: `PaymentGateway/GetActiveGateways`,
          method: 'GET',
        }),
      }),
      createPayment: build.mutation<CreateSubscriptionResponse, CreatePaymentPayload>({
        query: (data) => ({
          url: `SubscriptionPayment/Create`,
          method: 'POST',
          data,
        }),
      }),
      getConfiguration: build.query<ConfigurationResponse, void>({
        query: () => ({
          url: `StripeSession/GetConfiguration`,
          method: 'GET',
        }),
      }),
      createStripeSession: build.mutation<CreateStripeSessionResponse, CreateStripeSessionPayload>({
        query: (data) => ({
          url: `StripeSession/Create`,
          method: 'POST',
          data,
        }),
      }),
      getPaymentId: build.query<PaymentIdResponse, number>({
        query: (paymentId) => ({
          url: `SubscriptionPayment/Get?paymentId=${paymentId}`,
          method: 'GET',
        }),
      }),
      postUpgradeSubscriptionCostsLessThenMinAmount: build.mutation<CommonResponse, SharedSubscriptionIdPayload>({
        query: (subscriptionId) => ({
          url: `Subscription/UpgradeSubscriptionCostsLessThenMinAmount?subscriptionId=${subscriptionId}`,
          method: 'POST',
        }),
      }),
      getUsedSubscriptionTrialIds: build.query<UsedSubscriptionTrialIdsResponse, string>({
        query: (tenantId) => ({
          url: `Subscription/GetUsedSubscriptionTrialIds?tenantId=${tenantId}`,
          method: 'GET',
        }),
      }),
      selectedTrialSubscription: build.mutation<CommonResponse, string>({
        query: (subscriptionId) => ({
          url: `Subscription/SelectTrialSubscription?subscriptionId=${subscriptionId}`,
          method: 'POST',
        }),
      }),
      cancelSubscription: build.mutation<CommonResponse, void>({
        query: () => ({
          url: `Subscription/Cancel`,
          method: 'POST',
        }),
      }),
      selectFreeSubscription: build.mutation<CommonResponse, string>({
        query: (subscriptionId) => ({
          url: `Subscription/SelectFreeSubscription?subscriptionId=${subscriptionId}`,
          method: 'POST',
        }),
      }),
      hasAnyPayment: build.mutation<CommonResponse, void>({
        query: () => ({
          url: `SubscriptionPayment/HasAnyPayment`,
          method: 'POST',
        }),
      }),
      getInfoUpgradeSubscription: build.query<InfoUpgradeSubscriptionResponse, string>({
        query: (subscriptionId) => ({
          url: `SubscriptionPayment/GetInfo?UpgradeSubscriptionId=${subscriptionId}`,
          method: 'GET',
        }),
      }),
      getLastCompleted: build.query<LastCompletedResponse, void>({
        query: () => ({
          url: `SubscriptionPayment/GetLastCompleted`,
          method: 'GET',
        }),
      }),
      upgradedSubscriptionCostsLessThenMinAmount: build.mutation<CommonResponse, string>({
        query: (subscriptionId) => ({
          url: `Subscription/UpgradeSubscriptionCostsLessThenMinAmount?subscriptionId=${subscriptionId}`,
          method: 'POST',
        }),
      }),
      renewSubscription: build.mutation<CommonResponse, void>({
        query: () => ({
          url: `Subscription/Renew`,
          method: 'POST',
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetSubscriptionsFeaturesQuery,
  useGetMyHistoryQuery,
  useLazyGetInvoicesPdfQuery,
  useGetSubscriptionForSelectQuery,
  useGetSubscriptionQuery,
  useLazyGetSubscriptionQuery,
  useGetActiveGatewaysQuery,
  useCreatePaymentMutation,
  useCreateStripeSessionMutation,
  useGetPaymentIdQuery,
  useGetConfigurationQuery,
  usePostUpgradeSubscriptionCostsLessThenMinAmountMutation,
  useGetUsedSubscriptionTrialIdsQuery,
  useSelectedTrialSubscriptionMutation,
  useCancelSubscriptionMutation,
  useSelectFreeSubscriptionMutation,
  useHasAnyPaymentMutation,
  useLazyGetInfoUpgradeSubscriptionQuery,
  useLazyGetLastCompletedQuery,
  useUpgradedSubscriptionCostsLessThenMinAmountMutation,
  useRenewSubscriptionMutation,
} = subscriptionTenantApi;
