import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetUsersQuery } from 'apis/users.api';
import {
  getUsersFiltersFieldsDefaultValues,
  SEARCH_USERS_PLACEHOLDER,
  USERS_FILTERS_FORM_FIELDS,
  USERS_TABLE_COLUMNS,
} from 'constants/users';
import { useFilters } from 'hooks/useFilters';
import useImpersonateUser from 'hooks/useImpersonateUser';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import useTenantId from 'hooks/useTenantId';
import { CommonError } from 'interfaces/shared.interface';
import { Permissions } from 'interfaces/user.interface';
import { UsersFilterFormValues } from 'interfaces/users.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import UserSettingsModal from 'page-components/users/UserSettingsModal';
import UsersRow from 'page-components/users/UsersRow';
import {
  Button,
  LoadingOverlay,
  Pagination,
  FilterForm,
  Table,
  FilterFormContent,
  ErrorModal,
} from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';

const Users = () => {
  const { t } = useTranslation();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenSettingsUserModal, handleOpenSettingsUserModal, handleCloseSettingsUserModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { tenantId, name, tenantError, tenantIdQueryParameter, shouldSkip, isTenantIdLoading } = useTenantId({
    onError: handleOpenErrorModal,
  });

  const {
    handleImpersonator,
    isFetching: isImpersonateUserLoading,
    error: impersonateUserError,
  } = useImpersonateUser({
    onError: handleOpenErrorModal,
  });

  const { applyFilters, filterParams, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: errorUserData,
  } = useGetUsersQuery(
    { params: `${filterParams}${sortingParams}${paginationParams}${tenantIdQueryParameter}`, isAdmin: !!tenantId },
    { skip: shouldSkip }
  );

  const { result } = data || {};
  const { error: errorData } = (errorUserData as CommonError)?.data || {};

  const usersFiltersMethods = useForm<UsersFilterFormValues>({
    defaultValues: getUsersFiltersFieldsDefaultValues(t('All')),
  });

  const onSubmitFilters = (data: UsersFilterFormValues) => {
    const { isUserActive, isUserLocked, isEmailConfirmed } = data;

    const newFiltersToApply = {
      ...data,
      isUserActive: isUserActive.value !== '' ? (isUserActive.value as boolean).toString() : null,
      isUserLocked: isUserLocked.value !== '' ? (isUserLocked.value as boolean).toString() : null,
      isEmailConfirmed: isEmailConfirmed.value !== '' ? (isEmailConfirmed.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    usersFiltersMethods.reset(getUsersFiltersFieldsDefaultValues(t('All')));
    onSubmitFilters(getUsersFiltersFieldsDefaultValues(t('All')));
  };

  useEffect(() => {
    if (errorUserData) {
      handleOpenErrorModal();
    }
  }, [errorUserData, handleOpenErrorModal]);

  useEffect(() => {
    usersFiltersMethods.reset(getUsersFiltersFieldsDefaultValues(t('All')));
  }, [t, usersFiltersMethods]);

  return (
    <AppContentLayout title="Users" titlePrefix={name ? `${name}:` : ''}>
      <div data-cy="users-page">
        <UserSettingsModal
          handleClose={handleCloseSettingsUserModal}
          isOpen={isOpenSettingsUserModal}
          refetchListUsers={refetch}
          heading="CreateNewUser"
        />

        <div className="relative">
          <LoadingOverlay
            isLoading={isImpersonateUserLoading || (isFetching && !!data) || isTenantIdLoading}
            isFixed={isImpersonateUserLoading}
          />

          <div>
            <FormProvider {...usersFiltersMethods}>
              <form onSubmit={usersFiltersMethods.handleSubmit(onSubmitFilters)}>
                <FilterForm
                  dataCy="users-page-search"
                  placeHolder={SEARCH_USERS_PLACEHOLDER}
                  handleClearFields={handleClearFields}
                  rightTopActionButton={
                    <Button
                      hidden={!permissions[Permissions.PAGES_USERS_CREATE]}
                      dataCy="create-user-button"
                      className="h-10 ml-3.5"
                      type="button"
                      onClick={handleOpenSettingsUserModal}
                    >
                      {t('Create')}
                    </Button>
                  }
                >
                  <FilterFormContent fields={USERS_FILTERS_FORM_FIELDS} />
                </FilterForm>
              </form>
            </FormProvider>
          </div>

          <Table
            dataCy="users-table"
            columns={USERS_TABLE_COLUMNS}
            handleSetTabName={handleSetTabName}
            sortingColumnId={sortingColumnId}
            sortingType={sortingType}
            isLoading={isLoading}
          >
            {result?.items.map((user) => (
              <UsersRow
                key={user.id}
                user={user}
                handleImpersonate={handleImpersonator}
                refetchUsersList={refetch}
                isLoading={isFetching}
              />
            ))}
          </Table>

          <Pagination
            totalCount={result?.totalCount}
            handleSetMaxResultCount={handleSetMaxResultCount}
            maxResultCount={maxResultCount}
            handleSetCurrentPage={handleSetCurrentPage}
            currentPage={currentPage}
          />
        </div>

        <ErrorModal
          errorMessage={tenantError?.message || impersonateUserError?.message || errorData?.message}
          description={errorData?.details}
          isOpen={isErrorModalOpen}
          handleClose={handleCloseErrorModal}
        />
      </div>
    </AppContentLayout>
  );
};

export default Users;
