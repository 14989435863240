import { FieldTypes, FormItem, TableColumn } from 'interfaces/shared.interface';
import { BaseSubscriptionsData } from 'interfaces/subscriptions-admin.interface';
import {
  BASE_TENANT_EDIT_FORM_FIELDS,
  TENANTS_COLUMNS,
  TENANTS_FILTERS_FORM_FIELD_NAMES,
} from 'interfaces/tenants.interface';
import { Checkbox, Input, SelectFormInput } from 'shared-components';

import { BASE_FILTER_FIELDS } from './common';

export const TENANTS_COLUMNS_TITLES = {
  [TENANTS_COLUMNS.TENANT_NAME]: 'TenantId',
  [TENANTS_COLUMNS.TENANT_DISPLAY_NAME]: 'Name',
  [TENANTS_COLUMNS.CREATION_DATE]: 'CreationDate',
  [TENANTS_COLUMNS.SUBSCRIPTION]: 'Subscription',
  [TENANTS_COLUMNS.SUBSCRIPTION_START_DATE]: 'SubscriptionStartDate',
  [TENANTS_COLUMNS.SUBSCRIPTION_END_DATE]: 'SubscriptionEndDate',
  [TENANTS_COLUMNS.IS_ACTIVE]: 'IsActive',
  [TENANTS_COLUMNS.ACTIONS]: '',
};

export const TENANTS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: TENANTS_COLUMNS.TENANT_NAME,
    title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.TENANT_NAME],
  },
  {
    columnId: TENANTS_COLUMNS.TENANT_DISPLAY_NAME,
    title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.TENANT_DISPLAY_NAME],
  },
  {
    columnId: TENANTS_COLUMNS.CREATION_DATE,
    title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.CREATION_DATE],
    sorting: true,
  },
  {
    columnId: TENANTS_COLUMNS.SUBSCRIPTION,
    title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.SUBSCRIPTION],
  },
  {
    columnId: TENANTS_COLUMNS.SUBSCRIPTION_START_DATE,
    title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.SUBSCRIPTION_START_DATE],
    sorting: true,
  },
  {
    columnId: TENANTS_COLUMNS.SUBSCRIPTION_END_DATE,
    title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.SUBSCRIPTION_END_DATE],
    sorting: true,
  },
  {
    columnId: TENANTS_COLUMNS.IS_ACTIVE,
    title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.IS_ACTIVE],
    sorting: true,
  },
  { columnId: TENANTS_COLUMNS.ACTIONS, title: TENANTS_COLUMNS_TITLES[TENANTS_COLUMNS.ACTIONS] },
];

export const getTenantsFilterFormFields = (subscriptionsOptions?: BaseSubscriptionsData[]): FormItem[] => {
  return [
    {
      name: TENANTS_FILTERS_FORM_FIELD_NAMES.IS_ACTIVE,
      label: 'IsActive',
      className: 'ml-10 w-30',
      type: FieldTypes.SELECT,
      dataCy: 'is-active-select',
      component: SelectFormInput,
      labelClassName: 'text-sm text-gray-500 dark:text-gray',
      isClearable: true,
      placeholder: 'IsActive',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    {
      name: TENANTS_FILTERS_FORM_FIELD_NAMES.SUBSCRIPTION_ID,
      label: 'SelectSubscription',
      className: 'ml-10 w-30',
      type: FieldTypes.SELECT,
      dataCy: 'subscription-id-select',
      component: SelectFormInput,
      labelClassName: 'text-sm text-gray-500 dark:text-gray',
      isClearable: true,
      placeholder: 'SelectSubscription',
      options: subscriptionsOptions?.map((subscription) => ({
        label: subscription.displayText,
        value: subscription.value,
      })),
    },
  ];
};

export const getTenantsEditInputFields = (subscriptionsOptions?: BaseSubscriptionsData[]): FormItem[] => [
  {
    name: BASE_TENANT_EDIT_FORM_FIELDS.NAME,
    label: 'Name',
    required: true,
    component: Input,
    inputClassName: 'w-full text-md',
    dataCy: 'edit-name-field',
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: BASE_TENANT_EDIT_FORM_FIELDS.SUBSCRIPTION,
    label: 'Subscription',
    component: SelectFormInput,
    inputClassName: 'w-full',
    className: 'mt-4',
    labelClassName: 'text-xs',
    dataCy: 'edit-subscription-field',
    options: subscriptionsOptions?.map((subscription) => ({
      label: subscription.displayText,
      value: subscription.value,
    })),
  },
];

export const TENANT_EDIT_CHECKBOX_FIELD: FormItem[] = [
  {
    name: BASE_TENANT_EDIT_FORM_FIELDS.IS_ACTIVE,
    labelClassName: 'mt-5',
    dataCy: 'edit-status-field',
    label: 'IsActive',
    component: Checkbox,
  },
];

export const TENANTS_DEFAULT_FILTERS_VALUES = {
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [TENANTS_FILTERS_FORM_FIELD_NAMES.IS_ACTIVE]: { label: '', value: null },
  [TENANTS_FILTERS_FORM_FIELD_NAMES.SUBSCRIPTION_ID]: { label: '', value: null },
};

export const SEARCH_TENANTS_PLACEHOLDER = 'SearchByIdentifierOrName';
