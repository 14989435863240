import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  CHANGE_LOG_DETAILS_COLUMNS,
  CHANGE_LOGS_COLUMNS,
  OPERATION_LOGS_COLUMNS,
} from 'interfaces/integrations/os-audit-logs.interface';
import { TabItem, TableColumn } from 'interfaces/shared.interface';
import ChangeLogs from 'page-components/integrations/os-audit-logs/change-logs/ChangeLogs';
import OperationLogs from 'page-components/integrations/os-audit-logs/operation-logs/OperationLogs';

import { BoxVariants } from './common';

export const OS_AUDIT_LOGS_TABS: TabItem[] = [
  {
    id: 'operation-logs',
    title: 'OperationLogs',
    component: OperationLogs,
  },
  {
    dataCy: 'change-logs-tab-button',
    id: 'change-logs',
    title: 'ChangeLogs',
    component: ChangeLogs,
  },
];

const SERVICE_NAME = t('ServiceName');
const METHOD_NAME = t('MethodName');

export const OPERATION_LOGS_COLUMNS_TITLES = {
  [OPERATION_LOGS_COLUMNS.STATE]: 'State',
  [OPERATION_LOGS_COLUMNS.USER_EMAIL]: 'UserEmail',
  [OPERATION_LOGS_COLUMNS.SERVICE]: SERVICE_NAME + ' / ' + METHOD_NAME,
  [OPERATION_LOGS_COLUMNS.EXECUTION_DURATION]: 'ExecutionDuration',
  [OPERATION_LOGS_COLUMNS.BROWSER]: 'BrowserInfo',
  [OPERATION_LOGS_COLUMNS.EXECUTION_TIME]: 'Time',
  [OPERATION_LOGS_COLUMNS.ACTIONS]: '',
};

export const OPERATION_LOGS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: OPERATION_LOGS_COLUMNS.STATE,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.STATE],
    width: 'w-20',
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.USER_EMAIL,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.USER_EMAIL],
    width: 'w-80',
    sorting: true,
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.SERVICE,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.SERVICE],
    width: 'w-80',
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.EXECUTION_DURATION,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.EXECUTION_DURATION],
    width: 'w-36',
    sorting: true,
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.BROWSER,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.BROWSER],
    width: 'w-80',
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.EXECUTION_TIME,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.EXECUTION_TIME],
    width: 'w-52',
    sorting: true,
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.ACTIONS,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.ACTIONS],
    width: 'w-14',
    className: 'm-auto',
  },
];

export const CHANGE_LOGS_COLUMNS_TITLES = {
  [CHANGE_LOGS_COLUMNS.CHANGE_TYPE]: 'Change type',
  [CHANGE_LOGS_COLUMNS.OBJECT]: 'EntityTypeFullName',
  [CHANGE_LOGS_COLUMNS.USER_EMAIL]: 'UserEmail',
  [CHANGE_LOGS_COLUMNS.TIME]: 'Time',
  [CHANGE_LOGS_COLUMNS.ACTIONS]: '',
};

export const CHANGE_LOGS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CHANGE_LOGS_COLUMNS.CHANGE_TYPE,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.CHANGE_TYPE],
    width: 'w-80',
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.OBJECT,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.OBJECT],
    width: 'w-80',
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.USER_EMAIL,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.USER_EMAIL],
    sorting: true,
    width: 'w-80',
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.TIME,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.TIME],
    sorting: true,
    width: 'w-52',
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.ACTIONS,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.ACTIONS],
    width: 'w-14',
  },
];

export const DETAILS_SHARED_BOX_PROPS = { variant: BoxVariants.TRANSPARENT, className: 'border-none [&>div]:mb-4' };

export const DETAILS_SHARED_LABEL_PROPS = {
  labelClassName: 'text-black dark:text-white shrink-0 grow-0 sm:basis-36 py-1.5',
  valueClassName: 'sm:w-8/12 grow-0',
};

export const CHANGE_LOGS_DETAILS_COLUMNS_TITLES = {
  [CHANGE_LOG_DETAILS_COLUMNS.ENTITY_CHANGE_ID]: 'EnttityChangeId',
  [CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_NAME]: 'PropertyName',
  [CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_TYPE]: 'PropertyType',
  [CHANGE_LOG_DETAILS_COLUMNS.ORIGINAL_VALUE]: 'OriginalValue',
  [CHANGE_LOG_DETAILS_COLUMNS.NEW_VALUE]: 'NewValue',
};

export const CHANGE_LOGS_DETAILS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.ENTITY_CHANGE_ID,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.ENTITY_CHANGE_ID],
    width: 'w-[10%] border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_NAME,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_NAME],
    width: 'w-[14%] border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_TYPE,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_TYPE],
    width: 'border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.ORIGINAL_VALUE,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.ORIGINAL_VALUE],
    width: 'border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.NEW_VALUE,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.NEW_VALUE],
    width: 'border border-lightGray8 font-semibold',
  },
];

export const CURRENT_FILTERS = `MaxResultCount=1&SkipCount=0`;

export const OPERATIONS = [
  { name: 'AND', value: `null_${uuidv4()}`, type: '1' },
  { name: 'OR', value: `null_${uuidv4()}`, type: '1' },
  { name: 'NOT', value: `null_${uuidv4()}`, type: '1' },
];
