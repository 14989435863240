import { createSlice } from '@reduxjs/toolkit';

import { UserState } from 'interfaces/user.interface';

import {
  getCurrentUserLoginReducer,
  getUserConfigReducer,
  getUserDetailsReducer,
  setProfilePictureReducer,
} from './reducers';

const initialState: UserState = {
  data: null,
  roles: null,
  tenantId: null,
  profilePictureId: null,
  localization: null,
  currentTheme: null,
  permissions: {
    isManagingUsersAccess: false,
    grantedPermissions: null,
  },
};

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setProfilePictureReducer(builder);
    getUserDetailsReducer(builder);
    getUserConfigReducer(builder);
    getCurrentUserLoginReducer(builder);
  },
});

export default userSlice.reducer;
