import {
  GetAuthLinkResponse,
  GetConnectionDetailsResponse,
  GetCustomerResponse,
  GetCustomersResponse,
  GetInvoiceDetailsResponse,
  GetInvoicePdfResponse,
  GetInvoicesResponse,
} from 'interfaces/integrations/quickBooks.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const quickBooksApi = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getAuthQuickBooksLink: build.query<GetAuthLinkResponse, void>({
      query: () => ({
        url: `QuickBooks/GetAuthLink`,
        method: 'GET',
      }),
    }),
    getConnectionQuickBooksDetails: build.query<GetConnectionDetailsResponse, void>({
      query: () => ({
        url: `QuickBooks/GetConnectionDetails`,
        method: 'GET',
      }),
    }),
    postAuthenticationTokenConnect: build.mutation<CommonResponse, { connectionCode: string; companyId: string }>({
      query: (data) => ({
        url: `QuickBooks/Connect`,
        method: 'POST',
        data,
      }),
    }),
    getQuickBooksInvoices: build.query<GetInvoicesResponse, string>({
      query: (params) => ({
        url: `QuickBooks/GetInvoices?${params}`,
        method: 'GET',
      }),
    }),
    getQuickBooksCustomers: build.query<GetCustomersResponse, string>({
      query: (params) => ({
        url: `QuickBooks/GetCustomers?${params}`,
        method: 'GET',
      }),
    }),
    getInvoicePdf: build.query<GetInvoicePdfResponse, string>({
      query: (params) => ({
        url: `QuickBooks/GetInvoicePdf?invoiceId=${params}`,
        method: 'GET',
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
    postDisconnectQuickBooks: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `QuickBooks/Disconnect`,
        method: 'POST',
      }),
    }),
    getCustomer: build.query<GetCustomerResponse, string | undefined>({
      query: (params) => ({
        url: `QuickBooks/GetCustomer?CustomerId=${params}`,
        method: 'GET',
      }),
    }),
    getInvoiceDetails: build.query<GetInvoiceDetailsResponse, string | undefined>({
      query: (params) => ({
        url: `QuickBooks/GetInvoice?InvoiceId=${params}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAuthQuickBooksLinkQuery,
  useGetConnectionQuickBooksDetailsQuery,
  usePostAuthenticationTokenConnectMutation,
  useGetQuickBooksInvoicesQuery,
  useLazyGetQuickBooksInvoicesQuery,
  useGetQuickBooksCustomersQuery,
  useLazyGetQuickBooksCustomersQuery,
  useLazyGetInvoicePdfQuery,
  usePostDisconnectQuickBooksMutation,
  useGetCustomerQuery,
  useGetInvoiceDetailsQuery,
} = quickBooksApi;
