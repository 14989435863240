import { useMemo } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useTenantGetProfitShareQuery } from 'apis';
import { PROFIT_SHARE_LABELS } from 'apis/profit-share';
import { BoxVariants } from 'constants/common';
import { DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME } from 'constants/dashboard';
import { getPieChartOptions } from 'constants/shared-components';
import { Box, LoadingOverlay } from 'shared-components';
import { cn } from 'utils/global';

import { ProfitShareLabels } from './ProfitShareLabels';

export const ProfitShareWidget = () => {
  const { data: profitShareData, isLoading, isFetching } = useTenantGetProfitShareQuery();

  const chartOptions = useMemo(() => {
    const profitShares = profitShareData?.result.profitShares || [];
    const chartData = profitShares.map((profitShare) => ({ y: profitShare }));

    return getPieChartOptions(chartData, { colors: PROFIT_SHARE_LABELS.map(({ color }) => color) });
  }, [profitShareData]);

  return (
    <Box
      dataCy="profit-share-widget"
      className="relative h-full"
      headerClassName={cn(DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME, 'justify-start items-baseline gap-2')}
      variant={BoxVariants.ROUNDED}
      title="WidgetProfitShare"
      titleClassName="whitespace-nowrap"
      customHeader={<p className="text-xs text-gray2">Profit Share between customers</p>}
    >
      <div className="flex flex-col lg:flex-row gap-4 lg:gap-0">
        <LoadingOverlay isLoading={isLoading || isFetching} />
        <div className="max-h-64 h-full w-full lg:w-2/4">
          <HighchartsReact
            containerProps={{ style: { height: '100%', width: '100%' } }}
            highcharts={Highcharts}
            options={chartOptions}
          />
        </div>
        <ProfitShareLabels stats={profitShareData?.result.profitShares} />
      </div>
    </Box>
  );
};
