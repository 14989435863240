import { useCallback, useEffect } from 'react';

import { useLazyGetOsEntityChangesQuery } from 'apis/os-audit-logs.api';
import { CHANGE_LOGS_TABLE_COLUMNS } from 'constants/os-audit-logs';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, LoadingOverlay, Pagination, Table } from 'shared-components';

import ChangeLogsFilter from './ChangeLogsFilter';
import ChangeLogsRow from './ChangeLogsRow';

const ChangeLogs = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { filterParams, applyFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const [getEntityChanges, { data: changeLogsData, isLoading, error, isFetching }] = useLazyGetOsEntityChangesQuery();

  const { error: errorData } = (error as CommonError)?.data || {};

  const { result: changeLogsResult } = changeLogsData || {};

  useEffect(() => {
    getEntityChanges(`${filterParams}${sortingParams}${paginationParams}`);
  }, [filterParams, getEntityChanges, paginationParams, sortingParams]);

  const handleSearch = useCallback(
    (newFilterParams: string) => {
      const params = new URLSearchParams(newFilterParams);
      const filterObject = Object.fromEntries(params.entries());
      applyFilters(filterObject);
      getEntityChanges(`${newFilterParams}${sortingParams}${paginationParams}`);
    },
    [applyFilters]
  );

  useEffect(() => {
    if (!isFetching) {
      getEntityChanges(`${filterParams}${sortingParams}${paginationParams}`);
    }
  }, [filterParams, paginationParams, sortingParams]);

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
  }, [errorData, handleOpenErrorModal]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="change-logs-tab" className="relative">
        <LoadingOverlay isLoading={isFetching} />

        <ChangeLogsFilter onSearch={(filters) => handleSearch(filters)} handleClear={() => applyFilters('')} />

        <Table
          dataCy="change-logs-table"
          columns={CHANGE_LOGS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!changeLogsResult?.items.length}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {changeLogsResult?.items.map((item) => <ChangeLogsRow key={item.id} logData={item} />)}
        </Table>

        <Pagination
          totalCount={changeLogsResult?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default ChangeLogs;
