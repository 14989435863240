import { FC } from 'react';

import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StateManagedSelect, { default as SelectComponent } from 'react-select';

import useTheme from 'hooks/useTheme';
import { FormItem } from 'interfaces/shared.interface';
import { Skeleton } from 'shared-components/index';
import { cn } from 'utils/global';

import MultiSelectValueRemoveButton from './MultiSelectValueRemoveButton';
import { getSelectStyles } from './Select';
import ErrorMessage from '../ErrorMessage';

export type Props = {
  control: Control;
  isMulti?: boolean;
  className?: string;
  labelClassName?: string;
  maxMenuHeight?: number;
  isSearchable?: boolean;
  anchorPrefix?: string;
  error?: string;
  menuPlacement?: 'bottom' | 'top';
  isLoading?: boolean;
} & Partial<StateManagedSelect> &
  Omit<FormItem, 'component'>;

const MutliSelectFormInput: FC<Props> = ({
  label,
  options,
  control,
  dataCy,
  name,
  labelClassName,
  inputClassName,
  placeholder,
  isSearchable = false,
  disabled,
  defaultValue,
  menuPlacement,
  maxMenuHeight = 200,
  isClearable = false,
  className,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const internalizedOptions = options?.map((option) => {
    return { value: option.value, label: t(option.label) };
  });

  return (
    <div className={cn('w-full', className)}>
      <>
        {typeof label === 'string' ? (
          <label className={cn('text-sm text-gray-500', labelClassName)}>{t(label)}</label>
        ) : (
          label
        )}

        {isLoading ? (
          <Skeleton className={cn('w-full min-h-[2.5rem]', inputClassName)} />
        ) : (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
              <>
                <SelectComponent
                  inputId={dataCy}
                  components={{ MultiValueRemove: MultiSelectValueRemoveButton }}
                  isSearchable={isSearchable}
                  options={internalizedOptions}
                  isClearable={isClearable}
                  isMulti={true}
                  placeholder={placeholder}
                  menuPlacement={menuPlacement}
                  styles={getSelectStyles(theme, true, error)}
                  maxMenuHeight={maxMenuHeight}
                  className={cn(
                    'div-py-0',
                    {
                      'border-red-500': error,
                      '[&>div]:bg-gray2': disabled,
                    },
                    inputClassName
                  )}
                  {...field}
                  value={Object.values(field?.value || {}).includes(null) ? null : field.value}
                />
                {error?.message && <ErrorMessage dataCy="select-error-message">{t(error.message)}</ErrorMessage>}
              </>
            )}
          />
        )}
      </>
    </div>
  );
};

export default MutliSelectFormInput;
