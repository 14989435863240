import { useEffect, useState } from 'react';

import { useGetXeroContactsQuery } from 'apis/xero.api';
import { CONTACTS_TABLE_COLUMNS } from 'constants/xero';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, LoadingOverlay, Pagination, Table } from 'shared-components';

import ContactsRow from './ContactsRow';

const Contacts = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { data, isLoading, isFetching, error } = useGetXeroContactsQuery();

  const response = data?.result;
  const { error: errorData } = (error as CommonError)?.data || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [maxResultCount, setMaxResultCount] = useState(10);

  const startIndex = (currentPage - 1) * maxResultCount;
  const endIndex = startIndex + maxResultCount;

  const paginatedContacts = data?.result.slice(startIndex, endIndex);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetMaxResultCount = (count: number) => {
    setMaxResultCount(count);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (error) {
      handleOpenErrorModal();
    }
  }, [error, handleOpenErrorModal]);

  return (
    <div data-cy="contacts-tab">
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />

      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <Table
          dataCy="contacts-table"
          columns={CONTACTS_TABLE_COLUMNS}
          isTableEmpty={!response?.length}
          isLoading={isLoading}
        >
          {paginatedContacts?.map((item) => <ContactsRow key={item.contactID} contactsData={item} />)}
        </Table>

        <Pagination
          totalCount={response?.length}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Contacts;
