export enum TableVariants {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export const TableVariantsStyles: Record<
  TableVariants,
  { tableClassName: string; rowClassName: string; headClassName: string }
> = {
  [TableVariants.PRIMARY]: {
    tableClassName: 'bg-white dark:bg-darkBlue4',
    headClassName: 'p-3.5',
    rowClassName:
      'border-lightGray8 dark:even:border-darkBlue dark:odd:border-darkBlue4 border-b dark:text-white dark:even:bg-darkBlue dark:odd:bg-darkBlue4',
  },
  [TableVariants.SECONDARY]: {
    tableClassName: 'bg-transparent',
    headClassName: '!border-x-0 border-y !border-lightGray4 text-sm font-normal',
    rowClassName: '[&_td]:p-2 dark:text-white [&_td]:border-0 [&_td]:border-b [&_td]:last:border-b-0',
  },
};
