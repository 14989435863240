import { useTranslation } from 'react-i18next';

import { useSuspendConnectionMutation } from 'apis/codat-accounting.api';
import { sharedButtonStyle } from 'constants/codat-accounting';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Button, ErrorModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

interface Props {
  triggerRefetch: () => void;
  isLoading: boolean;
}

const SuspendConnectionButton = ({ triggerRefetch, isLoading }: Props) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [suspendConnection, { error: suspendCodatAccountingError, isLoading: isSuspendConnectionLoading }] =
    useSuspendConnectionMutation();

  const errorSuspendAccountData = (suspendCodatAccountingError as CommonError)?.data || {};

  const errorData = errorSuspendAccountData;

  const handleSuspendConnection = async () => {
    try {
      await suspendConnection(true).unwrap();
      triggerRefetch();
      successNotify(t('ConnectionSuspended'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <Button
        isLoading={isLoading || isSuspendConnectionLoading}
        className={sharedButtonStyle}
        data-cy="suspend-connection-button"
        onClick={handleSuspendConnection}
      >
        {t('SuspendConnection')}
      </Button>
    </>
  );
};

export default SuspendConnectionButton;
