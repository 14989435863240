import { BannerProps } from 'interfaces/subscriptions-tenant.interface';
import HereSubscriptionLink from 'page-components/subscriptions-tenant/banner/HereSubscriptionLink';

import BuildMessageWithLinks from './BannerMessageBuilder';

const SubscriptionDeactivatedBanner = ({
  isSubscriptionManagementPermission,
  displayName,
  expiringSubscriptionName,
  loading,
  daysAmount,
}: BannerProps) => {
  const buildDeactivatedSubscriptionMessageWithName = () => {
    return (
      <BuildMessageWithLinks
        messageKey="DeactivatedSubscriptionWillExpireNotification"
        values={{
          0: `${daysAmount}`,
          1: `${displayName}`,
          2: `${expiringSubscriptionName}`,
        }}
        splits={[{ index: 3, component: <HereSubscriptionLink /> }]}
      />
    );
  };

  const buildSubscriptionWillExpireNotificationForUser = () => {
    return (
      <BuildMessageWithLinks
        messageKey="SubscriptionWillExpireNotificationForUser"
        values={{ 0: `${daysAmount}` }}
        splits={[]}
      />
    );
  };

  const buildDeactivatedSubscriptionMessage = () => {
    return (
      <BuildMessageWithLinks
        messageKey="TenantWillBeDeactivatedAfterDeactivatedSubscriptionExpiresNotification"
        values={{
          0: `${daysAmount}`,
          1: `${displayName}`,
        }}
        splits={[{ index: 2, component: <HereSubscriptionLink /> }]}
      />
    );
  };

  const handleSubscriptionDeactivated = () => {
    if (isSubscriptionManagementPermission) {
      if (expiringSubscriptionName) {
        return buildDeactivatedSubscriptionMessageWithName();
      } else {
        return buildDeactivatedSubscriptionMessage();
      }
    }
    return buildSubscriptionWillExpireNotificationForUser();
  };

  const buildMessage = () => {
    return handleSubscriptionDeactivated();
  };

  const message = buildMessage();

  return !loading && message ? (
    <div data-cy="banner" className="w-full block text-center py-2 bg-blue9 text-white font-bold">
      {message}
    </div>
  ) : null;
};

export default SubscriptionDeactivatedBanner;
