import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDeleteTenantMutation } from 'apis/tenants.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Tenant } from 'interfaces/tenants.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Checkbox, DropdownMenu, ErrorModal, TableData, TableRow, WarningModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';
import { getTenantUsersLink, getTenantDetailsLink, getTenantRolesLink } from 'utils/url';

import TenantEditModal from './TenantEditModal';

interface Props {
  tenantData: Tenant;
  refetchTenants: VoidFunction;
  handleEdit: (id: number) => void;
  subscriptionId?: number;
}

const TenantsRow = ({ tenantData, refetchTenants }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isEditModalOpen, handleOpenEditModal, handleCloseEditModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [deleteTenant, { error: deleteTenantError }] = useDeleteTenantMutation();
  const errorData = (deleteTenantError as CommonError)?.data || {};

  const menuItemsAction = [
    {
      dataCy: 'menu-item-edit-button',
      name: 'Edit',
      actionOnClick: handleOpenEditModal,
      hidden: !permissions[Permissions.PAGES_HOST_TENANTS_EDIT],
    },
    {
      dataCy: 'menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        navigate(getTenantDetailsLink(tenantData.id));
      },
      hidden: !permissions[Permissions.PAGES_HOST_TENANTS_DETAILS],
    },
    {
      dataCy: 'menu-item-delete-button',
      name: 'Delete',
      actionOnClick: handleOpenInfoModal,
      hidden: !permissions[Permissions.PAGES_HOST_TENANTS_DELETE],
    },
    {
      dataCy: 'menu-item-manage-users-button',
      name: 'Manage Users',
      actionOnClick: () => {
        navigate(getTenantUsersLink(tenantData.id));
      },
      hidden: !permissions[Permissions.PAGES_HOST_TENANTS_USERS],
    },
    {
      dataCy: 'menu-item-manage-roles-button',
      name: 'Manage Roles',
      actionOnClick: () => {
        navigate(getTenantRolesLink(tenantData.id));
      },
      hidden: !permissions[Permissions.PAGES_HOST_TENANTS_ROLES],
    },
  ];

  const handleDeleteTenant = async () => {
    try {
      await deleteTenant(tenantData.id).unwrap();
      refetchTenants();
      handleCloseInfoModal();
      successNotify(t('SuccessfullyDeleted'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <TenantEditModal
        tenantId={tenantData.id}
        handleClose={handleCloseEditModal}
        isOpen={isEditModalOpen}
        refetchTenants={refetchTenants}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('TenantDeleteWarningMessage', { 0: tenantData.name })}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleDeleteTenant}
      />

      <TableRow dataCy="tenants-table-row">
        <TableData>{tenantData.name}</TableData>
        <TableData dataCy="table-data-tenant-name">{tenantData.displayName}</TableData>
        <TableData>{getFormattedDate(tenantData.creationDate)}</TableData>
        <TableData>{tenantData.subscriptionName}</TableData>
        <TableData>{getFormattedDate(tenantData.subscriptionStartDate)}</TableData>
        <TableData>{getFormattedDate(tenantData?.subscriptionEndDate)}</TableData>
        <TableData dataCy="active-tenant-checkbox">
          <Checkbox disabled checked={tenantData.isActive} />
        </TableData>
        <TableData>
          <DropdownMenu dataCy="tenants-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default TenantsRow;
