import { useEffect } from 'react';

import { endOfDay, startOfDay } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetInvoicesStatusesQuery, useGetSageInvoicesQuery } from 'apis/sage';
import {
  getInvoicesFiltersFieldsDefaultValue,
  getInvoicesFilterFormFields,
  INVOICES_TABLE_COLUMNS,
  InvoicesFilterFormValues,
  SEARCH_INVOICES_PLACEHOLDER,
} from 'constants/sage';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import InvoicesRow from 'page-components/integrations/sage/invoices/InvoicesRow';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

const Invoices = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { filterParams, applyFilters, isSameFilters } = useFilters();

  const { handleSetTabName, customSortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { customPaginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    isFetching,
    refetch,
    error: invoicesError,
  } = useGetSageInvoicesQuery(`${filterParams}${customSortingParams}${customPaginationParams}`);

  const {
    data: invoicesStatuses,
    isLoading: isInvoicesStatusesLoading,
    error: invoicesStatusesError,
  } = useGetInvoicesStatusesQuery();

  const isLoading = isInvoicesLoading || isInvoicesStatusesLoading;

  const { result } = invoicesData || {};

  const { error: invoicesStatusesErrorData } = (invoicesStatusesError as CommonError)?.data || {};
  const { error: invoicesErrorData } = (invoicesError as CommonError)?.data || {};

  const errorData = invoicesStatusesErrorData || invoicesErrorData;

  const invoicesFiltersMethods = useForm<InvoicesFilterFormValues>({
    defaultValues: getInvoicesFiltersFieldsDefaultValue(t('SelectStatus')),
  });

  const handleClearFields = () => {
    invoicesFiltersMethods.reset(getInvoicesFiltersFieldsDefaultValue(t('SelectStatus')));
    onSubmitFilters(getInvoicesFiltersFieldsDefaultValue(t('SelectStatus')));
  };

  const onSubmitFilters = async (data: InvoicesFilterFormValues) => {
    const { filter, issueDateFrom, issueDateTo, status } = data;

    const newFiltersToApply = {
      searchQuery: filter,
      From: issueDateFrom ? new Date(startOfDay(new Date(issueDateFrom))).toISOString() : '',
      To: issueDateTo ? new Date(endOfDay(new Date(issueDateTo))).toISOString() : '',
      StatusId: status.value !== '' ? (status.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
  }, [errorData, handleOpenErrorModal]);

  useEffect(() => {
    invoicesFiltersMethods.reset(getInvoicesFiltersFieldsDefaultValue(t('SelectStatus')));
  }, [t, invoicesFiltersMethods]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="sage-filtering">
        <FormProvider {...invoicesFiltersMethods}>
          <form onSubmit={invoicesFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="sage-page-search"
              placeHolder={SEARCH_INVOICES_PLACEHOLDER}
              handleClearFields={handleClearFields}
            >
              <FilterFormContentWithoutPermissions
                className="flex"
                fields={getInvoicesFilterFormFields(invoicesStatuses?.result.data)}
              />
            </FilterForm>
          </form>
        </FormProvider>
      </div>
      <div data-cy="invoices-tab" className="relative">
        <LoadingOverlay isLoading={isFetching} />
        <Table
          dataCy="invoices-table"
          columns={INVOICES_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!invoicesData?.result.data.totalCount}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {result?.data.invoices.map((item) => <InvoicesRow key={item.id} InvoiceData={item} />)}
        </Table>
        <Pagination
          totalCount={invoicesData?.result.data.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default Invoices;
