import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState, useMemo } from 'react';

import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { toast } from 'react-toastify';

import { BASE_ERL } from 'apis';
import { useLazyGetSupportTicketChatDetailsQuery } from 'apis/support-tickets.api';
import useIsAdmin from 'hooks/useUserRole';
import { UserChatMessageData, SendMessagePayload } from 'interfaces/support-tickets.interfaces';
import ChatNotification from 'page-components/support-tickets/chat/ChatNotification';
import { getCookie } from 'utils/auth';

type Context = {
  chatMessages: UserChatMessageData | null;
  isSendingMessage: boolean;
  sendMessage: (messageData: SendMessagePayload) => void;
};

const SupportRequestChatContext = createContext<Context>({
  chatMessages: null,
  isSendingMessage: false,
  sendMessage: () => {
    console.warn('No chat provider');
  },
});

const INITIAL_TRY_COUNT = 1;
const MAX_TRY_COUNT = 3;
const RECONNECT_TIME = 1000;

export const SupportRequestChatProvider = ({ children }: PropsWithChildren) => {
  const [chatHub, setChatHub] = useState<HubConnection | null>(null);
  const [isChatConnected, setIsChatConnected] = useState(false);
  const [chatMessages, setChatMessages] = useState<UserChatMessageData | null>(null);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const tries = useRef(INITIAL_TRY_COUNT);
  const reconnectTime = useRef(RECONNECT_TIME);

  const [getSupportTicketChatDetails] = useLazyGetSupportTicketChatDetailsQuery();
  const { isAdmin } = useIsAdmin();

  const configureConnection = (connection: HubConnection | null) => {
    setChatHub(connection);

    const startConnection = async () => {
      const start = () => {
        return new Promise<void>((resolve) => {
          if (tries.current > MAX_TRY_COUNT || !connection) {
            return;
          }

          connection
            .start()
            .then(() => {
              resolve();
              reconnectTime.current = RECONNECT_TIME;
              tries.current = INITIAL_TRY_COUNT;
              setIsChatConnected(true);
            })
            .catch(() => {
              setTimeout(() => {
                reconnectTime.current = reconnectTime.current * 2;
                tries.current = tries.current + 1;
                start();
              }, reconnectTime.current);
            });
        });
      };

      if (connection) {
        connection.onclose((e) => {
          setIsChatConnected(false);
          if (e) {
            console.debug('Chat connection closed with error: ' + e);
          } else {
            console.debug('Chat connection closed');
          }

          start();
        });
      }

      await start();
    };

    startConnection();

    if (connection) {
      registerChatEvents(connection);
    }
  };

  const sendMessage = useCallback(
    (messageData: SendMessagePayload) => {
      if (!isChatConnected) {
        console.warn('Chat is not connected');
        return;
      }

      setIsSendingMessage(true);

      if (!chatHub) {
        return;
      }

      chatHub
        .invoke('sendMessage', messageData)
        .then((result) => {
          if (result) {
            console.warn(result);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsSendingMessage(false);
        });
    },
    [chatHub, isChatConnected]
  );

  const registerChatEvents = (connection: HubConnection) => {
    connection.on('getChatMessage', async (messageData) => {
      setChatMessages({ ...messageData });

      if (messageData.receiverReadState === 2) {
        const chatDetails = await getSupportTicketChatDetails({
          ticketId: messageData.supportTicketId,
          isAdmin: isAdmin,
        }).unwrap();
        const { subject, chatCompanionFullName } = chatDetails.result || {};

        toast(
          <ChatNotification
            message={messageData.messageAttachmentName || messageData.message}
            subject={subject}
            chatName={chatCompanionFullName}
          />,
          {
            className: 'opacity-90',
            type: 'info',
          }
        );
      }
    });
  };

  useEffect(() => {
    const encAuthToken = getCookie('enc_auth_token');

    if (!encAuthToken) {
      return () => {
        connection.stop();
      };
    }

    const cleanedToken = encodeURIComponent(encAuthToken)
      .replace(/^(%3D)+/, '')
      .replace(/&negotiateVersion=1$/, '');
    const connectionUrl = `${BASE_ERL}signalr-chat?enc_auth_token=${cleanedToken}`;
    const connection = new HubConnectionBuilder().withUrl(connectionUrl).build();
    configureConnection(connection);
    return () => {
      connection.stop();
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      chatMessages,
      sendMessage,
      isSendingMessage,
    }),
    [chatMessages, sendMessage, isSendingMessage]
  );

  return <SupportRequestChatContext.Provider value={contextValue}>{children}</SupportRequestChatContext.Provider>;
};

export default SupportRequestChatContext;
