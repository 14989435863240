import { CommonResponse } from './shared.interface';

export enum INVOICES_COLUMNS {
  SUPPLIER_NAME = 'SupplierName',
  INVOICE_NUMBER = 'InvoiceNumber',
  TOTAL_AMOUNT = 'TotalAmount',
  ISSUED_DATE = 'IssuedDate',
  DUE_DATE = 'DueDate',
  ACTIONS = 'Actions',
}

export interface InvoicesShared {
  dueDate: string;
  invoiceNumber: string;
  issuedDate: string;
  supplierName: string;
  totalAmount: number;
}

export interface InvoicesResult extends InvoicesShared {
  id: number;
  tenantId: number;
}

export interface InvoicesFormValues {
  [INVOICES_FORM_FIELDS.ISSUED_DATE]: string;
  [INVOICES_FORM_FIELDS.DUE_DATE]: string;
  [INVOICES_FORM_FIELDS.SUPPLIER_NAME]: string;
  [INVOICES_FORM_FIELDS.INVOICE_NUMBER]: string;
  [INVOICES_FORM_FIELDS.TOTAL_AMOUNT]: number;
  [INVOICES_FORM_FIELDS.TOTAL_TAX]: number;
  [INVOICES_FORM_FIELDS.FILE_NAME]: string;
  [INVOICES_FORM_FIELDS.FILE_BASE]: string;
}

export interface InvoicesResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: InvoicesResult[];
  };
}

export enum INVOICES_FORM_FIELDS {
  ISSUED_DATE = 'issuedDate',
  DUE_DATE = 'dueDate',
  SUPPLIER_NAME = 'supplierName',
  INVOICE_NUMBER = 'invoiceNumber',
  TOTAL_AMOUNT = 'totalAmount',
  TOTAL_TAX = 'totalTax',
  FILE_NAME = 'fileName',
  FILE_BASE = 'fileBase64String',
}

export interface UploadInvoicePayload {
  dueDate: string;
  invoiceNumber: string;
  issuedDate: string;
  supplierName: string;
  totalAmount: number;
  totalTax: number;
  fileBase64String: string;
  fileName: string;
}

export interface InvoiceResponse extends CommonResponse {
  result: InvoiceInformation;
}

export interface InvoiceInformation {
  id: number;
  clientAddress: string;
  clientEmailAddress: string;
  clientName: string;
  clientPhoneNumber: string;
  displayFileName: string;
  supplierAddress: string;
  supplierEmailAddress: string;
  supplierName: string;
  supplierPhoneNumber: string;
  totalTax: number;
  uploadDateUtc: string;
  dueDate: string;
  invoiceNumber: string;
  issuedDate: string;
  tenantId: number;
  totalAmount: number;
}

export interface InvoiceLineItemsResponse {
  result: {
    totalCount: number;
    items: LineItems[];
  };
}

export interface LineItems {
  description: string;
  quantity: number;
  price: number;
  tax: number;
  amount: number;
}

export enum INVOICE_LINE_ITEMS_COLUMNS {
  DESCRIPTION = 'Description',
  QUANTITY = 'Quantity',
  PRICE = 'Price',
  AMOUNT = 'Amount',
  TAX = 'Tax',
  ACTIONS = '',
}
