import { useTwoFactorAuthenticatorQuery } from 'apis/auth.api';

const AppAuthenticatorGuide = () => {
  const { data: twoFactorContent, isLoading } = useTwoFactorAuthenticatorQuery();

  const { manualEntryKey, qrCodeSetupImageUrl } = twoFactorContent?.result || {};

  if (isLoading && !manualEntryKey) {
    return null;
  }

  return (
    <div data-cy="auth-app-qr-code" className="flex justify-around mt-4">
      <div>
        <img alt="qr code is broken" src={qrCodeSetupImageUrl} />
      </div>
      <div>
        <div className="pt-2">1. Download the authenticator app on your smartphone </div>
        <div className="pt-2">2. Scan this qr code with your smartphone or enter this code manually</div>
        <div data-cy="manual-entry-auth-app-key" className="mt-2 b bg-black text-white">
          {manualEntryKey}
        </div>
      </div>
    </div>
  );
};

export default AppAuthenticatorGuide;
