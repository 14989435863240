import { PROFIT_SHARE_LABELS } from 'apis/profit-share';

interface Props {
  stats?: number[];
}

export const ProfitShareLabels = ({ stats }: Props) => {
  if (!stats?.length) {
    return null;
  }

  return (
    <div className="flex-1 font-thin text-sm" data-cy="profit-share-labels">
      {PROFIT_SHARE_LABELS.map(({ id, label }, index) => (
        <p key={id} data-cy="profit-share-label">{`${stats[index]}% ${label}`}</p>
      ))}
    </div>
  );
};
