import { useTranslation } from 'react-i18next';

import { useOpen } from 'hooks/useOpen';
import Button from 'shared-components/button/Button';

import CreateDocumentTypeModal from './CreateDocumentTypeModal';

interface Props {
  refetchListDocument: VoidFunction;
}

const CreateDocumentType = ({ refetchListDocument }: Props) => {
  const { t } = useTranslation();
  const [isOpen, handleOpen, handleClose] = useOpen();
  return (
    <>
      <Button data-cy="create-document-type-button" onClick={handleOpen}>
        {t('Create')}
      </Button>
      <CreateDocumentTypeModal isOpen={isOpen} handleClose={handleClose} refetchListDocument={refetchListDocument} />
    </>
  );
};

export default CreateDocumentType;
