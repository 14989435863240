import { NO_DATA } from 'constants/common';
import { SupplierData } from 'interfaces/integrations/codat-accounting.interface';
import { TableData, TableRow } from 'shared-components';

interface Props {
  SupplierData: SupplierData;
}

const SuppliersRow = ({ SupplierData }: Props) => {
  const { name, contactName, emailAddress, registrationNumber } = SupplierData;

  return (
    <>
      <TableRow dataCy="suppliers-table-row">
        <TableData dataCy="suppliers-name">{name || NO_DATA}</TableData>
        <TableData dataCy="suppliers-contact-name">{contactName || NO_DATA}</TableData>
        <TableData dataCy="suppliers-email-address">{emailAddress || NO_DATA}</TableData>
        <TableData dataCy="suppliers-registration-number">{registrationNumber || NO_DATA}</TableData>
      </TableRow>
    </>
  );
};

export default SuppliersRow;
