import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePostCreateDocumentTypeMutation } from 'apis/typless.apis';
import { DOCUMENT_TYPE_FORM_FIELD } from 'constants/typless';
import { useOpen } from 'hooks/useOpen';
import {
  BaseCreateDocumentTypeFormValue,
  CreateDocumentTypePayload,
  DOCUMENT_TYPE_FORM_FIELDS,
} from 'interfaces/integrations/typless.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Modal, FormContent, ErrorModal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';
import { getDocumentTypeDetailsLink } from 'utils/url';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  refetchListDocument: VoidFunction;
}

const CreateDocumentTypeModal: React.FC<Props> = ({ isOpen, handleClose, refetchListDocument }) => {
  const { t } = useTranslation();
  const [isOpenErrorModal, handleOpenErrorModal, handleOnCloseErrorModal] = useOpen();
  const [createDocumentType, { isLoading, error: createDocumentTypeError }] = usePostCreateDocumentTypeMutation();
  const { error: errorRepaymentData } = (createDocumentTypeError as CommonError)?.data || {};
  const navigate = useNavigate();

  const { ...createDocumentTypeMethods } = useForm<BaseCreateDocumentTypeFormValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = createDocumentTypeMethods;

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const watchName = watch(DOCUMENT_TYPE_FORM_FIELDS.NAME);

  const onSubmit = async (data: BaseCreateDocumentTypeFormValue) => {
    if (!watchName) {
      return;
    }

    const payload: CreateDocumentTypePayload = {
      name: data[DOCUMENT_TYPE_FORM_FIELDS.NAME],
    };

    try {
      const response = await createDocumentType(payload).unwrap();
      refetchListDocument();
      onCloseModal();
      successNotify(t('SuccessfullyCreated'));
      const documentId = response.result;
      navigate(getDocumentTypeDetailsLink(documentId.toString()));
    } catch (error) {
      handleOpenErrorModal();
      console.error(error);
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorRepaymentData?.message}
        description={errorRepaymentData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
      <Modal
        dataCy="create-document-type-modal"
        isOpen={isOpen}
        isDisableOutsideClick
        onClose={onCloseModal}
        heading="CreateDocumentType"
        bodyClassName="md:w-1/2 lg:w-1/3 xl:w-1/4"
      >
        <FormProvider {...createDocumentTypeMethods} reset={reset}>
          <form data-cy="create-document-type-modal-form" onSubmit={handleSubmit(onSubmit)}>
            <FormContent
              isLoading={isLoading}
              fields={DOCUMENT_TYPE_FORM_FIELD()}
              register={register}
              errors={errors}
            />
            <ModalControlButtons
              disabled={!watchName || isLoading}
              isShownSubmit
              isLoading={isLoading}
              onClose={onCloseModal}
              saveButtonText={'Create'}
            />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CreateDocumentTypeModal;
