import { DashboardWidgets } from '../page-components/dashboard/widgets/DashboardWidgets';

const Dashboard = () => {
  return (
    <div data-cy="dashboard-welcome-page">
      <DashboardWidgets />
    </div>
  );
};

export default Dashboard;
