import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useGetSubscriptionQuery,
  useCreatePaymentMutation,
  useGetActiveGatewaysQuery,
} from 'apis/subscriptions-tenant.api';
import { ROUTES } from 'constants/routes';
import { SHARED_MODAL_BUTTON_STYLES, SHARED_MODAL_BUTTON_WRAPPER_STYLES } from 'constants/subscription-tenant';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { CreatePaymentPayload } from 'interfaces/subscriptions-tenant.interface';
import LabelComponent from 'page-components/subscriptions-tenant/modal/subscription-purchase/LabelComponent';
import TenantSubscriptionModal from 'page-components/subscriptions-tenant/modal/TenantSubscriptionModal';
import { Button, Checkbox, ErrorModal } from 'shared-components';
import { getSubscriptionStripePurchase } from 'utils/url';

const SubscriptionPurchase = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const backButtonRedirect = () =>
    navigate(location.state?.backButtonRedirect || ROUTES.tenantSubscription, {
      state: { isBackButton: true },
    });

  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);

  const searchParams = new URLSearchParams(location.search);
  const subscriptionId = searchParams.get('subscriptionId');

  const {
    data: subscriptionData,
    isLoading: isGetSubscriptionQueryLoading,
    error: getSubscriptionQueryError,
  } = useGetSubscriptionQuery(`${subscriptionId}`);
  const {
    data: activeGatewayData,
    isLoading: isGetActiveGatewaysLoading,
    error: getActiveGatewaysError,
  } = useGetActiveGatewaysQuery();

  const isLoading = isGetSubscriptionQueryLoading || isGetActiveGatewaysLoading;

  const [createPayment, { error: createPaymentError }] = useCreatePaymentMutation();

  const createPaymentErrorData = (createPaymentError as CommonError)?.data || {};
  const getSubscriptionQueryErrorData = (getSubscriptionQueryError as CommonError)?.data || {};
  const getActiveGatewaysErrorData = (getActiveGatewaysError as CommonError)?.data || {};

  const errorData = createPaymentErrorData || getSubscriptionQueryErrorData || getActiveGatewaysErrorData;

  const subscriptionPlans = subscriptionData?.result?.subscriptionPlans || [];

  const handleCheckboxChange = (planId: number) => {
    setSelectedPlanId(planId === selectedPlanId ? null : planId);
  };

  const onSubmit = async (subscriptionPaymentGatewayType: number, targetSubscriptionPlanId: number) => {
    if (!selectedPlanId || !activeGatewayData) {
      handleOpenErrorModal();
      return;
    }

    const payload: CreatePaymentPayload = {
      subscriptionPaymentGatewayType: subscriptionPaymentGatewayType,
      targetSubscriptionPlanId: targetSubscriptionPlanId,
    };

    try {
      const response = await createPayment(payload).unwrap();
      const paymentId = response?.result;

      if (paymentId) {
        navigate(getSubscriptionStripePurchase(paymentId));
        return;
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="subscription-purchase-page" className="p-6">
        <TenantSubscriptionModal
          dataCy="payment-info-subscription-modal"
          isPaymentInfo
          isBackButton
          heading="PaymentInfo"
          isLoading={isLoading}
          onClose={() => backButtonRedirect()}
          endChild={
            !isLoading &&
            !!subscriptionPlans.length && (
              <div className={SHARED_MODAL_BUTTON_WRAPPER_STYLES}>
                <Button
                  dataCy="checkout-button"
                  disabled={!selectedPlanId}
                  isLoading={isLoading}
                  className={SHARED_MODAL_BUTTON_STYLES}
                  onClick={() => onSubmit(activeGatewayData?.result.gatewayType ?? 1, selectedPlanId ?? 0)}
                >
                  {t('CheckoutWithStripe')}
                </Button>
              </div>
            )
          }
        >
          {!isLoading && subscriptionPlans.length ? (
            <>
              <div className="text-center mb-6">{`${t('Subscription')}: ${subscriptionData?.result.displayName}`}</div>
              {subscriptionPlans.map((plan) => {
                return (
                  <div key={plan.id} className="mb-6">
                    {!!plan.price && (
                      <Checkbox
                        dataCy="checkbox"
                        label={<LabelComponent price={plan.price} periodId={plan.periodId} />}
                        onChange={() => handleCheckboxChange(plan.id)}
                        checked={selectedPlanId === plan.id}
                      />
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <h2> {t('CannotFindSubscriptionPlan')} </h2>
          )}
        </TenantSubscriptionModal>
      </div>
    </>
  );
};

export default SubscriptionPurchase;
