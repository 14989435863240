import { ReactNode, useRef } from 'react';

import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { BackButton, Skeleton } from 'shared-components';
import { cn } from 'utils/global';

export interface Props {
  children: ReactNode;
  onClose?: VoidFunction;
  isLoading?: boolean;
  isPaymentInfo?: boolean;
  heading: string;
  headingClassName?: string;
  endChild?: ReactNode;
  dataCy?: string;
  isBackButton?: boolean;
  bodyClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
}

const TenantSubscriptionModal = ({
  children,
  onClose,
  isLoading,
  isPaymentInfo,
  heading,
  endChild,
  dataCy,
  isBackButton,
  headingClassName,
  bodyClassName,
  contentClassName,
  wrapperClassName,
}: Props) => {
  const modalRef = useRef(null);
  const portalElement = document.getElementById('modal-root'!);
  const { t } = useTranslation();

  return createPortal(
    <>
      <div
        data-cy={dataCy}
        className={
          'justify-center dark:!bg-darkBlue4 !bg-lightGray3 flex overflow-x-hidden overflow-y-auto items-baseline sm:items-center fixed inset-0 z-40 sm:p-3 p-0 bg-transparent'
        }
      >
        <div
          className={cn('relative my-0 min-w-min self-center shadow-lg', bodyClassName, {
            'sm:my-6 sm:w-11/12 md:w-3/5 w-full sm:min-h-fit min-h-full self-auto min-w-0 shadow-none': !isPaymentInfo,
          })}
        >
          <div
            className={cn(
              'shadow-none border-b-0 rounded-md relative flex flex-col dark:bg-darkBlue bg-white outline-none focus:outline-none px-0 sm:px-1 h-full p-3',
              { 'shadow-lg border-0': !isPaymentInfo }
            )}
            ref={modalRef}
          >
            <div className={cn('block justify-between pb-4 sm:rounded-md rounded-none items-center', wrapperClassName)}>
              {isBackButton && (
                <>
                  <div>
                    <BackButton onClick={onClose} />
                  </div>
                </>
              )}
              <h3
                data-cy="heading"
                className={cn(
                  'text-center sm:text-2xl text-xl dark:text-white text-darkGray my-4 px-[0.625rem]',
                  headingClassName
                )}
              >
                {t(heading)}
              </h3>
            </div>
            <div
              data-cy="content"
              className={cn('my-4 block dark:text-white text-darkGray relative px-[0.625rem]', contentClassName)}
            >
              {isLoading ? <Skeleton className="w-full h-64" /> : children}
            </div>
          </div>

          {endChild}
        </div>
      </div>
    </>,
    portalElement!
  );
};

export default TenantSubscriptionModal;
