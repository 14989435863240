import { ReactComponent as CheckMarkSvg } from 'assets/svg/check-mark.svg';
import { ReactComponent as ErrorTriangleSvg } from 'assets/svg/error-triangle.svg';
import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { AuditLog } from 'interfaces/integrations/os-audit-logs.interface';
import { DropdownMenu, Modal, TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

import OperationLogDetailsModalContent from './OperationLogDetailsModalContent';

type Props = {
  logData: AuditLog;
};

const OperationLogsRow = ({ logData }: Props) => {
  const [isOpenDetailsModal, handleOpenDetailsModal, handleCloseDetailsModal] = useOpen();

  const { userEmail, serviceName, methodName, executionDuration, browserInfo, executionTime, exception } = logData;

  const menuActions = [
    {
      dataCy: 'menu-item-details-button',
      name: 'ShowDetails',
      actionOnClick: handleOpenDetailsModal,
    },
  ];

  return (
    <>
      <Modal
        dataCy="operation-log-details-modal"
        heading="Details"
        isOpen={isOpenDetailsModal}
        onClose={handleCloseDetailsModal}
      >
        <OperationLogDetailsModalContent onClose={handleCloseDetailsModal} {...logData} />
      </Modal>

      <TableRow dataCy="operation-logs-table-row">
        <TableData className="custom-svg-icon">{exception ? <ErrorTriangleSvg /> : <CheckMarkSvg />}</TableData>
        <TableData dataCy="table-data-operation-logs-user-email">{userEmail}</TableData>
        <TableData>{`${serviceName}/${methodName}`}</TableData>
        <TableData>{executionDuration}</TableData>
        <TableData>{browserInfo.slice(0, 17)}...</TableData>
        <TableData>{getFormattedDate(executionTime, DAY_FORMAT_WITH_TIME)}</TableData>
        <TableData>
          <DropdownMenu elements={menuActions} />
        </TableData>
      </TableRow>
    </>
  );
};

export default OperationLogsRow;
