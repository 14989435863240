import { useParams } from 'react-router-dom';

import SageDetailsLayout from 'page-components/integrations/sage/details/SageDetailsLayout';

interface CypressProps {
  invoiceId?: string;
  contactId?: string;
}
const SageInvoiceDetailsPage = ({ invoiceId: propInvoiceId, contactId: propContactId }: CypressProps) => {
  const { invoiceId: routeInvoiceId, contactId: routeContactId } = useParams();
  const invoiceId = propInvoiceId || routeInvoiceId;
  const contactId = propContactId || routeContactId;

  return (
    <>
      <SageDetailsLayout contactId={contactId || ''} invoiceId={invoiceId || ''} />
    </>
  );
};

export default SageInvoiceDetailsPage;
