import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetEmailTemplateByIdQuery, useUpdateTemplateMutation } from 'apis/email-templates.api';
import { DEFAULT_EMAIL_TEMPLATE_FORM_VALUES } from 'constants/email-templates';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { EmailTemplateFormValues, TemplateVariablesValues } from 'interfaces/email-templates.interface';
import { CommonError } from 'interfaces/shared.interface';
import EmailTemplateForm from 'page-components/email-templates/template-form/EmailTemplateForm';
import { ErrorModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

const EditEmailTemplate = () => {
  const { emailTemplateId } = useParams();
  const navigate = useNavigate();

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { data, isLoading: isGetEmailTemplateLoading } = useGetEmailTemplateByIdQuery(emailTemplateId);
  const [updateTemplate, { error, isLoading: isUpdateTemplateLoading }] = useUpdateTemplateMutation();

  const { result: templateFormData } = data || {};
  const { data: errorData } = (error as CommonError) || {};

  const editEmailTemplateMethods = useForm<EmailTemplateFormValues>({
    defaultValues: DEFAULT_EMAIL_TEMPLATE_FORM_VALUES,
  });

  const onSubmit = async (data: EmailTemplateFormValues, field: TemplateVariablesValues[]) => {
    const payload = {
      templateName: data.templateName,
      subject: data.subject,
      body: data.textEditor,
      variableNames: field.map((field) => field.value),
      id: emailTemplateId,
    };

    try {
      await updateTemplate(payload).unwrap();
      navigate(ROUTES.emailTemplates);
      if (!isUpdateTemplateLoading) {
        successNotify('SavedSuccessfully');
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <div data-cy="edit-email-template-page">
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />

      <FormProvider {...editEmailTemplateMethods}>
        <EmailTemplateForm
          isLoading={isUpdateTemplateLoading || isGetEmailTemplateLoading}
          onSubmit={onSubmit}
          formData={templateFormData}
        />
      </FormProvider>
    </div>
  );
};

export default EditEmailTemplate;
