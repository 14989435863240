import { FormItem } from 'interfaces/shared.interface';
import { UPDATE_TENANT_NAME_FORM_FIELD } from 'interfaces/tenant-details.interface';
import { Input } from 'shared-components';

export const UPDATE_TENANT_NAME_FORM_FIELDS: FormItem[] = [
  {
    name: UPDATE_TENANT_NAME_FORM_FIELD.DISPLAY_NAME,
    inputClassName: 'text-sm',
    labelClassName: 'text-sm',
    component: Input,
    className: '',
    label: 'TenantName',
    dataCy: 'tenant-name-input',
    validation: {
      required: 'FieldRequired',
    },
  },
];
