import { useEffect } from 'react';

import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { DOCUMENT_TYPE_DETAILS_FORM_FIELD } from 'constants/typless';
import { BaseTrainDocumentTypeFormValue, ParseDocumentResponse } from 'interfaces/integrations/typless.interface';
import { FormContent } from 'shared-components';

type Props = {
  onUpdate: (data: BaseTrainDocumentTypeFormValue) => void;
  onValidityChange: (isValid: boolean) => void;
  parseData: ParseDocumentResponse | undefined;
};

const DocumentDetailsFormInfo = ({ onUpdate, onValidityChange, parseData }: Props) => {
  const { ...trainDocumentTypeMethods } = useForm<BaseTrainDocumentTypeFormValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    reset,
    control,
    formState: { errors, isValid },
  } = trainDocumentTypeMethods;

  const formValues = useWatch({
    control,
  });

  const generateDataObject = (data: any): BaseTrainDocumentTypeFormValue => ({
    supplierName: data?.supplierName || '-',
    supplierAddress: data?.supplierAddress || '-',
    supplierEmailAddress: data?.supplierEmailAddress || '-',
    supplierPhoneNumber: data?.supplierPhoneNumber || '-',
    clientName: data?.clientName || '-',
    clientAddress: data?.clientAddress || '-',
    clientEmailAddress: data?.clientEmailAddress || '-',
    clientPhoneNumber: data?.clientPhoneNumber || '-',
    invoiceNumber: data?.invoiceNumber || '-',
    invoiceCreatedDate: data?.invoiceCreatedDate || data?.issuedDate || '-',
    invoiceDueDate: data?.invoiceDueDate || data?.dueDate || '-',
    totalAmount: data?.totalAmount || 0,
    totalVAT: data?.totalVAT || data?.totalTax || 0,
  });

  useEffect(() => {
    if (parseData) {
      reset(generateDataObject(parseData.result));
    }
  }, [parseData, reset]);

  useEffect(() => {
    onUpdate(generateDataObject(formValues));
    onValidityChange(isValid);
  }, [formValues, isValid]);

  return (
    <FormProvider {...trainDocumentTypeMethods} reset={reset}>
      <form data-cy="create-document-type-modal-form">
        <FormContent
          fields={DOCUMENT_TYPE_DETAILS_FORM_FIELD()}
          register={register}
          control={control}
          errors={errors}
        />
      </form>
    </FormProvider>
  );
};

export default DocumentDetailsFormInfo;
