import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetConnectionDetailsQuery, usePostFetchAndSaveAccessTokenMutation } from 'apis/truelayer.api';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import TrueLayerConnectionHandler from 'page-components/integrations/truelayer/TrueLayerConnectionHandler';
import { ErrorModal, LoadingOverlay } from 'shared-components';
import { successNotify } from 'utils/notifications';

const TrueLayer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    data: connectionData,
    isLoading: isLoadingGetConnection,
    isFetching,
    error: getConnectionError,
    refetch,
  } = useGetConnectionDetailsQuery();

  const [postFetchAndSaveAccessToken, { isLoading: isLoadingPostFetch, error: postFetchError }] =
    usePostFetchAndSaveAccessTokenMutation();

  const isLoading = isLoadingPostFetch || isLoadingGetConnection;

  const { data: postFetchErrorData } = (postFetchError as CommonError) || {};
  const { data: getConnectionErrorData } = (getConnectionError as CommonError) || {};

  const [params] = useSearchParams();
  const code = params.get('code');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (code) {
          const redirectUri = window.location.origin + ROUTES.trueLayer;
          await postFetchAndSaveAccessToken({ code, redirectUri }).unwrap();
          refetch();
          navigate(ROUTES.trueLayer);
          successNotify(t('SuccessfullyConnectedOpenBanking'));
        }
      } catch (error) {
        handleOpenErrorModal();
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ErrorModal
        errorMessage={postFetchErrorData?.error?.message || getConnectionErrorData?.error?.message}
        description={postFetchErrorData?.error?.details || getConnectionErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div className="relative" data-cy="truelayer-page">
        <LoadingOverlay isLoading={isFetching && !!connectionData} />
        {!isLoading && !isFetching ? (
          <TrueLayerConnectionHandler
            connectionData={connectionData}
            isConnect={connectionData?.result.isConnected || false}
            triggerRefetch={refetch}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default TrueLayer;
