import {
  GetAuthorizationXeroUrlResponse,
  GetConnectionXeroDetailsResponse,
  GetContactsResponce,
  GetInvoicesResponse,
} from 'interfaces/integrations/xero.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const xeroApi = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getAuthorizationXeroUrl: build.query<GetAuthorizationXeroUrlResponse, void>({
      query: () => ({
        url: `Xero/GetAuthorizationUrl`,
        method: 'GET',
      }),
    }),
    getConnectionXeroDetails: build.query<GetConnectionXeroDetailsResponse, void>({
      query: () => ({
        url: `Xero/GetConnectionDetails`,
        method: 'GET',
      }),
    }),
    postAuthenticationToken: build.mutation<CommonResponse, { code?: string; redirectUri?: string }>({
      query: ({ code, redirectUri }) => ({
        url: `Xero/ProcessAuthenticationToken?code=${code}&redirectUri=${redirectUri}`,
        method: 'POST',
        data: { code, redirectUri },
      }),
    }),
    getXeroInvoices: build.query<GetInvoicesResponse, void>({
      query: () => ({
        url: `Xero/GetInvoices`,
        method: 'GET',
      }),
    }),
    getXeroContacts: build.query<GetContactsResponce, void>({
      query: () => ({
        url: `Xero/GetContacts`,
        method: 'GET',
      }),
    }),
    postDisconnectXeroAccount: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `Xero/DisconnectAccount`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAuthorizationXeroUrlQuery,
  useGetConnectionXeroDetailsQuery,
  usePostAuthenticationTokenMutation,
  useGetXeroInvoicesQuery,
  useGetXeroContactsQuery,
  usePostDisconnectXeroAccountMutation,
} = xeroApi;
