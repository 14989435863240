import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { user } from 'apis/user.api';
import { ProfileSettings } from 'interfaces/profile-settings.interface';
import { UserState } from 'interfaces/user.interface';

import { setProfilePictureId } from './action';

export function setProfilePictureReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addCase(setProfilePictureId, (state, { payload }) => {
    state.profilePictureId = payload;
  });
}

export function getUserDetailsReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addMatcher(
    user.endpoints.getCurrentUserDetails.matchFulfilled,
    (state, { payload }: PayloadAction<ProfileSettings>) => {
      const { user, roles, profilePictureId } = payload;

      state.data = user;
      state.roles = roles;
      state.profilePictureId = profilePictureId || null;
    }
  );
}

export function getUserConfigReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addMatcher(user.endpoints.getUserConfig.matchFulfilled, (state, { payload }) => {
    const { auth, features, localization } = payload?.result;

    state.localization = localization;
    state.permissions = {
      isManagingUsersAccess: features?.allFeatures['App.ManagingUsers']?.value === 'true',
      grantedPermissions: auth?.grantedPermissions,
    };
  });
}

export function getCurrentUserLoginReducer(builder: ActionReducerMapBuilder<UserState>) {
  builder.addMatcher(user.endpoints.getCurrentLoginInformations.matchFulfilled, (state, { payload }) => {
    if (!payload) {
      return;
    }

    const { tenant } = payload;

    state.tenantId = tenant?.id;
  });
}
