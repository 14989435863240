import { Modal } from 'shared-components';

import TenantEditModalContent from './TenantEditModalContent';

interface Props {
  handleClose: VoidFunction;
  isOpen: boolean;
  refetchTenants: VoidFunction;
  tenantId: number | string;
}
const TenantEditModal = ({ tenantId, handleClose, isOpen, refetchTenants }: Props) => {
  return (
    <Modal heading="EditTenant" dataCy="tenant-settings-modal" isOpen={isOpen} onClose={handleClose}>
      <TenantEditModalContent handleClose={handleClose} refetchTenants={refetchTenants} tenantId={tenantId} />
    </Modal>
  );
};

export default TenantEditModal;
