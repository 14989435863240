import { FC, ReactNode } from 'react';

import { cn } from 'utils/global';

interface Props {
  children: ReactNode;
  className?: string;
  dataCy?: string;
}

const ErrorMessage: FC<Props> = ({ children, className, dataCy }) => {
  return (
    <p data-cy={dataCy} className={cn('mt-1 text-red-500 text-xs', className)}>
      {children}
    </p>
  );
};

export default ErrorMessage;
