import { useParams } from 'react-router-dom';

import { useGetCustomerQuery } from 'apis/quickBooks.api';
import CustomerDetails from 'page-components/integrations/quickBooks/customers/CustomerDetails';
import AppContentLayout from 'shared-components/AppContentLayout';

const CustomerDetailsPage = () => {
  const { customerId } = useParams();

  const {
    data: customerData,
    isLoading: customerLoading,
    isFetching: customerFetching,
  } = useGetCustomerQuery(customerId);

  return (
    <div data-cy="customers-details-page">
      <AppContentLayout title="CustomerDetails" headerClassName="text-3xl text-darkGray dark:text-white">
        <div className="relative">
          <CustomerDetails data={customerData?.result.data} isLoading={customerLoading} fetching={customerFetching} />
        </div>
      </AppContentLayout>
    </div>
  );
};

export default CustomerDetailsPage;
