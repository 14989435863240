import { format } from 'date-fns';

import { DAY_FORMAT_WITH_TIME_WITH_SLASHES } from 'constants/global';
import { RecentTenant } from 'interfaces/tenants.interface';
import { TableData, TableRow } from 'shared-components';

interface Props {
  tenant: RecentTenant;
}

export const RecentTenantsTableRow = ({ tenant: { name, creationTime } }: Props) => {
  const creationTimeFormatted = format(creationTime, DAY_FORMAT_WITH_TIME_WITH_SLASHES);

  return (
    <TableRow dataCy="recent-tenants-table-row">
      <TableData>{name}</TableData>
      <TableData>{creationTimeFormatted}</TableData>
    </TableRow>
  );
};
