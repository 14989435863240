import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { PREVIOUS_COMPANY_NAMES_TABLE_COLUMNS } from 'constants/companies-house';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { useTableSorting } from 'hooks/useTableSorting';
import { CompaniesHouseDetailsResponse } from 'interfaces/integrations/companies-house.interface';
import { LabelWithValue, Table } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { cn } from 'utils/global';

import PreviousCompanyNamesRow from './PreviousCompanyNamesRow';

interface LabelValue {
  label: string;
  value: string | null | ReactNode | undefined;
  valueClassName?: string;
  labelClassName?: string;
  bodyClassName?: string;
}

interface Props {
  companyDetailsResponse?: CompaniesHouseDetailsResponse | null;
  data: LabelValue[];
  isLoading: boolean;
}

const LabelWithValueRender = ({ data }: Props) => {
  return (
    <>
      {data.map(({ label, value, valueClassName, labelClassName, bodyClassName }) => (
        <LabelWithValue
          key={label}
          withColon={false}
          bodyClassName={cn('block', bodyClassName)}
          valueClassName={cn('pt-2', valueClassName)}
          labelClassName={cn('pt-2', labelClassName)}
          label={label}
          value={value || ''}
          isLoading={false}
        />
      ))}
    </>
  );
};

const CompanyOverview = ({ companyDetailsResponse, isLoading }: Props) => {
  const { t } = useTranslation();

  const { handleSetTabName } = useTableSorting();

  const renderAddress = () => {
    const address = companyDetailsResponse?.result?.registeredOfficeAddress;
    if (!address) {
      return null;
    }
    return (
      <div className="w-full">
        <LabelWithValue
          withColon={false}
          bodyClassName="block"
          label="RegisteredOfficeAddress"
          value={`${address.addressLine1}, ${address.addressLine2}, ${address.locality}, ${address.country}, ${address.postalCode}`}
          isLoading={isLoading}
        />
      </div>
    );
  };

  const renderCompanyStatus = () => {
    const statusText = companyDetailsResponse?.result?.companyStatusText;
    if (!statusText) {
      return null;
    }
    return (
      <div className="w-full">
        <LabelWithValue
          withColon={false}
          bodyClassName="block"
          labelClassName="pt-2"
          label="CompanyStatus"
          value={statusText}
          isLoading={isLoading}
        />
      </div>
    );
  };

  const renderDissolvedOn = () => {
    const statusText = companyDetailsResponse?.result?.companyStatusText;
    if (statusText === 'Dissolved') {
      const dateOfCessation = companyDetailsResponse?.result?.dateOfCessation;
      return getFormattedDate(dateOfCessation, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES);
    }
    return null;
  };

  const renderCompanyType = () => {
    return companyDetailsResponse?.result?.type || null;
  };

  const renderIncorporatedOn = () => {
    const dateOfCreation = getFormattedDate(
      companyDetailsResponse?.result?.dateOfCreation,
      DAY_MONTH_YEAR_FORMAT_WITH_SLASHES
    );
    return dateOfCreation || null;
  };

  const renderAccounts = () => {
    const accounts = companyDetailsResponse?.result?.accounts;
    if (!accounts || !accounts.nextMadeUpTo || !accounts.nextDue) {
      return null;
    }

    const nextAccounts = `${t('NextAccountsMadeUp', {
      0: getFormattedDate(accounts.nextMadeUpTo, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
      1: getFormattedDate(accounts.nextDue, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    })}`;

    const lastAccounts = accounts.companyProfileLastAccounts?.madeUpTo
      ? ` ${t('LastAccountsMadeUp', {
          0: getFormattedDate(accounts.companyProfileLastAccounts?.madeUpTo, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
        })}`
      : '';

    return nextAccounts + lastAccounts;
  };

  const renderConfirmationStatement = () => {
    const confirmationStatement = companyDetailsResponse?.result?.confirmationStatement;
    if (!confirmationStatement) {
      return null;
    }
    return `${t('NextStatementDate', {
      0: getFormattedDate(confirmationStatement.nextMadeUpTo, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
      1: getFormattedDate(confirmationStatement.nextDue, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    })} ${t('LastAccountsMadeUp', {
      0: getFormattedDate(confirmationStatement.lastMadeUpTo, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    })}`;
  };

  const renderBusinessNature = () => {
    const { result } = companyDetailsResponse || {};
    const sicCodes = result?.sicCodes;
    if (!Array.isArray(sicCodes)) {
      return null;
    }
    return sicCodes.join('\n');
  };

  const renderPreviousCompanyNames = () => {
    const previousCompanyNames = companyDetailsResponse?.result?.previousCompanyNames;
    if (!Array.isArray(previousCompanyNames) || !previousCompanyNames.length) {
      return null;
    }

    return (
      <div className="text-2xl font-bold pb-2 w-full whitespace-pre-line pt-2">
        {t('PreviousCompanyNames')}
        <Table
          dataCy="previous-company-names-table"
          columns={PREVIOUS_COMPANY_NAMES_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          isTableEmpty={!previousCompanyNames?.length}
          isLoading={isLoading}
        >
          {previousCompanyNames.map((item) => (
            <PreviousCompanyNamesRow key={uuidv4()} companyData={item} />
          ))}
        </Table>
      </div>
    );
  };

  const data: LabelValue[] = [
    { label: 'CompanyType', value: renderCompanyType() },
    { label: 'Accounts', value: renderAccounts() },
    { label: 'BusinessNature', value: renderBusinessNature(), valueClassName: 'whitespace-pre-line' },
    { label: 'DissolvedOn', value: renderDissolvedOn() },
    { label: 'IncorporatedOn', value: renderIncorporatedOn() },
    { label: 'ConfirmationStatement', value: renderConfirmationStatement() },
  ];

  return (
    <div className="relative w-full flex flex-wrap">
      {renderAddress()}
      {renderCompanyStatus()}
      <div className="sm:w-1/2">
        <LabelWithValueRender data={data.slice(0, Math.ceil(data.length / 2))} isLoading={isLoading} />
      </div>
      <div className="sm:w-1/2">
        <LabelWithValueRender data={data.slice(Math.ceil(data.length / 2))} isLoading={isLoading} />
      </div>
      {renderPreviousCompanyNames()}
    </div>
  );
};
export default CompanyOverview;
