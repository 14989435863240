import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BaseFilterValues, CommonResponse, OptionType } from 'interfaces/shared.interface';

export interface GetAuthUrlResponse extends CommonResponse {
  result?: string;
}

export interface PostConnectedResponse extends CommonResponse {
  result: boolean;
}

export interface RecipientsDetails {
  name: string;
  email: string;
}

export interface DocumentsDetails {
  documentId: string;
  name: string;
}

export interface SigningRequestsDetails {
  envelopeId: string;
  recipients: RecipientsDetails[];
  documents: DocumentsDetails[];
  status: number;
  createdDate: string;
  statusChangedDate: string;
  isSigned: boolean;
}

export interface GetSigningRequestsResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: SigningRequestsDetails[];
  };
}

export enum DOCUSIGN_FILTERS_FORM_FIELD_NAMES {
  CREATION_DATE_FROM = 'creationStartDate',
  CREATION_DATE_TO = 'creationEndDate',
  STATUS = 'status',
}

export interface DocusignFilterFormValues extends BaseFilterValues {
  [DOCUSIGN_FILTERS_FORM_FIELD_NAMES.CREATION_DATE_FROM]: string;
  [DOCUSIGN_FILTERS_FORM_FIELD_NAMES.CREATION_DATE_TO]: string;
  [DOCUSIGN_FILTERS_FORM_FIELD_NAMES.STATUS]?: OptionType;
}

export enum DOCUSIGN_COLUMNS {
  CREATION_DATE = 'creationDate',
  SIGNING_DATE = 'signingDate',
  DOCUMENT_NAME = 'documentName',
  RECIPIENTS = 'Recipients',
  STATUS = 'status',
  ACTION = 'action',
}

export enum DOCUSIGN_REQUEST_FORM_FIELDS {
  DOCUMENT_NAME = 'documentName',
  EMAIL_SUBJECT = 'emailSubject',
  EMAIL_MESSAGE = 'emailMessage',
}

export interface BaseCreateRequestFormValue {
  [DOCUSIGN_REQUEST_FORM_FIELDS.DOCUMENT_NAME]: string;
  [DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_SUBJECT]: string;
  [DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_MESSAGE]: string;
}

export interface DocumentDetails {
  base64Content: string;
  fileType: string;
  name: string;
}

export interface Signer {
  fullName: string;
  email: string;
  orderingNumber: number;
  placements: Placement[];
}

export interface Placement {
  placementType: number;
  pageNumber: number;
  signXPosition: number;
  signYPosition: number;
}

export interface DocusignRequestPayload {
  documentDetails: DocumentDetails;
  emailNotificationSubject: string;
  emailNotificationMessage: string;
  sendInOrder: boolean;
  signers: Signer[];
}

export enum DOCUSIGN_SIGNERS_FORM_FIELDS {
  SIGNERS_NAME = 'signers-name',
  SIGNERS_EMAIL = 'signers-email',
}
export interface BaseAddSignersFormValue {
  [DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_NAME]: string;
  [DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_EMAIL]: string;
}

export interface SignerBlock {
  id: string | number;
  isActive: boolean;
  color: string;
  email: string;
  fullName: string;
}
export interface DroppedItem {
  icon: IconProp;
  label: string;
  x: number;
  y: number;
  pageNumber: number;
  borderColor: string;
  blockId: number;
  id: string | number;
  placementType: number;
}

export interface DocusignDownloadDocument {
  documentId: string;
  envelopeId: string;
}
