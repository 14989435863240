import { FieldTypes, TableColumn } from 'interfaces/shared.interface';
import { USERS_COLUMNS, USERS_FILTERS_FORM_FIELD_NAMES } from 'interfaces/users.interface';
import { SelectFormInput } from 'shared-components';

import { BASE_FILTER_FIELDS, BASE_PERMISSIONS_FROM_FIELD_NAME } from './common';

export const USERS_COLUMNS_TITLES = {
  [USERS_COLUMNS.FULL_NAME]: 'FullName',
  [USERS_COLUMNS.ROLES]: 'Roles',
  [USERS_COLUMNS.EMAIL_ADDRESS]: 'EmailAddress',
  [USERS_COLUMNS.IS_EMAIL_CONFIRMED]: 'EmailConfirm',
  [USERS_COLUMNS.IS_ACTIVE]: 'Active',
  [USERS_COLUMNS.IS_LOCKED]: 'Locked',
  [USERS_COLUMNS.CREATION_TIME]: 'CreatedDate',
  [USERS_COLUMNS.LAST_LOGIN_TIME]: 'LastLogin',
  [USERS_COLUMNS.ACTIONS]: '',
};

export const USERS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: USERS_COLUMNS.FULL_NAME,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.FULL_NAME],
    width: 'w-3/12',
    sorting: true,
  },
  {
    columnId: USERS_COLUMNS.ROLES,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.ROLES],
    width: 'w-2/12',
  },
  {
    columnId: USERS_COLUMNS.EMAIL_ADDRESS,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.EMAIL_ADDRESS],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: USERS_COLUMNS.IS_EMAIL_CONFIRMED,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.IS_EMAIL_CONFIRMED],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: USERS_COLUMNS.IS_ACTIVE,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.IS_ACTIVE],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: USERS_COLUMNS.IS_LOCKED,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.IS_LOCKED],
    width: 'w-1/12',
  },
  {
    columnId: USERS_COLUMNS.CREATION_TIME,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.CREATION_TIME],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: USERS_COLUMNS.LAST_LOGIN_TIME,
    title: USERS_COLUMNS_TITLES[USERS_COLUMNS.LAST_LOGIN_TIME],
    width: 'w-2/12',
  },
  { columnId: USERS_COLUMNS.ACTIONS, title: USERS_COLUMNS_TITLES[USERS_COLUMNS.ACTIONS] },
];

export const SEARCH_USERS_PLACEHOLDER = 'SearchByFullNameOrEmailAddress';

const SHARED_FILTERS_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const USERS_FILTERS_FORM_FIELDS = [
  {
    name: USERS_FILTERS_FORM_FIELD_NAMES.IS_USER_LOCKED,
    label: 'ShowLockedUsers',
    className: 'ml-10 w-30',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-locked-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
  {
    name: USERS_FILTERS_FORM_FIELD_NAMES.IS_USER_ACTIVE,
    label: 'IsActive',
    className: 'ml-10 w-30',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-active-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
  {
    name: USERS_FILTERS_FORM_FIELD_NAMES.IS_EMAIL_CONFIRMED,
    label: 'IsEmailConfirmed',
    className: 'ml-10 w-30',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-email-confirmed-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
];

export const getUsersFiltersFieldsDefaultValues = (selectSharedLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [USERS_FILTERS_FORM_FIELD_NAMES.IS_USER_LOCKED]: { label: selectSharedLabel, value: '' },
  [USERS_FILTERS_FORM_FIELD_NAMES.IS_USER_ACTIVE]: { label: selectSharedLabel, value: '' },
  [USERS_FILTERS_FORM_FIELD_NAMES.IS_EMAIL_CONFIRMED]: { label: selectSharedLabel, value: '' },
  [BASE_PERMISSIONS_FROM_FIELD_NAME.PERMISSIONS]: [],
});
