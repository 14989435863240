import { useState } from 'react';

import { convertFileToBase64 } from 'utils/files';

interface FileUploadResult {
  fileBase64String: string;
  fileName: string;
  fileType: string;
}

interface UseFileUpload {
  handleFileUpload: (files: FileList) => Promise<FileUploadResult | undefined>;
  error: string | undefined;
}

const useFileUpload = (): UseFileUpload => {
  const [error, setError] = useState<string | undefined>(undefined);

  const handleFileUpload = async (files: FileList): Promise<FileUploadResult | undefined> => {
    try {
      if (!files || files.length === 0) {
        return undefined;
      }

      const file = files[0];
      const fileBase64 = await convertFileToBase64(file);
      const { type: fileType, name: fileName } = file;

      if (!fileBase64) {
        setError('Failed to convert file to Base64');
        return undefined;
      }

      const fileBase64String = fileBase64.replace(`data:${fileType};base64,`, '');
      return { fileBase64String, fileName, fileType };
    } catch (error) {
      setError('Error during file upload');
      return undefined;
    }
  };

  return { handleFileUpload, error };
};

export default useFileUpload;
