import { CommonResponse, NameValueResult } from 'interfaces/shared.interface';

export interface SubscriptionsResponse extends CommonResponse {
  result: SharedSubscription[];
}

export interface SharedSubscription {
  defaultValues: string;
  description: string;
  displayName: string;
  inputType: InputTypeResult;
  value: string;
}

export interface InputTypeResult {
  validator: ValidatorResult;
  isReadonly: boolean;
  name: string;
  value: string;
}

export interface ValidatorResult {
  attributes: AttributesMaxMinResult;
  name: string;
  maxValue: number;
  minValue: number;
}

export interface AttributesMaxMinResult {
  maxValue: number;
  minValue: number;
}

export enum PERIOD {
  Trial = 1,
  Unlimited = 2,
  Daily = 3,
  Weekly = 4,
  Monthly = 5,
  Annually = 6,
}

export interface HistoryResponse extends CommonResponse {
  result: SharedHistory;
}

export interface SharedHistory {
  items: HistoryItem[];
  totalCount: number;
}

export interface HistoryItem {
  amount: number;
  creationTime: string;
  description: string;
  id: number;
  status: number;
  subscriptionName: string;
  subscriptionPlanPeriodId: number;
  tenantId: number;
}

export enum PAYMENT_HISTORY_COLUMNS {
  SUBSCRIPTION = 'Subscription',
  PROCESS_TIME = 'ProcessTime',
  AMOUNT = 'Amount',
  STATUS = 'Status',
  PERIOD = 'Period',
  ACTIONS = 'Actions',
}

export enum STATUS {
  Created = 1,
  Paid = 2,
  Failed = 3,
  Cancelled = 4,
  Completed = 5,
}

export interface SubscriptionForSelectResponse extends CommonResponse {
  result: {
    allFeatures: AllFeatureResult[];
    subscriptionsWithFeatures: SubscriptionsWithFeatures[];
  };
}

export interface AllFeatureResult {
  defaultValue: string;
  description: string | null;
  displayName: string;
  inputType: InputTypeResult;
  name: string;
  parentName: string | null;
  textHtmlColor: string | null;
}

export interface SubscriptionsWithFeatures {
  featureValues: NameValueResult[];
  subscription: SharedSubscriptionsResult;
}

export interface SharedSubscriptionsResult {
  displayName: string;
  expiringSubscriptionId: null | number;
  id: number;
  isFree: boolean;
  name: string;
  subscriptionPlans: SubscriptionPlansResult[];
  trialDayCount: null | number;
  waitingDayAfterExpire: null | string;
}
export interface SubscriptionPlansResult {
  id: number;
  price: number;
  periodId: number;
}

export enum PER_FREQUENCY {
  Trial = 1,
  Free = 2,
  PerDay = 3,
  PerWeek = 4,
  PerMonth = 5,
  PerYear = 6,
}

export interface SubscriptionResponse extends CommonResponse {
  result: SharedSubscriptionsResult;
}

export enum PRICE_FREQUENCY {
  Trial = 1,
  Free = 2,
  DailyPrice = 3,
  WeeklyPrice = 4,
  MonthlyPrice = 5,
  AnnualPrice = 6,
}

export interface CreatePaymentPayload {
  subscriptionPaymentGatewayType: number;
  targetSubscriptionPlanId: number;
}

export interface ConfigurationResponse extends CommonResponse {
  result: { publishableKey: string };
}

export interface CreateStripeSessionPayload {
  cancelUrl: string;
  paymentId: number;
  successUrl: string;
}

export interface PaymentIdResponse extends CommonResponse {
  result: HistoryItem;
}

export interface CreateSubscriptionResponse extends CommonResponse {
  result: number;
}

export interface ActiveGatewayResponse extends CommonResponse {
  result: { gatewayType: number };
}

export interface CreateStripeSessionResponse extends CommonResponse {
  result: { sessionId: string };
}

export interface SharedSubscriptionIdPayload {
  subscriptionId: number;
}

export interface UsedSubscriptionTrialIdsResponse extends CommonResponse {
  result: number[];
}

export interface InfoUpgradeSubscriptionResponse extends CommonResponse {
  result: {
    additionalPrice: number;
    subscription: SharedSubscriptionsResult;
  };
}

export interface LastCompletedResponse extends CommonResponse {
  result: {
    amount: number;
    dayCount: number;
    description: string;
    externalPaymentId: number | null;
    gateway: number;
    id: number;
    invoiceNo: number;
    payerId: number | null;
    paymentId: number | null;
    status: number;
    subscriptionDisplayName: string | null;
    subscriptionId: number;
    subscriptionPlanPeriodId: number;
    tenantId: number;
  };
}

export interface BannerProps {
  subscriptionAwaitingCancellation?: boolean;
  isSubscriptionDeactivated?: boolean;
  displayName?: string;
  expiringSubscriptionName?: string;
  loading: boolean;
  daysAmount: number;
  subscriptionIsExpiringSoon?: boolean;
  tenantId?: number;
  subscriptionId?: number;
  isInTrialPeriod?: boolean;
  isSubscriptionManagementPermission: boolean;
}
