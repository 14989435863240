import { useTranslation } from 'react-i18next';

import { useRequestToBeforgottenMutation } from 'apis/tenant-details.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import TenantDetailsBlock from 'page-components/tenant-details/TenantDetailsBlock';
import TenantRequestToBeForgottenBlock from 'page-components/tenant-details/TenantRequestToBeForgottenBlock';
import { ErrorModal, WarningModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

const TenantDetailsPage = () => {
  const { t } = useTranslation();
  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [forgetTenantRequest, { error: forgetTenantRequestError }] = useRequestToBeforgottenMutation();
  const errorData = (forgetTenantRequestError as CommonError)?.data || {};

  const permissions = useAppSelector(selectUserPermissions);

  const handleForgetTenantRequest = async () => {
    try {
      await forgetTenantRequest().unwrap();
      handleCloseInfoModal();
      successNotify(t('YourCompanyWillBeDeletedDescription'));
    } catch {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('AreYouSureToCreateForgetTenantRequestDescription')}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleForgetTenantRequest}
      />

      <div data-cy="tenant-details-page">
        <TenantDetailsBlock dataCy="tenant-name-block" />

        {permissions[Permissions.PAGES_TENANT_FORGET_TENANT_REQUEST_CREATE] && (
          <TenantRequestToBeForgottenBlock
            title={t('RequestToBeForgotten')}
            dataCy="request-to-be-forgotten"
            description={t('YouCanSendRequestToDeleteYourCompany')}
            buttonName={t('CreateRequestToBeForgotten')}
            onActionClick={handleOpenInfoModal}
          />
        )}
      </div>
    </>
  );
};

export default TenantDetailsPage;
