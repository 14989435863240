import { DAY_FORMAT_WITH_MONTH_NAME } from 'constants/global';
import { UserChatMessageData } from 'interfaces/support-tickets.interfaces';
import { getFormattedDate } from 'utils/dates';
import { cn } from 'utils/global';

import ChatAttachment from './ChatAttachment';

interface Props {
  messageData: UserChatMessageData;
}
const ChatMessage = ({ messageData }: Props) => {
  const { message, messageAttachmentType, messageAttachmentId, messageAttachmentName, side, creationTime } =
    messageData;

  return (
    <div
      className={cn('flex flex-col mx-2', {
        'items-end': side === 1,
        'items-start': side === 2,
      })}
      data-cy="chat-message"
    >
      <div
        className={cn('max-w-[35%] max-md:max-w-[65%] w-fit h-fit py-2 px-3 rounded-2xl bg-blue7 text-white mb-2 ', {
          'text-right': side === 1,
          'bg-lightGray12 text-darkBlue13': side === 2,
        })}
      >
        <div className="text-[9px] mb-1 text-left">{getFormattedDate(creationTime, DAY_FORMAT_WITH_MONTH_NAME)}</div>
        <div className="text-left text-sm leading-6 whitespace-normal break-all">
          {messageAttachmentId && messageAttachmentType && messageAttachmentName ? (
            <ChatAttachment
              fileId={messageAttachmentId}
              fileName={messageAttachmentName}
              fileType={messageAttachmentType}
            />
          ) : (
            message
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
