import { useGetCardsQuery } from 'apis/truelayer.api';
import { CARDS_TABLE_COLUMNS } from 'constants/truelayer';
import { LoadingOverlay, TotalCount, Table } from 'shared-components';

import CardsRow from './CardsRow';

const Cards = () => {
  const { data, isLoading, isFetching } = useGetCardsQuery();

  const response = data?.result.data.cards;

  return (
    <div data-cy="cards-tab" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />
      <Table dataCy="cards-table" columns={CARDS_TABLE_COLUMNS} isTableEmpty={!response?.length} isLoading={isLoading}>
        {response?.map((item) => <CardsRow key={item.id} cardsData={item} />)}
      </Table>

      <TotalCount totalCount={response?.length} className="p-2" />
    </div>
  );
};

export default Cards;
