import { useTranslation } from 'react-i18next';

import warningSVG from 'assets/png/info-circle.png';

import { Button, Modal } from '../index';

export interface Props {
  infoDescription?: string;
  infoMessage?: string;
  isOpen: boolean;
  handleClose: VoidFunction;
}
const InfoModal = ({ isOpen, handleClose, infoMessage = 'information', infoDescription }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal dataCy="info-modal" isOpen={isOpen} icon={warningSVG} isError hideCross onClose={handleClose}>
      <div className="text-2xl font-semibold font-sans text-center dark:text-white">{t(infoMessage)}</div>
      {infoDescription && (
        <div className="text-center mt-4 text-lg text-darkGray dark:text-white">{t(infoDescription)}</div>
      )}

      <div className="flex justify-center mt-5">
        <Button dataCy="info-modal-confirm-button" onClick={handleClose}>
          {t('Ok')}
        </Button>
      </div>
    </Modal>
  );
};

export default InfoModal;
