import { ChangeEvent, DragEvent, useRef, useState } from 'react';

import { faFile, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

import Button from './button/Button';

interface Props {
  handleUpload: (files: FileList) => void;
  handleRemove?: () => void;
  buttonText: string;
  multiple?: boolean;
  className?: string;
  fileType?: string | string[];
}

const DragAndDropFileUploader = ({
  handleUpload,
  handleRemove,
  multiple,
  buttonText = 'BrowseFiles',
  className,
  fileType = 'pdf',
}: Props) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [isIncorrectFileSelected, setIsIncorrectFileSelected] = useState(false);

  const handleButtonClick = () => {
    if (!hiddenFileInput.current) {
      return;
    }
    hiddenFileInput.current.click();
  };

  const validateFileType = (file: File) => {
    const acceptTypes = Array.isArray(fileType) ? fileType : [fileType];
    return acceptTypes.some((type) => file.type === `application/${type}` || file.name.endsWith(`.${type}`));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    const uploadedFiles = event.target.files[0];
    if (!validateFileType(uploadedFiles)) {
      event.target.value = '';
      return;
    }

    handleUpload(event.target.files);
    setFileName(uploadedFiles.name);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    if (!event.dataTransfer.files.length) {
      return;
    }
    const droppedFile = event.dataTransfer.files[0];
    if (!validateFileType(droppedFile)) {
      setIsIncorrectFileSelected(true);
      return;
    }
    handleUpload(event.dataTransfer.files);
    setFileName(droppedFile.name);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
  };

  const handleRemoveFile = () => {
    setFileName('');
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
    if (handleRemove) {
      handleRemove();
    }
  };

  let acceptFileType = '';
  if (typeof fileType === 'string') {
    acceptFileType = `.${fileType}`;
  } else if (Array.isArray(fileType)) {
    acceptFileType = fileType.map((type) => `.${type}`).join(',');
  }

  return (
    <div
      ref={dropZoneRef}
      className={cn(
        'cursor-pointer w-full rounded-md border-dashed border-3 text-center py-8 px-4 transition-colors relative',
        {
          'border-darkGray3 ': !isDragOver,
          'bg-linearGradient': isDragOver,
        },
        className
      )}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {fileName && <FontAwesomeIcon icon={faXmark} className="h-5 absolute right-3 top-3" onClick={handleRemoveFile} />}
      {!multiple && fileName ? (
        <div className="flex flex-col items-center">
          {fileName.endsWith('.pdf') ? (
            <FontAwesomeIcon icon={faFilePdf} className="h-20 mb-2" />
          ) : (
            <FontAwesomeIcon icon={faFile} className="h-20 mb-2" />
          )}
          <p className="text-xl font-bold">{fileName}</p>
        </div>
      ) : (
        <>
          <div className="font-bold">
            <p className="text-xl">{t('DragYourFileToStartUploading')}</p>
            <p className="py-2 text-2xl">{t('OR')}</p>
          </div>

          <Button
            data-cy="upload-file-button"
            className={cn('!bg-transparent border-1 border-darkBlue7 text-darkBlue7', className)}
            type="button"
            onClick={handleButtonClick}
          >
            {t(buttonText)}
          </Button>
          {isIncorrectFileSelected && (
            <p className="text-error mt-2">{t('TheDocumentMustBeOfType', { 0: fileType })}</p>
          )}
          <input
            className="hidden"
            ref={hiddenFileInput}
            type="file"
            onChange={handleInputChange}
            multiple={multiple}
            accept={acceptFileType}
          />
        </>
      )}
    </div>
  );
};

export default DragAndDropFileUploader;
