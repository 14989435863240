import { AUTHENTICATOR_PAGE_TITLE } from 'constants/two-factor-authentication';
import TwoFactorAuthenticatorForm from 'page-components/profile-settings/two-factor-authentication/TwoFactorAuthenticatorForm';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const TwoFactorAuthenticator = () => {
  return (
    <AuthFormContainer title={AUTHENTICATOR_PAGE_TITLE} containerClassName="sm:w-fit">
      <TwoFactorAuthenticatorForm />
    </AuthFormContainer>
  );
};

export default TwoFactorAuthenticator;
