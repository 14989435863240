import { useTranslation } from 'react-i18next';

import UserField from './UserField';

interface UserType {
  name: string;
  email: string;
  status: string;
  role: string;
  verified: boolean;
  registered: string;
}

const UserDetails: React.FC<{ user: UserType }> = ({ user }) => {
  const { name, email, status, role, verified, registered } = user;
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <h2 className="text-3xl font-bold text-center mb-10">{t('UserDetails')}</h2>
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2 justify-center items-center">
        <div className="text-center">
          <UserField label={t('Name')}>{name}</UserField>

          <UserField label={t('EmailAddress')}>{email}</UserField>

          <UserField label={t('Status')}>
            <span
              className={`px-3 pt-1 py-2 rounded-full font-medium 
    ${status === 'active' ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}
            >
              {status}
            </span>
          </UserField>
        </div>

        <div className="text-center">
          <UserField label={t('Role')}>{role}</UserField>
          {/* TODO replace Registered with something localized */}
          <UserField label={t('Registered')}>{registered}</UserField>
          {/* TODO replace Verified with something localized */}
          <UserField label={t('Verified')}> {verified ? t('Yes') : t('No')}</UserField>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
