import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

interface Props {
  icon: IconProp;
  label: string;
  borderColor?: string;
  blockId: number | string;
}

const DragAndDropSignersBlock = ({ icon, label, borderColor, blockId }: Props) => {
  const { t } = useTranslation();

  const [, drag] = useDrag(
    () => ({
      type: 'SIGNER_ITEM',
      item: { label, borderColor, icon, blockId },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [label, borderColor, icon, blockId]
  );

  return (
    <div ref={drag} className="flex h-14 bg-white dark:bg-darkBlue items-center p-2 rounded">
      <FontAwesomeIcon icon={icon} />
      <p className="mx-2">{t(label)}</p>
      <FontAwesomeIcon icon={faUpDownLeftRight} />
    </div>
  );
};

export default DragAndDropSignersBlock;
