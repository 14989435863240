import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  useGetDocumentDetailsQuery,
  usePostAddDocumentForTrainingMutation,
  usePostParseDocumentMutation,
  usePostStartTrainingMutation,
} from 'apis/typless.apis';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-3.svg';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { CommonError, FileData } from 'interfaces/shared.interface';
import TyplessDocumentTypesDetailsBlock from 'page-components/integrations/typless/documentTypes/TyplessDocumentTypesDetailsBlock';
import { Box, DragAndDropFileUploader, ErrorModal, LinkButton, LoadingOverlay } from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';
import { cn } from 'utils/global';

export interface Props {
  isDocumentParsing?: boolean;
  pdfDocummentId?: any;
  successfullMessage?: boolean;
  uploadDocumentLogic?: boolean;
  isDocumentTypeSelected?: () => void;
  fileDataLogic?: (fileData: FileData | null) => void;
  onUploadInvoice?: (invoiceData: any) => void;
}

const TyplessDocumentDetailsTypesPage: FC<Props> = ({
  isDocumentParsing,
  pdfDocummentId,
  successfullMessage,
  uploadDocumentLogic,
  isDocumentTypeSelected,
  fileDataLogic,
  onUploadInvoice,
}) => {
  const { documentId } = useParams();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { data, isLoading, error } = useGetDocumentDetailsQuery(Number(documentId || pdfDocummentId));

  const { t } = useTranslation();
  const { error: errorData } = (error as CommonError)?.data || {};

  const [parseDocument, { data: parseData, isLoading: parseDataLoading }] = usePostParseDocumentMutation();
  const [trainDocumentType, { isLoading: trainDocumentLoading }] = usePostAddDocumentForTrainingMutation();
  const [startTraining, { data: startTrainingData }] = usePostStartTrainingMutation();

  const [fileData, setFileData] = useState<FileData>({
    fileUrl: null as string | null,
    fileBase64: '',
    fileName: '',
  });

  const handleFileUpload = (files: FileList) => {
    const file = files[0];
    const url = URL.createObjectURL(file);
    const fileName = file.name;

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result as string;
      const cleanBase64String = base64String.replace('data:application/pdf;base64,', '');
      setFileData({ fileUrl: url, fileBase64: cleanBase64String, fileName });
    };
  };

  const handleFileReset = () => {
    setFileData({ fileUrl: null, fileBase64: '', fileName: '' });
  };

  useEffect(() => {
    if (error) {
      handleOpenErrorModal();
    }
  }, [error, handleOpenErrorModal]);

  useEffect(() => {
    if (fileDataLogic) {
      fileDataLogic(fileData);
    }
  }, [fileData, fileDataLogic]);

  return (
    <div data-cy="document-details-page">
      <AppContentLayout
        childrenClassName={cn('', { 'm-0': isDocumentParsing })}
        bodyClassName={cn('', { 'p-0': isDocumentParsing })}
        title={!isDocumentParsing ? t('TrainDocumentTypeDetails', { 0: data?.result?.name }) : ''}
        headerClassName="text-3xl text-darkGray dark:text-white"
      >
        <LoadingOverlay isLoading={isLoading || trainDocumentLoading || parseDataLoading} isFixed />
        <div className="relative">
          <Box data-cy="invoice-data" className={cn('p-4', { 'shadow-none': isDocumentParsing })}>
            <div className="items-center mb-5">
              {!isDocumentParsing && (
                <LinkButton
                  className="text-1xs mb-5"
                  variant={ButtonVariants.LINK}
                  to={ROUTES.typlessDocumentTypesPage}
                >
                  <ArrowLeft className="fill-blue5 mr-1.5" />
                  {t('Back')}
                </LinkButton>
              )}
              {!fileData.fileUrl ? (
                <DragAndDropFileUploader handleUpload={handleFileUpload} buttonText={'BrowseFiles'} fileType="pdf" />
              ) : (
                <TyplessDocumentTypesDetailsBlock
                  fileData={fileData}
                  data={data}
                  parseData={parseData}
                  isLoading={isLoading}
                  error={error}
                  successfullMessage={pdfDocummentId && successfullMessage}
                  trainDocumentType={trainDocumentType}
                  startTraining={startTraining}
                  handleOpenErrorModal={handleOpenErrorModal}
                  handleFileReset={handleFileReset}
                  parseDocument={parseDocument}
                  uploadDocument={uploadDocumentLogic ? onUploadInvoice : parseDocument}
                  uploadDocumentLogic={uploadDocumentLogic || false}
                  saveButton={pdfDocummentId ? 'Save' : 'StartTraining'}
                  startTrainingData={startTrainingData}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  isDocumentTypeSelected={isDocumentTypeSelected || (() => {})}
                />
              )}
            </div>
          </Box>
          <ErrorModal
            isOpen={isErrorModalOpen}
            handleClose={handleCloseErrorModal}
            errorMessage={errorData?.message}
            description={errorData?.details}
          />
        </div>
      </AppContentLayout>
    </div>
  );
};

export default TyplessDocumentDetailsTypesPage;
