import { useEffect, useRef, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useGetPaymentTypesQuery } from 'apis/gocardless.apis';
import { usePostCreateOrderMutation } from 'apis/paypal.api';
import { getPaymentPayPalFormFields } from 'constants/paypal';
import { useOpen } from 'hooks/useOpen';
import { PAY_FORM_FIELDS, PayPalRepaymentPayload } from 'interfaces/integrations/paypal.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Modal, FormContent, ErrorModal } from 'shared-components';

import PayPalButtonComponent from './PayPalButtonComponent';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const CreateOrderModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { ...createOrderMethods } = useForm<PayPalRepaymentPayload>({
    reValidateMode: 'onBlur',
  });
  const [isPayPalDisabled, setIsPayPalDisabled] = useState(true);
  const [, { error: createPayPalOrderError }] = usePostCreateOrderMutation();
  const [isOpenErrorModal, handleOnCloseErrorModal] = useOpen();
  const { error: errorRepaymentData } = (createPayPalOrderError as CommonError)?.data || {};
  const amountRef = useRef<number | null>(null);

  const {
    register,
    reset,
    watch,
    formState: { errors },
  } = createOrderMethods;
  const { isLoading } = useGetPaymentTypesQuery();

  const watchAmount = parseFloat(String(watch(PAY_FORM_FIELDS.AMOUNT)));

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (isNaN(watchAmount) || watchAmount < 1 || errors.amount) {
      setIsPayPalDisabled(true);
    } else {
      setIsPayPalDisabled(false);
    }
    amountRef.current = watchAmount;
  }, [watchAmount, errors.amount]);

  return (
    <>
      <ErrorModal
        errorMessage={errorRepaymentData?.message}
        description={errorRepaymentData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
      <Modal
        dataCy="create-payment-modal"
        isOpen={isOpen}
        isDisableOutsideClick
        onClose={onCloseModal}
        heading="CreateOrder"
        bodyClassName="md:w-1/2 lg:w-1/3 xl:w-1/4"
      >
        <FormProvider {...createOrderMethods} reset={reset}>
          <form data-cy="create-payment-modal-form">
            <FormContent
              isLoading={isLoading}
              fields={getPaymentPayPalFormFields}
              register={register}
              errors={errors}
            />
            <PayPalButtonComponent amount={amountRef} disabled={isPayPalDisabled} />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CreateOrderModal;
