import { useTenantGetRegionalStatsQuery } from 'apis';
import { BoxVariants } from 'constants/common';
import { DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME } from 'constants/dashboard';
import { REGIONAL_STATS_COLUMNS } from 'constants/regional-stats';
import { TableVariants } from 'constants/table';
import { Box, LoadingOverlay, Table } from 'shared-components';

import { RegionalStatsTableContent } from './RegionalStatsTableContent';

export const RegionalStatsWidget = () => {
  const { data: regionalStatsData, isLoading } = useTenantGetRegionalStatsQuery();

  return (
    <Box
      dataCy="regional-stats-widget"
      className="flex flex-col gap-4 relative h-full"
      headerClassName={DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME}
      variant={BoxVariants.ROUNDED}
      title="WidgetRegionalStats"
    >
      <LoadingOverlay isLoading={isLoading} />
      <Table columns={REGIONAL_STATS_COLUMNS} variant={TableVariants.SECONDARY} isLoading={isLoading}>
        <RegionalStatsTableContent regionalStats={regionalStatsData?.result?.stats} />
      </Table>
    </Box>
  );
};
