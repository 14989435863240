import { FC, RefObject } from 'react';

import { useTranslation } from 'react-i18next';

import { coreSplitApis } from 'apis';
import { useGetUserConfigQuery, useUpdateThemeMutation } from 'apis/user.api';
import { ReactComponent as CheckSvg } from 'assets/svg/check.svg';
import { ReactComponent as PaletteSvg } from 'assets/svg/palette.svg';
import { ReactComponent as SettingsSvg } from 'assets/svg/settings.svg';
import { ReactComponent as SignOutSvg } from 'assets/svg/sign-out.svg';
import { useOpen } from 'hooks/useOpen';
import { ImpersonateUserPayload } from 'interfaces/impersonate-user';
import { CommonError } from 'interfaces/shared.interface';
import { ThemeConfig } from 'interfaces/user.interface';
import { userLogout } from 'modules/auth/action';
import { useAppDispatch } from 'modules/store';
import { resetUserAuthStorage } from 'utils/auth';
import { cn } from 'utils/global';

import ChangeLanguage from './ChangeLanguage';
import MenuItem from './MenuItem';
import ErrorModal from './modal/ErrorModal';

interface Props {
  firstName?: string;
  lastName?: string;
  onClick?: (event: MouseEvent) => void;
  ref?: RefObject<HTMLDivElement>;
  className?: string;
  handleOpenSettingsModal: VoidFunction;
  isImpersonatorUser?: boolean;
  handleBackToImpersonator: (payload?: ImpersonateUserPayload) => void;
}

const COMMON_CLASSES = 'w-5 mr-1 fill-darkGray6 dark:fill-white';
const SettingsDropdown: FC<Props> = ({
  firstName,
  lastName,
  className,
  handleOpenSettingsModal,
  isImpersonatorUser,
  handleBackToImpersonator,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { data } = useGetUserConfigQuery();
  const [updateTheme, { error: updateThemeError }] = useUpdateThemeMutation();
  const errorData = (updateThemeError as CommonError)?.data || {};

  const currentTheme = data?.result?.setting.values['App.UserThemeName'] || ThemeConfig.light;
  const isLightTheme = currentTheme === ThemeConfig.light;

  const handleThemeChange = async (theme: ThemeConfig) => {
    try {
      await updateTheme({ theme }).unwrap();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleLogout = () => {
    if (isImpersonatorUser) {
      handleBackToImpersonator();
      return;
    }

    resetUserAuthStorage();

    if (coreSplitApis.util) {
      dispatch(coreSplitApis.util?.resetApiState());
    }
    dispatch(userLogout());
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div
        className={cn(
          'absolute rounded-[3px] top-11 right-5 bg-white dark:bg-darkBlue4 dark:border-[1px] border-darkBlue shadow-lg w-60 z-10',
          className,
          { light: isLightTheme, dark: !isLightTheme }
        )}
        data-cy="check"
      >
        <div className="px-3 py-1 border-b text-darkGray5 border-lightGray4 dark:border-darkBlue">
          <p className="font-semibold mb-[-3px]">{t('SignedInAs')}</p>
          <div className="flex">
            <p className="text-xs">
              {firstName} {lastName}
            </p>
          </div>
        </div>
        <div data-cy="settings-button" className="settings text-[14px]">
          <MenuItem
            image={<SettingsSvg className="w-5 settings-icon fill-darkGray6 dark:fill-darkGray3" />}
            label="Settings"
            onClick={handleOpenSettingsModal}
          />
        </div>
        <div className="relative flex items-center justify-between px-3 py-1.5 hover:bg-lightGray5 dark:hover:bg-darkBlue7 language">
          <ChangeLanguage />
        </div>
        <div
          className="relative flex items-center justify-between px-3 py-1.5 hover:bg-lightGray5 theme dark:hover:bg-darkBlue7"
          data-cy="theme-menu-item"
        >
          <MenuItem
            image={<PaletteSvg className="w-5 fill-darkGray6 dark:fill-darkGray3" />}
            label={t('Appearance', { 0: isLightTheme ? t('LightTheme') : t('DarkTheme') })}
            dropdownIcon
            className="px-0 py-0"
          >
            <div
              data-cy="theme-menu-item-div"
              className="hidden absolute top-0 right-56 w-56 mr-3.5 bg-white shadow-lg hover-theme-block"
            >
              <MenuItem
                image={isLightTheme ? <CheckSvg className={COMMON_CLASSES} /> : null}
                className="h-9"
                label="Light theme"
                onClick={() => handleThemeChange(ThemeConfig.light)}
                dataCy="light-theme-item"
              />
              <MenuItem
                image={!isLightTheme ? <CheckSvg className={COMMON_CLASSES} /> : null}
                label="Dark theme"
                className="h-9"
                onClick={() => handleThemeChange(ThemeConfig.dark)}
                dataCy="dark-theme-item"
              />
            </div>
          </MenuItem>
        </div>
        <div className="logout">
          <MenuItem
            dataCy="logout-button"
            image={<SignOutSvg className="w-5 fill-darkGray6 dark:fill-darkGray3" />}
            label={isImpersonatorUser ? 'ReturnBack' : 'Logout'}
            className="px-3 py-2.5 flex items-center border-t border-lightGray4 dark:border-darkBlue hover:bg-lightGray5"
            onClick={handleLogout}
          />
        </div>
      </div>
    </>
  );
};

export default SettingsDropdown;
