import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

use(initReactI18next).init({
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    prefix: '{',
    suffix: '}',
  },
});

export default i18n;
