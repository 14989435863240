import { useTranslation } from 'react-i18next';

import { FORGOT_PASSWORD_PAGE_TITLE } from 'constants/forgot-password';
import { ForgotPasswordForm } from 'page-components/forgot-password';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <AuthFormContainer title={t(FORGOT_PASSWORD_PAGE_TITLE)}>
      <ForgotPasswordForm />
    </AuthFormContainer>
  );
};

export default ForgotPassword;
