import { FC, useEffect } from 'react';

import { format } from 'date-fns';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLazyAdminGetTopStatsQuery } from 'apis';
import { ButtonVariants } from 'constants/common';
import { DAY_FORMAT_WITH_TIME_WITH_SLASHES } from 'constants/global';
import useUserRole from 'hooks/useUserRole';
import { DashboardFiltersForm } from 'interfaces/dashboard.interface';
import { Button, LoadingOverlay, Modal, RangeDatePickerInput } from 'shared-components';

export interface Props {
  errorMessage?: string;
  isOpen: boolean;
  handleClose: VoidFunction;
  description?: string;
}

export const DashboardFiltersModal: FC<Props> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const [getAdminTopStats, { isLoading, isFetching }] = useLazyAdminGetTopStatsQuery();
  const { control, handleSubmit, watch, reset } = useForm<DashboardFiltersForm>();

  const { isAdmin } = useUserRole();

  const watchDate = watch('dateRange');

  const onClose = () => {
    reset();
    handleClose();
  };

  const onSubmit: SubmitHandler<DashboardFiltersForm> = ({ dateRange }) => {
    const [start, end] = dateRange;

    const startDate = format(start, DAY_FORMAT_WITH_TIME_WITH_SLASHES);
    const endDate = format(end, DAY_FORMAT_WITH_TIME_WITH_SLASHES);

    if (isAdmin) {
      getAdminTopStats({ startDate, endDate });
    }
  };

  useEffect(() => {
    if (watchDate) {
      handleSubmit(onSubmit)();
    }
  }, [handleSubmit, watchDate]);

  return (
    <Modal
      bodyClassName="md:w-2/5 2xl:w-1/4"
      heading="DashboardFilters"
      dataCy="dashboard-filter-modal"
      isOpen={isOpen}
      onClose={onClose}
      hideCross
    >
      <div className="flex flex-col gap-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <LoadingOverlay isLoading={isLoading || isFetching} />
          <RangeDatePickerInput
            control={control}
            name="dateRange"
            label="FilterDateRangePicker"
            placeholder={['StartDate', 'EndDate']}
            dataCy="filter-date-range"
          />
        </form>
        <Button className="self-end" onClick={onClose} dataCy="modal-close-button" variant={ButtonVariants.BORDERED}>
          {t('Close')}
        </Button>
      </div>
    </Modal>
  );
};
