import { BASE_PERMISSIONS_FROM_FIELD_NAME } from 'constants/common';

import { Permission } from './permissions.interface';
import { BaseFilterValues, CommonResponse, OptionType } from './shared.interface';
import { User } from './user.interface';

export enum USERS_COLUMNS {
  FULL_NAME = 'name',
  ROLES = 'roles',
  EMAIL_ADDRESS = 'emailAddress',
  IS_EMAIL_CONFIRMED = 'isEmailConfirmed',
  IS_ACTIVE = 'isActive',
  IS_LOCKED = 'isLocked',
  CREATION_TIME = 'creationTime',
  LAST_LOGIN_TIME = 'lastLoginTime',
  ACTIONS = 'actions',
}

export enum USERS_FILTERS_FORM_FIELD_NAMES {
  IS_USER_LOCKED = 'isUserLocked',
  IS_USER_ACTIVE = 'isUserActive',
  IS_EMAIL_CONFIRMED = 'isEmailConfirmed',
}

export interface UsersResult {
  totalCount: number;
  items: User[];
}

export interface UsersResponse extends CommonResponse {
  result: UsersResult;
}

export interface UsersFilterFormValues extends BaseFilterValues {
  [USERS_FILTERS_FORM_FIELD_NAMES.IS_USER_LOCKED]: OptionType;
  [USERS_FILTERS_FORM_FIELD_NAMES.IS_USER_ACTIVE]: OptionType;
  [USERS_FILTERS_FORM_FIELD_NAMES.IS_EMAIL_CONFIRMED]: OptionType;
  [BASE_PERMISSIONS_FROM_FIELD_NAME.PERMISSIONS]: string[];
}

export interface UserPermissionsResult {
  permissions: Permission[];
  grantedPermissionNames: string[];
}

export interface UserPermissionsResponse extends CommonResponse {
  result: UserPermissionsResult;
}

export interface GetTenantResult {
  tenancyName: string;
  name: string;
  isActive: boolean;
  id: number;
}

export interface GetTenantResponse extends CommonResponse {
  result: GetTenantResult;
}

export interface UpdateUserPermissionsData {
  data: {
    id: number;
    grantedPermissionNames: string[];
    tenantId?: string;
  };
  isAdmin: boolean;
}
