import { useFormContext } from 'react-hook-form';

import { FormItem } from 'interfaces/shared.interface';
import { FormContent } from 'shared-components/index';

interface Props {
  fields: FormItem[];
  className?: string;
}

const FilterFormContentWithoutPermissions = ({ fields, className }: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div data-cy="filters-container" className={className}>
      <FormContent fields={fields} control={control} register={register} errors={errors} />
    </div>
  );
};

export default FilterFormContentWithoutPermissions;
