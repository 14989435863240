import appShield from 'assets/png/app-shield.png';
import mailIcon from 'assets/png/mail-icon.png';
import smsIcon from 'assets/png/sms-icon.png';
import { FieldTypes, FormItem } from 'interfaces/shared.interface';
import {
  TWO_FACTOR_AUTH_FORM_FIELDS,
  TWO_FACTOR_AUTHENTICATION_TYPES,
} from 'interfaces/two-factor-authentication.interface';
import { Input } from 'shared-components';

import { TWO_FACTOR_AUTHENTICATOR_PATTERN } from './global';

export const TWO_FACTOR_AUTHENTICATION_OPTIONS = {
  email: {
    type: TWO_FACTOR_AUTHENTICATION_TYPES.email,
    label: 'Email',
    img: mailIcon,
    value: 1,
    dataCy: 'two-factor-authentication-email',
  },
  appAuthenticator: {
    type: TWO_FACTOR_AUTHENTICATION_TYPES.authenticator,
    label: 'Authenticator app',
    img: appShield,
    value: 2,
    dataCy: 'two-factor-authentication-authenticator',
  },
  sms: {
    type: TWO_FACTOR_AUTHENTICATION_TYPES.sms,
    label: 'Sms',
    img: smsIcon,
    value: 3,
    dataCy: 'two-factor-authentication-sms',
  },
};

export const TWO_FACTOR_AUTHENTICATOR_PAGE_PLACEHOLDER = 'Authentication code';

export const TWO_FACTOR_AUTHENTICATOR_FORM_INPUTS: FormItem[] = [
  {
    name: TWO_FACTOR_AUTH_FORM_FIELDS.twoFactorAuthenticationCode,
    type: FieldTypes.TEXT,
    inputClassName: 'w-full',
    component: Input,
    required: true,
    className: 'mb-4 w-full',
    placeholder: TWO_FACTOR_AUTHENTICATOR_PAGE_PLACEHOLDER,
    validation: {
      required: 'This field is required',
      pattern: { message: 'Code invalid', value: TWO_FACTOR_AUTHENTICATOR_PATTERN },
    },
  },
];

export const TWO_FACTOR_SELECT_BUTTON = 'Select';
export const TWO_FACTOR_SELECTED_BUTTON = 'Selected';
export const AUTHENTICATOR_QR_CODE_BUTTON = 'Scan QR Code';

export const AUTHENTICATOR_PAGE_TITLE = 'Two-factor authentication code';

export const RESEND_CODE_BUTTON = 'ResendCode';
