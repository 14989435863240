import { CommonResponse } from 'interfaces/shared.interface';
import { CreateSupportRequestPayload, SupportRequestsResponse } from 'interfaces/tenant-support-requests.interface';

import { coreSplitApis } from './core.api';

export const supportRequests = coreSplitApis.enhanceEndpoints({ addTagTypes: ['SupportRequests'] }).injectEndpoints({
  endpoints: (build) => ({
    getSupportRequests: build.query<SupportRequestsResponse, string>({
      query: (params) => ({
        url: `SupportTicket/GetClientSupportTickets?${params}`,
        method: 'GET',
      }),
      providesTags: ['SupportRequests'],
    }),
    createSupportRequests: build.mutation<CommonResponse, CreateSupportRequestPayload>({
      query: (data) => ({
        url: `SupportTicket/CreateSupportTicket`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['SupportRequests']),
    }),
    tenantResolveTicket: build.mutation<void, number>({
      query: (ticketId) => ({
        url: `SupportTicket/ResolveTicketByClient?ticketId=${ticketId}`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['SupportRequests']),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSupportRequestsQuery, useCreateSupportRequestsMutation, useTenantResolveTicketMutation } =
  supportRequests;
