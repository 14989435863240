import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useCreateOrUpdateUserMutation, useGetUserDetailsQuery } from 'apis/users.api';
import { getProfileSettingsTabs } from 'constants/profile-settings';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import {
  BASE_USER_SETTINGS_FORM_FIELDS,
  CreateUserFormValues,
  CreateUserSettingPayload,
  UserDetails,
} from 'interfaces/user.interface';
import UserSettingInputsCheckboxes from 'page-components/users/UserSettingInputsCheckboxes';
import { ErrorModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';

interface Props {
  handleClose: VoidFunction;
  refetchListUsers: VoidFunction;
  userId?: number;
}
const UserSettingsModalContent = ({ handleClose, refetchListUsers, userId }: Props) => {
  const { data: userData, isLoading: isLoadingUserData } = useGetUserDetailsQuery(userId);

  const [createOrUpdateUser, { error }] = useCreateOrUpdateUserMutation();

  const { user, roles } = userData || {};

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { reset, ...createUserFormMethods } = useForm<CreateUserFormValues>({ reValidateMode: 'onSubmit' });

  const watchRoles = createUserFormMethods?.watch(BASE_USER_SETTINGS_FORM_FIELDS.roles);
  const assignedRolesCount = Object.values(watchRoles || {}).filter((role) => role)?.length;

  const { data: errorData } = (error as CommonError) || {};

  const onSubmit = async ({
    name,
    surname,
    emailAddress,
    phoneNumber,
    setRandomPassword,
    password,
    isActive,
    isTwoFactorEnabled,
    isLockoutEnabled,
    sendActivationEmail,
    roles,
  }: CreateUserFormValues) => {
    const assignedRoleNames = Object.keys(roles).filter((key) => roles[key]);

    const payload = {
      sendActivationEmail,
      setRandomPassword,
      user: {
        ...user,
        name,
        surname,
        password,
        phoneNumber,
        isActive,
        isLockoutEnabled,
        isTwoFactorEnabled,
        emailAddress,
      } as UserDetails,
      assignedRoleNames,
    } as CreateUserSettingPayload;

    try {
      await createOrUpdateUser(payload).unwrap().then(refetchListUsers);
      handleClose();
      successNotify();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (user) {
      const assignedRoles = roles?.reduce(
        (prevRoles, { roleName, isAssigned }) => ({ ...prevRoles, [roleName]: isAssigned }),
        {}
      );

      reset({ ...user, sendActivationEmail: true, setRandomPassword: !user.password, roles: assignedRoles });
    }
  }, [reset, user, roles]);

  if (isLoadingUserData) {
    return null;
  }

  return (
    <>
      <FormProvider {...createUserFormMethods} reset={reset}>
        <form onSubmit={createUserFormMethods.handleSubmit(onSubmit)}>
          <Tabs
            tabs={getProfileSettingsTabs(assignedRolesCount)}
            componentProps={{
              userId,
              roles,
              profilePictureId: userData?.profilePictureId,
              customComponent: UserSettingInputsCheckboxes,
            }}
          />
        </form>
      </FormProvider>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default UserSettingsModalContent;
