import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetAllWithBaseInfoQuery } from 'apis/subscriptions-admin.api';
import { useGetTenantsQuery } from 'apis/tenants.api';
import {
  SEARCH_TENANTS_PLACEHOLDER,
  TENANTS_DEFAULT_FILTERS_VALUES,
  TENANTS_TABLE_COLUMNS,
  getTenantsFilterFormFields,
} from 'constants/tenants';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import { TenantsFilterFormValues } from 'interfaces/tenants.interface';
import CreateTenantModal from 'page-components/tenants/CreateTenantModal';
import TenantEditModal from 'page-components/tenants/TenantEditModal';
import TenantsRow from 'page-components/tenants/TenantsRow';
import { Button, ErrorModal, FilterForm, FormContent, LoadingOverlay, Pagination, Table } from 'shared-components';

const Tenants = () => {
  const { t } = useTranslation();

  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { filterParams, applyFilters, isSameFilters } = useFilters();
  const [isOpenCreateTenantModal, handleOpenCreateTenantModal, handleCloseCreateTenantModal] = useOpen();
  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: errorTenantsData,
  } = useGetTenantsQuery(`${filterParams}${sortingParams}${paginationParams}`);

  const [isEditModalOpen, handleOpenEditModal, handleCloseEditModal] = useOpen();

  const { data: subscriptionsData } = useGetAllWithBaseInfoQuery();

  const [editingId, setEditingId] = useState<number | null>(null);

  const { result: subscriptionOptions } = subscriptionsData || {};
  const { items, totalCount } = data?.result || {};
  const { error: errorData } = (errorTenantsData as CommonError)?.data || {};

  const tenantsFiltersMethods = useForm<TenantsFilterFormValues>({
    defaultValues: TENANTS_DEFAULT_FILTERS_VALUES,
  });

  const handleClearFields = () => {
    tenantsFiltersMethods.reset(TENANTS_DEFAULT_FILTERS_VALUES);
    onSubmitFilters(TENANTS_DEFAULT_FILTERS_VALUES);
  };

  const onSubmitFilters = async (data: TenantsFilterFormValues) => {
    const { subscriptionId, isActive, filter } = data;

    const newFiltersToApply = {
      searchQuery: filter,
      subscriptionId: subscriptionId.value,
      isActive: isActive?.value && (isActive.value as boolean).toString(),
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleTenantEdit = (id: number | null) => {
    setEditingId(id);
    handleOpenEditModal();
  };

  useEffect(() => {
    if (errorTenantsData) {
      handleOpenErrorModal();
    }
  }, []);

  return (
    <div data-cy="tenants-page">
      <CreateTenantModal
        handleClose={handleCloseCreateTenantModal}
        isOpen={isOpenCreateTenantModal}
        refetchListTenants={refetch}
      />

      {isEditModalOpen && editingId !== null && (
        <TenantEditModal
          handleClose={handleCloseEditModal}
          isOpen={isEditModalOpen}
          refetchTenants={refetch}
          tenantId={editingId}
        />
      )}
      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <div>
          <FormProvider {...tenantsFiltersMethods}>
            <form onSubmit={tenantsFiltersMethods.handleSubmit(onSubmitFilters)}>
              <FilterForm
                dataCy="filters-container"
                placeHolder={SEARCH_TENANTS_PLACEHOLDER}
                handleClearFields={handleClearFields}
                rightTopActionButton={
                  <Button
                    dataCy="open-tenant-modal-button"
                    className="h-10 ml-3.5"
                    type="button"
                    onClick={handleOpenCreateTenantModal}
                  >
                    {t('Create')}
                  </Button>
                }
              >
                <div className="flex" data-cy="tenants-filters-content">
                  <FormContent
                    fields={getTenantsFilterFormFields(subscriptionOptions)}
                    register={tenantsFiltersMethods.register}
                    errors={tenantsFiltersMethods.formState.errors}
                  />
                </div>
              </FilterForm>
            </form>
          </FormProvider>
        </div>

        <Table
          dataCy="tenants-table"
          columns={TENANTS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {items?.map((item) => (
            <TenantsRow key={item.id} tenantData={item} refetchTenants={refetch} handleEdit={handleTenantEdit} />
          ))}
        </Table>

        <Pagination
          totalCount={totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />

        <ErrorModal
          isOpen={isOpenErrorModal}
          handleClose={handleCloseErrorModal}
          errorMessage={errorData?.message}
          description={errorData?.details}
        />
      </div>
    </div>
  );
};

export default Tenants;
