import {
  AuditLogsResponse,
  ChangeLogsResponse,
  EntityHistoryObjectResponse,
  EntityPropertyChangeResponse,
  AuditLogsToExcelResponse,
} from 'interfaces/audit-logs.interface';

import { coreSplitApis } from './core.api';

export const auditLogsApi = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getAuditLogs: build.query<AuditLogsResponse, string>({
      query: (params) => ({
        url: `AuditLog/GetAuditLogs?${params}`,
        method: 'GET',
      }),
    }),
    getEntityChanges: build.query<ChangeLogsResponse, string>({
      query: (params) => ({
        url: `AuditLog/GetEntityChanges?${params}`,
        method: 'GET',
      }),
    }),
    getEntityHistoryObjectTypes: build.query<EntityHistoryObjectResponse, void>({
      query: () => ({
        url: 'AuditLog/GetEntityHistoryObjectTypes',
        method: 'GET',
      }),
    }),
    getEntityPropertyChanges: build.query<EntityPropertyChangeResponse, number>({
      query: (logId) => ({
        url: `AuditLog/GetEntityPropertyChanges?entityChangeId=${logId}`,
        method: 'GET',
      }),
    }),
    getAuditLogsToExcel: build.query<AuditLogsToExcelResponse, string>({
      query: (params) => ({
        url: `AuditLog/GetAuditLogsToExcel?${params}`,
        method: 'GET',
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
    getEntityChangesToExcel: build.query<AuditLogsToExcelResponse, string>({
      query: (params) => ({
        url: `AuditLog/GetEntityChangesToExcel?${params}`,
        method: 'GET',
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useLazyGetAuditLogsQuery,
  useLazyGetEntityChangesQuery,
  useGetEntityHistoryObjectTypesQuery,
  useGetEntityPropertyChangesQuery,
  useLazyGetAuditLogsToExcelQuery,
  useLazyGetEntityChangesToExcelQuery,
} = auditLogsApi;
