import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetRolesQuery } from 'apis/roles.api';
import {
  getRolesFiltersFieldsDefaultValues,
  getRolesTableColumns,
  ROLES_FILTER_FORM_FIELDS,
  SEARCH_ROLES_PLACEHOLDER,
} from 'constants/roles';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import useTenantId from 'hooks/useTenantId';
import { RolesFilterFormValues } from 'interfaces/roles.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { ManageRoleModalContent } from 'page-components/roles';
import RolesRow from 'page-components/roles/RolesRow';
import {
  Button,
  ErrorModal,
  FilterForm,
  FilterFormContent,
  LoadingOverlay,
  Modal,
  Pagination,
  Table,
} from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';

const Roles = () => {
  const { t } = useTranslation();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenSettingsRoleModal, handleOpenSettingsRoleModal, handleCloseSettingsRoleModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { tenantId, name, tenantError, tenantIdQueryParameter, shouldSkip, isTenantIdLoading } = useTenantId({
    onError: handleOpenErrorModal,
  });

  const { filterParams, applyFilters, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: errorRolesData,
  } = useGetRolesQuery(
    { params: `${filterParams}${sortingParams}${paginationParams}${tenantIdQueryParameter}`, isAdmin: !!tenantId },
    { skip: shouldSkip }
  );

  const [editingId, setEditingId] = useState<number | null>(null);

  const { result } = data || {};
  const { error: errorData } = (errorRolesData as CommonError)?.data || {};

  const rolesFiltersMethods = useForm<RolesFilterFormValues>({
    defaultValues: getRolesFiltersFieldsDefaultValues(t('All')),
  });

  const onSubmitFilters = async (data: RolesFilterFormValues) => {
    const { isStatic, isDefault } = data;

    const newFiltersToApply = {
      ...data,
      isStatic: isStatic.value !== '' ? (isStatic.value as boolean).toString() : null,
      isDefault: isDefault.value !== '' ? (isDefault.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const hasStaticItemWithDeletePermissions =
    (result?.items.some((item) => item.isStatic) ?? false) && !!permissions[Permissions.PAGES_ROLES_DELETE];

  const isEditPermissionEnabled = !!permissions[Permissions.PAGES_ROLES_EDIT];

  const handleClearFields = () => {
    rolesFiltersMethods.reset(getRolesFiltersFieldsDefaultValues(t('All')));
    onSubmitFilters(getRolesFiltersFieldsDefaultValues(t('All')));
  };

  const onCloseCreateRoleModal = () => {
    setEditingId(null);
    handleCloseSettingsRoleModal();
  };

  const handleRoleEdit = (id: number | null) => {
    setEditingId(id);
    handleOpenSettingsRoleModal();
  };

  useEffect(() => {
    if (errorRolesData) {
      handleOpenErrorModal();
    }
  }, [handleOpenErrorModal, errorRolesData]);

  useEffect(() => {
    rolesFiltersMethods.reset(getRolesFiltersFieldsDefaultValues(t('All')));
  }, [t, rolesFiltersMethods]);

  return (
    <AppContentLayout title="Roles" titlePrefix={name ? `${name}:` : ''}>
      <Modal
        heading={editingId ? 'UpdateRole' : 'CreateNewRole'}
        isOpen={isOpenSettingsRoleModal}
        onClose={onCloseCreateRoleModal}
      >
        <ManageRoleModalContent handleClose={onCloseCreateRoleModal} refetchRoles={refetch} editingId={editingId} />
      </Modal>
      <div data-cy="roles-page" className="relative">
        <LoadingOverlay isLoading={(isFetching && !!data) || isTenantIdLoading} />

        <div>
          <FormProvider {...rolesFiltersMethods}>
            <form onSubmit={rolesFiltersMethods.handleSubmit(onSubmitFilters)}>
              <FilterForm
                dataCy="roles-page-search"
                placeHolder={SEARCH_ROLES_PLACEHOLDER}
                handleClearFields={handleClearFields}
                rightTopActionButton={
                  <Button
                    hidden={!permissions[Permissions.PAGES_ROLES_CREATE]}
                    data-cy="open-role-modal-button"
                    className="h-10 ml-3.5"
                    type="button"
                    onClick={handleOpenSettingsRoleModal}
                  >
                    {t('Create')}
                  </Button>
                }
              >
                <FilterFormContent fields={ROLES_FILTER_FORM_FIELDS} />
              </FilterForm>
            </form>
          </FormProvider>
        </div>

        <Table
          dataCy="roles-table"
          columns={getRolesTableColumns(isEditPermissionEnabled || hasStaticItemWithDeletePermissions)}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {result?.items.map((item) => (
            <RolesRow
              key={item.id}
              roleData={item}
              handleEdit={handleRoleEdit}
              hasStaticItemWithDeletePermissions={hasStaticItemWithDeletePermissions}
              isEditPermissionEnabled={isEditPermissionEnabled}
              refetchTrigger={refetch}
            />
          ))}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />

        <ErrorModal
          errorMessage={tenantError?.message || errorData?.message}
          description={errorData?.details}
          isOpen={isErrorModalOpen}
          handleClose={handleCloseErrorModal}
        />
      </div>
    </AppContentLayout>
  );
};

export default Roles;
