import { useTranslation } from 'react-i18next';

import TableData from './TableData';
import TableRow from './TableRow';

interface Props {
  text?: string;
  colSpan?: number;
}

const NoTableData = ({ text = 'NoData', colSpan }: Props) => {
  const { t } = useTranslation();

  return (
    <TableRow dataCy="no-table-data" className="border-none">
      <TableData className="text-center text-darkGray dark:text-white" colSpan={colSpan}>
        {t(text)}
      </TableData>
    </TableRow>
  );
};

export default NoTableData;
