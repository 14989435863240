import { FieldTypes, FormItem } from 'interfaces/shared.interface';
import { SIGN_IN_FORM_FIELDS } from 'interfaces/sign-in.interface';
import { Checkbox, Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';

export const SIGN_IN_PAGE_TITLE = 'LogIn';

export const SIGN_IN_PAGE_FORGOT_PASSWORD_TEXT = 'ForgotPassword';

export const SIGN_IN_PAGE_ACTIVATE_EMAIL_TEXT = 'ActivateEmail';

export const SIGN_IN_PAGE_REGISTER_TEXT = 'Register';

export const SIGN_IN_FORM_INPUTS: FormItem[] = [
  {
    name: SIGN_IN_FORM_FIELDS.userName,
    label: 'EmailAddress',
    type: FieldTypes.TEXT,
    inputClassName: 'w-full',
    component: Input,
    className: 'mb-4',
    required: true,
    validation: {
      required: 'PleaseFillAllRequiredFields',
      pattern: { message: 'InvalidEmailAddress', value: EMAIL_PATTERN },
    },
    dataCy: 'email-address-input',
  },
  {
    name: SIGN_IN_FORM_FIELDS.password,
    label: 'Password',
    type: FieldTypes.PASSWORD,
    inputClassName: 'w-full',
    className: 'mb-4',
    component: Input,
    required: true,
    validation: {
      required: 'PleaseFillAllRequiredFields',
    },
    dataCy: 'password-input',
  },
  {
    name: SIGN_IN_FORM_FIELDS.rememberMe,
    label: 'RememberMe',
    type: FieldTypes.CHECKBOX,
    inputClassName: 'w-full',
    labelClassName: 'font-semibold',
    className: '-mb-7',
    component: Checkbox,
    dataCy: 'remember-me-checkbox',
  },
];
