import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetConnectionPayPalDetailsQuery, usePostConnectMutation } from 'apis/paypal.api';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import PayPalConnectionHandler from 'page-components/integrations/paypal/PayPalConnectionHandler';
import { ErrorModal, LoadingOverlay } from 'shared-components';
import { successNotify } from 'utils/notifications';

const Paypal = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const {
    data: connectionData,
    isLoading: isLoadingGetConnection,
    isFetching,
    error: getConnectionError,
    refetch,
  } = useGetConnectionPayPalDetailsQuery();

  const [postConnect] = usePostConnectMutation();
  const isLoading = isLoadingGetConnection;
  const navigate = useNavigate();
  const { data: getConnectionErrorData } = (getConnectionError as CommonError) || {};
  const [params] = useSearchParams();
  const merchantId = params.get('merchantIdInPayPal');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!merchantId) {
          return;
        }
        await postConnect({ merchantId }).unwrap();
        refetch();
        navigate(ROUTES.payPal);
        successNotify(t('SuccessfullyConnected'));
      } catch (error) {
        handleOpenErrorModal();
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ErrorModal
        errorMessage={getConnectionErrorData?.error?.message}
        description={getConnectionErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div className="relative" data-cy="paypal-page">
        <LoadingOverlay isLoading={isFetching && !!connectionData} />
        {!isLoading && !isFetching ? (
          <PayPalConnectionHandler isConnect={connectionData?.result.isConnected || false} triggerRefetch={refetch} />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Paypal;
