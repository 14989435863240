import { useTranslation } from 'react-i18next';

import {
  useActivateUserMutation,
  useDeactivateUserMutation,
  useUnlockUserMutation,
  useDeleteUserMutation,
} from 'apis/users.api';
import { useOpen } from 'hooks/useOpen';
import { useSelectDropdownItem } from 'hooks/useSelectDropdownItem';
import { ImpersonateUserPayload } from 'interfaces/impersonate-user';
import { CommonError, DropdownMenuItems } from 'interfaces/shared.interface';
import { Permissions, User } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Checkbox, DropdownMenu, TableData, WarningModal, TableRow, ErrorModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { formatObjectsArrToStringWithSeparator } from 'utils/global';
import { successNotify } from 'utils/notifications';

import UserChangePermissionsModal from './change-permissions/UserChangePermissionsModal';
import UserSettingsModal from './UserSettingsModal';

interface Props {
  user: User;
  refetchUsersList: VoidFunction;
  handleImpersonate: (payload?: ImpersonateUserPayload) => void;
  isLoading: boolean;
}

const UsersRow = ({ user, refetchUsersList, handleImpersonate }: Props) => {
  const { t } = useTranslation();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { selectedItem, handleSelectDropdownItem } = useSelectDropdownItem();

  const { name, surname, roles, emailAddress, isEmailConfirmed, isActive, lastLoginTime, creationTime, isLocked } =
    user;

  const [unlockUser, { error: unlockUserError }] = useUnlockUserMutation();
  const [deleteUser, { error: deleteUserError }] = useDeleteUserMutation();
  const [deactivateUser, { error: deactivateUserError }] = useDeactivateUserMutation();

  const [activateUser, { error: activateUserError }] = useActivateUserMutation();

  const errorUnlockUserErrorData = (unlockUserError as CommonError)?.data || {};
  const errorDeleteUserErrortData = (deleteUserError as CommonError)?.data || {};
  const errorDeactivateUserErrorData = (deactivateUserError as CommonError)?.data || {};
  const errorActivateUserErrorData = (activateUserError as CommonError)?.data || {};

  const errorData =
    errorUnlockUserErrorData || errorDeleteUserErrortData || errorDeactivateUserErrorData || errorActivateUserErrorData;

  const handleDeleteUser = async () => {
    try {
      await deleteUser(user.id).unwrap();
      handleCloseWarningModal();
      successNotify(t('SuccessfullyDeleted'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleUnlockUser = async (id: number) => {
    try {
      await unlockUser({ id }).unwrap();
      successNotify(t('UnlockedTheUser', { 0: user.name, 1: user.surname }));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const [isEditModalOpen, handleOpenEditModal, handleCloseEditModal] = useOpen();
  const [isChangePermissionModalOpen, handleOpenChangePermissionModal, handleCloseChangePermissionModal] = useOpen();

  const toggleDeactivateUser = async () => {
    try {
      if (user.isActive) {
        await deactivateUser(user.id).unwrap();
        successNotify(t('DeactivatedSuccessfully'));
      } else {
        await activateUser(user.id).unwrap();
        successNotify(t('ActivatedSuccessfully'));
      }
      refetchUsersList();
      handleCloseWarningModal();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const menuItemsAction: DropdownMenuItems[] = [
    {
      name: 'Edit',
      dataCy: 'users-menu-item-edit-button',
      hidden: !permissions[Permissions.PAGES_USERS_EDIT],
      actionOnClick: handleOpenEditModal,
    },
    {
      name: 'Unlock',
      dataCy: 'users-menu-item-unlock-button',
      hidden: !permissions[Permissions.PAGES_USERS_UNLOCK],
      actionOnClick: () => handleUnlockUser(user.id),
    },
    {
      name: 'ChangePermissions',
      dataCy: 'users-menu-item-change-permissions-button',
      hidden: !permissions[Permissions.PAGES_USERS_CHANGE_PERMISSIONS],
      actionOnClick: handleOpenChangePermissionModal,
    },
    {
      name: user.isActive ? 'Deactivate' : 'Activate',
      description: t(user.isActive ? 'UserDeactivateWarningMessage' : 'UserActivateWarningMessage', {
        0: name,
        1: surname,
      }),
      dataCy: 'users-menu-item-deactivate-button',
      hidden: !permissions[Permissions.PAGES_USERS_EDIT],
      actionOnClick: handleOpenWarningModal,
      handleConfirm: toggleDeactivateUser,
    },
    {
      name: 'Delete',
      dataCy: 'users-menu-item-delete-button',
      description: t('UserDeleteWarningMessage', { 0: user.name }),
      hidden: !permissions[Permissions.PAGES_USERS_DELETE],
      actionOnClick: handleOpenWarningModal,
      handleConfirm: handleDeleteUser,
    },
    {
      name: 'Impersonation',
      dataCy: 'users-menu-item-impersonate-button',
      hidden: !permissions[Permissions.PAGES_USERS_IMPERSONATION],
      actionOnClick: () => handleImpersonate({ userId: user.id, tenantId: null }),
    },
  ];

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={selectedItem?.description}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={selectedItem?.handleConfirm}
      />

      <UserChangePermissionsModal
        isOpen={isChangePermissionModalOpen}
        handleClose={handleCloseChangePermissionModal}
        userId={user.id}
        heading={`${t('Permissions')} ${name} ${surname}`}
        triggerRefetch={refetchUsersList}
      />

      <UserSettingsModal
        heading={`${t('EditUser')} ${name} ${surname}`}
        userId={user.id}
        handleClose={handleCloseEditModal}
        isOpen={isEditModalOpen}
        refetchListUsers={refetchUsersList}
      />

      <TableRow dataCy="users-table-row">
        <TableData dataCy="table-data-user-full-name">{`${name} ${surname}`}</TableData>
        <TableData>
          <p>{formatObjectsArrToStringWithSeparator(roles, 'roleName')}</p>
        </TableData>
        <TableData>{emailAddress}</TableData>
        <TableData>
          <Checkbox dataCy="email-confirmed-checkbox" defaultChecked={isEmailConfirmed} disabled />
        </TableData>
        <TableData>
          <Checkbox dataCy="is-active-checkbox" checked={isActive} disabled />
        </TableData>
        <TableData>
          <Checkbox checked={isLocked} disabled />
        </TableData>
        <TableData>{getFormattedDate(creationTime)}</TableData>
        <TableData>{getFormattedDate(lastLoginTime)}</TableData>
        <TableData>
          <DropdownMenu
            dataCy="users-dropdown-menu"
            onItemClick={handleSelectDropdownItem}
            elements={menuItemsAction}
          />
        </TableData>
      </TableRow>
    </>
  );
};

export default UsersRow;
