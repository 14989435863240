import { SUPPORT_TICKETS_TABS } from 'constants/support-tickets';
import Tabs from 'shared-components/Tabs';

const SupportTickets = () => {
  return (
    <div>
      <Tabs tabs={SUPPORT_TICKETS_TABS} />
    </div>
  );
};

export default SupportTickets;
