import { RESET_PASSWORD_PAGE_TITLE } from 'constants/reset-password';
import { ResetPasswordForm } from 'page-components/reset-password';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const ForgotPassword = () => {
  return (
    <AuthFormContainer title={RESET_PASSWORD_PAGE_TITLE}>
      <ResetPasswordForm />
    </AuthFormContainer>
  );
};

export default ForgotPassword;
