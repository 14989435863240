import { FC, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { SIDEBAR_ADMIN_LINKS, SIDEBAR_TENANT_LINKS } from 'constants/routes';
import { UserTypes } from 'constants/user';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';

import SidebarMenuItem from './SidebarMenuItem';

interface Props {
  isSidebarVisible?: boolean;
  userType: string;
}

const Sidebar: FC<Props> = ({ isSidebarVisible, userType }) => {
  const { pathname } = useLocation();

  const permissions = useAppSelector(selectUserPermissions);

  // TODO - Uncomment this when we have a tenant sidebar
  // const userType = useMemo(() => {
  //   return localStorage.getItem('userType') as UserTypes;
  // }, []);

  const navigationLinks = useMemo(() => {
    switch (userType) {
      case UserTypes.ADMIN:
        return SIDEBAR_ADMIN_LINKS;
      case UserTypes.TENANT:
        return SIDEBAR_TENANT_LINKS;
      default:
        return SIDEBAR_TENANT_LINKS;
    }
  }, [userType]);

  return (
    <div className="flex-grow w-full">
      {navigationLinks
        .filter((link) => {
          if (Array.isArray(link.permissionTag)) {
            return link.permissionTag.some((permission) => permissions[permission]);
          } else if (link.permissionTag) {
            return permissions[link.permissionTag];
          }
          return true;
        })

        .map((link) => {
          return (
            <SidebarMenuItem
              key={link.title}
              label={link.title}
              icon={link.icon}
              className="sidebar-menuItem !text-white w-full pl-[22px]"
              active={pathname === link.path}
              dropdownIcon={link.dropdownIcon}
              submenu={link.submenu}
              path={link.path}
              isSidebarVisible={isSidebarVisible}
              dataCy={link.dataCy}
            />
          );
        })}
    </div>
  );
};

export default Sidebar;
