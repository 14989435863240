import { useTranslation } from 'react-i18next';

import { BalanceData } from 'interfaces/integrations/truelayer.interface';
import { LabelWithValue } from 'shared-components';
import { cn, useFormatPriceFunction } from 'utils/global';

interface Props {
  accountId?: string;
  data?: BalanceData;
  isLoading: boolean;
  title: string;
}

const Balance = ({ accountId, data, title, isLoading }: Props) => {
  const { t } = useTranslation();

  const noData = 0;

  const sharedBodyClass = 'bg-lightGray3 dark:bg-darkBlue4 w-72 w-full';
  const sharedLabelWithValueBodyClass = 'block p-6 w-60';
  const sharedLabelWithValueLabelClass = 'mr-3 text-base font-semibold';
  const sharedLabelWithValueClass = 'mr-5 pt-3 text-4xl';
  const skeletonClassName = 'w-full';

  const current = useFormatPriceFunction(data?.current || noData, data?.currency);
  const available = useFormatPriceFunction(data?.available || noData, data?.currency);
  const overdraft = useFormatPriceFunction(data?.overdraft || noData, data?.currency);
  const creditLimit = useFormatPriceFunction(data?.creditLimit || noData, data?.currency);

  return (
    <>
      <div data-cy="balance-section">
        <div className="lg:w-full mb-12">
          <div className="pb-3 font-medium text-lg">{t(title)}</div>
          <div className="md:grid gap-y-4 gap-x-12 lg:gap-x-4 grid-cols-2 lg:flex justify-end">
            <div className={cn(sharedBodyClass)}>
              <LabelWithValue
                bodyClassName={cn(sharedLabelWithValueBodyClass)}
                labelClassName={cn('text-blue', sharedLabelWithValueLabelClass)}
                valueClassName={cn(sharedLabelWithValueClass)}
                withColon={false}
                label={'Current'}
                value={current}
                isLoading={isLoading}
                skeletonClassName={skeletonClassName}
              />
            </div>
            <div className={cn(sharedBodyClass)}>
              <LabelWithValue
                bodyClassName={cn(sharedLabelWithValueBodyClass)}
                labelClassName={cn('text-green', sharedLabelWithValueLabelClass)}
                valueClassName={cn(sharedLabelWithValueClass)}
                withColon={false}
                label={'Available'}
                value={available}
                isLoading={isLoading}
                skeletonClassName={skeletonClassName}
              />
            </div>
            <div className={cn(sharedBodyClass)}>
              {accountId ? (
                <LabelWithValue
                  bodyClassName={cn(sharedLabelWithValueBodyClass)}
                  labelClassName={cn('text-yellow', sharedLabelWithValueLabelClass)}
                  valueClassName={cn(sharedLabelWithValueClass)}
                  withColon={false}
                  label={'Overdraft'}
                  value={overdraft}
                  isLoading={isLoading}
                  skeletonClassName={skeletonClassName}
                />
              ) : (
                <LabelWithValue
                  bodyClassName={cn(sharedLabelWithValueBodyClass)}
                  labelClassName={cn('text-yellow', sharedLabelWithValueLabelClass)}
                  valueClassName={cn(sharedLabelWithValueClass)}
                  withColon={false}
                  label={'CreditLimit'}
                  value={creditLimit}
                  isLoading={isLoading}
                  skeletonClassName={skeletonClassName}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Balance;
