import TextPageLayout from 'shared-components/TextPageLayout';

const TermsAndConditions = () => (
  <TextPageLayout title="TermsAndConditions">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer mattis ornare arcu, tempus ornare purus accumsan
      nec. Pellentesque in auctor ligula. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
      cubilia curae; Duis tincidunt est sit amet felis porttitor, sit amet vehicula libero euismod. Integer laoreet
      venenatis lacus in ullamcorper. In non augue eget dolor commodo commodo id id nulla. Vivamus vitae consectetur
      lorem. Nulla lorem risus, malesuada vel enim non, porta consequat metus. Aenean feugiat varius turpis in sagittis.
      Nunc at mi nunc. Etiam at volutpat odio. Nam sit amet tortor tristique leo iaculis tincidunt. Quisque at neque eu
      libero cursus tincidunt. Aenean placerat tortor aliquam lectus vehicula lobortis. Mauris quis volutpat turpis.
      Mauris egestas diam turpis, ut tempus nunc interdum semper.
      <br />
      <br />
      In rutrum, est pellentesque lacinia volutpat, lectus diam interdum urna, vitae scelerisque ipsum elit in elit.
      Proin semper malesuada justo quis bibendum. Vivamus metus quam, bibendum cursus lacinia sed, molestie eget orci.
      Vivamus vel maximus ipsum. Mauris volutpat ut lorem sed maximus. Curabitur pulvinar arcu vel erat aliquet, in
      scelerisque neque mattis. In vel pulvinar ipsum. Cras viverra tempus sagittis. Etiam dapibus bibendum nisi.
      Curabitur quis lorem in magna iaculis tempus. Donec eleifend enim quis dolor dignissim commodo at et massa.
      Praesent vulputate ut lorem sed viverra. Fusce ut sapien commodo mauris facilisis sodales. Donec luctus et arcu et
      euismod. Nullam efficitur tellus nec dui tempus congue.
      <br />
      <br />
      In metus tortor, gravida ut tortor sed, euismod faucibus justo. Cras commodo sem nec nibh suscipit, convallis
      tincidunt tellus dictum. Donec imperdiet pellentesque lacus ut facilisis. Etiam commodo dui eget neque rutrum, sed
      ultricies turpis efficitur. Aenean ultricies sem sit amet arcu laoreet, finibus sollicitudin orci eleifend.
      Aliquam erat volutpat. In at lacus ut orci sollicitudin consectetur sit amet vitae enim.
      <br />
      <br />
      Phasellus pharetra pretium urna, ut blandit mi condimentum sit amet. Proin vestibulum lacinia dolor quis luctus.
      Ut sit amet tempor dui. Quisque sed velit eget libero hendrerit condimentum eu vitae mauris. Donec justo nisl,
      finibus ac aliquam sed, faucibus ut orci. Duis nec fermentum ligula. Ut accumsan orci lectus, et fermentum leo
      laoreet ac. Pellentesque volutpat ex mauris, sed varius magna venenatis eu. In dapibus ultricies tellus.
      <br />
      <br />
      Vivamus tristique orci mauris, id semper mi varius vel. Donec tempus mollis tincidunt. Integer velit nulla,
      aliquam ac vehicula eget, interdum at purus. Suspendisse libero ex, aliquam eu posuere imperdiet, euismod eget
      sem. Sed nec risus eleifend, tempor arcu a, rhoncus risus. Etiam at lacus malesuada, aliquet urna nec,
      pellentesque nibh. Phasellus lorem neque, vehicula ac aliquam ut, elementum vel nunc. Aliquam aliquet faucibus
      molestie. Curabitur ullamcorper, nulla sed sodales dictum, ex metus blandit nulla, at placerat erat risus vitae
      lacus. Vestibulum semper urna sed cursus porttitor. Pellentesque convallis sem eget libero dignissim, ut semper
      turpis ullamcorper.
    </p>
  </TextPageLayout>
);

export default TermsAndConditions;
