import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useGetOrganizationUnitsQuery, useGetOrganizationUnitsUsersQuery } from 'apis/organization-units.api';
import { MEMBERS_TABLE_COLUMNS } from 'constants/organization-units';
import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { SelectedUnitInfo } from 'interfaces/organization-units.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Button, LoadingOverlay, Pagination, Table } from 'shared-components';

import MembersTabRow from './MembersTabRow';
import SkeletonMembersTabRow from '../SkeletonTabRow';

type Props = {
  selectedUnit: SelectedUnitInfo | null;
  handleOpenAddMemberModal: VoidFunction;
};

const MembersTab = ({ selectedUnit, handleOpenAddMemberModal }: Props) => {
  const { t } = useTranslation();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const permissions = useAppSelector(selectUserPermissions);
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { filterParams } = useFilters();
  const { data: treeData } = useGetOrganizationUnitsQuery();
  const { data, isLoading, isFetching, isUninitialized, refetch } = useGetOrganizationUnitsUsersQuery(
    {
      userId: selectedUnit?.id,
      params: `${filterParams}${sortingParams}${paginationParams}`,
    },
    {
      skip: !selectedUnit?.id,
    }
  );
  const { result } = data || {};

  return (
    <div data-cy="members-tab" className="relative">
      {isLoading && (
        <Table columns={MEMBERS_TABLE_COLUMNS} isLoading={true}>
          <SkeletonMembersTabRow />
        </Table>
      )}
      {!isLoading && treeData?.result?.items.length ? (
        <>
          <LoadingOverlay isLoading={isFetching && !!data} />
          {permissions[Permissions.PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_MEMBERS] && (
            <Button
              className="absolute right-0 -top-7 -translate-y-[100%]"
              dataCy="members-tab-add-member-button"
              type="button"
              onClick={handleOpenAddMemberModal}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" className="text-white mr-1" />
              {t('AddMember')}
            </Button>
          )}

          <Table
            dataCy="members-table"
            columns={MEMBERS_TABLE_COLUMNS}
            handleSetTabName={handleSetTabName}
            sortingColumnId={sortingColumnId}
            isTableEmpty={!result?.items.length}
            sortingType={sortingType}
            isLoading={isLoading && isUninitialized}
          >
            {result?.items.map((item) => (
              <MembersTabRow key={item.id} userData={item} refetchMembers={refetch} selectedUnit={selectedUnit} />
            ))}
          </Table>

          <Pagination
            totalCount={result?.totalCount}
            handleSetMaxResultCount={handleSetMaxResultCount}
            maxResultCount={maxResultCount}
            handleSetCurrentPage={handleSetCurrentPage}
            currentPage={currentPage}
          />
        </>
      ) : (
        <p className="text-gray2 text-sm">{t('SelectAnOrganizationUnitToSeeMembers')}</p>
      )}
    </div>
  );
};

export default MembersTab;
