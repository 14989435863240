import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import check from 'assets/svg/check-converder.svg';
import { Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';

export interface Props {
  successMessage?: string;
  isOpen: boolean;
  handleClose: VoidFunction;
  description?: string;
}

const SuccessModal: FC<Props> = ({ successMessage = '', description, isOpen, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} icon={check} isError hideCross isDisableOutsideClick onClose={handleClose}>
      <div className="text-2xl font-semibold text-center">{t(successMessage)}</div>
      {description && <div className="text-center mt-4">{t(description)}</div>}

      <ModalControlButtons isError onClose={handleClose} />
    </Modal>
  );
};

export default SuccessModal;
