import { useTranslation } from 'react-i18next';

import { useLazyGetInvoicesPdfQuery } from 'apis/subscriptions-tenant.api';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { HistoryItem, PERIOD, STATUS } from 'interfaces/subscriptions-tenant.interface';
import { DropdownMenu, ErrorModal, TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { downloadDocument, useFormatPriceFunction } from 'utils/global';

interface Props {
  paymentHistoryData: HistoryItem;
  refetchPayment: VoidFunction;
}

const PaymentHistoryRow = ({ paymentHistoryData, refetchPayment }: Props) => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { subscriptionName, creationTime, amount, status, subscriptionPlanPeriodId, id } = paymentHistoryData;

  const [getInvoicesPdf, { error: downloadInvoiceError }] = useLazyGetInvoicesPdfQuery();
  const errorDownloadInvoiceErrorData = (downloadInvoiceError as CommonError)?.data || {};
  const errorData = errorDownloadInvoiceErrorData;

  const menuItemsAction = [
    {
      dataCy: 'payment-history-download-button',
      name: t('DownloadAsPdf'),
      actionOnClick: () => handleDownloadDocument(),
    },
  ];

  const handleDownloadDocument = async () => {
    try {
      refetchPayment();

      const { result: pdf } = await getInvoicesPdf(id).unwrap();
      if (pdf) {
        downloadDocument(pdf.content, pdf.fileName);
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <TableRow dataCy="payment-history-table-row">
        <TableData dataCy="payment-history-subscription-name-row">{subscriptionName}</TableData>
        <TableData dataCy="payment-history-process-time-row">
          {getFormattedDate(creationTime, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="payment-history-amount-row">{useFormatPriceFunction(amount)}</TableData>
        <TableData dataCy="payment-history-status-row">{STATUS[status]}</TableData>
        <TableData dataCy="payment-history-period-row">{PERIOD[subscriptionPlanPeriodId]}</TableData>
        <TableData>
          <DropdownMenu dataCy="payment-history-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default PaymentHistoryRow;
