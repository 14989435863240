import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { BoxVariants } from 'constants/common';
import { Box } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  title?: string;
  stat?: ReactNode;
  description?: string;
  children: ReactNode;
}

const TopStatsBox = ({ title, description, stat: Stat, children }: Props) => {
  const { t } = useTranslation();

  return (
    <Box dataCy="top-stats-box" className="relative h-full" variant={BoxVariants.ROUNDED}>
      <div className={cn('flex justify-between', { 'flex-col lg:flex-row gap-2 flex-wrap mb-10': Stat })}>
        {title && <h3 className="text-sm font-semibold whitespace-nowrap">{t(title)}</h3>}
        {Stat}
      </div>
      <p className="text-sm text-gray2 font-semibold mb-5">{description}</p>
      <div>{children}</div>
    </Box>
  );
};

export default TopStatsBox;
