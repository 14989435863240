import {
  OsAuditLogsResponse,
  OsChangeLogsResponse,
  EntityPropertyChangeResponse,
  OsAuditLogsToExcelResponse,
  ChangeProperties,
} from 'interfaces/integrations/os-audit-logs.interface';

import { coreSplitApis } from './core.api';

export const osAuditLogsApi = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getOsAuditLogs: build.query<OsAuditLogsResponse, string>({
      query: (params) => ({
        url: `OsAuditLog/GetAuditLogs?${params}`,
        method: 'GET',
      }),
    }),
    getOsEntityChanges: build.query<OsChangeLogsResponse, string>({
      query: (params) => ({
        url: `OsAuditLog/GetEntityChanges?${params}`,
        method: 'GET',
      }),
    }),
    getChangeLogProperties: build.query<ChangeProperties, void>({
      query: () => ({
        url: 'OsAuditLog/GetChangeLogProperties',
        method: 'GET',
      }),
    }),
    getAuditLogProperties: build.query<ChangeProperties, void>({
      query: () => ({
        url: 'OsAuditLog/GetAuditLogProperties',
        method: 'GET',
      }),
    }),
    getOsEntityPropertyChanges: build.query<EntityPropertyChangeResponse, number>({
      query: (logId) => ({
        url: `OsAuditLog/GetEntityPropertyChanges?entityChangeId=${logId}`,
        method: 'GET',
      }),
    }),
    getOsAuditLogsToExcel: build.query<OsAuditLogsToExcelResponse, string>({
      query: (params) => ({
        url: `OsAuditLog/GetAuditLogsToExcel?${params}`,
        method: 'GET',
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
    getOsEntityChangesToExcel: build.query<OsAuditLogsToExcelResponse, string>({
      query: (params) => ({
        url: `OsAuditLog/GetEntityChangesToExcel?${params}`,
        method: 'GET',
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetOsAuditLogsQuery,
  useLazyGetOsAuditLogsQuery,
  useLazyGetOsEntityChangesQuery,
  useLazyGetChangeLogPropertiesQuery,
  useLazyGetAuditLogPropertiesQuery,
  useGetOsEntityPropertyChangesQuery,
  useLazyGetOsAuditLogsToExcelQuery,
  useLazyGetOsEntityChangesToExcelQuery,
} = osAuditLogsApi;
