import { useTranslation } from 'react-i18next';

import {
  useExportMyPersonalInfoToExcelMutation,
  useCreateForgetUserRequestMutation,
  useGetForgetMyUserRequestDetailsQuery,
} from 'apis/users.api';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Button, ErrorModal, LoadingOverlay } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { downloadDocument } from 'utils/global';

const ManagePersonalInformationSetting = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [exportMyPersonalInfoToExcel, { isLoading: isExportPersonalInfoLoading, error: isExportPersonalInfoError }] =
    useExportMyPersonalInfoToExcelMutation();
  const [createForgetUserRequest, { isLoading: isForgetUserRequestLoading, error: forgetUserRequestError }] =
    useCreateForgetUserRequestMutation();
  const { data, refetch, isLoading, isFetching } = useGetForgetMyUserRequestDetailsQuery();

  const errorExportPersonalInfoData = (isExportPersonalInfoError as CommonError)?.data || {};
  const errorForgetUserRequestData = (forgetUserRequestError as CommonError)?.data || {};

  const errorData = errorExportPersonalInfoData || errorForgetUserRequestData;

  const { dateCreated, isRequested } = data?.result || {};

  const handleDownloadPersonalInformation = async () => {
    try {
      const response = await exportMyPersonalInfoToExcel();

      if ('data' in response) {
        const result = response.data;
        downloadDocument(result.result.context, result.result.fileType, result.result.fileName);
      }
    } catch {
      handleOpenErrorModal();
    }
  };

  const handleForgetUser = async () => {
    try {
      await createForgetUserRequest();
      refetch();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <LoadingOverlay className="!h-3/4" isLoading={isFetching && !!data} />
      <div className="p-5 text-sm dark:text-gray text-black" data-cy="manage-personal-information-tab-content">
        <div className="pb-8">
          <h2 className=""> {t('YouCanDownloadAndCheckAllYourPersonalInfoThatWeStoreInOurSystem')}</h2>
          <Button
            isLoading={isExportPersonalInfoLoading}
            dataCy="download-personal-information-button"
            className="h-10 ml-2 mt-4"
            type="button"
            onClick={handleDownloadPersonalInformation}
          >
            {t('Download')}
          </Button>
        </div>
        <div>
          <h2 className="mb-4"> {t('YouCanSendRequestToDeleteYourAccount')}</h2>
          <div className="ml-2">
            {isRequested ? (
              <span className="text-darkGray dark:text-white" data-cy="requested-on-date">
                {t('RequestedOn')} {getFormattedDate(dateCreated, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
              </span>
            ) : (
              <Button
                isLoading={isForgetUserRequestLoading || isLoading}
                data-cy="request-to-be-forgotten-button"
                className="h-10"
                type="button"
                onClick={handleForgetUser}
              >
                {t('CreateRequestToBeForgotten')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePersonalInformationSetting;
