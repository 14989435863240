import {
  CONTACTS_COLUMNS,
  INVOICE_COLUMNS,
  INVOICES_COLUMNS,
  StatusAndTypesData,
} from 'interfaces/integrations/sage.interface';
import { BaseFilterValues, FieldTypes, FormItem, OptionType, TabItem, TableColumn } from 'interfaces/shared.interface';
import Contacts from 'page-components/integrations/sage/contacts/Contacts';
import Invoices from 'page-components/integrations/sage/invoices/Invoices';
import { SelectFormInput } from 'shared-components';
import DatePickerInput from 'shared-components/DatePickerInput';

import { BASE_FILTER_FIELDS } from './common';

export const SAGE_TABS: TabItem[] = [
  {
    dataCy: 'invoices-tab-button',
    id: 'invoices',
    title: 'Invoices',
    component: Invoices,
  },
  {
    dataCy: 'cards-tab-button',
    id: 'contacts',
    title: 'Contacts',
    component: Contacts,
  },
];

export const getInvoicesFiltersFieldsDefaultValue = (selectStatusLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_FROM]: '',
  [INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_TO]: '',
  [INVOICES_FILTERS_FORM_FIELD_NAMES.STATUS]: { label: selectStatusLabel, value: '' },
});

export enum INVOICES_FILTERS_FORM_FIELD_NAMES {
  ISSUE_DATE_FROM = 'issueDateFrom',
  ISSUE_DATE_TO = 'issueDateTo',
  STATUS = 'status',
}

export interface InvoicesFilterFormValues extends BaseFilterValues {
  [INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_FROM]: string;
  [INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_TO]: string;
  [INVOICES_FILTERS_FORM_FIELD_NAMES.STATUS]: OptionType;
}

export const SEARCH_INVOICES_PLACEHOLDER = 'SearchByInvoiceReferenceOrContactName';

export const getInvoicesFilterFormFields = (invoicesStatusedOptions?: StatusAndTypesData[]): FormItem[] => {
  return [
    {
      name: INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_TO,
      label: 'IssueDateTo',
      type: FieldTypes.DATE,
      inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
      dataCy: 'invoice-issued-date-field',
      className: 'pl-10 w-64',
      component: DatePickerInput,
    },
    {
      name: INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_FROM,
      label: 'IssueDateFrom',
      type: FieldTypes.DATE,
      inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
      dataCy: 'invoice-due-date-field',
      className: 'mb-4 pl-10 w-64',
      component: DatePickerInput,
    },
    {
      name: INVOICES_FILTERS_FORM_FIELD_NAMES.STATUS,
      label: 'SelectStatus',
      type: FieldTypes.SELECT,
      inputClassName: 'w-full mt-2',
      labelClassName: 'flex',
      dataCy: 'invoice-supplier-name-field',
      className: 'pl-10 w-40 w-52 ',
      component: SelectFormInput,
      isFilter: true,
      options: invoicesStatusedOptions?.map((statuses) => ({
        label: statuses.name,
        value: statuses.id.toString(),
      })),
    },
  ];
};

export const INVOICES_COLUMNS_TITLES = {
  [INVOICES_COLUMNS.REFERENCE]: 'Reference',
  [INVOICES_COLUMNS.CONTACT_NAME]: 'ContactName',
  [INVOICES_COLUMNS.TOTAL_AMOUNT]: 'TotalAmount',
  [INVOICES_COLUMNS.ISSUE_DATE]: 'IssueDate',
  [INVOICES_COLUMNS.DUE_DATE]: 'DueDate',
  [INVOICES_COLUMNS.STATUS]: 'Status',
  [INVOICES_COLUMNS.ACTION]: '',
};

export const INVOICES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICES_COLUMNS.REFERENCE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.REFERENCE],
    width: 'w-1/12',
  },
  {
    columnId: INVOICES_COLUMNS.CONTACT_NAME,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.CONTACT_NAME],
    width: 'w-5/12',
  },

  {
    columnId: INVOICES_COLUMNS.TOTAL_AMOUNT,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.TOTAL_AMOUNT],
    width: 'w-2/12',
  },
  {
    columnId: INVOICES_COLUMNS.ISSUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.ISSUE_DATE],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.DUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.DUE_DATE],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.STATUS,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.STATUS],
    width: 'w-2/12',
    className: 'm-auto',
  },
  {
    columnId: INVOICES_COLUMNS.ACTION,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.ACTION],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const getContactsFiltersFieldsDefaultValue = (selectStatusLabel: string, selectTypeLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [CONTACTS_FILTERS_FORM_FIELD_NAMES.STATUS]: { label: selectStatusLabel, value: '' },
  [CONTACTS_FILTERS_FORM_FIELD_NAMES.TYPE]: { label: selectTypeLabel, value: '' },
});

export const CONTACTS_COLUMNS_TITLES = {
  [CONTACTS_COLUMNS.NAME]: 'Name',
  [CONTACTS_COLUMNS.REFERENCE]: 'Reference',
  [CONTACTS_COLUMNS.TYPE]: 'Type',
  [CONTACTS_COLUMNS.STATUS]: 'Status',
  [CONTACTS_COLUMNS.ACTION]: '',
};

export const CONTACTS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CONTACTS_COLUMNS.NAME,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.NAME],
    width: 'w-5/12',
  },
  {
    columnId: CONTACTS_COLUMNS.REFERENCE,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.REFERENCE],
    width: 'w-52',
  },

  {
    columnId: CONTACTS_COLUMNS.TYPE,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.TYPE],
    width: 'w-1/4',
  },
  {
    columnId: CONTACTS_COLUMNS.STATUS,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.STATUS],
    width: 'w-36',
  },
  {
    columnId: CONTACTS_COLUMNS.ACTION,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.ACTION],
    width: 'w-14',
  },
];

const STATUS_FILTERS_OPTIONS = [
  { label: 'All', value: true },
  { label: 'Active', value: false },
];

export enum CONTACTS_FILTERS_FORM_FIELD_NAMES {
  STATUS = 'status',
  TYPE = 'type',
}

export const getContactsFilterFormFields = (contactsTypeOptions?: StatusAndTypesData[]): FormItem[] => {
  return [
    {
      name: CONTACTS_FILTERS_FORM_FIELD_NAMES.STATUS,
      label: 'SelectStatus',
      type: FieldTypes.SELECT,
      inputClassName: 'w-full mt-2',
      labelClassName: 'flex',
      dataCy: 'invoice-supplier-name-field',
      className: 'pl-10 w-40 w-52 ',
      component: SelectFormInput,
      isFilter: true,
      options: [...STATUS_FILTERS_OPTIONS],
    },
    {
      name: CONTACTS_FILTERS_FORM_FIELD_NAMES.TYPE,
      label: 'SelectStatus',
      type: FieldTypes.SELECT,
      inputClassName: 'w-full mt-2',
      labelClassName: 'flex',
      dataCy: 'invoice-supplier-name-field',
      className: 'pl-10 w-40 w-52 ',
      component: SelectFormInput,
      isFilter: true,
      options: contactsTypeOptions?.map((type) => ({
        label: type.name,
        value: type.id.toString(),
      })),
    },
  ];
};

export interface ContactsFilterFormValues extends BaseFilterValues {
  [CONTACTS_FILTERS_FORM_FIELD_NAMES.STATUS]: OptionType;
  [CONTACTS_FILTERS_FORM_FIELD_NAMES.TYPE]: OptionType;
}
export const SEARCH_CONTACTS_PLACEHOLDER = 'SearchByContactNameOrReference';

export const INVOICE_COLUMNS_TITLES = {
  [INVOICE_COLUMNS.DESCRIPTION]: 'Description',
  [INVOICE_COLUMNS.QUANTITY]: 'Qty',
  [INVOICE_COLUMNS.UNIT_PRICE]: 'UnitPrice',
  [INVOICE_COLUMNS.NET_AMOUNT]: 'NetAmount',
  [INVOICE_COLUMNS.TAX_AMOUNT]: 'TaxAmount',
  [INVOICE_COLUMNS.TOTAL_AMOUNT]: 'TotalAmount',
  [INVOICE_COLUMNS.DISCOUNT_AMOUNT]: 'DiscountAmount',
};

export const INVOICE_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICE_COLUMNS.DESCRIPTION,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.DESCRIPTION],
  },
  {
    columnId: INVOICE_COLUMNS.QUANTITY,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.QUANTITY],
  },
  {
    columnId: INVOICE_COLUMNS.UNIT_PRICE,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.UNIT_PRICE],
  },
  {
    columnId: INVOICE_COLUMNS.NET_AMOUNT,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.NET_AMOUNT],
  },
  {
    columnId: INVOICE_COLUMNS.TAX_AMOUNT,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.TAX_AMOUNT],
  },
  {
    columnId: INVOICE_COLUMNS.TOTAL_AMOUNT,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.TOTAL_AMOUNT],
  },
  {
    columnId: INVOICE_COLUMNS.DISCOUNT_AMOUNT,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.DISCOUNT_AMOUNT],
  },
];

export const sharedSkeletonStyle = 'w-40 h-50 ml-0';
export const sharedLabelStyle = 'mb-2 lg:w-1/2 w-full';
export const sharedValueStyle = 'mb-2 break-normal lg:w-1/2 w-full block';
export const sharedBodyStyle = 'lg:flex block text mx-2';
