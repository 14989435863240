import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { DOCUSIGN_REQUEST_FORM_FIELD } from 'constants/docusign';
import { ROUTES } from 'constants/routes';
import { BaseCreateRequestFormValue, DOCUSIGN_REQUEST_FORM_FIELDS } from 'interfaces/integrations/docusign.interface';
import { Modal, FormContent, DragAndDropFileUploader } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { convertFileToBase64 } from 'utils/files';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const CreateRequestModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const navigate = useNavigate();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [fileData, setFileData] = useState<File | null>(null);
  const [, setFileName] = useState<string | null>(null);
  const { ...createDocumentTypeMethods } = useForm<BaseCreateRequestFormValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    reset,
    watch,
    formState: { errors },
  } = createDocumentTypeMethods;

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const watchDocumentName = watch(DOCUSIGN_REQUEST_FORM_FIELDS.DOCUMENT_NAME);
  const watchEmailSubject = watch(DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_SUBJECT);

  const onSubmit = async () => {
    if (!watchDocumentName || !watchEmailSubject || !isFileUploaded || !fileData) {
      return;
    }
    const fileBase64 = await convertFileToBase64(fileData);
    navigate(ROUTES.docusignNewDocument, {
      state: {
        documentName: watchDocumentName,
        emailSubject: watchEmailSubject,
        emailMessage: watch(DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_MESSAGE),
        fileData: {
          fileUrl: URL.createObjectURL(fileData),
          fileBase64: fileBase64,
          fileName: fileData.name,
        },
      },
    });
  };

  const uploadFile = (files: FileList) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result?.toString().split(',')[1];
      if (base64String) {
        localStorage.setItem('fileData', base64String);
        setFileName(file.name);
        setFileData(file);
        setIsFileUploaded(true);
      }
    };

    reader.readAsDataURL(file);
  };

  const removeFile = () => {
    setFileData(null);
    setIsFileUploaded(false);
    localStorage.removeItem('fileData');
  };

  return (
    <>
      <Modal
        dataCy="create-request-modal"
        isOpen={isOpen}
        isDisableOutsideClick
        onClose={onCloseModal}
        heading="Create"
        bodyClassName="md:w-1/2 xl:w-2/4"
      >
        <FormProvider {...createDocumentTypeMethods} reset={reset}>
          <form data-cy="create-request-modal-form">
            <FormContent fields={[DOCUSIGN_REQUEST_FORM_FIELD()[0]]} register={register} errors={errors} />
            <DragAndDropFileUploader
              handleUpload={uploadFile}
              handleRemove={removeFile}
              buttonText={'BrowseFiles'}
              fileType="pdf"
              className="mb-3"
            />
            <FormContent fields={DOCUSIGN_REQUEST_FORM_FIELD().slice(1, 3)} register={register} errors={errors} />
            <ModalControlButtons
              disabled={!watchDocumentName || !watchEmailSubject || !isFileUploaded}
              isShownSubmit
              onSubmit={onSubmit}
              onClose={onCloseModal}
              saveButtonText={'Create'}
            />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CreateRequestModal;
