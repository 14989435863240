import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

interface Props {
  infoData: { label: string; value: string | number | null }[];
  className?: string;
}

const STYLES = 'text-sm font-semibold mr-1 mb-2';

const ChangeLogsBlockInfo = ({ infoData, className }: Props) => {
  const { t } = useTranslation();
  return (
    <div data-cy="logs-block-information" className={cn('p-4 border border-lightGray8 bg-lightGray7', className)}>
      {infoData?.map((info) => (
        <div key={info.value} className="flex">
          <div className={STYLES}>{t(info.label)} :</div>
          <div className={STYLES}>{info.value && t(info.value.toString())}</div>
        </div>
      ))}
    </div>
  );
};

export default ChangeLogsBlockInfo;
