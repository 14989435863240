import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateOrganizationUnitMutation } from 'apis/organization-units.api';
import { TENANT_ORGANIZATION_UNIT_FIELDS } from 'constants/organization-units';
import { useOpen } from 'hooks/useOpen';
import {
  ORGANIZATION_UNITS_REQUEST_FORM_FIELDS,
  UpdateOrganizationUnitPayload,
} from 'interfaces/organization-units.interface';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, FormContent, Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

interface Props {
  handleClose: VoidFunction;
  refetchListRoot: VoidFunction;
  isOpen: boolean;
  selectedUnitId: number;
  selectedUnitLabel: string;
}
const UpdateOrganizationUnitModal = ({
  handleClose,
  isOpen,
  refetchListRoot,
  selectedUnitId,
  selectedUnitLabel,
}: Props) => {
  const { t } = useTranslation();
  const [updateSupportRequest, { isLoading: isUpdateOrganizationLoading, error }] = useUpdateOrganizationUnitMutation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const updateSupportRequestMethods = useForm<UpdateOrganizationUnitPayload>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = updateSupportRequestMethods;

  const { data: errorData } = (error as CommonError) || {};

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmit = async (data: UpdateOrganizationUnitPayload) => {
    const payload: UpdateOrganizationUnitPayload = {
      displayName: data[ORGANIZATION_UNITS_REQUEST_FORM_FIELDS.DISPLAY_NAME],
      id: selectedUnitId,
    };
    try {
      await updateSupportRequest(payload);
      refetchListRoot();
      onCloseModal();
      successNotify(t('SavedSuccessfully'));
    } catch (error) {
      handleOpenErrorModal();
      console.error(error);
    }
  };

  const headerName = (
    <p className="modal-heading">
      {t('Edit')}:&nbsp;<span>{selectedUnitLabel}</span>
    </p>
  );

  useEffect(() => {
    if (selectedUnitLabel) {
      setValue(ORGANIZATION_UNITS_REQUEST_FORM_FIELDS.DISPLAY_NAME, selectedUnitLabel);
    }
  }, [selectedUnitLabel, setValue]);

  return (
    <>
      <Modal dataCy="update-root-unit-modal" isOpen={isOpen} onClose={onCloseModal} heading={headerName}>
        <FormProvider {...updateSupportRequestMethods}>
          <form data-cy="update-root-unit-modal-form" onSubmit={handleSubmit(onSubmit)}>
            <FormContent fields={TENANT_ORGANIZATION_UNIT_FIELDS} register={register} errors={errors} />
            <ModalControlButtons
              isShownSubmit
              className="pb-5"
              saveButtonText="Save"
              closeButtonText="Cancel"
              isLoading={isUpdateOrganizationLoading}
              onClose={onCloseModal}
            />
          </form>
        </FormProvider>
      </Modal>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default UpdateOrganizationUnitModal;
