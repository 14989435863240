import { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetSageConnectionDetailsQuery, usePostSageConnectionMutation } from 'apis/sage';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import SageConnectionHandler from 'page-components/integrations/sage/SageConnectionHandler';
import { ErrorModal, InfoModal, LoadingOverlay } from 'shared-components';

const Sage = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isInfoModalOpen, handleOpenInfoModal, handleCloseInfoModal] = useOpen();

  const { data: connectionData, isFetching, error: getConnectionError, refetch } = useGetSageConnectionDetailsQuery();
  const { data: getConnectionErrorData } = (getConnectionError as CommonError) || {};
  const navigate = useNavigate();
  const [postSageConnection] = usePostSageConnectionMutation();

  const [params] = useSearchParams();
  const connectionCode = params.get('code');

  useEffect(() => {
    if (getConnectionError) {
      handleOpenErrorModal();
    }
    if (connectionData?.result?.isConnectionExpired) {
      handleOpenInfoModal();
    }
  }, [connectionData?.result?.isConnectionExpired, getConnectionError, handleOpenErrorModal, handleOpenInfoModal]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!connectionCode) {
          return;
        }

        await postSageConnection({ connectionCode }).unwrap();
        refetch();
        navigate(ROUTES.sage);
      } catch (error) {
        handleOpenErrorModal();
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <InfoModal
        infoMessage="ConnectionExpired"
        infoDescription={'SageConnectionExpiredDescription'}
        isOpen={isInfoModalOpen}
        handleClose={handleCloseInfoModal}
      />
      <ErrorModal
        errorMessage={getConnectionErrorData?.error?.message}
        description={getConnectionErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div className="relative" data-cy="sage-page">
        <LoadingOverlay isLoading={isFetching && !!connectionData} />
        <SageConnectionHandler
          connectionData={connectionData}
          isConnect={connectionData?.result.isConnected || false}
          triggerRefetch={refetch}
          isLoading={isFetching}
        />
      </div>
    </>
  );
};

export default Sage;
