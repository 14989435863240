import { useTranslation } from 'react-i18next';

import { usePostDisconnectAccountMutation, useLazyGetBankAuthUrlQuery } from 'apis/truelayer.api';
import { TRUELAYER_TABS } from 'constants/truelayer';
import { useOpen } from 'hooks/useOpen';
import { GetConnectionDetailsResponse } from 'interfaces/integrations/truelayer.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal, WarningModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';
import { getTrueLayerLink } from 'utils/url';

interface Props {
  isConnect: boolean;
  triggerRefetch: () => void;
  connectionData?: GetConnectionDetailsResponse;
}

const TrueLayerConnectionHandler = ({ isConnect, triggerRefetch, connectionData }: Props) => {
  const { t } = useTranslation();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [disconnectTrueLayer, { error: disconnectAccountError, isLoading: isPostDisconnectLoading }] =
    usePostDisconnectAccountMutation();
  const [getBankUrl, { error: bankAuthUrlError }] = useLazyGetBankAuthUrlQuery();

  const errorDisconnectAccountData = (disconnectAccountError as CommonError)?.data || {};
  const errorBankAuthUrlData = (bankAuthUrlError as CommonError)?.data || {};

  const errorData = errorDisconnectAccountData || errorBankAuthUrlData;

  const handleConnectTrueLayer = async () => {
    try {
      const { data } = await getBankUrl();
      const link = getTrueLayerLink(data?.result || '');
      window.location.href = link;
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleDisconnectTrueLayer = async () => {
    try {
      await disconnectTrueLayer().unwrap();
      triggerRefetch();
      handleCloseWarningModal();
      successNotify(t('SuccessfullyDisconnectedOpenBanking'));
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('OpenBankingDisconnectWarningMessage')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDisconnectTrueLayer}
      />
      {!isConnect ? (
        <Box>
          <Button data-cy="connect-truelayer-button" onClick={handleConnectTrueLayer}>
            {t('ConnectTrueLayer')}
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <div className="grid grid-cols-2">
              <div className="col-span-1 flex flex-col justify-center">
                <p className="mb-2">{t('TrueLayerAccountConnected')}.</p>
                <p>{t('ConnectionExpiresInDays', { 0: connectionData?.result.expiresInDays })}.</p>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Button
                  className="float-right"
                  isLoading={isPostDisconnectLoading}
                  data-cy="disconnect-truelayer-button"
                  onClick={handleOpenWarningModal}
                >
                  {t('DisconnectTrueLayer')}
                </Button>
              </div>
            </div>
          </Box>
          <div className="pt-5">
            <Tabs tabs={TRUELAYER_TABS} />
          </div>
        </>
      )}
    </>
  );
};

export default TrueLayerConnectionHandler;
