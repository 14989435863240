import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { InvoiceData } from 'interfaces/integrations/sage.interface';
import { DropdownMenu, TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { useFormatPriceFunction } from 'utils/global';
import { getSageInvoiceDetails } from 'utils/url';

interface Props {
  InvoiceData: InvoiceData;
}

const InvoicesRow = ({ InvoiceData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id, reference, contactName, totalAmount, issueDate, dueDate, status } = InvoiceData;

  const menuItemsAction = [
    {
      dataCy: 'invoices-menu-item-details-button',
      name: t('Details'),
      actionOnClick: () => {
        navigate(getSageInvoiceDetails(id));
      },
    },
  ];
  return (
    <>
      <TableRow dataCy="invoices-table-row">
        <TableData dataCy="invoices-reference">{reference}</TableData>
        <TableData dataCy="invoices-contact-name">{contactName}</TableData>
        <TableData dataCy="invoices-total-amount">{useFormatPriceFunction(totalAmount)}</TableData>
        <TableData dataCy="invoices-issue-date">
          {getFormattedDate(issueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="invoices-due-date">
          {getFormattedDate(dueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="invoices-status">{t(status.name)}</TableData>
        <TableData>
          <DropdownMenu dataCy="invoices-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default InvoicesRow;
