import { useTranslation } from 'react-i18next';

import { NO_DATA } from 'constants/common';
import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { RepaymentDetails } from 'interfaces/integrations/gocardless.interface';
import { LabelWithValue } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

import { sharedBodyClass, sharedLabelClass, sharedValueClass } from '../const';

interface Props {
  isError?: boolean;
  data?: RepaymentDetails;
  isLoading: boolean;
}

const MandateInformation = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();

  const result = data?.mandate;

  return (
    <div className="p-4">
      <LabelWithValue
        bodyClassName={sharedBodyClass}
        labelClassName={sharedLabelClass}
        valueClassName={sharedValueClass}
        withColon={true}
        label={t('Mandate')}
        value={t(result?.mandate || NO_DATA)}
        isLoading={isLoading}
      />
      <LabelWithValue
        bodyClassName={sharedBodyClass}
        labelClassName={sharedLabelClass}
        valueClassName={sharedValueClass}
        withColon={true}
        label={t('Status')}
        value={t(result?.status || NO_DATA)}
        isLoading={isLoading}
      />
      <LabelWithValue
        bodyClassName={sharedBodyClass}
        labelClassName={sharedLabelClass}
        valueClassName={sharedValueClass}
        withColon={true}
        label={t('CreationTime')}
        value={getFormattedDate(result?.createdDate, DAY_FORMAT_WITH_TIME)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default MandateInformation;
