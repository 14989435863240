import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useGetCardBalanceQuery, useGetCardInformationQuery } from 'apis/truelayer.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import TrueLayerDetails from 'page-components/integrations/truelayer/details/TrueLayerDetailsLayout';
import { ErrorModal } from 'shared-components';

const TrueLayerCardDetailsPage = () => {
  const { cardId } = useParams();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    data: cardInformationData,
    isLoading: isCardInformationLoading,
    error: cardInformationError,
  } = useGetCardInformationQuery(cardId ?? '');

  const {
    data: cardBalanceData,
    isLoading: isCardBalanceLoading,
    error: cardBalanceError,
  } = useGetCardBalanceQuery(cardId ?? '');

  const informationErrorData = (cardInformationError as CommonError)?.data;
  const balanceErrorData = (cardBalanceError as CommonError)?.data;

  const errorMessage = informationErrorData?.error?.message || balanceErrorData?.error?.message;
  const errorDetails = informationErrorData?.error?.details || balanceErrorData?.error?.details;

  useEffect(() => {
    if (informationErrorData || balanceErrorData) {
      handleOpenErrorModal();
    }
  }, [informationErrorData, balanceErrorData, handleOpenErrorModal]);

  return (
    <>
      <ErrorModal
        errorMessage={errorMessage}
        description={errorDetails}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <TrueLayerDetails
        informationData={cardInformationData?.result?.data}
        balanceData={cardBalanceData?.result?.data}
        cardId={cardId}
        isLoading={isCardBalanceLoading || isCardInformationLoading}
        informationTitle="CardInformation"
        balanceTitle="CardBalance"
        transactionsTitle="CardTransactions"
      />
    </>
  );
};

export default TrueLayerCardDetailsPage;
