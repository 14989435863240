import { CREATE_ROLES_FORM_FIELDS, ROLES_COLUMNS, ROLES_FILTERS_FORM_FIELD_NAMES } from 'interfaces/roles.interface';
import { FieldTypes, FormItem, TabItem, TableColumn } from 'interfaces/shared.interface';
import PermissionsTabContent from 'page-components/roles/permissions/PermissionsTabContent';
import RoleNameTabContent from 'page-components/roles/RoleNameTabContent';
import { Checkbox, Input, SelectFormInput } from 'shared-components';

import { BASE_FILTER_FIELDS, BASE_PERMISSIONS_FROM_FIELD_NAME } from './common';

export const ROLES_COLUMNS_TITLES = {
  [ROLES_COLUMNS.ROLE_NAME]: 'RoleName',
  [ROLES_COLUMNS.STATIC]: 'Static',
  [ROLES_COLUMNS.DEFAULT]: 'Default',
  [ROLES_COLUMNS.CREATION_TIME]: 'CreationTime',
  [ROLES_COLUMNS.ACTIONS]: '',
};

export const getRolesTableColumns = (permissions: boolean): TableColumn[] => [
  {
    columnId: ROLES_COLUMNS.ROLE_NAME,
    title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.ROLE_NAME],
    width: 'w-8/12',
    sorting: true,
  },
  {
    columnId: ROLES_COLUMNS.STATIC,
    title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.STATIC],
    width: 'w-1/12',
  },
  {
    columnId: ROLES_COLUMNS.DEFAULT,
    title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.DEFAULT],
    width: 'w-1/12',
  },
  {
    columnId: ROLES_COLUMNS.CREATION_TIME,
    title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.CREATION_TIME],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: ROLES_COLUMNS.ACTIONS,
    title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.ACTIONS],
    width: 'w-1/12',
    hidden: !permissions,
  },
];

export const SEARCH_ROLES_PLACEHOLDER = 'SearchByRoleName';

export const CREATE_ROLE_TABS: TabItem[] = [
  {
    id: 'role-name',
    title: 'RoleName',
    component: RoleNameTabContent,
    dataCy: 'roleNameTab',
  },
  {
    id: 'permissions',
    title: 'Permissions',
    component: PermissionsTabContent,
    dataCy: 'rolePermissionsTab',
  },
];

export const CREATE_ROLE_FORM_FIELDS = [
  {
    name: CREATE_ROLES_FORM_FIELDS.displayName,
    inputClassName: 'w-full',
    component: Input,
    required: true,
    className: 'mb-4',
    label: 'RoleName',
    validation: {
      required: 'FieldRequired',
    },
    dataCy: 'displayName',
  },

  {
    name: CREATE_ROLES_FORM_FIELDS.isDefault,
    component: Checkbox,
    className: 'mb-4',
    label: 'Default',
    dataCy: 'isDefault',
    tooltipContent: 'DefaultRole_Description',
  },
];

const SHARED_FILTERS_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'All', value: '' },
];

export const ROLES_FILTER_FORM_FIELDS: FormItem[] = [
  {
    name: ROLES_FILTERS_FORM_FIELD_NAMES.IS_STATIC,
    label: 'IsStatic',
    className: 'ml-10 w-30',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-static-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
  {
    name: ROLES_FILTERS_FORM_FIELD_NAMES.IS_DEFAULT,
    label: 'IsDefault',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    className: 'ml-10 w-30',
    dataCy: 'is-default-select',
    type: FieldTypes.SELECT,
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
];

export const getRolesFiltersFieldsDefaultValues = (selectStaticAndDefaultLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [ROLES_FILTERS_FORM_FIELD_NAMES.IS_STATIC]: { label: selectStaticAndDefaultLabel, value: '' },
  [ROLES_FILTERS_FORM_FIELD_NAMES.IS_DEFAULT]: { label: selectStaticAndDefaultLabel, value: '' },
  [BASE_PERMISSIONS_FROM_FIELD_NAME.PERMISSIONS]: [],
});
