import { useTranslation } from 'react-i18next';

import { useOpen } from 'hooks/useOpen';
import Button from 'shared-components/button/Button';

import CreatePayModal from './CreatePayModal';

const CreatePayButton = () => {
  const [isOpen, handleOpen, handleClose] = useOpen();

  const { t } = useTranslation();
  return (
    <>
      <Button data-cy="create-payment-button" onClick={handleOpen}>
        {t('Create')}
      </Button>
      <CreatePayModal isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default CreatePayButton;
