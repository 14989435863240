import { PropsWithChildren, ReactElement } from 'react';

import { UseFormRegister, FieldErrors, Control, FieldError, Path, FieldValues, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FORM_INPUTS_WITHOUT_REFS } from 'constants/common';
import { FormItem } from 'interfaces/shared.interface';
import { cn } from 'utils/global';

export interface Props<T extends FieldValues> {
  fields: FormItem[];
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  control?: Control<T>;
  className?: string;
  isLoading?: boolean;
}

const FormContent = <T extends FieldValues>({
  fields,
  register,
  className,
  control,
  errors,
  isLoading,
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  const { t } = useTranslation();

  if (!fields?.length) {
    return null;
  }

  return (
    <>
      {fields.map(
        ({ component: Component, pureName, placeholder, validation, className: componentClassName, ...props }) => {
          const error = get(errors as Record<string, FieldError>, pureName || props.name)?.message;

          const { ref, ...registerProps } = register(props.name as Path<T>, {
            ...validation,
            onChange: props.onChange,
          });

          const componentProps = {
            error,
            control,
            placeholder: t(placeholder || ''),
            className: cn(componentClassName, className),
            isLoading: isLoading,
            ref: props.type && FORM_INPUTS_WITHOUT_REFS.includes(props.type) ? undefined : ref,
            ...props,
            ...registerProps,
          };

          return <Component key={props.name} {...componentProps} />;
        }
      )}
    </>
  );
};

export default FormContent;
