import { useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useCreateStripeSessionMutation,
  useGetConfigurationQuery,
  useGetPaymentIdQuery,
} from 'apis/subscriptions-tenant.api';
import { Currency, getFormatPrice } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { SHARED_MODAL_BUTTON_STYLES, SHARED_MODAL_BUTTON_WRAPPER_STYLES } from 'constants/subscription-tenant';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { CreateStripeSessionPayload } from 'interfaces/subscriptions-tenant.interface';
import TenantSubscriptionModal from 'page-components/subscriptions-tenant/modal/TenantSubscriptionModal';
import { Button, ErrorModal, LabelWithValue, LoadingOverlay } from 'shared-components';
import { getStripeCancelServices } from 'utils/url';

const SubscriptionStripePurchase = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [isRedirected, setIsRedirected] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const paymentIdString = searchParams.get('paymentId');
  const paymentId = paymentIdString ? Number(paymentIdString) : 0;

  const { data, isLoading: isGetPaymentIdLoading, error: getPaymentIdError } = useGetPaymentIdQuery(paymentId);
  const {
    data: publishableKey,
    isLoading: isGetConfigurationLoading,
    error: getConfigurationError,
  } = useGetConfigurationQuery();

  const [createStripeSession, { isLoading: isCreateStripeSessionLoading, error: createStripeSessionError }] =
    useCreateStripeSessionMutation();

  const errorStripeSessionData = (createStripeSessionError as CommonError)?.data || {};
  const errorConfigurationData = (getConfigurationError as CommonError)?.data || {};
  const errorPaymentIdData = (getPaymentIdError as CommonError)?.data || {};
  const errorData = errorPaymentIdData || errorConfigurationData || errorStripeSessionData;

  const selectedPlan = data?.result;

  const isLoading = isGetPaymentIdLoading || isGetConfigurationLoading || isCreateStripeSessionLoading;

  const onSubmit = async (paymentId: number) => {
    if (!paymentId) {
      handleOpenErrorModal();
      return;
    }

    const redirectUri = (link: string) => window.location.origin + link;

    const payload: CreateStripeSessionPayload = {
      paymentId,
      successUrl: redirectUri(ROUTES.subscriptionPaymentCompleted),
      cancelUrl: redirectUri(getStripeCancelServices(`${paymentIdString}`)),
    };

    try {
      setIsRedirected(true);
      const stripePromise = loadStripe(publishableKey?.result.publishableKey ?? '');

      const response = await createStripeSession(payload).unwrap();
      const sessionId = response?.result?.sessionId;

      const stripe = await stripePromise;

      if (stripe) {
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          handleOpenErrorModal();
        }
      }
    } catch (error) {
      setIsRedirected(false);
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="subscription-invoice-purchase-page">
        <TenantSubscriptionModal
          bodyClassName="min-w-[28rem]"
          headingClassName="text-start"
          dataCy="payment-info-subscription-modal"
          isPaymentInfo
          isLoading={isLoading}
          heading="SubscriptionInvoice"
          onClose={() => navigate(ROUTES.tenantSubscription)}
          endChild={
            <div className={SHARED_MODAL_BUTTON_WRAPPER_STYLES}>
              <Button
                dataCy="checkout-button"
                isLoading={isLoading || isRedirected}
                className={SHARED_MODAL_BUTTON_STYLES}
                onClick={() => onSubmit(paymentId)}
              >
                {t('Purchase')}
              </Button>
            </div>
          }
        >
          <div className="relative">
            <LoadingOverlay isLoading={isRedirected} className="w-full h-full" />
            <LabelWithValue
              value={selectedPlan?.description}
              label={t('Item')}
              bodyClassName="justify-between mb-5"
              withColon={false}
              skeletonClassName="min-w-[10rem] h-full"
            />
            <LabelWithValue
              value={getFormatPrice(selectedPlan?.amount ?? 0, Currency.GBP)}
              label={`${t('Price')} ${t('ExcludingVat')}`}
              bodyClassName="justify-between mb-9"
              withColon={false}
            />
          </div>
        </TenantSubscriptionModal>
      </div>
    </>
  );
};

export default SubscriptionStripePurchase;
