import { useEffect } from 'react';

import { useGetRepaymentDetailsQuery } from 'apis/gocardless.apis';
import { getRepaymentsTabs } from 'constants/gocardless';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';

interface Props {
  repaymentId: number;
}
const RepaymentDetailsModalContent = ({ repaymentId }: Props) => {
  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { data, isLoading, error: errorRepaymentData } = useGetRepaymentDetailsQuery(repaymentId);
  const { error: errorData } = (errorRepaymentData as CommonError)?.data || {};

  const result = data?.result;

  const isMandate = !!result?.mandate;
  const isSchedule = !!result?.schedule;
  const isPayments = !!result?.payments;

  useEffect(() => {
    if (errorRepaymentData) {
      handleOpenErrorModal();
    }
  }, [errorRepaymentData, handleOpenErrorModal]);

  return (
    <div>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />
      <Tabs
        tabs={getRepaymentsTabs(isMandate, isSchedule, isPayments)}
        componentProps={{
          data: result,
          isLoading: isLoading,
        }}
      />
    </div>
  );
};

export default RepaymentDetailsModalContent;
