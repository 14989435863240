import { useGetRoleForEditQuery } from 'apis/roles.api';
import { useTreeViewSelect } from 'hooks/useTreeViewSelect';
import PermissionsTabContent from 'page-components/roles/permissions/PermissionsTabContent';
import { Button, Modal } from 'shared-components/index';

interface Props {
  isOpen: boolean;
  handleOnClose: VoidFunction;
  handleSubmitPermissions: (values: string[]) => void;
  appliedPermissions: string[];
}
const FilterPermissionsModal = ({ isOpen, handleOnClose, handleSubmitPermissions, appliedPermissions }: Props) => {
  const { data, isLoading } = useGetRoleForEditQuery({});
  const { permissions } = data?.result || {};

  const { handleChangeSelectedIds, selectedIds, handleClearSelectedIds } = useTreeViewSelect(appliedPermissions);

  const onSubmitPermissions = () => {
    handleSubmitPermissions(selectedIds);
    handleClearSelectedIds();
    handleOnClose();
  };

  const onClosePermissionsModal = () => {
    handleOnClose();
    handleClearSelectedIds();
  };

  return (
    <Modal dataCy="permissions-modal" heading="Select permissions" isOpen={isOpen} onClose={onClosePermissionsModal}>
      <PermissionsTabContent
        defaultSelectedIds={selectedIds}
        permissions={permissions || []}
        handleChangeSelectedIds={handleChangeSelectedIds}
        isLoading={isLoading}
      />
      <Button dataCy="select-permissions" onClick={onSubmitPermissions} type="button" className="float-right mt-8">
        Select
      </Button>
    </Modal>
  );
};

export default FilterPermissionsModal;
