import { FC } from 'react';

import {
  faArrowDownLong,
  faArrowUpLong,
  faArrowUpShortWide,
  faArrowDownShortWide,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { SORTING_DESC } from 'constants/global';
import { TableColumn } from 'interfaces/shared.interface';
import Button from 'shared-components/button/Button';
import Checkbox from 'shared-components/Checkbox';
import { cn } from 'utils/global';

interface Props {
  columns?: TableColumn[];
  handleSetTabName?: (value: string) => void;
  sortingType?: string;
  sortingColumnId?: string | null;
  dataCy?: string;
  showAddButton?: boolean;
  addButtonText?: string;
  addButtonIcon?: IconDefinition;
  onAddRow?: () => void;
  isSelectAllCheckbox?: boolean;
  selectAll?: boolean;
  handleSelectAll?: () => void;
  tableHeadClassName?: string;
}

const TITLES_SHOULD_BE_REPLACED_WITH_TRANSLATION = ['Service/Action'];
const TableHead: FC<Props> = ({
  columns,
  sortingColumnId,
  sortingType,
  handleSetTabName,
  dataCy,
  showAddButton,
  addButtonText,
  addButtonIcon,
  onAddRow,
  isSelectAllCheckbox,
  selectAll,
  handleSelectAll,
  tableHeadClassName,
}) => {
  const { t } = useTranslation();
  const sortingIcon = sortingType === SORTING_DESC ? faArrowDownShortWide : faArrowUpShortWide;

  const handleTitleTranslation = (title: string | void) => {
    if (!title) {
      return '';
    }

    if (TITLES_SHOULD_BE_REPLACED_WITH_TRANSLATION.includes(title)) {
      return title
        .split('/')
        .map((word) => t(word))
        .join('/');
    }

    return t(title);
  };

  return (
    <thead data-cy={dataCy} className="border-y dark:bg-darkBlue3 border-lightGray8 dark:border-darkBlue">
      <tr>
        {showAddButton && (
          <th>
            <Button onClick={onAddRow} className="mt-3 w-full xl:mt-0 h-auto" data-cy="add-line-item">
              {addButtonIcon && <FontAwesomeIcon icon={addButtonIcon} className="text-white mr-1" />}
              {addButtonText || t('Create')}
            </Button>
          </th>
        )}
        {isSelectAllCheckbox && (
          <th className="py-0 px-3.5 w-3/12">
            <Checkbox type="checkbox" checked={selectAll} onChange={handleSelectAll} data-cy="select-all-checkbox" />
          </th>
        )}
        {columns?.map(({ columnId, title, width, className, sorting, component: Component, hidden, required }) => (
          <th
            hidden={hidden}
            key={columnId}
            scope="col"
            className={cn(
              'text-xs dark:text-white font-semibold text-gray-900 text-left',
              tableHeadClassName,
              className,
              width
            )}
          >
            {!!Component ? <Component /> : null}
            {!!title && handleTitleTranslation(title)}
            {required && ' *'}
            {sorting && handleSetTabName && (
              <button className="h-auto" data-cy="table-sorting" onClick={() => handleSetTabName(columnId)}>
                {sortingColumnId === columnId ? (
                  <FontAwesomeIcon data-cy="table-applied-sorting-direction" icon={sortingIcon} className="ml-2" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faArrowDownLong} className="ml-2" />
                    <FontAwesomeIcon icon={faArrowUpLong} />
                  </>
                )}
              </button>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
