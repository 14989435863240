import { FC } from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { STRING_WITHOUT_SPACES_PATTERN } from 'constants/global';
import { FormItem, OptionType } from 'interfaces/shared.interface';
import { InputIcon } from 'shared-components';
import { cn } from 'utils/global';

import ArrowButtons from './ArrowButtons';
import ErrorMessage from './ErrorMessage';

export type Props = {
  label?: string;
  error?: string;
  name: string;
  control: Control;
  defaultValue?: OptionType;
  required?: boolean;
  isShownArrowButtons?: boolean;
  decimalsLimit?: number;
  minValue?: string;
  maxValue?: string;
  maxLength?: number;
  fixedDecimalLength?: number;
  icon?: IconDefinition;
  allowNegativeValue?: boolean;
  decimalSeparator?: string;
  arrowButtonBodyClassName?: string;
  arrowButtonArrowClassName?: string;
  groupSeparator?: string;
} & Partial<CurrencyInputProps> &
  Omit<FormItem, 'component'>;

const InputNumberFormat: FC<Props> = ({
  className,
  inputClassName,
  name,
  dataCy,
  control,
  defaultValue = '0',
  maxLength = 12,
  placeholder,
  labelClassName,
  label,
  disabled,
  required,
  isShownArrowButtons,
  minValue = '0',
  maxValue = Number.MAX_VALUE.toString(),
  fixedDecimalLength = 2,
  allowNegativeValue = false,
  icon,
  decimalSeparator = ',',
  arrowButtonBodyClassName,
  arrowButtonArrowClassName,
  error,
  groupSeparator = ' ',
}) => {
  const { t } = useTranslation();

  const valueWithoutSpaces = (value: string) => {
    if (typeof value !== 'string') {
      return '';
    }
    return value.replace(STRING_WITHOUT_SPACES_PATTERN, '');
  };

  const handleValueChange = (onChange: (value: string) => void, value?: string) => {
    if (!value) {
      onChange('');
      return;
    }

    const cleanedValue = valueWithoutSpaces(value);
    const hasDecimalSeparator = cleanedValue.includes(decimalSeparator);

    if (fixedDecimalLength > 0 && !hasDecimalSeparator) {
      const newValue = Number(cleanedValue.replace(decimalSeparator, '.')).toFixed(fixedDecimalLength);
      onChange(newValue.replace('.', decimalSeparator));
    }

    onChange(cleanedValue);
  };

  const handleOnBlur = (onChange: (value: string) => void, value?: string) => {
    if (!value) {
      return;
    }

    const cleanedValue = valueWithoutSpaces(value);

    if (allowNegativeValue) {
      onChange(cleanedValue);
      return;
    }

    if (fixedDecimalLength === 0 && Number(cleanedValue.replace(decimalSeparator, '.')) > Number(maxValue)) {
      onChange(maxValue);
    }

    if (fixedDecimalLength === 0 && Number(cleanedValue.replace(decimalSeparator, '.')) < Number(minValue)) {
      onChange(minValue);
    }

    const zeroPadding = fixedDecimalLength > 0 ? decimalSeparator + '0'.repeat(fixedDecimalLength) : '';

    if (fixedDecimalLength !== 0 && Number(cleanedValue.replace(decimalSeparator, '.')) < Number(minValue)) {
      onChange(`${minValue}${zeroPadding}`);
    }

    if (fixedDecimalLength === null) {
      onChange(cleanedValue.toString());
    }
  };

  const incrementValue = (onChange: (value: string) => void, value: string) => {
    let numericValue = parseFloat(valueWithoutSpaces(value.replace(decimalSeparator, '.')));

    if (isNaN(numericValue)) {
      numericValue = parseFloat(defaultValue.toString()) || 0;
    }

    const newValue = numericValue + 1;

    const formattedValue = newValue.toFixed(fixedDecimalLength).replace('.', decimalSeparator);

    if (formattedValue.length > maxLength) {
      return;
    }

    if (newValue > parseFloat(maxValue)) {
      onChange(maxValue.replace('.', decimalSeparator).toString());
    }

    if (fixedDecimalLength > 0) {
      if (newValue < 0) {
      }

      onChange(formattedValue);
    } else {
      onChange(newValue.toString().replace('.', decimalSeparator));
    }
  };

  const decrementValue = (onChange: (value: string) => void, value: string) => {
    let numericValue = parseFloat(valueWithoutSpaces(value.replace(decimalSeparator, '.')));

    if (isNaN(numericValue)) {
      numericValue = 0;
    }

    const newValue = numericValue - 1;

    if (!allowNegativeValue || (allowNegativeValue && fixedDecimalLength)) {
      if (newValue < 0) {
        return;
      }
      onChange(newValue.toFixed(fixedDecimalLength).replace('.', decimalSeparator).toString());
    }

    if (allowNegativeValue && fixedDecimalLength === null) {
      onChange(newValue.toString().replace('.', decimalSeparator));
    }

    if (minValue && newValue < parseFloat(minValue)) {
      onChange(minValue.replace('.', decimalSeparator));
    }
  };

  return (
    <div className={className}>
      {label && (
        <label
          className={cn('block text-gray-700 mb-2 text-sm font-normal dark:text-white', labelClassName, {
            'opacity-70': disabled,
          })}
        >
          {t(label)}
          {label && required && ' *'}
        </label>
      )}
      <div className="flex justify-center items-center">
        <InputIcon icon={icon} />
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <CurrencyInput
                name={field.name}
                disabled={disabled}
                data-cy={dataCy}
                className={cn(
                  'custom-input rounded disabled:dark:bg-black dark:text-white dark:placeholder:text-darkGray3',
                  inputClassName,
                  { 'rounded-s-none': icon },
                  { 'rounded-e-none': isShownArrowButtons },
                  { 'hover:border-lightGray2': disabled },
                  { 'opacity-70': disabled },
                  { '!border-error focus:ring': !!error }
                )}
                defaultValue={defaultValue?.toString() || undefined}
                placeholder={placeholder}
                fixedDecimalLength={fixedDecimalLength}
                allowDecimals={fixedDecimalLength !== 0 || fixedDecimalLength === null}
                value={field.value || '' || defaultValue?.toString()}
                allowNegativeValue={allowNegativeValue}
                step={1}
                decimalSeparator={decimalSeparator}
                groupSeparator={groupSeparator}
                maxLength={maxLength}
                onBlur={(event) => handleOnBlur(field.onChange, event.target.value)}
                onValueChange={(value) => handleValueChange(field.onChange, value)}
                onKeyDown={(event) => {
                  const { key, target } = event;
                  if (key === decimalSeparator) {
                    const input = target as HTMLInputElement;
                    const cursorPosition = input.selectionStart ?? input.value.length;
                    setTimeout(() => {
                      input.selectionStart = cursorPosition + 1;
                      input.selectionEnd = cursorPosition + 1;
                    }, 0);
                  }
                }}
              />
              {isShownArrowButtons && (
                <ArrowButtons
                  disabled={disabled}
                  onClickIncrement={() => incrementValue(field.onChange, field.value || '')}
                  onClickDecrement={() => decrementValue(field.onChange, field.value || '')}
                  arrowButtonArrowClassName={arrowButtonArrowClassName}
                  arrowButtonBodyClassName={arrowButtonBodyClassName}
                />
              )}
            </>
          )}
        />
      </div>
      {error && <ErrorMessage dataCy="error-message">{t(error)}</ErrorMessage>}
    </div>
  );
};

export default InputNumberFormat;
