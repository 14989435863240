import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateSupportRequestsMutation } from 'apis/tenant-support-requests.api';
import { TENANT_SUPPORT_REQUEST_FIELDS } from 'constants/support-requests';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import {
  CreateSupportRequestPayload,
  TENANT_SUPPORT_REQUEST_FORM_FIELDS,
} from 'interfaces/tenant-support-requests.interface';
import { ErrorModal, FormContent, Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

interface Props {
  handleClose: VoidFunction;
  refetchListTenants: VoidFunction;
  isOpen: boolean;
}
const CreateSupportRequestModal = ({ handleClose, isOpen, refetchListTenants }: Props) => {
  const [createSupportRequest, { isLoading: isCreateSupportRequestsLoading, error }] =
    useCreateSupportRequestsMutation();

  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const createSupportRequestMethods = useForm<CreateSupportRequestPayload>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors },
  } = createSupportRequestMethods;

  const { data: errorData } = (error as CommonError) || {};

  const watchSubjectField = watch(TENANT_SUPPORT_REQUEST_FORM_FIELDS.SUBJECT);
  const watchDescriptionField = watch(TENANT_SUPPORT_REQUEST_FORM_FIELDS.DESCRIPTION);

  const isDisabledSubmitButton = !watchSubjectField || !watchDescriptionField;

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmit = async (data: CreateSupportRequestPayload) => {
    if (isDisabledSubmitButton) {
      return;
    }

    const payload: CreateSupportRequestPayload = {
      subject: data[TENANT_SUPPORT_REQUEST_FORM_FIELDS.SUBJECT],
      description: data[TENANT_SUPPORT_REQUEST_FORM_FIELDS.DESCRIPTION],
    };
    try {
      await createSupportRequest(payload);
      refetchListTenants();
      onCloseModal();
      successNotify(t('SuccessfullyCreated'));
    } catch (error) {
      handleOpenErrorModal();
      console.error(error);
    }
  };

  return (
    <>
      <Modal dataCy="create-tenant-modal" isOpen={isOpen} onClose={onCloseModal} heading="CreateRequest">
        <FormProvider {...createSupportRequestMethods}>
          <form data-cy="create-request-modal-form" onSubmit={handleSubmit(onSubmit)}>
            <FormContent fields={TENANT_SUPPORT_REQUEST_FIELDS} register={register} errors={errors} />
            <ModalControlButtons
              isShownSubmit
              className="pb-5"
              saveButtonText="Save"
              closeButtonText="Cancel"
              isLoading={isCreateSupportRequestsLoading}
              onClose={onCloseModal}
              disabled={isDisabledSubmitButton}
            />
          </form>
        </FormProvider>
      </Modal>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default CreateSupportRequestModal;
