import { useState, FC } from 'react';

import { useFormContext } from 'react-hook-form';

import { ReactComponent as InfoSvg } from 'assets/svg/info.svg';
import { AUTHENTICATOR_QR_CODE_BUTTON, TWO_FACTOR_AUTHENTICATION_OPTIONS } from 'constants/two-factor-authentication';
import { ProfileSettingsFormValues } from 'interfaces/profile-settings.interface';
import { BASE_USER_SETTINGS_FORM_FIELDS } from 'interfaces/user.interface';
import { Button } from 'shared-components';
import IconWithTooltip from 'shared-components/IconWithTooltip';
import ModalControlButtons from 'shared-components/ModalControlButtons';

import AppAuthenticatorGuide from './AppAuthenticatorGuide';
import AuthenticationFieldVariant from './AuthenticationFieldVariant';

export type Props = {
  handleClose: VoidFunction;
  isLoading: boolean;
};

const TwoFactorAuthentication: FC<Props> = ({ handleClose, isLoading }) => {
  const [isClickedQRCode, setIsClickedQRCode] = useState(false);

  const { setValue, watch } = useFormContext<ProfileSettingsFormValues>();

  const watchTwoFactorTypeId = watch(BASE_USER_SETTINGS_FORM_FIELDS.twoFactorAuthenticationTypeId);
  const watchPhoneNumber = watch(BASE_USER_SETTINGS_FORM_FIELDS.phone);

  const handleClickQRCode = () => {
    setIsClickedQRCode(true);
  };

  const handleSelect = (value: number) => {
    setIsClickedQRCode(false);
    setValue(BASE_USER_SETTINGS_FORM_FIELDS.twoFactorAuthenticationTypeId, value);
  };

  return (
    <div className="p-6" data-cy="two-factor-auth-tab-content">
      <AuthenticationFieldVariant
        {...TWO_FACTOR_AUTHENTICATION_OPTIONS.email}
        onSelect={handleSelect}
        isSelected={watchTwoFactorTypeId === TWO_FACTOR_AUTHENTICATION_OPTIONS.email.value}
      />

      <AuthenticationFieldVariant
        {...TWO_FACTOR_AUTHENTICATION_OPTIONS.appAuthenticator}
        onSelect={handleSelect}
        isSelected={watchTwoFactorTypeId === TWO_FACTOR_AUTHENTICATION_OPTIONS.appAuthenticator.value}
        additionalButtons={
          <Button dataCy="show-qr-code-button" type="button" className="mr-2" onClick={handleClickQRCode}>
            {AUTHENTICATOR_QR_CODE_BUTTON}
          </Button>
        }
      >
        {isClickedQRCode && <AppAuthenticatorGuide />}
      </AuthenticationFieldVariant>

      <AuthenticationFieldVariant
        {...TWO_FACTOR_AUTHENTICATION_OPTIONS.sms}
        onSelect={handleSelect}
        isDisabled={!watchPhoneNumber}
        isSelected={watchTwoFactorTypeId === TWO_FACTOR_AUTHENTICATION_OPTIONS.sms.value}
        additionalButtons={
          <IconWithTooltip
            anchorId="info"
            place="left"
            tooltipContent="SpecifyPhoneNumber"
            icon={<InfoSvg className="mr-3.5" id="info" />}
          />
        }
        buttonsContainerClassName="flex items-center"
        ignoreSelectedCondition
      />
      <ModalControlButtons isShownSubmit className="pb-5" isLoading={isLoading} onClose={handleClose} />
    </div>
  );
};

export default TwoFactorAuthentication;
