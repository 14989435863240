export enum SIGN_IN_FORM_FIELDS {
  userName = 'userName',
  password = 'password',
  rememberMe = 'rememberMe',
}

export interface SignInFormValues {
  [SIGN_IN_FORM_FIELDS.userName]: string;
  [SIGN_IN_FORM_FIELDS.password]: string;
  [SIGN_IN_FORM_FIELDS.rememberMe]: boolean;
}
