import { FC } from 'react';

import { AddMemberTableItem } from 'interfaces/organization-units.interface';
import { Checkbox, TableData, TableRow } from 'shared-components';

interface Props {
  memberData: AddMemberTableItem;
  onToggleSelect: (id: number) => void;
  isSelected: boolean;
}

const AddMemberModalRow: FC<Props> = ({ memberData, onToggleSelect, isSelected }) => {
  const { name, value } = memberData;

  return (
    <>
      <TableRow dataCy="add-member-table-row">
        <TableData>
          <Checkbox
            dataCy="select-user-checkbox"
            checked={isSelected}
            onChange={() => onToggleSelect(parseInt(value))}
          />
        </TableData>
        <TableData dataCy="table-data-user-name">{name}</TableData>
      </TableRow>
    </>
  );
};

export default AddMemberModalRow;
