import { useEffect } from 'react';

import { Control, Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ParseDocument } from 'interfaces/integrations/typless.interface';
import { Button, Input, InputNumberFormat, TableData, TableRow } from 'shared-components';

type ControlType = Control<any>;

type Props = {
  ordersData: ParseDocument;
  onDeleteField?: (id: string) => void;
  onUpdate?: (data: ParseDocument) => void;
};

const TrainDocumentRow = ({ ordersData, onDeleteField, onUpdate }: Props) => {
  const { t } = useTranslation();
  const { description, quantity, price, tax, amount } = ordersData;

  const { control, watch, setValue } = useForm<ParseDocument>({
    defaultValues: { description, quantity, price, tax, amount },
  });

  const rowValues = watch();

  useEffect(() => {
    if (onUpdate) {
      onUpdate({ ...rowValues, id: ordersData.id });
    }
  }, [rowValues, onUpdate]);

  useEffect(() => {
    const ensureNumericValue = (fieldName: keyof ParseDocument) => {
      const value = watch(fieldName);
      setValue(fieldName, String(value));
    };
    ensureNumericValue('quantity');
    ensureNumericValue('price');
    ensureNumericValue('tax');
    ensureNumericValue('amount');
  }, [watch, setValue]);

  return (
    <>
      <TableRow dataCy="train-document-table-row">
        <TableData dataCy="train-delete-field">
          <Button
            dataCy="delete-button"
            className="w-full"
            onClick={() => onDeleteField && onDeleteField(ordersData.id)}
          >
            {t('Delete')}
          </Button>
        </TableData>
        <TableData dataCy="train-description-field">
          <Controller
            control={control}
            name="description"
            render={({ field }) => <Input dataCy="description-field-item" inputClassName="w-full text-sm" {...field} />}
          />
        </TableData>
        <TableData dataCy="train-quantity-field">
          <Controller
            control={control}
            name="quantity"
            render={({ field }) => (
              <InputNumberFormat
                control={control as ControlType}
                dataCy="quantity-field-item"
                inputClassName="w-full text-sm"
                {...field}
                isShownArrowButtons
                fixedDecimalLength={0}
              />
            )}
          />
        </TableData>
        <TableData dataCy="train-price-field">
          <Controller
            control={control}
            name="price"
            render={({ field }) => (
              <InputNumberFormat
                control={control as ControlType}
                dataCy="price-field-item"
                inputClassName="w-full text-sm"
                {...field}
                isShownArrowButtons
                fixedDecimalLength={0}
              />
            )}
          />
        </TableData>
        <TableData dataCy="train-tax-field">
          <Controller
            control={control}
            name="tax"
            render={({ field }) => (
              <InputNumberFormat
                control={control as ControlType}
                dataCy="tax-field-item"
                inputClassName="w-full text-sm"
                {...field}
                isShownArrowButtons
                fixedDecimalLength={0}
              />
            )}
          />
        </TableData>
        <TableData dataCy="train-amount-field">
          <Controller
            control={control}
            name="amount"
            render={({ field }) => (
              <InputNumberFormat
                control={control as ControlType}
                dataCy="total-field-item"
                inputClassName="w-full text-sm"
                {...field}
                isShownArrowButtons
                fixedDecimalLength={0}
              />
            )}
          />
        </TableData>
      </TableRow>
    </>
  );
};

export default TrainDocumentRow;
