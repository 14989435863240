import { PropsWithChildren, ReactNode } from 'react';

import { TWO_FACTOR_SELECT_BUTTON, TWO_FACTOR_SELECTED_BUTTON } from 'constants/two-factor-authentication';
import { Button } from 'shared-components';

type Props = {
  type: string;
  label: string;
  img?: string;
  isSelected?: boolean;
  onSelect: (value: number) => void;
  value: number;
  additionalButtons?: ReactNode;
  isDisabled?: boolean;
  dataCy?: string;
  buttonsContainerClassName?: string;
  ignoreSelectedCondition?: boolean;
};

const AuthenticationFieldVariant = ({
  type,
  value,
  label,
  img,
  onSelect,
  children,
  isSelected,
  additionalButtons,
  isDisabled,
  dataCy,
  ignoreSelectedCondition,
  buttonsContainerClassName,
}: PropsWithChildren<Props>) => {
  return (
    <div className="border-b border-zinc-200 py-4" data-cy={dataCy}>
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="mr-2">
            <img className="w-5" alt={type} src={img} />
          </div>
          <div>{label}</div>
        </div>
        <div className={buttonsContainerClassName}>
          {(isSelected || ignoreSelectedCondition) && additionalButtons}

          <Button
            type="button"
            dataCy="select-two-fa-method-button"
            onClick={() => onSelect(value)}
            disabled={isDisabled}
          >
            {isSelected ? TWO_FACTOR_SELECTED_BUTTON : TWO_FACTOR_SELECT_BUTTON}
          </Button>
        </div>
      </div>

      {children}
    </div>
  );
};

export default AuthenticationFieldVariant;
