import { FC } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Skeleton } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  columns?: number;
  tableRowClassName?: string;
  tableDataClassName?: string;
  skeletonClassName?: string;
}

const TableSkeleton: FC<Props> = ({ columns, tableRowClassName, skeletonClassName, tableDataClassName }) => {
  return (
    <tr data-cy="table-skeleton" className={cn(tableRowClassName)}>
      {Array.from({ length: columns || 0 }).map(() => (
        <td className={cn(tableDataClassName)} key={uuidv4()}>
          <Skeleton className={cn('h-5 w-full px-3.5 dark:bg-slate-700', skeletonClassName)} />
        </td>
      ))}
    </tr>
  );
};

export default TableSkeleton;
