import { Skeleton } from 'shared-components';
import { cn } from 'utils/global';

const PermissionsTabSkeleton = () => {
  const sharedStyles = 'w-auto h-9 mb-1';

  return (
    <>
      <Skeleton className={cn(sharedStyles)} />
      <Skeleton className={cn('ml-3.5', sharedStyles)} />
      <Skeleton className={cn('ml-3.5', sharedStyles)} />
    </>
  );
};

export default PermissionsTabSkeleton;
