import { TableData, TableRow } from 'shared-components';

const SkeletonMembersTabRow = () => {
  return (
    <>
      <TableRow>
        <TableData></TableData>
        <TableData></TableData>
        <TableData></TableData>
      </TableRow>
    </>
  );
};

export default SkeletonMembersTabRow;
