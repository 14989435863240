import { useState } from 'react';

import { faAngleDown, faAnglesDown, faAnglesUp, faAngleUp, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { DroppedItem, SignerBlock } from 'interfaces/integrations/docusign.interface';
import { Box, Button, Checkbox } from 'shared-components';
import { getRandomColor } from 'utils/global';

import SignersBlockItem from './SignersBlockItem';

interface Props {
  signerBlocks: SignerBlock[];
  setSignerBlocks: React.Dispatch<React.SetStateAction<SignerBlock[]>>;
  droppedItems: DroppedItem[];
  setDroppedItems: React.Dispatch<React.SetStateAction<DroppedItem[]>>;
  isSigningOrderActive: boolean;
  setIsSigningOrderActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignersBlock = ({
  signerBlocks,
  setSignerBlocks,
  droppedItems,
  setDroppedItems,
  isSigningOrderActive,
  setIsSigningOrderActive,
}: Props) => {
  const [activeBlockId, setActiveBlockId] = useState<number | null>(null);
  const { t } = useTranslation();

  const addBlock = () => {
    setSignerBlocks([
      ...signerBlocks,
      {
        id: uuidv4(),
        isActive: false,
        color: getRandomColor(),
        fullName: '',
        email: '',
      },
    ]);
  };

  const removeBlock = () => {
    if (activeBlockId) {
      setSignerBlocks(signerBlocks.filter((block: SignerBlock) => block.id !== activeBlockId));
      setDroppedItems(droppedItems.filter((item) => item.blockId !== activeBlockId));
      setActiveBlockId(null);
    }
  };

  const toggleBlock = (id: any) => {
    setActiveBlockId(id);
    setSignerBlocks(
      signerBlocks.map((block: SignerBlock) => ({
        ...block,
        isActive: block.id === id,
      }))
    );
  };

  const updateColor = (id: number | string, newColor: string) => {
    const updatedBlocks = signerBlocks.map((block: SignerBlock) =>
      block.id === id ? { ...block, color: newColor } : block
    );
    setSignerBlocks(updatedBlocks);

    const updatedDroppedItems = droppedItems.map((item: any) =>
      item.blockId === id ? { ...item, borderColor: newColor } : item
    );
    setDroppedItems(updatedDroppedItems);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSigningOrderActive(e.target.checked);
  };

  const moveBlockUp = () => {
    if (activeBlockId === null) {
      return;
    }

    const currentIndex = signerBlocks.findIndex((block: SignerBlock) => block.id === activeBlockId);
    if (currentIndex > 0) {
      const newBlocks = [...signerBlocks];
      [newBlocks[currentIndex], newBlocks[currentIndex - 1]] = [newBlocks[currentIndex - 1], newBlocks[currentIndex]];
      setSignerBlocks(newBlocks);
    }
  };

  const moveBlockToTop = () => {
    if (activeBlockId === null) {
      return;
    }

    const currentIndex = signerBlocks.findIndex((block: SignerBlock) => block.id === activeBlockId);
    if (currentIndex > 0) {
      const newBlocks = [...signerBlocks];
      const [movedBlock] = newBlocks.splice(currentIndex, 1);
      newBlocks.unshift(movedBlock);
      setSignerBlocks(newBlocks);
    }
  };

  const moveBlockDown = () => {
    if (activeBlockId === null) {
      return;
    }

    const currentIndex = signerBlocks.findIndex((block: SignerBlock) => block.id === activeBlockId);
    if (currentIndex < signerBlocks.length - 1) {
      const newBlocks = [...signerBlocks];
      [newBlocks[currentIndex], newBlocks[currentIndex + 1]] = [newBlocks[currentIndex + 1], newBlocks[currentIndex]];
      setSignerBlocks(newBlocks);
    }
  };

  const moveBlockToBottom = () => {
    if (activeBlockId === null) {
      return;
    }

    const currentIndex = signerBlocks.findIndex((block: SignerBlock) => block.id === activeBlockId);
    if (currentIndex < signerBlocks.length - 1) {
      const newBlocks = [...signerBlocks];
      const [movedBlock] = newBlocks.splice(currentIndex, 1);
      newBlocks.push(movedBlock);
      setSignerBlocks(newBlocks);
    }
  };

  const updateSignerBlock = (id: string | number, data: Partial<SignerBlock>) => {
    setSignerBlocks((currentBlocks: SignerBlock[]) =>
      currentBlocks.map((block: SignerBlock) => (block.id === id ? { ...block, ...data } : block))
    );
  };

  return (
    <div data-cy="signers-block-component">
      <Checkbox
        label="SetSigningOrder"
        dataCy="set-signing-order-checkbox"
        className="mb-4 mt-8 w-full"
        checked={isSigningOrderActive}
        onChange={handleCheckboxChange}
      />
      <div className="flex">
        <Button className="mr-2 " dataCy="plus-button" onClick={addBlock}>
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: '150%' }} className="text-white" />
        </Button>
        <Button dataCy="minus-button" onClick={removeBlock} disabled={!activeBlockId}>
          <FontAwesomeIcon icon={faMinus} style={{ fontSize: '150%' }} className="text-white" />
        </Button>
        {isSigningOrderActive && (
          <div className="border-l-[1px] ml-2">
            <Button dataCy="up-button" onClick={moveBlockUp} disabled={!activeBlockId} className="ml-2">
              <FontAwesomeIcon icon={faAngleUp} style={{ fontSize: '150%' }} className="text-white" />
            </Button>
            <Button dataCy="all-up-button" onClick={moveBlockToTop} disabled={!activeBlockId} className="ml-2">
              <FontAwesomeIcon icon={faAnglesUp} style={{ fontSize: '150%' }} className="text-white" />
            </Button>
            <Button dataCy="down-button" onClick={moveBlockDown} disabled={!activeBlockId} className="ml-2">
              <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: '150%' }} className="text-white" />
            </Button>
            <Button dataCy="all-down-button" onClick={moveBlockToBottom} disabled={!activeBlockId} className="ml-2">
              <FontAwesomeIcon icon={faAnglesDown} style={{ fontSize: '150%' }} className="text-white" />
            </Button>
          </div>
        )}
      </div>
      <Box className="!bg-white dark:!bg-darkBlue4 mt-4">
        <p className="text-sm">{t('Signers')}</p>
      </Box>

      {signerBlocks.length === 0 ? (
        <Box className="!bg-white dark:!bg-darkBlue4 mt-[1px]">
          <p data-cy="no-results" className="text-sm">
            {t('NoResultsFound')}
          </p>
        </Box>
      ) : (
        signerBlocks.map((block: SignerBlock, index: number) => (
          <SignersBlockItem
            id={block.id}
            key={block.id}
            isActive={block.isActive}
            color={block.color}
            setColor={(newColor: string) => updateColor(block.id, newColor)}
            toggleBlock={() => toggleBlock(block.id)}
            isCheckboxActive={isSigningOrderActive}
            numeration={index + 1}
            updateBlockColor={(newColor: string) => updateColor(block.id, newColor)}
            block={block}
            updateSignerBlock={updateSignerBlock}
            setDroppedItems={setDroppedItems}
          />
        ))
      )}
    </div>
  );
};

export default SignersBlock;
