import { NO_DATA } from 'constants/common';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/global';
import { Officers } from 'interfaces/integrations/companies-house.interface';
import { TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

type Props = Officers;

const OfficersRow = ({ name, nationality, countryOfResidence, officerRole, appointedOn, resignedOn }: Props) => {
  return (
    <>
      <TableRow>
        <TableData dataCy="officers-name-table-row">{name || NO_DATA}</TableData>
        <TableData dataCy="officers-nationality">{nationality || NO_DATA}</TableData>
        <TableData dataCy="officers-country-of-residence">{countryOfResidence || NO_DATA}</TableData>
        <TableData dataCy="officers-role-table">{officerRole || NO_DATA}</TableData>
        <TableData dataCy="officers-appointed">
          {getFormattedDate(appointedOn, DAY_FORMAT_WITH_SLASHES) || NO_DATA}
        </TableData>
        <TableData dataCy="officers-resigned-on">
          {getFormattedDate(resignedOn, DAY_FORMAT_WITH_SLASHES) || NO_DATA}
        </TableData>
      </TableRow>
    </>
  );
};

export default OfficersRow;
