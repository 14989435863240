import { NO_DATA } from 'constants/common';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/global';
import { BeneficialOwners } from 'interfaces/integrations/companies-house.interface';
import { TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

type Props = BeneficialOwners;

const BeneficialOwnersRow = ({
  name,
  nationality,
  countryOfResidence,
  naturesOfControl,
  notifiedOn,
  ceasedOn,
}: Props) => {
  return (
    <>
      <TableRow>
        <TableData dataCy="beneficial-owners-name-table-row">{name || NO_DATA}</TableData>
        <TableData dataCy="beneficial-owners-nationality-table-row">{nationality || NO_DATA}</TableData>
        <TableData dataCy="beneficial-owners-country-of-residence-table-row">{countryOfResidence || NO_DATA}</TableData>
        <TableData dataCy="beneficial-owners-nature-of-control-table-row" className="pl-6">
          {naturesOfControl?.map((control: string) => (
            <div className="list-item" key={control}>
              {control || NO_DATA}
            </div>
          ))}
        </TableData>
        <TableData dataCy="beneficial-owners-notified-on-table-row">
          {getFormattedDate(notifiedOn, DAY_FORMAT_WITH_SLASHES) || NO_DATA}
        </TableData>
        <TableData dataCy="beneficial-owners-ceased-on-table-row">
          {getFormattedDate(ceasedOn, DAY_FORMAT_WITH_SLASHES) || NO_DATA}
        </TableData>
      </TableRow>
    </>
  );
};

export default BeneficialOwnersRow;
