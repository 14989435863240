import { STRING_WITHOUT_SPACES_PATTERN } from 'constants/global';

export const parseNumberFormatInputToNumber = (value?: string | null) => {
  if (!value) {
    return null;
  }

  return parseFloat(value.replace(STRING_WITHOUT_SPACES_PATTERN, '').replace(',', '.'));
};

export const getCapitalizedValue = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getValuesWithDecimals = (value: number) => {
  return value.toFixed(2).toString();
};

export const getAppliedFilters = (filters: unknown): string => {
  const filtersArray = Object.entries(filters as Record<string, string | string[] | undefined>)
    .filter(([, value]) => !!value)
    .map(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        return `${getCapitalizedValue(key)}=${value.join('&')}&`;
      }

      return `${getCapitalizedValue(key)}=${value}&`;
    });

  return filtersArray.join('');
};
