import { Outlet, Navigate } from 'react-router-dom';

import { useGetCurrentLoginInformationsQuery } from 'apis/user.api';

import LoadingOverlay from './LoadingOverlay';

type Props = {
  isAllowed: boolean | null;
  allowedRoles?: string[];
  redirectPath: string;
};

const ProtectedRoute = ({ isAllowed, allowedRoles, redirectPath }: Props) => {
  const { data: currentLoginInfoData, isFetching } = useGetCurrentLoginInformationsQuery();
  const userRole = currentLoginInfoData?.tenant ? 'tenant' : 'admin';

  if (isFetching) {
    return <LoadingOverlay isLoading />;
  }
  if (!isAllowed || (allowedRoles && !allowedRoles.includes(userRole))) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
