import { useEffect } from 'react';

import { endOfDay, startOfDay } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';

import { useGetCodatAccountingInvoicesQuery } from 'apis/codat-accounting.api';
import {
  CODAT_INVOICE_FORM_INPUTS,
  CODAT_INVOICES_FILTERS_FIELDS_DEFAULT_VALUES,
  CodatInvoicesFilterFormValues,
  INVOICES_TABLE_COLUMNS,
  SEARCH_CODAT_INVOICES_PLACEHOLDER,
} from 'constants/codat-accounting';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import InvoicesRow from 'page-components/integrations/codat-accounting/invoices/InvoicesRow';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  InfoModal,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

const Invoices = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isInfoModalOpen, handleOpenInfoModal, handleCloseInfoModal] = useOpen();

  const { handleSetTabName, customSortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { customPaginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { filterParams, applyFilters, isSameFilters } = useFilters();

  const {
    data: invoicesData,
    isLoading: isInvoicesLoading,
    isFetching,
    refetch,
    error: invoicesError,
  } = useGetCodatAccountingInvoicesQuery(`${filterParams}${customSortingParams}${customPaginationParams}`);

  const { result } = invoicesData || {};

  const { error: errorData } = (invoicesError as CommonError)?.data || {};

  const infoData = result?.isDatasetSyncNotCompleted;

  const invoicesFiltersMethods = useForm<CodatInvoicesFilterFormValues>({
    defaultValues: CODAT_INVOICES_FILTERS_FIELDS_DEFAULT_VALUES,
  });

  const handleClearFields = () => {
    invoicesFiltersMethods.reset(CODAT_INVOICES_FILTERS_FIELDS_DEFAULT_VALUES);
    onSubmitFilters(CODAT_INVOICES_FILTERS_FIELDS_DEFAULT_VALUES);
  };

  const onSubmitFilters = async (data: CodatInvoicesFilterFormValues) => {
    const { filter, IssueDateFrom, IssueDateTo } = data;

    const newFiltersToApply = {
      InvoiceNumberQuery: filter,
      issueDateFrom: IssueDateFrom ? new Date(startOfDay(new Date(IssueDateFrom))).toISOString() : '',
      issueDateTo: IssueDateTo ? new Date(endOfDay(new Date(IssueDateTo))).toISOString() : '',
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
    if (infoData) {
      handleOpenInfoModal();
    }
  }, [errorData, handleOpenErrorModal, handleOpenInfoModal, infoData]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <InfoModal
        infoMessage={'DatasetSyncInProgress'}
        infoDescription={'DatasetSyncInProgressDescription'}
        isOpen={isInfoModalOpen}
        handleClose={handleCloseInfoModal}
      />
      <div className="px-5 pt-5 pb-0" data-cy="codat-accounting-invoices-filtering">
        <FormProvider {...invoicesFiltersMethods}>
          <form onSubmit={invoicesFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="codat-accounting-invoices-page-search"
              placeHolder={SEARCH_CODAT_INVOICES_PLACEHOLDER}
              handleClearFields={handleClearFields}
            >
              <FilterFormContentWithoutPermissions className="flex " fields={CODAT_INVOICE_FORM_INPUTS} />
            </FilterForm>
          </form>
        </FormProvider>
      </div>
      <div data-cy="invoices-tab" className="relative px-5">
        <LoadingOverlay isLoading={isFetching && !!result} />
        <Table
          dataCy="invoices-table"
          columns={INVOICES_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!invoicesData?.result.data.totalCount}
          sortingType={sortingType}
          isLoading={isInvoicesLoading}
        >
          {result?.data.invoices.map((item) => <InvoicesRow key={item.id} InvoiceData={item} />)}
        </Table>
        <Pagination
          totalCount={invoicesData?.result.data.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default Invoices;
