import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  icon?: IconDefinition;
}

const InputIcon = ({ icon }: Props) => {
  if (!icon) {
    return null;
  }

  return (
    <div className="flex justify-center items-center h-10 w-9 rounded bg-lightGray4 border-lightGray2 rounded-e-none dark:bg-gray">
      <FontAwesomeIcon className="h-3 dark:text-darkGray6" icon={icon} />
    </div>
  );
};

export default InputIcon;
