import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useGetTemplatesQuery } from 'apis/email-templates.api';
import { EMAIL_TEMPLATES_SEARCH_PLACEHOLDER, EMAIL_TEMPLATES_TABLE_COLUMNS } from 'constants/email-templates';
import { ROUTES } from 'constants/routes';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { BaseFilterValues, CommonError } from 'interfaces/shared.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import EmailTemplatesRow from 'page-components/email-templates/EmailTemplatesRow';
import { DropdownMenuButton, ErrorModal, FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';

const EmailTemplates = () => {
  const navigation = useNavigate();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { applyFilters, filterParams, isSameFilters } = useFilters();
  const { handleSetTabName, sortingType, sortingColumnId, sortingParams } = useTableSorting();
  const { paginationParams, handleSetMaxResultCount, handleSetCurrentPage, currentPage, maxResultCount } =
    usePagination();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: errorTemplateData,
  } = useGetTemplatesQuery(`${filterParams}${sortingParams}${paginationParams}`);

  const { totalCount, items } = data?.result || {};
  const { error: errorData } = (errorTemplateData as CommonError)?.data || {};

  const templatesFormMethods = useForm<BaseFilterValues>();
  const onSubmitFilters = (data: BaseFilterValues) => {
    applyFilters(data);

    if (isSameFilters(applyFilters)) {
      refetch();
    } else {
      applyFilters(applyFilters);
    }
  };

  const isTableEmpty = !items?.length;

  const menuItemsAction = [
    {
      dataCy: 'menu-item-edit-button',
      name: 'Create',
      actionOnClick: () => {
        navigation(ROUTES.createEmailTemplate);
      },
    },
    {
      dataCy: 'menu-item-delete-button',
      name: 'SendEmail',
      actionOnClick: () => {
        navigation(ROUTES.sendEmailTemplate);
      },
    },
  ];

  useEffect(() => {
    if (errorTemplateData) {
      handleOpenErrorModal();
    }
  }, [errorTemplateData, handleOpenErrorModal]);

  return (
    <div data-cy="email-templates-page">
      <LoadingOverlay isLoading={isFetching && !!data} />

      <FormProvider {...templatesFormMethods}>
        <form onSubmit={templatesFormMethods.handleSubmit(onSubmitFilters)}>
          <FilterForm
            dataCy="filtering-input"
            withFilter={false}
            placeHolder={EMAIL_TEMPLATES_SEARCH_PLACEHOLDER}
            rightTopActionButton={
              <DropdownMenuButton
                hidden={!permissions[Permissions.PAGES_HOST_EMAIL_TEMPLATES_CREATE]}
                menuItemsAction={menuItemsAction}
                dropdownClassName="!block"
              />
            }
          />
        </form>
      </FormProvider>

      <Table
        dataCy="email-templates-table"
        columns={EMAIL_TEMPLATES_TABLE_COLUMNS}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        sortingType={sortingType}
        isTableEmpty={isTableEmpty}
        isLoading={isLoading}
      >
        {items?.map((item) => <EmailTemplatesRow key={item.id} templatesData={item} />)}
      </Table>

      <Pagination
        totalCount={totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />

      <ErrorModal
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />
    </div>
  );
};

export default EmailTemplates;
