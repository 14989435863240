import { LineItems } from 'interfaces/integrations/sage.interface';
import { TableData, TableRow } from 'shared-components';
import { useFormatPriceFunction } from 'utils/global';

interface Props {
  invoiceLineItemsData: LineItems;
}

const InvoiceLineItemsRow = ({ invoiceLineItemsData }: Props) => {
  const { description, quantity, unitPrice, netAmount, taxAmount, totalAmount, discountAmount } = invoiceLineItemsData;

  return (
    <>
      <TableRow dataCy="invoice-line-item-table-row">
        <TableData dataCy="invoice-line-items-description">{description}</TableData>
        <TableData dataCy="invoice-line-items-quantity">{quantity}</TableData>
        <TableData dataCy="invoice-line-items-unitPrice">{useFormatPriceFunction(unitPrice)}</TableData>
        <TableData dataCy="invoice-line-items-netAmount">{useFormatPriceFunction(netAmount)}</TableData>
        <TableData dataCy="invoice-line-items-taxAmount">{useFormatPriceFunction(taxAmount)}</TableData>
        <TableData dataCy="invoice-line-items-totalAmount">{useFormatPriceFunction(totalAmount)}</TableData>
        <TableData dataCy="invoice-line-items-discountAmount">{useFormatPriceFunction(discountAmount)}</TableData>
      </TableRow>
    </>
  );
};

export default InvoiceLineItemsRow;
