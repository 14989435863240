import { CommonResponse } from '../shared.interface';
export enum OPERATION_LOGS_COLUMNS {
  STATE = 'state',
  USER_EMAIL = 'userEmail',
  SERVICE = 'service',
  EXECUTION_DURATION = 'executionDuration',
  BROWSER = 'browser',
  EXECUTION_TIME = 'executionTime',
  ACTIONS = 'actions',
}

export interface AuditLog {
  id: string;
  browserInfo: string;
  clientIpAddress: string;
  clientName: string | null;
  customData: string | null;
  exception: string | null;
  executionDuration: number;
  executionTime: string;
  impersonatorEmail: string | null;
  impersonatorFullName: string | null;
  impersonatorTenantId: string | null;
  impersonatorUserId: string | null;
  methodName: string;
  parameters: string;
  returnValue: string | null;
  serviceName: string;
  tenantId: number | null;
  userId: number;
  userName: string;
  userEmail: string;
}

export interface OsAuditLogsResponseResult {
  items: AuditLog[];
  totalCount: number;
}

export interface OsAuditLogsResponse extends CommonResponse {
  result: OsAuditLogsResponseResult;
}

export interface ChangeProperties extends CommonResponse {
  result: NameType[];
}

export interface NameType {
  name: string;
  type: string;
}
export interface ChangeLog {
  tenantId: number | null;
  userId: number;
  userName: string;
  changeTime: string;
  entityId: string;
  entityTypeFullName: string;
  changeTypeName: string;
  entityChangeSetId: number;
  clientIpAddress: string;
  clientName: string | null;
  browserInfo: string;
  impersonatorTenantId: number | null;
  impersonatorUserId: number | null;
  impersonatorEmail: string | null;
  impersonatorFullName: string | null;
  extensionData: string | null;
  reason: string;
  id: number;
  userEmail: string;
}

export interface ChangeLogsResult {
  items: ChangeLog[];
  totalCount: number;
}

export interface OsChangeLogsResponse extends CommonResponse {
  result: ChangeLogsResult;
}

export interface EntityPropertyChange {
  entityChangeId: number;
  newValue: string;
  originalValue: string;
  propertyName: string;
  propertyTypeFullName: string;
  tenantId: number | null;
  id: number;
}
export interface EntityPropertyChangeResponse extends CommonResponse {
  result: EntityPropertyChange[];
}

export enum CHANGE_LOGS_COLUMNS {
  CHANGE_TYPE = 'changeType',
  OBJECT = 'object',
  USER_EMAIL = 'userEmail',
  TIME = 'changeTime',
  ACTIONS = 'actions',
}

export enum CHANGE_LOG_DETAILS_COLUMNS {
  ENTITY_CHANGE_ID = 'entityChangeId',
  PROPERTY_NAME = 'propertyName',
  PROPERTY_TYPE = 'propertyTypeFullName',
  ORIGINAL_VALUE = 'originalValue',
  NEW_VALUE = 'newValue',
}

export interface OsAuditLogsResponseToExcelResult {
  context: string;
  fileName: string;
  fileType: string;
}

export interface OsAuditLogsToExcelResponse extends CommonResponse {
  result: OsAuditLogsResponseToExcelResult;
}

export enum OPTION_TYPE {
  LONG = 'long',
  DATE = 'date',
  TEXT = 'text',
  OPERATOR = '1',
  KEYWORD = 'keyword',
  INTEGER = 'integer',
}

export enum OPERATOR_TYPE {
  LESS_THAN = 'lt',
  GREATER_THAN = 'gt',
  EQUALS = 'eq',
}

export interface OPTION {
  value: string;
  label: string;
  type: OPTION_TYPE;
}

export interface OptionTypeAdvancedFilter {
  label: string;
  value: string;
  type?: string;
  isDisabled?: boolean;
  uuid?: string;
  propertyType?: string;
}
