import { useMemo } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useCreateTemplateMutation, useGetEmailTemplateByIdQuery } from 'apis/email-templates.api';
import { DEFAULT_EMAIL_TEMPLATE_FORM_VALUES } from 'constants/email-templates';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { EmailTemplateFormValues, TemplateVariablesValues } from 'interfaces/email-templates.interface';
import { CommonError } from 'interfaces/shared.interface';
import EmailTemplateForm from 'page-components/email-templates/template-form/EmailTemplateForm';
import { ErrorModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

const CloneEmailTemplate = () => {
  const { emailTemplateId } = useParams();
  const navigate = useNavigate();

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { data, isLoading } = useGetEmailTemplateByIdQuery(emailTemplateId);
  const [createTemplate, { error }] = useCreateTemplateMutation();

  const { result: templateFormData } = data || {};
  const { data: errorData } = (error as CommonError) || {};

  const cloneEmailTemplateMethods = useForm<EmailTemplateFormValues>({
    defaultValues: DEFAULT_EMAIL_TEMPLATE_FORM_VALUES,
  });

  const formattedTemplateFormData = useMemo(
    () =>
      templateFormData && {
        ...templateFormData,
        templateName: '',
      },
    [templateFormData]
  );

  const onSubmit = async (data: EmailTemplateFormValues, field: TemplateVariablesValues[]) => {
    const payload = {
      templateName: data.templateName,
      subject: data.subject,
      body: data.textEditor,
      variableNames: field.map((field) => field.value),
      id: emailTemplateId,
    };

    try {
      await createTemplate(payload).unwrap();
      navigate(ROUTES.emailTemplates);
      successNotify('SavedSuccessfully');
    } catch (error) {
      console.error(error);
      handleOpenErrorModal();
    }
  };

  return (
    <div data-cy="clone-email-template-page">
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />

      <FormProvider {...cloneEmailTemplateMethods}>
        <EmailTemplateForm isLoading={isLoading} onSubmit={onSubmit} formData={formattedTemplateFormData} />
      </FormProvider>
    </div>
  );
};

export default CloneEmailTemplate;
