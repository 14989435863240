import { PropsWithChildren } from 'react';

import { cn } from 'utils/global';

interface Props {
  className?: string;
  colSpan?: number;
  dataCy?: string;
}

const TableData = ({ children, className, colSpan, dataCy }: PropsWithChildren<Props>) => {
  return (
    <td
      data-cy={dataCy}
      colSpan={colSpan}
      className={cn('h-12 py-0 px-3.5 text-sm font-light text-gray-900 break-words', className)}
    >
      {children}
    </td>
  );
};

export default TableData;
