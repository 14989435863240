import { FieldTypes } from 'interfaces/shared.interface';

export enum ButtonVariants {
  MAIN = 'main',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
  TRANSPARENT_WITH_BORDER = 'transparentWithBorder',
  DISABLED = 'disabled',
  LINK = 'link',
  BORDERED = 'bordered',
  DELETE = 'delete',
  OVERCAST = 'overcast',
  CONFIRM = 'confirm',
}

export enum BoxVariants {
  MAIN = 'main',
  TRANSPARENT = 'transparent',
  ROUNDED = 'rounded',
}

export const LANGUAGE_VARIANTS = {
  UA: 'uk-UA',
  UK: 'en-GB',
  USA: 'en',
};

export const ButtonVariantStyles: { [key in ButtonVariants]: string } = {
  [ButtonVariants.MAIN]:
    'text-sm text-white bg-blue dark:bg-darkBlue7 rounded py-[7px] px-3 hover:bg-blue-700 hover:bg-linearGradient',
  [ButtonVariants.SECONDARY]: 'text-sm text-white bg-slate-500 rounded py-[7px] px-3 hover:bg-orange-700',
  [ButtonVariants.TRANSPARENT]:
    'text-sm text-slate-500 dark:text-gray border !bg-transparent border-slate-500 bg-white border-none dark:border rounded py-[7px] px-3',
  [ButtonVariants.TRANSPARENT_WITH_BORDER]:
    'text-sm text-slate-500 dark:text-gray dark:border-gray-600 !bg-transparent border-slate-500 bg-white border rounded py-[7px] px-3',
  [ButtonVariants.DISABLED]: 'text-sm text-white bg-blue rounded py-[7px] opacity-60 px-3 hover:bg-blue-700',
  [ButtonVariants.LINK]: 'text-darkBlue7 hover:text-darkBlue11',
  [ButtonVariants.BORDERED]: 'text-sm border border-blue3 py-[7px] px-3.5 rounded hover:bg-blue6',
  [ButtonVariants.DELETE]:
    'text-sm text-error hover:text-white bg-none hover:bg-error border border-error rounded py-[7px] px-3 transition-colors duration-200 ease-in-out',
  [ButtonVariants.OVERCAST]:
    'text-sm text-white bg-gray2 rounded py-1 px-2.5 hover:bg-gray14 border border-gray2 hover:border-gray14',
  [ButtonVariants.CONFIRM]:
    'text-sm text-white bg-green4 dark:bg-green4 rounded py-[7px] px-3 hover:bg-blue-700 hover:bg-linearGradient',
};

export const BoxVariantsStyles: { [key in BoxVariants]: string } = {
  [BoxVariants.MAIN]: 'bg-white dark:bg-darkBlue shadow',
  [BoxVariants.TRANSPARENT]: 'border border-gray6 dark:border-darkBlue4 dark:bg-darkBlue4',
  [BoxVariants.ROUNDED]: 'bg-white dark:bg-darkBlue shadow-centerLight rounded-md',
};

export enum LOCAL_STORAGE_KEYS {
  AUTH_TOKEN = 'auth_token',
}

export enum COOKIE_KEYS {
  AUTH_TOKEN = 'Abp.AuthToken',
  ENC_AUTH_TOKEN = 'enc_auth_token',
  LOCALIZATION_CULTURE_NAME = 'Abp.Localization.CultureName',
}

export enum BASE_FILTER_FIELDS {
  SEARCH_FIELD = 'filter',
}

export enum BASE_TEXT_AREA_FIELDS {
  TEXT_AREA_FIELD = 'textArea',
}

export enum BASE_PERMISSIONS_FROM_FIELD_NAME {
  PERMISSIONS = 'permissions',
}

export const FORM_INPUTS_WITHOUT_REFS = [FieldTypes.SELECT, FieldTypes.NUMBER, FieldTypes.DATE];

export const NO_DATA = '-';
