import { useCallback, useState } from 'react';

import { SORTING_DESC, SORTING_ASC } from 'constants/global';
export const useTableSorting = () => {
  const [sortingColumnId, setColumnId] = useState<string | null>(null);
  const [sortingType, setSortingType] = useState(SORTING_ASC);

  const handleSetTabName = useCallback(
    (columnId: string) => {
      if (sortingColumnId !== columnId) {
        setColumnId(columnId);
        setSortingType(SORTING_ASC);
        return;
      }

      if (sortingType === SORTING_ASC) {
        setSortingType(SORTING_DESC);
        return;
      }

      setSortingType(SORTING_ASC);
    },
    [sortingType, sortingColumnId]
  );

  const sortingParams = sortingColumnId ? `Sorting=${sortingColumnId}%20${sortingType}&` : '';

  const customSortingParams = sortingColumnId ? `SortBy=${sortingColumnId}%20${sortingType}&` : '';

  return { sortingParams, customSortingParams, handleSetTabName, sortingColumnId, sortingType };
};
