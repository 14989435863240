import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import warningSVG from 'assets/svg/warning.svg';
import { ButtonVariants } from 'constants/common';
import { Button, Checkbox, Modal } from 'shared-components';
import { cn } from 'utils/global';

export interface Props {
  warningMessage?: string;
  isOpen: boolean;
  handleClose: VoidFunction;
  handleConfirm: VoidFunction | undefined;
  description?: string | string[];
  isLoading?: boolean;
  descriptionClassName?: string;
  isCancelButton?: boolean;
  approveButtonText?: string;
  checkboxLabel?: string;
  isCheckConfirmButton?: boolean;
}
const WarningModal: FC<Props> = ({
  isOpen,
  handleClose,
  handleConfirm,
  description,
  warningMessage,
  isLoading,
  descriptionClassName,
  isCancelButton = true,
  approveButtonText = 'Yes',
  checkboxLabel,
  isCheckConfirmButton,
}) => {
  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(false);

  return (
    <Modal dataCy="warning-modal" isOpen={isOpen} icon={warningSVG} isError hideCross onClose={handleClose}>
      <div className="text-2xl font-semibold font-sans text-center">{warningMessage}</div>
      {description && <div className={cn('text-center mt-4', descriptionClassName)}>{description}</div>}

      {isCheckConfirmButton && (
        <div className="block mt-5 text-left">
          <Checkbox
            dataCy="checkbox"
            label={checkboxLabel}
            labelClassName="font-normal"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
        </div>
      )}
      <div className="flex justify-center mt-5">
        <Button
          dataCy="warning-modal-confirm-button"
          disabled={!isChecked && isCheckConfirmButton}
          onClick={handleConfirm}
          className="mr-4"
          isLoading={isLoading}
        >
          {t(approveButtonText)}
        </Button>
        {isCancelButton && (
          <Button className="hover:bg-gray2" onClick={handleClose} variant={ButtonVariants.SECONDARY}>
            {t('Cancel')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default WarningModal;
