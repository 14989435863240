import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BASE_PERMISSIONS_FROM_FIELD_NAME } from 'constants/common';
import { useOpen } from 'hooks/useOpen';
import { FormItem } from 'interfaces/shared.interface';
import FilterPermissionsModal from 'shared-components/filter/FilterPermissionsModal';
import { Button, FormContent } from 'shared-components/index';

interface Props {
  fields: FormItem[];
}

const FilterFormContent = ({ fields }: Props) => {
  const { t } = useTranslation();
  const [isOpenRolesPermissionsModal, handleOpenRolesPermissionsModal, handleCloseRolesPermissionsModal] = useOpen();
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchPermissions = watch(BASE_PERMISSIONS_FROM_FIELD_NAME.PERMISSIONS);

  const handleSubmitPermissions = (values: string[]) => {
    setValue(BASE_PERMISSIONS_FROM_FIELD_NAME.PERMISSIONS, values);
  };

  return (
    <>
      <FilterPermissionsModal
        isOpen={isOpenRolesPermissionsModal}
        handleOnClose={handleCloseRolesPermissionsModal}
        handleSubmitPermissions={handleSubmitPermissions}
        appliedPermissions={watchPermissions}
      />

      <div className="flex mx-10" data-cy="filters-container">
        <div>
          <div className="text-sm text-gray-500 dark:text-gray mb-1">{t('Permissions')}</div>
          <Button dataCy="permissions-button" type="button" onClick={handleOpenRolesPermissionsModal}>
            {`${t('SelectPermissions')} : ${watchPermissions.length}`}
          </Button>
        </div>
        <FormContent fields={fields} control={control} register={register} errors={errors} />
      </div>
    </>
  );
};

export default FilterFormContent;
