import { LANGUAGE_VARIANTS } from 'constants/common';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES, getFormatPrice, getFormatPriceUsingGBP } from 'constants/global';
import { GetInvoices } from 'interfaces/integrations/xero.interface';
import { useAppSelector } from 'modules/store';
import { selectLanguagesSettings } from 'modules/user/selector';
import { TableData, TableRow } from 'shared-components';
import TableDataPair from 'shared-components/table/TableDataPair';
import { getFormattedDate } from 'utils/dates';

type Props = {
  invoicesData: GetInvoices;
};

const InvoicesRow = ({ invoicesData }: Props) => {
  const { dueDate, reference, invoiceNumber, amountDue, amountPaid, amountCredited, total, subTotal, totalTax } =
    invoicesData;
  const { currentLanguage } = useAppSelector(selectLanguagesSettings);
  const formatPriceFunction = currentLanguage?.name === LANGUAGE_VARIANTS.UA ? getFormatPriceUsingGBP : getFormatPrice;

  return (
    <>
      <TableRow dataCy="invoices-table-row">
        <TableData dataCy="invoices-due-date">
          {getFormattedDate(dueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="invoices-reference">{reference}</TableData>
        <TableData dataCy="invoices-invoice-number">{invoiceNumber}</TableData>
        <TableData dataCy="invoices-amount">
          <TableDataPair labelClassName={'font-bold'} label="AmountDueUppercase">
            {formatPriceFunction(amountDue)}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="AmountPaidUppercase">
            {formatPriceFunction(amountPaid)}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="AmountCreditedUppercase">
            {formatPriceFunction(amountCredited)}
          </TableDataPair>
        </TableData>
        <TableData dataCy="invoices-total">
          <TableDataPair labelClassName={'font-bold'} label="Total">
            {formatPriceFunction(total)}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="SubTotal">
            {formatPriceFunction(subTotal)}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="TaxTotal">
            {formatPriceFunction(totalTax)}
          </TableDataPair>
        </TableData>
      </TableRow>
    </>
  );
};

export default InvoicesRow;
