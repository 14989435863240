import { Skeleton } from 'shared-components';
import { cn } from 'utils/global';

const ChatMessageSkeleton = () => {
  return (
    <div className={cn('flex flex-col mx-2', {})} data-cy="chat-message">
      <Skeleton className="w-96 h-20 py-2 px-3 rounded-2xl mb-1 self-end" />
      <Skeleton className="w-96 h-20 py-2 px-3 rounded-2xl mb-1 self-start" />
    </div>
  );
};

export default ChatMessageSkeleton;
