import {
  CUSTOMERS_COLUMNS,
  CUSTOMERS_FILTERS_FORM_FIELD_NAMES,
  INVOICE_COLUMNS,
  INVOICES_COLUMNS,
} from 'interfaces/integrations/quickBooks.interface';
import { FieldTypes, TabItem, TableColumn } from 'interfaces/shared.interface';
import Customers from 'page-components/integrations/quickBooks/customers/Customers';
import Invoices from 'page-components/integrations/quickBooks/invoices/Invoices';
import { SelectFormInput } from 'shared-components';

import { BASE_FILTER_FIELDS } from './common';

export const QUICKBOOKS_TABS: TabItem[] = [
  {
    dataCy: 'invoices-tab-button',
    id: 'invoices-tab',
    title: 'Invoices',
    component: Invoices,
  },
  {
    dataCy: 'customers-tab-button',
    id: 'customers-tab',
    title: 'Customers',
    component: Customers,
  },
];

export const INVOICES_COLUMNS_TITLES = {
  [INVOICES_COLUMNS.DUE_DATE]: 'DueDate',
  [INVOICES_COLUMNS.CUSTOMER_NAME]: 'CustomerName',
  [INVOICES_COLUMNS.ISSUE_DATE]: 'IssueDate',
  [INVOICES_COLUMNS.REFERENCE]: 'Reference',
  [INVOICES_COLUMNS.TOTAL_AMOUNT]: 'TotalAmount',
  [INVOICES_COLUMNS.ACTIONS]: '',
};

export const INVOICES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICES_COLUMNS.REFERENCE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.REFERENCE],
    width: 'w-2/12',
  },
  {
    columnId: INVOICES_COLUMNS.CUSTOMER_NAME,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.CUSTOMER_NAME],
    width: 'w-4/12',
  },
  {
    columnId: INVOICES_COLUMNS.ISSUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.ISSUE_DATE],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.DUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.DUE_DATE],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.TOTAL_AMOUNT,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.TOTAL_AMOUNT],
    width: 'w-2/12',
  },
  {
    columnId: INVOICES_COLUMNS.ACTIONS,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.ACTIONS],
    width: 'w-1/12',
  },
];

export const CUSTOMERS_COLUMNS_TITLES = {
  [CUSTOMERS_COLUMNS.NAME]: 'Name',
  [CUSTOMERS_COLUMNS.EMAIL_ADDRESS]: 'EmailAddress',
  [CUSTOMERS_COLUMNS.BILLING_ADDRESS]: 'BillingAddress',
  [CUSTOMERS_COLUMNS.STATUS]: 'Status',
  [CUSTOMERS_COLUMNS.ACTION]: '',
};

export const CUSTOMERS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CUSTOMERS_COLUMNS.NAME,
    title: CUSTOMERS_COLUMNS_TITLES[CUSTOMERS_COLUMNS.NAME],
    width: 'w-4/12',
    sorting: true,
  },
  {
    columnId: CUSTOMERS_COLUMNS.EMAIL_ADDRESS,
    title: CUSTOMERS_COLUMNS_TITLES[CUSTOMERS_COLUMNS.EMAIL_ADDRESS],
    width: 'w-3/12',
  },
  {
    columnId: CUSTOMERS_COLUMNS.BILLING_ADDRESS,
    title: CUSTOMERS_COLUMNS_TITLES[CUSTOMERS_COLUMNS.BILLING_ADDRESS],
    width: 'w-4/12',
  },
  {
    columnId: CUSTOMERS_COLUMNS.STATUS,
    title: CUSTOMERS_COLUMNS_TITLES[CUSTOMERS_COLUMNS.STATUS],
    width: 'w-1/12',
  },
  {
    columnId: CUSTOMERS_COLUMNS.ACTION,
    title: CUSTOMERS_COLUMNS_TITLES[CUSTOMERS_COLUMNS.ACTION],
    width: 'w-1/12',
  },
];

const SHARED_FILTERS_OPTIONS = [
  { label: 'Active', value: 'true' },
  { label: 'NotActive', value: 'false' },
];

export const CUSTOMERS_FILTERS_FORM_FIELDS = [
  {
    name: CUSTOMERS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS,
    label: 'SelectStatus',
    className: 'ml-10 w-40',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-active-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
];

export const getCustomersFiltersFieldsDefaultValues = (selectStatusLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [CUSTOMERS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: { label: selectStatusLabel, value: '' },
});

export const INVOICE_COLUMNS_TITLES = {
  [INVOICE_COLUMNS.DESCRIPTION]: 'Description',
  [INVOICE_COLUMNS.QTY]: 'Qty',
  [INVOICE_COLUMNS.UNIT_PRICE]: 'UnitPrice',
  [INVOICE_COLUMNS.TAX]: 'Tax',
  [INVOICE_COLUMNS.AMOUNT]: 'Amount',
};

export const INVOICE_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICE_COLUMNS.DESCRIPTION,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.DESCRIPTION],
    width: 'w-5/12',
  },
  {
    columnId: INVOICE_COLUMNS.QTY,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.QTY],
    width: 'w-1/12',
  },
  {
    columnId: INVOICE_COLUMNS.UNIT_PRICE,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.UNIT_PRICE],
    width: 'w-2/12',
  },
  {
    columnId: INVOICE_COLUMNS.TAX,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.TAX],
    width: 'w-2/12',
  },
  {
    columnId: INVOICE_COLUMNS.AMOUNT,
    title: INVOICE_COLUMNS_TITLES[INVOICE_COLUMNS.AMOUNT],
    width: 'w-2/12',
  },
];
