import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetTenantDetailsQuery, useUpdateTenantMutation } from 'apis/tenants.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { BaseTenantEditFormValues } from 'interfaces/tenants.interface';
import { ErrorModal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

import TenantEditInputFields from './TenantEditInputsFields';

interface Props {
  handleClose: VoidFunction;
  refetchTenants: VoidFunction;
  tenantId: number | string;
}
const TenantEditModalContent = ({ handleClose, refetchTenants, tenantId }: Props) => {
  const { t } = useTranslation();
  const { data: tenantData, isLoading: isTenantDetailsLoading } = useGetTenantDetailsQuery(tenantId, {
    refetchOnMountOrArgChange: true,
  });
  const [updateTenant, { isLoading: isUpdateTenantLoading, error }] = useUpdateTenantMutation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { reset, ...updateTenantFormMethods } = useForm<BaseTenantEditFormValues>();

  const { data: errorData } = (error as CommonError) || {};

  const onSubmit = async ({ name, subscription, isActive }: BaseTenantEditFormValues) => {
    if (!tenantData) {
      return;
    }

    const payload = {
      name,
      id: tenantData.result.id,
      subscriptionId: Number(subscription.value),
      isActive,
    };

    try {
      await updateTenant(payload).unwrap();
      refetchTenants();
      handleClose();
      successNotify(t('ChangedSuccessfully'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (tenantData) {
      const { name, subscriptionName, subscriptionId, isActive } = tenantData.result;
      reset({
        name: name,
        subscription: {
          label: subscriptionName,
          value: String(subscriptionId),
        },
        isActive: isActive,
      });
    }
  }, [tenantData, reset]);

  return (
    <>
      <FormProvider reset={reset} {...updateTenantFormMethods}>
        <form onSubmit={updateTenantFormMethods.handleSubmit(onSubmit)}>
          <TenantEditInputFields isLoading={isTenantDetailsLoading} />
          <ModalControlButtons isShownSubmit isLoading={isUpdateTenantLoading} onClose={handleClose} />
        </form>
      </FormProvider>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default TenantEditModalContent;
