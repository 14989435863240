import {
  CodatLinkPost,
  CodatLinkResponse,
  GetAccountingDetailsResponse,
  GetAccountingPlatformsResponse,
  InvoicesResponse,
  SuppliersResponse,
} from 'interfaces/integrations/codat-accounting.interface';

import { coreSplitApis } from './core.api';

export const codatAccountingApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['Connection'] }).injectEndpoints({
  endpoints: (build) => ({
    getCodatAccountingPlatforms: build.query<GetAccountingPlatformsResponse, void>({
      query: () => ({
        url: `CodatAccounting/GetAccountingPlatforms`,
        method: 'GET',
      }),
    }),

    getCodatAccountingDetails: build.query<GetAccountingDetailsResponse, void>({
      query: () => ({
        url: `CodatAccounting/GetAccountingDetails`,
        method: 'GET',
      }),
      providesTags: ['Connection'],
    }),

    postCodatAccounting: build.mutation<CodatLinkResponse, CodatLinkPost>({
      query: (body) => ({
        url: `CodatAccounting/ConnectAccounting`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: body,
      }),
    }),

    deleteConnection: build.mutation<void, void>({
      query: () => ({
        url: `CodatAccounting/DeleteAccountingConnection`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Connection']),
    }),

    suspendConnection: build.mutation<void, boolean>({
      query: () => ({
        url: `CodatAccounting/SuspendAccountingConnection`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Connection']),
    }),

    getCodatAccountingInvoices: build.query<InvoicesResponse, string>({
      query: (params) => ({
        url: `CodatAccounting/GetInvoices?${params}`,
        method: 'GET',
      }),
    }),

    getCodatAccountingSuppliers: build.query<SuppliersResponse, string>({
      query: (params) => ({
        url: `CodatAccounting/GetSuppliers?${params}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCodatAccountingPlatformsQuery,
  useGetCodatAccountingDetailsQuery,
  usePostCodatAccountingMutation,
  useDeleteConnectionMutation,
  useSuspendConnectionMutation,
  useGetCodatAccountingInvoicesQuery,
  useGetCodatAccountingSuppliersQuery,
} = codatAccountingApi;
