import { Currency } from 'constants/global';
import { BaseFilterValues, CommonResponse, OptionType } from 'interfaces/shared.interface';

export interface GetAuthLinkResponse extends CommonResponse {
  result?: string;
}

export interface GetConnectionDetailsResponse extends CommonResponse {
  result: {
    expiresInDays: number | null;
    isConnected: boolean;
    isConnectionExpired: boolean | null;
  };
}

export enum INVOICES_COLUMNS {
  REFERENCE = 'reference',
  CUSTOMER_NAME = 'customerName',
  ISSUE_DATE = 'issueDate',
  DUE_DATE = 'dueDate',
  TOTAL_AMOUNT = 'totalAmount',
  ACTIONS = 'actions',
}

export enum CUSTOMERS_COLUMNS {
  NAME = 'name',
  EMAIL_ADDRESS = 'emailAddress',
  BILLING_ADDRESS = 'billingAddress',
  STATUS = 'status',
  ACTION = 'action',
}

export interface GetInvoice {
  id: string;
  description: string;
  amount: number;
  quantity: number;
  unitPrice: number;
  taxInclusiveAmount: number | null;
  tax: number | null;
}

export interface GetInvoices {
  id: string;
  reference: string;
  customerName: string;
  issueDate: string;
  dueDate: string;
  lineItems: GetInvoice[];
  currency: Currency;
  total: number;
  totalTax: number;
  subtotal: number;
}

export interface GetInvoicesResponse {
  result: {
    isReconnectionRequired: boolean;
    data: {
      totalCount: number;
      invoices: GetInvoices[];
    };
  };
}

export interface GetCustomers {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  companyName: string | null;
  emailAddress: string;
  primaryPhoneNumber: string;
  mobileNumber: string | null;
  billingAddress: string;
  preferredDeliveryMethod: string;
  isActive: boolean;
}

export interface GetCustomersResponse {
  result: {
    isReconnectionRequired: boolean;
    data: {
      totalCount: number;
      customers: GetCustomers[];
    };
  };
}

export enum CUSTOMERS_FILTERS_FORM_FIELD_NAMES {
  SELECT_STATUS = 'IsActive',
}

export interface CustomersFilterFormValues extends BaseFilterValues {
  [CUSTOMERS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: OptionType;
}

export interface GetInvoicePdfResponse extends CommonResponse {
  result: {
    isReconnectionRequired: boolean;
    data: {
      fileName: string;
      content: string;
    };
  };
}

export interface GetCustomerResponse {
  result: {
    isReconnectionRequired: boolean;
    data: GetCustomers;
  };
}

export enum INVOICE_COLUMNS {
  DESCRIPTION = 'reference',
  QTY = 'customerName',
  UNIT_PRICE = 'issueDate',
  TAX = 'dueDate',
  AMOUNT = 'totalAmount',
}

export interface GetInvoiceLineItems {
  id: string;
  description: string;
  amount: number;
  quantity: number;
  unitPrice: number;
  taxInclusiveAmount: number;
  tax: number;
}

export interface GetInvoiceDetails {
  id: string;
  reference: string;
  customerName: string;
  issueDate: string;
  dueDate: string;
  lineItems: GetInvoiceLineItems[];
  currency: Currency;
  total: number;
  totalTax: number;
  subtotal: number;
}

export interface GetInvoiceDetailsResponse extends CommonResponse {
  result: {
    isReconnectionRequired: boolean;
    data: GetInvoiceDetails;
  };
}
