import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { FormItem } from 'interfaces/shared.interface';
import { Checkbox, InputNumberFormat } from 'shared-components';

export type Props = {
  label?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  checkboxLabel?: string;
  inputId?: string;
  labelHtmlFor?: string;
  name?: string;
  value?: string;
  dataCy?: string;
} & Omit<FormItem, 'component'>;

const CheckboxWithNumberInput = ({
  label,
  checkboxLabel,
  inputClassName,
  labelClassName,
  className,
  disabled,
  name,
  dataCy,
  maxLength,
}: Props) => {
  const { resetField, control, getValues } = useFormContext();

  const [isChecked, setIsChecked] = useState(!!getValues(name));

  useEffect(() => {
    if (!isChecked) {
      resetField(name);
    }
  }, [getValues, isChecked, name, resetField]);

  return (
    <>
      <Checkbox
        dataCy={`${dataCy}-checkbox`}
        disabled={disabled}
        label={checkboxLabel}
        labelClassName="font-normal"
        checked={isChecked}
        className={className}
        onChange={(e) => setIsChecked(e.target.checked)}
      />

      {isChecked && (
        <>
          <InputNumberFormat
            control={control}
            name={name}
            inputClassName={inputClassName}
            labelClassName={labelClassName}
            label={label}
            disabled={disabled}
            className={className}
            isShownArrowButtons
            fixedDecimalLength={0}
            maxLength={maxLength}
            dataCy={dataCy}
          />
        </>
      )}
    </>
  );
};

export default CheckboxWithNumberInput;

CheckboxWithNumberInput.displayName = 'CheckboxWithNumberInput';
