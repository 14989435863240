import { FC, useCallback, useState } from 'react';

import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GroupBase, MultiValue, OptionsOrGroups, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import useTheme from 'hooks/useTheme';
import { OptionType } from 'interfaces/shared.interface';
import { cn } from 'utils/global';

import MultiSelectValueRemoveButton from './MultiSelectValueRemoveButton';
import { getSelectStyles } from './Select';

interface Props {
  loadOptions: (inputValue: string) => Promise<OptionsOrGroups<OptionType, GroupBase<OptionType>>>;
  isMulti?: boolean;
  error?: string;
  className?: string;
  placeholder?: string;
  onClickHandler?: (item: OptionType | null) => void;
  menuIsOpen?: boolean;
  isClearable?: boolean;
}

const AsyncSelectComponent: FC<Props> = ({
  loadOptions,
  className,
  isMulti,
  placeholder = 'Search',
  onClickHandler,
  menuIsOpen,
  isClearable,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (newValue: MultiValue<OptionType> | SingleValue<OptionType> | null) => {
    if (!onClickHandler) {
      return;
    }

    if (newValue === null) {
      setSelectedOptions([]);
      onClickHandler(null);
      loadOptions('');
      return;
    }

    const selectedOptions = Array.isArray(newValue) ? newValue : [newValue];
    setSelectedOptions(selectedOptions);
    selectedOptions.forEach((option) => {
      onClickHandler(option);
    });
  };

  const debouncedLoadOptions = useCallback(
    debounce((inputValue: string, callback: (options: OptionsOrGroups<OptionType, GroupBase<OptionType>>) => void) => {
      const fetchOptions = async () => {
        try {
          const options = await loadOptions(inputValue);
          callback(options);
        } catch (error) {
          callback([]);
        }
      };
      fetchOptions();
    }, 300),
    [loadOptions]
  );

  return (
    <AsyncSelect
      onChange={handleChange}
      onInputChange={(newValue) => setInputValue(newValue)}
      className={cn('text-base dark:text-white text-gray2 whitespace-nowrap text-ellipsis', className)}
      isMulti={isMulti}
      cacheOptions
      defaultOptions
      loadOptions={debouncedLoadOptions}
      isSearchable
      placeholder={placeholder}
      menuPlacement="bottom"
      noOptionsMessage={() => (inputValue ? t('NoResultsFound') : null)}
      components={{
        MultiValueRemove: MultiSelectValueRemoveButton,
      }}
      styles={getSelectStyles(theme, isMulti, undefined, true, isClearable)}
      value={selectedOptions}
      menuIsOpen={menuIsOpen}
      isClearable={isClearable}
      escapeClearsValue
    />
  );
};

export default AsyncSelectComponent;
