import { Draft } from '@reduxjs/toolkit';

import { COOKIE_KEYS } from 'constants/common';
import {
  GetUserProfilePictureParams,
  ProfileSettings,
  ProfileSettingsResponse,
  UpdatePictureResponse,
  UpdateProfileSettingsPayload,
  UpdateUserProfilePicturePayload,
} from 'interfaces/profile-settings.interface';
import { ChangeLanguagePayload } from 'interfaces/user-config.interfaces';
import {
  CurrentLoginResponse,
  CurrentLoginResult,
  UpdateProfileThemePayload,
  UserConfigResponse,
  UserProfilePictureResponse,
} from 'interfaces/user.interface';
import { getCookie } from 'utils/auth';

import { coreSplitApis } from './core.api';

export const user = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getUserConfig: build.query<UserConfigResponse, void>({
      query: () => {
        const languageName = getCookie(COOKIE_KEYS.LOCALIZATION_CULTURE_NAME);

        return {
          url: `AbpUserConfiguration/GetAll`,
          method: 'GET',
          headers: {
            'Accept-Language': languageName,
          },
        };
      },
    }),
    getCurrentLoginInformations: build.query<CurrentLoginResult, void>({
      query: () => ({
        url: 'Session/GetCurrentLoginInformations',
        method: 'GET',
      }),
      transformResponse: (response: CurrentLoginResponse) => {
        return response.result;
      },
    }),
    changeLanguage: build.mutation<void, ChangeLanguagePayload>({
      query: (data) => ({ url: `User/ChangeLanguage`, method: 'POST', data }),
    }),
    getCurrentUserDetails: build.query<ProfileSettings, void>({
      query: () => ({
        url: 'User/GetCurrentUserDetails',
        method: 'GET',
      }),
      transformResponse: (response: ProfileSettingsResponse) => {
        return response.result;
      },
    }),
    getUserProfilePicture: build.query<UserProfilePictureResponse, GetUserProfilePictureParams>({
      query: (params) => ({
        url: `User/GetProfilePicture?profilePictureId=${params.profilePictureId}${
          params.tenantId ? `&tenantId=${params.tenantId}` : ''
        }`,
        method: 'GET',
      }),
    }),
    updateUserDetails: build.mutation<unknown, UpdateProfileSettingsPayload>({
      query: (registerData) => ({ url: `User/UpdateUserAccount`, method: 'PUT', data: registerData }),
    }),
    updateUserProfilePicture: build.mutation<UpdatePictureResponse, UpdateUserProfilePicturePayload>({
      query: (registerData) => ({ url: `User/UpdateUserProfilePicture`, method: 'PUT', data: registerData }),
    }),
    updateTheme: build.mutation<void, UpdateProfileThemePayload>({
      query: (theme) => ({ url: `User/ChangeTheme`, method: 'POST', data: theme }),
      async onQueryStarted({ theme }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          user.util.updateQueryData('getUserConfig', undefined, (draft: Draft<UserConfigResponse>) => {
            draft.result.setting.values['App.UserThemeName'] = theme;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserConfigQuery,
  useLazyGetUserConfigQuery,
  useGetCurrentUserDetailsQuery,
  useLazyGetCurrentUserDetailsQuery,
  useGetCurrentLoginInformationsQuery,
  useLazyGetCurrentLoginInformationsQuery,
  useGetUserProfilePictureQuery,
  useLazyGetUserProfilePictureQuery,
  useUpdateUserDetailsMutation,
  useUpdateUserProfilePictureMutation,
  useChangeLanguageMutation,
  useUpdateThemeMutation,
} = user;
