import { ACTIVATE_EMAIL_FROM_FIELDS } from 'interfaces/activate-email.interface';
import { FieldTypes, FormItem } from 'interfaces/shared.interface';
import { Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';

export const ACTIVATE_EMAIL_PAGE_TITLE = 'EmailActivation';

export const ACTIVATE_EMAIL_PAGE_TEXT = 'SendEmailActivationLink_Information';

export const ACTIVATE_EMAIL_PAGE_BACK_BUTTON = 'Back';

export const ACTIVATE_EMAIL_PAGE_SUBMIT_BUTTON = 'Submit';

export const ACTIVATE_EMAIL_FORM_FIELDS: FormItem[] = [
  {
    name: ACTIVATE_EMAIL_FROM_FIELDS.userEmail,
    type: FieldTypes.TEXT,
    inputClassName: 'w-full',
    component: Input,
    required: true,
    className: 'mb-4',
    requiredPlaceholder: true,
    placeholder: 'EmailAddress',
    validation: {
      required: 'EmailRequired',
      pattern: { message: 'InvalidEmail', value: EMAIL_PATTERN },
    },
    dataCy: 'email-field',
  },
];

export const SEND_MAIL_SUCCESS_MESSAGE = 'MailSent';
export const SEND_MAIL_SUCCESS_DESCRIPTION = 'ActivationMailSentMessage';
