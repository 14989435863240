import { useParams } from 'react-router-dom';

import SageInvoiceDetailsLayout from 'page-components/integrations/sage/details/SageDetailsLayout';

const SageContactDetailsPage = () => {
  const { contactId } = useParams();

  return (
    <>
      <SageInvoiceDetailsLayout contactId={contactId || ''} />
    </>
  );
};

export default SageContactDetailsPage;
