import { CommonResponse } from 'interfaces/shared.interface';

export interface GetAuthorizationXeroUrlResponse extends CommonResponse {
  result: {
    xeroCallbackUrl: string;
  };
}

export interface GetConnectionXeroDetailsResponse extends CommonResponse {
  result: {
    isConnected: boolean;
  };
}

export interface GetInvoicesResponse extends CommonResponse {
  result: GetInvoices[];
}

export interface GetInvoices extends GetInvoicesResponse {
  cisRate: null | number;
  totalTax: number;
  total: number;
  totalDiscount: null | number;
  invoiceID: string;
  repeatingInvoiceID: null | string;
  hasAttachments: boolean;
  isDiscounted: boolean;
  amountDue: number;
  amountPaid: number;
  fullyPaidOnDate: null | string;
  amountCredited: number;
  updatedDateUTC: string;
  hasErrors: boolean;
  subTotal: number;
  cisDeduction: null | number;
  plannedPaymentDate: null | string;
  type: number;
  status: number;
  contact: GetContactsInInvoices;
  statusAttributeString: null | string;
  date: string;
  invoiceNumber: string;
  reference: string;
  brandingThemeID: string;
  url: null | string;
  currencyRate: number;
  sentToContact: null | string;
  expectedPaymentDate: null | string;
  dueDate: string;
}

export interface GetContactsInInvoices {
  isCustomer: null | boolean;
  xeroNetworkKey: null | string;
  salesDefaultAccountCode: null | string;
  purchasesDefaultAccountCode: null | string;
  trackingCategoryName: null | string;
  trackingCategoryOption: null | string;
  updatedDateUTC: null | string;
  website: null | string;
  discount: null | number;
  hasAttachments: null | boolean;
  isCompanyContact: null | boolean;
  statusAttributeString: null | string;
  accountsPayableTaxType: null | string;
  contactStatus: number;
  contactID: string;
  contactNumber: null | string;
  name: string;
  firstName: null | string;
  accountNumber: null | string;
  emailAddress: null | string;
  skypeUserName: null | string;
  bankAccountDetails: null | string;
  taxNumber: null | string;
  accountsReceivableTaxType: null | string;
  lastName: null | string;
}

export interface GetContactsResponce extends CommonResponse {
  result: GetContactsInInvoices[];
}

export enum INVOICES_COLUMNS {
  DUE_DATE = 'dueDate',
  REFERENCE = 'reference',
  INVOICE_NUMBER = 'invoiceNumber',
  AMOUNT = 'amount',
  TOTAL = 'total',
}

export enum CONTACTS_COLUMNS {
  NAME = 'name',
  CONTACT_NAME = 'contactName',
  EMAIL_ADDRESS = 'emailAddress',
}
