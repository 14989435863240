import { ReactNode } from 'react';

import { TopStatItemVariant } from 'constants/dashboard';

import { CommonResponse, OptionsTypeValue } from './shared.interface';

export interface TenantTopStats {
  newFeedbacks: number;
  newOrders: number;
  newUsers: number;
  totalProfit: number;
}

export interface TenantTopStatsResponse extends CommonResponse {
  result: TenantTopStats;
}

export interface TopStatsWidgetItem<T> {
  id: keyof T;
  variant?: TopStatItemVariant;
  title: string;
  description?: string;
  isCurrency?: boolean;
  valueClassName: string;
  progressLabel?: string;
  progressLabelClassName?: string;
  progressLabelValueClassName?: string;
  progressClassName?: string;
}

export interface DashboardFiltersForm {
  dateRange: [Date, Date];
}

export interface DashboardState {
  isEditMode: boolean;
}

export interface DashboardAction {
  id: string;
  component: ReactNode;
}

export interface DashboardWidget {
  widgetId: string;
  width: number;
  height: number;
  positionX: number;
  positionY: number;
}

export interface DashboardPage {
  id: string;
  name: string;
  widgets: DashboardWidget[];
}

export interface DashboardLayout {
  dashboardName: string;
  pages: DashboardPage[];
}

export interface DeletePageQueries {
  id: string;
  dashboardName: string;
}

export interface GetDashboardLayoutResponse {
  result: DashboardLayout;
}

export interface GetDashboardDefinition {
  result: DashboardDefinitions;
}

export interface DashboardDefinitions {
  name: string;
  widgets: DashboardDefinition[];
}

export interface DashboardDefinition {
  id: string;
  name: string;
}

export interface AddWidgetPayload {
  widgetId: string;
  pageId: string;
  dashboardName: string;
  width: number;
  height: number;
}

export interface AddWidgetForm {
  widget: OptionsTypeValue;
}

export interface AddPagePayload {
  dashboardName: string;
  name: string;
}

export interface RenamePagePayload {
  id: string;
  dashboardName: string;
  name: string;
}

export interface AddPageForm {
  name: string;
}

export interface RenamePageForm {
  name: string;
}

export enum ADD_WIDGET_FORM_FIELD_NAMES {
  widget = 'widget',
}

export enum ADD_PAGE_FORM_FIELD_NAMES {
  name = 'name',
}

export enum RENAME_PAGE_FORM_FIELD_NAMES {
  name = 'name',
}
