import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { useCreateSubscriptionMutation } from 'apis/subscriptions-admin.api';
import {
  CREATE_SUBSCRIPTION_DEFAULT_VALUES,
  SUBSCRIPTION_MANAGEMENT_VALIDATE_SCHEMA,
  SUBSCRIPTION_MANAGEMENT_TABS,
} from 'constants/subscriptions-admin';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import {
  SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES,
  CreateSubscriptionFormValues,
  CreateSubscriptionPayload,
} from 'interfaces/subscriptions-admin.interface';
import { ErrorModal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';
import { getCapitalizedValue, parseNumberFormatInputToNumber } from 'utils/string';

interface Props {
  onClose: VoidFunction;
  refetch: VoidFunction;
  isLoading: boolean;
}
const CreateSubscriptionModalContent = ({ onClose, refetch, isLoading }: Props) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [createSubscription, { error: createSubscriptionError }] = useCreateSubscriptionMutation();

  const errorData = (createSubscriptionError as CommonError)?.data || {};

  const subscriptionsFormMethods = useForm<CreateSubscriptionFormValues>({
    resolver: yupResolver(SUBSCRIPTION_MANAGEMENT_VALIDATE_SCHEMA),
    defaultValues: CREATE_SUBSCRIPTION_DEFAULT_VALUES,
    reValidateMode: 'onBlur',
  });

  const onSubmit = async ({
    displayName,
    isPaid,
    dailyPrice,
    monthlyPrice,
    weeklyPrice,
    annualPrice,
    trialDayCount,
    waitingDayAfterExpire,
    expiringSubscriptionId,
    managingUsers,
    maxUserCount,
  }: CreateSubscriptionFormValues) => {
    const payload: CreateSubscriptionPayload = {
      featureValues: [
        {
          name: `App.${getCapitalizedValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS)}`,
          value: managingUsers.toString(),
        },
        {
          name: `App.${getCapitalizedValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT)}`,
          value: maxUserCount,
        },
      ],
      subscription: {
        displayName,
        isPaid: !!isPaid,
        dailyPrice: parseNumberFormatInputToNumber(dailyPrice),
        monthlyPrice: parseNumberFormatInputToNumber(monthlyPrice),
        weeklyPrice: parseNumberFormatInputToNumber(weeklyPrice),
        annualPrice: parseNumberFormatInputToNumber(annualPrice),
        trialDayCount: parseNumberFormatInputToNumber(trialDayCount),
        waitingDayAfterExpire: parseNumberFormatInputToNumber(waitingDayAfterExpire),
        expiringSubscriptionId: expiringSubscriptionId || null,
      },
    };

    try {
      await createSubscription(payload).unwrap().then(refetch);
      onClose();
      successNotify();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="create-subscription-modal">
        <FormProvider {...subscriptionsFormMethods}>
          <form onSubmit={subscriptionsFormMethods.handleSubmit(onSubmit)}>
            <Tabs tabs={SUBSCRIPTION_MANAGEMENT_TABS} />
            <ModalControlButtons isShownSubmit onClose={onClose} isLoading={isLoading} />
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default CreateSubscriptionModalContent;
