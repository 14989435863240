import { CommonResponse } from './shared.interface';

export enum ACTIVATE_EMAIL_FROM_FIELDS {
  userEmail = 'userEmail',
}
export interface ActivateEmailFormValues {
  [ACTIVATE_EMAIL_FROM_FIELDS.userEmail]: string;
}

export interface ActivateEmailPayload {
  emailAddress: string;
}

export interface ActivateResponse extends CommonResponse {
  result: null;
}
