import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import SubscriptionSelectCarousel from 'page-components/subscriptions-tenant/modal/subscription-select-modal/SubscriptionSelectCarousel';
import TenantSubscriptionModal from 'page-components/subscriptions-tenant/modal/TenantSubscriptionModal';

const SubscriptionSelect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isBackButton = location.state?.isBackButton || false;

  return (
    <TenantSubscriptionModal
      dataCy="choose-tenant-subscription-modal"
      heading="ChooseSubscription"
      onClose={() => navigate(ROUTES.tenantSubscription)}
      isBackButton={isBackButton}
    >
      <SubscriptionSelectCarousel />
    </TenantSubscriptionModal>
  );
};

export default SubscriptionSelect;
