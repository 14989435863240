import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetRepaymentsQuery } from 'apis/gocardless.apis';
import {
  getGoCardlessFiltersFieldsDefaultValue,
  GOCARDLESS_FILTER_FORM_FIELDS,
  GOCARDLESS_TABLE_COLUMNS,
  SEARCH_GOCARDLESS_PLACEHOLDER,
} from 'constants/gocardless';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { GoCardlessFilterFormValues } from 'interfaces/integrations/gocardless.interface';
import { CommonError } from 'interfaces/shared.interface';
import CreatePayButton from 'page-components/integrations/gocardless/create-pay-gocardless/CreatePayButton';
import RepaymentsRow from 'page-components/integrations/gocardless/RepaymentsRow';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

const GoCardless = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { filterParams, applyFilters, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: errorGoCardlessData,
  } = useGetRepaymentsQuery(`${filterParams}${sortingParams}${paginationParams}`);

  const { result } = data || {};

  const { error: errorData } = (errorGoCardlessData as CommonError)?.data || {};

  const goCardlessFiltersMethods = useForm<GoCardlessFilterFormValues>({
    defaultValues: getGoCardlessFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectPaymentType')),
  });

  const onSubmitFilters = async (data: GoCardlessFilterFormValues) => {
    const { status, paymentType, filter } = data;

    const newFiltersToApply = {
      searchQuery: filter,
      StatusId: status.value !== '' ? (status.value as boolean).toString() : null,
      PaymentTypeId: paymentType.value !== '' ? (paymentType.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    goCardlessFiltersMethods.reset(getGoCardlessFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectPaymentType')));
    onSubmitFilters(getGoCardlessFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectPaymentType')));
  };

  useEffect(() => {
    if (errorGoCardlessData) {
      handleOpenErrorModal();
    }
  }, [errorGoCardlessData, handleOpenErrorModal]);

  useEffect(() => {
    goCardlessFiltersMethods.reset(getGoCardlessFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectPaymentType')));
  }, [t, goCardlessFiltersMethods]);

  return (
    <div data-cy="gocardless-page">
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />
      <div className="float-right">
        <CreatePayButton />
      </div>

      <div>
        <FormProvider {...goCardlessFiltersMethods}>
          <form onSubmit={goCardlessFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="go-cardless-page-search"
              placeHolder={SEARCH_GOCARDLESS_PLACEHOLDER}
              handleClearFields={handleClearFields}
            >
              <FilterFormContentWithoutPermissions className="flex" fields={GOCARDLESS_FILTER_FORM_FIELDS} />
            </FilterForm>
          </form>
        </FormProvider>
      </div>
      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <Table
          dataCy="repayments-table"
          columns={GOCARDLESS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          sortingType={sortingType}
          isLoading={isLoading}
          isTableEmpty={!result?.totalCount}
        >
          {result?.items.map((item) => <RepaymentsRow key={item.id} repaymentsData={item} />)}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default GoCardless;
