import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
const Divider = ({ children }: Props) => {
  return (
    <div className="border-solid border-opacity-10 border-black dark:border-darkGray3 border-y-1 p-4">{children}</div>
  );
};

export default Divider;
