import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { SHARED_MODAL_BUTTON_STYLES, SHARED_MODAL_BUTTON_WRAPPER_STYLES } from 'constants/subscription-tenant';
import TenantSubscriptionModal from 'page-components/subscriptions-tenant/modal/TenantSubscriptionModal';
import { Button, LoadingOverlay } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  icon: IconDefinition;
  heading: string;
  iconClassName: string;
  description?: string;
  isLoading?: boolean;
}

const PaymentResult = ({ icon, heading, iconClassName, description, isLoading = true }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div data-cy="subscription-invoice-purchase-page">
      <TenantSubscriptionModal
        contentClassName="mt-0 mb-8"
        bodyClassName={cn('min-w-[20rem]', { 'h-72': isLoading })}
        headingClassName="pt-8"
        isPaymentInfo
        heading={heading}
        endChild={
          !isLoading && (
            <div className={SHARED_MODAL_BUTTON_WRAPPER_STYLES}>
              <Button
                dataCy="continue-button"
                className={SHARED_MODAL_BUTTON_STYLES}
                onClick={() => navigate(ROUTES.tenantSubscription)}
              >
                {t('Continue')}
              </Button>
            </div>
          )
        }
      >
        <div className={cn('text-center text-sm', { 'pb-4': !description })}>
          {description && <p className="pb-4">{t(description)}</p>}
          {!isLoading && (
            <FontAwesomeIcon icon={icon} className={cn('block w-full h-12 text-center pb-2 pt-8', iconClassName)} />
          )}
          {isLoading && <LoadingOverlay className="pt-20" isLoading={isLoading} spinnerWrapper="!opacity-0" />}
        </div>
      </TenantSubscriptionModal>
    </div>
  );
};

export default PaymentResult;
