import { ReactNode } from 'react';

import { Modal } from 'shared-components';

interface Props {
  isOpen: boolean;
  handleClose: VoidFunction;
  children?: ReactNode;
  heading?: string;
}
const SubscriptionManagementModal = ({ isOpen, handleClose, children, heading }: Props) => {
  return (
    <Modal heading={heading} isOpen={isOpen} onClose={handleClose}>
      {children}
    </Modal>
  );
};

export default SubscriptionManagementModal;
