import { useTranslation } from 'react-i18next';

import { useChangeLanguageMutation } from 'apis/user.api';
import { ReactComponent as GlobeSvg } from 'assets/svg/globe.svg';
import { COOKIE_KEYS } from 'constants/common';
import { useOpen } from 'hooks/useOpen';
import { useUserConfig } from 'hooks/useUserConfig';
import { CommonError } from 'interfaces/shared.interface';
import { useAppSelector } from 'modules/store';
import { selectLanguagesSettings } from 'modules/user/selector';
import { cn } from 'utils/global';

import MenuItem from './MenuItem';
import ErrorModal from './modal/ErrorModal';

const ChangeLanguage = () => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { handleGetUserConfig } = useUserConfig();

  const { currentLanguage, languages } = useAppSelector(selectLanguagesSettings);
  const [changeLanguage, { error: changeLanguageError }] = useChangeLanguageMutation();
  const errorData = (changeLanguageError as CommonError)?.data || {};

  const onChangeLanguage = async (languageName: string) => {
    try {
      document.cookie = `${COOKIE_KEYS.LOCALIZATION_CULTURE_NAME}=${languageName}`;

      await changeLanguage({ languageName }).unwrap();
      await handleGetUserConfig();
    } catch (err) {
      handleOpenErrorModal();
    }
  };
  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <MenuItem
        image={<GlobeSvg className="w-5 fill-darkGray6 dark:fill-darkGray3" />}
        label={t('Language', { 0: t(currentLanguage?.displayName || 'English') })}
        dropdownIcon
        className="p-0"
        dataCy="languages-main-item"
      >
        <div
          className="hidden absolute top-0 right-56 w-56 mr-3.5 bg-white shadow-lg hover-language-block"
          data-cy="languages-menu-item"
        >
          {languages
            ?.filter((language) => language.name !== currentLanguage?.name)
            .map((language) => (
              <MenuItem
                dataCy="language-menu-item"
                key={language.name}
                image={<div className={cn(language.icon, 'w-5 fill-darkGray6 dark:fill-darkGray3')} />}
                className="h-9"
                label={language.displayName}
                onClick={() => onChangeLanguage(language.name)}
              />
            ))}
        </div>
      </MenuItem>
    </>
  );
};

export default ChangeLanguage;
