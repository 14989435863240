import TreeView, { flattenTree, INodeRendererProps, ITreeViewState } from 'react-accessible-treeview';
import { Control, FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';

import { SUBSCRIPTION_FEATURES_ELEMENTS } from 'constants/subscriptions-admin';
import { FieldTypes } from 'interfaces/shared.interface';
import { SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES } from 'interfaces/subscriptions-admin.interface';
import { InputNumberFormat } from 'shared-components';
import TreeViewItem from 'shared-components/TreeViewItem';

const ELEMENTS_WITH_INPUT_IDS = [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT];

interface NodeProps extends INodeRendererProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
}

const NodeRenderer: React.FC<NodeProps> = (props) => {
  const { register, control } = props;
  const renderWithInput = ELEMENTS_WITH_INPUT_IDS.some((id) => id === props.element.id);

  if (renderWithInput) {
    return (
      <TreeViewItem {...props} isSelected>
        <InputNumberFormat
          {...register(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT)}
          dataCy="max-users-count-field"
          type={FieldTypes.NUMBER}
          inputClassName="max-h-7 dark:hover:none dark:border-darkBlue"
          className="ml-2"
          control={control}
          isShownArrowButtons
          fixedDecimalLength={0}
          arrowButtonArrowClassName="w-4 dark:bg-darkBlue"
          arrowButtonBodyClassName="scale-y-[0.7] w-4"
        />
      </TreeViewItem>
    );
  }

  return <TreeViewItem {...props} />;
};

NodeRenderer.displayName = 'NodeRenderer';

const SubscriptionFeaturesTab = () => {
  const { register, control, setValue, getValues } = useFormContext();

  const subscriptionFeatures = flattenTree({
    name: '',
    children: SUBSCRIPTION_FEATURES_ELEMENTS,
  });

  const selectedIds = SUBSCRIPTION_FEATURES_ELEMENTS.filter((element) => getValues(element.id)).map(
    (element) => element.id
  );

  const handleChangeSelectedIds = ({ treeState }: { treeState: ITreeViewState }) => {
    const managingUsersValues = Array.from(treeState.selectedIds).some(
      (id) => id === SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS
    );
    setValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS, managingUsersValues);
  };

  const renderNode =
    // eslint-disable-next-line react/display-name
    (register: UseFormRegister<FieldValues>, control: Control<FieldValues>) => (props: INodeRendererProps) => {
      return <NodeRenderer {...props} register={register} control={control} />;
    };

  return (
    <div>
      <TreeView
        data={subscriptionFeatures}
        aria-label="create-subscription-features"
        multiSelect
        defaultSelectedIds={selectedIds}
        togglableSelect
        onSelect={handleChangeSelectedIds}
        nodeRenderer={renderNode(register, control)}
      />
    </div>
  );
};

export default SubscriptionFeaturesTab;
