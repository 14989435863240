import {
  RolesResponse,
  GetRoleForEditPayload,
  CreateOrUpdateRolePayload,
  CreateOrUpdateRoleResponse,
} from 'interfaces/roles.interface';
import { GetListSharedPayload } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const roles = coreSplitApis.enhanceEndpoints({ addTagTypes: ['Roles', 'GetRole'] }).injectEndpoints({
  endpoints: (build) => ({
    getRoles: build.query<RolesResponse, GetListSharedPayload>({
      query: ({ isAdmin, params }) => ({
        url: `${isAdmin ? 'RoleAdmin' : 'Role'}/GetRoles?${params}`,
        method: 'GET',
      }),
      providesTags: ['Roles'],
    }),
    getRoleForEdit: build.query<GetRoleForEditPayload, { id?: number | null }>({
      query: (params) => ({ url: `Role/GetRoleForEdit`, method: 'GET', params }),
      providesTags: ['GetRole'],
    }),
    createOrUpdateRole: build.mutation<CreateOrUpdateRoleResponse, CreateOrUpdateRolePayload>({
      query: (data) => ({ url: `Role/CreateOrUpdateRole`, method: 'POST', data }),
      invalidatesTags: (_, error) => (error ? [] : ['GetRole']),
    }),
    deleteRole: build.mutation<void, number>({
      query: (params) => ({
        url: `Role/DeleteRole?Id=${params}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Roles']),
    }),
  }),
  overrideExisting: false,
});

export const { useGetRolesQuery, useDeleteRoleMutation, useGetRoleForEditQuery, useCreateOrUpdateRoleMutation } = roles;
