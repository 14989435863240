import { faSterlingSign } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';

import {
  BASE_RADIO_BUTTON_RADIO_VALUES,
  FieldTypes,
  FormItem,
  TabItem,
  TableColumn,
} from 'interfaces/shared.interface';
import {
  SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES,
  SUBSCRIPTION_PAYMENT_STATUSES,
  SUBSCRIPTION_PLAN_PERIODS,
  SUBSCRIPTIONS_COLUMNS,
  SUBSCRIPTIONS_FILTERS_FORM_FIELD_NAMES,
} from 'interfaces/subscriptions-admin.interface';
import SubscriptionFeaturesTab from 'page-components/subscriptions-admin/subscriptions-management-modal/SubscriptionFeaturesTab';
import SubscriptionPropertiesTab from 'page-components/subscriptions-admin/subscriptions-management-modal/SubscriptionPropertiesTab';
import { CheckboxWithNumberInput, InputNumberFormat, SelectFormInput } from 'shared-components';

import { BASE_FILTER_FIELDS } from './common';
import { REQUIRED_FIELD_MESSAGE } from './global';

export const SUBSCRIPTION_MANAGEMENT_TABS: TabItem[] = [
  {
    id: 'sub-props',
    title: 'SubscriptionProperties',
    component: SubscriptionPropertiesTab,
    dataCy: 'subscription-properties-tab',
  },
  {
    id: 'sub-permissions',
    title: 'Features',
    component: SubscriptionFeaturesTab,
    dataCy: 'subscription-permissions-tab',
  },
];

export const SubscriptionManagementPaymentFormFields = (isDisabled?: boolean): FormItem[] => {
  return [
    {
      name: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DAILY_PRICE,
      type: FieldTypes.NUMBER,
      label: 'DailyPrice',
      component: InputNumberFormat,
      dataCy: 'daily-price-field',
      inputClassName: 'w-full text-sm',
      labelClassName: 'font-semibold',
      className: 'mt-4',
      disabled: isDisabled,
      icon: faSterlingSign,
    },
    {
      name: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WEEKLY_PRICE,
      type: FieldTypes.NUMBER,
      label: 'WeeklyPrice',
      dataCy: 'weekly-price-field',
      component: InputNumberFormat,
      inputClassName: 'w-full text-sm',
      labelClassName: 'font-semibold',
      className: 'mt-4',
      disabled: isDisabled,
      icon: faSterlingSign,
    },
    {
      name: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MONTHLY_PRICE,
      type: FieldTypes.NUMBER,
      label: 'MonthlyPrice',
      dataCy: 'monthly-price-field',
      component: InputNumberFormat,
      inputClassName: 'w-full text-sm',
      labelClassName: 'font-semibold',
      className: 'mt-4',
      disabled: isDisabled,
      icon: faSterlingSign,
    },
    {
      name: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ANNUAL_PRICE,
      type: FieldTypes.NUMBER,
      label: 'AnnualPrice',
      dataCy: 'annual-price-field',
      component: InputNumberFormat,
      inputClassName: 'w-full text-sm',
      labelClassName: 'font-semibold',
      className: 'mt-4',
      disabled: isDisabled,
      icon: faSterlingSign,
    },

    {
      name: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.TRIAL_DAY_COUNT,
      type: FieldTypes.NUMBER,
      label: 'TrialDayCount',
      checkboxLabel: 'IsTrialActive',
      dataCy: 'trial-day-count-field',
      component: CheckboxWithNumberInput,
      inputClassName: 'w-full text-sm',
      labelClassName: 'font-medium',
      className: 'mt-4',
      disabled: isDisabled,
    },
    {
      name: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WAITING_DAY_AFTER_EXPIRE,
      type: FieldTypes.NUMBER,
      label: 'WaitingDayAfterExpire',
      checkboxLabel: 'WaitAfterSubscriptionExpireDate',
      dataCy: 'waiting-day-after-expire-field',
      component: CheckboxWithNumberInput,
      inputClassName: 'w-full text-sm',
      labelClassName: 'font-medium',
      className: 'mt-4',
      disabled: isDisabled,
    },
  ];
};

export const CREATE_SUBSCRIPTION_RADIO_INPUTS_OPTIONS = [
  {
    id: '1',
    label: 'DeactivateTenant',
    value: BASE_RADIO_BUTTON_RADIO_VALUES.NO,
    dataCy: 'deactivate-tenant-radio',
  },
  {
    id: '2',
    label: 'AssignToAnotherSubscription',
    value: BASE_RADIO_BUTTON_RADIO_VALUES.YES,
    dataCy: 'assign-to-another-subscription-radio',
  },
];

export const SUBSCRIPTION_LEGEND = 'WhatWillDoneAfterSubscriptionExpiry';

export const SUBSCRIPTION_FEATURES_ELEMENTS = [
  { id: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS, name: 'ManagingUsers' },
  { id: SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT, name: 'MaximumUserCount' },
];

export const CREATE_SUBSCRIPTION_DEFAULT_VALUES = {
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID]: false,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DAILY_PRICE]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WEEKLY_PRICE]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MONTHLY_PRICE]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ANNUAL_PRICE]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.TRIAL_DAY_COUNT]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WAITING_DAY_AFTER_EXPIRE]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ASSIGNED_SUBSCRIPTION]: BASE_RADIO_BUTTON_RADIO_VALUES.NO,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION_ID]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION]: null,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS]: false,
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT]: '0',
};

export const OPTION_VALUES_SCHEMA = yup.object().shape({
  label: yup.string().required(),
  value: yup.string().required(),
});

export const SUBSCRIPTION_MANAGEMENT_VALIDATE_SCHEMA = yup
  .object()
  .shape({
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DISPLAY_NAME]: yup.string().required(REQUIRED_FIELD_MESSAGE),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS]: yup.boolean().required(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT]: yup.string().required(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DAILY_PRICE]: yup.string().nullable(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WEEKLY_PRICE]: yup.string().nullable(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MONTHLY_PRICE]: yup.string().nullable(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ANNUAL_PRICE]: yup.string().nullable(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID]: yup.boolean(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ASSIGNED_SUBSCRIPTION]: yup.string().nullable().notRequired(),
    [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION]: OPTION_VALUES_SCHEMA.default(null).nullable(),
  })
  .test(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID, '', (values) => {
    if (!values[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID]) {
      return true;
    }

    const hasAtLeastOnePrice =
      values[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DAILY_PRICE] ||
      values[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WEEKLY_PRICE] ||
      values[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MONTHLY_PRICE] ||
      values[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ANNUAL_PRICE];

    if (!hasAtLeastOnePrice) {
      return new yup.ValidationError(
        'AtLeastOnePriceRequired',
        null,
        SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID
      );
    }

    return true;
  })

  .test(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION, '', (values) => {
    if (
      values[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ASSIGNED_SUBSCRIPTION] === BASE_RADIO_BUTTON_RADIO_VALUES.YES &&
      !values[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION]
    ) {
      return new yup.ValidationError(
        REQUIRED_FIELD_MESSAGE,
        null,
        SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION
      );
    }

    return true;
  });

export const SUBSCRIPTIONS_COLUMNS_TITLES = {
  [SUBSCRIPTIONS_COLUMNS.NAME]: 'Name',
  [SUBSCRIPTIONS_COLUMNS.PRICE]: 'Price',
  [SUBSCRIPTIONS_COLUMNS.WAITING_DAY_AFTER_EXPIRE]: 'WaitingDayAfterExpire',
  [SUBSCRIPTIONS_COLUMNS.EXPIRING_SUBSCRIPTION]: 'ExpiringSubscription',
  [SUBSCRIPTIONS_COLUMNS.STATUS]: 'Status',
  [SUBSCRIPTIONS_COLUMNS.ACTIONS]: '',
};

export const SEARCH_SUBSCRIPTIONS_PLACEHOLDER = 'SearchByNameWithThreeDot';

export const SUBSCRIPTIONS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: SUBSCRIPTIONS_COLUMNS.NAME,
    title: SUBSCRIPTIONS_COLUMNS_TITLES[SUBSCRIPTIONS_COLUMNS.NAME],
    width: 'w-4/12',
  },
  {
    columnId: SUBSCRIPTIONS_COLUMNS.PRICE,
    title: SUBSCRIPTIONS_COLUMNS_TITLES[SUBSCRIPTIONS_COLUMNS.PRICE],
    width: 'w-2/12',
  },
  {
    columnId: SUBSCRIPTIONS_COLUMNS.WAITING_DAY_AFTER_EXPIRE,
    title: SUBSCRIPTIONS_COLUMNS_TITLES[SUBSCRIPTIONS_COLUMNS.WAITING_DAY_AFTER_EXPIRE],
    width: 'w-2/12',
  },
  {
    columnId: SUBSCRIPTIONS_COLUMNS.EXPIRING_SUBSCRIPTION,
    title: SUBSCRIPTIONS_COLUMNS_TITLES[SUBSCRIPTIONS_COLUMNS.EXPIRING_SUBSCRIPTION],
    width: 'w-2/12',
  },
  {
    columnId: SUBSCRIPTIONS_COLUMNS.STATUS,
    title: SUBSCRIPTIONS_COLUMNS_TITLES[SUBSCRIPTIONS_COLUMNS.STATUS],
    width: 'w-2/12',
  },
  { columnId: SUBSCRIPTIONS_COLUMNS.ACTIONS, title: SUBSCRIPTIONS_COLUMNS_TITLES[SUBSCRIPTIONS_COLUMNS.ACTIONS] },
];

const SHARED_FILTERS_OPTIONS = [
  { label: 'Active', value: '1' },
  { label: 'Deactivated', value: '2' },
];

export const SUBSCRIPTIONS_FILTERS_FORM_FIELDS = [
  {
    name: SUBSCRIPTIONS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS,
    label: 'SelectStatus',
    className: 'ml-10 w-40',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-active-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
];

export const getSubscriptionsFiltersFieldsDefaultValues = (selectStatusLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [SUBSCRIPTIONS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: { label: selectStatusLabel, value: '' },
});

export const SUBSCRIPTION_PAYMENT_STATUS_LABELS = {
  [SUBSCRIPTION_PAYMENT_STATUSES.CREATED]: 'Created',
  [SUBSCRIPTION_PAYMENT_STATUSES.PAID]: 'Paid',
  [SUBSCRIPTION_PAYMENT_STATUSES.FAILED]: 'Failed',
  [SUBSCRIPTION_PAYMENT_STATUSES.CANCELLED]: 'Cancelled',
  [SUBSCRIPTION_PAYMENT_STATUSES.COMPLETED]: 'Completed',
};

export const SUBSCRIPTION_PLAN_PERIOD_LABELS = {
  [SUBSCRIPTION_PLAN_PERIODS.TRIAL]: 'Trial',
  [SUBSCRIPTION_PLAN_PERIODS.UNLIMITED]: 'Unlimited',
  [SUBSCRIPTION_PLAN_PERIODS.DAILY]: 'Daily',
  [SUBSCRIPTION_PLAN_PERIODS.WEEKLY]: 'Weekly',
  [SUBSCRIPTION_PLAN_PERIODS.MONTHLY]: 'Monthly',
  [SUBSCRIPTION_PLAN_PERIODS.ANNUAL]: 'Annual',
};
