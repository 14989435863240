import { Provider } from 'react-redux';
import { Flip, ToastContainer } from 'react-toastify';

import { Theme } from 'shared-components';

import { store } from './modules/store';
import Router from './Router';
import I18NextLayout from './shared-components/I18NextLayout';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <I18NextLayout>
        <Theme>
          <Router />
        </Theme>
        <ToastContainer
          position="top-right"
          hideProgressBar
          closeOnClick
          pauseOnHover
          theme="colored"
          transition={Flip}
        />
      </I18NextLayout>
    </Provider>
  );
}

export default App;
