import { addDays } from 'date-fns';

import {
  ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES,
  ACCOUNTS_COLUMNS,
  ACCOUNTS_TRANSACTIONS_COLUMNS,
  CARDS_COLUMNS,
} from 'interfaces/integrations/truelayer.interface';
import { FieldTypes, FormItem, TabItem, TableColumn } from 'interfaces/shared.interface';
import Accounts from 'page-components/integrations/truelayer/accounts/Accounts';
import Cards from 'page-components/integrations/truelayer/cards/Cards';
import DatePickerInput from 'shared-components/DatePickerInput';

export const ACCOUNTS_COLUMNS_TITLES = {
  [ACCOUNTS_COLUMNS.NAME]: 'Name',
  [ACCOUNTS_COLUMNS.TYPE]: 'Type',
  [ACCOUNTS_COLUMNS.CURRENCY]: 'Currency',
  [ACCOUNTS_COLUMNS.ACTION]: '',
};

export const ACCOUNTS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: ACCOUNTS_COLUMNS.NAME,
    title: ACCOUNTS_COLUMNS_TITLES[ACCOUNTS_COLUMNS.NAME],
    width: 'w-6/12',
  },
  {
    columnId: ACCOUNTS_COLUMNS.TYPE,
    title: ACCOUNTS_COLUMNS_TITLES[ACCOUNTS_COLUMNS.TYPE],
    width: 'w-4/12',
    sorting: true,
  },
  {
    columnId: ACCOUNTS_COLUMNS.CURRENCY,
    title: ACCOUNTS_COLUMNS_TITLES[ACCOUNTS_COLUMNS.CURRENCY],
    width: 'w-3/12',
  },
  {
    columnId: ACCOUNTS_COLUMNS.ACTION,
    title: ACCOUNTS_COLUMNS_TITLES[ACCOUNTS_COLUMNS.ACTION],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const CARDS_COLUMNS_TITLES = {
  [CARDS_COLUMNS.NAME]: 'Name',
  [CARDS_COLUMNS.CARD_NUMBER]: 'CardNumber',
  [CARDS_COLUMNS.TYPE]: 'Type',
  [CARDS_COLUMNS.NETWORK]: 'Network',
  [CARDS_COLUMNS.CURRENCY]: 'Currency',
  [CARDS_COLUMNS.ACTION]: '',
};

export const CARDS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CARDS_COLUMNS.NAME,
    title: CARDS_COLUMNS_TITLES[CARDS_COLUMNS.NAME],
    width: 'w-3/12',
  },
  {
    columnId: CARDS_COLUMNS.CARD_NUMBER,
    title: CARDS_COLUMNS_TITLES[CARDS_COLUMNS.CARD_NUMBER],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: CARDS_COLUMNS.TYPE,
    title: CARDS_COLUMNS_TITLES[CARDS_COLUMNS.TYPE],
    width: 'w-2/12',
  },
  {
    columnId: CARDS_COLUMNS.NETWORK,
    title: CARDS_COLUMNS_TITLES[CARDS_COLUMNS.NETWORK],
    width: 'w-3/12',
  },
  {
    columnId: CARDS_COLUMNS.CURRENCY,
    title: CARDS_COLUMNS_TITLES[CARDS_COLUMNS.CURRENCY],
    width: 'w-3/12',
  },
  {
    columnId: CARDS_COLUMNS.ACTION,
    title: CARDS_COLUMNS_TITLES[CARDS_COLUMNS.ACTION],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const TRUELAYER_TABS: TabItem[] = [
  {
    id: 'accounts-tab-button',
    title: 'Accounts',
    component: Accounts,
  },
  {
    dataCy: 'cards-tab-button',
    id: 'cards',
    title: 'Cards',
    component: Cards,
  },
];
export const ACCOUNTS_TRANSACTIONS_COLUMNS_TITLES = {
  [ACCOUNTS_TRANSACTIONS_COLUMNS.DATE]: 'Date',
  [ACCOUNTS_TRANSACTIONS_COLUMNS.DESCRIPTION]: 'Description',

  [ACCOUNTS_TRANSACTIONS_COLUMNS.AMOUNT]: 'Amount',

  [ACCOUNTS_TRANSACTIONS_COLUMNS.TYPE]: 'Type',

  [ACCOUNTS_TRANSACTIONS_COLUMNS.CATEGORY]: 'Category',

  [ACCOUNTS_TRANSACTIONS_COLUMNS.RUNNING_BALANCE]: 'RunningBalance',
};

export const ACCOUNTS_TRANSACTIONS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: ACCOUNTS_TRANSACTIONS_COLUMNS.DATE,
    title: ACCOUNTS_TRANSACTIONS_COLUMNS_TITLES[ACCOUNTS_TRANSACTIONS_COLUMNS.DATE],
    width: 'w-[16%]',
  },
  {
    columnId: ACCOUNTS_TRANSACTIONS_COLUMNS.DESCRIPTION,
    title: ACCOUNTS_TRANSACTIONS_COLUMNS_TITLES[ACCOUNTS_TRANSACTIONS_COLUMNS.DESCRIPTION],
    width: 'w-[16%]',
  },
  {
    columnId: ACCOUNTS_TRANSACTIONS_COLUMNS.AMOUNT,
    title: ACCOUNTS_TRANSACTIONS_COLUMNS_TITLES[ACCOUNTS_TRANSACTIONS_COLUMNS.AMOUNT],
    width: 'w-[16%]',
  },
  {
    columnId: ACCOUNTS_TRANSACTIONS_COLUMNS.TYPE,
    title: ACCOUNTS_TRANSACTIONS_COLUMNS_TITLES[ACCOUNTS_TRANSACTIONS_COLUMNS.TYPE],
    width: 'w-[16%]',
  },
  {
    columnId: ACCOUNTS_TRANSACTIONS_COLUMNS.CATEGORY,
    title: ACCOUNTS_TRANSACTIONS_COLUMNS_TITLES[ACCOUNTS_TRANSACTIONS_COLUMNS.CATEGORY],
    width: 'w-[16%]',
  },
  {
    columnId: ACCOUNTS_TRANSACTIONS_COLUMNS.RUNNING_BALANCE,
    title: ACCOUNTS_TRANSACTIONS_COLUMNS_TITLES[ACCOUNTS_TRANSACTIONS_COLUMNS.RUNNING_BALANCE],
    width: 'w-[16%]',
  },
];

export const ACCOUNT_TRANSACTIONS_FILTERS_FIELDS_DEFAULT_VALUES = {
  [ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES.START_DATE]: new Date().toISOString(),
  [ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES.END_DATE]: addDays(new Date(), 1).toISOString(),
};

export const ACCOUNT_TRANSACTIONS_FILTER_FORM_FIELDS: FormItem[] = [
  {
    name: ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES.START_DATE,
    component: DatePickerInput,
    type: FieldTypes.DATE,
    className: 'mr-4',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
    label: 'StartDate',
    dataCy: 'start-date',
  },
  {
    name: ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES.END_DATE,
    component: DatePickerInput,
    type: FieldTypes.DATE,
    className: '',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2 ml-0 dark:text-white',
    label: 'EndDate',
    dataCy: 'end-date',
  },
];
