import { GetContactsInInvoices } from 'interfaces/integrations/xero.interface';
import { TableData, TableRow } from 'shared-components';

type Props = {
  contactsData: GetContactsInInvoices;
};

const ContactsRow = ({ contactsData }: Props) => {
  const { name, firstName, lastName, emailAddress } = contactsData;

  return (
    <>
      <TableRow dataCy="contacts-table-row">
        <TableData dataCy="contacts-reference">{name || '-'}</TableData>
        <TableData dataCy="contacts-contact-name">{firstName && lastName ? `${firstName} ${lastName}` : '-'}</TableData>
        <TableData dataCy="contacts-email-address">{emailAddress || '-'}</TableData>
      </TableRow>
    </>
  );
};

export default ContactsRow;
