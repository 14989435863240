import { ApproveRequestParams, RequestsToBeForgottenResponse } from 'interfaces/gdpr.interface';
import { GetListSharedPayload } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const gdprApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['RequestsToBeForgotten'] }).injectEndpoints({
  endpoints: (build) => ({
    getForgetRequest: build.query<RequestsToBeForgottenResponse, GetListSharedPayload>({
      query: ({ isAdmin, params }) => ({
        url: `Forget${isAdmin ? 'Tenant' : 'User'}Request/GetAll?${params}`,
        method: 'GET',
      }),
      providesTags: ['RequestsToBeForgotten'],
    }),
    approveRequest: build.mutation<void, ApproveRequestParams>({
      query: ({ isAdmin, requestId }) => ({
        url: `Forget${isAdmin ? 'Tenant' : 'User'}Request/Approve?requestId=${requestId}`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['RequestsToBeForgotten']),
    }),
  }),
  overrideExisting: false,
});

export const { useGetForgetRequestQuery, useApproveRequestMutation } = gdprApi;
