import { cn } from '../../utils/global';

interface Props {
  title: string | number;
  description: string;
  className?: string;
  isStatic?: boolean;
}

const ProgressCircleData = ({ title, description, className, isStatic }: Props) => {
  return (
    <div
      data-cy="progress-circle-data"
      className={cn(
        'flex flex-col text-darkGray dark:text-white text-center whitespace-nowrap',
        { 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2': !isStatic },
        className
      )}
    >
      <p>{title}</p>
      <p className="text-xs">{description}</p>
    </div>
  );
};

export default ProgressCircleData;
