interface UserFieldProps {
  label: string;
  children: React.ReactNode;
}
const UserField = ({ label, children }: UserFieldProps) => (
  <div className="mb-5">
    <h3 className="font-medium mb-2 text-xl">{label}</h3>
    <p className="text-lg">{children}</p>
  </div>
);

export default UserField;
