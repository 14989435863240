import { FieldTypes, FormItem, TableColumn } from 'interfaces/shared.interface';
import {
  SUPPORT_REQUESTS_STATUSES,
  SUPPORT_REQUESTS_COLUMNS,
  SUPPORT_REQUESTS_FILTERS_FORM_FIELD_NAMES,
  TENANT_SUPPORT_REQUEST_FORM_FIELDS,
} from 'interfaces/tenant-support-requests.interface';
import { Input, SelectFormInput, TextArea } from 'shared-components';

import { BASE_FILTER_FIELDS } from './common';

export const SUPPORT_REQUESTS_COLUMNS_TITLES = {
  [SUPPORT_REQUESTS_COLUMNS.ISSUE]: 'Issue',
  [SUPPORT_REQUESTS_COLUMNS.STATUS]: 'Status',
  [SUPPORT_REQUESTS_COLUMNS.CREATION_TIME]: 'CreationTime',
  [SUPPORT_REQUESTS_COLUMNS.ACTIONS]: '',
};

export const SUPPORT_REQUESTS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: SUPPORT_REQUESTS_COLUMNS.ISSUE,
    title: SUPPORT_REQUESTS_COLUMNS_TITLES[SUPPORT_REQUESTS_COLUMNS.ISSUE],
    width: 'w-6/12',
  },
  {
    columnId: SUPPORT_REQUESTS_COLUMNS.STATUS,
    title: SUPPORT_REQUESTS_COLUMNS_TITLES[SUPPORT_REQUESTS_COLUMNS.STATUS],
    width: 'w-1/12',
  },

  {
    columnId: SUPPORT_REQUESTS_COLUMNS.CREATION_TIME,
    title: SUPPORT_REQUESTS_COLUMNS_TITLES[SUPPORT_REQUESTS_COLUMNS.CREATION_TIME],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: SUPPORT_REQUESTS_COLUMNS.ACTIONS,
    title: SUPPORT_REQUESTS_COLUMNS_TITLES[SUPPORT_REQUESTS_COLUMNS.ACTIONS],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const TENANT_SUPPORT_REQUEST_FIELDS: FormItem[] = [
  {
    name: TENANT_SUPPORT_REQUEST_FORM_FIELDS.SUBJECT,
    label: 'Subject',
    type: FieldTypes.TEXT,
    required: true,
    inputClassName: 'w-full',
    labelClassName: 'text-sm',
    dataCy: 'support-request-subject-field',
    className: 'mb-4',
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: TENANT_SUPPORT_REQUEST_FORM_FIELDS.DESCRIPTION,
    label: 'Description',
    type: FieldTypes.TEXT,
    required: true,
    numberOfCharacters: 1024,
    labelClassName: 'text-sm',
    dataCy: 'support-request-description-field',
    textAreaClassName: 'w-full h-10 dark:bg-darkBlue4 dark:text-white dark:hover:border-blue2',
    style: { minHeight: '200px' },
    component: TextArea,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
];

const SHARED_FILTERS_OPTIONS = [
  { label: 'Requested', value: '1' },
  { label: 'InProcess', value: '2' },
  { label: 'Resolved', value: '3' },
];

export const SUPPORT_REQUESTS_STATUS_LABELS = {
  [SUPPORT_REQUESTS_STATUSES.REQUESTED]: 'Requested',
  [SUPPORT_REQUESTS_STATUSES.IN_PROGRESS]: 'InProcess',
  [SUPPORT_REQUESTS_STATUSES.RESOLVED]: 'Resolved',
};

export const SUPPORT_REQUESTS_FILTERS_FORM_FIELDS = [
  {
    name: SUPPORT_REQUESTS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS,
    label: 'SelectStatus',
    className: 'ml-10 w-40',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-active-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
];

export const getSupportRequestsFiltersFieldsDefaultValues = (selectStatusLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [SUPPORT_REQUESTS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: { label: selectStatusLabel, value: '' },
});
