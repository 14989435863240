import { DAY_FORMAT_WITH_TIME_WITHOUT_SECONDS } from 'constants/global';
import { GetPayPalOrders } from 'interfaces/integrations/paypal.interface';
import { TableData, TableRow } from 'shared-components';
import TableDataPair from 'shared-components/table/TableDataPair';
import { getFormattedDate } from 'utils/dates';
import { useFormatPriceFunction } from 'utils/global';

type Props = {
  ordersData: GetPayPalOrders;
};

const OrdersRow = ({ ordersData }: Props) => {
  const { payerFirstName, payerLastName, payerEmail, amount, fee, netAmount, dateCreated } = ordersData;

  return (
    <>
      <TableRow dataCy="orders-table-row">
        <TableData dataCy="orders-name">
          {payerFirstName}&nbsp;
          {payerLastName}
        </TableData>
        <TableData dataCy="orders-email">{payerEmail}</TableData>
        <TableData dataCy="orders-amount">
          <TableDataPair labelClassName={'font-bold'} label="GrossAmount">
            {useFormatPriceFunction(amount)}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="Fee">
            {useFormatPriceFunction(fee)}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="NetAmount">
            {useFormatPriceFunction(netAmount)}
          </TableDataPair>
        </TableData>
        <TableData dataCy="orders-date">
          {getFormattedDate(dateCreated, DAY_FORMAT_WITH_TIME_WITHOUT_SECONDS)}
        </TableData>
      </TableRow>
    </>
  );
};

export default OrdersRow;
