export const REGIONAL_STATS_COLUMNS = [
  {
    columnId: 'action',
    title: '#',
  },
  {
    columnId: 'country',
    title: 'Country',
  },
  {
    columnId: 'sales',
    title: 'Sales',
  },
  {
    columnId: 'change',
    title: 'Change ',
  },
  {
    columnId: 'avgPrice',
    title: 'Avg Price',
  },
  {
    columnId: 'total',
    title: 'Total ',
  },
];

export const REGIONAL_STATS_CHART_COLORS = ['#00c5dc', '#f4516c', '#34bfa3', '#ffb822'];
