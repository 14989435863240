import { useTranslation } from 'react-i18next';

import { useGetOsEntityPropertyChangesQuery } from 'apis/os-audit-logs.api';
import { ButtonVariants, LANGUAGE_VARIANTS } from 'constants/common';
import { DATE_LOCALES, DAY_FORMAT_WITH_DAY_NAME, DAY_FORMAT_WITH_DAY_NAME_AND_FORMAT_TIME } from 'constants/global';
import {
  CHANGE_LOGS_DETAILS_TABLE_COLUMNS,
  DETAILS_SHARED_BOX_PROPS,
  DETAILS_SHARED_LABEL_PROPS,
} from 'constants/os-audit-logs';
import { ChangeLog } from 'interfaces/integrations/os-audit-logs.interface';
import { useAppSelector } from 'modules/store';
import { selectLanguagesSettings } from 'modules/user/selector';
import { Box, Button, LabelWithValue, Table } from 'shared-components';
import { Props as LabelWithValueProps } from 'shared-components/LabelWithValue';
import { getFormattedDate } from 'utils/dates';
import { cn } from 'utils/global';

import ChangeLogsBlockInfo from './ChangeLogsBlockInfo';
import ChangeLogsDetailsRow from './ChangeLogsDetailsRow';

type Props = {
  onClose: VoidFunction;
} & ChangeLog;

const renderInfoLabels = (infoLabels: Pick<LabelWithValueProps, 'label' | 'value' | 'valueClassName'>[]) => {
  return infoLabels?.map(({ label, value, valueClassName }) => (
    <LabelWithValue
      key={label}
      displayAlways
      label={label}
      value={value || '-'}
      {...DETAILS_SHARED_LABEL_PROPS}
      valueClassName={cn(valueClassName, DETAILS_SHARED_LABEL_PROPS.valueClassName)}
    />
  ));
};

const ChangeLogsDetailsModalContent = ({
  onClose,
  id,
  userId,
  browserInfo,
  tenantId,
  userEmail,
  clientIpAddress,
  clientName,
  entityTypeFullName,
  entityId,
  extensionData,
  reason,
  changeTime,
  impersonatorUserId,
  impersonatorEmail,
  impersonatorFullName,
}: Props) => {
  const { t } = useTranslation();
  const { data: entityPropData, isLoading } = useGetOsEntityPropertyChangesQuery(id);
  const { currentLanguage } = useAppSelector(selectLanguagesSettings);
  const { result: entityPropChanges } = entityPropData || {};

  const userInformation = [
    { label: 'UserId', value: userId, valueClassName: '' },
    { label: 'TenantId', value: tenantId, valueClassName: '' },
    { label: 'UserEmail', value: userEmail, valueClassName: '' },
    { label: 'ClientIPAddress', value: clientIpAddress, valueClassName: '' },
    { label: 'ClientNameLogs', value: clientName, valueClassName: '' },
    { label: 'BrowserInfo', value: browserInfo, valueClassName: '' },
  ];

  if (impersonatorUserId) {
    userInformation.push(
      { label: 'Note', value: t('AuditLogImpersonatedInfo'), valueClassName: 'text-orange dark:text-orange' },
      { label: 'ImpersonatorUserId', value: impersonatorUserId, valueClassName: '' },
      { label: 'ImpersonatorEmail', value: impersonatorEmail, valueClassName: '' },
      { label: 'ImpersonatorFullName', value: impersonatorFullName, valueClassName: '' }
    );
  }

  const entityInformation = [
    { label: 'EntityTypeFullName', value: entityTypeFullName },
    {
      label: 'EntityId',
      value: entityId,
    },
  ];

  const commonInfo = [
    { label: 'ExtensionData', value: extensionData },
    { label: 'Reason', value: ` ${reason}` },
    {
      label: 'Time',
      value: getFormattedDate(
        changeTime,
        currentLanguage?.name == LANGUAGE_VARIANTS.UA
          ? DAY_FORMAT_WITH_DAY_NAME
          : DAY_FORMAT_WITH_DAY_NAME_AND_FORMAT_TIME,
        DATE_LOCALES[currentLanguage?.name as string]
      ),
    },
  ];

  return (
    <div className="border-t mt-3.5 pt-3.5 border-lightGray11 dark:border-white">
      <Box title="UserInformation" {...DETAILS_SHARED_BOX_PROPS}>
        {renderInfoLabels(userInformation)}
      </Box>

      <ChangeLogsBlockInfo
        infoData={entityInformation}
        className="border-b-0 dark:border-none dark:bg-darkBlue4 dark:text-darkGray2"
      />

      <Table columns={CHANGE_LOGS_DETAILS_TABLE_COLUMNS} isLoading={isLoading}>
        {entityPropChanges?.map((entityPropChange) => (
          <ChangeLogsDetailsRow key={entityPropChange.id} {...entityPropChange} />
        ))}
      </Table>

      <ChangeLogsBlockInfo infoData={commonInfo} className="border-t-0 dark:border-none dark:bg-darkBlue4" />

      <hr className="my-3.5 border-lightGray11" />

      <Button onClick={onClose} dataCy="modal-close-button" variant={ButtonVariants.BORDERED}>
        {t('Close')}
      </Button>
    </div>
  );
};

export default ChangeLogsDetailsModalContent;
