import React, { forwardRef, TextareaHTMLAttributes, useRef, useEffect, ChangeEvent, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

import { ErrorMessage } from './index';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  textAreaClassName?: string;
  labelClassName?: string;
  anchorPrefix?: string;
  error?: string;
  isAbsoluteError?: boolean;
  placeholder?: string;
  numberOfCharacters?: number;
  value?: string;
  dataCy?: string;
  required?: boolean;
  inputClassName?: string;
}

const TEXT_AREA_HEIGHT_OFFSET = 1;

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      labelClassName,
      textAreaClassName,
      className,
      error,
      isAbsoluteError,
      onChange,
      placeholder,
      numberOfCharacters,
      value,
      dataCy,
      required,
      inputClassName,
      ...textAreaProps
    },
    ref
  ) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null);
    const [isResizedManually, setIsResizedManually] = useState(false);

    const { watch } = useFormContext();
    const { t } = useTranslation();
    const watchValue = watch(textAreaProps.name as string);

    const setRef = (node: HTMLTextAreaElement) => {
      if (!node) {
        return;
      }

      if (typeof ref === 'function') {
        ref(node);
      } else {
        (ref as React.MutableRefObject<HTMLTextAreaElement | null>).current = node;
      }

      localRef.current = node;
    };

    const handleManualResize = () => {
      setIsResizedManually(true);
    };

    const handleChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(ev);
      }

      if (localRef.current && !isResizedManually) {
        localRef.current.style.height = 'auto';
        localRef.current.style.height = `${ev.target.scrollHeight + TEXT_AREA_HEIGHT_OFFSET}px`;
      }
    };

    useEffect(() => {
      const currentElement = localRef.current;
      if (currentElement) {
        currentElement.addEventListener('mouseup', handleManualResize);
      }
      return () => {
        if (currentElement) {
          currentElement.removeEventListener('mouseup', handleManualResize);
        }
      };
    }, []);

    return (
      <div className={className} data-cy={dataCy}>
        {label && (
          <div className={cn('text-darkBlue mb-1 dark:text-gray', labelClassName)}>
            {t(label)}
            {label && required && ' *'}
          </div>
        )}

        <div className="relative">
          <textarea
            value={value}
            ref={setRef}
            onChange={handleChange}
            placeholder={placeholder}
            maxLength={numberOfCharacters}
            className={cn(
              'custom-textarea text-sm dark:text-white rounded',
              {
                '!border-error focus:ring rounded border-cherry': !!error,
              },
              textAreaClassName,
              inputClassName
            )}
            {...textAreaProps}
          />
        </div>

        {numberOfCharacters ? (
          <div className="w-fit rounded-2xl bg-gray6 px-2 text-darkGray text-sm dark:bg-darkGray3 dark:text-white">
            {watchValue?.length || 0} / {numberOfCharacters}
          </div>
        ) : null}

        {error && (
          <ErrorMessage
            dataCy="error-message"
            className={cn('input-error-message', {
              absolute: isAbsoluteError,
            })}
          >
            {t(error)}
          </ErrorMessage>
        )}
      </div>
    );
  }
);

export default TextArea;

TextArea.displayName = 'TextArea';
