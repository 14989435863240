import { Currency } from 'constants/global';
import { CommonResponse } from 'interfaces/shared.interface';

export interface GetBankAuthUrlResponse extends CommonResponse {
  result?: string;
}

export interface GetReconnectionInformation {
  isReconnectionRequired: boolean;
  reconnectionUrl: string | null;
}

export interface GetConnectionDetailsResponse extends CommonResponse {
  result: {
    expiresInDays: number | null;
    isConnected: boolean;
    isConnectionExpired: boolean | null;
  };
}

export interface GetAccountsResponse extends CommonResponse {
  result: GetAccounts;
}

export interface GetAccounts extends GetReconnectionInformation {
  data: {
    accounts: AccountsData[];
  };
}

export interface GetInformationResponse extends CommonResponse {
  result: GetInformation;
}

export interface GetInformation extends GetReconnectionInformation {
  data: AccountsData;
}

export interface AccountsData {
  bsb: string;
  currency: Currency;
  iban: string;
  id: string;
  name: string;
  number: string;
  routingNumber: string;
  sortCode: string;
  swiftBic: string;
  type: string;
}

export enum ACCOUNTS_COLUMNS {
  NAME = 'name',
  TYPE = 'type',
  CURRENCY = 'currency',
  ACTION = 'action',
}

export enum CARDS_COLUMNS {
  NAME = 'name',
  CARD_NUMBER = 'cardNumber',
  TYPE = 'type',
  NETWORK = 'network',
  CURRENCY = 'currency',
  ACTION = 'action',
}
export enum ACCOUNTS_TRANSACTIONS_COLUMNS {
  DATE = 'date',
  DESCRIPTION = 'description',
  AMOUNT = 'amount',
  TYPE = 'type',
  CATEGORY = 'category',
  RUNNING_BALANCE = 'runningBalance',
}
export interface GetCardsResponse extends CommonResponse {
  result: GetCards;
}

export interface GetCards extends GetReconnectionInformation {
  data: {
    cards: CardsData[];
  };
}

export interface GetCardInformationResponse extends CommonResponse {
  result: { data: CardsData };
}
export interface CardsData {
  currency: Currency;
  id: string;
  last4Digits: string;
  name: string;
  nameOnCard: string;
  network: string;
  type: string;
  validFrom: string;
  validTo: string;
}

export interface GetBalanceResponse extends CommonResponse {
  result: GetBalance;
}

export interface GetBalance extends GetReconnectionInformation {
  data: BalanceData;
}
export interface BalanceData {
  available: number;
  currency: Currency;
  current: number;
  overdraft: number;
  creditLimit: number;
}

export interface GetTransactionsResponse extends CommonResponse {
  result: GetTransactions;
}

export interface GetTransactions extends GetReconnectionInformation {
  data: {
    transactions: TransactionsData[];
  };
}
export interface TransactionsData {
  amount: number;
  category: string;
  classification: string;
  currency: Currency;
  date: string;
  description: string;
  id: string;
  merchantName: string;
  runningBalanceAmount: number;
  runningBalanceCurrency: string;
  type: string;
}

export interface AccountTransactionsPayload {
  accountId?: string;
  params?: string;
}

export enum ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES {
  START_DATE = 'from',
  END_DATE = 'to',
}

export interface AccountTransactionsFilterFormValues {
  [ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES.START_DATE]: string;
  [ACCOUNT_TRANSACTIONS_FILTERS_FORM_FIELD_NAMES.END_DATE]: string;
}
