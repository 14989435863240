import { RESET_PASSWORD_FROM_FIELDS } from 'interfaces/reset-password.interface';
import { FieldTypes, FormItem } from 'interfaces/shared.interface';
import { Input } from 'shared-components';

export const RESET_PASSWORD_FORM_INPUTS: FormItem[] = [
  {
    name: RESET_PASSWORD_FROM_FIELDS.newPassword,
    label: 'Password',
    component: Input,
    className: 'mb-4 mt-10',
    type: FieldTypes.PASSWORD,
    inputClassName: 'w-full',
  },
  {
    name: RESET_PASSWORD_FROM_FIELDS.confirmPassword,
    label: 'Repeat password',
    component: Input,
    className: 'mb-4 mt-4',
    type: FieldTypes.PASSWORD,
    inputClassName: 'w-full',
  },
];

export const RESET_PASSWORD_PAGE_BACK_BUTTON = 'Back';

export const RESET_PASSWORD_PAGE_SUBMIT_BUTTON = 'Submit';

export const RESET_PASSWORD_PAGE_TITLE = 'ResetPassword';

export const RESET_PASSWORD_SUCCESS_MESSAGE = 'NewPasswordApplied';

export const RESET_PASSWORD_DESCRIPTION_MESSAGE = 'NowYouCanLoginToTheAppUsingTheNewPassword';
