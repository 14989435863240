import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRegistrationMutation } from 'apis/auth.api';
import { TENANT_FORM_FIELDS } from 'constants/create-tenant';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { BASE_TENANT_FORM_FIELDS, CreateTenantFormValues, CreateTenantPayload } from 'interfaces/tenants.interface';
import TenantInputsCheckboxes from 'page-components/tenants/TenantInputsCheckboxes';
import { ErrorModal, FormContent, Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

interface Props {
  handleClose: VoidFunction;
  refetchListTenants: VoidFunction;
  isOpen: boolean;
}
const CreateTenantModal = ({ handleClose, isOpen, refetchListTenants }: Props) => {
  const [createOrUpdateTenant, { isLoading: isRegistrationLoading, error }] = useRegistrationMutation();

  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const createTenantFormMethods = useForm<CreateTenantFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const {
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors },
  } = createTenantFormMethods;

  const { data: errorData } = (error as CommonError) || {};

  const watchSetRandomPassword = watch(BASE_TENANT_FORM_FIELDS.SET_RANDOM_PASSWORD);
  const watchPassword = watch(BASE_TENANT_FORM_FIELDS.ADMIN_PASSWORD);

  const isDisabledSubmitButton = watchSetRandomPassword ? !watchSetRandomPassword : !watchPassword?.length;

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmit = async (data: CreateTenantFormValues) => {
    if (isDisabledSubmitButton) {
      return;
    }

    const payload: CreateTenantPayload = {
      adminEmailAddress: data[BASE_TENANT_FORM_FIELDS.ADMIN_EMAIL_ADDRESS],
      adminFirstName: data[BASE_TENANT_FORM_FIELDS.ADMIN_FIRST_NAME],
      adminLastName: data[BASE_TENANT_FORM_FIELDS.ADMIN_LAST_NAME],
      isRegisteredByAnotherPerson: true,
      isAdminAgreeToTermsAndConditions: true,
      tenantName: data[BASE_TENANT_FORM_FIELDS.TENANT_NAME],
    };
    if (!data[BASE_TENANT_FORM_FIELDS.SET_RANDOM_PASSWORD]) {
      payload.adminPassword = data[BASE_TENANT_FORM_FIELDS.ADMIN_PASSWORD];
    }
    try {
      await createOrUpdateTenant(payload).unwrap().then(refetchListTenants);
      onCloseModal();
      successNotify(t('SuccessfullyCreated'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <Modal dataCy="create-tenant-modal" isOpen={isOpen} onClose={onCloseModal} heading="CreateNewTenant">
        <FormProvider {...createTenantFormMethods}>
          <form data-cy="manage-tenant-modal-form" onSubmit={handleSubmit(onSubmit)}>
            <FormContent fields={TENANT_FORM_FIELDS} register={register} errors={errors} />
            <TenantInputsCheckboxes register={register} watch={watch} />
            <ModalControlButtons
              isShownSubmit
              saveButtonText="Create"
              closeButtonText="Back"
              isLoading={isRegistrationLoading}
              onClose={onCloseModal}
              disabled={isDisabledSubmitButton}
            />
          </form>
        </FormProvider>
      </Modal>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default CreateTenantModal;
