import { useCallback, useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { usePostSendDocumentSignRequestMutation } from 'apis/docusign.api';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-3.svg';
import { ButtonVariants } from 'constants/common';
import { DOCUSIGN_REQUEST_FORM_FIELD } from 'constants/docusign';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import {
  BaseCreateRequestFormValue,
  DOCUSIGN_REQUEST_FORM_FIELDS,
  DocusignRequestPayload,
  DroppedItem,
  SignerBlock,
} from 'interfaces/integrations/docusign.interface';
import { CommonError } from 'interfaces/shared.interface';
import SignersBlock from 'page-components/integrations/docusign/SignersBlock';
import {
  Box,
  Button,
  ErrorModal,
  FormContent,
  LinkButton,
  LoadingOverlay,
  PDFViewer,
  WarningModal,
} from 'shared-components';
import { base64ToBlob } from 'utils/files';
import { successNotify } from 'utils/notifications';

const DocusignDocumentEditorPage = () => {
  const location = useLocation();
  const { documentName, emailSubject, emailMessage, fileData } = location.state;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [droppedItems, setDroppedItems] = useState<DroppedItem[]>([]);
  const [signerBlocks, setSignerBlocks] = useState<SignerBlock[]>([]);
  const [isSigningOrderActive, setIsSigningOrderActive] = useState<boolean>(false);
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [
    postSendDocumentSignRequest,
    { error: postSendDocumentSignRequestError, isLoading: isPostSendDocumentSignRequestLoading },
  ] = usePostSendDocumentSignRequestMutation();
  const errorData = (postSendDocumentSignRequestError as CommonError)?.data || {};
  const { ...createDocumentTypeMethods } = useForm<BaseCreateRequestFormValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      [DOCUSIGN_REQUEST_FORM_FIELDS.DOCUMENT_NAME]: documentName,
      [DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_SUBJECT]: emailSubject,
      [DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_MESSAGE]: emailMessage,
    },
  });

  const {
    register,
    formState: { errors },
    watch,
  } = createDocumentTypeMethods;

  const watchDocumentName = watch(DOCUSIGN_REQUEST_FORM_FIELDS.DOCUMENT_NAME);
  const watchEmailSubject = watch(DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_SUBJECT);
  const watchEmailMessage = watch(DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_MESSAGE);

  const isAllSignersValid = useCallback(() => {
    return signerBlocks.every((block) => block.fullName.trim() !== '' && block.email.trim() !== '');
  }, [signerBlocks]);

  const isSendButtonDisabled =
    !watchDocumentName || !watchEmailSubject || signerBlocks.length === 0 || !isAllSignersValid();

  const updateDroppedItems = useCallback(
    (newItemsOrUpdater: DroppedItem[] | ((prevItems: DroppedItem[]) => DroppedItem[])) => {
      setDroppedItems((prev) => {
        const newItems = typeof newItemsOrUpdater === 'function' ? newItemsOrUpdater(prev) : newItemsOrUpdater;

        if (isEqual(newItems, prev)) {
          return prev;
        }

        return newItems;
      });
    },
    []
  );

  const handleSubmit = async () => {
    if (isSendButtonDisabled) {
      return;
    }

    const allSignersHaveSignature = signerBlocks.every((block) => {
      return droppedItems.some((item) => item.blockId === block.id && item.placementType === 1);
    });

    if (!allSignersHaveSignature) {
      handleOpenWarningModal();
      return;
    }

    const payload: DocusignRequestPayload = {
      documentDetails: {
        base64Content: fileData.fileBase64.replace('data:application/pdf;base64,', ''),
        fileType: 'application/pdf',
        name: watchDocumentName,
      },
      emailNotificationSubject: watchEmailSubject,
      emailNotificationMessage: watchEmailMessage,
      sendInOrder: isSigningOrderActive,
      signers: signerBlocks.map((block, index) => {
        const blockPlacements = droppedItems
          .filter((item) => item.blockId === block.id)
          .map((item) => ({
            placementType: item.placementType,
            pageNumber: item.pageNumber,
            signXPosition: Math.round(item.x / 1.35),
            signYPosition: Math.round(item.y / 1.35),
          }));

        return {
          fullName: block.fullName,
          email: block.email,
          orderingNumber: index + 1,
          placements: blockPlacements,
        };
      }),
    };

    try {
      await postSendDocumentSignRequest(payload).unwrap();
      navigate(ROUTES.docusign);
      successNotify(t('SuccessfullySentSigningRequest'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    const storedFileData = localStorage.getItem('fileData');
    if (storedFileData) {
      const fileBlob = base64ToBlob(storedFileData, 'application/pdf');
      const fileUrl = URL.createObjectURL(fileBlob);
      setFileUrl(fileUrl);
    }
  }, []);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        description={t('NotAllSignatuesPlaced')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleCloseWarningModal}
        descriptionClassName="text-2xl"
        isCancelButton={false}
        approveButtonText="Ok"
      />
      <DndProvider backend={HTML5Backend}>
        <LoadingOverlay isLoading={isPostSendDocumentSignRequestLoading} isFixed />
        <div data-cy="document-details-page" className="flex h-[85vh] overflow-hidden">
          <Box data-cy="invoice-data" className="p-4 w-[28rem] min-w-[28rem] h-auto max-h-[auto] overflow-auto">
            <div className="items-center mb-5">
              <LinkButton className="text-1xs mb-5" variant={ButtonVariants.LINK} to={ROUTES.docusign}>
                <ArrowLeft className="fill-blue5 mr-1.5" />
                {t('BackToSigningRequestList')}
              </LinkButton>
              <FormProvider {...createDocumentTypeMethods}>
                <form data-cy="create-request-modal-form">
                  <FormContent fields={DOCUSIGN_REQUEST_FORM_FIELD()} register={register} errors={errors} />
                </form>
                <hr className="h-[0.0625rem] opacity-50 bg-gray14 dark:bg-white border-none mb-2" />
                <SignersBlock
                  signerBlocks={signerBlocks}
                  setSignerBlocks={setSignerBlocks}
                  droppedItems={droppedItems}
                  setDroppedItems={setDroppedItems}
                  isSigningOrderActive={isSigningOrderActive}
                  setIsSigningOrderActive={setIsSigningOrderActive}
                />
                <Button
                  dataCy="save-button"
                  className="w-full mt-10"
                  onClick={handleSubmit}
                  disabled={isSendButtonDisabled}
                >
                  {t('Send')}
                </Button>
              </FormProvider>
            </div>
          </Box>
          <div className="flex-grow p-2 flex justify-center overflow-hidden" id={'.react-pdf__Page'}>
            {fileUrl && (
              <PDFViewer
                fileUrl={fileUrl}
                className="h-full z-10 min-w-[50rem]"
                signerBlocks={signerBlocks}
                droppedItems={droppedItems}
                setDroppedItems={updateDroppedItems}
              />
            )}
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default DocusignDocumentEditorPage;
