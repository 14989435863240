import { useGetAccountsQuery } from 'apis/truelayer.api';
import { ACCOUNTS_TABLE_COLUMNS } from 'constants/truelayer';
import { LoadingOverlay, Table, TotalCount } from 'shared-components';

import AccountsRow from './AccountsRow';

const Accounts = () => {
  const { data, isLoading, isFetching } = useGetAccountsQuery();

  const response = data?.result.data.accounts;

  return (
    <div data-cy="accounts-tab" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />
      <Table
        dataCy="accounts-table"
        columns={ACCOUNTS_TABLE_COLUMNS}
        isTableEmpty={!response?.length}
        isLoading={isLoading}
      >
        {response?.map((item) => <AccountsRow key={item.id} accountsData={item} />)}
      </Table>

      <TotalCount totalCount={response?.length} className="p-2" />
    </div>
  );
};

export default Accounts;
