import {
  FilterAutoCompleteItemsRequest,
  FilterAutoCompleteItemsResult,
  GetGoogleAutocompletePayload,
} from 'interfaces/sample.interface';
import { DragAndDropResponse, GetGoogleAutocompleteResponse } from 'interfaces/samples.interface';

import { coreSplitApis } from './core.api';

export const samples = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    postFilterAutoCompleteItems: build.mutation<FilterAutoCompleteItemsResult, FilterAutoCompleteItemsRequest>({
      query: (data) => ({
        url: `Sample/FilterAutoCompleteItems`,
        method: 'POST',
        data,
      }),
    }),
    getDragAndDrop: build.query<DragAndDropResponse, void>({
      query: () => ({
        url: 'Sample/GetDragAndDrop',
        method: 'GET',
      }),
    }),

    getGooglePlacePredictions: build.query<GetGoogleAutocompleteResponse, GetGoogleAutocompletePayload>({
      query: ({ SearchWord }) => ({
        url: `Sample/GetGooglePlacePredictions`,
        method: 'GET',
        params: { SearchWord },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { usePostFilterAutoCompleteItemsMutation, useGetDragAndDropQuery, useLazyGetGooglePlacePredictionsQuery } =
  samples;
