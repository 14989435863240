import { ReactNode } from 'react';

import useTheme from 'hooks/useTheme';

interface Props {
  children: ReactNode;
}

const Theme = ({ children }: Props) => {
  const { themeName } = useTheme();

  if (typeof window !== 'undefined') {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');
    root.classList.add(themeName);
  }

  return <>{children}</>;
};

export default Theme;
