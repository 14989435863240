import { useState } from 'react';

import { SelectedUnitInfo } from 'interfaces/organization-units.interface';
import { OrganizationTreeBlock, OrganizationUnitsTableBlock } from 'page-components/organization-units';

type SelectedUnitState = SelectedUnitInfo | null;

const OrganizationUnits = () => {
  const [selectedUnit, setSelectedUnit] = useState<SelectedUnitState>(null);

  return (
    <div data-cy="organization-units-page" className="lg:flex">
      <OrganizationTreeBlock
        dataCy="organization-tree-block"
        className="lg:w-1/3"
        setSelectedUnit={setSelectedUnit}
        selectedUnit={selectedUnit}
      />

      <OrganizationUnitsTableBlock
        dataCy="organization-units-table-block"
        className="lg:w-2/3 dark:border-t-1 dark:border-gray12 dark:lg:border-t-0 dark:lg:border-l-1"
        selectedUnit={selectedUnit}
      />
    </div>
  );
};

export default OrganizationUnits;
