import {
  DocusignDownloadDocument,
  DocusignRequestPayload,
  GetAuthUrlResponse,
  GetSigningRequestsResponse,
  PostConnectedResponse,
} from 'interfaces/integrations/docusign.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const docusignApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['Docusign'] }).injectEndpoints({
  endpoints: (build) => ({
    getAuthorizationUrl: build.query<GetAuthUrlResponse, void>({
      query: () => ({
        url: `Docusign/GetAuthorizationUrl`,
        method: 'GET',
      }),
    }),
    postIsDocusignConnected: build.mutation<PostConnectedResponse, void>({
      query: () => ({
        url: `Docusign/IsDocusignConnected`,
        method: 'POST',
      }),
    }),
    postProcessAuthentificationToken: build.mutation<CommonResponse, { code?: string }>({
      query: ({ code }) => ({
        url: `Docusign/ProcessAuthenticationToken?code=${code}`,
        method: 'POST',
        data: { code },
      }),
    }),
    getSigningRequests: build.query<GetSigningRequestsResponse, string>({
      query: (params) => ({
        url: `Docusign/GetSigningRequests?${params}`,
        method: 'GET',
      }),
      providesTags: ['Docusign'],
    }),
    postDisconnectDocusignAccount: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `Docusign/Disconnect`,
        method: 'POST',
      }),
    }),
    postSendDocumentSignRequest: build.mutation<CommonResponse, DocusignRequestPayload>({
      query: (data) => ({
        url: `Docusign/SendDocumentSignRequest`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Docusign']),
    }),
    postDownloadDocument: build.mutation<GetAuthUrlResponse, DocusignDownloadDocument>({
      query: (data) => ({
        url: `Docusign/DownloadDocument`,
        method: 'POST',
        data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAuthorizationUrlQuery,
  useGetAuthorizationUrlQuery,
  usePostIsDocusignConnectedMutation,
  usePostProcessAuthentificationTokenMutation,
  useGetSigningRequestsQuery,
  usePostDisconnectDocusignAccountMutation,
  usePostSendDocumentSignRequestMutation,
  usePostDownloadDocumentMutation,
} = docusignApi;
