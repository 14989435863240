import { useNavigate } from 'react-router-dom';

import { CardsData } from 'interfaces/integrations/truelayer.interface';
import { DropdownMenu, TableData, TableRow } from 'shared-components';
import { getTrueLayerCardDetails } from 'utils/url';

type Props = {
  cardsData: CardsData;
};

const CardsRow = ({ cardsData }: Props) => {
  const navigate = useNavigate();

  const { name, last4Digits, type, network, currency, id } = cardsData;

  const menuActions = [
    {
      dataCy: 'menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        navigate(getTrueLayerCardDetails(id));
      },
    },
  ];

  return (
    <>
      <TableRow dataCy="cards-table-row">
        <TableData dataCy="cards-name">{name}</TableData>
        <TableData dataCy="cards-last-four-digits">*{last4Digits}</TableData>
        <TableData dataCy="cards-type">{type}</TableData>
        <TableData dataCy="cards-network">{network}</TableData>
        <TableData dataCy="cards-currency">{currency}</TableData>
        <TableData>
          <DropdownMenu dataCy="cards-dropdown-menu" elements={menuActions} />
        </TableData>
      </TableRow>
    </>
  );
};

export default CardsRow;
