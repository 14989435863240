import { Layout } from 'react-grid-layout';

import { DashboardWidget } from 'interfaces/dashboard.interface';

export const convertGridToDashboardLayout = ({ i, h, w, x, y }: Layout) => ({
  widgetId: i,
  height: h,
  width: w,
  positionX: x,
  positionY: y,
});

export const convertDashboardToGridLayout = ({ widgetId, width, height, positionX, positionY }: DashboardWidget) => ({
  i: widgetId,
  h: height,
  w: width,
  x: positionX,
  y: positionY,
});

export const getNumberOfCols = (widgets?: DashboardWidget[], defaultCols = 12) => {
  if (!widgets?.length) {
    return defaultCols;
  }

  const widgetsOutsideDefaultCols = widgets.filter(
    (widget) => widget.positionX > defaultCols || widget.width > defaultCols
  );

  if (widgetsOutsideDefaultCols.length > 0) {
    const maxPositionXOutside = Math.max(...widgetsOutsideDefaultCols.map((widget) => widget.positionX));
    const maxWidthOutside = Math.max(...widgetsOutsideDefaultCols.map((widget) => widget.width));

    return maxPositionXOutside + maxWidthOutside;
  }

  return defaultCols;
};

export const getDashboardName = (isAdmin: boolean) => (isAdmin ? 'HostDashboard' : 'TenantDashboard');

export const convertWidgetIdToTranslationKey = (widgetId?: string | null) =>
  widgetId
    ?.replace(/^Widgets/, 'Widget')
    .replace(/_[^_]+_/, '')
    .replace(/_/g, '') ?? '';
