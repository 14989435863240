import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ContactData } from 'interfaces/integrations/sage.interface';
import { DropdownMenu, TableData, TableRow } from 'shared-components';
import { getSageContactDetails } from 'utils/url';

interface Props {
  ContactData: ContactData;
}

const ContactsRow = ({ ContactData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id, name, reference, contactTypes, isActive } = ContactData;

  const menuItemsAction = [
    {
      dataCy: 'contacts-menu-item-details-button',
      name: t('Details'),
      actionOnClick: () => {
        navigate(getSageContactDetails(id));
      },
    },
  ];

  return (
    <>
      <TableRow dataCy="contacts-table-row">
        <TableData dataCy="contacts-name">{name}</TableData>
        <TableData dataCy="contacts-reference">{reference}</TableData>
        <TableData dataCy="contacts-contact-type">{contactTypes.map((item) => item.name)}</TableData>
        <TableData dataCy="contacts-is-active">{t(isActive ? 'Active' : 'NotActive')}</TableData>
        <TableData>
          <DropdownMenu dataCy="contacts-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default ContactsRow;
