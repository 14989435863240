export const EXPIRING_TENANTS_COLUMNS = [
  {
    columnId: 'tenantName',
    title: 'TenantName',
  },
  {
    columnId: 'remainingDay',
    title: 'RemainingDay',
  },
];
