import { useEffect, useState } from 'react';

export const useDashboardInteraction = () => {
  const [isInteractable, setIsInteractable] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsInteractable(window.innerWidth > 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isInteractable;
};
