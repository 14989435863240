import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetContactTypesQuery, useGetSageContactsQuery } from 'apis/sage';
import {
  getContactsFiltersFieldsDefaultValue,
  getContactsFilterFormFields,
  CONTACTS_TABLE_COLUMNS,
  ContactsFilterFormValues,
  SEARCH_CONTACTS_PLACEHOLDER,
} from 'constants/sage';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import ContactsRow from 'page-components/integrations/sage/contacts/ContactsRow';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

const Contacts = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { customPaginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { filterParams, applyFilters, isSameFilters } = useFilters();

  const {
    data: contactsData,
    isLoading: isContactsLoading,
    isFetching,
    refetch,
    error: contactsError,
  } = useGetSageContactsQuery(`${filterParams}${sortingParams}${customPaginationParams}`);

  const { data: contactTypes, isLoading: isContactTypesLoading, error: contactTypesError } = useGetContactTypesQuery();

  const isLoading = isContactsLoading || isContactTypesLoading;

  const { result } = contactsData || {};

  const { error: contactTypesErrorData } = (contactTypesError as CommonError)?.data || {};
  const { error: contactsErrorData } = (contactsError as CommonError)?.data || {};

  const errorData = contactTypesErrorData || contactsErrorData;

  const contactsFiltersMethods = useForm<ContactsFilterFormValues>({
    defaultValues: getContactsFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectType')),
  });

  const handleClearFields = () => {
    contactsFiltersMethods.reset(getContactsFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectType')));
    onSubmitFilters(getContactsFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectType')));
  };

  const onSubmitFilters = async (data: ContactsFilterFormValues) => {
    const { filter, status, type } = data;

    const newFiltersToApply = {
      searchQuery: filter,
      ShowOnlyActive: status.value !== '' ? (status.value as boolean).toString() : null,
      ContactTypeId: type.value?.toString(),
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
  }, [errorData, handleOpenErrorModal]);

  useEffect(() => {
    contactsFiltersMethods.reset(getContactsFiltersFieldsDefaultValue(t('SelectStatus'), t('SelectType')));
  }, [t, contactsFiltersMethods]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="sage-filtering">
        <FormProvider {...contactsFiltersMethods}>
          <form onSubmit={contactsFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="sage-page-search"
              placeHolder={SEARCH_CONTACTS_PLACEHOLDER}
              handleClearFields={handleClearFields}
            >
              <FilterFormContentWithoutPermissions
                className="flex"
                fields={getContactsFilterFormFields(contactTypes?.result.data)}
              />
            </FilterForm>
          </form>
        </FormProvider>
      </div>
      <div data-cy="contacts-tab" className="relative">
        <LoadingOverlay isLoading={isFetching} />
        <Table
          dataCy="contacts-table"
          columns={CONTACTS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!contactsData?.result.data.totalCount}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {result?.data.contacts.map((item) => <ContactsRow key={item.id} ContactData={item} />)}
        </Table>
        <Pagination
          totalCount={contactsData?.result.data.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default Contacts;
