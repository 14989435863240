import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetForgetRequestQuery } from 'apis/gdpr.api';
import {
  GDPR_FILTERS_FORM_FIELDS,
  getGDPRFiltersFieldsDefaultValues,
  FILTER_PLACEHOLDER,
  TABLE_COLUMNS,
} from 'constants/gdpr';
import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import useIsAdmin from 'hooks/useUserRole';
import { GDPRFilterFormValues } from 'interfaces/gdpr.interface';
import GDPRRow from 'page-components/gdpr/GDPRRow';
import { FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';
import FilterFormContentWithoutPermissions from 'shared-components/filter/FilterFormContentWithoutPermissions';

const GDPR = () => {
  const { t } = useTranslation();

  const { applyFilters, filterParams, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { userRole, isAdmin } = useIsAdmin();
  const { data, isLoading, isFetching, refetch } = useGetForgetRequestQuery({
    params: `${filterParams}${sortingParams}${paginationParams}`,
    isAdmin,
  });

  const { result } = data || {};

  const GDPRFiltersMethods = useForm<GDPRFilterFormValues>({
    defaultValues: getGDPRFiltersFieldsDefaultValues(t('SelectStatus')),
  });

  const onSubmitFilters = (data: GDPRFilterFormValues) => {
    const { statusId, filter } = data;

    const newFiltersToApply = {
      searchQuery: filter,
      statusId: statusId.value !== '' ? (statusId.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    GDPRFiltersMethods.reset(getGDPRFiltersFieldsDefaultValues(t('SelectStatus')));
    onSubmitFilters(getGDPRFiltersFieldsDefaultValues(t('SelectStatus')));
  };

  useEffect(() => {
    GDPRFiltersMethods.reset(getGDPRFiltersFieldsDefaultValues(t('SelectStatus')));
  }, [t, GDPRFiltersMethods]);

  return (
    <div data-cy="gdpr-page">
      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <FormProvider {...GDPRFiltersMethods}>
          <form onSubmit={GDPRFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="gdpr-page-search"
              placeHolder={FILTER_PLACEHOLDER[userRole]}
              handleClearFields={handleClearFields}
            >
              <FilterFormContentWithoutPermissions fields={GDPR_FILTERS_FORM_FIELDS} />
            </FilterForm>
          </form>
        </FormProvider>
        <Table
          dataCy="gdpr-table"
          columns={TABLE_COLUMNS[userRole]}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!result?.requests.length}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {result?.requests.map((tenant) => (
            <GDPRRow
              key={tenant.id}
              gdprData={tenant}
              userRole={userRole}
              isAdmin={isAdmin}
              refetchSubscriptions={refetch}
            />
          ))}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default GDPR;
