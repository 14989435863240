import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { CompaniesHouseDetailsResponse } from 'interfaces/integrations/companies-house.interface';
import CompaniesHouseSearch from 'page-components/integrations/companies-house/CompaniesHouseSearch';
import CompaniesHouseTabs from 'page-components/integrations/companies-house/CompaniesHouseTabs';
import { Box, LoadingOverlay } from 'shared-components';

const CompaniesHouse = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [companyDetailsResponse, setCompanyDetailsResponse] = useState<CompaniesHouseDetailsResponse | null>(null);

  const { t } = useTranslation();

  return (
    <div data-cy="companies-house-page">
      <CompaniesHouseSearch
        setIsLoading={setIsFetching}
        setIsSelected={setIsSelected}
        setCompanyDetailsResponse={setCompanyDetailsResponse}
      />
      {isSelected && (
        <div className="relative">
          <LoadingOverlay isLoading={isFetching} />
          <Box>
            <div className="dark:bg-darkBlue p-4 ">
              <h2 className="text-3xl font-bold">{companyDetailsResponse?.result?.companyName}</h2>
              <h3 className="text-xl">{t('CompanyNumber', { 0: companyDetailsResponse?.result?.companyNumber })}</h3>
              <CompaniesHouseTabs companyDetailsResponse={companyDetailsResponse} />
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default CompaniesHouse;
