import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { TabItem } from 'interfaces/shared.interface';
import { cn } from 'utils/global';

interface Props<T> {
  tabs: TabItem[];
  componentProps?: T;
  onActiveTabChange?: (index: number, id?: string) => void;
  dynamicTabIndex?: number;
  hideOnSingleTab?: boolean;
}

const Tabs = <T,>({ tabs, componentProps, onActiveTabChange, dynamicTabIndex, hideOnSingleTab }: Props<T>) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { t } = useTranslation();

  const handleSelectTab = (tabIndex: number, id?: string) => {
    onActiveTabChange?.(tabIndex, id);
    setActiveTabIndex(tabIndex);
  };

  const TabContentComponent = tabs[activeTabIndex]?.component;

  useEffect(() => {
    if (dynamicTabIndex !== undefined && activeTabIndex !== dynamicTabIndex) {
      setActiveTabIndex(dynamicTabIndex);
    }
  }, [dynamicTabIndex]);

  return (
    <div>
      <div className="w-full mb-5 flex relative overflow-x-auto after:content-[''] after:absolute after:w-full after:h-px after:bg-darkGray3 after:-bottom-0 after:left-0">
        <div className="flex-grow flex">
          {!(hideOnSingleTab && tabs.length === 1) &&
            tabs.map(({ id, title, dataCy }, index) => (
              <div
                className={cn('p-3.5 h-full leading-[14px] cursor-pointer text-sm relative z-10 hover:text-blue2', {
                  'border-b border-darkBlue7 text-blue2': index === activeTabIndex,
                  'dark:text-white': index !== activeTabIndex,
                })}
                key={id}
                onClick={() => handleSelectTab(index, id)}
                data-cy={dataCy}
              >
                {t(title)}
              </div>
            ))}
        </div>
        <div className="transition ease-linear duration-150 absolute bottom-0 h-0.5 bg-darkblue left-0" />
      </div>

      {TabContentComponent && typeof TabContentComponent === 'function' ? (
        <TabContentComponent {...componentProps} />
      ) : (
        TabContentComponent
      )}
    </div>
  );
};

export default Tabs;
