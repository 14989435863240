import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { useGetCodatAccountingPlatformsQuery, usePostCodatAccountingMutation } from 'apis/codat-accounting.api';
import { CODAT_ACCOUNTING_TABS, CONNECTION_STATUS } from 'constants/codat-accounting';
import { useOpen } from 'hooks/useOpen';
import { GetAccountingDetailsResponse } from 'interfaces/integrations/codat-accounting.interface';
import { CommonError, OptionType } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal, Select } from 'shared-components';
import Tabs from 'shared-components/Tabs';

import CodatConnectedInformationBlock from './CodatConnectedInformationBlock';

interface Props {
  isConnectionCreated: boolean;
  triggerRefetch: () => void;
  connectionData?: GetAccountingDetailsResponse;
  setIsLoading: (isLoading: boolean) => void;
  connectionStatus: CONNECTION_STATUS;
  isLoading: boolean;
}

const CodatAccountConnectionHandler = ({
  isConnectionCreated,
  connectionStatus,
  triggerRefetch,
  connectionData,
  setIsLoading,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    data,
    error: codatAccountingPlatforms,
    isLoading: isCodatAccountingPlatformLoading,
  } = useGetCodatAccountingPlatformsQuery(undefined, { skip: isConnectionCreated });

  const [connectCodatAccounting, { error: connectCodatAccountingError }] = usePostCodatAccountingMutation();

  const errorCodatAccountingPlatformData = (codatAccountingPlatforms as CommonError)?.data || {};
  const errorConnectAccountData = (connectCodatAccountingError as CommonError)?.data || {};

  const errorData = errorConnectAccountData || errorCodatAccountingPlatformData;

  const selectOptions = useMemo(() => {
    return data?.result.map((item) => ({ label: item.name || '', value: item.key || '' })) || [];
  }, [data]);

  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedOption(selectOptions[0]);
      setIsLoading(true);
    }
    setIsLoading(false);
  }, [selectOptions, selectedOption, setIsLoading]);

  const handleConnectCodat = async () => {
    try {
      setIsLoading(true);
      const response = await connectCodatAccounting({ platformKey: selectedOption?.value?.toString() || '' }).unwrap();
      window.location.href = response?.result.linkUrl || '';
    } catch (error) {
      handleOpenErrorModal();
      setIsLoading(false);
    }
  };

  const handleSelectChange = (newValue: SingleValue<OptionType> | MultiValue<OptionType>) => {
    if (!Array.isArray(newValue)) {
      setSelectedOption(newValue as SingleValue<OptionType>);
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />

      {!isConnectionCreated ? (
        <Box>
          <Select
            className="w-1/2"
            isSearchable={false}
            options={selectOptions}
            onChange={handleSelectChange}
            value={selectedOption}
            isDisabled={!data}
            isLoading={isCodatAccountingPlatformLoading}
            menuPlacement="bottom"
          />
          <Button
            className="mt-4"
            isLoading={isLoading}
            disabled={!data}
            data-cy="connect-sage-button"
            onClick={handleConnectCodat}
          >
            {t('ConnectAccounting')}
          </Button>
        </Box>
      ) : (
        <CodatConnectedInformationBlock
          connectionStatus={connectionStatus}
          triggerRefetch={triggerRefetch}
          setIsLoading={setIsLoading}
          connectionData={connectionData?.result}
          isLoading={isLoading}
        />
      )}
      {connectionStatus === CONNECTION_STATUS.LINKED && (
        <div className="pt-9">
          <Tabs tabs={CODAT_ACCOUNTING_TABS} />
        </div>
      )}
    </>
  );
};

export default CodatAccountConnectionHandler;
