import { FC } from 'react';

import { Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';

import RepaymentDetailsModalContent from './RepaymentDetailsModalContent';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  heading?: string;
  repaymentId: number;
}

const RepaymentDetailsModal: FC<Props> = ({ isOpen, repaymentId, handleClose, heading }) => {
  return (
    <>
      <Modal heading={heading} isOpen={isOpen} onClose={handleClose}>
        <RepaymentDetailsModalContent repaymentId={repaymentId} />
        <ModalControlButtons onClose={handleClose} className="justify-end" closeButtonText="Close" />
      </Modal>
    </>
  );
};

export default RepaymentDetailsModal;
