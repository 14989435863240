import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

interface Props {
  text: string;
  className?: string;
}

const ColoredBlock = ({ text, className }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'p-4 bg-orange dark:bg-gray8 border border-orange1 dark:border-gray8 rounded text-sm text-darkBlue12 dark:text-white italic font-light',
        className
      )}
    >
      <p>{t(text)}</p>
    </div>
  );
};

export default ColoredBlock;
