import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetUserConfigQuery } from 'apis/user.api';

interface Props {
  title: string;
  children: ReactNode;
}
const TextPageLayout = ({ title, children }: Props) => {
  const { t } = useTranslation();

  const { isLoading } = useGetUserConfigQuery();

  if (isLoading) {
    return null;
  }

  return (
    <div className="py-5 p-[50px] text-darkGray text-sm font-light leading-5">
      <h2 className="text-center text-2.5xl leading-9 mb-2">{t(title)}</h2>
      {children}
    </div>
  );
};

export default TextPageLayout;
