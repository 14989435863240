import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { PlacesType, Tooltip } from 'react-tooltip';

import { useOpen } from 'hooks/useOpen';

interface Props {
  icon: ReactNode;
  tooltipContent: string;
  anchorId: string;
  place?: PlacesType;
}

const IconWithTooltip: FC<Props> = ({ icon, tooltipContent, anchorId, place = 'right' }) => {
  const { t } = useTranslation();

  const [isTooltipOpen, handleOpenTooltip, handleCloseTooltip] = useOpen();

  return (
    <>
      <div id={anchorId} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
        {icon}
      </div>

      <Tooltip
        anchorId={anchorId}
        place={place}
        positionStrategy="fixed"
        className="max-w-[170px] font-light z-20"
        isOpen={isTooltipOpen}
        clickable
      >
        {t(tooltipContent)}
      </Tooltip>
    </>
  );
};

export default IconWithTooltip;
