import { useState } from 'react';

import { coreSplitApis } from 'apis';
import { useLazyGetCurrentLoginInformationsQuery, useLazyGetCurrentUserDetailsQuery } from 'apis/user.api';
import {
  useBackFromImpersonatedAuthenticateMutation,
  useBackToImpersonatorMutation,
  useImpersonatedAuthenticateMutation,
  useImpersonateMutation,
} from 'apis/users.api';
import { ImpersonatedAuthenticateResult, ImpersonateUserPayload } from 'interfaces/impersonate-user';
import { CommonError } from 'interfaces/shared.interface';
import { getAuthState } from 'modules/auth/action';
import { useAppDispatch } from 'modules/store';
import { setAccessTokenToCookie } from 'utils/auth';

import { useUserConfig } from './useUserConfig';

interface Props {
  onError: VoidFunction;
}

const useImpersonateUser = ({ onError }: Props) => {
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useAppDispatch();

  const { handleGetUserConfig } = useUserConfig();
  const [fetchUserDetails] = useLazyGetCurrentUserDetailsQuery();
  const [fetchCurrentUserLoginInfo] = useLazyGetCurrentLoginInformationsQuery();

  const [impersonate, { error }] = useImpersonateMutation();
  const [impersonatedAuthenticate, { error: impersonatedAuthenticateError }] = useImpersonatedAuthenticateMutation();

  const [backToImpersonator, { error: backToImpersonatorError }] = useBackToImpersonatorMutation();
  const [backFromImpersonatedAuthenticate, { error: backFromImpersonatedAuthError }] =
    useBackFromImpersonatedAuthenticateMutation();

  const { error: impersonateError } = (error as CommonError)?.data || {};
  const { error: impersonatedAuthError } = (impersonatedAuthenticateError as CommonError)?.data || {};
  const { error: backImpersonatorError } = (backToImpersonatorError as CommonError)?.data || {};
  const { error: backImpersonatorAuthError } = (backFromImpersonatedAuthError as CommonError)?.data || {};

  const impersonateUserError =
    impersonateError || impersonatedAuthError || backImpersonatorError || backImpersonatorAuthError;

  const handleUserInfo = async (authData: ImpersonatedAuthenticateResult) => {
    const { accessToken, encryptedAccessToken, expireInSeconds } = authData;

    setAccessTokenToCookie(accessToken, encryptedAccessToken, expireInSeconds);

    if (coreSplitApis.util) {
      dispatch(coreSplitApis.util?.resetApiState());
    }

    dispatch(getAuthState());

    await handleGetUserConfig();
    await fetchCurrentUserLoginInfo();
    await fetchUserDetails();
  };

  // If there is a payload, impersonate the user, otherwise return to the impersonator
  const handleImpersonator = async (payload?: ImpersonateUserPayload) => {
    setIsFetching(true);

    try {
      const { result } = await (payload ? impersonate(payload) : backToImpersonator()).unwrap();

      const response = await (payload
        ? impersonatedAuthenticate(result?.impersonationToken)
        : backFromImpersonatedAuthenticate(result?.impersonationToken)
      ).unwrap();

      await handleUserInfo(response?.result);

      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
      onError();
      console.error(e);
    }
  };

  return {
    handleImpersonator,
    isFetching,
    error: impersonateUserError,
  };
};

export default useImpersonateUser;
