import { faTrashCan, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Input } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  field: { id: number; value: string };
  value: string;
  onChange: (id: number, value: string) => void;
  onClickCopy: (value: string) => void;
  onClickDelete: (id: number) => void;
  index: number;
  isLoading?: boolean;
}
const TemplateVariableField = ({ field, value, onChange, onClickDelete, onClickCopy, index, isLoading }: Props) => {
  return (
    <div
      data-cy="template-variable-field"
      key={field.id}
      className={cn('flex border-b border-gray6 p-2 dark:border-none dark:bg-darkBlue4', {
        'dark:bg-transparent': (index + 1) % 2 === 0,
      })}
    >
      <Input
        isLoading={isLoading}
        className="w-full"
        dataCy="variable-field-input"
        inputClassName="text-sm w-full"
        value={value}
        onChange={(e) => onChange(field.id, e.target.value)}
      />
      <Button dataCy="copy-button" className="rounded-full mx-2" type="button" onClick={() => onClickCopy(value)}>
        <FontAwesomeIcon icon={faCopy} />
      </Button>
      <Button
        dataCy="trash-button"
        className="rounded-full bg-red-600 dark:bg-red-600"
        type="button"
        onClick={() => onClickDelete(field.id)}
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </Button>
    </div>
  );
};

export default TemplateVariableField;
