import { PropsWithChildren } from 'react';

import { TableVariants, TableVariantsStyles } from 'constants/table';
import { cn } from 'utils/global';

type Props = {
  dataCy?: string;
  className?: string;
  variant?: TableVariants;
};

const TableRow = ({ children, className, variant = TableVariants.PRIMARY, dataCy }: PropsWithChildren<Props>) => {
  return (
    <tr data-cy={dataCy} className={cn(TableVariantsStyles[variant].rowClassName, className)}>
      {children}
    </tr>
  );
};

export default TableRow;
