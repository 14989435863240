export const RECENT_TENANTS_COLUMNS = [
  {
    columnId: 'name',
    title: 'TenantName',
  },
  {
    columnId: 'creationTime',
    title: 'CreationTime',
  },
];
