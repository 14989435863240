import axios, { AxiosInstance } from 'axios';

import { COOKIE_KEYS } from 'constants/common';
import { resetUserAuthStorage, getAuthCookie } from 'utils/auth';

function createHttpClient(baseUrl: string): AxiosInstance {
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 0,
  });

  instance.interceptors.request.use((config) => {
    const token = getAuthCookie(COOKIE_KEYS.AUTH_TOKEN);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function axiosRetryInterceptor(err) {
      if (err.response.status === 401) {
        resetUserAuthStorage();
        window.location.reload();
      }

      return Promise.reject(err);
    }
  );

  return instance;
}
export default createHttpClient;
