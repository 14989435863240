import { useState, useEffect, useCallback } from 'react';

export const useTimer = (initialSeconds = 60) => {
  const [timeLeft, setTimeLeft] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | null = null;

    if (isActive && timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((time) => (time > 0 ? time - 1 : 0));
      }, 1000);
    }

    if (!isActive || timeLeft === 0) {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (timeLeft === 0) {
        setIsActive(false);
      }
    }

    return () => {
      if (!intervalId) {
        return;
      }

      clearInterval(intervalId);
    };
  }, [isActive, timeLeft]);

  const startTimer = useCallback(() => {
    setIsActive(true);
  }, []);

  const pauseTimer = useCallback(() => {
    setIsActive(false);
  }, []);

  const resetTimer = useCallback(() => {
    setTimeLeft(initialSeconds);
    setIsActive(true);
  }, [initialSeconds]);

  return {
    timeLeft,
    isActive,
    startTimer,
    pauseTimer,
    resetTimer,
  };
};
