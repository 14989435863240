import { useMemo, useState } from 'react';

import { filterDuplicatedFromArray, getPossibleKeysFromArray, getUniqueKeys } from 'utils/global';

interface Props<T> {
  initialList: T[];
  parentKey: keyof T & string;
  nameKey: keyof T & string;
  labelKey: keyof T & string;
}

export const useTreeViewSearch = <T>({ initialList, parentKey, nameKey, labelKey }: Props<T>) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const filteredBySearchValueList = useMemo(() => {
    if (!initialList?.length) {
      return [];
    }

    const filteredElements = initialList.filter((listElem) => {
      if (!listElem[labelKey]) {
        return '';
      }

      return String(listElem[labelKey])?.toLowerCase().includes(searchValue.toLowerCase());
    });

    const parentKeys = getUniqueKeys(filteredElements, parentKey);

    const allPossibleKeys = getPossibleKeysFromArray(parentKeys);

    const additionalObjects = filterDuplicatedFromArray(allPossibleKeys, initialList, nameKey);

    return [...filteredElements, ...additionalObjects].filter(
      (value, index, self) => self.findIndex((item) => item[nameKey] === value[nameKey]) === index
    );
  }, [initialList, labelKey, nameKey, parentKey, searchValue]);

  const initialListIds = useMemo(() => initialList?.map((listItem) => listItem[nameKey]), [initialList, nameKey]);

  return { list: filteredBySearchValueList, handleSearch, searchValue, initialListIds };
};
