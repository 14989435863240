import {
  BENEFICIAL_OWNERS_COLUMNS,
  FILING_HISTORY_COLUMNS,
  OFFICERS_COLUMNS,
  PREVIOUS_COMPANY_NAMES_COLUMNS,
} from 'interfaces/integrations/companies-house.interface';
import { TabItem, TableColumn } from 'interfaces/shared.interface';
import BeneficialOwners from 'page-components/integrations/companies-house/beneficial-owners/BeneficialOwners';
import CompanyOverview from 'page-components/integrations/companies-house/company-overview/CompanyOverview';
import FilingHistory from 'page-components/integrations/companies-house/filing-history/FilingHistory';
import Officers from 'page-components/integrations/companies-house/officers/Officers';

export const COMPANIES_HOUSE_TABS: TabItem[] = [
  {
    dataCy: 'company-overview-tab-button',
    id: 'company-overview',
    title: 'CompanyOverview',
    component: CompanyOverview,
  },
  {
    dataCy: 'filing-history-tab-button',
    id: 'filing-history',
    title: 'FilingHistory',
    component: FilingHistory,
  },
  {
    dataCy: 'officers-tab-button',
    id: 'officers',
    title: 'Officers',
    component: Officers,
  },
  {
    dataCy: 'beneficial-owners-tab-button',
    id: 'beneficial-owners',
    title: 'BeneficialOwners',
    component: BeneficialOwners,
  },
];

export const FILING_HISTORY_COLUMNS_TITLES = {
  [FILING_HISTORY_COLUMNS.DATE]: 'Date',
  [FILING_HISTORY_COLUMNS.TYPE]: 'Type',
  [FILING_HISTORY_COLUMNS.DESCRIPTION]: 'Description',
  [FILING_HISTORY_COLUMNS.ACTIONS]: '',
};

export const FILING_HISTORY_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: FILING_HISTORY_COLUMNS.DATE,
    title: FILING_HISTORY_COLUMNS_TITLES[FILING_HISTORY_COLUMNS.DATE],
    width: 'w-52',
  },
  {
    columnId: FILING_HISTORY_COLUMNS.TYPE,
    title: FILING_HISTORY_COLUMNS_TITLES[FILING_HISTORY_COLUMNS.TYPE],
    width: 'w-52',
  },
  {
    columnId: FILING_HISTORY_COLUMNS.DESCRIPTION,
    title: FILING_HISTORY_COLUMNS_TITLES[FILING_HISTORY_COLUMNS.DESCRIPTION],
    width: 'w-52',
  },
  {
    columnId: FILING_HISTORY_COLUMNS.ACTIONS,
    title: FILING_HISTORY_COLUMNS_TITLES[FILING_HISTORY_COLUMNS.ACTIONS],
    width: 'w-1/12',
  },
];

export const OFFICERS_TITLES = {
  [OFFICERS_COLUMNS.NAME]: 'Name',
  [OFFICERS_COLUMNS.NATIONALITY]: 'Nationality',
  [OFFICERS_COLUMNS.RESIDENCE]: 'Residence',
  [OFFICERS_COLUMNS.ROLE]: 'Role',
  [OFFICERS_COLUMNS.APPOINTED_ON]: 'AppointedOn',
  [OFFICERS_COLUMNS.RESIGNED_ON]: 'ResignedOn',
};

export const OFFICERS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: OFFICERS_COLUMNS.NAME,
    title: OFFICERS_TITLES[OFFICERS_COLUMNS.NAME],
    width: 'w-52',
  },
  {
    columnId: OFFICERS_COLUMNS.NATIONALITY,
    title: OFFICERS_TITLES[OFFICERS_COLUMNS.NATIONALITY],
    width: 'w-52',
  },
  {
    columnId: OFFICERS_COLUMNS.RESIDENCE,
    title: OFFICERS_TITLES[OFFICERS_COLUMNS.RESIDENCE],
    width: 'w-52',
  },
  {
    columnId: OFFICERS_COLUMNS.ROLE,
    title: OFFICERS_TITLES[OFFICERS_COLUMNS.ROLE],
    width: 'w-52',
  },
  {
    columnId: OFFICERS_COLUMNS.APPOINTED_ON,
    title: OFFICERS_TITLES[OFFICERS_COLUMNS.APPOINTED_ON],
    width: 'w-52',
  },
  {
    columnId: OFFICERS_COLUMNS.RESIGNED_ON,
    title: OFFICERS_TITLES[OFFICERS_COLUMNS.RESIGNED_ON],
    width: 'w-52',
  },
];

export const PREVIOUS_COMPANY_NAMES_COLUMNS_TITLES = {
  [PREVIOUS_COMPANY_NAMES_COLUMNS.NAMES]: 'Names',
  [PREVIOUS_COMPANY_NAMES_COLUMNS.PERIOD]: 'Period',
};

export const PREVIOUS_COMPANY_NAMES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: PREVIOUS_COMPANY_NAMES_COLUMNS.NAMES,
    title: PREVIOUS_COMPANY_NAMES_COLUMNS_TITLES[PREVIOUS_COMPANY_NAMES_COLUMNS.NAMES],
    width: 'w-1/2',
  },
  {
    columnId: PREVIOUS_COMPANY_NAMES_COLUMNS.PERIOD,
    title: PREVIOUS_COMPANY_NAMES_COLUMNS_TITLES[PREVIOUS_COMPANY_NAMES_COLUMNS.PERIOD],
    width: 'w-1/2',
  },
];

export const BENEFICIAL_OWNERS_TITLES = {
  [BENEFICIAL_OWNERS_COLUMNS.NAME]: 'Name',
  [BENEFICIAL_OWNERS_COLUMNS.NATIONALITY]: 'Nationality',
  [BENEFICIAL_OWNERS_COLUMNS.RESIDENCE]: 'Residence',
  [BENEFICIAL_OWNERS_COLUMNS.NATURE_OF_CONTROL]: 'NaturesOfControl',
  [BENEFICIAL_OWNERS_COLUMNS.NOTIFIED_ON]: 'NotifiedOn',
  [BENEFICIAL_OWNERS_COLUMNS.CEASED_ON]: 'CeasedOn',
};

export const BENEFICIAL_OWNERS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: BENEFICIAL_OWNERS_COLUMNS.NAME,
    title: BENEFICIAL_OWNERS_TITLES[BENEFICIAL_OWNERS_COLUMNS.NAME],
    width: '',
  },
  {
    columnId: BENEFICIAL_OWNERS_COLUMNS.NATIONALITY,
    title: BENEFICIAL_OWNERS_TITLES[BENEFICIAL_OWNERS_COLUMNS.NATIONALITY],
    width: '',
  },
  {
    columnId: BENEFICIAL_OWNERS_COLUMNS.RESIDENCE,
    title: BENEFICIAL_OWNERS_TITLES[BENEFICIAL_OWNERS_COLUMNS.RESIDENCE],
    width: '',
  },
  {
    columnId: BENEFICIAL_OWNERS_COLUMNS.NATURE_OF_CONTROL,
    title: BENEFICIAL_OWNERS_TITLES[BENEFICIAL_OWNERS_COLUMNS.NATURE_OF_CONTROL],
    width: '',
  },
  {
    columnId: BENEFICIAL_OWNERS_COLUMNS.NOTIFIED_ON,
    title: BENEFICIAL_OWNERS_TITLES[BENEFICIAL_OWNERS_COLUMNS.NOTIFIED_ON],
    width: '',
  },
  {
    columnId: BENEFICIAL_OWNERS_COLUMNS.CEASED_ON,
    title: BENEFICIAL_OWNERS_TITLES[BENEFICIAL_OWNERS_COLUMNS.CEASED_ON],
    width: '',
  },
];
