export const SAMPLE_PAGE_DRAG_AND_DROP = 'DragAndDrop';
export const SAMPLE_PAGE_AUTOCOMPLETE = 'AutoComplete';
export const SAMPLE_PAGE_AUTOCOMPLETE_USING_GOOGLE = 'GooglePlacesAutoComplete';

export const BOARD_COLUMNS_NAME = {
  todo: 'ToDo',
  inProgress: 'InProgress',
  codeReview: 'CodeReview',
  inTest: 'InTest',
  done: 'Done',
};

export const ASYNC_AUTOCOMPLETE_DOCUMENTATION = 'https://react-select.com/async';
export const ASYNC_AUTOCOMPLETE_DOCUMENTATION_USING_GOOGLE = 'https://github.com/vivet/GoogleApi';
export const GITHUB_TASKSBOARD_COMPONENT =
  'https://github.com/evolve-consultants/aspnet-easy-go-frontend-react/blob/main/src/page-components/samples/TasksBoard.tsx';
