import { useEffect, useState } from 'react';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddRolesRequestMutation, useFindRolesRequestMutation } from 'apis/organization-units.api';
import { ADD_NEW_MEMBER_TABLE_COLUMNS } from 'constants/organization-units';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import {
  AddNewRolesPayload,
  ExtendedAddNewRolesPayload,
  SelectedUnitInfo,
} from 'interfaces/organization-units.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Button, Checkbox, ErrorModal, Input, LoadingOverlay, Modal, Pagination, Table } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

import AddRoleModalRow from './AddRoleModalRow';

interface Props {
  handleClose: VoidFunction;
  refetchListRoot: VoidFunction;
  isOpen: boolean;
  selectedUnit: SelectedUnitInfo | null;
}
const AddRoleModal = ({ handleClose, isOpen, refetchListRoot, selectedUnit }: Props) => {
  const { t } = useTranslation();
  const [addRolesRequest, { error: addRolesError }] = useAddRolesRequestMutation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { handleSetTabName, sortingColumnId, sortingType } = useTableSorting();
  const { handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } = usePagination();
  const [findRoles, { data, isLoading: isFindRolesLoading, isUninitialized, error: findRolesError }] =
    useFindRolesRequestMutation({});
  const [selectedRoleIds, setSelectedRoleIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const { result } = data || {};

  const addRolesFormMethods = useForm<ExtendedAddNewRolesPayload>({
    defaultValues: {
      search: '',
    },
  });

  const handleSearchChange = addRolesFormMethods.handleSubmit(() => {
    fetchUsers();
  });

  const {
    watch,
    register,
    reset,
    formState: { errors },
  } = addRolesFormMethods;

  const errorFindRolesData = (findRolesError as CommonError)?.data || {};
  const errorAddRolesData = (addRolesError as CommonError)?.data || {};

  const errorData = errorFindRolesData || errorAddRolesData;

  const searchQuery = watch('search');

  const onCloseModal = () => {
    reset();
    setSelectedRoleIds([]);
    handleClose();
  };

  const fetchUsers = () => {
    findRoles({
      filter: searchQuery,
      maxResultCount: '10',
      organizationUnitId: selectedUnit?.id,
      skipCount: 0,
    });
  };

  const onSubmit = async () => {
    const payload: AddNewRolesPayload = {
      organizationUnitId: selectedUnit?.id,
      roleIds: selectedRoleIds,
    };
    try {
      await addRolesRequest(payload).unwrap();
      refetchListRoot();
      onCloseModal();
      successNotify(t('SuccessfullyAdded'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleToggleRoleSelect = (userId: number) => {
    setSelectedRoleIds((prevSelectedIds) =>
      prevSelectedIds.includes(userId) ? prevSelectedIds.filter((id) => id !== userId) : [...prevSelectedIds, userId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRoleIds([]);
    } else {
      const allUserIds = result?.items.map((item) => Number(item.value)) || [];
      setSelectedRoleIds(allUserIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    isOpen && fetchUsers();
  }, [isOpen]);

  useEffect(() => {
    const allUserIds = result?.items?.map((item) => Number(item.value)) || [];
    const areAllSelected = allUserIds.length > 0 && allUserIds.every((id) => selectedRoleIds.includes(id));
    setSelectAll(areAllSelected);
  }, [result?.items, setSelectedRoleIds]);

  return (
    <>
      <Modal dataCy="create-root-unit-modal" isOpen={isOpen} onClose={onCloseModal} heading="SelectRoles">
        <FormProvider {...addRolesFormMethods}>
          <form data-cy="create-root-unit-modal-form" onSubmit={addRolesFormMethods.handleSubmit(onSubmit)}>
            <div className="flex w-full">
              <Input
                isLoading={isFindRolesLoading}
                {...register('search')}
                {...errors}
                dataCy="add-role-search"
                className="mb-3.5 flex-grow"
                inputClassName="w-full text-sm"
                name="search"
                placeholder="SearchWithThreeDot"
              />
              <Button
                isLoading={isFindRolesLoading}
                dataCy="submit-search-filter"
                onClick={handleSearchChange}
                type="submit"
                className="h-10 rounded-s-none"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </div>

            <div className="relative">
              <LoadingOverlay isLoading={isUninitialized} />

              <Table
                dataCy="add-role-table"
                columns={ADD_NEW_MEMBER_TABLE_COLUMNS}
                handleSetTabName={handleSetTabName}
                sortingColumnId={sortingColumnId}
                isTableEmpty={!result?.items.length}
                sortingType={sortingType}
                isLoading={isFindRolesLoading}
                selectAll={selectAll}
                handleSelectAll={handleSelectAll}
                isSelectAllCheckbox
              >
                {result?.items.map((item) => (
                  <AddRoleModalRow
                    key={item.value}
                    roleData={item}
                    onToggleSelect={handleToggleRoleSelect}
                    isSelected={selectedRoleIds.includes(Number(item.value))}
                  />
                ))}
              </Table>

              <Pagination
                totalCount={result?.totalCount}
                handleSetMaxResultCount={handleSetMaxResultCount}
                maxResultCount={maxResultCount}
                handleSetCurrentPage={handleSetCurrentPage}
                currentPage={currentPage}
              />
            </div>

            <ModalControlButtons isShownSubmit className="pb-5" isLoading={isFindRolesLoading} onClose={onCloseModal} />
          </form>
        </FormProvider>
      </Modal>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default AddRoleModal;
