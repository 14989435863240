import { useCallback, useState } from 'react';

import { infoNotify } from 'utils/notifications';

export const useTemplateVariables = () => {
  const [fields, setFields] = useState<{ id: number; value: string }[]>([]);

  const addNewField = useCallback(() => {
    setFields([...fields, { id: Date.now(), value: '' }]);
  }, [fields]);

  const deleteField = useCallback(
    (id: number) => {
      setFields(fields.filter((field) => field.id !== id));
    },
    [fields]
  );

  const handleInputChange = useCallback(
    (id: number, value: string) => {
      const newFields = fields.map((field) => {
        if (field.id === id) {
          return { ...field, value: value };
        }
        return field;
      });
      setFields(newFields);
    },
    [fields]
  );

  const copyToClipboard = useCallback(async (value: string) => {
    try {
      await navigator.clipboard.writeText(`#{${value}}#`);
      infoNotify('Copied');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }, []);

  return { fields, addNewField, deleteField, handleInputChange, copyToClipboard, setFields };
};
