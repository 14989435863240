import { CONFIRM_EMAIL_PAGE_TITLE } from 'constants/confirm-email';
import ConfirmEmailForm from 'page-components/confirm-email/ConfirmEmailForm';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const ConfirmEmail = () => {
  return (
    <AuthFormContainer title={CONFIRM_EMAIL_PAGE_TITLE}>
      <ConfirmEmailForm />
    </AuthFormContainer>
  );
};

export default ConfirmEmail;
