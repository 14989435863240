import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetConnectionXeroDetailsQuery, usePostAuthenticationTokenMutation } from 'apis/xero.api';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import XeroConnectionHandler from 'page-components/integrations/xero/XeroConnectionHandler';
import { ErrorModal, LoadingOverlay } from 'shared-components';
import { successNotify } from 'utils/notifications';

const Xero = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [postAuthenticationToken, { isLoading: isLoadingPostFetch, error: postFetchError }] =
    usePostAuthenticationTokenMutation();
  const {
    data: connectionData,
    isLoading: isLoadingGetConnection,
    isFetching,
    error: getConnectionError,
    refetch,
  } = useGetConnectionXeroDetailsQuery();

  const isLoading = isLoadingPostFetch || isLoadingGetConnection;

  const navigate = useNavigate();

  const { data: postFetchErrorData } = (postFetchError as CommonError) || {};
  const { data: getConnectionErrorData } = (getConnectionError as CommonError) || {};

  const [params] = useSearchParams();
  const code = params.get('code');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (code) {
          const redirectUri = window.location.origin + ROUTES.xeroCallback;
          await postAuthenticationToken({ code, redirectUri }).unwrap();
          refetch();
          navigate(ROUTES.xero);
          successNotify(t('SuccessfullyConnected'));
        }
      } catch (error) {
        handleOpenErrorModal();
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ErrorModal
        errorMessage={postFetchErrorData?.error?.message || getConnectionErrorData?.error?.message}
        description={postFetchErrorData?.error?.details || getConnectionErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div className="relative" data-cy="xero-page">
        <LoadingOverlay isLoading={isFetching && !!connectionData} />
        {!isLoading && !isFetching ? (
          <XeroConnectionHandler isConnect={connectionData?.result.isConnected || false} triggerRefetch={refetch} />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default Xero;
