import {
  GetAccountsResponse,
  GetBalanceResponse,
  GetBankAuthUrlResponse,
  GetCardInformationResponse,
  GetCardsResponse,
  GetConnectionDetailsResponse,
  GetInformationResponse,
  GetTransactionsResponse,
} from 'interfaces/integrations/truelayer.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const trueLayerApi = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getBankAuthUrl: build.query<GetBankAuthUrlResponse, void>({
      query: () => ({
        url: `TrueLayer/GetBankAuthUrl`,
        method: 'GET',
      }),
    }),
    getConnectionDetails: build.query<GetConnectionDetailsResponse, void>({
      query: () => ({
        url: `TrueLayer/GetConnectionDetails`,
        method: 'GET',
      }),
    }),
    postFetchAndSaveAccessToken: build.mutation<CommonResponse, { code?: string; redirectUri?: string }>({
      query: ({ code, redirectUri }) => ({
        url: `TrueLayer/FetchAndSaveAccessToken?code=${code}&redirectUri=${redirectUri}`,
        method: 'POST',
        data: { code, redirectUri },
      }),
    }),
    postDisconnectAccount: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `TrueLayer/DisconnectAccount`,
        method: 'POST',
      }),
    }),

    getCards: build.query<GetCardsResponse, void>({
      query: () => ({
        url: `TrueLayer/GetCards`,
        method: 'GET',
      }),
    }),
    getAccounts: build.query<GetAccountsResponse, void>({
      query: () => ({
        url: `TrueLayer/GetAccounts`,
        method: 'GET',
      }),
    }),
    getAccountInformation: build.query<GetInformationResponse, string>({
      query: (accountId) => ({
        url: `TrueLayer/GetAccount?accountId=${accountId}`,
        method: 'GET',
      }),
    }),
    getAccountBalance: build.query<GetBalanceResponse, string>({
      query: (accountId) => ({
        url: `TrueLayer/GetAccountBalance?accountId=${accountId}`,
        method: 'GET',
      }),
    }),
    getAccountTransactions: build.query<GetTransactionsResponse, string>({
      query: (params) => ({
        url: `TrueLayer/GetAccountTransactions?accountId=${params}`,
        method: 'GET',
      }),
    }),
    getCardInformation: build.query<GetCardInformationResponse, string>({
      query: (cardId) => ({
        url: `TrueLayer/GetCard?cardId=${cardId}`,
        method: 'GET',
      }),
    }),
    getCardBalance: build.query<GetBalanceResponse, string>({
      query: (cardId) => ({
        url: `TrueLayer/GetCardBalance?cardId=${cardId}`,
        method: 'GET',
      }),
    }),
    getCardTransactions: build.query<GetTransactionsResponse, string>({
      query: (params) => ({
        url: `TrueLayer/GetCardTransactions?cardId=${params}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetBankAuthUrlQuery,
  useGetConnectionDetailsQuery,
  usePostFetchAndSaveAccessTokenMutation,
  usePostDisconnectAccountMutation,
  useGetCardsQuery,
  useGetAccountsQuery,
  useGetAccountInformationQuery,
  useGetAccountBalanceQuery,
  useGetAccountTransactionsQuery,
  useLazyGetAccountTransactionsQuery,
  useGetCardInformationQuery,
  useGetCardBalanceQuery,
  useGetCardTransactionsQuery,
  useLazyGetCardTransactionsQuery,
} = trueLayerApi;
