import { CommonResponse } from '../shared.interface';

export interface FilingHistoryResponse extends CommonResponse {
  result: FilingHistoryResult;
}
export interface FilingHistory {
  category: string | null;
  date: string | null;
  description: string | null;
  fileLink: string | null;
  pages: string | null;
  type: string | null;
}

export interface FilingHistoryResult {
  items: FilingHistory[];
  totalCount: number;
}

export enum FILING_HISTORY_COLUMNS {
  DATE = 'date',
  TYPE = 'type',
  DESCRIPTION = 'description',
  ACTIONS = 'actions',
}
export interface CompaniesHouseSearchResult {
  result: {
    items: SearchResultItems[];
    totalCount: number;
  };
}

export interface SearchResultItems {
  companyNumber: string;
  title: string;
}
export interface CompaniesHouseSearchRequest {
  searchQuery: string;
}

export interface CompaniesHouseDetailsResponse {
  result: {
    accounts: {
      companyProfileLastAccounts: {
        madeUpTo: string | null;
      } | null;
      nextDue: string | null;
      nextMadeUpTo: string | null;
      overdue: boolean | null;
    } | null;
    branchCompanyDetails: string | null;
    companyName: string | null;
    companyNumber: string | null;
    companyStatusText: string | null;
    confirmationStatement: {
      lastMadeUpTo: string | null;
      nextDue: string | null;
      nextMadeUpTo: string | null;
      overDue: boolean | null;
    } | null;
    dateOfCessation: string | null;
    dateOfCreation: string | null;
    etag: string | null;
    foreignCompanyDetails: string | null;
    hasBeenLiquidated: boolean | null;
    hasCharges: boolean | null;
    hasInsolvencyHistory: boolean | null;
    isCommunityInterestCompany: boolean | null;
    jurisdiction: string | null;
    lastFullMembersListDate: string | null;
    previousCompanyNames: PreviousCompanyNames[];
    registeredOfficeAddress: Address;
    registeredOfficeIsInDispute: boolean | null;
    sicCodes: string[] | null;
    type: string | null;
    undeliverableRegisteredOfficeAddress: boolean | null;
  } | null;
}

export interface Address {
  addressLine1: string | null;
  addressLine2: string | null;
  careOf: string | null;
  country: string | null;
  locality: string | null;
  poBox: string | null;
  postalCode: string | null;
  premises: string | null;
  region: string | null;
}
export interface PreviousCompanyNames {
  ceasedOn: string | null;
  effectiveFrom: string | null;
  name: string | null;
}

export enum PREVIOUS_COMPANY_NAMES_COLUMNS {
  NAMES = 'names',
  PERIOD = 'period',
}

export interface PersonInformationShared {
  name: string | null;
  countryOfResidence: string | null;
  nationality: string | null;
}

export interface OfficersResponse extends CommonResponse {
  result: OfficersResult;
}
export interface Officers extends PersonInformationShared {
  appointedOn: string | null;
  birthDate: string | null;
  companyOfficerAddress: Address;
  occupation: string | null;
  officerRole: string | null;
  resignedOn: string | null;
}

export interface OfficersResult {
  items: Officers[];
  totalCount: number;
}
export enum OFFICERS_COLUMNS {
  NAME = 'name',
  NATIONALITY = 'nationality',
  RESIDENCE = 'residence',
  ROLE = 'role',
  APPOINTED_ON = 'appointedOn',
  RESIGNED_ON = 'resignedOn',
}

export interface BeneficialOwnersResponse extends CommonResponse {
  result: BeneficialOwnersResult;
}
export interface BeneficialOwners extends PersonInformationShared {
  naturesOfControl: string[] | null;
  ceasedOn: string | null;
  notifiedOn: string | null;
}

export interface BeneficialOwnersResult {
  items: BeneficialOwners[];
  totalCount: number;
}
export enum BENEFICIAL_OWNERS_COLUMNS {
  NAME = 'name',
  NATIONALITY = 'nationality',
  RESIDENCE = 'residence',
  NATURE_OF_CONTROL = 'natureOfControl',
  NOTIFIED_ON = 'notifiedOn',
  CEASED_ON = 'ceasedOn',
}
