import { useTranslation } from 'react-i18next';

import { ACTIVATE_EMAIL_PAGE_TEXT, ACTIVATE_EMAIL_PAGE_TITLE } from 'constants/activate-email';
import { ActivateEmailForm } from 'page-components/activate-email';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <AuthFormContainer title={t(ACTIVATE_EMAIL_PAGE_TITLE)}>
      <p className="text-darkGray dark:text-white mb-4 mt-9">{t(ACTIVATE_EMAIL_PAGE_TEXT)}</p>
      <ActivateEmailForm />
    </AuthFormContainer>
  );
};

export default ForgotPassword;
