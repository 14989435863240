import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
}

const TextDivider = ({ label }: Props) => {
  const { t } = useTranslation();

  const lineStyle = 'flex-1 border-t border-solid border-opacity-10 border-black dark:border-darkGray3';
  return (
    <div className="flex items-center">
      <div className={lineStyle} />
      <div className="mx-4 font-bold">{t(label)}</div>
      <div className={lineStyle} />
    </div>
  );
};

export default TextDivider;
