import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetSubscriptionsQuery } from 'apis/subscriptions-admin.api';
import { useGetUserConfigQuery } from 'apis/user.api';
import {
  getSubscriptionsFiltersFieldsDefaultValues,
  SEARCH_SUBSCRIPTIONS_PLACEHOLDER,
  SUBSCRIPTIONS_FILTERS_FORM_FIELDS,
  SUBSCRIPTIONS_TABLE_COLUMNS,
} from 'constants/subscriptions-admin';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import { SubscriptionsFilterFormValues } from 'interfaces/subscriptions-admin.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import CreateSubscriptionModalContent from 'page-components/subscriptions-admin/create-subscription/CreateSubscriptionModalContent';
import SubscriptionsRow from 'page-components/subscriptions-admin/SubscriptionRow';
import SubscriptionManagementModal from 'page-components/subscriptions-admin/subscriptions-management-modal/SubscriptionManagementModal';
import { Button, ErrorModal, FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';
import FilterFormContentWithoutPermissions from 'shared-components/filter/FilterFormContentWithoutPermissions';

const SubscriptionsAdmin = () => {
  const { t } = useTranslation();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isOpenCreateSubscriptionModal, handleOpenCreateSubscription, handleCloseCreateSubscription] = useOpen();

  const { applyFilters, filterParams, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingType } = useTableSorting();
  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: errorSubscriptionData,
  } = useGetSubscriptionsQuery(`${filterParams}${paginationParams}`);
  const { data: userConfig } = useGetUserConfigQuery();

  const { result } = data || {};
  const { error: errorData } = (errorSubscriptionData as CommonError)?.data || {};

  const { name } = userConfig?.result?.localization?.currentLanguage || {};

  const subscriptionsFiltersMethods = useForm<SubscriptionsFilterFormValues>({
    defaultValues: getSubscriptionsFiltersFieldsDefaultValues(t('SelectStatus')),
  });

  const onSubmitFilters = (data: SubscriptionsFilterFormValues) => {
    const { statusId, filter } = data;

    const newFiltersToApply = {
      searchQuery: filter,
      statusId: statusId.value !== '' ? (statusId.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    subscriptionsFiltersMethods.reset(getSubscriptionsFiltersFieldsDefaultValues(t('SelectStatus')));
    onSubmitFilters(getSubscriptionsFiltersFieldsDefaultValues(t('SelectStatus')));
  };

  useEffect(() => {
    if (!name) {
      return;
    }

    handleClearFields();
  }, [name]);

  useEffect(() => {
    if (errorSubscriptionData) {
      handleOpenErrorModal();
    }
  }, [errorSubscriptionData, handleOpenErrorModal]);

  useEffect(() => {
    subscriptionsFiltersMethods.reset(getSubscriptionsFiltersFieldsDefaultValues(t('SelectStatus')));
  }, [t, subscriptionsFiltersMethods]);

  return (
    <div data-cy="subscriptions-page">
      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <SubscriptionManagementModal
          heading="CreateSubscription"
          isOpen={isOpenCreateSubscriptionModal}
          handleClose={handleCloseCreateSubscription}
        >
          <CreateSubscriptionModalContent
            onClose={handleCloseCreateSubscription}
            refetch={refetch}
            isLoading={isFetching}
          />
        </SubscriptionManagementModal>

        <div>
          <FormProvider {...subscriptionsFiltersMethods}>
            <form onSubmit={subscriptionsFiltersMethods.handleSubmit(onSubmitFilters)}>
              <FilterForm
                dataCy="subscriptions-page-search"
                placeHolder={SEARCH_SUBSCRIPTIONS_PLACEHOLDER}
                handleClearFields={handleClearFields}
                rightTopActionButton={
                  <Button
                    hidden={!permissions[Permissions.PAGES_HOST_SUBSCRIPTIONS_CREATE]}
                    data-cy="create-subscription-button"
                    className="h-10 ml-3.5"
                    type="button"
                    onClick={handleOpenCreateSubscription}
                  >
                    {t('Create')}
                  </Button>
                }
              >
                <FilterFormContentWithoutPermissions fields={SUBSCRIPTIONS_FILTERS_FORM_FIELDS} />
              </FilterForm>
            </form>
          </FormProvider>
        </div>
        <Table
          dataCy="subscriptions-table"
          columns={SUBSCRIPTIONS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {result?.items.map((subscription) => (
            <SubscriptionsRow key={subscription.id} subscription={subscription} refetchSubscriptions={refetch} />
          ))}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>

      <ErrorModal
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />
    </div>
  );
};

export default SubscriptionsAdmin;
