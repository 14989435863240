import { useTenantGetTopStatsQuery } from 'apis';
import { TENANT_MOCKED_TOP_STATS_PERCENTS, TENANT_TOP_STATS_WIDGET } from 'constants/dashboard';

import { TopStatItem } from './TopStatItem';
import TopStatsSkeleton from './TopStatsSkeleton';

export const TenantTopStats = () => {
  const { data: topStatsData, isLoading } = useTenantGetTopStatsQuery();

  if (isLoading) {
    return <TopStatsSkeleton />;
  }

  return (
    <>
      {TENANT_TOP_STATS_WIDGET.map(({ id, ...props }) => {
        const value = topStatsData?.result?.[id] || 0;

        const percent = TENANT_MOCKED_TOP_STATS_PERCENTS[id];

        return <TopStatItem key={id} id={id} isLoading={isLoading} value={value} percent={percent} {...props} />;
      })}
    </>
  );
};
