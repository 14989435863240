import { useCallback, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetAllUserEmailsQuery,
  useLazyGetEmailTemplateByIdQuery,
  useSendCustomEmailMutation,
} from 'apis/email-templates.api';
import { DEFAULT_SEND_EMAIL_TEMPLATE_FORM_VALUES, getSendEmailTemplateFormFields } from 'constants/email-templates';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { useTemplateTextEditor } from 'hooks/useTemplateTextEditor';
import {
  SEND_EMAIL_TEMPLATE_FIELDS_NAMES,
  SendEmailTemplateFormValues,
  SHARED_EMAIL_TEMPLATES_FIELDS_NAMES,
} from 'interfaces/email-templates.interface';
import { BASE_WYSIWYG_FIELD_NAMES, CommonError } from 'interfaces/shared.interface';
import { BottomButtonsContainer } from 'page-components/email-templates/BottomButtonsContainer';
import FillTemplateVariables from 'page-components/email-templates/template-form/FillTemplateVariables';
import { ErrorModal, FormContent, LoadingOverlay, TextEditor } from 'shared-components';
import { successNotify } from 'utils/notifications';

const SendEmailTemplate = () => {
  const navigate = useNavigate();
  const { emailTemplateId } = useParams();

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { handleChangeInputValue, setTemplateVariables, templateVariables, setBodyContent, updatedBodyContent } =
    useTemplateTextEditor();

  const [
    getEmailTemplateById,
    { isFetching: isFetchingTemplateById, isLoading: isLoadingTemplateById, error: getTemplateError },
  ] = useLazyGetEmailTemplateByIdQuery();
  const { data: userEmailsData, isLoading: userEmailsDataLoading } = useGetAllUserEmailsQuery();
  const [sendCustomEmail, { error: sendCustomEmailError, isLoading }] = useSendCustomEmailMutation();

  const errorSendCustomEmailData = (sendCustomEmailError as CommonError)?.data || {};
  const errorGetTemplateErrorData = (getTemplateError as CommonError)?.data || {};

  const errorData = errorSendCustomEmailData || errorGetTemplateErrorData;

  const recipientOptions = userEmailsData?.result?.map((email) => ({ value: email, label: email }));

  const { reset, ...sentEmailTemplateMethods } = useForm<SendEmailTemplateFormValues>({
    defaultValues: DEFAULT_SEND_EMAIL_TEMPLATE_FORM_VALUES,
  });

  const onSubmit = async (data: SendEmailTemplateFormValues) => {
    const payload = {
      subject: data[SHARED_EMAIL_TEMPLATES_FIELDS_NAMES.SUBJECT],
      body: data[BASE_WYSIWYG_FIELD_NAMES.TEXT_EDITOR],
      userEmails: data[SEND_EMAIL_TEMPLATE_FIELDS_NAMES.RECIPIENTS].map((email) => email.value),
    };

    try {
      await sendCustomEmail(payload).unwrap();
      navigate(ROUTES.emailTemplates);
      successNotify('EmailsSuccessfullySended');
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const getEmailTemplate = useCallback(async () => {
    try {
      const fetchedData = await getEmailTemplateById(emailTemplateId).unwrap();
      const { subject, body, variableNames } = fetchedData?.result || {};

      reset({
        subject,
      });

      setTemplateVariables(variableNames.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {}));
      setBodyContent(body);
    } catch (error) {
      handleOpenErrorModal();
    }
  }, [emailTemplateId, getEmailTemplateById, handleOpenErrorModal, reset, setBodyContent, setTemplateVariables]);

  useEffect(() => {
    if (!emailTemplateId) {
      return;
    }

    getEmailTemplate();
  }, [getEmailTemplate, emailTemplateId]);

  useEffect(() => {
    if (updatedBodyContent) {
      reset({ textEditor: updatedBodyContent });
    }
  }, [reset, updatedBodyContent]);

  return (
    <div data-cy="send-email-template-page">
      {emailTemplateId && <LoadingOverlay isLoading={isFetchingTemplateById && !isLoadingTemplateById} />}

      <ErrorModal
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.error?.message}
      />
      <FormProvider {...sentEmailTemplateMethods} reset={reset}>
        <form
          onSubmit={sentEmailTemplateMethods.handleSubmit(onSubmit)}
          className="bg-white p-6 shadow-md dark:bg-darkBlue"
        >
          <FormContent
            isLoading={userEmailsDataLoading}
            fields={getSendEmailTemplateFormFields(recipientOptions)}
            register={sentEmailTemplateMethods.register}
            errors={sentEmailTemplateMethods.formState.errors}
          />

          {!!Object.keys(templateVariables).length && (
            <FillTemplateVariables
              handleChangeInputValue={handleChangeInputValue}
              templateVariables={templateVariables}
              isLoading={isLoadingTemplateById}
            />
          )}

          <TextEditor isLoading={isLoadingTemplateById} className="mt-12" />
          <BottomButtonsContainer isLoading={isLoading} submitButtonText={'Send'} />
        </form>
      </FormProvider>
    </div>
  );
};

export default SendEmailTemplate;
