import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { usePostDisconnectPayPalAccountMutation, usePostStartOnboardingMutation } from 'apis/paypal.api';
import { PAYPAL_TAB } from 'constants/paypal';
import { useOpen } from 'hooks/useOpen';
import { StartOnboardingResponse } from 'interfaces/integrations/paypal.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal, WarningModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';

interface Props {
  isConnect: boolean;
  triggerRefetch: () => void;
}

const PayPalConnectionHandler = ({ isConnect, triggerRefetch }: Props) => {
  const { t } = useTranslation();
  const [postStartOnboarding, { error: postStartOnboardingError, isLoading: isPostOnboardingLoading }] =
    usePostStartOnboardingMutation();

  const [isOnboardingLoading, setIsOnboardingLoading] = useState(isPostOnboardingLoading);

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [disconnectPayPal, { error: disconnectPayPalAccountError, isLoading: isDisconnectPayPalLoading }] =
    usePostDisconnectPayPalAccountMutation();

  const errorAuthorizationPayPalUrlData = (postStartOnboardingError as CommonError)?.data || {};
  const errorDisconnectPayPalData = (disconnectPayPalAccountError as CommonError)?.data || {};

  const errorData = errorAuthorizationPayPalUrlData || errorDisconnectPayPalData;

  const handleConnectPayPal = async () => {
    try {
      setIsOnboardingLoading(true);
      const response: StartOnboardingResponse = await postStartOnboarding().unwrap();
      const link = response?.result.redirectUrl;
      window.location.href = link;
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleDisconnectPayPal = async () => {
    try {
      await disconnectPayPal().unwrap();
      triggerRefetch();
      handleCloseWarningModal();
      successNotify(t('DisconnectedSuccessfully'));
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('DoYouWantToDeleteYourPayPalConnection')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDisconnectPayPal}
      />
      {!isConnect ? (
        <Box>
          <Button data-cy="connect-paypal-button" isLoading={isOnboardingLoading} onClick={handleConnectPayPal}>
            {t('ConnectPayPal')}
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <div className="grid grid-cols-2">
              <div className="col-span-1 flex flex-col justify-center">
                <p className="mb-2">{t('PayPalAccountConnected')}.</p>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Button
                  isLoading={isDisconnectPayPalLoading}
                  className="float-right"
                  data-cy="disconnect-paypal-button"
                  onClick={handleOpenWarningModal}
                >
                  {t('DisconnectPayPal')}
                </Button>
              </div>
            </div>
          </Box>
          <div className="pt-5">
            <Tabs tabs={PAYPAL_TAB} />
          </div>
        </>
      )}
    </>
  );
};

export default PayPalConnectionHandler;
