import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useGetTenantIdQuery } from 'apis/tenants.api';
import { CommonError } from 'interfaces/shared.interface';

interface Props {
  onError: VoidFunction;
}

const useTenantId = ({ onError }: Props) => {
  const { tenantId } = useParams();

  const {
    data: tenantData,
    error: tenantErrorData,
    isLoading,
  } = useGetTenantIdQuery(tenantId || '', { skip: !tenantId });

  const { error: tenantError } = (tenantErrorData as CommonError)?.data || {};

  const { id, name } = tenantData?.result || {};

  const tenantIdQueryParameter = id ? `&TenantId=${id}` : '';

  const shouldSkip = tenantId ? !id : false;

  useEffect(() => {
    if (tenantError?.message) {
      onError();
    }
  }, [onError, tenantError?.message]);

  return {
    tenantId,
    tenantError,
    name: tenantId && name,
    tenantIdQueryParameter,
    shouldSkip,
    isTenantIdLoading: isLoading,
  };
};

export default useTenantId;
