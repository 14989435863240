import { useEffect, useState } from 'react';

import { useGetCodatAccountingDetailsQuery } from 'apis/codat-accounting.api';
import { CONNECTION_STATUS } from 'constants/codat-accounting';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import CodatAccountConnectionHandler from 'page-components/integrations/codat-accounting/CodatAccountingConnectionHandler';
import { Box, ErrorModal, LoadingOverlay } from 'shared-components';

const CodatAccount = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    data: connectionData,
    isLoading: isCodatAccountDetailsLoading,
    isFetching,
    error: getConnectionError,
    refetch,
  } = useGetCodatAccountingDetailsQuery();

  const { data: getConnectionErrorData } = (getConnectionError as CommonError) || {};

  const [isLoading, setIsLoading] = useState(isCodatAccountDetailsLoading);

  useEffect(() => {
    if (getConnectionError) {
      handleOpenErrorModal();
    }
    setIsLoading(isCodatAccountDetailsLoading);
  }, [getConnectionError, handleOpenErrorModal, isCodatAccountDetailsLoading]);

  return (
    <div data-cy="codat-accounting-page">
      <ErrorModal
        errorMessage={getConnectionErrorData?.error?.message}
        description={getConnectionErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />

      {isFetching || isLoading ? (
        <div>
          <Box className="mt-10 h-28">
            <LoadingOverlay className="!relative" isLoading={true} />
          </Box>
        </div>
      ) : (
        <div className="mt-2">
          <CodatAccountConnectionHandler
            setIsLoading={setIsLoading}
            connectionData={connectionData}
            isConnectionCreated={!!connectionData?.result.isAccountingConnectionCreated}
            triggerRefetch={refetch}
            isLoading={isFetching}
            connectionStatus={connectionData?.result.connectionStatus as CONNECTION_STATUS}
          />
        </div>
      )}
    </div>
  );
};

export default CodatAccount;
