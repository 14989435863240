export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const extractMimeTypeAndDataFromBase64 = (base64String: string) => {
  const [mimeType, base64Data] = base64String.split(';base64,');
  return { mimeType: mimeType.split(':')[1], base64Data };
};

export const joinMimeTypeAndDataToBase64 = ({ data, mimeType }: { data?: string | Blob; mimeType?: string }) => {
  if (!data || !mimeType) {
    return null;
  }

  return `data:${mimeType};base64,${data}`;
};

export const base64ToBlob = (base64: string, mimeType: string): Blob => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};
