import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useGetPayPalOrdersQuery } from 'apis/paypal.api';
import { ORDERS_TABLE_COLUMNS, SEARCH_ORDERS_PLACEHOLDER } from 'constants/paypal';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { BaseFilterValues, CommonError } from 'interfaces/shared.interface';
import { ErrorModal, FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';

import CreateOrderButton from './CreateOrderButton';
import OrdersRow from './OrdersRow';

const Orders = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { filterParams, applyFilters, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const { data, isLoading, isFetching, refetch, error } = useGetPayPalOrdersQuery(
    `${filterParams}${sortingParams}${paginationParams}`
  );

  const { result } = data || {};
  const { error: errorData } = (error as CommonError)?.data || {};

  const ordersFormMethods = useForm<BaseFilterValues>();
  const onSubmitFilters = (data: BaseFilterValues) => {
    const filter = data.filter;
    const newFiltersToApply = {
      searchQuery: filter,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  useEffect(() => {
    if (error) {
      handleOpenErrorModal();
    }
  }, [error, handleOpenErrorModal]);

  return (
    <div data-cy="order-line-items">
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />

      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <FormProvider {...ordersFormMethods}>
          <form onSubmit={ordersFormMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="filtering-input"
              withFilter={false}
              placeHolder={SEARCH_ORDERS_PLACEHOLDER}
              rightTopActionButton={<CreateOrderButton />}
            />
          </form>
        </FormProvider>

        <Table
          dataCy="orders-table"
          columns={ORDERS_TABLE_COLUMNS}
          isTableEmpty={!result?.totalCount}
          isLoading={isLoading}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          sortingType={sortingType}
        >
          {result?.orders.map((item) => <OrdersRow key={item.id} ordersData={item} />)}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Orders;
