import { useTranslation } from 'react-i18next';

import { SubscriptionsWithFeatures } from 'interfaces/subscriptions-tenant.interface';
import { formatDataCy } from 'utils/global';

import SubscriptionButtonModalControls from './SubscriptionButtonModalControls';
import SubscriptionFeature from './SubscriptionFeature';
import SubscriptionPlans from './SubscriptionPlans';

interface Props {
  subscription: SubscriptionsWithFeatures;
  allFeaturesLookup?: Record<string, string>;
  isLoading: boolean;
}

export const SubscriptionCard = ({ subscription, allFeaturesLookup, isLoading }: Props) => {
  const { t } = useTranslation();

  const { displayName, subscriptionPlans } = subscription.subscription;

  return (
    <>
      <div
        data-cy={formatDataCy(displayName)}
        className="flex flex-col h-full dark:bg-darkBlue14 shadow-md bg-white rounded-md mx-5"
      >
        <div className="flex-grow min-h-[50%]">
          <h4 className="mb-1 bg-blue5 w-full text-center rounded-t-md py-2 font-bold text-lg text-white">
            {displayName}
          </h4>
          <SubscriptionPlans
            plans={subscriptionPlans}
            isLoading={isLoading}
            isFree={subscription.subscription.isFree}
          />
          {subscriptionPlans.some((plan) => plan.price) && (
            <div className="flex flex-row text-blue5 text-sm px-4">{t('ExcludingVat')}</div>
          )}
        </div>
        <div className="border-t border-gray8 h-0"></div>
        <div className="flex flex-col my-3 mx-4 flex-grow min-h-[20%]">
          <SubscriptionFeature
            featureValues={subscription.featureValues}
            allFeaturesLookup={allFeaturesLookup}
            isLoading={isLoading}
          />
        </div>

        <SubscriptionButtonModalControls
          subscription={subscription}
          isLoading={isLoading}
          allFeaturesLookup={allFeaturesLookup}
        />
      </div>
    </>
  );
};

export default SubscriptionCard;
