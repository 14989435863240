import { useEffect, useMemo, useState } from 'react';

import { Layout, Layouts } from 'react-grid-layout';

import { useGetDashboardLayoutQuery } from 'apis';
import { DASHBOARD_GRID_BREAKPOINTS, DashboardWidgetItems } from 'constants/dashboard';
import { DashboardPage } from 'interfaces/dashboard.interface';
import { convertDashboardToGridLayout, getDashboardName } from 'utils/dashboard';

import useUserRole from './useUserRole';

export const useDashboardLayout = (onDeleteAction: VoidFunction, onConfirmDelete: VoidFunction) => {
  const { isAdmin } = useUserRole();
  const dashboardName = getDashboardName(isAdmin);

  const { data: dashboardLayoutData } = useGetDashboardLayoutQuery(dashboardName);
  const dashboardLayout = dashboardLayoutData?.result;

  const gridBreakPoints = useMemo(() => {
    return DASHBOARD_GRID_BREAKPOINTS(isAdmin);
  }, [isAdmin]);

  const [layouts, setLayouts] = useState<Layouts>(gridBreakPoints);
  const [page, setCurrentPage] = useState<DashboardPage | null>();
  const [widgetId, setWidgetId] = useState<string | null>();

  const handlePageChange = (page: DashboardPage) => {
    const mdLayouts = page.widgets?.map(convertDashboardToGridLayout) ?? [];

    setCurrentPage(page);
    setLayouts((prevLayout) => ({ ...prevLayout, md: mdLayouts }));
  };

  const handleLayoutChange = (_: Layout[], allLayouts: Layouts) => {
    setLayouts({ ...allLayouts });
  };

  const handleDeleteWidget = (widgetId: DashboardWidgetItems) => () => {
    onDeleteAction();
    setWidgetId(widgetId);
  };

  const handleConfirmDelete = () => {
    setLayouts((prevLayouts) => ({
      ...prevLayouts,
      md: prevLayouts.md?.filter((layout: Layout) => layout.i !== widgetId),
    }));
    setWidgetId(null);
    onConfirmDelete();
  };

  useEffect(() => {
    const pages = dashboardLayout?.pages;

    if (!pages?.length) {
      return;
    }

    const currentPage = pages?.find(({ id }) => page?.id === id) ?? pages[0];

    handlePageChange(currentPage);
  }, [dashboardLayout]);

  return {
    page,
    layouts,
    dashboardLayout,
    widgetId,
    handlePageChange,
    handleLayoutChange,
    handleDeleteWidget,
    handleConfirmDelete,
  };
};
