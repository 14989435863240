import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from 'utils/global';

import { Button } from './index';

interface Props {
  onClickIncrement: VoidFunction;
  onClickDecrement: VoidFunction;
  disabled?: boolean;
  arrowButtonArrowClassName?: string;
  arrowButtonBodyClassName?: string;
}

const ArrowButtons = ({
  onClickIncrement,
  onClickDecrement,
  disabled,
  arrowButtonArrowClassName,
  arrowButtonBodyClassName,
}: Props) => {
  const sharedButtonStyling = 'h-5 rounded-s-none w-8 pt-1 flex justify-center';
  return (
    <div className={cn('h-10 w-8', arrowButtonBodyClassName)}>
      <Button
        disabled={disabled}
        className={cn('rounded-b-none', sharedButtonStyling, arrowButtonArrowClassName)}
        type="button"
        onClick={onClickIncrement}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </Button>
      <Button
        disabled={disabled}
        className={cn('rounded-t-none', sharedButtonStyling, arrowButtonArrowClassName)}
        type="button"
        onClick={onClickDecrement}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </Button>
    </div>
  );
};

export default ArrowButtons;
