import { BaseWysiwygValues, CommonResponse, OptionsTypeValue } from './shared.interface';

export interface EmailTemplate {
  id: number;
  templateName: string;
  subject: string;
  body: string;
  creationTime: string;
  variableNames: string[];
}
export interface EmailTemplatesResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: EmailTemplate[];
  };
}

export interface EmailTemplateByIdResponse extends CommonResponse {
  result: EmailTemplate;
}

export enum EMAIL_TEMPLATES_COLUMNS {
  TEMPLATE_NAME = 'templateName',
  CREATION_TIME = 'creationTime',
  ACTIONS = 'actions',
}

export enum SHARED_EMAIL_TEMPLATES_FIELDS_NAMES {
  SUBJECT = 'subject',
}

export enum CREATE_EMAIL_TEMPLATES_FIELDS_NAMES {
  TEMPLATE_NAME = 'templateName',
}

export interface EmailTemplateFormValues extends BaseWysiwygValues {
  [CREATE_EMAIL_TEMPLATES_FIELDS_NAMES.TEMPLATE_NAME]: string;
  [SHARED_EMAIL_TEMPLATES_FIELDS_NAMES.SUBJECT]: string;
}

export interface EmailTemplateFormPayload {
  templateName: string;
  subject: string;
  body: string;
  variableNames: string[];
}

export interface EditEmailTemplatePayload extends EmailTemplateFormPayload {
  id?: string;
}

export enum SEND_EMAIL_TEMPLATE_FIELDS_NAMES {
  RECIPIENTS = 'recipient',
}

export interface SendEmailTemplateFormValues extends BaseWysiwygValues {
  [SHARED_EMAIL_TEMPLATES_FIELDS_NAMES.SUBJECT]: string;
  [SEND_EMAIL_TEMPLATE_FIELDS_NAMES.RECIPIENTS]: OptionsTypeValue[];
}

export interface AllUserEmailsResponse extends CommonResponse {
  result: string[];
}

export interface SendCustomEmailPayload {
  subject: string;
  body: string;
  userEmails: string[];
}

export interface TemplateVariablesValues {
  id: number;
  value: string;
}
