import { useCallback, useState } from 'react';

export const usePagination = () => {
  const [maxResultCount, setMaxResultCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [skipCount, setSkipCount] = useState(0);

  const handleSetMaxResultCount = useCallback((maxResultCount: number) => {
    setMaxResultCount(maxResultCount);
    setSkipCount(0);
    setCurrentPage(1);
  }, []);

  const handleSetCurrentPage = useCallback(
    (currentPage: number) => {
      setCurrentPage(currentPage);
      setSkipCount((currentPage - 1) * maxResultCount);
    },
    [maxResultCount]
  );

  const paginationParams = `MaxResultCount=${maxResultCount}&SkipCount=${skipCount}`;

  const customPaginationParams = `Take=${maxResultCount}&Skip=${skipCount}`;

  const quickBookPaginationParams = `Skip=${skipCount}&Take=${maxResultCount}`;

  return {
    maxResultCount,
    handleSetMaxResultCount,
    paginationParams,
    customPaginationParams,
    quickBookPaginationParams,
    currentPage,
    handleSetCurrentPage,
  };
};
