import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NO_DATA } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { sharedBodyStyle, sharedLabelStyle, sharedSkeletonStyle, sharedValueStyle } from 'constants/sage';
import { InvoiceData } from 'interfaces/integrations/sage.interface';
import { LabelWithValue } from 'shared-components';
import BackButton from 'shared-components/button/BackButton';
import { getFormattedDate } from 'utils/dates';
import { useFormatPriceFunction } from 'utils/global';

interface Props {
  data?: InvoiceData;
  isLoading?: boolean;
}

const InvoiceInformationBlock = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const informationLabelValueMap = {
    Reference: [data?.reference, 'invoice-information-block-reference'],
    IssueDate: [getFormattedDate(data?.issueDate), 'invoice-information-block-issue-date'],
    NetAmount: [useFormatPriceFunction(data?.netAmount || 0), 'invoice-information-block-net-amount'],
    InvoiceNumber: [data?.invoiceNumber, 'invoice-information-block-invoice-number'],
    DueDate: [getFormattedDate(data?.dueDate), 'invoice-information-block-due-date'],
    TaxAmount: [useFormatPriceFunction(data?.taxAmount || 0), 'invoice-information-block-tax-amount'],
    ContactName: [data?.contactName, 'invoice-information-block-contact-name'],
    CreatedAt: [getFormattedDate(data?.createdAt), 'invoice-information-block-created-at'],
    TotalAmount: [useFormatPriceFunction(data?.totalAmount || 0), 'invoice-information-block-total-amount'],
    Status: [data?.status?.name, 'invoice-information-block-name'],
    UpdatedAt: [getFormattedDate(data?.updatedAt), 'invoice-information-block-updated-at'],
    TotalPaid: [useFormatPriceFunction(data?.totalPaid || 0), 'invoice-information-block-total-paid'],
    MainAddress: [data?.mainAddress, 'invoice-information-block-main-address'],
    IsSent: [t(data?.isSent ? 'Yes' : 'No'), 'invoice-information-block-is-sent'],
    TotalDiscountAmount: [
      useFormatPriceFunction(data?.totalDiscountAmount || 0),
      'invoice-information-block-total-discount-amount',
    ],
    DeliveryAddress: [data?.deliveryAddress, 'invoice-information-block-deliver-address'],
    IsSentByEmail: [t(data?.isSentByEmail ? 'Yes' : 'No'), 'invoice-information-block-is-sent-by-email'],
  };

  return (
    <>
      <div data-cy="information-section">
        <div>
          <BackButton onClick={() => navigate(ROUTES.sage)} />
        </div>
        <div className="grid pb-3 md:grid-cols-3 flex-row break-all sm:grid-cols-2">
          {Object.entries(informationLabelValueMap).map(([label, [value, dataCy]]) => (
            <LabelWithValue
              bodyClassName={sharedBodyStyle}
              key={label}
              labelClassName={sharedLabelStyle}
              valueClassName={sharedValueStyle}
              label={label}
              value={value || NO_DATA}
              isLoading={isLoading}
              skeletonClassName={sharedSkeletonStyle}
              dataCy={dataCy}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default InvoiceInformationBlock;
