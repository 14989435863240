import { useEffect, useState } from 'react';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EMAIL_TEMPLATE_FORM_FIELDS } from 'constants/email-templates';
import { useOpen } from 'hooks/useOpen';
import { useTemplateVariables } from 'hooks/useTemplateVariables';
import { EmailTemplate, EmailTemplateFormValues, TemplateVariablesValues } from 'interfaces/email-templates.interface';
import { FormContent, InfoModal, TextEditor, ToggleSwitch } from 'shared-components';

import TemplateVariables from './TemplateVariables';
import { BottomButtonsContainer } from '../BottomButtonsContainer';

interface Props {
  isLoading: boolean;
  onSubmit: (data: EmailTemplateFormValues, fields: TemplateVariablesValues[]) => Promise<void>;
  formData?: EmailTemplate;
}
const EmailTemplateForm = ({ isLoading, onSubmit, formData }: Props) => {
  const { t } = useTranslation();

  const [isSwitchEnabled, setIsSwitchEnabled] = useState(false);

  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const { fields, addNewField, handleInputChange, deleteField, copyToClipboard, setFields } = useTemplateVariables();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useFormContext<EmailTemplateFormValues>();

  const handleOnSubmit = (data: EmailTemplateFormValues, fields: { id: number; value: string }[]) => {
    if (!isSwitchEnabled) {
      onSubmit(data, []);
      return;
    }

    const duplicateFields = fields.filter((field, index) => fields.findIndex((f) => f.value === field.value) !== index);

    if (!!duplicateFields.length) {
      handleOpenWarningModal();
      return;
    }

    onSubmit(data, fields);
  };

  useEffect(() => {
    if (!formData) {
      return;
    }

    reset({
      textEditor: formData.body,
      subject: formData.subject,
      templateName: formData.templateName,
    });

    if (!!formData?.variableNames?.length) {
      setIsSwitchEnabled(true);
      formData.variableNames.forEach((value, index) => {
        setFields((prev) => [...prev, { id: index, value }]);
      });
    }
  }, [reset, formData, setFields]);
  return (
    <>
      <InfoModal
        infoDescription={'EmailTemplateVaribaleNamesShouldBeUnique'}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
      />

      <form
        onSubmit={handleSubmit((data) => handleOnSubmit(data, fields))}
        className="bg-white p-6 shadow-md dark:bg-darkBlue"
      >
        <FormContent isLoading={isLoading} fields={EMAIL_TEMPLATE_FORM_FIELDS} register={register} errors={errors} />

        {isSwitchEnabled && (
          <TemplateVariables
            handleInputChange={handleInputChange}
            addNewField={addNewField}
            fields={fields}
            copyToClipboard={copyToClipboard}
            deleteField={deleteField}
          />
        )}

        <TextEditor isLoading={isLoading} className="mt-12">
          <div className="flex p-4 items-center border border-b-0 bg-lightGray7 border-lightGray2 rounded-t dark:bg-darkBlue4 dark:border-darkBlue4">
            <div className="rounded-full bg-yellow-500 text-[13px] font-semibold p-1 mr-2 dark:text-white dark:bg-yellow-600">
              <>{t('EnableVariables')}</>
              <FontAwesomeIcon className="ml-1" icon={faExclamationTriangle} />
            </div>
            <ToggleSwitch
              dataCy="email-template-form-switch"
              isEnabled={isSwitchEnabled}
              onToggle={() => setIsSwitchEnabled(!isSwitchEnabled)}
            />
          </div>
        </TextEditor>
        <BottomButtonsContainer isLoading={isLoading} />
      </form>
    </>
  );
};

export default EmailTemplateForm;
