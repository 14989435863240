import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ROUTES } from 'constants/routes';
import { Button } from 'shared-components';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div id="error-page">
      <h1>{t('NotFoundTitle')}</h1>
      <p>{t('UnexpectedError')}</p>
      <Button onClick={() => navigate(ROUTES.dashboard)}>{t('RedirectToDashboard')}</Button>
    </div>
  );
};

export default NotFound;
