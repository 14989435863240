import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NO_DATA } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { sharedBodyStyle, sharedLabelStyle, sharedSkeletonStyle, sharedValueStyle } from 'constants/sage';
import { ContactData } from 'interfaces/integrations/sage.interface';
import { LabelWithValue } from 'shared-components';
import BackButton from 'shared-components/button/BackButton';
import { getFormattedDate } from 'utils/dates';
import { cn, useFormatPriceFunction } from 'utils/global';

interface Props {
  data?: ContactData;
  isLoading?: boolean;
}

const ContactInformationBlock = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mainContactPersonData = data?.mainContactPerson;
  const bankAccountDetailsData = data?.bankAccountDetails;

  const informationTopLeftLabelValueMap = {
    Name: [data?.name, 'contact-information-block-name'],
    Reference: [data?.reference, 'contact-information-block-reference'],
    Type: [t(data?.contactTypes?.map((item) => item.name) || NO_DATA), 'contact-information-block-type'],
    Status: [t(data?.isActive ? 'Active' : 'NotActive'), 'contact-information-status'],
  };

  const informationTopMiddleLabelValueMap = {
    MainAddress: [data?.mainAddress, 'contact-information-block-main-address'],
    DeliveryAddress: [data?.deliveryAddress, 'contact-information-block-deliver-address'],
    EmailAddress: [data?.email, 'contact-information-block-email', 'break-all'],
    Balance: [useFormatPriceFunction(data?.balance || 0), 'contact-information-block-balance'],
  };

  const informationTopRightLabelValueMap = {
    RegistrationNumber: [data?.registrationNumber, 'contact-information-block-registration-number'],
    TaxNumber: [data?.taxNumber, 'contact-information-block-tax-number'],
    CreatedAt: [getFormattedDate(data?.createdAt), 'contact-information-block-created-at'],
    UpdatedAt: [getFormattedDate(data?.updatedAt), 'contact-information-block-updated-ad'],
  };

  const informationMainContactValueMap = {
    Name: [mainContactPersonData?.name, 'contact-information-block-main-contact-name'],
    JobTitle: [mainContactPersonData?.jobTitle, 'contact-information-block-main-contact-job-title'],
    Telephone: [mainContactPersonData?.telephone, 'contact-information-block-main-contact-telephone'],
    Mobile: [mainContactPersonData?.mobile, 'contact-information-block-main-contact-mobile'],
    EmailAddress: [mainContactPersonData?.email, 'contact-information-block-main-contact-email', 'break-all'],
  };

  const informationBankAccountDetailsValueMap = {
    AccountName: [bankAccountDetailsData?.accountName, 'contact-information-block-bank-account-account-name'],
    AccountNumber: [bankAccountDetailsData?.accountNumber, 'contact-information-block-bank-account-account-number'],
    SortCodeLower: [bankAccountDetailsData?.sortCode, 'contact-information-block-bank-account-sort-code'],
    BIC: [bankAccountDetailsData?.bic, 'contact-information-block-bank-account-bic'],
    IBAN: [bankAccountDetailsData?.iban, 'contact-information-block-bank-account-iban'],
  };

  return (
    <>
      <div data-cy="information-section">
        <div>
          <BackButton onClick={() => navigate(ROUTES.sage)} />
        </div>
        <div className="block w-full">
          <div className="grid pb-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="flex flex-col">
              {Object.entries(informationTopLeftLabelValueMap).map(([label, [value, dataCy, valueClassName]]) => (
                <LabelWithValue
                  bodyClassName={sharedBodyStyle}
                  key={label}
                  labelClassName={sharedLabelStyle}
                  valueClassName={cn(sharedValueStyle, valueClassName)}
                  label={label}
                  value={value || NO_DATA}
                  isLoading={isLoading}
                  skeletonClassName={sharedSkeletonStyle}
                  dataCy={dataCy}
                />
              ))}
            </div>
            <div className="flex flex-col">
              {Object.entries(informationTopMiddleLabelValueMap).map(([label, [value, dataCy, valueClassName]]) => (
                <LabelWithValue
                  bodyClassName={sharedBodyStyle}
                  key={label}
                  labelClassName={sharedLabelStyle}
                  valueClassName={cn(sharedValueStyle, valueClassName)}
                  label={label}
                  value={value || NO_DATA}
                  isLoading={isLoading}
                  skeletonClassName={sharedSkeletonStyle}
                  dataCy={dataCy}
                />
              ))}
            </div>
            <div className="flex flex-col">
              {Object.entries(informationTopRightLabelValueMap).map(([label, [value, dataCy, valueClassName]]) => (
                <LabelWithValue
                  bodyClassName={sharedBodyStyle}
                  key={label}
                  labelClassName={sharedLabelStyle}
                  valueClassName={cn(sharedValueStyle, valueClassName)}
                  label={label}
                  value={value || NO_DATA}
                  isLoading={isLoading}
                  skeletonClassName={sharedSkeletonStyle}
                  dataCy={dataCy}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="block w-full">
          <div className="grid pb-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="flex flex-col">
              <p className="pt-2 pb-6 w-full">{t('MainContactPerson')}</p>
              {Object.entries(informationMainContactValueMap).map(([label, [value, dataCy, valueClassName]]) => (
                <LabelWithValue
                  bodyClassName={sharedBodyStyle}
                  key={label}
                  labelClassName={sharedLabelStyle}
                  valueClassName={cn(sharedValueStyle, valueClassName)}
                  label={label}
                  value={value || NO_DATA}
                  isLoading={isLoading}
                  skeletonClassName={sharedSkeletonStyle}
                  dataCy={dataCy}
                />
              ))}
            </div>
            <div className="flex flex-col">
              <p className="pt-2 pb-6 w-full">{t('BankAccountDetails')}</p>
              {Object.entries(informationBankAccountDetailsValueMap).map(([label, [value, dataCy, valueClassName]]) => (
                <LabelWithValue
                  bodyClassName={sharedBodyStyle}
                  key={label}
                  labelClassName={sharedLabelStyle}
                  valueClassName={cn(sharedValueStyle, valueClassName)}
                  label={label}
                  value={value || NO_DATA}
                  isLoading={isLoading}
                  skeletonClassName={sharedSkeletonStyle}
                  dataCy={dataCy}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInformationBlock;
