import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { CheckboxVariants } from 'constants/checkbox';
import { Currency, getFormatPrice } from 'constants/global';
import { REGIONAL_STATS_CHART_COLORS } from 'constants/regional-stats';
import { getSharedSimplifiedLineChartOptions } from 'constants/shared-components';
import { TableVariants } from 'constants/table';
import { RegionalStatistic } from 'interfaces/regional-stats.interface';
import { Checkbox, TableData, TableRow } from 'shared-components';
import { capitalizeFirstLetter } from 'utils/global';

interface Props extends RegionalStatistic {
  index: number;
}

export const RegionalStatsTableRow = ({ countryName, sales, change, averagePrice, totalPrice, index }: Props) => {
  return (
    <TableRow dataCy="regional-stats-table-row" variant={TableVariants.SECONDARY}>
      <TableData>
        <Checkbox variant={CheckboxVariants.SECONDARY} />
      </TableData>
      <TableData>{capitalizeFirstLetter(countryName)}</TableData>
      <TableData>{sales}</TableData>
      <TableData>
        <div data-cy="regional-stats-chart-container" className="w-20 h-10">
          <HighchartsReact
            containerProps={{ style: { height: '100%' } }}
            highcharts={Highcharts}
            options={getSharedSimplifiedLineChartOptions(change, REGIONAL_STATS_CHART_COLORS[index])}
          />
        </div>
      </TableData>
      <TableData>{getFormatPrice(averagePrice, Currency.USD, 2)}</TableData>
      <TableData>{getFormatPrice(totalPrice, Currency.USD, 2)}</TableData>
    </TableRow>
  );
};
