import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetSupportTicketChatDetailsQuery, useLazyGetUserChatMessagesQuery } from 'apis/support-tickets.api';
import { ROUTES } from 'constants/routes';
import { useChatContext } from 'hooks/useChatContext';
import useIsAdmin from 'hooks/useUserRole';
import { SendMessageContent, UserChatMessageData } from 'interfaces/support-tickets.interfaces';
import { useAppSelector } from 'modules/store';
import { selectTenantId } from 'modules/user/selector';
import ChatHeaderInfo from 'page-components/support-tickets/chat/ChatHeaderInfo';
import ChatMessage from 'page-components/support-tickets/chat/ChatMessage';
import ChatMessageForm from 'page-components/support-tickets/chat/ChatMessageForm';
import ChatMessageSkeleton from 'page-components/support-tickets/chat/ChatMessageSkeleton';
import AppContentLayout from 'shared-components/AppContentLayout';

const SupportTicketChat = () => {
  const { t } = useTranslation();

  const { ticketId } = useParams();
  const navigate = useNavigate();
  const context = useChatContext();
  const chatBodyRef = useRef<HTMLDivElement>(null);

  const { isAdmin } = useIsAdmin();
  const tenantId = useAppSelector(selectTenantId);

  const [messagesData, setMessagesData] = useState<UserChatMessageData[]>([]);

  const { data: chatDetailsData, isLoading: isLoadingChatDetailsData } = useGetSupportTicketChatDetailsQuery({
    ticketId: ticketId,
    isAdmin: isAdmin,
  });
  const [getUserChatMessages, { data: chatMessagesData, isLoading: isLoadingMessages }] =
    useLazyGetUserChatMessagesQuery();

  const isLoading = isLoadingChatDetailsData || isLoadingMessages;

  const { result: chatDetails } = chatDetailsData || {};
  const { result: chatMessages } = chatMessagesData || {};

  const handleSendMessage = (messageContent: SendMessageContent) => {
    if (!chatDetails?.ticketId || !chatDetails?.chatCompanionId) {
      return;
    }

    const payload = {
      ...messageContent,
      ticketId: chatDetails?.ticketId,
      userId: chatDetails?.chatCompanionId,
      tenantId: tenantId && !isAdmin ? tenantId : chatDetails?.chatCompanionTenantId,
    };

    context.sendMessage(payload);
  };

  const isResolved = chatDetails?.statusId === 3;

  useEffect(() => {
    if (!chatMessages) {
      return;
    }

    setMessagesData([...chatMessages?.items]);
  }, [chatMessages]);

  useEffect(() => {
    setMessagesData((prevState) => {
      if (!context.chatMessages) {
        return prevState;
      }

      return [...prevState, context.chatMessages];
    });
  }, [context.chatMessages]);

  useEffect(() => {
    if (!ticketId) {
      navigate(ROUTES.supportTickets);
    }

    if (chatDetails?.chatCompanionId) {
      getUserChatMessages({ userId: chatDetails?.chatCompanionId, ticketId });
    }
  }, [chatDetails?.chatCompanionId, getUserChatMessages, navigate, ticketId]);

  useEffect(() => {
    if (chatMessages?.items?.length) {
      chatBodyRef.current?.scrollTo({
        top: chatBodyRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [chatMessages?.items?.length, messagesData, isLoadingMessages, isLoadingChatDetailsData]);

  return (
    <AppContentLayout bodyClassName="py-0">
      <div data-cy="support-chat">
        <ChatHeaderInfo headerData={chatDetails} isAdmin={isAdmin} isLoading={isLoadingChatDetailsData} />

        <div className="px-6 bg-white dark:bg-darkBlue" data-cy="chat-messages">
          <div ref={chatBodyRef} className="custom-scrollbar h-[65vh] overflow-y-auto">
            {isLoading && <ChatMessageSkeleton />}
            {!isLoading && messagesData.length ? (
              messagesData.map((message) => <ChatMessage key={message.id} messageData={message} />)
            ) : (
              <div className="h-[72vh] p-3">
                <div className="w-full h-fit align-middle text-sm text-center">
                  <div className="align-middle rounded-2xl bg-lightGray4 dark:bg-darkGray3 text-darkGray dark:text-white inline p-1">
                    {t('ThereAreNoMessagesInThisChat')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {!isResolved && <ChatMessageForm isSendingMessage={context.isSendingMessage} sendMessage={handleSendMessage} />}
      </div>
    </AppContentLayout>
  );
};

export default SupportTicketChat;
