import { SUBSCRIPTION_TABS } from 'constants/subscription-tenant';
import Tabs from 'shared-components/Tabs';

const SubscriptionsTenant = () => {
  return (
    <div data-cy="subscriptions-page">
      <div className="relative">
        <Tabs tabs={SUBSCRIPTION_TABS} />
      </div>
    </div>
  );
};

export default SubscriptionsTenant;
