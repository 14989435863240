import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useEmailActivateMutation } from 'apis/auth.api';
import {
  ACTIVATE_EMAIL_PAGE_BACK_BUTTON,
  ACTIVATE_EMAIL_PAGE_SUBMIT_BUTTON,
  SEND_MAIL_SUCCESS_DESCRIPTION,
  SEND_MAIL_SUCCESS_MESSAGE,
  ACTIVATE_EMAIL_FORM_FIELDS,
} from 'constants/activate-email';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { ACTIVATE_EMAIL_FROM_FIELDS, ActivateEmailFormValues } from 'interfaces/activate-email.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Button, FormContent, ErrorModal, SuccessModal } from 'shared-components';

const ActivateEmailForm = () => {
  const navigate = useNavigate();

  const [isOpenSuccessModal, handleOnOpenSuccessModal, handleCloseSuccessModal] = useOpen();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();

  const [activateEmail, { isLoading, error }] = useEmailActivateMutation();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ActivateEmailFormValues>({ mode: 'onBlur' });

  const { error: errorData } = (error as CommonError)?.data || {};

  const closeSuccessModal = () => {
    handleCloseSuccessModal();
    navigate(ROUTES.signIn);
  };

  const onSubmit = async (data: ActivateEmailFormValues) => {
    try {
      const activateEmailResponse = await activateEmail({
        emailAddress: data[ACTIVATE_EMAIL_FROM_FIELDS.userEmail],
      }).unwrap();

      if (activateEmailResponse.success) {
        handleOnOpenSuccessModal();
      }
    } catch (error) {
      handleOnOpenErrorModal();
    }
  };

  return (
    <>
      <SuccessModal
        successMessage={t(SEND_MAIL_SUCCESS_MESSAGE)}
        description={t(SEND_MAIL_SUCCESS_DESCRIPTION)}
        isOpen={isOpenSuccessModal}
        handleClose={closeSuccessModal}
      />
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
      <form data-cy="activate-email-form" onSubmit={handleSubmit(onSubmit)} className="w-4/4 m-auto">
        <FormContent fields={ACTIVATE_EMAIL_FORM_FIELDS} register={register} errors={errors} />

        <div className="flex items-center justify-between">
          <Button isLoading={isLoading} type="button" variant={ButtonVariants.BORDERED}>
            <Link to={ROUTES.signIn} className="text-sm">
              {t(ACTIVATE_EMAIL_PAGE_BACK_BUTTON)}
            </Link>
          </Button>

          <Button isLoading={isLoading} dataCy="submit-button" type="submit">
            {t(ACTIVATE_EMAIL_PAGE_SUBMIT_BUTTON)}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ActivateEmailForm;
