import { BaseFilterValues, CommonResponse, NameValueResult, OptionsTypeValue, OptionType } from './shared.interface';

export enum SUBSCRIPTIONS_FILTERS_FORM_FIELD_NAMES {
  SELECT_STATUS = 'statusId',
}

export enum SUBSCRIPTIONS_COLUMNS {
  NAME = 'name',
  PRICE = 'price',
  WAITING_DAY_AFTER_EXPIRE = 'waitingDayAfterExpire',
  EXPIRING_SUBSCRIPTION = 'expiringSubscription',
  STATUS = 'status',
  ACTIONS = 'actions',
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'Active',
  NOT_ACTIVE = 'NotActive',
}

export enum SUBSCRIPTION_PAYMENT_STATUSES {
  CREATED = 1,
  PAID = 2,
  FAILED = 3,
  CANCELLED = 4,
  COMPLETED = 5,
}

export enum SUBSCRIPTION_PLAN_PERIODS {
  TRIAL = 1,
  UNLIMITED = 2,
  DAILY = 3,
  WEEKLY = 4,
  MONTHLY = 5,
  ANNUAL = 6,
}

interface Status {
  id: string;
  name: string;
}

export interface SharedSubscription {
  id: number;
  displayName: string;
  dailyPrice: number;
  weeklyPrice: number;
  monthlyPrice: number;
  annualPrice: number;
  waitingDayAfterExpire: string;
  expiringSubscriptionDisplayName: string;
  status: Status;
}

export interface SubscriptionsResult {
  totalCount: number;
  items: SharedSubscription[];
}

export interface SubscriptionsResponse extends CommonResponse {
  result: SubscriptionsResult;
}

export interface SubscriptionsData {
  isFree: boolean;
  value: string;
  displayText: string;
  isSelected: boolean;
}
export interface SubscriptionsGetAllResponse extends CommonResponse {
  result: SubscriptionsData[];
}

export interface SubscriptionsFilterFormValues extends BaseFilterValues {
  [SUBSCRIPTIONS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: OptionType;
}

export interface SubscriptionPayment {
  amount: number;
  creationTime: string;
  description: string;
  id: number;
  status: number;
  subscriptionName: string;
  subscriptionPlanPeriodId: number;
  tenantId: number;
}

export interface SubscriptionsPaymentHistoryResult {
  totalCount: number;
  items: SubscriptionPayment[];
}

export interface SubscriptionPaymentHistoryResponse extends CommonResponse {
  result: SubscriptionsPaymentHistoryResult;
}

export interface BaseSubscriptionsData {
  isFree: boolean;
  value: string;
  displayText: string;
  isSelected: boolean;
}
export interface BaseSubscriptionsResponse extends CommonResponse {
  result: BaseSubscriptionsData[];
}

export enum SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES {
  ANNUAL_PRICE = 'annualPrice',
  DAILY_PRICE = 'dailyPrice',
  DISPLAY_NAME = 'displayName',
  IS_PAID = 'isPaid',
  MONTHLY_PRICE = 'monthlyPrice',
  TRIAL_DAY_COUNT = 'trialDayCount',
  WAITING_DAY_AFTER_EXPIRE = 'waitingDayAfterExpire',
  WEEKLY_PRICE = 'weeklyPrice',
  ASSIGNED_SUBSCRIPTION = 'assignedSubscription',
  EXPIRING_SUBSCRIPTION_ID = 'expiringSubscriptionId',
  EXPIRING_SUBSCRIPTION = 'expiringSubscription',
  MANAGING_USERS = 'managingUsers',
  MAX_USERS_COUNT = 'maxUserCount',
  STATUS_ID = 'statusId',
}

export interface SubscriptionManagementFormValues {
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DISPLAY_NAME]: string;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID]?: boolean;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ANNUAL_PRICE]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DAILY_PRICE]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MONTHLY_PRICE]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WEEKLY_PRICE]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.TRIAL_DAY_COUNT]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WAITING_DAY_AFTER_EXPIRE]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ASSIGNED_SUBSCRIPTION]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION_ID]?: string | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION]: OptionsTypeValue | null;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS]: boolean;
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT]: string;
}

export type CreateSubscriptionFormValues = SubscriptionManagementFormValues;

export interface EditSubscriptionFormValues extends SubscriptionManagementFormValues {
  [SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.STATUS_ID]?: boolean;
}

export interface SubscriptionManagementValues {
  displayName: string;
  isPaid: boolean;
  dailyPrice: number | null;
  weeklyPrice: number | null;
  monthlyPrice: number | null;
  annualPrice: number | null;
  trialDayCount: number | null;
  waitingDayAfterExpire: number | null;
  expiringSubscriptionId: string | null;
}

export interface CreateSubscriptionPayload {
  featureValues: NameValueResult[];
  subscription: SubscriptionManagementValues;
}

export interface EditSubscriptionPayload {
  featureValues: NameValueResult[];
  subscription: SubscriptionByIdValues;
}

export interface SubscriptionByIdValues extends SubscriptionManagementValues {
  id: number;
  statusId: number;
}

export interface SubscriptionByIdResult {
  featureValues: NameValueResult[];
  subscription: SubscriptionByIdValues;
}

export interface GetSubscriptionByIdResponse extends CommonResponse {
  result: SubscriptionByIdResult;
}

export interface SubscriptionStatistic {
  value: number;
  label: string;
}

export interface SubscriptionStatistics {
  subscriptionStatistics: SubscriptionStatistic[];
}

export interface SubscriptionStatisticsResponse {
  result: SubscriptionStatistics;
}
