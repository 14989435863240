import {
  DOCUMENT_TYPE_FORM_FIELDS,
  TRAIN_DOCUMENT_COLUMNS,
  TRAIN_DOCUMENT_TYPE_FORM_FIELDS,
  TYPLESS_COLUMNS,
  TYPLESS_FILTERS_FORM_FIELD_NAMES,
} from 'interfaces/integrations/typless.interface';
import { FieldTypes, FormItem, TableColumn } from 'interfaces/shared.interface';
import { Input, InputNumberFormat, SelectFormInput } from 'shared-components';
import DatePickerInput from 'shared-components/DatePickerInput';

import { BASE_FILTER_FIELDS } from './common';

export const getTyplessFiltersFieldsDefaultValue = (selectStatusLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [TYPLESS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: { label: selectStatusLabel, value: '' },
});

export const TYPLESS_COLUMNS_TITLES = {
  [TYPLESS_COLUMNS.NAME]: 'Name',
  [TYPLESS_COLUMNS.STATUS]: 'Status',
  [TYPLESS_COLUMNS.NUMBER_OF_TRAININGS]: 'NumberOfTrainings',
  [TYPLESS_COLUMNS.CREATION_TIME]: 'CreationTime',
  [TYPLESS_COLUMNS.ACTION]: '',
};

export const TYPLESS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: TYPLESS_COLUMNS.NAME,
    title: TYPLESS_COLUMNS_TITLES[TYPLESS_COLUMNS.NAME],
    width: 'w-4/12',
    sorting: true,
  },
  {
    columnId: TYPLESS_COLUMNS.STATUS,
    title: TYPLESS_COLUMNS_TITLES[TYPLESS_COLUMNS.STATUS],
    width: 'w-3/12',
  },
  {
    columnId: TYPLESS_COLUMNS.NUMBER_OF_TRAININGS,
    title: TYPLESS_COLUMNS_TITLES[TYPLESS_COLUMNS.NUMBER_OF_TRAININGS],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: TYPLESS_COLUMNS.CREATION_TIME,
    title: TYPLESS_COLUMNS_TITLES[TYPLESS_COLUMNS.CREATION_TIME],
    width: 'w-3/12',
    sorting: true,
  },
  {
    columnId: TYPLESS_COLUMNS.ACTION,
    title: TYPLESS_COLUMNS_TITLES[TYPLESS_COLUMNS.ACTION],
  },
];

export enum DocumentStatus {
  Trained = 1,
  NotTrained = 2,
  Training = 3,
}

export const STATUS_LABELS = {
  [DocumentStatus.Trained]: 'Trained',
  [DocumentStatus.NotTrained]: 'NotTrained',
  [DocumentStatus.Training]: 'Training',
};

const STATUS_FILTERS_OPTIONS = [
  { label: 'Trained', value: '1' },
  { label: 'NotTrained', value: '2' },
  { label: 'Training', value: '3' },
];

export const SEARCH_TYPLESS_PLACEHOLDER = 'SearchByNameWithThreeDot';

export const TYPLESS_FILTER_FORM_FIELDS: FormItem[] = [
  {
    name: TYPLESS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS,
    label: 'SelectStatus',
    className: 'ml-10 w-30',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-status-select',
    component: SelectFormInput,
    options: [...STATUS_FILTERS_OPTIONS],
    isFilter: true,
  },
];

export const DOCUMENT_TYPE_FORM_FIELD = (): FormItem[] => {
  return [
    {
      name: DOCUMENT_TYPE_FORM_FIELDS.NAME,
      label: 'Name',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'document-type-name-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
  ];
};

export const DOCUMENT_TYPE_DETAILS_FORM_FIELD = (): FormItem[] => {
  return [
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_NAME,
      label: 'SupplierName',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'supplier-name-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_ADDRESS,
      label: 'SupplierAddress',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      inputClassName: 'w-full',
      dataCy: 'supplier-address-field',
      className: 'mb-4',
      component: Input,
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_EMAIL_ADDRESS,
      label: 'SupplierEmailAddress',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      inputClassName: 'w-full',
      dataCy: 'supplier-email-address-field',
      className: 'mb-4',
      component: Input,
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_PHONE_NUMBER,
      label: 'SupplierPhoneNumber',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      inputClassName: 'w-full',
      dataCy: 'supplier-phone-number-field',
      className: 'mb-4',
      component: Input,
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_NAME,
      label: 'ClientName',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'client-name-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_ADDRESS,
      label: 'ClientAddress',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      inputClassName: 'w-full',
      dataCy: 'client-address-field',
      className: 'mb-4',
      component: Input,
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_EMAIL_ADDRESS,
      label: 'ClientEmailAddress',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      inputClassName: 'w-full',
      dataCy: 'client-email-address-field',
      className: 'mb-4',
      component: Input,
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_PHONE_NUMBER,
      label: 'ClientPhoneNumber',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      inputClassName: 'w-full',
      dataCy: 'client-phone-number-field',
      className: 'mb-4',
      component: Input,
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.INVOICE_NUMBER,
      label: 'InvoiceNumber',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'invoice-number-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.ISSUED_DATE,
      label: 'IssuedDate',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.DATE,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'issued-date-field',
      className: 'mb-4',
      component: DatePickerInput,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.DUE_DATE,
      label: 'DueDate',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.DATE,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'due-date-field',
      className: 'mb-4',
      component: DatePickerInput,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.TOTAL_AMOUNT,
      label: 'TotalAmount',
      labelClassName: 'text-sm font-semibold',
      type: FieldTypes.NUMBER,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'total-amount-field',
      className: 'mb-4',
      isShownArrowButtons: true,
      component: InputNumberFormat,
      validation: {
        required: 'ThisFieldIsRequired',
      },
      decimalSeparator: '.',
      fixedDecimalLength: 0,
    },
    {
      name: TRAIN_DOCUMENT_TYPE_FORM_FIELDS.TOTAL_TAX,
      label: 'TotalTax',
      labelClassName: 'text-xs font-semibold',
      type: FieldTypes.NUMBER,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'total-tax-field',
      className: 'mb-4',
      isShownArrowButtons: true,
      component: InputNumberFormat,
      validation: {
        required: 'ThisFieldIsRequired',
      },
      decimalSeparator: '.',
      fixedDecimalLength: 0,
    },
  ];
};

export const TRAIN_DOCUMENT_COLUMNS_TITLES = {
  [TRAIN_DOCUMENT_COLUMNS.DESCRIPTION]: 'Description',
  [TRAIN_DOCUMENT_COLUMNS.QUANTITY]: 'Quantity',
  [TRAIN_DOCUMENT_COLUMNS.PRICE]: 'Price',
  [TRAIN_DOCUMENT_COLUMNS.TAX]: 'Tax',
  [TRAIN_DOCUMENT_COLUMNS.AMOUNT]: 'Amount',
};

export const TRAIN_DOCUMENT_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: TRAIN_DOCUMENT_COLUMNS.DESCRIPTION,
    title: TRAIN_DOCUMENT_COLUMNS_TITLES[TRAIN_DOCUMENT_COLUMNS.DESCRIPTION],
    width: 'w-2/12',
  },
  {
    columnId: TRAIN_DOCUMENT_COLUMNS.QUANTITY,
    title: TRAIN_DOCUMENT_COLUMNS_TITLES[TRAIN_DOCUMENT_COLUMNS.QUANTITY],
    width: 'w-2/12',
  },
  {
    columnId: TRAIN_DOCUMENT_COLUMNS.PRICE,
    title: TRAIN_DOCUMENT_COLUMNS_TITLES[TRAIN_DOCUMENT_COLUMNS.PRICE],
    width: 'w-2/12',
  },
  {
    columnId: TRAIN_DOCUMENT_COLUMNS.TAX,
    title: TRAIN_DOCUMENT_COLUMNS_TITLES[TRAIN_DOCUMENT_COLUMNS.TAX],
    width: 'w-2/12',
  },
  {
    columnId: TRAIN_DOCUMENT_COLUMNS.AMOUNT,
    title: TRAIN_DOCUMENT_COLUMNS_TITLES[TRAIN_DOCUMENT_COLUMNS.AMOUNT],
    width: 'w-2/12',
  },
];
