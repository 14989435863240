import {
  AddNewMemberPayload,
  AddNewMemberResponse,
  AddNewMembersPayload,
  AddNewRolesPayload,
  CreateRootUnitPayload,
  MoveRootUnitPayload,
  OrganizationUnitsDeleteRolesPayload,
  OrganizationUnitsDeleteUsersPayload,
  OrganizationUnitsResponse,
  OrganizationUnitsRolesPayload,
  OrganizationUnitsRolesResponse,
  OrganizationUnitsUsersPayload,
  OrganizationUnitsUsersResponse,
  UpdateOrganizationUnitPayload,
} from 'interfaces/organization-units.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const organizationUnitsApi = coreSplitApis
  .enhanceEndpoints({ addTagTypes: ['OrganizationUnits', 'OrganizationUnitsUsers', 'OrganizationUnitsRoles'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrganizationUnits: build.query<OrganizationUnitsResponse, void>({
        query: () => ({
          url: `OrganizationUnit/GetOrganizationUnits`,
          method: 'GET',
        }),
        providesTags: ['OrganizationUnits'],
      }),
      createRootUnitRequest: build.mutation<CommonResponse, CreateRootUnitPayload>({
        query: (data) => ({
          url: `OrganizationUnit/CreateOrganizationUnit`,
          method: 'POST',
          data,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits']),
      }),
      deleteOrganizationUnit: build.mutation<void, number>({
        query: (unitId) => ({
          url: `OrganizationUnit/DeleteOrganizationUnit?Id=${unitId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits', 'OrganizationUnitsUsers']),
      }),
      updateOrganizationUnit: build.mutation<CommonResponse, UpdateOrganizationUnitPayload>({
        query: (data) => ({
          url: `OrganizationUnit/UpdateOrganizationUnit`,
          method: 'PUT',
          data,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits']),
      }),
      moveRootUnitRequest: build.mutation<CommonResponse, MoveRootUnitPayload>({
        query: (data) => ({
          url: `OrganizationUnit/MoveOrganizationUnit`,
          method: 'POST',
          data,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits']),
      }),
      getOrganizationUnitsUsers: build.query<OrganizationUnitsUsersResponse, OrganizationUnitsUsersPayload>({
        query: ({ userId, params }) => ({
          url: `OrganizationUnit/GetOrganizationUnitUsers?id=${userId}&${params}`,
          method: 'GET',
        }),
        providesTags: ['OrganizationUnitsUsers'],
      }),
      deleteOrganizationUnitUser: build.mutation<void, OrganizationUnitsDeleteUsersPayload>({
        query: ({ userId, unitId }) => ({
          url: `OrganizationUnit/RemoveUserFromOrganizationUnit?UserId=${userId}&OrganizationUnitId=${unitId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits', 'OrganizationUnitsUsers']),
      }),
      findUsersRequest: build.mutation<AddNewMemberResponse, AddNewMemberPayload>({
        query: (data) => ({
          url: `OrganizationUnit/FindUsers`,
          method: 'POST',
          data,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnitsUsers']),
      }),
      addMembersRequest: build.mutation<null, AddNewMembersPayload>({
        query: (data) => ({
          url: `OrganizationUnit/AddUsersToOrganizationUnit`,
          method: 'POST',
          data,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits', 'OrganizationUnitsUsers']),
      }),
      getOrganizationUnitsRoles: build.query<OrganizationUnitsRolesResponse, OrganizationUnitsRolesPayload>({
        query: ({ roleId, params }) => ({
          url: `OrganizationUnit/GetOrganizationUnitRoles?id=${roleId}&${params}`,
          method: 'GET',
        }),
        providesTags: ['OrganizationUnitsRoles'],
      }),
      deleteOrganizationUnitRole: build.mutation<void, OrganizationUnitsDeleteRolesPayload>({
        query: ({ roleId, unitId }) => ({
          url: `OrganizationUnit/RemoveRoleFromOrganizationUnit?RoleId=${roleId}&OrganizationUnitId=${unitId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits', 'OrganizationUnitsRoles']),
      }),
      findRolesRequest: build.mutation<AddNewMemberResponse, AddNewMemberPayload>({
        query: (data) => ({
          url: `OrganizationUnit/FindRoles`,
          method: 'POST',
          data,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnitsRoles']),
      }),
      addRolesRequest: build.mutation<null, AddNewRolesPayload>({
        query: (data) => ({
          url: `OrganizationUnit/AddRolesToOrganizationUnit`,
          method: 'POST',
          data,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['OrganizationUnits', 'OrganizationUnitsRoles']),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetOrganizationUnitsQuery,
  useCreateRootUnitRequestMutation,
  useDeleteOrganizationUnitMutation,
  useUpdateOrganizationUnitMutation,
  useMoveRootUnitRequestMutation,
  useGetOrganizationUnitsUsersQuery,
  useDeleteOrganizationUnitUserMutation,
  useFindUsersRequestMutation,
  useAddMembersRequestMutation,
  useGetOrganizationUnitsRolesQuery,
  useDeleteOrganizationUnitRoleMutation,
  useFindRolesRequestMutation,
  useAddRolesRequestMutation,
} = organizationUnitsApi;
