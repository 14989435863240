import {
  ImpersonatedAuthenticateResponse,
  ImpersonateUserPayload,
  ImpersonateUserResponse,
} from 'interfaces/impersonate-user';
import { ProfileSettings, ProfileSettingsResponse } from 'interfaces/profile-settings.interface';
import { CommonResponse, GetListSharedPayload } from 'interfaces/shared.interface';
import {
  CreateUserSettingPayload,
  PersonalInfoToExcelResponse,
  ForgetMyUserRequestDetailsResponse,
} from 'interfaces/user.interface';
import { UpdateUserPermissionsData, UserPermissionsResponse, UsersResponse } from 'interfaces/users.interface';

import { coreSplitApis } from './core.api';

export const users = coreSplitApis.enhanceEndpoints({ addTagTypes: ['Users'] }).injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<UsersResponse, GetListSharedPayload>({
      query: ({ params, isAdmin }) => ({
        url: `${isAdmin ? 'UserAdmin' : 'User'}/GetUsers?${params}`,
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getUserDetails: build.query<ProfileSettings, number | void>({
      query: (userId) => ({
        url: 'User/GetUserDetails' + (userId ? `?Id=${userId}` : ''),
        method: 'GET',
      }),
      transformResponse: (response: ProfileSettingsResponse) => {
        return response.result;
      },
      keepUnusedDataFor: 0,
    }),
    getUserPermissions: build.query<UserPermissionsResponse, GetListSharedPayload>({
      query: ({ params, isAdmin }) => ({
        url: `${isAdmin ? 'UserAdmin' : 'User'}/GetUserPermissions?${params}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    updateUserPermissions: build.mutation<CommonResponse, UpdateUserPermissionsData>({
      query: ({ isAdmin, data }) => ({
        url: `${isAdmin ? 'UserAdmin' : 'User'}/UpdateUserPermissions`,
        method: 'PUT',
        data,
      }),
    }),
    createOrUpdateUser: build.mutation<CommonResponse, CreateUserSettingPayload>({
      query: (settingsData) => ({ url: `User/CreateOrUpdateUser`, method: 'POST', data: settingsData }),
    }),
    deleteUser: build.mutation<void, number>({
      query: (userId) => ({
        url: `User/DeleteUser?Id=${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Users']),
    }),
    unlockUser: build.mutation<number, { id?: number | null }>({
      query: (data) => ({ url: `User/UnlockUser`, method: 'POST', data }),
    }),
    deactivateUser: build.mutation<number, number | undefined>({
      query: (userId) => ({ url: `User/DeactivateUser` + (userId ? `?Id=${userId}` : ''), method: 'POST', userId }),
    }),
    activateUser: build.mutation<number, number | undefined>({
      query: (userId) => ({ url: `User/ActivateUser` + (userId ? `?Id=${userId}` : ''), method: 'POST', userId }),
    }),
    impersonate: build.mutation<ImpersonateUserResponse, ImpersonateUserPayload>({
      query: (data) => ({ url: 'Account/Impersonate', method: 'POST', data }),
    }),
    impersonatedAuthenticate: build.mutation<ImpersonatedAuthenticateResponse, string>({
      query: (token: string) => ({
        url: `api/TokenAuth/ImpersonatedAuthenticate?impersonationToken=${token}`,
        method: 'POST',
      }),
    }),
    backToImpersonator: build.mutation<ImpersonateUserResponse, void>({
      query: () => ({
        url: 'Account/BackToImpersonator',
        method: 'POST',
      }),
    }),
    backFromImpersonatedAuthenticate: build.mutation<ImpersonatedAuthenticateResponse, string>({
      query: (token) => ({
        url: `api/TokenAuth/BackFromImpersonatedAuthenticate?impersonationToken=${token}`,
        method: 'POST',
      }),
    }),
    exportMyPersonalInfoToExcel: build.mutation<PersonalInfoToExcelResponse, void>({
      query: () => ({
        url: `User/ExportMyPersonalInfoToExcel`,
        method: 'POST',
        responseHandler: (response: Response) => response.blob(),
      }),
    }),
    createForgetUserRequest: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `ForgetUserRequest/Create
        `,
        method: 'POST',
      }),
    }),
    getForgetMyUserRequestDetails: build.query<ForgetMyUserRequestDetailsResponse, void>({
      query: () => ({
        url: 'ForgetUserRequest/GetForgetMyUserRequestDetails',
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersQuery,
  useGetUserPermissionsQuery,
  useGetUserDetailsQuery,
  useCreateOrUpdateUserMutation,
  useDeleteUserMutation,
  useUnlockUserMutation,
  useUpdateUserPermissionsMutation,
  useDeactivateUserMutation,
  useActivateUserMutation,
  useImpersonateMutation,
  useImpersonatedAuthenticateMutation,
  useBackToImpersonatorMutation,
  useBackFromImpersonatedAuthenticateMutation,
  useExportMyPersonalInfoToExcelMutation,
  useCreateForgetUserRequestMutation,
  useGetForgetMyUserRequestDetailsQuery,
} = users;
