import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  children: ReactNode;
}

const ProfileSettingsAvatarSection = ({ title, children }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="w-full lg:w-1/2 px-2 max-w-full grow basis-0 h-full">
      <p className="py-2">{t(title)}: </p>
      {children}
    </div>
  );
};

export default ProfileSettingsAvatarSection;
