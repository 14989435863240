import {
  BeneficialOwnersResponse,
  CompaniesHouseDetailsResponse,
  CompaniesHouseSearchRequest,
  CompaniesHouseSearchResult,
  FilingHistoryResponse,
  OfficersResponse,
} from 'interfaces/integrations/companies-house.interface';

import { coreSplitApis } from './core.api';

export const companiesHouse = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    companiesHouseSearch: build.mutation<CompaniesHouseSearchResult, CompaniesHouseSearchRequest>({
      query: (data) => ({
        url: `CompaniesHouse/Search`,
        method: 'POST',
        data,
      }),
    }),
    companiesHouseDetails: build.query<CompaniesHouseDetailsResponse, string>({
      query: (params) => ({
        url: `CompaniesHouse/GetDetails?companyNumber=${params}`,
        method: 'GET',
      }),
    }),
    filingHistory: build.query<FilingHistoryResponse, string>({
      query: (params) => ({
        url: `CompaniesHouse/GetFilingHistory?CompanyNumber=${params}`,
        method: 'GET',
      }),
    }),
    officers: build.query<OfficersResponse, string>({
      query: (params) => ({
        url: `CompaniesHouse/GetOfficers?CompanyNumber=${params}`,
        method: 'GET',
      }),
    }),
    personsWithSignificantControl: build.query<BeneficialOwnersResponse, string>({
      query: (params) => ({
        url: `CompaniesHouse/GetPersonsWithSignificantControl?CompanyNumber=${params}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCompaniesHouseSearchMutation,
  useLazyCompaniesHouseDetailsQuery,
  useFilingHistoryQuery,
  useOfficersQuery,
  usePersonsWithSignificantControlQuery,
} = companiesHouse;
