import { FormProvider, useForm } from 'react-hook-form';

import { useGetAvailableTicketsQuery } from 'apis/support-tickets.api';
import { SUPPORT_TICKETS_TABLE_COLUMNS } from 'constants/support-tickets';
import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { BaseFilterValues } from 'interfaces/shared.interface';
import { FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';

import AvailableTicketsRow from './AvailableTicketsTabRow';

const AvailableTicketsTab = () => {
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const availableTicketsFormMethods = useForm<BaseFilterValues>();
  const { applyFilters, filterParams, isSameFilters } = useFilters();

  const { data, isLoading, isFetching, refetch } = useGetAvailableTicketsQuery(
    `${filterParams}${sortingParams}${paginationParams}`
  );

  const { result } = data || {};

  const onSubmitFilters = (data: BaseFilterValues) => {
    applyFilters(data);

    if (isSameFilters(applyFilters)) {
      refetch();
    } else {
      applyFilters(applyFilters);
    }
  };

  return (
    <div data-cy="available-tickets-tab" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />

      <div>
        <FormProvider {...availableTicketsFormMethods}>
          <form onSubmit={availableTicketsFormMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm dataCy="available-tickets-input" withFilter={false} placeHolder="SearchWithThreeDot" />
          </form>
        </FormProvider>
      </div>

      <Table
        dataCy="available-tickets-table"
        columns={SUPPORT_TICKETS_TABLE_COLUMNS()}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        isTableEmpty={!result?.items.length}
        sortingType={sortingType}
        isLoading={isLoading}
      >
        {result?.items.map((item) => <AvailableTicketsRow key={item.id} supportTicketsData={item} />)}
      </Table>

      <Pagination
        totalCount={result?.totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default AvailableTicketsTab;
