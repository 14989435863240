import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckMarkSvg } from 'assets/svg/check-mark.svg';
import { ReactComponent as ErrorTriangleSvg } from 'assets/svg/error-triangle.svg';
import { DETAILS_SHARED_BOX_PROPS, DETAILS_SHARED_LABEL_PROPS } from 'constants/audit-logs';
import { ButtonVariants } from 'constants/common';
import { AuditLog } from 'interfaces/audit-logs.interface';
import { useAppSelector } from 'modules/store';
import { selectLanguagesSettings } from 'modules/user/selector';
import { Box, Button, LabelWithValue } from 'shared-components';
import FormattedText from 'shared-components/FormattedText';
import { Props as LabelWithValueProps } from 'shared-components/LabelWithValue';
import { getFormattedDateLocalizedDistanceToNow } from 'utils/dates';

const renderInfoLabels = (infoLabels: Pick<LabelWithValueProps, 'label' | 'value'>[]) => {
  return infoLabels?.map((props) => (
    <LabelWithValue isLoading={false} key={props.label} displayAlways {...props} {...DETAILS_SHARED_LABEL_PROPS} />
  ));
};

type Props = {
  onClose: VoidFunction;
} & AuditLog;

const OperationLogDetailsModalContent = ({
  onClose,
  userId,
  userEmail,
  clientIpAddress,
  clientName,
  browserInfo,
  serviceName,
  methodName,
  executionTime,
  executionDuration,
  parameters,
  customData,
  exception,
}: Props) => {
  const { t } = useTranslation();

  const { currentLanguage } = useAppSelector(selectLanguagesSettings);

  const userInformation = [
    { label: 'UserId', value: userId || '-' },
    { label: 'UserEmail', value: userEmail || '-' },
    { label: 'ClientIPAddress', value: clientIpAddress || '-' },
    { label: 'ClientNameLogs', value: clientName || '-' },
    { label: 'BrowserInfo', value: browserInfo || '-' },
  ];

  const actionInformation = [
    { label: 'ServiceName', value: serviceName },
    { label: 'MethodName', value: methodName },
    { label: 'Time', value: getFormattedDateLocalizedDistanceToNow(executionTime, currentLanguage?.name) || '-' },
    { label: 'ExecutionDuration', value: `${executionDuration || '-'} ms` },
  ];

  return (
    <div className="border-t mt-3.5 pt-3.5 border-lightGray11 dark:border-white">
      <Box title="UserInformation" {...DETAILS_SHARED_BOX_PROPS}>
        {renderInfoLabels(userInformation)}
      </Box>

      <Box title="ActionInformations" {...DETAILS_SHARED_BOX_PROPS}>
        {renderInfoLabels(actionInformation)}

        <LabelWithValue label="Parameters" {...DETAILS_SHARED_BOX_PROPS}>
          <FormattedText content={parameters} shouldBeFormatted />
        </LabelWithValue>
      </Box>

      <Box dataCy="operation-logs-modal-custom-data" title="CustomData" {...DETAILS_SHARED_BOX_PROPS}>
        {customData ? (
          <LabelWithValue label="CustomData" {...DETAILS_SHARED_BOX_PROPS}>
            <FormattedText content={customData} shouldBeFormatted />
          </LabelWithValue>
        ) : (
          <p className="sm:flex-row mb-2 dark:text-gray text-sm py-1.5">{t('NoneLogs')}</p>
        )}
      </Box>

      <Box title="ErrorState" {...DETAILS_SHARED_BOX_PROPS}>
        {exception ? (
          <div data-cy="operation-logs-modal-error-state-error">
            <ErrorTriangleSvg />
            <FormattedText content={exception} />
          </div>
        ) : (
          <div data-cy="operation-logs-modal-error-state-success" className="flex items-center space-x-1 text-sm">
            <CheckMarkSvg /> <p>{t('Success')}</p>
          </div>
        )}
      </Box>

      <hr className="my-3.5 border-lightGray11" />

      <Button onClick={onClose} dataCy="modal-close-button" variant={ButtonVariants.BORDERED}>
        {t('Close')}
      </Button>
    </div>
  );
};

export default OperationLogDetailsModalContent;
