import { useTranslation } from 'react-i18next';

import { useGetDragAndDropQuery } from 'apis/samples.api';
import {
  ASYNC_AUTOCOMPLETE_DOCUMENTATION,
  ASYNC_AUTOCOMPLETE_DOCUMENTATION_USING_GOOGLE,
  BOARD_COLUMNS_NAME,
  GITHUB_TASKSBOARD_COMPONENT,
  SAMPLE_PAGE_AUTOCOMPLETE,
  SAMPLE_PAGE_AUTOCOMPLETE_USING_GOOGLE,
  SAMPLE_PAGE_DRAG_AND_DROP,
} from 'constants/samples';
import AutoCompleteUsingGoogle from 'page-components/samples/AutoCompleteUsingGoogle';
import { SamplesBlock, AutoComplete, TasksBoard } from 'page-components/samples/index';
import { LoadingOverlay } from 'shared-components';

const Samples = () => {
  const { t } = useTranslation();
  const { data, isLoading, isFetching } = useGetDragAndDropQuery();

  const tasksData = data?.result.map((item) => item.taskName) || [];

  return (
    <div data-cy="samples-page" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />

      <SamplesBlock dataCy="samples-block" link={ASYNC_AUTOCOMPLETE_DOCUMENTATION} title={t(SAMPLE_PAGE_AUTOCOMPLETE)}>
        <AutoComplete dataCy="samples-names-autocomplete" />
      </SamplesBlock>

      <SamplesBlock
        dataCy="samples-block"
        link={ASYNC_AUTOCOMPLETE_DOCUMENTATION_USING_GOOGLE}
        title={t(SAMPLE_PAGE_AUTOCOMPLETE_USING_GOOGLE)}
      >
        <AutoCompleteUsingGoogle dataCy="samples-names-autocomplete-using-google" />
      </SamplesBlock>

      <SamplesBlock dataCy="samples-block" link={GITHUB_TASKSBOARD_COMPONENT} title={t(SAMPLE_PAGE_DRAG_AND_DROP)}>
        <TasksBoard
          dataCy="samples-drag-and-drop-menu"
          columns={BOARD_COLUMNS_NAME}
          tasksData={tasksData}
          isLoading={isLoading}
        />
      </SamplesBlock>
    </div>
  );
};

export default Samples;
