import TreeView, { flattenTree, INodeRendererProps } from 'react-accessible-treeview';
import { useTranslation } from 'react-i18next';

import { BoxVariants } from 'constants/common';
import { useTreeViewSearch } from 'hooks/useTreeViewSearch';
import { UseTreeViewReturnValues } from 'hooks/useTreeViewSelect';
import { Permission } from 'interfaces/permissions.interface';
import { Input } from 'shared-components';
import Box from 'shared-components/Box';
import TreeViewItem from 'shared-components/TreeViewItem';
import { getFormattedPermissions } from 'utils/permissions';

import PermissionsTabSkeleton from './PernissionsTabSkeleton';

interface Props extends Pick<UseTreeViewReturnValues, 'handleChangeSelectedIds'> {
  permissions: Permission[];
  defaultSelectedIds?: string[];
  isLoading: boolean;
}

function NodeRenderer(props: INodeRendererProps): JSX.Element {
  return <TreeViewItem {...props} />;
}

const PermissionsTabContent = ({ permissions, handleChangeSelectedIds, defaultSelectedIds, isLoading }: Props) => {
  const {
    searchValue,
    handleSearch,
    list: permissionsList,
    initialListIds,
  } = useTreeViewSearch<Permission>({
    initialList: permissions,
    parentKey: 'parentName',
    labelKey: 'displayName',
    nameKey: 'name',
  });

  const { t } = useTranslation();

  const filteredPermissions = flattenTree({
    name: '',
    children: getFormattedPermissions({ permissions: permissionsList }),
  });
  return (
    <>
      <Input
        dataCy="permissions-search"
        className="mb-3.5"
        inputClassName="w-full"
        name="search"
        placeholder="Search"
        onChange={(e) => handleSearch(e.target.value)}
        value={searchValue}
      />
      <Box variant={BoxVariants.TRANSPARENT}>
        {isLoading && <PermissionsTabSkeleton />}
        {!isLoading && filteredPermissions?.length ? (
          <TreeView
            propagateSelect
            propagateSelectUpwards
            data={filteredPermissions}
            aria-label="create-role-permissions"
            multiSelect
            togglableSelect
            onBlur={handleChangeSelectedIds}
            defaultSelectedIds={defaultSelectedIds}
            defaultExpandedIds={(initialListIds as string[]) || defaultSelectedIds}
            nodeRenderer={NodeRenderer}
          />
        ) : (
          <div>{t('NoData')}</div>
        )}
      </Box>
    </>
  );
};

export default PermissionsTabContent;
