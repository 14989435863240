import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useResolveTicketMutation } from 'apis/support-tickets.api';
import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { SupportTickets } from 'interfaces/support-tickets.interfaces';
import { DropdownMenu, ErrorModal, TableData, TableRow, WarningModal } from 'shared-components';
import TableDataPair from 'shared-components/table/TableDataPair';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';
import { getSupportRequestChatLink } from 'utils/url';

type Props = {
  supportTicketsData: SupportTickets;
};

const AssignedToMeTabRow = ({ supportTicketsData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, supportRequesterName, supportRequesterTenantName, requestSubject, description } = supportTicketsData;
  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [resolveTicket, { error: resolveTicketError }] = useResolveTicketMutation();
  const errorData = (resolveTicketError as CommonError)?.data || {};

  const menuActions = [
    {
      dataCy: 'menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        navigate(getSupportRequestChatLink(id));
      },
    },
    {
      dataCy: 'menu-item-resolve-button',
      name: 'Resolve',
      actionOnClick: handleOpenInfoModal,
    },
  ];

  const handleResolveTicket = async () => {
    try {
      await resolveTicket(id).unwrap();
      handleCloseInfoModal();
      successNotify(t('SuccessfullyResolved'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('AreYouSureToResolveThisTicket')}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleResolveTicket}
      />
      <TableRow dataCy="assigned-to-me-table-row">
        <TableData>
          <TableDataPair labelClassName={'font-bold'} label="Name">
            {supportRequesterName}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="Tenant">
            {supportRequesterTenantName}
          </TableDataPair>
        </TableData>
        <TableData>
          <TableDataPair labelClassName={'font-bold'} label="Subject">
            {requestSubject}
          </TableDataPair>
          <TableDataPair
            dataCy="table-data-assigned-ticket-description"
            labelClassName={'font-bold'}
            label="Description"
          >
            {description}
          </TableDataPair>
        </TableData>
        <TableData>{getFormattedDate(supportTicketsData.creationTime, DAY_FORMAT_WITH_TIME)}</TableData>
        <TableData>
          <DropdownMenu elements={menuActions} />
        </TableData>
      </TableRow>
    </>
  );
};

export default AssignedToMeTabRow;
