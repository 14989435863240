import { useTranslation } from 'react-i18next';

interface Props {
  subject: string;
  chatName: string;
  message: string;
}

const ChatNotification = ({ message, subject, chatName }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="items-start text-sm font-light w-60">
      <div className="font-semibold mb-2">{t('MessageReceived')}</div>
      <div>
        {t('Subject')}: {subject}
      </div>
      <div className="truncate">
        {chatName}: {message}
      </div>
    </div>
  );
};

export default ChatNotification;
