import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { useGetSubscriptionsFeaturesQuery } from 'apis/subscriptions-tenant.api';
import { NO_DATA } from 'constants/common';
import useSubscriptionInformation from 'hooks/useSubscriptionInformation';
import { LabelWithValue, Skeleton } from 'shared-components';
import { formatDataCy } from 'utils/global';

const SubscriptionInformationIncludedSection = () => {
  const { subscriptionId } = useSubscriptionInformation();

  const { data, isLoading: isGetSubscriptionFeatureLoading } = useGetSubscriptionsFeaturesQuery(subscriptionId ?? 0);

  const { t } = useTranslation();

  const rightLabelValueMap = data?.result?.reduce(
    (map, subscription) => {
      if (subscription.value === 'false') {
        return map;
      }

      const label = subscription.displayName;

      map[label] = subscription.value || NO_DATA;
      return map;
    },
    {} as Record<string, string>
  );

  return (
    <>
      <div className="sm:min-h-full sm:min-w-0 sm:border-r dark:border-darkGray3 border-lightGray4 block border-b min-w-full min-h-0" />

      <div className="flex flex-col sm:w-1/2 w-full sm:pl-4 pl-0" data-cy="subscription-included-information">
        <h2 className="mb-5 text-sm dark:text-gray items-start sm:pt-0 pt-3.5">{t('WhatsIncluded')}</h2>
        {isGetSubscriptionFeatureLoading ? (
          <Skeleton className="w-80" />
        ) : (
          <>
            {Object.entries(rightLabelValueMap || {}).map(([label, value]) => {
              const isManagingUsers = label === t('ManagingUsers');

              return (
                <LabelWithValue
                  iconClassName="bg-transparent text-green2 flex pr-1"
                  icon={faCheckCircle}
                  bodyClassName="pr-1 flex items-center flex-row"
                  key={label}
                  labelClassName="dark:text-white"
                  valueClassName="dark:text-white"
                  withColon={isManagingUsers ? false : true}
                  label={label}
                  value={isManagingUsers ? ' ' : value || NO_DATA}
                  isLoading={isGetSubscriptionFeatureLoading}
                  skeletonClassName="w-40"
                  dataCy={formatDataCy(label)}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default SubscriptionInformationIncludedSection;
