import { Tenant, TenantDetailsResponse, TenantsResponse } from 'interfaces/tenants.interface';
import { GetTenantResponse } from 'interfaces/users.interface';

import { coreSplitApis } from './core.api';

export const tenantsApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['Tenants', 'GetTenant'] }).injectEndpoints({
  endpoints: (build) => ({
    getTenants: build.query<TenantsResponse, string>({
      query: (params) => ({
        url: `Tenant/GetAllPaged?${params}`,
        method: 'GET',
      }),
      providesTags: ['Tenants'],
    }),
    deleteTenant: build.mutation<void, number>({
      query: (tenantId) => ({
        url: `Tenant/Delete?Id=${tenantId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Tenants']),
    }),
    getTenantId: build.query<GetTenantResponse, number | string>({
      query: (tenantId) => ({
        url: `Tenant/Get?Id=${tenantId}`,
        method: 'GET',
      }),
    }),
    getTenantDetails: build.query<TenantDetailsResponse, number | string>({
      query: (tenantId) => ({
        url: `Tenant/GetDetails?tenantId=${tenantId}`,
        method: 'GET',
      }),
      providesTags: ['GetTenant'],
    }),
    updateTenant: build.mutation<void, Pick<Tenant, 'name' | 'subscriptionId' | 'id' | 'isActive'>>({
      query: (data) => ({
        url: `Tenant/UpdateDetails`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Tenants', 'GetTenant']),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTenantsQuery,
  useDeleteTenantMutation,
  useGetTenantIdQuery,
  useGetTenantDetailsQuery,
  useUpdateTenantMutation,
} = tenantsApi;
