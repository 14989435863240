export const MEMBER_ACTIVITY_COLUMNS = [
  {
    columnId: 'member',
    title: 'MEMBER',
  },
  {
    columnId: 'earnings',
    title: 'Earnings',
  },
  {
    columnId: 'cases',
    title: 'CASES',
  },
  {
    columnId: 'closed',
    title: 'CLOSED ',
  },
  {
    columnId: 'rate',
    title: 'RATE',
  },
];
