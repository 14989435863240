import { useTranslation } from 'react-i18next';

import { Button } from 'shared-components';

import TemplateInstructions from './TemplateInstructions';
import TemplateVariableField from './TemplateVariableField';

interface Props {
  fields: { id: number; value: string }[];
  addNewField: VoidFunction;
  handleInputChange: (id: number, value: string) => void;
  copyToClipboard: (value: string) => void;
  deleteField: (id: number) => void;
}

const TemplateVariables = ({ fields, deleteField, addNewField, copyToClipboard, handleInputChange }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mt-12 grid grid-cols-2 gap-y-2 gap-x-3.5 max-lg:grid-cols-1" data-cy="template-variables">
      <div className="text-base text-darkGray col-span-2 max-lg:row-start-2 dark:text-white">{t('EmailVariables')}</div>
      <div className="w-full mr-4 col-span-1 max-lg:row-start-3">
        <div className="w-full border border-lightGray4 rounded-sm bg-lightGray3 p-4 dark:bg-darkBlue4 dark:border-none">
          <Button type="button" onClick={addNewField} dataCy="add-new-button">
            {t('New')}
          </Button>
        </div>
        <div className="p-4 font-semibold text-darkGray text-sm border-b border-gray6 dark:text-white dark:border-none">
          {t('Name')}
        </div>
        {fields.length > 0 ? (
          fields.map((field, index) => (
            <TemplateVariableField
              index={index}
              key={field.id}
              field={field}
              value={field.value}
              onChange={handleInputChange}
              onClickCopy={copyToClipboard}
              onClickDelete={deleteField}
            />
          ))
        ) : (
          <div className="flex justify-center text-sm pt-4 text-darkGray dark:text-white">{t('NoData')}</div>
        )}
      </div>

      <TemplateInstructions className="col-span-1 max-lg:row-start-1" />
    </div>
  );
};

export default TemplateVariables;
