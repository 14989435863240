import { IconDefinition, faCircleInfo, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useGetOrganizationUnitsQuery } from 'apis/organization-units.api';
import { useOpen } from 'hooks/useOpen';
import { SelectedUnitInfo } from 'interfaces/organization-units.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Button } from 'shared-components';
import { cn } from 'utils/global';

import { CreateRootUnitModal, OrganizationTree } from '.';

interface Props {
  dataCy?: string;
  className: string;
  setSelectedUnit: (unit: SelectedUnitInfo) => void;
  selectedUnit: SelectedUnitInfo | null;
}

const OrganizationTreeBlock = ({ dataCy, className, setSelectedUnit, selectedUnit }: Props) => {
  const { t } = useTranslation();
  const { refetch } = useGetOrganizationUnitsQuery();
  const [isOpenCreateRootUnitModal, handleOpenCreateRootUnitModal, handleCloseCreateRootUnitModal] = useOpen();
  const permissions = useAppSelector(selectUserPermissions);

  return (
    <>
      <CreateRootUnitModal
        handleClose={handleCloseCreateRootUnitModal}
        isOpen={isOpenCreateRootUnitModal}
        refetchListRoot={refetch}
        data-cy="create-unit-modal"
      />

      <div
        data-cy={dataCy}
        className={cn(
          'w-full bg-white dark:bg-darkBlue rounded-t-md lg:rounded-r-none lg:rounded-l-md p-6 shadow-centerLight',
          className
        )}
      >
        <h2 className="text-2xl mb-2 ">{t('OrganizationTree')}</h2>
        <div className="md:flex lg:block xl:flex justify-between items-center">
          <p className="lg:w-2/3 text-sm">
            <FontAwesomeIcon icon={faCircleInfo as IconDefinition} className="text-blue8 mr-1" />
            {t('OrganizationUnitsTreeInfo')}
          </p>
          {permissions[Permissions.PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_ORGANIZATION_TREE] && (
            <Button
              type="button"
              className="mt-3 w-auto xl:mt-0 h-auto"
              onClick={handleOpenCreateRootUnitModal}
              dataCy="add-root-unit-button"
            >
              <FontAwesomeIcon icon={faPlus as IconDefinition} size="1x" className="text-white mr-1" />
              {t('AddRootUnit')}
            </Button>
          )}
        </div>
        <div className="mt-4 p-4 text-base border-1 dark:border-1 border-darkGray11 dark:border-darkBlue4 dark:bg-darkBlue4 rounded-sm text-center">
          <OrganizationTree setSelectedUnit={setSelectedUnit} selectedUnit={selectedUnit} />
        </div>
      </div>
    </>
  );
};

export default OrganizationTreeBlock;
