import {
  ADD_NEW_MEMBER_COLUMNS,
  MEMBERS_COLUMNS,
  ORGANIZATION_UNITS_REQUEST_FORM_FIELDS,
  ROLES_COLUMNS,
} from 'interfaces/organization-units.interface';
import { FieldTypes, FormItem, TabItem, TableColumn } from 'interfaces/shared.interface';
import MembersTab from 'page-components/organization-units/members-tab/MembersTab';
import RolesTab from 'page-components/organization-units/roles-tab/RolesTab';
import { Input } from 'shared-components';

export const ORGANIZATION_UNITS_TABS: TabItem[] = [
  {
    dataCy: 'members-tab-button',
    id: 'members',
    title: 'Members',
    component: MembersTab,
  },
  {
    dataCy: 'roles-tab-button',
    id: 'roles',
    title: 'Roles',
    component: RolesTab,
  },
];

export const TENANT_SUPPORT_REQUEST_FIELDS: FormItem[] = [
  {
    name: ORGANIZATION_UNITS_REQUEST_FORM_FIELDS.DISPLAY_NAME,
    label: 'Name',
    type: FieldTypes.TEXT,
    required: true,
    inputClassName: 'w-full text-sm',
    labelClassName: 'text-sm',
    dataCy: 'organization-unit-name-field',
    className: 'mb-4',
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
];

export const TENANT_ORGANIZATION_UNIT_FIELDS: FormItem[] = [
  {
    name: ORGANIZATION_UNITS_REQUEST_FORM_FIELDS.DISPLAY_NAME,
    label: 'Name',
    type: FieldTypes.TEXT,
    inputClassName: 'w-full text-sm',
    labelClassName: 'text-sm',
    dataCy: 'organization-unit-name-field',
    className: 'mb-4',
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
];

export const MEMBERS_COLUMNS_TITLES = {
  [MEMBERS_COLUMNS.DELETE]: 'Delete',
  [MEMBERS_COLUMNS.USER_NAME]: 'UserName',
  [MEMBERS_COLUMNS.ADDITION_TIME]: 'AddedTime',
};

export const MEMBERS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: MEMBERS_COLUMNS.DELETE,
    title: MEMBERS_COLUMNS_TITLES[MEMBERS_COLUMNS.DELETE],
    width: 'w-3/12',
  },
  {
    columnId: MEMBERS_COLUMNS.USER_NAME,
    title: MEMBERS_COLUMNS_TITLES[MEMBERS_COLUMNS.USER_NAME],
    width: 'w-5/12',
    sorting: true,
  },
  {
    columnId: MEMBERS_COLUMNS.ADDITION_TIME,
    title: MEMBERS_COLUMNS_TITLES[MEMBERS_COLUMNS.ADDITION_TIME],
    width: 'w-4/12',
    sorting: true,
  },
];

export const MEMBERS_COLUMNS_MODAL_TITLES = {
  [ADD_NEW_MEMBER_COLUMNS.NAME]: 'Name',
};

export const ADD_NEW_MEMBER_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: ADD_NEW_MEMBER_COLUMNS.NAME,
    title: MEMBERS_COLUMNS_MODAL_TITLES[ADD_NEW_MEMBER_COLUMNS.NAME],
    width: 'w-9/12',
  },
];

export const ADD_MEMBER_REQUEST_FIELDS: FormItem[] = [
  {
    name: ORGANIZATION_UNITS_REQUEST_FORM_FIELDS.DISPLAY_NAME,
    type: FieldTypes.TEXT,
    required: true,
    inputClassName: 'w-full text-sm',
    labelClassName: 'text-sm',
    dataCy: 'organization-unit-name-field',
    className: 'mb-3.5 flex-grow',
    placeholder: 'SearchWithThreeDot',
    component: Input,
  },
];

export const ROLES_COLUMNS_TITLES = {
  [ROLES_COLUMNS.DELETE]: 'Delete',
  [ROLES_COLUMNS.ROLE]: 'Role',
  [ROLES_COLUMNS.ADDITION_TIME]: 'AddedTime',
};

export const ROLES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: ROLES_COLUMNS.ROLE,
    title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.ROLE],
    width: 'w-5/12',
    sorting: true,
  },
  {
    columnId: ROLES_COLUMNS.ADDITION_TIME,
    title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.ADDITION_TIME],
    width: 'w-6/12',
    sorting: true,
  },
];
