import { useNavigate } from 'react-router-dom';

import { AccountsData } from 'interfaces/integrations/truelayer.interface';
import { DropdownMenu, TableData, TableRow } from 'shared-components';
import { capitalizeFirstLetter } from 'utils/global';
import { getTrueLayerAccountDetails } from 'utils/url';

type Props = {
  accountsData: AccountsData;
};

const AccountsRow = ({ accountsData }: Props) => {
  const navigate = useNavigate();

  const { name, type, currency, id } = accountsData;

  const menuActions = [
    {
      dataCy: 'menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        navigate(getTrueLayerAccountDetails(id));
      },
    },
  ];

  return (
    <>
      <TableRow dataCy="accounts-table-row">
        <TableData dataCy="accounts-name">{name}</TableData>
        <TableData dataCy="accounts-type">{capitalizeFirstLetter(type)}</TableData>
        <TableData dataCy="accounts-currency">{currency}</TableData>
        <TableData dataCy="accounts-dropdown-menu">
          <DropdownMenu elements={menuActions} />
        </TableData>
      </TableRow>
    </>
  );
};

export default AccountsRow;
