import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';

interface Props {
  labelClassName?: string;
  label?: string;
  dataCy?: string;
}

const TableDataPair = ({ children, labelClassName, label, dataCy }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  return (
    <p data-cy={dataCy} className={'block break-all'}>
      <b className={labelClassName}>{t(label || '')}</b>: {children}
    </p>
  );
};

export default TableDataPair;
