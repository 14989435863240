import { useCallback, useState } from 'react';

import { DropdownMenuItems } from 'interfaces/shared.interface';

export const useSelectDropdownItem = () => {
  const [selectedItem, setSelectedItem] = useState<DropdownMenuItems | null>(null);

  const handleSelectDropdownItem = useCallback((dropdownItem: DropdownMenuItems) => {
    setSelectedItem(dropdownItem);
  }, []);

  return { selectedItem, handleSelectDropdownItem };
};
