import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useGetCodatAccountingSuppliersQuery } from 'apis/codat-accounting.api';
import {
  CODAT_SUPPLIERS_FILTERS_FIELDS_DEFAULT_VALUES,
  CodatSuppliersFilterFormValues,
  SEARCH_CODAT_SUPPLIERS_PLACEHOLDER,
  SUPPLIERS_TABLE_COLUMNS,
} from 'constants/codat-accounting';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import SuppliersRow from 'page-components/integrations/codat-accounting/suppliers/SuppliersRow';
import { ErrorModal, FilterForm, InfoModal, LoadingOverlay, Pagination, Table } from 'shared-components';

const Suppliers = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isInfoModalOpen, handleOpenInfoModal, handleCloseInfoModal] = useOpen();

  const { handleSetTabName, customSortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { customPaginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { filterParams, applyFilters, isSameFilters } = useFilters();

  const {
    data: suppliersData,
    isLoading: isSuppliersLoading,
    isFetching,
    refetch,
    error: suppliersError,
  } = useGetCodatAccountingSuppliersQuery(`${filterParams}${customSortingParams}${customPaginationParams}`);

  const { result } = suppliersData || {};

  const { error: suppliersErrorData } = (suppliersError as CommonError)?.data || {};

  const errorData = suppliersErrorData;

  const infoData = result?.isDatasetSyncNotCompleted;

  const suppliersFiltersMethods = useForm<CodatSuppliersFilterFormValues>({
    defaultValues: CODAT_SUPPLIERS_FILTERS_FIELDS_DEFAULT_VALUES,
  });

  const handleClearFields = () => {
    suppliersFiltersMethods.reset(CODAT_SUPPLIERS_FILTERS_FIELDS_DEFAULT_VALUES);
    onSubmitFilters(CODAT_SUPPLIERS_FILTERS_FIELDS_DEFAULT_VALUES);
  };

  const onSubmitFilters = async (data: CodatSuppliersFilterFormValues) => {
    const { filter } = data;

    const newFiltersToApply = {
      SearchQuery: filter,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
    if (infoData) {
      handleOpenInfoModal();
    }
  }, [errorData, handleOpenErrorModal, handleOpenInfoModal, infoData]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <InfoModal
        infoMessage={'DatasetSyncInProgress'}
        infoDescription={'DatasetSyncInProgressDescription'}
        isOpen={isInfoModalOpen}
        handleClose={handleCloseInfoModal}
      />

      <div className="px-5 pt-5 pb-0" data-cy="codat-accounting-suppliers-filtering">
        <FormProvider {...suppliersFiltersMethods}>
          <form onSubmit={suppliersFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="codat-accounting-suppliers-page-search"
              placeHolder={SEARCH_CODAT_SUPPLIERS_PLACEHOLDER}
              handleClearFields={handleClearFields}
              withFilter={false}
            />
          </form>
        </FormProvider>
      </div>
      <div data-cy="suppliers-tab" className="relative px-5">
        <LoadingOverlay isLoading={isFetching && !!result} />
        <Table
          dataCy="suppliers-table"
          columns={SUPPLIERS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!suppliersData?.result.data.totalCount}
          sortingType={sortingType}
          isLoading={isSuppliersLoading}
        >
          {result?.data.suppliers.map((item) => <SuppliersRow key={item.id} SupplierData={item} />)}
        </Table>
        <Pagination
          totalCount={suppliersData?.result.data.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default Suppliers;
