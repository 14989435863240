import { useTranslation } from 'react-i18next';

import { useApproveRequestMutation } from 'apis/gdpr.api';
import { WARNING_MODAL_DESCRIPTION } from 'constants/gdpr';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { RequestsToBeForgotten } from 'interfaces/gdpr.interface';
import { CommonError } from 'interfaces/shared.interface';
import { DropdownMenu, TableData, TableRow, WarningModal, ErrorModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';

interface Props {
  gdprData: RequestsToBeForgotten;
  refetchSubscriptions: VoidFunction;
  userRole: string;
  isAdmin: boolean;
}

const GDPRRow = ({ gdprData, userRole, isAdmin }: Props) => {
  const { t } = useTranslation();

  const { id, statusName, tenantId, tenantName, userFirstName, userId, userLastName } = gdprData;

  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();

  const [approveRequest, { error }] = useApproveRequestMutation();
  const { error: errorData } = (error as CommonError)?.data || {};

  const menuItemsAction = [
    {
      dataCy: 'gdpr-menu-item-approve-button',
      name: 'Approve',
      actionOnClick: handleOpenInfoModal,
    },
  ];

  const handleApproveRequest = async () => {
    try {
      await approveRequest({ isAdmin, requestId: id }).unwrap();
      handleCloseInfoModal();
      successNotify(t('SavedSuccessfully'));
    } catch (error) {
      handleOnOpenErrorModal();
    }
  };

  return (
    <>
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t(WARNING_MODAL_DESCRIPTION[userRole])}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleApproveRequest}
      />

      <TableRow dataCy="gdpr-table-row">
        <TableData dataCy="gdpr-table-row-item-id">{isAdmin ? tenantId : userId}</TableData>
        <TableData>{isAdmin ? tenantName : `${userFirstName} ${userLastName}`}</TableData>
        <TableData>{t(statusName)}</TableData>
        <TableData>{getFormattedDate(gdprData.dateCreated, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}</TableData>
        <TableData>
          <DropdownMenu dataCy="gdpr-dropdown-menu" elements={menuItemsAction} disabled={statusName === 'Completed'} />
        </TableData>
      </TableRow>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
    </>
  );
};

export default GDPRRow;
