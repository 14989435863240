import { useEffect, useMemo } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useForm } from 'react-hook-form';

import { useLazyTenantGetSalesSummaryQuery } from 'apis';
import { AREA_CHART_FILTER_FORM_FILTER_OPTIONS, AREA_CHART_FILTER_FORM_DEFAULT_VALUES } from 'constants/area-chart';
import { BoxVariants } from 'constants/common';
import { DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME } from 'constants/dashboard';
import { getSharedStackedAreaChartOptions } from 'constants/shared-components';
import { AREA_CHART_FILTERS_FORM_FIELDS_NAMES, AreaChartFilterFormValues } from 'interfaces/area-chart.interface';
import { SalesSummary } from 'interfaces/sales-summary.interface';
import { Box, LoadingOverlay } from 'shared-components';
import RadioInputSetV1 from 'shared-components/radio/RadioInputSetV1';
import { getFormattedChartDate } from 'utils/summary-sales';

import SalesSummaryMetrics from './SalesSummaryMetrics';

const SalesSummaryWidget = () => {
  const [getTenantSalesSummary, { data, isLoading }] = useLazyTenantGetSalesSummaryQuery();

  const { salesSummary, ...salesSummaryMetrics } = data?.result || ({} as SalesSummary);

  const { register, watch } = useForm<AreaChartFilterFormValues>({
    defaultValues: AREA_CHART_FILTER_FORM_DEFAULT_VALUES,
  });

  const watchSalesSummaryDatePeriod = watch(AREA_CHART_FILTERS_FORM_FIELDS_NAMES.AREA_CHART_DATE_PERIOD);

  const chartOptions = useMemo(() => {
    const categories = salesSummary?.map(({ period }) => getFormattedChartDate(period));

    const salesSeriesData = {
      name: 'Sales',
      data: salesSummary?.map(({ sales }) => Number(sales)),
      color: '#7aa3e5',
      showInLegend: false,
    };

    const profitSeriesData = {
      name: 'Profit',
      data: salesSummary?.map(({ profit }) => Number(profit)),
      color: '#a8385d',
      showInLegend: false,
    };

    return getSharedStackedAreaChartOptions({ categories, series: [salesSeriesData, profitSeriesData] });
  }, [salesSummary]);

  useEffect(() => {
    if (!watchSalesSummaryDatePeriod) {
      return;
    }

    getTenantSalesSummary(watchSalesSummaryDatePeriod);
  }, [getTenantSalesSummary, watchSalesSummaryDatePeriod]);

  return (
    <Box
      dataCy="sales-summary-widget"
      className="relative h-full"
      headerClassName={DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME}
      variant={BoxVariants.ROUNDED}
      title="WidgetSalesSummary"
      customHeader={
        <RadioInputSetV1
          className="opacity-0 peer-checked:bg-black"
          {...register(AREA_CHART_FILTERS_FORM_FIELDS_NAMES.AREA_CHART_DATE_PERIOD)}
          radioOptions={AREA_CHART_FILTER_FORM_FILTER_OPTIONS}
        />
      }
    >
      <LoadingOverlay isLoading={isLoading} />
      <SalesSummaryMetrics metrics={salesSummaryMetrics} />
      <div data-cy="sales-summary-chart-container">
        <HighchartsReact
          containerProps={{ style: { height: '100%' } }}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    </Box>
  );
};
export default SalesSummaryWidget;
