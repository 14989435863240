import { BaseFilterValues, CommonResponse, OptionType } from 'interfaces/shared.interface';

export interface DocumentTypes {
  id: number;
  name: string;
  uniqueName: string;
  numberOfTrainings: number;
  createdDate: string;
  status: number;
}

export interface DocumentsTypesResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: DocumentTypes[];
  };
}

export enum TYPLESS_FILTERS_FORM_FIELD_NAMES {
  SELECT_STATUS = 'status',
}

export interface TyplessFilterFormValues extends BaseFilterValues {
  [TYPLESS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: OptionType;
}

export enum TYPLESS_COLUMNS {
  NAME = 'name',
  STATUS = 'status',
  NUMBER_OF_TRAININGS = 'numberOfTrainings',
  CREATION_TIME = 'CreatedDate',
  ACTION = 'action',
}

export enum DOCUMENT_TYPE_FORM_FIELDS {
  NAME = 'name',
}

export interface BaseCreateDocumentTypeFormValue {
  [DOCUMENT_TYPE_FORM_FIELDS.NAME]: string;
}
export interface CreateDocumentTypePayload {
  name: string;
}

export interface CreateDocumentTypeResponse extends CommonResponse {
  result: number;
}

export interface GetDocumentTypeDetailsResponse extends CommonResponse {
  result: DocumentTypes;
}

export enum TRAIN_DOCUMENT_TYPE_FORM_FIELDS {
  SUPPLIER_NAME = 'supplierName',
  SUPPLIER_ADDRESS = 'supplierAddress',
  SUPPLIER_EMAIL_ADDRESS = 'supplierEmailAddress',
  SUPPLIER_PHONE_NUMBER = 'supplierPhoneNumber',
  CLIENT_NAME = 'clientName',
  CLIENT_ADDRESS = 'clientAddress',
  CLIENT_EMAIL_ADDRESS = 'clientEmailAddress',
  CLIENT_PHONE_NUMBER = 'clientPhoneNumber',
  INVOICE_NUMBER = 'invoiceNumber',
  ISSUED_DATE = 'invoiceCreatedDate',
  DUE_DATE = 'invoiceDueDate',
  TOTAL_AMOUNT = 'totalAmount',
  TOTAL_TAX = 'totalVAT',
}

export interface BaseTrainDocumentTypeFormValue {
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_NAME]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_ADDRESS]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_EMAIL_ADDRESS]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.SUPPLIER_PHONE_NUMBER]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_NAME]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_ADDRESS]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_EMAIL_ADDRESS]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.CLIENT_PHONE_NUMBER]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.INVOICE_NUMBER]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.ISSUED_DATE]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.DUE_DATE]: string;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.TOTAL_AMOUNT]: number;
  [TRAIN_DOCUMENT_TYPE_FORM_FIELDS.TOTAL_TAX]: number;
}

export interface InvoiceLineItemTrainData {
  description: string;
  quantity: number;
  price: number;
  amount: number;
  tax: number;
}

export interface TrainData {
  supplierName: string;
  supplierAddress: string;
  supplierEmailAddress: string;
  supplierPhoneNumber: string;
  clientName: string;
  clientAddress: string;
  clientEmailAddress: string;
  clientPhoneNumber: string;
  invoiceNumber: string;
  invoiceCreatedDate: string;
  invoiceDueDate: string;
  totalAmount: number;
  totalVAT: number;
  invoiceLineItems: InvoiceLineItemTrainData[];
}

export interface TrainDocumentTypePayload {
  documentBase64String: string;
  documentName: string;
  documentTypeId: string | number;
  trainData: TrainData;
}

export enum TRAIN_DOCUMENT_COLUMNS {
  BUTTON = 'button',
  DESCRIPTION = 'description',
  QUANTITY = 'quantity',
  PRICE = 'price',
  TAX = 'tax',
  AMOUNT = 'amount',
}

export interface ParseDocumentPayload {
  documentBase64String: string;
  documentName: string;
  documentTypeId: string | number;
}

export interface ParseDocument {
  id: string;
  description: string;
  quantity: number;
  price: number;
  amount: number;
  tax: number;
}

export interface ParseDocumentResponse extends CommonResponse {
  result: {
    supplierName: string;
    supplierAddress: string;
    supplierEmailAddress: string;
    supplierPhoneNumber: string;
    clientName: string;
    clientAddress: string;
    clientEmailAddress: string;
    clientPhoneNumber: string;
    totalAmount: number;
    totalTax: number;
    dueDate: string;
    issuedDate: string;
    invoiceNumber: string;
    lineItems: ParseDocument[];
  };
}

export interface TrainedDocumentTypes {
  id: number;
  name: string;
  uniqueName: string;
  status: number;
}

export interface TrainedDocumentTypesResponse extends CommonResponse {
  result: TrainedDocumentTypes[];
}

export interface UploadDocumentPayload {
  fileName: string;
  fileBase64String: string;
  clientAddress: string | null;
  clientEmailAddress: string | null;
  clientName: string;
  clientPhoneNumber: string | null;
  dueDate: string;
  invoiceLineItems: InvoiceLineItemTrainData[];
  invoiceNumber: string;
  issuedDate: string;
  supplierAddress: string | null;
  supplierEmailAddress: string | null;
  supplierName: string;
  supplierPhoneNumber: string | null;
  totalAmount: number;
  totalTax: number;
}
