import { CommonResponse } from 'interfaces/shared.interface';

export interface GetConnectionDetailsResponse extends CommonResponse {
  result: {
    expiresInDays: number | null;
    isConnected: boolean;
    isConnectionExpired: boolean | null;
  };
}

export interface GetAuthLinkResponse extends CommonResponse {
  result?: string;
}

export interface GetStatusAndTypesResponse extends CommonResponse {
  result: {
    data: StatusAndTypesData[];
    isReconnectionRequired: boolean;
  };
}

export interface StatusAndTypesData {
  id: string;
  name: string;
}

export interface GetInvoiceResponse extends CommonResponse {
  result: {
    data: InvoicesData;
    isReconnectionRequired: boolean;
  };
}

export interface InvoicesData {
  invoices: InvoiceData[];
  totalCount: number;
}

export interface GetInvoiceDetailsResponse extends CommonResponse {
  result: {
    data: InvoiceData;
    isReconnectionRequired: boolean;
  };
}

export interface InvoiceData {
  contactName: string;
  createdAt: string;
  currencyCode: string;
  deliveryAddress: string;
  displayedAs: string;
  dueDate: string;
  id: string;
  invoiceNumber: string;
  isSent: boolean;
  isSentByEmail: boolean;
  issueDate: string;
  lineItems: LineItemData[];
  mainAddress: string;
  netAmount: number;
  reference: string;
  status: StatusAndTypesData;
  taxAmount: number;
  totalAmount: number;
  totalDiscountAmount: number;
  totalPaid: number;
  updatedAt: string;
}

export interface LineItemData {
  description: string;
  discountAmount: number;
  id: string;
  name: string;
  netAmount: number;
  quantity: number;
  taxAmount: number;
  totalAmount: number;
  unitPrice: number;
}

export enum INVOICES_COLUMNS {
  REFERENCE = 'SupplierName',
  CONTACT_NAME = 'InvoiceNumber',
  TOTAL_AMOUNT = 'TotalAmount',
  ISSUE_DATE = 'date',
  DUE_DATE = 'due_date',
  STATUS = 'Status',
  ACTION = 'Action',
}

export enum INVOICE_COLUMNS {
  DESCRIPTION = 'Description',
  QUANTITY = 'Qty',
  UNIT_PRICE = 'UnitPrice',
  NET_AMOUNT = 'NetAmount',
  TAX_AMOUNT = 'TaxAmount',
  TOTAL_AMOUNT = 'TotalAmount',
  DISCOUNT_AMOUNT = 'DiscountAmount',
}

export enum CONTACTS_COLUMNS {
  NAME = 'Name',
  REFERENCE = 'Reference',
  TYPE = 'Type',
  STATUS = 'Status',
  ACTION = 'Action',
}

export interface GetContactsResponse extends CommonResponse {
  result: {
    data: ContactsData;
    isReconnectionRequired: boolean;
  };
}

export interface ContactsData {
  contacts: ContactData[];
  totalCount: number;
}
export interface GetContactDetailsResponse extends CommonResponse {
  result: {
    data: ContactData;
    isReconnectionRequired: boolean;
  };
}

export interface ContactData {
  balance: number;
  bankAccountDetails: BankAccountDetailsData;
  contactTypes: StatusAndTypesData[];
  createdAt: string;
  currencyCode: string;
  deliveryAddress: string;
  email: string;
  id: string;
  isActive: boolean;
  mainAddress: string;
  mainContactPerson: MainContactPersonData;
  name: string;
  reference: string;
  registrationNumber: string;
  taxNumber: string;
  updatedAt: string;
}

export interface BankAccountDetailsData {
  accountName: string;
  accountNumber: string;
  bic: string;
  iban: string;
  sortCode: string;
}

export interface MainContactPersonData {
  email: string;
  id: string;
  isMainContact: boolean;
  isPreferredContact: boolean;
  jobTitle: string;
  mobile: string;
  name: string;
  telephone: string;
}

export interface LineItems {
  description: string;
  quantity: number;
  unitPrice: number;
  netAmount: number;
  taxAmount: number;
  totalAmount: number;
  discountAmount: number;
}
