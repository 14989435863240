import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateRootUnitRequestMutation } from 'apis/organization-units.api';
import { TENANT_SUPPORT_REQUEST_FIELDS } from 'constants/organization-units';
import { useOpen } from 'hooks/useOpen';
import { CreateRootUnitPayload, ORGANIZATION_UNITS_REQUEST_FORM_FIELDS } from 'interfaces/organization-units.interface';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, FormContent, Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

interface Props {
  handleClose: VoidFunction;
  refetchListRoot: VoidFunction;
  isOpen: boolean;
  selectedUnitId?: number | null;
}
const CreateRootUnitModal = ({ handleClose, isOpen, refetchListRoot, selectedUnitId }: Props) => {
  const { t } = useTranslation();
  const [createSupportRequest, { isLoading: isCreateRootUnitLoading, error }] = useCreateRootUnitRequestMutation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const createSupportRequestMethods = useForm<CreateRootUnitPayload>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors },
  } = createSupportRequestMethods;

  const { data: errorData } = (error as CommonError) || {};
  const watchSubjectField = watch(ORGANIZATION_UNITS_REQUEST_FORM_FIELDS.DISPLAY_NAME);

  const isDisabledSubmitButton = !watchSubjectField;

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmit = async (data: CreateRootUnitPayload) => {
    if (isDisabledSubmitButton) {
      return;
    }

    const payload: CreateRootUnitPayload = {
      displayName: data[ORGANIZATION_UNITS_REQUEST_FORM_FIELDS.DISPLAY_NAME],
      parentId: selectedUnitId || null,
    };
    try {
      await createSupportRequest(payload).unwrap();
      refetchListRoot();
      onCloseModal();
      successNotify(t('SuccessfullyCreated'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <Modal dataCy="create-root-unit-modal" isOpen={isOpen} onClose={onCloseModal} heading="NewOrganizationUnit">
        <FormProvider {...createSupportRequestMethods}>
          <form data-cy="create-root-unit-modal-form" onSubmit={handleSubmit(onSubmit)}>
            <FormContent fields={TENANT_SUPPORT_REQUEST_FIELDS} register={register} errors={errors} />
            <ModalControlButtons
              isShownSubmit
              className="pb-5"
              saveButtonText="Save"
              closeButtonText="Cancel"
              isLoading={isCreateRootUnitLoading}
              onClose={onCloseModal}
              disabled={isDisabledSubmitButton}
            />
          </form>
        </FormProvider>
      </Modal>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};

export default CreateRootUnitModal;
