import { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateTenantNameMutation } from 'apis/tenant-details.api';
import { useGetCurrentLoginInformationsQuery } from 'apis/user.api';
import { ButtonVariants } from 'constants/common';
import { UPDATE_TENANT_NAME_FORM_FIELDS } from 'constants/tenant-details-tenant';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import {
  TenantDetailsNamePayload,
  UPDATE_TENANT_NAME_FORM_FIELD,
  UpdateTenantNameFormValues,
} from 'interfaces/tenant-details.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Button, ErrorModal, FormContent } from 'shared-components';
import { successNotify } from 'utils/notifications';

interface Props {
  dataCy?: string;
}

const TenantDetailsBlock = ({ dataCy }: Props) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [updateTenantName, { isLoading: isUpdateTenantNameLoading, error: updateTenantNameError }] =
    useUpdateTenantNameMutation();

  const errorData = (updateTenantNameError as CommonError)?.data || {};

  const { data: tanantData, refetch } = useGetCurrentLoginInformationsQuery();
  const [isEditing, setIsEditing] = useState(false);
  const permissions = useAppSelector(selectUserPermissions);

  const updateTenantNameMethods = useForm<UpdateTenantNameFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = updateTenantNameMethods;

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setIsEditing(false);
  };

  const onSubmit = async (data: UpdateTenantNameFormValues) => {
    const payload: TenantDetailsNamePayload = {
      name: data[UPDATE_TENANT_NAME_FORM_FIELD.DISPLAY_NAME],
      isActive: true,
      id: tanantData?.tenant.id,
    };

    try {
      await updateTenantName(payload).unwrap();
      refetch();
      setIsEditing(false);
      if (!isUpdateTenantNameLoading) {
        successNotify(t('SuccessfullyCreated'));
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (tanantData?.tenant.name) {
      setValue(UPDATE_TENANT_NAME_FORM_FIELD.DISPLAY_NAME, tanantData?.tenant.name);
    }
  }, [tanantData, setValue]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy={dataCy} className="w-full bg-white dark:bg-darkBlue shadow-centerLight rounded-md mb-4 py-8 px-4">
        {isEditing && permissions[Permissions.PAGES_TENANT_TENANT_DETAILS_EDIT] ? (
          <div data-cy="block-with-change-name-input">
            <FormProvider {...updateTenantNameMethods}>
              <form data-cy="create-request-modal-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="sm:flex sm:items-center">
                  <FormContent fields={UPDATE_TENANT_NAME_FORM_FIELDS} register={register} errors={errors} />
                  <Button
                    dataCy="save-button"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isUpdateTenantNameLoading}
                    className="mt-4 mr-4 sm:mx-4 sm:mt-7"
                  >
                    {t('Save')}
                  </Button>
                  <Button className="sm:mt-7" onClick={handleCancel} variant={ButtonVariants.TRANSPARENT_WITH_BORDER}>
                    {t('Cancel')}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        ) : (
          <div className="flex justify-between items-center">
            <p className="text-sm">
              {t('TenantName') + ': '} {tanantData?.tenant.name}
            </p>
            {permissions[Permissions.PAGES_TENANT_TENANT_DETAILS_EDIT] && (
              <Button data-cy="edit-tenant-name-button" className="h-10 ml-3.5" type="button" onClick={handleEdit}>
                {t('Edit')}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TenantDetailsBlock;
