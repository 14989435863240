import { useTranslation } from 'react-i18next';

import { useLazyGetInvoicePdfQuery } from 'apis/quickBooks.api';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-3.svg';
import { ButtonVariants } from 'constants/common';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { GetInvoiceDetails } from 'interfaces/integrations/quickBooks.interface';
import { Box, Button, ErrorModal, LabelWithValue, LinkButton } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { downloadDocument, useFormatPriceFunction } from 'utils/global';

interface Props {
  data: GetInvoiceDetails | undefined;
  isLoading: boolean;
  setIsFetching: (isFetching: boolean) => void;
}

const InvoiceData = ({ data, isLoading, setIsFetching }: Props) => {
  const { t } = useTranslation();
  const [getInvoiceToPdf, { data: invoiceData, isLoading: isGetInvoicePdfLoading }] = useLazyGetInvoicePdfQuery();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const noDate = '-';

  const { reference, issueDate, total, customerName, dueDate, totalTax, subtotal } = data || {};

  const labelValueMap: { [key: string]: string | number | boolean | null | undefined } = {
    Reference: reference,
    IssueDate: getFormattedDate(issueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    TotalAmount: useFormatPriceFunction(total || 0),
    CustomerName: customerName,
    DueDate: getFormattedDate(dueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    TotalTax: useFormatPriceFunction(totalTax || 0),
    Subtotal: useFormatPriceFunction(subtotal || 0),
  };

  const handleDownloadDocument = async () => {
    if (!data?.id) {
      console.error('Error: Invoice ID is undefined');
      return;
    }

    try {
      setIsFetching(true);
      const invoiceId = data.id;
      const response = await getInvoiceToPdf(invoiceId);

      if (response.data?.result.data.content && response.data?.result.data.fileName) {
        downloadDocument(response.data.result.data.content, response.data.result.data.fileName);
      }
    } catch (error) {
      handleOpenErrorModal();
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={invoiceData?.error?.message}
        description={invoiceData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />

      <div className="relative">
        <Box data-cy="invoice-data" className="p-4">
          <div className="flex justify-between items-center mb-5">
            <LinkButton className="text-1xs mb-5" variant={ButtonVariants.LINK} to={ROUTES.quickBooks}>
              <ArrowLeft className="fill-blue5 mr-1.5" />
              {t('Back')}
            </LinkButton>

            <Button
              isLoading={isGetInvoicePdfLoading}
              data-cy="download-invoice-details-button"
              className="h-10 ml-3.5"
              type="button"
              onClick={handleDownloadDocument}
            >
              {t('Download')}
            </Button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
            {Object.entries(labelValueMap).map(([label, value]) => (
              <LabelWithValue
                isLoading={isLoading}
                key={label}
                labelClassName="w-1/2 items-center flex"
                label={label}
                value={value || noDate}
              />
            ))}
          </div>
        </Box>
      </div>
    </>
  );
};

export default InvoiceData;
