import { cn } from 'utils/global';

interface Props {
  content: string;
  shouldBeFormatted?: boolean;
  className?: string;
}

const FormattedText = ({ content, shouldBeFormatted, className }: Props) => {
  const formattedContent = content && shouldBeFormatted ? JSON.stringify(JSON.parse(content), null, 4) : content;

  return (
    <pre className={cn('overflow-auto py-1.5 text-xs', className)} lang="js">
      {formattedContent}
    </pre>
  );
};

export default FormattedText;
