import { addDays } from 'date-fns';

import {
  CHANGE_LOG_DETAILS_COLUMNS,
  CHANGE_LOGS_COLUMNS,
  CHANGE_LOGS_FILTERS_FORM_FIELD_NAMES,
  OPERATION_LOGS_COLUMNS,
  OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES,
} from 'interfaces/audit-logs.interface';
import {
  FieldTypes,
  FormItem,
  OptionsTypeValue,
  SHARED_START_END_DATE_FILTERS_FIELD_NAMES,
  TabItem,
  TableColumn,
} from 'interfaces/shared.interface';
import ChangeLogs from 'page-components/audit-logs/change-logs/ChangeLogs';
import OperationLogs from 'page-components/audit-logs/operation-logs/OperationLogs';
import { Input, SelectFormInput } from 'shared-components';
import DatePickerInput from 'shared-components/DatePickerInput';
import InputNumberFormat from 'shared-components/InputNumberFormat';

import { BASE_FILTER_FIELDS, BoxVariants } from './common';

export const AUDIT_LOGS_TABS: TabItem[] = [
  {
    id: 'operation-logs',
    title: 'OperationLogs',
    component: OperationLogs,
  },
  {
    dataCy: 'change-logs-tab-button',
    id: 'change-logs',
    title: 'ChangeLogs',
    component: ChangeLogs,
  },
];

export const OPERATION_LOGS_COLUMNS_TITLES = {
  [OPERATION_LOGS_COLUMNS.STATE]: 'State',
  [OPERATION_LOGS_COLUMNS.USER_EMAIL]: 'UserEmail',
  [OPERATION_LOGS_COLUMNS.SERVICE]: 'Service/Action',
  [OPERATION_LOGS_COLUMNS.EXECUTION_DURATION]: 'Duration',
  [OPERATION_LOGS_COLUMNS.BROWSER]: 'Browser',
  [OPERATION_LOGS_COLUMNS.EXECUTION_TIME]: 'Time',
  [OPERATION_LOGS_COLUMNS.ACTIONS]: '',
};

export const OPERATION_LOGS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: OPERATION_LOGS_COLUMNS.STATE,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.STATE],
    width: 'w-1/12',
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.USER_EMAIL,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.USER_EMAIL],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.SERVICE,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.SERVICE],
    width: 'w-2/12',
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.EXECUTION_DURATION,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.EXECUTION_DURATION],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.BROWSER,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.BROWSER],
    width: 'w-2/12',
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.EXECUTION_TIME,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.EXECUTION_TIME],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: OPERATION_LOGS_COLUMNS.ACTIONS,
    title: OPERATION_LOGS_COLUMNS_TITLES[OPERATION_LOGS_COLUMNS.ACTIONS],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const OPERATION_LOGS_FILTER_FORM_FIELDS: FormItem[] = [
  {
    name: OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.BROWSER_INFO,
    component: Input,
    className: 'col-start-1 col-end-5 md:col-start-1 md:col-end-3',
    labelClassName: 'text-sm',
    inputClassName: 'w-full !text-sm dark:bg-transparent',
    label: 'Browser',
    placeholder: 'SearchWithThreeDotBrowserName',
    dataCy: 'browser-info-field',
  },
  {
    name: OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.SERVICE_NAME,
    component: Input,
    className: 'col-start-5 col-end-9 md:col-start-3 md:col-end-5 md:row-start-1 md:row-end-2',
    labelClassName: 'text-sm',
    inputClassName: 'w-full !text-sm dark:bg-transparent',
    label: 'Service',
    placeholder: 'SearchWithThreeDotServiceName',
    dataCy: 'service-info',
  },
  {
    name: OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.METHOD_NAME,
    component: Input,
    className: 'col-start-9 col-end-13 md:col-start-5 md:col-end-7 md:row-start-1 md:row-end-2',
    labelClassName: 'text-sm',
    inputClassName: 'w-full !text-sm dark:bg-transparent',
    label: 'Action',
    placeholder: 'SearchWithThreeDotMethodName',
    dataCy: 'method-info',
  },
  {
    name: SHARED_START_END_DATE_FILTERS_FIELD_NAMES.START_DATE,
    component: DatePickerInput,
    type: FieldTypes.DATE,
    className: 'col-start-1 col-end-4 md:col-start-1 md:col-end-2 md:row-start-2 md:row-end-3',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
    label: 'StartDate',
    dataCy: 'start-date',
  },
  {
    name: SHARED_START_END_DATE_FILTERS_FIELD_NAMES.END_DATE,
    component: DatePickerInput,
    type: FieldTypes.DATE,
    className: 'col-start-4 col-end-7 md:col-start-2 md:col-end-3 md:row-start-2 md:row-end-3',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2 ml-0 dark:text-white',
    label: 'EndDate',
    dataCy: 'end-date',
  },
  {
    name: OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.MIN_DURATION,
    component: InputNumberFormat,
    className:
      "col-start-7 col-end-10 md:col-start-3 md:col-end-4 md:row-start-2 md:row-end-3 relative after:content-['_—'] after:absolute after:text-darkGray after:top-[45%] after:left-[100%] dark:after:text-white",
    type: FieldTypes.NUMBER,
    inputClassName: 'w-full !text-sm dark:bg-transparent',
    placeholder: 'MinExecutationDuration',
    label: 'Duration',
    dataCy: 'method-info',
    fixedDecimalLength: 0,
    maxValue: 86400,
  },
  {
    name: OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.MAX_DURATION,
    component: InputNumberFormat,
    className: 'col-start-10 col-end-13 md:col-start-4 md:col-end-5 md:row-start-2 md:row-end-3',
    type: FieldTypes.NUMBER,
    label: ' ',
    labelClassName: 'h-5',
    inputClassName: 'w-full !text-sm dark:bg-transparent',
    placeholder: 'MaxExecutationDuration',
    dataCy: 'method-name',
    fixedDecimalLength: 0,
    maxValue: 86400,
  },
  {
    name: OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.HAS_EXCEPTION,
    component: SelectFormInput,
    className: 'col-start-1 col-end-13 md:col-start-1 md:col-end-3 md:row-start-3 md:row-end-4',
    inputClassName: 'w-full mt-2',
    label: 'ErrorState',
    placeholder: 'SelectErrorState',
    dataCy: 'has-exception-select',
    type: FieldTypes.SELECT,
    options: [
      { label: 'HasError', value: true },
      { label: 'Success', value: false },
    ],
    isFilter: true,
  },
];

export const OPERATION_LOGS_FILTERS_FIELDS_DEFAULT_VALUES = {
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.SEARCH_FIELD]: '',
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.BROWSER_INFO]: '',
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.SERVICE_NAME]: '',
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.METHOD_NAME]: '',
  [SHARED_START_END_DATE_FILTERS_FIELD_NAMES.START_DATE]: new Date().toISOString(),
  [SHARED_START_END_DATE_FILTERS_FIELD_NAMES.END_DATE]: addDays(new Date(), 1).toISOString(),
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.MIN_DURATION]: '',
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.MAX_DURATION]: '',
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.HAS_EXCEPTION]: null,
};

export const CHANGE_LOGS_COLUMNS_TITLES = {
  [CHANGE_LOGS_COLUMNS.ACTION]: 'Action',
  [CHANGE_LOGS_COLUMNS.OBJECT]: 'Object',
  [CHANGE_LOGS_COLUMNS.USER_EMAIL]: 'UserEmail',
  [CHANGE_LOGS_COLUMNS.TIME]: 'Time',
  [CHANGE_LOGS_COLUMNS.ACTIONS]: '',
};

export const CHANGE_LOGS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CHANGE_LOGS_COLUMNS.ACTION,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.ACTION],
    width: 'w-1/12',
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.OBJECT,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.OBJECT],
    width: 'w-11/12',
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.USER_EMAIL,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.USER_EMAIL],
    sorting: true,
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.TIME,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.TIME],
    sorting: true,
  },
  {
    columnId: CHANGE_LOGS_COLUMNS.ACTIONS,
    title: CHANGE_LOGS_COLUMNS_TITLES[CHANGE_LOGS_COLUMNS.ACTIONS],
  },
];

export const getChangeLogsFilterFormFields = (optionsEntityTypes?: OptionsTypeValue[]): FormItem[] => [
  {
    name: SHARED_START_END_DATE_FILTERS_FIELD_NAMES.START_DATE,
    component: DatePickerInput,
    type: FieldTypes.DATE,
    className: 'mr-4 w-1/4',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
    label: 'StartDate',
    dataCy: 'start-date',
  },
  {
    name: SHARED_START_END_DATE_FILTERS_FIELD_NAMES.END_DATE,
    component: DatePickerInput,
    type: FieldTypes.DATE,
    className: 'mr-4 w-1/4',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2 ml-0 dark:text-white',
    label: 'EndDate',
    dataCy: 'end-date',
  },
  {
    name: CHANGE_LOGS_FILTERS_FORM_FIELD_NAMES.ENTITY_TYPE_FULL_NAME,
    component: SelectFormInput,
    className: 'w-1/2',
    inputClassName: 'w-full mt-1',
    labelClassName: '',
    label: 'Object',
    placeholder: 'SelectAType',
    dataCy: 'entity-type-full-name-select',
    isClearable: true,
    type: FieldTypes.SELECT,
    options: optionsEntityTypes,
    isFilter: true,
  },
];

export const getChangeLogsFiltersFieldsDefaultValues = (selectATypeLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [CHANGE_LOGS_FILTERS_FORM_FIELD_NAMES.ENTITY_TYPE_FULL_NAME]: { label: selectATypeLabel, value: '' },
  [SHARED_START_END_DATE_FILTERS_FIELD_NAMES.START_DATE]: new Date().toISOString(),
  [SHARED_START_END_DATE_FILTERS_FIELD_NAMES.END_DATE]: addDays(new Date(), 1).toISOString(),
});

export const DETAILS_SHARED_BOX_PROPS = { variant: BoxVariants.TRANSPARENT, className: 'border-none [&>div]:mb-4' };

export const DETAILS_SHARED_LABEL_PROPS = {
  labelClassName: 'text-black dark:text-white shrink-0 grow-0 sm:basis-36 py-1.5',
  valueClassName: 'sm:w-8/12 grow-0',
};

export const CHANGE_LOGS_DETAILS_COLUMNS_TITLES = {
  [CHANGE_LOG_DETAILS_COLUMNS.ENTITY_CHANGE_ID]: 'EnttityChangeId',
  [CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_NAME]: 'PropertyName',
  [CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_TYPE]: 'PropertyType',
  [CHANGE_LOG_DETAILS_COLUMNS.ORIGINAL_VALUE]: 'OriginalValue',
  [CHANGE_LOG_DETAILS_COLUMNS.NEW_VALUE]: 'NewValue',
};

export const CHANGE_LOGS_DETAILS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.ENTITY_CHANGE_ID,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.ENTITY_CHANGE_ID],
    width: 'w-[10%] border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_NAME,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_NAME],
    width: 'w-[14%] border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_TYPE,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.PROPERTY_TYPE],
    width: 'border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.ORIGINAL_VALUE,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.ORIGINAL_VALUE],
    width: 'border border-lightGray8 font-semibold',
  },
  {
    columnId: CHANGE_LOG_DETAILS_COLUMNS.NEW_VALUE,
    title: CHANGE_LOGS_DETAILS_COLUMNS_TITLES[CHANGE_LOG_DETAILS_COLUMNS.NEW_VALUE],
    width: 'border border-lightGray8 font-semibold',
  },
];
