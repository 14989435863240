import { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
  dataCy?: string;
  link?: string;
}

const SamplesPageBlock = ({ title, children, dataCy, link }: Props) => {
  return (
    <div data-cy={dataCy} className="w-full bg-white dark:bg-darkBlue shadow-centerLight rounded-md mb-4">
      <div className="border-b-2 dark:border-b-[1px] border-darkGray11 dark:border-gray2">
        <h2 className="text-lg text-blue2 p-4">
          <a href={link}>{title}</a>
        </h2>
      </div>
      <div className="relative p-4 text-base">{children}</div>
    </div>
  );
};

export default SamplesPageBlock;
