import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';

import { DashboardWidgetItems } from 'constants/dashboard';
import { UserTypes } from 'constants/user';
import { useDashboardInteraction } from 'hooks/useDashboardInteraction';
import { DashboardLayout, DashboardPage, DashboardWidget } from 'interfaces/dashboard.interface';
import { selectIsEditMode } from 'modules/dashboard/selector';
import { useAppSelector } from 'modules/store';
import { getNumberOfCols } from 'utils/dashboard';
import { cn } from 'utils/global';

import { DailySalesWidget } from './daily-sales/DailySalesWidget';
import { DashboardWidgetWrapper } from './DashboardWidgetWrapper';
import { ExpiringTenants } from './expiring-tenants/ExpiringTenants';
import { GeneralStatsWidget } from './general-stats/GeneralStatsWidget';
import { IncomeStatisticsWidget } from './income-statistics/IncomeStatisticsWidget';
import { MemberActivity } from './member-activity/MemberActivity';
import { ProfitShareWidget } from './profit-share/ProfitShareWidget';
import { RecentTenants } from './recent-tenants/RecentTenants';
import { RegionalStatsWidget } from './regional-stats/RegionalStatsWidget';
import SalesSummaryWidget from './sales-summary/SalesSummaryWidget';
import { SubscriptionStatisticsWidget } from './subscription-statistics/SubscriptionStatisticsWidget';
import { TopStatsWidget } from './top-stats/TopStatsWidget';
import { EditActions } from '../dashboard-edit-mode/EditActions';

const DASHBOARD_SNIPPETS = (isAdmin: boolean) => [
  {
    id: isAdmin ? DashboardWidgetItems.TOP_STATS_WIDGET_ADMIN : DashboardWidgetItems.TOP_STATS_WIDGET_TENANT,
    component: <TopStatsWidget />,
    userTypes: [UserTypes.TENANT, UserTypes.ADMIN],
  },
  {
    id: DashboardWidgetItems.INCOME_STATISTICS_WIDGET,
    component: <IncomeStatisticsWidget />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    id: DashboardWidgetItems.SUBSCRIPTION_STATISTICS_WIDGET,
    component: <SubscriptionStatisticsWidget />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    id: DashboardWidgetItems.EXPIRING_TENANTS,
    component: <ExpiringTenants />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    id: DashboardWidgetItems.RECENT_TENANTS,
    component: <RecentTenants />,
    userTypes: [UserTypes.ADMIN],
  },
  {
    id: DashboardWidgetItems.SALES_SUMMARY_WIDGET,
    component: <SalesSummaryWidget />,
    userTypes: [UserTypes.TENANT],
  },
  {
    id: DashboardWidgetItems.REGIONAL_STATS_WIDGET,
    component: <RegionalStatsWidget />,
    userTypes: [UserTypes.TENANT],
  },
  {
    id: DashboardWidgetItems.GENERAL_STATS_WIDGET,
    component: <GeneralStatsWidget />,
    userTypes: [UserTypes.TENANT],
  },
  {
    id: DashboardWidgetItems.DAILY_SALES_WIDGET,
    component: <DailySalesWidget />,
    userTypes: [UserTypes.TENANT],
  },
  {
    id: DashboardWidgetItems.PROFIT_SHARE_WIDGET,
    component: <ProfitShareWidget />,
    userTypes: [UserTypes.TENANT],
  },
  {
    id: DashboardWidgetItems.MEMBER_ACTIVITY,
    component: <MemberActivity />,
    userTypes: [UserTypes.TENANT],
  },
];

interface Props {
  isAdmin: boolean;
  onDeleteWidget: (widgetId: DashboardWidgetItems) => () => void;
  layouts: Layouts;
  dashboardLayout: DashboardLayout;
  widgets: DashboardWidget[];
  page: Omit<DashboardPage, 'widgets'>;
  userRole: UserTypes;
  handleLayoutChange: (layout: Layout[], allLayouts: Layouts) => void;
}

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const DashboardWidgetsLayout = ({
  isAdmin,
  userRole,
  layouts,
  handleLayoutChange,
  widgets,
  page,
  dashboardLayout,
  onDeleteWidget,
}: Props) => {
  const isInteractable = useDashboardInteraction();
  const isEditMode = useAppSelector(selectIsEditMode);

  const isEditable = isEditMode && isInteractable;

  return (
    <>
      <ResponsiveReactGridLayout
        layouts={layouts}
        useCSSTransforms
        breakpoints={{ md: 690, xxs: 0 }}
        cols={{ md: getNumberOfCols(widgets), xxs: 1 }}
        rowHeight={30}
        compactType="vertical"
        isResizable={isEditable}
        isDraggable={isEditable}
        onLayoutChange={handleLayoutChange}
      >
        {DASHBOARD_SNIPPETS(isAdmin)
          .filter(({ userTypes, id }) => userTypes.includes(userRole) && layouts.md?.some((layout) => layout.i === id))
          .map(({ id, component }) => (
            <div key={id} className={cn('relative', { 'cursor-grab': isEditMode })}>
              <DashboardWidgetWrapper isEditMode={isEditMode} widgetId={id} onDelete={onDeleteWidget}>
                {component}
              </DashboardWidgetWrapper>
            </div>
          ))}
      </ResponsiveReactGridLayout>
      {isEditMode && (
        <EditActions
          dashboardName={dashboardLayout?.dashboardName}
          pages={dashboardLayout?.pages}
          currentPage={page}
          currentLayouts={layouts.md}
        />
      )}
    </>
  );
};
