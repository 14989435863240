import React, { useCallback, useEffect } from 'react';

import { debounce } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import {
  useFetchCalculateRepaymentMutation,
  useGetCreateRepaymentMutation,
  useGetPaymentTypesQuery,
} from 'apis/gocardless.apis';
import { DEFAULT_LOAN_LENGTH_MONTHS, getPaymentTypeFormFields, MAX_LOAN_LENGTH_MONTHS } from 'constants/gocardless';
import { useOpen } from 'hooks/useOpen';
import {
  PAY_FORM_FIELDS,
  SavePayPayload,
  CalculateRepaymentPayload,
  CalculateRepaymentResponse,
  PAY_SUMMARY_FORM_FIELDS,
} from 'interfaces/integrations/gocardless.interface';
import { CommonError, OptionType } from 'interfaces/shared.interface';
import { Modal, FormContent, ErrorModal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { isDirectDebitPaymentType, isInstantPaymentType } from 'utils/payment';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const CreatePayModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { ...savePayMethods } = useForm<SavePayPayload>({
    reValidateMode: 'onSubmit',
  });
  const [fetchCalculations, { error: fetchCalculateRepaymentError }] = useFetchCalculateRepaymentMutation();
  const [getCreateRepayment, { error: createRepaymentError }] = useGetCreateRepaymentMutation();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();
  const { error: errorCalulateRepaymentData } = (fetchCalculateRepaymentError as CommonError)?.data || {};
  const { error: errorRepaymentData } = (createRepaymentError as CommonError)?.data || {};

  const {
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = savePayMethods;
  const { data, isLoading } = useGetPaymentTypesQuery();

  const watchAmount = parseFloat(String(watch(PAY_FORM_FIELDS.AMOUNT))) || 0;
  const watchPaymentTypeObject = watch(PAY_FORM_FIELDS.PAYMENT_TYPE);
  const watchPaymentType: number =
    typeof watchPaymentTypeObject === 'object' && watchPaymentTypeObject !== null
      ? parseInt(String((watchPaymentTypeObject as OptionType).value))
      : parseInt(String(watchPaymentTypeObject));
  const watchLoanLengthMonths = parseInt(String(watch(PAY_FORM_FIELDS.LOAN_LENGTH_MONTHS)));

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const isDirectDebit = isDirectDebitPaymentType(watchPaymentType);
  const isInstantPayment = isInstantPaymentType(watchPaymentType);

  const debouncedFetchCalculatedRepayment = useCallback(
    debounce(async () => {
      try {
        const amountNumber = parseFloat(String(watchAmount));
        const loanLengthMonths = isDirectDebit ? watchLoanLengthMonths || DEFAULT_LOAN_LENGTH_MONTHS : 0;

        const response = await fetchCalculations({
          amount: amountNumber,
          loanLengthMonths: loanLengthMonths > MAX_LOAN_LENGTH_MONTHS ? MAX_LOAN_LENGTH_MONTHS : loanLengthMonths || 0,
          paymentType: watchPaymentType,
        }).unwrap();

        if (response) {
          const repaymentData = response as CalculateRepaymentResponse;
          setValue(PAY_SUMMARY_FORM_FIELDS.TOTAL_AMOUNT, repaymentData.result.totalAmount);
          setValue(PAY_SUMMARY_FORM_FIELDS.FIRST_PAYMENT_AMOUNT, repaymentData.result.firstPaymentAmount);
          setValue(PAY_SUMMARY_FORM_FIELDS.RECURRING_PAYMENT_AMOUNT, repaymentData.result.recurringPaymentAmount);
          setValue(PAY_SUMMARY_FORM_FIELDS.REPAYMENT_TERM_LENGTH, repaymentData.result.repaymentTermLength);
        } else {
          handleOnOpenErrorModal();
        }
      } catch (error) {
        handleOnOpenErrorModal();
      }
    }, 500),
    [watchAmount, watchPaymentType, watchLoanLengthMonths, isDirectDebit]
  );

  useEffect(() => {
    return () => {
      debouncedFetchCalculatedRepayment.cancel();
    };
  }, [debouncedFetchCalculatedRepayment]);

  useEffect(() => {
    if (watchLoanLengthMonths || (watchAmount && watchPaymentType)) {
      debouncedFetchCalculatedRepayment();
    }
  }, [watchPaymentType, watchLoanLengthMonths, watchAmount]);

  const handleSubmit = async () => {
    try {
      const payload: CalculateRepaymentPayload = {
        amount: watchAmount,
        loanLengthMonths: watchLoanLengthMonths || 0,
        paymentType: watchPaymentType,
      };

      const response = await getCreateRepayment(payload).unwrap();
      if (response && response.result) {
        window.location.href = response.result;
      } else {
        handleOnOpenErrorModal();
      }
    } catch (error) {
      handleOnOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorRepaymentData?.message || errorCalulateRepaymentData?.message}
        description={errorRepaymentData?.details || errorCalulateRepaymentData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
      <Modal
        dataCy="create-payment-modal"
        isOpen={isOpen}
        isDisableOutsideClick
        onClose={onCloseModal}
        heading="Pay with GoCardless"
        bodyClassName="lg:w-1/3 md:w-1/2"
      >
        <FormProvider {...savePayMethods} reset={reset}>
          <form data-cy="create-payment-modal-form" onSubmit={savePayMethods.handleSubmit(handleSubmit)}>
            <FormContent
              isLoading={isLoading}
              fields={getPaymentTypeFormFields(data?.result, isInstantPayment, isDirectDebit)}
              register={register}
              errors={errors}
            />
            <ModalControlButtons
              disabled={!watchPaymentTypeObject}
              isShownSubmit
              isLoading={isLoading}
              onClose={onCloseModal}
            />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default CreatePayModal;
