import { BaseFilterValues, OptionType } from './shared.interface';

export enum GDPR_ADMIN_COLUMNS {
  TENANT_ID = 'tenantId',
  TENANT_NAME = 'tenantName',
  STATUS = 'status',
  CREATION_TIME = 'dateCreated',
  ACTIONS = 'actions',
}

export enum GDPR_TENANT_COLUMNS {
  USER_ID = 'userId',
  USER_NAME = 'userName',
  STATUS = 'status',
  CREATION_TIME = 'dateCreated',
  ACTIONS = 'actions',
}

export enum GDPR_FILTERS_FORM_FIELD_NAMES {
  SELECT_STATUS = 'statusId',
}

export interface GDPRFilterFormValues extends BaseFilterValues {
  [GDPR_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: OptionType;
}

export interface RequestsToBeForgotten {
  id: number;
  tenantId: number;
  tenantName: string;
  statusId: number;
  statusName: string;
  dateCreated: string;
  userId: number;
  userFirstName: string;
  userLastName: string;
}

export interface RequestsToBeForgottenResponse {
  result: {
    totalCount: number;
    requests: RequestsToBeForgotten[];
  };
}

export interface ApproveRequestParams {
  isAdmin: boolean;
  requestId: number;
}
