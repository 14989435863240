import { GetInvoiceLineItems } from 'interfaces/integrations/quickBooks.interface';
import { TableData, TableRow } from 'shared-components';
import { useFormatPriceFunction } from 'utils/global';

type Props = {
  invoiceData: GetInvoiceLineItems;
};

const InvoiceRow = ({ invoiceData }: Props) => {
  const { description, quantity, unitPrice, tax, amount } = invoiceData;

  return (
    <>
      <TableRow dataCy="invoice-table-row">
        <TableData dataCy="invoice-description">{description}</TableData>
        <TableData dataCy="invoice-qty">{quantity}</TableData>
        <TableData dataCy="invoice-unit-price">{useFormatPriceFunction(unitPrice)}</TableData>
        <TableData dataCy="invoice-tax">{useFormatPriceFunction(tax)}</TableData>
        <TableData dataCy="invoice-amount">{useFormatPriceFunction(amount)}</TableData>
      </TableRow>
    </>
  );
};

export default InvoiceRow;
