import UserPng from 'assets/png/user.png';
import { cn } from 'utils/global';

import Skeleton from './skeleton/Skeleton';

export const COMMON_AVATAR_CLASSNAME = 'w-7 h-7 rounded-full';

interface Props {
  isLoading?: boolean;
  imagePath?: string | null;
  className?: string;
}

const Avatar = ({ imagePath, isLoading, className }: Props) => {
  if (isLoading) {
    return <Skeleton className={cn(COMMON_AVATAR_CLASSNAME, className)} />;
  } else {
    return <img className={cn(COMMON_AVATAR_CLASSNAME, className)} src={imagePath || UserPng} alt="userImage" />;
  }
};

export default Avatar;
