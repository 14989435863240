import { RegionalStatistic } from 'interfaces/regional-stats.interface';

import { RegionalStatsTableRow } from './RegionalStatsTableRow';

interface Props {
  regionalStats?: RegionalStatistic[];
}

export const RegionalStatsTableContent = ({ regionalStats }: Props) => {
  return (
    <>
      {regionalStats?.length
        ? regionalStats?.map((statistic, index) => (
            <RegionalStatsTableRow
              key={`${statistic.countryName}-${statistic.totalPrice}`}
              index={index}
              {...statistic}
            />
          ))
        : null}
    </>
  );
};
