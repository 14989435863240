import { useCallback, useState } from 'react';

export type UseOpenReturnValues = [boolean, VoidFunction, VoidFunction];

export const useOpen = (initialValue?: boolean): UseOpenReturnValues => {
  const [isOpen, setIsOpen] = useState(initialValue || false);

  const handleOpen = useCallback(() => setIsOpen(true), []);

  const handleClose = useCallback(() => setIsOpen(false), []);

  return [isOpen, handleOpen, handleClose];
};
