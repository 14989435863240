import { AUDIT_LOGS_TABS } from 'constants/audit-logs';
import Tabs from 'shared-components/Tabs';

const AuditLogs = () => {
  return (
    <>
      <Tabs tabs={AUDIT_LOGS_TABS} />
    </>
  );
};

export default AuditLogs;
