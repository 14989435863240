import { useNavigate } from 'react-router-dom';

import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { SupportTickets } from 'interfaces/support-tickets.interfaces';
import { DropdownMenu, TableData, TableRow } from 'shared-components';
import TableDataPair from 'shared-components/table/TableDataPair';
import { getFormattedDate } from 'utils/dates';
import { getSupportRequestChatLink } from 'utils/url';

type Props = {
  supportTicketsData: SupportTickets;
};

const ResolvedTicketsTabRow = ({ supportTicketsData }: Props) => {
  const navigate = useNavigate();

  const { id, supportRequesterName, supportRequesterTenantName, requestSubject, description, supportResponderName } =
    supportTicketsData;
  const menuActions = [
    {
      dataCy: 'menu-item-details-button',
      name: 'ViewDetails',
      actionOnClick: () => {
        navigate(getSupportRequestChatLink(id));
      },
    },
  ];

  return (
    <>
      <TableRow dataCy="resolved-tickets-table-row">
        <TableData className="md:max-w-[20rem]  lg:w-[50%]">
          <TableDataPair labelClassName={'font-bold'} label="Name">
            {supportRequesterName}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="Tenant">
            {supportRequesterTenantName}
          </TableDataPair>
        </TableData>
        <TableData className="max-w-[12rem]">
          <TableDataPair labelClassName={'font-bold'} label="Subject">
            {requestSubject}
          </TableDataPair>
          <TableDataPair
            dataCy="table-data-resolved-ticket-description"
            labelClassName={'font-bold'}
            label="Description"
          >
            {description}
          </TableDataPair>
        </TableData>
        <TableData className="md:max-w-[9rem] lg:max-w-full">
          {supportResponderName ? supportResponderName : supportRequesterName}
        </TableData>
        <TableData className="md:w-[13rem] lg:w-[10%]">
          {getFormattedDate(supportTicketsData.creationTime, DAY_FORMAT_WITH_TIME)}
        </TableData>
        <TableData className="w-1/12">
          <DropdownMenu elements={menuActions} />
        </TableData>
      </TableRow>
    </>
  );
};

export default ResolvedTicketsTabRow;
