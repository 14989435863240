import { ReactNode } from 'react';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { CLEAR_BUTTON_STYLES } from 'constants/global';
import { Button } from 'shared-components/index';
import { cn } from 'utils/global';

interface Props {
  isOpen: boolean;
  children?: ReactNode;
  handleClearFields: VoidFunction;
  openFilter?: boolean;
}
const FilterFieldsContainer = ({ children, isOpen, handleClearFields, openFilter }: Props) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  const sharedFilterClassName = 'flex bg-white dark:bg-darkBlue';
  const sharedFilterButtonsClassName = 'flex bg-lightGray7 dark:bg-darkBlue';

  if (!openFilter) {
    return (
      <>
        <div className="border border-lightGray7 dark:border-none shadow-md mb-8 rounded-md">
          <div className={cn('py-4', sharedFilterClassName)}>{children}</div>
          <div className={cn('justify-between px-4 py-2', sharedFilterButtonsClassName)}>
            <Button type="button" className={CLEAR_BUTTON_STYLES} onClick={handleClearFields}>
              {t('Clear')}
            </Button>
            <Button dataCy="bottom-submit-search-filter">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="rounded-md flex mb-4">
      <div className={(cn('pb-4'), sharedFilterClassName)}>{children}</div>
      <div
        className={cn(
          'bg-lightGray7 dark:bg-darkBlue items-center top-3 relative h-full self-center',
          sharedFilterButtonsClassName
        )}
      >
        <Button dataCy="bottom-submit-search-filter" className="mr-4">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
        <Button
          type="button"
          className="text-white hover:bg-lightGray6 bg-blue border-blue3 hover:bg-linearGradient"
          onClick={handleClearFields}
        >
          {t('Clear')}
        </Button>
      </div>
    </div>
  );
};

export default FilterFieldsContainer;
