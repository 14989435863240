import { TopStatItemVariant } from 'constants/dashboard';
import { TopStatsWidgetItem } from 'interfaces/dashboard.interface';
import { LabelWithValue, LoadingOverlay, ProgressLine } from 'shared-components';
import { cn } from 'utils/global';

import TopStatsBox from './TopStatsBox';
import ValueWithCountUp from './ValueWithCountUp';

interface Props<T> extends TopStatsWidgetItem<T> {
  isLoading: boolean;
  value: number;
  percent: number;
}

export const TopStatItem = <T,>({
  isLoading,
  value,
  percent,
  isCurrency,
  valueClassName,
  progressClassName,
  progressLabel = 'Change',
  progressLabelClassName,
  progressLabelValueClassName,
  variant = TopStatItemVariant.WITH_DATA_OVER_BAR,
  ...props
}: Props<T>) => {
  const isWithDataOverBar = variant === TopStatItemVariant.WITH_DATA_OVER_BAR;

  return (
    <div className="overflow-hidden">
      <TopStatsBox
        stat={
          isWithDataOverBar ? (
            ''
          ) : (
            <ValueWithCountUp
              dataCy="top-stats-count"
              className={valueClassName}
              value={value}
              isCurrency={isCurrency}
            />
          )
        }
        {...props}
      >
        <LoadingOverlay isLoading={isLoading} />
        {isWithDataOverBar && (
          <ValueWithCountUp dataCy="top-stats-count" className={valueClassName} value={value} isCurrency={isCurrency} />
        )}
        <ProgressLine progress={percent} progressClassName={progressClassName} />
        <LabelWithValue
          bodyClassName="justify-between !text-gray2 whitespace-nowrap"
          labelClassName={cn('mt-1', progressLabelClassName)}
          valueClassName={cn('!text-gray2 font-semibold', progressLabelValueClassName)}
          label={progressLabel}
          value={`${percent}%`}
          withColon={false}
        />
      </TopStatsBox>
    </div>
  );
};
