import { FormattedPermission, Permission } from 'interfaces/permissions.interface';

export const getFormattedPermissions = ({
  permissions,
  parentName = null,
}: {
  permissions: Permission[];
  parentName?: string | null;
}) => {
  if (!permissions?.length) {
    return [];
  }

  return permissions
    ?.filter((item) => item?.parentName === parentName)
    .map(({ name, displayName }) => {
      const children: FormattedPermission[] = getFormattedPermissions({ permissions, parentName: name });
      return {
        id: name,
        name: displayName,
        ...(children.length ? { children } : {}),
      };
    });
};
