import { COOKIE_KEYS, LOCAL_STORAGE_KEYS } from 'constants/common';

export const getAccessToken = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
};

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN, accessToken);
};

export const resetUserStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
};

export const getAuthCookie = (name: string) => {
  const nameEQ = name + '=';
  const splitCookies = document.cookie.split(';');
  const currentCookie = splitCookies.find((c) => c.trim().includes(nameEQ));

  if (currentCookie) {
    return currentCookie.substring(nameEQ.length + 1);
  }

  return null;
};

export const getCookie = (name: string) => {
  const nameEQ = name + '=';
  const splitCookies = document.cookie.split(';');
  const currentCookie = splitCookies.find((c) => c.trim().includes(nameEQ));

  if (currentCookie) {
    return currentCookie.substring(nameEQ.length);
  }

  return null;
};

export const setAccessTokenToCookie = (accessToken: string, encryptedAccessToken: string, expireDate: number) => {
  if (expireDate) {
    const date = new Date();
    date.setTime(date.getTime() + expireDate);
    const expires = '; expires=' + date.toUTCString();

    document.cookie = 'enc_auth_token=' + encryptedAccessToken + expires + '; path=/';
    document.cookie = 'Abp.AuthToken=' + accessToken + expires + '; path=/';
  }
};

export const clearCookies = (name: string) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const resetUserAuthStorage = () => {
  clearCookies(COOKIE_KEYS.AUTH_TOKEN);
  clearCookies(COOKIE_KEYS.ENC_AUTH_TOKEN);
  clearCookies(COOKIE_KEYS.LOCALIZATION_CULTURE_NAME);
};
