import { RegisterOptions } from 'react-hook-form';

import { FieldTypes, FormItem } from 'interfaces/shared.interface';
import { SIGN_UP_FROM_FIELDS } from 'interfaces/sign-up.interface';
import SignUpCheckboxLabel from 'page-components/sign-up/SignUpCheckboxLabel';
import { Checkbox, Input } from 'shared-components';

import { EMAIL_PATTERN, PASSWORD_PATTERN } from './global';

export const SIGN_UP_PAGE_TITLE = 'Register';
export const getSignUpFormFields = (currentPassword: string): FormItem[] => [
  {
    name: SIGN_UP_FROM_FIELDS.tenantName,
    label: 'TenantName',
    type: FieldTypes.TEXT,
    component: Input,
    inputClassName: 'w-full',
    className: 'mb-4 col-span-2',
    required: true,
    validation: {
      required: 'TenantNameRequired',
      maxLength: {
        value: 64,
        message: 'ExceedMaxTenantNameLength',
      },
    },
    dataCy: 'tenant-name-input',
  },
  {
    name: SIGN_UP_FROM_FIELDS.firstName,
    label: 'AdminFirstName',
    type: FieldTypes.TEXT,
    inputClassName: 'w-full',
    required: true,
    component: Input,
    className: 'mb-4 col-span-2 md:col-span-1 md:w-[96%]',
    validation: {
      required: 'FirstNameRequired',
      maxLength: {
        value: 64,
        message: 'ExceedMaxTenantNameLength',
      },
    },
    dataCy: 'first-name-input',
  },
  {
    name: SIGN_UP_FROM_FIELDS.lastName,
    label: 'AdminLastName',
    type: FieldTypes.TEXT,
    inputClassName: 'w-full md:ml-2',
    labelClassName: 'md:ml-2',
    errorClassName: 'md:ml-2',
    required: true,
    component: Input,
    className: 'mb-4 col-span-2 md:col-span-1 md:w-[96%]',
    validation: {
      required: 'LastNameRequired',
      maxLength: {
        value: 64,
        message: 'ExceedMaxLastNameLength',
      },
    },
    dataCy: 'last-name-input',
  },
  {
    name: SIGN_UP_FROM_FIELDS.userEmail,
    label: 'AdminEmailAddress',
    type: FieldTypes.TEXT,
    inputClassName: 'w-full',
    required: true,
    className: 'mb-4 col-span-2',
    component: Input,
    validation: {
      required: 'EmailRequired',
      pattern: { message: 'InvalidEmailAddress', value: EMAIL_PATTERN },
      maxLength: {
        value: 256,
        message: 'ExceedMaxEmailLength',
      },
    },
    dataCy: 'email-address-input',
  },
  {
    name: SIGN_UP_FROM_FIELDS.confirmPassword,
    label: 'RepeatAdminPassword',
    type: FieldTypes.PASSWORD,
    inputClassName: 'w-full',
    required: true,
    className: 'mb-4 col-span-2',
    component: Input,
    validation: confirmPasswordValidation(currentPassword),
    dataCy: 'repeat-password-input',
  },
  {
    name: SIGN_UP_FROM_FIELDS.termsAndConditions,
    component: Checkbox,
    label: SignUpCheckboxLabel,
    labelClassName: 'text-darkGray',
    className: 'mb-4 col-span-2',
    dataCy: 'terms-and-conditions-input',
  },
];

export const PASSWORD_VALIDATION: RegisterOptions = {
  pattern: {
    message: ' ',
    value: PASSWORD_PATTERN,
  },
};

export const confirmPasswordValidation = (currentPassword?: string | null, ignoreIfEmptyPassword?: boolean) => {
  return {
    required: !ignoreIfEmptyPassword ? 'PasswordRequired' : undefined,
    validate: (confirmPassword?: string | null) => {
      if (ignoreIfEmptyPassword && !currentPassword) {
        return true;
      }

      return currentPassword === confirmPassword || 'PasswordsDoNotMatch';
    },
  };
};
