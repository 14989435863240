import { twMerge } from 'tailwind-merge';

import { Currency, getFormatPrice } from 'constants/global';
import useCountUp from 'hooks/useCountUp';

interface Props {
  value?: number;
  isCurrency?: boolean;
  className?: string;
  dataCy?: string;
}

const ValueWithCountUp = ({ value, isCurrency, className, dataCy }: Props) => {
  const [count, refreshAnimation] = useCountUp({ duration: 1000, targetValue: value || 0 });

  if (value === undefined) {
    return <p className="h-5" />;
  }

  return (
    <p
      data-cy={dataCy}
      className={twMerge('text-sm font-bold text-blue2', className)}
      onClick={count > 0 ? refreshAnimation : undefined}
    >
      {isCurrency ? getFormatPrice(Number(count), Currency.GBP, 0) : count}
    </p>
  );
};

export default ValueWithCountUp;
