import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUploadInvoiceMutation } from 'apis/invoices.api';
import { UPLOAD_INVOICES_FORM_FIELDS } from 'constants/invoices';
import useFileUpload from 'hooks/useFileUpload';
import { useOpen } from 'hooks/useOpen';
import { INVOICES_FORM_FIELDS, InvoicesFormValues, UploadInvoicePayload } from 'interfaces/invoices.interface';
import { CommonError } from 'interfaces/shared.interface';
import { FormContent, Modal, ErrorMessage, ErrorModal } from 'shared-components';
import FileUploaderButton from 'shared-components/FileUploaderButton';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  refetch: VoidFunction;
  isLoading: boolean;
}

const UploadInvoiceModal = ({ isOpen, handleClose, refetch, isLoading }: Props) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { ...createInvoiceMethods } = useForm<UploadInvoicePayload>({ reValidateMode: 'onSubmit' });

  const [uploadInvoice, { error: uploadInvoiceError }] = useUploadInvoiceMutation();
  const errorData = (uploadInvoiceError as CommonError)?.data || {};

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fileUploadButtonText, setFileUploadButtonText] = useState(t('Upload'));

  const { handleFileUpload, error: fileUploadError } = useFileUpload();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = createInvoiceMethods;

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmit = async (formData: InvoicesFormValues) => {
    try {
      const payload: UploadInvoicePayload = {
        ...formData,
      };

      await uploadInvoice(payload).unwrap();
      refetch();
      handleClose();
      reset();
      setFileUploadButtonText('Upload');
      successNotify('SavedSuccessfully');
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleUploadFile = async (files: FileList) => {
    const uploadResult = await handleFileUpload(files);

    if (!uploadResult) {
      handleOpenErrorModal();
      return;
    }

    const { fileBase64String, fileName } = uploadResult;

    createInvoiceMethods.setValue(INVOICES_FORM_FIELDS.FILE_NAME, fileName);
    createInvoiceMethods.setValue(INVOICES_FORM_FIELDS.FILE_BASE, fileBase64String);

    setFileUploadButtonText(fileName);
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message || fileUploadError}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <Modal
        dataCy="upload-invoice-modal"
        isOpen={isOpen}
        isDisableOutsideClick
        onClose={handleClose}
        heading="UploadInvoice"
        bodyClassName="lg:w-1/3 md:w-1/2"
      >
        <FormProvider {...createInvoiceMethods}>
          <form
            data-cy="upload-invoice-modal-form"
            onSubmit={(e) => {
              setFormSubmitted(true);
              handleSubmit(onSubmit)(e);
            }}
          >
            <FormContent fields={UPLOAD_INVOICES_FORM_FIELDS} register={register} errors={errors} />
            <FileUploaderButton
              className="w-full text-ellipsis whitespace-nowrap overflow-hidden"
              isIcon={false}
              fileType="pdf"
              buttonText={fileUploadButtonText}
              handleUpload={handleUploadFile}
            />
            {formSubmitted && fileUploadButtonText === t('Upload') && (
              <ErrorMessage>{t('FileCanNotBeEmptyUse', { 0: 'PDF' })}</ErrorMessage>
            )}
            <ModalControlButtons isShownSubmit isLoading={isLoading} onClose={onCloseModal} />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default UploadInvoiceModal;
