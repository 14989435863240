import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetAllWithBaseInfoQuery } from 'apis/subscriptions-admin.api';
import { CREATE_SUBSCRIPTION_RADIO_INPUTS_OPTIONS, SUBSCRIPTION_LEGEND } from 'constants/subscriptions-admin';
import { BASE_RADIO_BUTTON_RADIO_VALUES, OptionsTypeValue } from 'interfaces/shared.interface';
import { SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES } from 'interfaces/subscriptions-admin.interface';
import { RadioInputSet, SelectFormInput } from 'shared-components';

interface Props {
  isEditingType?: boolean;
}

const SubscriptionManagementRadioInputWithSelect = ({ isEditingType }: Props) => {
  const { t } = useTranslation();

  const { register, setValue, getValues, watch, control, unregister } = useFormContext();

  const { data, isLoading } = useGetAllWithBaseInfoQuery('onlyFreeItems=true');
  const watchAssignedSubscription = watch(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ASSIGNED_SUBSCRIPTION);

  const { result: baseSubscriptionsResult } = data || {};

  const selectOptions = baseSubscriptionsResult?.map((item) => ({ label: item.displayText, value: item.value }));
  const expiringSubscriptionId = getValues(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION_ID);

  const defaultSelectedOption = () => {
    if (!selectOptions) {
      return undefined;
    }

    if (isEditingType) {
      return selectOptions.find((item) => Number(item.value) === expiringSubscriptionId) || selectOptions[0];
    }

    return selectOptions.find((item) => Number(item.value) === expiringSubscriptionId);
  };

  const handleSelectValue = (options: OptionsTypeValue) => {
    setValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION_ID, options.value);
  };

  useEffect(() => {
    if (watchAssignedSubscription === BASE_RADIO_BUTTON_RADIO_VALUES.NO) {
      unregister(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION);
    }
  }, [watchAssignedSubscription, unregister]);

  if (isLoading) {
    return null;
  }

  return (
    <div className="mt-4 ">
      <RadioInputSet
        {...register(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ASSIGNED_SUBSCRIPTION)}
        radioOptions={CREATE_SUBSCRIPTION_RADIO_INPUTS_OPTIONS}
        radioLegend={SUBSCRIPTION_LEGEND}
      />
      {watchAssignedSubscription === BASE_RADIO_BUTTON_RADIO_VALUES.YES && (
        <SelectFormInput
          dataCy="expiring-subscription-select"
          control={control}
          onSelectValue={handleSelectValue}
          name={SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.EXPIRING_SUBSCRIPTION}
          options={selectOptions}
          defaultValue={defaultSelectedOption()}
          placeholder={t('ExpiringSubscription')}
        />
      )}
    </div>
  );
};

export default SubscriptionManagementRadioInputWithSelect;
