import { useCallback, useEffect, useState } from 'react';

import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDownloadFileMutation } from 'apis/support-tickets.api';
import { Button } from 'shared-components';
import { joinMimeTypeAndDataToBase64 } from 'utils/files';
import { downloadDocument } from 'utils/global';

interface Props {
  fileId: string;
  fileName: string;
  fileType: string;
}

const ChatAttachment = ({ fileId, fileName, fileType }: Props) => {
  const [imageBlobUrl, setImageBlobUrl] = useState<string | undefined>();

  const [downloadFile, { isLoading }] = useDownloadFileMutation();

  const onClickDownload = async () => {
    const fileData = await downloadFile({ fileId, fileName }).unwrap();
    const contentPath = fileData?.result?.content;

    downloadDocument(contentPath, fileName, fileType);
  };

  const isImage = (path: string) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(path);
  };

  const fetchFileData = useCallback(async () => {
    const fileData = await downloadFile({ fileId, fileName }).unwrap();
    const blobContent = fileData?.result?.content;

    const blobUrl = joinMimeTypeAndDataToBase64({ data: blobContent, mimeType: fileType });

    if (blobUrl) {
      setImageBlobUrl(blobUrl);
    }
  }, [downloadFile, fileId, fileName, fileType]);

  useEffect(() => {
    if (isImage(fileName)) {
      fetchFileData();
    }
  }, [fetchFileData, fileId, fileName]);

  return (
    <div>
      {isImage(fileName) && <img src={imageBlobUrl} alt={fileName} />}

      <Button isLoading={isLoading} onClick={onClickDownload}>
        <FontAwesomeIcon className="mr-1" icon={faFileArrowDown} />
        {fileName}
      </Button>
    </div>
  );
};

export default ChatAttachment;
