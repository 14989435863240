import { RefObject, useState } from 'react';

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useTranslation } from 'react-i18next';

import { usePostCapturePaymentMutation, usePostCreateOrderMutation } from 'apis/paypal.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, LoadingOverlay } from 'shared-components';
import { cn } from 'utils/global';
import { infoNotify, successNotify } from 'utils/notifications';

interface Props {
  amount: RefObject<number>;
  disabled: boolean;
}

const PayPalButtonComponent = ({ amount, disabled }: Props) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [isPayPalLoading, setIsPaypalWidgetLoading] = useState(true);

  const [createPayPalOrder, { error: createPayPalOrderError }] = usePostCreateOrderMutation();
  const [capturePayPalOrder, { error: capturePayPalOrderError }] = usePostCapturePaymentMutation();

  const errorPaymentPayPalData = (createPayPalOrderError as CommonError)?.data || {};
  const errorCapturePayPalData = (capturePayPalOrderError as CommonError)?.data || {};

  const errors = errorPaymentPayPalData?.error ? errorPaymentPayPalData : errorCapturePayPalData;

  const clientId = process.env.REACT_APP_PAY_PAL_CLIEND_ID;

  if (!clientId) {
    throw new Error('PayPal client ID is not defined in environment variables.');
  }

  const initialOptions = {
    clientId,
    currency: 'GBP',
    intent: 'capture',
  };

  const createOrder = async (): Promise<string> => {
    try {
      if (!amount.current) {
        throw new Error('Amount is not set');
      }
      const response = await createPayPalOrder({ amount: amount.current }).unwrap();
      return response.result.orderId;
    } catch (error) {
      handleOpenErrorModal();
      throw error;
    }
  };

  const onApprove = async (actions: any) => {
    try {
      const orderId = actions.orderID;
      await capturePayPalOrder({ orderId }).unwrap();
      successNotify(t('CreatedSuccessfully'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <div className="relative">
      <LoadingOverlay isLoading={isPayPalLoading} />
      <div className={cn('px-5 pt-5 bg-white rounded-sm', { 'opacity-20': disabled })}>
        <ErrorModal
          errorMessage={errors?.error?.message}
          description={errors?.error?.details}
          isOpen={isErrorModalOpen}
          handleClose={handleCloseErrorModal}
        />

        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            className={cn('', { 'pointer-events-none': disabled })}
            onCancel={() => infoNotify('OrderPaymentHasBeenCanceled')}
            style={{ layout: 'vertical', color: 'gold', shape: 'rect', label: 'paypal' }}
            createOrder={createOrder}
            onApprove={onApprove}
            disabled={disabled}
            data-cy="paypal-button"
            onInit={() => setIsPaypalWidgetLoading(false)}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  );
};

export default PayPalButtonComponent;
