import { FC, ReactNode } from 'react';

import { Link, LinkProps } from 'react-router-dom';

import { ButtonVariants, ButtonVariantStyles } from 'constants/common';
import { cn } from 'utils/global';

interface Props extends LinkProps {
  children: ReactNode;
  className?: string;
  variant?: ButtonVariants;
  to: string;
}

const LinkButton: FC<Props> = ({ children, variant = ButtonVariants.MAIN, className, to, ...props }) => {
  return (
    <Link to={to} className={cn('flex items-center', ButtonVariantStyles[variant], className)} {...props}>
      {children}
    </Link>
  );
};

export default LinkButton;
