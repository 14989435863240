import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import {
  SIGN_IN_PAGE_ACTIVATE_EMAIL_TEXT,
  SIGN_IN_PAGE_FORGOT_PASSWORD_TEXT,
  SIGN_IN_PAGE_REGISTER_TEXT,
  SIGN_IN_PAGE_TITLE,
} from 'constants/sign-in';
import { SignInForm } from 'page-components/sign-in';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <AuthFormContainer title={t(SIGN_IN_PAGE_TITLE)}>
      <SignInForm />
      <div className="flex flex-col">
        <div data-cy="sign-in-extra-link" className="my-[-1px]">
          <Link to={ROUTES.forgotPassword} className="text-blue2 hover:text-darkBlue5 text-sm">
            {t(SIGN_IN_PAGE_FORGOT_PASSWORD_TEXT)}
          </Link>
        </div>
        <div data-cy="sign-in-extra-link" className="my-[-1px]">
          <Link to={ROUTES.activateEmail} className="text-blue2 hover:text-darkBlue5 text-sm">
            {t(SIGN_IN_PAGE_ACTIVATE_EMAIL_TEXT)}
          </Link>
        </div>
        <div data-cy="sign-in-extra-link" className="my-[-1px]">
          <Link to={ROUTES.signUp} className="text-blue2 hover:text-darkBlue5 text-sm">
            {t(SIGN_IN_PAGE_REGISTER_TEXT)}
          </Link>
        </div>
      </div>
    </AuthFormContainer>
  );
};

export default SignIn;
