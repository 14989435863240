import { TableColumn } from 'interfaces/shared.interface';
import { TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS } from 'interfaces/tenants.interface';

export const PAYMENT_HISTORY_COLUMNS_TITLES = {
  [TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.SUBSCRIPTION]: 'Subscription',
  [TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.AMOUNT]: 'Amount',
  [TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.STATUS]: 'Status',
  [TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.PERIOD]: 'Period',
  [TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.PAYMENT_DATE]: 'PaymentDateColumn',
};

export const TENANT_PAYMENT_HISTORY_COLUMNS: TableColumn[] = [
  {
    columnId: TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.SUBSCRIPTION,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.SUBSCRIPTION],
    width: 'w-1/5',
  },
  {
    columnId: TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.AMOUNT,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.AMOUNT],
    width: 'w-1/5',
  },
  {
    columnId: TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.STATUS,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.STATUS],
    width: 'w-1/5',
  },
  {
    columnId: TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.PERIOD,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.PERIOD],
    width: 'w-1/5',
  },
  {
    columnId: TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.PAYMENT_DATE,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS.PAYMENT_DATE],
    width: 'w-1/5',
  },
];
