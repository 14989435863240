import { Carousel } from 'primereact/carousel';
import { useTranslation } from 'react-i18next';

import { useGetSubscriptionForSelectQuery } from 'apis/subscriptions-tenant.api';
import { RESPONSIVE_CAROUSEL_OPTIONS } from 'constants/subscription-tenant';
import { SubscriptionsWithFeatures } from 'interfaces/subscriptions-tenant.interface';
import { Skeleton } from 'shared-components';

import { SubscriptionCard } from './SubscriptionCard';

const SubscriptionSelectCarousel = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetSubscriptionForSelectQuery();

  const subscriptionsWithFeatures = data?.result?.subscriptionsWithFeatures ?? [];
  const allFeaturesLookup = data?.result?.allFeatures.reduce(
    (map, feature) => {
      map[feature.name] = feature.displayName;
      return map;
    },
    {} as Record<string, string>
  );

  if (isLoading) {
    return <Skeleton className="w-full h-96 rounded-md mx-1" />;
  }

  if (!subscriptionsWithFeatures.length && !isLoading) {
    return <div>{t('NoOtherSubscriptions')}</div>;
  }

  const itemTemplate = (subscription: SubscriptionsWithFeatures) => (
    <SubscriptionCard subscription={subscription} allFeaturesLookup={allFeaturesLookup} isLoading={isLoading} />
  );

  return (
    <Carousel
      circular
      value={subscriptionsWithFeatures}
      numVisible={3}
      numScroll={1}
      itemTemplate={itemTemplate}
      autoplayInterval={0}
      showIndicators={true}
      responsiveOptions={RESPONSIVE_CAROUSEL_OPTIONS}
      data-cy="subscription-select-carousel"
    />
  );
};

export default SubscriptionSelectCarousel;
