import React, { useEffect, useRef } from 'react';

import { faArrowsUpDownLeftRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrag } from 'react-dnd';

import { DroppedItem, SignerBlock } from 'interfaces/integrations/docusign.interface';

interface DocusignDroppedItemProps {
  item: DroppedItem;
  signerBlocks: SignerBlock[];
  setDroppedItems?: React.Dispatch<React.SetStateAction<DroppedItem[]>>;
  pageSize: { width: number; height: number };
  scale: number;
}

const DocusignDroppedItem = ({ item, setDroppedItems, pageSize }: DocusignDroppedItemProps) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const displayX = `${(item.x / pageSize.width) * 100}%`;
  const displayY = `${(item.y / pageSize.height) * 100}%`;

  const [, drag] = useDrag({
    type: 'SIGNER_ITEM',
    item: { ...item, type: 'SIGNER_ITEM' },
    collect: (monitor) => ({
      opacity: !!monitor.isDragging(),
    }),
  });

  const handleRemove = () => {
    if (setDroppedItems) {
      setDroppedItems((prevItems) => prevItems.filter((dropped) => dropped.id !== item.id));
    }
  };

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.style.borderColor = item.borderColor;
    }
  }, [item.borderColor]);

  return (
    <div
      key={item.id}
      ref={itemRef}
      className="absolute w-32 border-solid border-3 p-1 rounded-md text-black bg-white z-50 flex justify-between cursor-pointer"
      style={{
        left: displayX,
        top: displayY,
        touchAction: 'none',
        borderColor: item.borderColor,
      }}
    >
      <FontAwesomeIcon
        icon={faArrowsUpDownLeftRight}
        onMouseDown={(event) => {
          event.stopPropagation();
          drag(itemRef.current);
        }}
        className="cursor-move"
        style={{ fontSize: '120%' }}
      />
      <div className="flex flex-col mt-1">
        <FontAwesomeIcon icon={item.icon} />
        {item.label}
      </div>
      <FontAwesomeIcon style={{ fontSize: '110%' }} icon={faXmark} onClick={handleRemove} />
    </div>
  );
};

export default DocusignDroppedItem;
