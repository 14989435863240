import { FieldTypes, FormItem } from 'interfaces/shared.interface';
import { BASE_TENANT_FORM_FIELDS } from 'interfaces/tenants.interface';
import { Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';

export const TENANT_FORM_FIELDS: FormItem[] = [
  {
    name: BASE_TENANT_FORM_FIELDS.TENANT_NAME,
    label: 'TenantName',
    type: FieldTypes.TEXT,
    required: true,
    inputClassName: 'w-full',
    dataCy: 'tenant-name-field',
    className: 'mb-4',
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: BASE_TENANT_FORM_FIELDS.ADMIN_FIRST_NAME,
    label: 'AdminFirstName',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    dataCy: 'admin-first-name-field',
    inputClassName: 'w-full',
    className: 'w-full md:table-cell md:w-screen pb-4',
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: BASE_TENANT_FORM_FIELDS.ADMIN_LAST_NAME,
    label: 'AdminLastName',
    type: FieldTypes.TEXT,
    required: true,
    inputClassName: 'w-full',
    dataCy: 'admin-last-name-field',
    className: 'w-full md:table-cell md:w-screen md:pl-4 pb-4',
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: BASE_TENANT_FORM_FIELDS.ADMIN_EMAIL_ADDRESS,
    label: 'EmailAddress',
    type: FieldTypes.TEXT,
    required: true,
    component: Input,
    dataCy: 'admin-email-address-field',
    inputClassName: 'w-full',
    className: 'mb-4',
    validation: {
      required: 'EmailRequired',
      pattern: { message: 'InvalidEmailAddress', value: EMAIL_PATTERN },
      maxLength: {
        value: 256,
        message: 'ExceedMaxEmailLength',
      },
    },
  },
];

export const USER_NO_ROLES_MESSAGE = 'UserShouldHaveAtLeastOneRole';
