import { useMemo } from 'react';

import { format } from 'date-fns';

import { useAdminGetTopStatsQuery } from 'apis';
import { ADMIN_MOCKED_TOP_STATS_PERCENTS, ADMIN_TOP_STATS_WIDGET, TopStatItemVariant } from 'constants/dashboard';
import {
  DAY_FORMAT_WITH_SLASHES,
  DAY_FORMAT_WITH_TIME_WITH_SLASHES,
  getDateRangeForCurrentMonth,
} from 'constants/global';

import { TopStatItem } from './TopStatItem';
import TopStatsSkeleton from './TopStatsSkeleton';

export const AdminTopStats = () => {
  const { startDate, endDate } = useMemo(() => getDateRangeForCurrentMonth(), []);

  const { data: adminTopStatsData, isLoading } = useAdminGetTopStatsQuery({
    startDate: format(startDate, DAY_FORMAT_WITH_TIME_WITH_SLASHES),
    endDate: format(endDate, DAY_FORMAT_WITH_TIME_WITH_SLASHES),
  });

  if (isLoading) {
    return <TopStatsSkeleton />;
  }

  return (
    <>
      {ADMIN_TOP_STATS_WIDGET({
        startDate: format(startDate, DAY_FORMAT_WITH_SLASHES),
        endDate: format(endDate, DAY_FORMAT_WITH_SLASHES),
      }).map(({ id, ...props }) => {
        const value = adminTopStatsData?.result?.[id] || 0;

        const percent = ADMIN_MOCKED_TOP_STATS_PERCENTS[id];

        return (
          <TopStatItem
            key={id}
            id={id}
            variant={TopStatItemVariant.WITH_DATA_IN_TITLE}
            isLoading={isLoading}
            value={value}
            percent={percent}
            {...props}
          />
        );
      })}
    </>
  );
};
