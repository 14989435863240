import { ChangeEvent, ReactElement, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

import Button from './button/Button';

interface Props {
  handleUpload: (files: FileList) => void;
  buttonText: string;
  multiple?: boolean;
  icon?: ReactElement;
  className?: string;
  isIcon?: boolean;
  fileType?: string | string[];
}

const FileUploaderButton = ({
  handleUpload,
  multiple,
  buttonText,
  icon,
  className,
  isIcon = true,
  fileType,
}: Props) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (!hiddenFileInput?.current) {
      return;
    }
    hiddenFileInput.current.click();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files?.length) {
      return;
    }
    const uploadedFiles = event.target.files;

    handleUpload(uploadedFiles);
    event.target.value = '';
  };

  const iconElement = icon || '+';

  let acceptFileType = '';
  if (typeof fileType === 'string') {
    acceptFileType = `.${fileType}`;
  } else if (Array.isArray(fileType)) {
    acceptFileType = fileType.join(',');
  }
  return (
    <>
      <Button
        data-cy="upload-file-button"
        className={cn('button-upload', className)}
        type="button"
        onClick={handleButtonClick}
      >
        {isIcon ? iconElement : null}
        {t(buttonText)}
      </Button>
      <input
        className="hidden"
        ref={hiddenFileInput}
        type="file"
        onChange={handleInputChange}
        multiple={multiple}
        accept={acceptFileType}
      />
    </>
  );
};

export default FileUploaderButton;
