import { faCheck } from '@fortawesome/free-solid-svg-icons';

import useBaseApiLoading from 'hooks/useBaseApiLoading';
import PaymentResult from 'page-components/subscriptions-tenant/modal/payment-result/PaymentResult';

const SubscriptionPaymentCompleted = () => {
  const { isLoading } = useBaseApiLoading();

  return (
    <PaymentResult
      heading={isLoading ? 'ReceivingPaymentResult' : 'ThankYou'}
      icon={faCheck}
      iconClassName="text-green4"
      description={isLoading ? 'PleaseWait' : 'YourPaymentHasBeenCompleted'}
      isLoading={isLoading}
    />
  );
};

export default SubscriptionPaymentCompleted;
