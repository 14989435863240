import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateTemplateMutation } from 'apis/email-templates.api';
import { DEFAULT_EMAIL_TEMPLATE_FORM_VALUES } from 'constants/email-templates';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import {
  CREATE_EMAIL_TEMPLATES_FIELDS_NAMES,
  EmailTemplateFormValues,
  SHARED_EMAIL_TEMPLATES_FIELDS_NAMES,
  TemplateVariablesValues,
} from 'interfaces/email-templates.interface';
import { BASE_WYSIWYG_FIELD_NAMES, CommonError } from 'interfaces/shared.interface';
import EmailTemplateForm from 'page-components/email-templates/template-form/EmailTemplateForm';
import { ErrorModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

const CreateEmailTemplate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [createTemplate, { isLoading, error }] = useCreateTemplateMutation();

  const { data: errorData } = (error as CommonError) || {};

  const createEmailTemplateMethods = useForm<EmailTemplateFormValues>({
    defaultValues: DEFAULT_EMAIL_TEMPLATE_FORM_VALUES,
  });

  const onSubmit = async (data: EmailTemplateFormValues, fields: TemplateVariablesValues[]) => {
    const payload = {
      templateName: data[CREATE_EMAIL_TEMPLATES_FIELDS_NAMES.TEMPLATE_NAME],
      subject: data[SHARED_EMAIL_TEMPLATES_FIELDS_NAMES.SUBJECT],
      body: data[BASE_WYSIWYG_FIELD_NAMES.TEXT_EDITOR],
      variableNames: fields.map((field) => field.value),
    };

    try {
      await createTemplate(payload).unwrap();
      navigate(ROUTES.emailTemplates);
      successNotify(t('SavedSuccessfully'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <div data-cy="create-email-template-page">
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />
      <FormProvider {...createEmailTemplateMethods}>
        <EmailTemplateForm isLoading={isLoading} onSubmit={onSubmit} />
      </FormProvider>
    </div>
  );
};

export default CreateEmailTemplate;
