import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

const PASSWORD_STRENGTH_POINTS = [
  { id: 'length', titleKey: 'PasswordComplexity_RequiredLength_Hint', pattern: /^.{6,}$/ },
  { id: 'cases', titleKey: 'PasswordComplexity_RequireUppercase_Hint', pattern: /(?=.*[a-z])(?=.*[A-Z])/ },
  {
    id: 'numsAndLetters',
    titleKey: 'PasswordComplexity_RequireNonAlphanumeric_Hint',
    pattern: /(?=.*\d)(?=.*[a-zA-Z])/,
  },
  {
    id: 'symbols',
    titleKey: 'PasswordComplexity_RequireDigit_Hint',
    pattern: /(?=.*[!@$#%])/,
  },
];

export interface Props {
  password: string | undefined;
  className?: string;
  elementClassName?: string;
  dataCy?: string;
}

const PasswordStrengthWidget: FC<Props> = ({ password = '', className, elementClassName, dataCy }) => {
  const { t } = useTranslation();

  return (
    <div data-cy={dataCy} className={className}>
      <div>
        {PASSWORD_STRENGTH_POINTS.map(({ id, titleKey, pattern }) => {
          if (new RegExp(pattern).test(password)) {
            return null;
          }

          const title = id === 'length' ? t(titleKey, { 0: 6 }) : t(titleKey);

          return (
            <div className={cn('flex items-center first:pt-4 last:pb-8', elementClassName)} key={id}>
              <p data-cy="error" className={'mr-3 min-w-[12px] text-[13px] text-red-600'}>
                &bull; {title}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(PasswordStrengthWidget);
