import {
  CalculateRepaymentPayload,
  CalculateRepaymentResponse,
  CreateRepaymentResponse,
  PaymentTypesResponse,
  RepaymentDetailsResponse,
  RepaymentsResponse,
} from 'interfaces/integrations/gocardless.interface';

import { coreSplitApis } from './core.api';

export const goCardlessApi = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getPaymentTypes: build.query<PaymentTypesResponse, void>({
      query: () => ({
        url: 'Repayment/GetPaymentTypes',
        method: 'GET',
      }),
    }),
    getRepayments: build.query<RepaymentsResponse, string>({
      query: (params) => ({
        url: `Repayment/GetRepayments?${params}`,
        method: 'GET',
      }),
    }),
    getRepaymentStatuses: build.query<PaymentTypesResponse, string>({
      query: () => ({
        url: 'Repayment/GetRepaymentStatuses',
        method: 'GET',
      }),
    }),
    fetchCalculateRepayment: build.mutation<CalculateRepaymentResponse, CalculateRepaymentPayload>({
      query: (data) => ({
        url: 'Repayment/CalculateRepayment',
        method: 'POST',
        data: data,
      }),
    }),
    getCreateRepayment: build.mutation<CreateRepaymentResponse, CalculateRepaymentPayload>({
      query: (data) => ({
        url: 'Repayment/CreateRepayment',
        method: 'POST',
        data: data,
      }),
    }),
    getRepaymentDetails: build.query<RepaymentDetailsResponse, number>({
      query: (repaymentId) => ({
        url: `Repayment/GetRepaymentDetails?repaymentId=${repaymentId}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaymentTypesQuery,
  useGetRepaymentsQuery,
  useGetRepaymentStatusesQuery,
  useFetchCalculateRepaymentMutation,
  useGetRepaymentDetailsQuery,
  useGetCreateRepaymentMutation,
} = goCardlessApi;
