import { t } from 'i18next';
import { useParams } from 'react-router-dom';

import { useGetInvoiceLineItemsQuery, useGetInvoiceQuery } from 'apis/invoices.api';
import { INVOICE_LINE_ITEMS_TABLE_COLUMNS } from 'constants/invoices';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { LineItems } from 'interfaces/invoices.interface';
import InvoiceData from 'page-components/Invoices/InvoiceData';
import InvoiceLineItemsRow from 'page-components/Invoices/InvoiceLineItemsRow';
import { LoadingOverlay, Pagination, Skeleton, Table } from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';

const InvoiceDetailsPage = () => {
  const { invoiceId } = useParams();

  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const {
    data: invoiceLineItemsData,
    isLoading,
    refetch,
    isFetching,
  } = useGetInvoiceLineItemsQuery(`${Number(invoiceId)}&${sortingParams}${paginationParams}`);

  const { data: invoiceData, isLoading: invoiceLoading, isFetching: invoiceFetching } = useGetInvoiceQuery(invoiceId);

  return (
    <div data-cy="invoice-details-page">
      <AppContentLayout
        headerClassName="text-3xl text-darkGray dark:text-white"
        customHeader={
          invoiceLoading ? <Skeleton className="w-36 h-5" /> : `Invoice #${invoiceData?.result.invoiceNumber}`
        }
      >
        <div className="relative">
          <InvoiceData data={invoiceData?.result} isLoading={invoiceLoading} fetching={invoiceFetching} />
          <div className="px-4">
            <h2 className="text-2xl py-10">{t('InvoiceLineItems')}</h2>
            <LoadingOverlay isLoading={isFetching && !!invoiceLineItemsData} />
            <Table
              dataCy="invoice-line-items-table"
              columns={INVOICE_LINE_ITEMS_TABLE_COLUMNS}
              handleSetTabName={handleSetTabName}
              sortingColumnId={sortingColumnId}
              isTableEmpty={!invoiceLineItemsData?.result.items.length}
              sortingType={sortingType}
              isLoading={isLoading}
            >
              {invoiceLineItemsData?.result.items &&
                invoiceLineItemsData.result.items.map((requests: LineItems) => (
                  <InvoiceLineItemsRow key={null} invoiceLineItemsData={requests} refetchInvoice={refetch} />
                ))}
            </Table>
            <Pagination
              totalCount={invoiceLineItemsData?.result.totalCount}
              handleSetMaxResultCount={handleSetMaxResultCount}
              maxResultCount={maxResultCount}
              handleSetCurrentPage={handleSetCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </AppContentLayout>
    </div>
  );
};

export default InvoiceDetailsPage;
