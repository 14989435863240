import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { Repayments } from 'interfaces/integrations/gocardless.interface';
import { TableData, TableRow, DropdownMenu } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { useFormatPriceFunction } from 'utils/global';

import RepaymentDetailsModal from './repayment-details/RepaymentDetailsModal';

interface Props {
  repaymentsData: Repayments;
}

const RepaymentsRow: FC<Props> = ({ repaymentsData }) => {
  const { t } = useTranslation();

  const [isRepaymentDetailsModalOpen, handleOpenRepaymentDetailsModalModal, handleCloseRepaymentDetailsModalModal] =
    useOpen();

  const menuItemsAction = [
    {
      dataCy: 'view-details-button',
      name: 'ShowDetails',
      actionOnClick: handleOpenRepaymentDetailsModalModal,
    },
  ];

  return (
    <>
      <RepaymentDetailsModal
        repaymentId={repaymentsData.id}
        isOpen={isRepaymentDetailsModalOpen}
        handleClose={handleCloseRepaymentDetailsModalModal}
        heading={`${t('Details')}`}
      />

      <TableRow dataCy="repayments-table-row">
        <TableData dataCy="table-data-repayments-reference-id">{repaymentsData.referenceId}</TableData>
        <TableData dataCy="table-data-repayments-payment-type">{t(repaymentsData.paymentType)}</TableData>
        <TableData dataCy="table-data-repayments-total-amount">
          {useFormatPriceFunction(repaymentsData?.totalAmount || 0, repaymentsData?.currency)}
        </TableData>
        <TableData dataCy="table-data-repayments-status">{t(repaymentsData.status)}</TableData>
        <TableData dataCy="table-data-repayments-created-date">
          {getFormattedDate(repaymentsData.createdDate, DAY_FORMAT_WITH_TIME)}
        </TableData>
        <TableData>
          <DropdownMenu elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default RepaymentsRow;
