import { ReactNode, useRef } from 'react';

import { useOpen } from 'hooks/useOpen';
import useOutsideClick from 'hooks/useOutsideClick';
import { Button } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  children: ReactNode;
  popUpContent: ReactNode;
  wrapperClassName?: string;
  buttonClassName?: string;
  popUpClassName?: string;
  dataCy?: string;
}

const ButtonWithPopUp = ({
  children,
  popUpContent,
  wrapperClassName,
  buttonClassName,
  popUpClassName,
  dataCy,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isPopUpOpened, handleOpenPopUp, handleClosePopUp] = useOpen();

  const onButtonClick = () => (isPopUpOpened ? handleClosePopUp() : handleOpenPopUp());

  useOutsideClick(containerRef, handleClosePopUp);

  return (
    <div className={cn('relative', wrapperClassName)} ref={containerRef} data-cy={dataCy}>
      {isPopUpOpened && (
        <div
          className={cn(
            'absolute -top-11 left-0 shadow-centerMedium dark:border dark:border-blackOpacity2 bg-white dark:bg-darkBlue p-2 rounded',
            popUpClassName
          )}
        >
          {popUpContent}
        </div>
      )}
      <Button type="button" onClick={onButtonClick} className={cn('flex gap-1 items-center', buttonClassName)}>
        {children}
      </Button>
    </div>
  );
};

export default ButtonWithPopUp;
