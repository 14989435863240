import { useEffect } from 'react';

import { useGetSageInvoiceDetailsQuery, useGetSageContactDetailsQuery } from 'apis/sage';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Box, ErrorModal } from 'shared-components';

import ContactInformationBlock from './contact-details/ContactInformationBlock';
import InvoiceInformationBlock from './invoice-details/InvoiceInformationBlock';
import InvoiceLineItems from './invoice-details/InvoiceLineItems';

type Props = {
  invoiceId?: string;
  contactId?: string;
};

const SageDetailsLayout = ({ invoiceId, contactId }: Props) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    data: invoiceData,
    isLoading: isInvoiceLoading,
    error: invoiceError,
  } = useGetSageInvoiceDetailsQuery(invoiceId || '', { skip: !invoiceId });

  const {
    data: contactData,
    isLoading: isContactLoading,
    error: contactError,
  } = useGetSageContactDetailsQuery(contactId || '', { skip: !contactId });

  const { error: errorInvoiceData } = (invoiceError as CommonError)?.data || {};
  const { error: errorContactData } = (contactError as CommonError)?.data || {};

  const errorData = errorInvoiceData || errorContactData;

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
  }, [errorData, handleOpenErrorModal]);

  return (
    <div data-cy="details-page">
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />
      {invoiceId ? (
        <>
          <Box>
            <div className="2xl:flex justify-between md:block">
              <InvoiceInformationBlock isLoading={isInvoiceLoading} data={invoiceData?.result.data} />
            </div>
          </Box>
          <div className="p-5">
            <InvoiceLineItems isLoading={isInvoiceLoading} data={invoiceData?.result.data.lineItems} />
          </div>
        </>
      ) : (
        <Box>
          <div className="2xl:flex justify-between md:block">
            <ContactInformationBlock isLoading={isContactLoading} data={contactData?.result.data} />
          </div>
        </Box>
      )}
    </div>
  );
};

export default SageDetailsLayout;
