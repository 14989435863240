import { useGetUserConfigQuery } from 'apis/user.api';
import { ThemeConfig } from 'interfaces/user.interface';

const useTheme = () => {
  const response = useGetUserConfigQuery();
  const currentTheme = response.data?.result?.setting.values['App.UserThemeName'];
  const theme = ThemeConfig.dark === currentTheme ? ThemeConfig.dark : ThemeConfig.light;
  const themeNames = {
    [ThemeConfig.dark]: 'dark',
    [ThemeConfig.light]: 'light',
  };
  return {
    theme,
    themeName: themeNames[theme],
  };
};

export default useTheme;
