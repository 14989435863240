import { FC } from 'react';

import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useDeleteRoleMutation } from 'apis/roles.api';
import { DAY_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { RolesItem } from 'interfaces/roles.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Checkbox, TableData, TableRow, DropdownMenu, WarningModal, ErrorModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

interface Props {
  roleData: RolesItem;
  handleEdit: (id: number) => void;
  hasStaticItemWithDeletePermissions: boolean;
  isEditPermissionEnabled: boolean;
  refetchTrigger: () => void;
}

const RolesRow: FC<Props> = ({
  roleData,
  handleEdit,
  hasStaticItemWithDeletePermissions,
  isEditPermissionEnabled,
  refetchTrigger,
}) => {
  const { t } = useTranslation();

  const [isOpenWarningModal, handleOnOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const permissions = useAppSelector(selectUserPermissions);

  const [deleteRole, { error: deleteRoleError }] = useDeleteRoleMutation();

  const errorData = (deleteRoleError as CommonError)?.data || {};

  const menuItemsAction = [
    {
      dataCy: 'menu-item-edit-button',
      name: 'Edit',
      actionOnClick: () => {
        handleEdit(roleData?.id);
      },
      hidden: !permissions[Permissions.PAGES_ROLES_EDIT],
    },
    ...(roleData.isStatic
      ? []
      : [
          {
            dataCy: 'menu-item-delete-button',
            name: 'Delete',
            actionOnClick: handleOnOpenWarningModal,
            hidden: !permissions[Permissions.PAGES_ROLES_DELETE],
          },
        ]),
  ];

  const handleDeleteRole = async () => {
    try {
      await deleteRole(roleData.id).unwrap();
      refetchTrigger();
      handleCloseWarningModal();
      successNotify(t('SuccessfullyDeleted'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('RoleDeleteWarningMessage', { 0: roleData.displayName })}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDeleteRole}
      />

      <TableRow dataCy="roles-table-row">
        <TableData dataCy="table-data-role-name">{roleData.displayName}</TableData>
        <TableData>
          <Checkbox dataCy="static-role-checkbox" checked={roleData.isStatic} disabled />
        </TableData>
        <TableData>
          <Checkbox dataCy="default-role-checkbox" checked={roleData.isDefault} disabled />
        </TableData>
        <TableData>{format(parseISO(roleData.creationTime), DAY_FORMAT_WITH_SLASHES)}</TableData>
        {(hasStaticItemWithDeletePermissions || isEditPermissionEnabled) && (
          <TableData>
            <DropdownMenu elements={menuItemsAction} />
          </TableData>
        )}
      </TableRow>
    </>
  );
};

export default RolesRow;
