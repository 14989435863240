import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useLazyGetQuickBooksCustomersQuery } from 'apis/quickBooks.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError, OptionType } from 'interfaces/shared.interface';
import { ErrorModal } from 'shared-components';
import AsyncSelectComponent from 'shared-components/select/AsyncSelectComponent';

interface Props {
  onSearchApply: (invoicesParams: string | null) => void;
}

const QuickBooksInvoicesSearch = ({ onSearchApply }: Props) => {
  const { t } = useTranslation();
  const [isOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [trigger, { error }] = useLazyGetQuickBooksCustomersQuery();
  const { data: errorData } = (error as CommonError) || {};
  const [, setOptions] = useState<OptionType[]>([]);

  const loadOptions = async (inputValue: string): Promise<OptionType[]> => {
    if (!inputValue) {
      setOptions([]);
      return [];
    }

    const customersParams = `Take=1000&Skip=0&CustomerName=${inputValue}`;
    const result = await trigger(customersParams).unwrap();
    if (result) {
      const options = result.result.data.customers.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(options);
      return options;
    }
    return [];
  };

  const handleOptionClick = async (item: OptionType | null) => {
    if (item === null) {
      onSearchApply(null);
      return;
    }

    const customerId = item.value?.toString() || '';
    onSearchApply(customerId);
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />
      <div className="w-1/2" data-cy="companies-house-search" data-tooltip-id="tooltip">
        <div className="pb-5">
          <AsyncSelectComponent
            loadOptions={loadOptions}
            placeholder={t('CustomerSearchPlaceholder')}
            onClickHandler={handleOptionClick}
            isClearable
          />
        </div>
      </div>
    </>
  );
};

export default QuickBooksInvoicesSearch;
