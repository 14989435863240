import { UserTypes } from 'constants/user';
import useUserRole from 'hooks/useUserRole';

import { AdminTopStats } from './AdminTopStats';
import { TenantTopStats } from './TenantTopStats';

export const TopStatsWidget = () => {
  const { userRole } = useUserRole();

  return (
    <div data-cy="top-stats-widget" className="grid grid-cols-2 md:grid-cols-4 gap-2 h-full">
      {userRole === UserTypes.TENANT ? <TenantTopStats /> : <AdminTopStats />}
    </div>
  );
};
