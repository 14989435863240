import { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useSendAuthCodeMutation } from 'apis/auth.api';
import { ButtonVariants } from 'constants/common';
import { BACK_BUTTON, SUBMIT_BUTTON } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { RESEND_CODE_BUTTON, TWO_FACTOR_AUTHENTICATOR_FORM_INPUTS } from 'constants/two-factor-authentication';
import { useHandleSignIn } from 'hooks/useHandleSignIn';
import { useOpen } from 'hooks/useOpen';
import { useTimer } from 'hooks/useTimer';
import { SignInPayload } from 'interfaces/auth.interface';
import { CommonError } from 'interfaces/shared.interface';
import { TWO_FACTOR_AUTH_FORM_FIELDS, TwoFactorAuthValues } from 'interfaces/two-factor-authentication.interface';
import { selectAuthCredentials, selectTwoFactorAuthenticationType, selectUserId } from 'modules/auth/selector';
import { useAppSelector } from 'modules/store';
import { Button, FormContent, ErrorModal } from 'shared-components';

const TIMER_TIME = 90;

const TwoFactorAuthenticatorForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authPayload = useAppSelector(selectAuthCredentials);
  const userId = useAppSelector(selectUserId);
  const twoFactorAuthenticationType = useAppSelector(selectTwoFactorAuthenticationType);

  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();
  const { handleSignIn, isLoading, error } = useHandleSignIn();
  const { timeLeft, isActive, startTimer, resetTimer } = useTimer(TIMER_TIME);

  const { data: errorAuthData } = (error as CommonError) || {};

  const [sendAuthCode, { isLoading: isLoadingAuthCode, data: responseCodeData }] = useSendAuthCodeMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TwoFactorAuthValues>({ mode: 'onBlur' });

  const handleCloseErrorModal = () => {
    handleOnCloseErrorModal();
    navigate(ROUTES.signIn);
  };

  const handleResendCode = async () => {
    if (userId && twoFactorAuthenticationType) {
      await sendAuthCode({ userId, twoFactorAuthenticationType });
      resetTimer();
    }
  };

  const onSubmit = async (data: TwoFactorAuthValues) => {
    const payloadWithTwoFactorAuth = {
      ...authPayload,
      twoFactorAuthenticationCode: data[TWO_FACTOR_AUTH_FORM_FIELDS.twoFactorAuthenticationCode],
    } as SignInPayload;

    if (responseCodeData?.success) {
      await handleSignIn(payloadWithTwoFactorAuth);
    }
  };

  useEffect(() => {
    if (userId && twoFactorAuthenticationType) {
      sendAuthCode({ userId, twoFactorAuthenticationType });

      startTimer();
    }
  }, [userId, twoFactorAuthenticationType, sendAuthCode, startTimer]);

  useEffect(() => {
    if (!authPayload) {
      navigate(ROUTES.signIn);
    }
  }, [authPayload, navigate]);

  useEffect(() => {
    if (errorAuthData) {
      handleOnOpenErrorModal();
    }
  }, [handleOnOpenErrorModal, errorAuthData]);

  return (
    <div className="mt-8">
      <ErrorModal
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
        errorMessage={errorAuthData && errorAuthData.error?.message}
        description={errorAuthData && errorAuthData.error?.details}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="w-4/4 m-auto">
        <div className="flex justify-between">
          <FormContent fields={TWO_FACTOR_AUTHENTICATOR_FORM_INPUTS} register={register} errors={errors} />
          {!isActive && (
            <Button className="w-2/6 h-10 ml-4" isLoading={isLoadingAuthCode} type="button" onClick={handleResendCode}>
              {t(RESEND_CODE_BUTTON)}
            </Button>
          )}
        </div>
        <div className="text-red-500 text-sm mb-4">{`${t('RemainingTime')}: ${timeLeft} ${t('SecondShort')}`}</div>
        <div className="flex items-center justify-between">
          <Button type="button" variant={ButtonVariants.BORDERED} disabled={isLoading || isLoadingAuthCode}>
            <Link to={ROUTES.signIn} className="text-sm">
              {t(BACK_BUTTON)}
            </Link>
          </Button>
          <Button isLoading={isLoading} type="submit">
            {t(SUBMIT_BUTTON)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default TwoFactorAuthenticatorForm;
