import { FC, ReactNode, useState, useEffect } from 'react';

import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectComponent, { MultiValueProps, StylesConfig } from 'react-select';
import { v4 as uuidv4 } from 'uuid';

import useTheme from 'hooks/useTheme';
import { OPTION_TYPE, OPERATOR_TYPE, OptionTypeAdvancedFilter } from 'interfaces/integrations/os-audit-logs.interface';
import { FormItem, OptionType } from 'interfaces/shared.interface';
import { ThemeConfig } from 'interfaces/user.interface';
import { Skeleton } from 'shared-components/index';
import { cn } from 'utils/global';

import MultiValue from './MultiValue';
import ErrorMessage from '../../ErrorMessage';
import { getSelectStyles } from '../Select';

export interface ExtendedMultiValueProps extends MultiValueProps<OptionTypeAdvancedFilter, true> {
  openPickerId: string | null;
  inputValues: { [key: string]: string };
  handleInputChange: (optionValue: string, value: string) => void;
  type?: string;
  control: Control;
  handleDatePickerToggle: (isOpen: boolean) => void;
}

const getMultiValueWrapper: React.FC<ExtendedMultiValueProps> = ({
  handleDatePickerToggle,
  control,
  inputValues,
  handleInputChange,
  type,
  openPickerId,
  ...props
}) => {
  return (
    <MultiValue
      isDateAndTimePickerOpen={openPickerId === props.data.value}
      setIsDateAndTimePickerOpen={() => handleDatePickerToggle}
      control={control}
      inputValues={inputValues}
      onInputChange={handleInputChange}
      type={type}
      {...props}
    />
  );
};

export type Props = {
  control: Control;
  className?: string;
  maxMenuHeight?: number;
  error?: string;
  menuPlacement?: 'bottom' | 'top';
  label?: ReactNode;
  onFilterChange: (queryString: string) => void;
  isLoading?: boolean;
  options: OptionTypeAdvancedFilter[];
} & Omit<FormItem, 'component'>;

const AdvancedFilter: FC<Props> = ({
  label,
  options,
  control,
  dataCy,
  name,
  labelClassName,
  inputClassName,
  placeholder,
  isSearchable = true,
  disabled,
  defaultValue,
  menuPlacement,
  maxMenuHeight = 200,
  className,
  isLoading,
  onFilterChange,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [selectedOptions, setSelectedOptions] = useState<OptionTypeAdvancedFilter[]>([]);
  const [openPickerId, setOpenPickerId] = useState<string | null>(null);

  const handleDatePickerToggle = (optionValue: string) => {
    setOpenPickerId(openPickerId === optionValue ? null : optionValue);
  };

  const getExpandedFilterOptions = (options: OptionTypeAdvancedFilter[]): OptionTypeAdvancedFilter[] => {
    return options.flatMap((option) => {
      if (option.type === OPTION_TYPE.LONG || option.type === OPTION_TYPE.DATE) {
        return [
          {
            value: `${option.value}_${OPERATOR_TYPE.LESS_THAN}_${uuidv4()}`,
            label: `${option.label}<`,
            type: option.type,
          },
          {
            value: `${option.value}_${OPERATOR_TYPE.GREATER_THAN}_${uuidv4()}`,
            label: `${option.label}>`,
            type: option.type,
          },
          {
            value: `${option.value}_${OPERATOR_TYPE.EQUALS}_${uuidv4()}`,
            label: `${option.label}=`,
            type: option.type,
          },
        ];
      }
      return [{ ...option, value: `${option.value}_${uuidv4()}` }];
    });
  };

  const internalizedOptions: OptionTypeAdvancedFilter[] = getExpandedFilterOptions(
    options?.map((option) => ({
      label: `${t(option.label)} ${option.type !== '1' && option.type !== 'long' && option.type !== 'date' ? '=' : ''}`,
      value: option.value,
      type: option.type,
    })) || []
  );

  const handleInputChange = (optionValue: string, value: string) => {
    setInputValues((prev) => {
      const newValues = { ...prev, [optionValue]: value };
      generateQueryString();
      return newValues;
    });
  };

  const formatQueryString = (name: string, value: string, propertyType: string, index: number) => {
    let operator: string;

    if (name.includes(OPERATOR_TYPE.LESS_THAN)) {
      operator = '%3C';
    } else if (name.includes(OPERATOR_TYPE.GREATER_THAN)) {
      operator = '%3E';
    } else {
      operator = '';
    }

    const baseName = name.replace(/_lt|_gt|_eq/, '');
    const encodedName = baseName + '%3A' + operator;
    const encodedValue = value || 'null';
    return `Query[${index}].name=${encodeURIComponent(
      encodedName
    )}&Query[${index}].value=${encodedValue}&Query[${index}].type=${
      encodedValue === value ? 0 : 1
    }&Query[${index}].propertyType=${propertyType}`;
  };

  const generateQueryString = () => {
    let indexCounter = 0;

    const createQueryPart = (name: string, value: string, type: number, propertyType: string) => {
      return `Query[${indexCounter}].name=${name}&Query[${indexCounter}].value=${encodeURIComponent(
        value
      )}&Query[${indexCounter}].type=${type}&Query[${indexCounter}].propertyType=${encodeURIComponent(propertyType)}`;
    };

    const queryParts = selectedOptions.reduce((parts, option) => {
      const key = String(option.value);
      const value = inputValues[key] || '';

      if (value.trim() === '' && option.type !== '1') {
        return parts;
      }

      if (option.type === '1') {
        parts.push(createQueryPart(option.label.trim(), 'null', 1, 'undefined'));
      } else {
        parts.push(
          formatQueryString(`${key.replace(/_[a-f0-9\-]+$/, '')}`, value, option?.type?.toString() || '', indexCounter)
        );
      }

      indexCounter++;
      return parts;
    }, [] as string[]);

    const queryString = queryParts.join('&');
    onFilterChange(queryString);
  };

  const customStyles: StylesConfig<OptionType> = {
    ...getSelectStyles(theme),
    control: (provided) => ({
      ...provided,
      border: 0,
      background: 'transparent',
      '&:hover': {
        borderColor: '#4096ff',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme === ThemeConfig.dark ? '#111827' : 'white',
    }),
  };

  useEffect(() => {
    generateQueryString();
  }, [selectedOptions, inputValues]);

  const selectComponent = (props: any) => {
    return getMultiValueWrapper({
      isDateAndTimePickerOpen: openPickerId === props.data.value,
      setIsDateAndTimePickerOpen: () => handleDatePickerToggle(props.data.value),
      control: control,
      inputValues: inputValues,
      handleInputChange: handleInputChange,
      type: internalizedOptions.find((option) => option.type === props.data.type)?.type,
      ...props,
    });
  };

  return (
    <div data-cy="advanced-filter" className={cn('w-full', className)}>
      {label ? (
        <div className={cn('text-sm text-gray-500', labelClassName)}>
          {typeof label === 'string' ? t(label) : label}
        </div>
      ) : null}

      {isLoading ? (
        <Skeleton className={cn('w-full min-h-[2.5rem]', inputClassName)} />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field, fieldState: { error } }) => {
            const handleChange = (selectedOptions: any) => {
              setSelectedOptions(Array.isArray(selectedOptions) ? selectedOptions : []);
              field.onChange(selectedOptions);
            };

            return (
              <>
                <SelectComponent<OptionTypeAdvancedFilter, true>
                  menuIsOpen={openPickerId === null ? undefined : false}
                  backspaceRemovesValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  inputId={dataCy}
                  isSearchable={isSearchable}
                  options={internalizedOptions}
                  isClearable={false}
                  isMulti={true}
                  placeholder={placeholder}
                  menuPlacement={menuPlacement}
                  styles={customStyles}
                  maxMenuHeight={maxMenuHeight}
                  className={cn(
                    '',
                    {
                      'border-red-500': error,
                      '[&>div]:bg-gray2': disabled,
                    },
                    inputClassName
                  )}
                  onChange={handleChange}
                  components={{
                    MultiValue: selectComponent,
                  }}
                />
                {error?.message && <ErrorMessage dataCy="select-error-message">{t(error.message)}</ErrorMessage>}
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default AdvancedFilter;
