import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetSubscriptionPaymentsHistoryQuery } from 'apis/subscriptions-admin.api';
import { useGetTenantDetailsQuery } from 'apis/tenants.api';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left-3.svg';
import { ButtonVariants } from 'constants/common';
import { FULL_DATE_FORMAT } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { TENANT_PAYMENT_HISTORY_COLUMNS } from 'constants/tenant-details';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { CommonError } from 'interfaces/shared.interface';
import TenantSubscriptionPaymentRow from 'page-components/tenants/tenant-details/TenantSubscriptionPaymentRow';
import { ErrorModal, LinkButton, Pagination, Table, Box, NoTableData, LabelWithValue } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

const TenantDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { data, isLoading, error } = useGetTenantDetailsQuery(id || '', { skip: !id });
  const { data: subscriptionPaymentsHistory, isLoading: isPaymentsHistoryLoading } =
    useGetSubscriptionPaymentsHistoryQuery(`tenantId=${id}&${paginationParams}`);

  const { name, subscriptionName, subscriptionStartDate, subscriptionEndDate } = data?.result || {};
  const { items: paymentsHistoryItems, totalCount } = subscriptionPaymentsHistory?.result || {};

  const { error: errorData } = (error as CommonError)?.data || {};

  useEffect(() => {
    if (errorData?.message) {
      handleOpenErrorModal();
    }
  }, [errorData?.message, handleOpenErrorModal]);

  return (
    <div data-cy="tenant-details-page">
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />

      <Box>
        <LinkButton className="text-1xs mb-5" variant={ButtonVariants.LINK} to={ROUTES.tenants}>
          <ArrowLeft className="fill-blue5 mr-1.5" />
          {t('Back')}
        </LinkButton>

        <LabelWithValue labelClassName="w-52" label="Name" value={name} isLoading={isLoading} />
        <LabelWithValue
          labelClassName="w-52"
          label="SubscriptionPlanLower"
          value={subscriptionName}
          isLoading={isLoading}
        />
        <LabelWithValue
          labelClassName="w-52"
          label="SubscriptionStartDate"
          value={getFormattedDate(subscriptionStartDate)}
          isLoading={isLoading}
        />
        <LabelWithValue
          labelClassName="w-52"
          label="SubscriptionEndDate"
          value={getFormattedDate(subscriptionEndDate, FULL_DATE_FORMAT)}
          isLoading={isLoading}
        />
        <div className="relative">
          <h5 className="mt-5 mb-3 text-xl text-darkGray dark:text-white">{t('SubscriptionPaymentHistory')}</h5>
          <Table
            dataCy="payment-history-table"
            className="[&_thead]:border-t-2"
            columns={TENANT_PAYMENT_HISTORY_COLUMNS}
            isLoading={isPaymentsHistoryLoading}
          >
            {paymentsHistoryItems?.length && !isPaymentsHistoryLoading ? (
              paymentsHistoryItems.map((payment) => <TenantSubscriptionPaymentRow key={payment.id} payment={payment} />)
            ) : (
              <NoTableData colSpan={5} />
            )}
          </Table>
        </div>

        <Pagination
          totalCount={totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </Box>
    </div>
  );
};

export default TenantDetails;
