import { Tooltip } from 'react-tooltip';

import useCountUp from 'hooks/useCountUp';
import { GeneralStatsMetric } from 'interfaces/general-stats.interface';
import { ProgressCircle, ProgressCircleData } from 'shared-components';

interface Props extends Omit<GeneralStatsMetric, 'dataCy'> {
  value: number;
  total: number;
}

const GeneralStatsProgressItem = ({ id, label, value, total, color = '#a8385d' }: Props) => {
  const percent = Math.ceil((value / total) * 100);

  const [countedPercent] = useCountUp({ duration: 1500, targetValue: percent || 0 });

  const tooltipId = `general-stats-tooltip-${id}`;

  return (
    <ProgressCircle
      percentage={countedPercent}
      color={color}
      tooltipId={tooltipId}
      iconClassName="w-10 h-10 lg:w-32 lg:h-32"
    >
      <ProgressCircleData title={`${countedPercent}%`} description={label} />
      <Tooltip id={tooltipId} place="top" positionStrategy="fixed" className="hidden lg:block !bg-black !opacity-100">
        <ProgressCircleData isStatic className="flex-col-reverse" title={value} description={label} />
      </Tooltip>
    </ProgressCircle>
  );
};

export default GeneralStatsProgressItem;
