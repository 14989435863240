import { useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react';

import { ITreeViewState } from 'react-accessible-treeview';

export interface UseTreeViewReturnValues {
  selectedIds: string[];
  handleClearSelectedIds: VoidFunction;
  handleChangeSelectedIds: (values: { treeState: ITreeViewState }) => void;
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
}

export const useTreeViewSelect = (appliedPermissions?: string[]): UseTreeViewReturnValues => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleClearSelectedIds = useCallback(() => {
    setSelectedIds([]);
  }, []);

  const handleChangeSelectedIds = useCallback(({ treeState }: { treeState: ITreeViewState }) => {
    const { selectedIds, halfSelectedIds } = treeState;
    setSelectedIds([...Array.from(selectedIds), ...Array.from(halfSelectedIds)] as string[]);
  }, []);

  useEffect(() => {
    if (!appliedPermissions) {
      return;
    }

    setSelectedIds(appliedPermissions);
  }, [appliedPermissions, setSelectedIds]);

  return { selectedIds, handleChangeSelectedIds, handleClearSelectedIds, setSelectedIds };
};
