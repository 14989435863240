import { useTranslation } from 'react-i18next';

import { REGEX_TRANSLATION_SPLIT } from 'constants/global';

const BannerMessageBuilder = ({
  messageKey,
  values,
  splits,
}: {
  messageKey: string;
  values: Record<string, string>;
  splits: Array<{ index: number; component: JSX.Element }>;
}) => {
  const { t } = useTranslation();
  const message = t(messageKey, values);

  const parts = message.split(REGEX_TRANSLATION_SPLIT);

  const messageParts: (string | JSX.Element)[] = [];
  let componentIndex = 0;

  for (const element of parts) {
    const part = element;

    const match = part.match(/\{(\d+)\}/);
    if (match) {
      const index = parseInt(match[1], 10);

      if (componentIndex < splits.length && splits[componentIndex].index === index) {
        messageParts.push(splits[componentIndex].component);
        componentIndex++;
      }
    } else {
      messageParts.push(part);
    }
  }

  return (
    <p>
      {messageParts.map((part) => (
        <span key={messageKey}>{part}</span>
      ))}
    </p>
  );
};

export default BannerMessageBuilder;
