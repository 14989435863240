import { useTranslation } from 'react-i18next';

import { useOpen } from 'hooks/useOpen';
import Button from 'shared-components/button/Button';

import CreateOrderModal from './CreateOrderModal';

const CreateOrderButton = () => {
  const [isOpen, handleOpen, handleClose] = useOpen();

  const { t } = useTranslation();
  return (
    <>
      <Button data-cy="create-order-button" onClick={handleOpen}>
        {t('Create')}
      </Button>
      <CreateOrderModal isOpen={isOpen} handleClose={handleClose} />
    </>
  );
};

export default CreateOrderButton;
