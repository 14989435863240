import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { INVOICE_TABLE_COLUMNS } from 'constants/sage';
import { LineItemData } from 'interfaces/integrations/sage.interface';
import { LoadingOverlay, Pagination, Table } from 'shared-components';

import InvoiceLineItemsRow from './InvoiceLineItemsRow';

type Props = {
  data?: LineItemData[];
  isLoading: boolean;
};

const InvoiceLineItems = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [maxResultCount, setMaxResultCount] = useState(10);

  const startIndex = (currentPage - 1) * maxResultCount;
  const endIndex = startIndex + maxResultCount;

  const paginatedInvoices = data?.slice(startIndex, endIndex);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetMaxResultCount = (count: number) => {
    setMaxResultCount(count);
    setCurrentPage(1);
  };

  return (
    <div data-cy="invoice-details-invoice-line-items" className="bg-transparent">
      <p className="text-2xl mb-5">{t('LineItems')}</p>
      <div className="relative">
        <LoadingOverlay isLoading={isLoading} />
        <Table
          dataCy="invoices-table"
          columns={INVOICE_TABLE_COLUMNS}
          isTableEmpty={!data?.length}
          isLoading={isLoading}
        >
          {paginatedInvoices?.map((item) => <InvoiceLineItemsRow key={item.id} invoiceLineItemsData={item} />)}
        </Table>
        <Pagination
          totalCount={data?.length}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default InvoiceLineItems;
