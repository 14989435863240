import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetDocumentsTypesQuery } from 'apis/typless.apis';
import {
  getTyplessFiltersFieldsDefaultValue,
  SEARCH_TYPLESS_PLACEHOLDER,
  TYPLESS_FILTER_FORM_FIELDS,
  TYPLESS_TABLE_COLUMNS,
} from 'constants/typless';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { TyplessFilterFormValues } from 'interfaces/integrations/typless.interface';
import { CommonError } from 'interfaces/shared.interface';
import CreateDocumentType from 'page-components/integrations/typless/documentTypes/CreateDocumentType';
import TyplessDocumentTypesRow from 'page-components/integrations/typless/documentTypes/TyplessDocumentTypesRow';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

const TyplessDocumentTypesPage = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { filterParams, applyFilters, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    error: errorTyplessData,
  } = useGetDocumentsTypesQuery(`${filterParams}${sortingParams}${paginationParams}`);

  const { result } = data || {};

  const { error: errorData } = (errorTyplessData as CommonError)?.data || {};

  const typlessFiltersMethods = useForm<TyplessFilterFormValues>({
    defaultValues: getTyplessFiltersFieldsDefaultValue(t('SelectStatus')),
  });

  const onSubmitFilters = async (data: TyplessFilterFormValues) => {
    const { status, filter } = data;

    const newFiltersToApply = {
      searchQuery: filter,
      StatusId: status.value !== '' ? (status.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    typlessFiltersMethods.reset(getTyplessFiltersFieldsDefaultValue(t('SelectStatus')));
    onSubmitFilters(getTyplessFiltersFieldsDefaultValue(t('SelectStatus')));
  };

  useEffect(() => {
    if (errorTyplessData) {
      handleOpenErrorModal();
    }
  }, [errorTyplessData, handleOpenErrorModal]);

  useEffect(() => {
    typlessFiltersMethods.reset(getTyplessFiltersFieldsDefaultValue(t('SelectStatus')));
  }, [t, typlessFiltersMethods]);

  return (
    <div data-cy="typless-page">
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />

      <div>
        <FormProvider {...typlessFiltersMethods}>
          <form onSubmit={typlessFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="typless-page-search"
              placeHolder={SEARCH_TYPLESS_PLACEHOLDER}
              handleClearFields={handleClearFields}
              rightTopActionButton={<CreateDocumentType refetchListDocument={refetch} />}
            >
              <FilterFormContentWithoutPermissions className="flex" fields={TYPLESS_FILTER_FORM_FIELDS} />
            </FilterForm>
          </form>
        </FormProvider>
      </div>
      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <Table
          dataCy="typless-table"
          columns={TYPLESS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          sortingType={sortingType}
          isLoading={isLoading}
          isTableEmpty={!result?.totalCount}
        >
          {result?.items.map((item) => <TyplessDocumentTypesRow key={item.id} documentTypesData={item} />)}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default TyplessDocumentTypesPage;
