import { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { PASSWORD_VALIDATION } from 'constants/sign-up';
import { useOpen } from 'hooks/useOpen';
import { BASE_USER_SETTINGS_FORM_FIELDS, CreateUserFormValues } from 'interfaces/user.interface';
import { Checkbox, Input } from 'shared-components';
import PasswordStrengthWidget from 'shared-components/PasswordStrengthWidget';
import { cn } from 'utils/global';

const UserSettingInputsCheckboxes = () => {
  const [isShownPasswordWidget, handleShowPasswordWidget, handleHidePasswordWidget] = useOpen();

  const {
    register,
    watch,
    resetField,
    formState: { errors },
  } = useFormContext<CreateUserFormValues>();

  const watchPassword = watch(BASE_USER_SETTINGS_FORM_FIELDS.password);
  const watchSetRandomPassword = watch(BASE_USER_SETTINGS_FORM_FIELDS.setRandomPassword);

  useEffect(() => {
    if (watchSetRandomPassword) {
      resetField(BASE_USER_SETTINGS_FORM_FIELDS.password);
    }
  }, [resetField, watchSetRandomPassword]);

  return (
    <>
      <Checkbox
        {...register(BASE_USER_SETTINGS_FORM_FIELDS.setRandomPassword)}
        label="Set random password"
        dataCy="set-random-password-checkbox"
        className="mb-4"
      />
      {!watchSetRandomPassword && (
        <>
          <Input
            {...register(BASE_USER_SETTINGS_FORM_FIELDS.password, PASSWORD_VALIDATION)}
            error={errors[BASE_USER_SETTINGS_FORM_FIELDS.password]?.message}
            onFocus={handleShowPasswordWidget}
            onBlur={handleHidePasswordWidget}
            dataCy="password-field"
            type="password"
            label="Password"
            inputClassName="w-full"
            className="mb-4"
            required
          />
          <PasswordStrengthWidget
            password={watchPassword || ''}
            className={cn('p-0 mb-4', { block: isShownPasswordWidget, hidden: !isShownPasswordWidget })}
          />
        </>
      )}
    </>
  );
};

export default UserSettingInputsCheckboxes;
