import { BaseFilterValues, CommonResponse, OptionType, OptionsTypeValue } from './shared.interface';
import { SignUpPayload } from './sign-up.interface';

export interface CreateTenantFormValues extends BaseTenantFormValues {
  [BASE_TENANT_FORM_FIELDS.SET_RANDOM_PASSWORD]: boolean;
}
export interface SharedTenant {
  adminEmailAddress: string;
  adminFirstName: string;
  adminLastName: string;
  isRegisteredByAnotherPerson: boolean;
  tenantName: string;
}

export interface TenantDetails extends SharedTenant {
  password: string;
  tenancyName: boolean;
  isTwoFactorEnabled: boolean;
  isLockoutEnabled: boolean;
}

export interface SubscriptionResult {
  displayName: string;
  expiringSubscriptionName: string;
  id: number;
  isFree: boolean;
  isInTrialPeriod: boolean;
  isSubscriptionDeactivated: boolean;
  nextSubscriptionPaymentAmount: number;
  periodId: number;
  price: number;
  subscriptionAwaitingCancellation: boolean;
  subscriptionEndDateUtc: string;
  subscriptionPlanId: number;
  subscriptionStartDateUtc: string;
  trialDayCount: null;
}

export interface Tenant extends SharedTenant {
  subscription: SubscriptionResult;
  id: number;
  name: string;
  displayName: string;
  creationDate: string;
  subscriptionName: string;
  subscriptionId: number;
  subscriptionPlanPeriodId: number;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  isActive: boolean;
}
export interface TenantsResult {
  items: Tenant[];
  totalCount: number;
}
export interface TenantsResponse extends CommonResponse {
  result: TenantsResult;
}

export interface TenantDetailsResponse {
  result: Tenant;
}

export interface CreateTenantPayload extends SignUpPayload {
  isRegisteredByAnotherPerson: boolean;
  setRandomPassword?: boolean;
}

export interface ExpiringTenant {
  tenantName: string;
  remainingDayCount: number;
}

export interface ExpiringTenants {
  expiringTenants: ExpiringTenant[];
}

export interface ExpiringTenantsResponse {
  result: ExpiringTenants;
}

export interface RecentTenant {
  id: number;
  name: string;
  creationTime: string;
}

export interface RecentTenants {
  recentTenants: RecentTenant[];
}

export interface RecentTenantsResponse {
  result: RecentTenants;
}

export enum TENANTS_COLUMNS {
  TENANT_NAME = 'nameId',
  TENANT_DISPLAY_NAME = 'name',
  CREATION_DATE = 'creationDate',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_START_DATE = 'subscriptionStartDate',
  SUBSCRIPTION_END_DATE = 'subscriptionEndDate',
  IS_ACTIVE = 'isActive',
  ACTIONS = 'actions',
}

export enum TENANT_SUBSCRIPTIONS_HISTORY_COLUMNS {
  SUBSCRIPTION = 'subscription',
  AMOUNT = 'amount',
  STATUS = 'status',
  PERIOD = 'period',
  PAYMENT_DATE = 'paymentDate',
}

export enum BASE_TENANT_FORM_FIELDS {
  ADMIN_EMAIL_ADDRESS = 'adminEmailAddress',
  ADMIN_FIRST_NAME = 'adminFirstName',
  ADMIN_LAST_NAME = 'adminLastName',
  IS_REGISTERED_BY_ANOTHER_PERSON = 'isRegisteredByAnotherPerson',
  TENANT_NAME = 'tenantName',
  SET_RANDOM_PASSWORD = 'setRandomPassword',
  ADMIN_PASSWORD = 'adminPassword',
}

export enum TENANTS_FILTERS_FORM_FIELD_NAMES {
  IS_ACTIVE = 'isActive',
  SUBSCRIPTION_ID = 'subscriptionId',
}

export enum BASE_TENANT_EDIT_FORM_FIELDS {
  NAME = 'name',
  SUBSCRIPTION = 'subscription',
  IS_ACTIVE = 'isActive',
}

export interface BaseTenantEditFormValues {
  [BASE_TENANT_EDIT_FORM_FIELDS.NAME]: string;
  [BASE_TENANT_EDIT_FORM_FIELDS.SUBSCRIPTION]: OptionsTypeValue;
  [BASE_TENANT_EDIT_FORM_FIELDS.IS_ACTIVE]: boolean;
}

export interface TenantsFilterFormValues extends BaseFilterValues {
  [TENANTS_FILTERS_FORM_FIELD_NAMES.IS_ACTIVE]: OptionType;
  [TENANTS_FILTERS_FORM_FIELD_NAMES.SUBSCRIPTION_ID]: OptionType;
}

export interface BaseTenantFormValues {
  [BASE_TENANT_FORM_FIELDS.ADMIN_EMAIL_ADDRESS]: string;
  [BASE_TENANT_FORM_FIELDS.ADMIN_FIRST_NAME]: string;
  [BASE_TENANT_FORM_FIELDS.ADMIN_PASSWORD]: string;
  [BASE_TENANT_FORM_FIELDS.ADMIN_LAST_NAME]: string;
  [BASE_TENANT_FORM_FIELDS.IS_REGISTERED_BY_ANOTHER_PERSON]: boolean;
  [BASE_TENANT_FORM_FIELDS.TENANT_NAME]: string;
}
