import { useMemo } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useTenantGetDailySalesQuery } from 'apis';
import { BoxVariants } from 'constants/common';
import { DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME } from 'constants/dashboard';
import { getSharedSimplifiedColumnChartOptions } from 'constants/shared-components';
import { Box, LoadingOverlay } from 'shared-components';

export const DailySalesWidget = () => {
  const { data, isLoading } = useTenantGetDailySalesQuery();
  const { dailySales } = data?.result || { dailySales: [] };

  const chartOptions = useMemo(() => {
    return getSharedSimplifiedColumnChartOptions(dailySales, '#34bfa3');
  }, [dailySales]);

  return (
    <Box
      dataCy="daily-sales-widget"
      className="relative h-full"
      headerClassName={DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME}
      variant={BoxVariants.ROUNDED}
      title="WidgetDailySales"
    >
      <LoadingOverlay isLoading={isLoading} />
      <div data-cy="daily-sales-chart-container" className="pb-8 max-h-60 h-full">
        <HighchartsReact
          containerProps={{ style: { height: '100%' } }}
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    </Box>
  );
};
