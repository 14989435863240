import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { STRING_WITHOUT_VARIABLES_PATTERN } from 'constants/global';

export const useTemplateTextEditor = () => {
  const [bodyContent, setBodyContent] = useState('');
  const [updatedBodyContent, setUpdatedBodyContent] = useState('');
  const [templateVariables, setTemplateVariables] = useState<Record<string, string>>({});

  const handleChangeInputValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTemplateVariables((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  useEffect(() => {
    setUpdatedBodyContent(bodyContent);
  }, [bodyContent]);

  useEffect(() => {
    const result = bodyContent.replace(STRING_WITHOUT_VARIABLES_PATTERN, (match, key) => {
      return templateVariables[key] || match;
    });

    setUpdatedBodyContent(result);
  }, [bodyContent, templateVariables]);

  return {
    updatedBodyContent,
    handleChangeInputValue,
    setTemplateVariables,
    setBodyContent,
    templateVariables,
  };
};
