import { INVOICE_LINE_ITEMS_COLUMNS, INVOICES_COLUMNS, INVOICES_FORM_FIELDS } from 'interfaces/invoices.interface';
import { TableColumn, FieldTypes, FormItem } from 'interfaces/shared.interface';
import { Input, InputNumberFormat } from 'shared-components';
import DatePickerInput from 'shared-components/DatePickerInput';

export const INVOICES_COLUMNS_TITLES = {
  [INVOICES_COLUMNS.SUPPLIER_NAME]: 'SupplierName',
  [INVOICES_COLUMNS.INVOICE_NUMBER]: 'InvoiceNumber',
  [INVOICES_COLUMNS.TOTAL_AMOUNT]: 'TotalAmount',
  [INVOICES_COLUMNS.ISSUED_DATE]: 'IssuedDate',
  [INVOICES_COLUMNS.DUE_DATE]: 'DueDate',
  [INVOICES_COLUMNS.ACTIONS]: '',
};

export const INVOICES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICES_COLUMNS.SUPPLIER_NAME,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.SUPPLIER_NAME],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.INVOICE_NUMBER,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.INVOICE_NUMBER],
    width: 'w-2/12',
    sorting: true,
  },

  {
    columnId: INVOICES_COLUMNS.TOTAL_AMOUNT,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.TOTAL_AMOUNT],
    width: 'w-2/12',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.ISSUED_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.ISSUED_DATE],
    width: 'w-2/12',
    className: 'm-auto',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.DUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.DUE_DATE],
    width: 'w-2/12',
    className: 'm-auto',
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.ACTIONS,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.ACTIONS],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const UPLOAD_INVOICES_FORM_FIELDS: FormItem[] = [
  {
    name: INVOICES_FORM_FIELDS.ISSUED_DATE,
    label: 'IssueDate',
    type: FieldTypes.DATE,
    required: true,
    inputClassName: 'w-full [&_svg]:fill-lightGray2',
    dataCy: 'invoice-issued-date-field',
    className: 'mb-4',
    component: DatePickerInput,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: INVOICES_FORM_FIELDS.DUE_DATE,
    label: 'DueDate',
    type: FieldTypes.DATE,
    required: true,
    inputClassName: 'w-full [&_svg]:fill-lightGray2',
    dataCy: 'invoice-due-date-field',
    className: 'mb-4',
    component: DatePickerInput,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: INVOICES_FORM_FIELDS.SUPPLIER_NAME,
    label: 'SupplierName',
    type: FieldTypes.TEXT,
    required: true,
    inputClassName: 'w-full',
    labelClassName: 'text-sm',
    dataCy: 'invoice-supplier-name-field',
    className: 'mb-4',
    maxLength: 128,
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: INVOICES_FORM_FIELDS.INVOICE_NUMBER,
    label: 'InvoiceNumber',
    type: FieldTypes.TEXT,
    required: true,
    inputClassName: 'w-full',
    labelClassName: 'text-sm',
    dataCy: 'invoice-invoice-number-field',
    className: 'mb-4',
    maxLength: 16,
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: INVOICES_FORM_FIELDS.TOTAL_AMOUNT,
    label: 'TotalAmount',
    type: FieldTypes.NUMBER,
    required: true,
    inputClassName: 'w-full',
    labelClassName: 'text-sm',
    dataCy: 'invoice-total-amount-field',
    className: 'mb-4',
    component: InputNumberFormat,
    isShownArrowButtons: true,
    allowNegativeValue: true,
    validation: {
      required: 'ThisFieldIsRequired',
    },
    decimalSeparator: '.',
    fixedDecimalLength: null,
  },
  {
    name: INVOICES_FORM_FIELDS.TOTAL_TAX,
    label: 'TotalTax',
    type: FieldTypes.NUMBER,
    required: true,
    inputClassName: 'w-full',
    labelClassName: 'text-sm',
    dataCy: 'invoice-total-tax-field',
    className: 'mb-4',
    component: InputNumberFormat,
    isShownArrowButtons: true,
    allowNegativeValue: true,
    validation: {
      required: 'ThisFieldIsRequired',
    },
    decimalSeparator: '.',
    fixedDecimalLength: null,
  },
];

export const INVOICE_LINE_ITEMS_COLUMNS_TITLES = {
  [INVOICE_LINE_ITEMS_COLUMNS.DESCRIPTION]: 'Description',
  [INVOICE_LINE_ITEMS_COLUMNS.QUANTITY]: 'Quantity',
  [INVOICE_LINE_ITEMS_COLUMNS.PRICE]: 'Price',
  [INVOICE_LINE_ITEMS_COLUMNS.AMOUNT]: 'Tax',
  [INVOICE_LINE_ITEMS_COLUMNS.TAX]: 'Amount',
};

export const INVOICE_LINE_ITEMS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICE_LINE_ITEMS_COLUMNS.DESCRIPTION,
    title: INVOICE_LINE_ITEMS_COLUMNS_TITLES[INVOICE_LINE_ITEMS_COLUMNS.DESCRIPTION],
    width: 'w-1/12',
  },
  {
    columnId: INVOICE_LINE_ITEMS_COLUMNS.QUANTITY,
    title: INVOICE_LINE_ITEMS_COLUMNS_TITLES[INVOICE_LINE_ITEMS_COLUMNS.QUANTITY],
    width: 'w-1/12',
    sorting: true,
  },

  {
    columnId: INVOICE_LINE_ITEMS_COLUMNS.PRICE,
    title: INVOICE_LINE_ITEMS_COLUMNS_TITLES[INVOICE_LINE_ITEMS_COLUMNS.PRICE],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: INVOICE_LINE_ITEMS_COLUMNS.AMOUNT,
    title: INVOICE_LINE_ITEMS_COLUMNS_TITLES[INVOICE_LINE_ITEMS_COLUMNS.AMOUNT],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: INVOICE_LINE_ITEMS_COLUMNS.TAX,
    title: INVOICE_LINE_ITEMS_COLUMNS_TITLES[INVOICE_LINE_ITEMS_COLUMNS.TAX],
    width: 'w-1/12',
    sorting: true,
  },
];
