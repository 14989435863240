import { PropsWithChildren } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { BASE_WYSIWYG_FIELD_NAMES } from 'interfaces/shared.interface';
import { Skeleton } from 'shared-components/index';

interface Props {
  className?: string;
  isLoading?: boolean;
}

const MODULES = {
  toolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ['bold', 'italic', 'underline', 'strike'], ['link']],
};

const TextEditor = ({ className, isLoading, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  return (
    <div className={className}>
      <div className="text-base text-darkGray mb-2 dark:text-white">{t('EmailTemplateBody')}</div>
      {children}
      <div className="h-auto max-h-96 dark:border-darkBlue4 dark:border" data-cy="text-editor">
        {isLoading ? (
          <Skeleton className="h-72 w-full" />
        ) : (
          <Controller
            name={BASE_WYSIWYG_FIELD_NAMES.TEXT_EDITOR}
            control={control}
            render={({ field }) => (
              <ReactQuill
                theme="snow"
                placeholder="Insert text here ..."
                className="custom-quill-editor"
                value={field.value}
                onChange={field.onChange}
                modules={MODULES}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default TextEditor;
