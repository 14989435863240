import { ReactNode } from 'react';

import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INodeRendererProps } from 'react-accessible-treeview';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ButtonVariants } from 'constants/common';
import { cn } from 'utils/global';

import { Button, Checkbox } from './index';

interface Props extends INodeRendererProps {
  children?: ReactNode;
}

const TreeViewItem = ({
  getNodeProps,
  level,
  isSelected,
  isBranch,
  handleExpand,
  isExpanded,
  element,
  handleSelect,
  isHalfSelected,
  children,
}: Props) => {
  const { t } = useTranslation();
  const isItemSelected = isSelected || isHalfSelected;

  return (
    <div style={{ marginLeft: `${14 * (level - 1)}px` }} data-cy="treeViewItem">
      <div
        {...getNodeProps({ onClick: () => '' })}
        className={cn(
          ' rounded transition-all cursor-pointer text-darkgray text-sm px-1 py-1 w-full my-1 border-transparent focus:border-transparent focus:ring-0',
          {
            'bg-lightBlue1 dark:bg-blue5': isItemSelected,
            'hover:bg-lightGray6 dark:hover:bg-darkBlue10': !isItemSelected,
          }
        )}
      >
        <div className="flex items-center">
          {isBranch ? (
            <Button
              variant={ButtonVariants.TRANSPARENT}
              type="button"
              onClick={handleExpand}
              className={
                'p-0 w-7 h-7 mr-2 flex items-center justify-center rounded-full hover:!bg-lightGray6 dark:!bg-transparent'
              }
            >
              <ArrowSvg
                className={cn('fill-black dark:fill-darkGray3 dark:hover:fill-white transition-all', {
                  'rotate-90': isExpanded,
                })}
              />
            </Button>
          ) : (
            <div className="w-7 h-7 mr-2" />
          )}
          <Checkbox
            dataCy="treeViewItem-checkbox"
            inputClassName="dark:checked:border-white dark:border"
            checked={isItemSelected}
            onClick={handleSelect}
            readOnly
          />
          <FontAwesomeIcon className="mx-2 text-gray2" icon={isExpanded ? faFolderOpen : faFolder} />
          <span className="name">{t(element.name)}</span>
          {children}
        </div>
      </div>
    </div>
  );
};

export default TreeViewItem;
