import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components, MultiValueRemoveProps } from 'react-select';

import { OptionType } from 'interfaces/shared.interface';

const MultiSelectValueRemoveButton = (props: MultiValueRemoveProps<OptionType>) => {
  return (
    components.MultiValueRemove && (
      <components.MultiValueRemove {...props}>
        <FontAwesomeIcon className="dark:text-white text-gray2 px-2 cursor-pointer" icon={faTimesCircle} />
      </components.MultiValueRemove>
    )
  );
};
export default MultiSelectValueRemoveButton;
