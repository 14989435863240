import { FC, ReactNode, useRef } from 'react';

import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import useOutsideClick from 'hooks/useOutsideClick';
import useTheme from 'hooks/useTheme';
import { Button, Skeleton } from 'shared-components';
import { cn } from 'utils/global';

export interface Props {
  icon?: string | IconProp;
  isOpen: boolean;
  heading?: React.ReactNode;
  hideCross?: boolean;
  isDisableOutsideClick?: boolean;
  children: ReactNode;
  onClose: VoidFunction;
  isError?: boolean;
  dataCy?: string;
  bodyClassName?: string;
  isLoading?: boolean;
}

const Modal: FC<Props> = ({
  isOpen,
  heading,
  hideCross,
  isDisableOutsideClick = false,
  children,
  onClose,
  isError,
  icon,
  dataCy,
  bodyClassName,
  isLoading,
}) => {
  const modalRef = useRef(null);
  const portalElement = document.getElementById('modal-root'!);
  const { t } = useTranslation();

  const { themeName } = useTheme();

  useOutsideClick(isDisableOutsideClick ? null : modalRef, onClose);

  let content;
  if (typeof heading === 'string') {
    content = t(heading);
  } else if (heading) {
    content = heading;
  } else {
    content = '';
  }

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <>
      <div
        data-cy={dataCy || 'modal'}
        className={cn(
          cn(
            'App',
            themeName,
            'justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-3 bg-transparent',
            { 'animate-[flipIn_.5s] place-items-center': isError }
          )
        )}
      >
        <div
          className={cn('relative my-6 w-11/12 md:w-2/4', bodyClassName, {
            'm-auto max-w-md w-[32em] text-center': isError,
          })}
        >
          <div
            className="border-0 rounded-md shadow-lg relative flex flex-col dark:bg-darkBlue bg-white outline-none focus:outline-none px-[30px]"
            ref={modalRef}
          >
            <div
              className={cn('flex justify-between pb-2 rounded-md items-center pt-7', {
                'self-center pt-10': icon,
              })}
            >
              <h3 data-cy="heading" className={'text-lg font-semibold dark:text-white  text-darkGray'}>
                {content}
              </h3>
              {icon && (
                <div className="w-24 h-20 animate-[animateErrorIcon_.5s] block self-center">
                  {typeof icon === 'string' ? <img src={icon} alt="icon" /> : <FontAwesomeIcon icon={icon} size="6x" />}
                </div>
              )}

              {!hideCross && (
                <Button
                  dataCy="close-button-icon"
                  className="bg-transparent dark:bg-transparent p-0 w-8 h-8 block text-darkGray3 text-xl rounded-full dark:hover:text-white hover:bg-slate-100 "
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={faClose as IconDefinition} />
                </Button>
              )}
            </div>
            {isLoading ? (
              <div data-cy="content" className="my-4 block relative h-56">
                <Skeleton className="w-64 h-7" />
              </div>
            ) : (
              <div data-cy="content" className="my-4 block dark:text-white text-darkGray relative">
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>,
    portalElement!
  );
};

export default Modal;
