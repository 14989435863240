import { useTranslation } from 'react-i18next';

import { useDeleteConnectionMutation } from 'apis/codat-accounting.api';
import { sharedButtonStyle } from 'constants/codat-accounting';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Button, ErrorModal, WarningModal } from 'shared-components';
interface Props {
  triggerRefetch: () => void;
  isLoading: boolean;
}

const ConnectAnotherAccountButton = ({ triggerRefetch, isLoading }: Props) => {
  const { t } = useTranslation();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [deleteConnection, { error: deleteCodatAccountingError, isLoading: isDeleteConnectionLoading }] =
    useDeleteConnectionMutation();

  const errorDeleteAccountData = (deleteCodatAccountingError as CommonError)?.data || {};

  const handleDeleteConnection = async () => {
    try {
      await deleteConnection().unwrap();
      triggerRefetch();
      handleCloseWarningModal();
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorDeleteAccountData?.error?.message}
        description={errorDeleteAccountData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('AreYouSureYouWantToConnectAnotherAccDescription')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDeleteConnection}
        isLoading={isDeleteConnectionLoading}
      />
      <Button
        isLoading={isLoading || isDeleteConnectionLoading}
        className={sharedButtonStyle}
        data-cy="connect-another-account-button"
        onClick={handleOpenWarningModal}
      >
        {t('ConnectAnotherAccount')}
      </Button>
    </>
  );
};

export default ConnectAnotherAccountButton;
