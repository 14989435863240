import { DownloadFileResponse, UploadFilePayload } from 'interfaces/shared.interface';
import {
  DownloadFilePayload,
  SupportTicketChatDetailsPayload,
  SupportTicketChatDetailsResponse,
  SupportTicketsResponse,
  UploadFileResponse,
  UserChatMessagesPayload,
  UserChatMessagesResponse,
} from 'interfaces/support-tickets.interfaces';

import { coreSplitApis } from './core.api';

export const supportTicketsApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['SupportTickets'] }).injectEndpoints({
  endpoints: (build) => ({
    getAssignedToMeTickets: build.query<SupportTicketsResponse, string>({
      query: (params) => ({
        url: `SupportTicket/GetAdminActiveAssignedTickets?${params}`,
        method: 'GET',
      }),
      providesTags: ['SupportTickets'],
    }),
    getAvailableTickets: build.query<SupportTicketsResponse, string>({
      query: (params) => ({
        url: `SupportTicket/GetAdminAvailableTickets?${params}`,
        method: 'GET',
      }),
      providesTags: ['SupportTickets'],
    }),
    getResolvedTickets: build.query<SupportTicketsResponse, string>({
      query: (params) => ({
        url: `SupportTicket/GetAdminResolvedTickets?${params}`,
        method: 'GET',
      }),
      providesTags: ['SupportTickets'],
    }),
    assignToMeTicket: build.mutation<void, number>({
      query: (ticketId) => ({
        url: `SupportTicket/AssignTicket?ticketId=${ticketId}`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['SupportTickets']),
    }),
    resolveTicket: build.mutation<void, number>({
      query: (ticketId) => ({
        url: `SupportTicket/ResolveTicketByAdmin?ticketId=${ticketId}`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['SupportTickets']),
    }),

    getSupportTicketChatDetails: build.query<SupportTicketChatDetailsResponse, SupportTicketChatDetailsPayload>({
      query: ({ ticketId, isAdmin }) => ({
        url: `SupportTicket/GetSupportTicketChat${isAdmin ? 'Admin' : 'Client'}Details?ticketId=${ticketId}`,
        method: 'GET',
      }),
    }),
    getUserChatMessages: build.query<UserChatMessagesResponse, UserChatMessagesPayload>({
      query: ({ userId, ticketId }) => ({
        url: `Chat/GetUserChatMessages?UserId=${userId}&TicketId=${ticketId}`,
        method: 'GET',
      }),
    }),

    downloadFile: build.mutation<DownloadFileResponse, DownloadFilePayload>({
      query: (data) => ({
        url: 'Chat/DownloadFile',
        method: 'POST',
        data: data,
      }),
    }),

    uploadFile: build.mutation<UploadFileResponse, UploadFilePayload>({
      query: (data) => ({
        url: 'Chat/UploadFile',
        method: 'POST',
        data: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAssignedToMeTicketsQuery,
  useGetAvailableTicketsQuery,
  useGetResolvedTicketsQuery,
  useAssignToMeTicketMutation,
  useResolveTicketMutation,
  useGetSupportTicketChatDetailsQuery,
  useLazyGetSupportTicketChatDetailsQuery,
  useLazyGetUserChatMessagesQuery,
  useDownloadFileMutation,
  useUploadFileMutation,
} = supportTicketsApi;
