import { CONNECTION_STATUS, mapConnectionStatus } from 'constants/codat-accounting';
import { AccountingDetails } from 'interfaces/integrations/codat-accounting.interface';
import { Box, LabelWithValue } from 'shared-components';

import ConnectAnotherAccountButton from './buttons/ConnectAnotherAccountButton';
import DeleteConnectionButton from './buttons/DeleteConnectionButton';
import ResumeConnectionButton from './buttons/ResumeConnectionButton';
import SuspendConnectionButton from './buttons/SuspendConnectionButton';

interface Props {
  triggerRefetch: () => void;
  connectionData?: AccountingDetails;
  connectionStatus: CONNECTION_STATUS;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const CodatConnectedInformationBlock = ({
  connectionStatus,
  setIsLoading,
  triggerRefetch,
  connectionData,
  isLoading,
}: Props) => {
  return (
    <>
      <Box>
        <div className="col-span-1 flex flex-col justify-center">
          <LabelWithValue
            label="AccountingPlatform"
            dataCy="accounting-platform-info"
            value={connectionData?.accountingPlatformName}
          />
          <LabelWithValue
            label="AccountingCompanyName"
            dataCy="accounting-company-name-info"
            value={connectionData?.accountingCompanyName}
          />
          <LabelWithValue
            label="ConnectionStatus"
            dataCy="connection-status-info"
            value={mapConnectionStatus(connectionStatus)}
          />
        </div>

        <div className="flex">
          {connectionStatus === CONNECTION_STATUS.LINKED ? (
            <SuspendConnectionButton triggerRefetch={triggerRefetch} isLoading={isLoading} />
          ) : (
            <ResumeConnectionButton
              connectionData={connectionData}
              triggerRefetch={triggerRefetch}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          <DeleteConnectionButton triggerRefetch={triggerRefetch} isLoading={isLoading} />
          <ConnectAnotherAccountButton triggerRefetch={triggerRefetch} isLoading={isLoading} />
        </div>
      </Box>
    </>
  );
};

export default CodatConnectedInformationBlock;
