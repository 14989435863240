import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import errorSVG from 'assets/svg/error.svg';
import { WRONG_MESSAGE } from 'constants/global';
import { Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';

export interface Props {
  errorMessage?: string;
  isOpen: boolean;
  handleClose: VoidFunction;
  description?: string;
}

const ErrorModal: FC<Props> = ({ errorMessage = WRONG_MESSAGE, description = '', isOpen, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal dataCy="error-modal" isOpen={isOpen} icon={errorSVG} isError hideCross onClose={handleClose}>
      <div className="text-2xl font-semibold text-center">{t(errorMessage)}</div>
      {description && <div className="text-center mt-4">{t(description)}</div>}

      <ModalControlButtons isError onClose={handleClose} />
    </Modal>
  );
};

export default ErrorModal;
