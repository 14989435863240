import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Board, BoardColumnKey } from 'interfaces/samples.interface';
import { Skeleton } from 'shared-components';

interface Props {
  dataCy?: string;
  columns: Record<string, string>;
  tasksData: string[];
  isLoading: boolean;
}

const TasksBoard = ({ dataCy, columns, tasksData, isLoading }: Props) => {
  const { t } = useTranslation();

  const [board, setBoard] = useState<Board>({
    todo: [],
    inProgress: [],
    codeReview: [],
    inTest: [],
    done: [],
  });
  const [draggedTaskId, setDraggedTaskId] = useState<string | null>(null);

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>, column: BoardColumnKey) => {
    event.preventDefault();
    const taskId = draggedTaskId;

    const sourceColumnEntry = Object.entries(board).find(([, tasks]) => tasks.includes(taskId));

    if (sourceColumnEntry) {
      const [sourceColumn, tasks] = sourceColumnEntry;
      const taskIndex = tasks.findIndex((task: string) => task === taskId);

      if (sourceColumn === column || taskIndex === -1) {
        return;
      }

      setBoard((prevState) => {
        const newSourceTasks = [...prevState[sourceColumn as keyof Board]].filter((_, index) => index !== taskIndex);
        const newTargetTasks = [...prevState[column], taskId];

        return {
          ...prevState,
          [sourceColumn]: newSourceTasks,
          [column]: newTargetTasks,
        };
      });
    }
  };

  const onDragStart = (taskId: string) => {
    setDraggedTaskId(taskId);
  };

  useEffect(() => {
    if (tasksData) {
      setBoard({
        todo: tasksData,
        inProgress: [],
        codeReview: [],
        inTest: [],
        done: [],
      });
    }
  }, [tasksData]);

  return (
    <div data-cy={dataCy} className="flex justify-center flex-wrap mx-auto p-5 bg-white dark:bg-darkBlue">
      {Object.entries(columns).map(([columnKey, columns]) => (
        <div
          key={columnKey}
          className="flex flex-col mb-2.5 w-250 mx-2"
          onDragOver={onDragOver}
          onDrop={(event) => onDrop(event, columnKey as BoardColumnKey)}
        >
          <h2 className="text-gray10 dark:text-gray mb-1">
            {t(columns)} ({board[columnKey as BoardColumnKey].length})
          </h2>
          <div
            data-cy="samples-drag-and-drop-column"
            className="flex-grow p-2 rounded-sm min-h-[50px] border border-gray6 flex flex-col justify-start"
          >
            {isLoading ? (
              <div className="p-2">
                <Skeleton className="w-full h-9" />
              </div>
            ) : (
              board[columnKey as BoardColumnKey].map((task) => (
                <div
                  data-cy="samples-drag-and-drop-task"
                  key={task}
                  draggable
                  onDragStart={() => onDragStart(task)}
                  className="p-2 text-sm dark:text-lightBlue mb-2.5 bg-lightGray10 dark:bg-darkBlue10 rounded cursor-grab task"
                >
                  {task}
                </div>
              ))
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TasksBoard;
