import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useRegistrationMutation } from 'apis/auth.api';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { getSignUpFormFields, PASSWORD_VALIDATION } from 'constants/sign-up';
import { useHandleSignIn } from 'hooks/useHandleSignIn';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { SIGN_UP_FROM_FIELDS, SignUpFormValues, SignUpPayload } from 'interfaces/sign-up.interface';
import { Button, ErrorModal, FormContent, Input } from 'shared-components';
import PasswordStrengthWidget from 'shared-components/PasswordStrengthWidget';
import { cn } from 'utils/global';

const SignUpForm = () => {
  const [isShownPasswordWidget, setIsShownPasswordWidget] = useState(false);
  const [isOpenedErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpFormValues>({ mode: 'onBlur' });

  const { t } = useTranslation();

  const { handleSignIn, isLoading: isLoadingSignIn } = useHandleSignIn();
  const [registration, { isLoading, error }] = useRegistrationMutation();

  const { data: errorData } = (error as CommonError) || {};

  const watchPassword = watch(SIGN_UP_FROM_FIELDS.password);
  const watchTermsAndConditions = watch(SIGN_UP_FROM_FIELDS.termsAndConditions);

  const toggleIsShownPasswordWidget = () => setIsShownPasswordWidget((prev) => !prev);

  const onSubmit = async (data: SignUpFormValues) => {
    const payloadData = {
      adminEmailAddress: data[SIGN_UP_FROM_FIELDS.userEmail],
      adminFirstName: data[SIGN_UP_FROM_FIELDS.firstName],
      adminLastName: data[SIGN_UP_FROM_FIELDS.lastName],
      adminPassword: data[SIGN_UP_FROM_FIELDS.password],
      isAdminAgreeToTermsAndConditions: data[SIGN_UP_FROM_FIELDS.termsAndConditions],
      tenantName: data[SIGN_UP_FROM_FIELDS.tenantName],
    } as SignUpPayload;

    try {
      const signUpResponse = await registration(payloadData).unwrap();
      if (signUpResponse.result) {
        const { password, emailAddress, name } = signUpResponse.result;

        const payloadForSignIn = {
          password: password,
          userNameOrEmailAddress: emailAddress || name,
          rememberClient: false,
        };

        await handleSignIn(payloadForSignIn);
      }
    } catch (err) {
      console.error(err);

      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenedErrorModal}
        handleClose={handleCloseErrorModal}
      />

      <form data-cy="sign-up-form" onSubmit={handleSubmit(onSubmit)} className="w-4/4 m-auto mt-9 grid grid-cols-2">
        <FormContent fields={getSignUpFormFields(watchPassword)} register={register} errors={errors} />

        <Input
          {...register(SIGN_UP_FROM_FIELDS.password, PASSWORD_VALIDATION)}
          error={errors[SIGN_UP_FROM_FIELDS.password]?.message}
          onFocus={toggleIsShownPasswordWidget}
          onBlur={toggleIsShownPasswordWidget}
          type="password"
          label={t('AdminPassword')}
          className="mb-4 col-span-2 row-start-5 row-end-6 md:row-start-4 md:row-end-5"
          inputClassName="w-full"
          required
          dataCy="password-input"
        />

        <PasswordStrengthWidget
          dataCy={'password-field'}
          password={watchPassword}
          className={cn(
            'col-span-2 row-start-5 row-end-6',
            { block: isShownPasswordWidget },
            { hidden: !isShownPasswordWidget }
          )}
        />

        <div className="flex items-center justify-between col-span-2">
          <Link to={ROUTES.signIn} className="text-sm">
            <Button type="button" variant={ButtonVariants.BORDERED} disabled={isLoading || isLoadingSignIn}>
              {t('Back')}
            </Button>
          </Link>

          <Button dataCy="register-button" type="submit" isLoading={isLoading} disabled={!watchTermsAndConditions}>
            {t('Register')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SignUpForm;
