import { useGetMyHistoryQuery } from 'apis/subscriptions-tenant.api';
import { PAYMENT_HISTORY_TABLE_COLUMNS } from 'constants/subscription-tenant';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { LoadingOverlay, Pagination, Table } from 'shared-components';

import PaymentHistoryRow from './PaymentHistoryRow';

const PaymentHistory = () => {
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { data, isLoading, refetch, isFetching } = useGetMyHistoryQuery(`${sortingParams}${paginationParams}`);

  const { result } = data || {};

  return (
    <div className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />
      <Table
        dataCy="payment-history-table"
        columns={PAYMENT_HISTORY_TABLE_COLUMNS}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        isTableEmpty={!result?.items.length}
        sortingType={sortingType}
        isLoading={isLoading}
      >
        {result?.items.map((requests) => (
          <PaymentHistoryRow key={requests.id} paymentHistoryData={requests} refetchPayment={refetch} />
        ))}
      </Table>
      <Pagination
        totalCount={result?.totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default PaymentHistory;
