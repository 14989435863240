import { BASE_PERMISSIONS_FROM_FIELD_NAME } from 'constants/common';

import { Permission } from './permissions.interface';
import { BaseFilterValues, CommonResponse, OptionType } from './shared.interface';

export enum ROLES_COLUMNS {
  ROLE_NAME = 'displayName',
  STATIC = 'isStatic',
  DEFAULT = 'isDefault',
  CREATION_TIME = 'creationTime',
  ACTIONS = 'actions',
}

export enum ROLES_FILTERS_FORM_FIELD_NAMES {
  IS_STATIC = 'isStatic',
  IS_DEFAULT = 'isDefault',
}

export enum CREATE_ROLES_FORM_FIELDS {
  displayName = 'displayName',
  isDefault = 'isDefault',
}

export interface CreateRolesFormValues {
  [CREATE_ROLES_FORM_FIELDS.displayName]: string;
  [CREATE_ROLES_FORM_FIELDS.isDefault]: boolean;
}

export interface RolesFilterFormValues extends BaseFilterValues {
  [ROLES_FILTERS_FORM_FIELD_NAMES.IS_STATIC]: OptionType;
  [ROLES_FILTERS_FORM_FIELD_NAMES.IS_DEFAULT]: OptionType;
  [BASE_PERMISSIONS_FROM_FIELD_NAME.PERMISSIONS]: string[];
}

export interface RolesInterface {
  header: string;
}

export interface RolesItem {
  name: string;
  displayName: string;
  isStatic: boolean;
  isDefault: boolean;
  creationTime: string;
  id: number;
}

export interface RolesResult {
  totalCount: number;
  items: RolesItem[];
}

export interface RolesResponse extends CommonResponse {
  result: RolesResult;
}

export interface RoleForEdit {
  permissions: Permission[];
  role: RolesItem;
  grantedPermissionNames: string[];
}

export interface GetRoleForEditPayload extends CommonResponse {
  result: RoleForEdit;
}

export interface RolePayload extends CreateRolesFormValues {
  id: number | null;
}

export interface CreateOrUpdateRolePayload {
  grantedPermissionNames: string[];
  role: RolePayload;
}

export interface CreateOrUpdateRoleResponse extends CommonResponse {
  result: null;
}
