import { useTranslation } from 'react-i18next';

import { SIGN_UP_PAGE_TITLE } from 'constants/sign-up';
import { SignUpForm } from 'page-components/sign-up';
import AuthFormContainer from 'shared-components/AuthFormContainer';

const SignUp = () => {
  const { t } = useTranslation();

  return (
    <AuthFormContainer title={t(SIGN_UP_PAGE_TITLE)} containerClassName="md:w-[485px]">
      <SignUpForm />
    </AuthFormContainer>
  );
};

export default SignUp;
