import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { useCompaniesHouseSearchMutation, useLazyCompaniesHouseDetailsQuery } from 'apis/companies-house.api';
import { useOpen } from 'hooks/useOpen';
import { CompaniesHouseDetailsResponse } from 'interfaces/integrations/companies-house.interface';
import { CommonError, OptionType } from 'interfaces/shared.interface';
import { ErrorModal } from 'shared-components';
import AsyncSelectComponent from 'shared-components/select/AsyncSelectComponent';

interface Props {
  setIsSelected: (isSelected: boolean) => void;
  setCompanyDetailsResponse: (details: CompaniesHouseDetailsResponse | null) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const CompaniesHouseSearch = ({ setIsSelected, setIsLoading, setCompanyDetailsResponse }: Props) => {
  const { t } = useTranslation();

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [postCompaniesHouseSearchItems] = useCompaniesHouseSearchMutation();

  const [getCompaniesHouseDetails, { error }] = useLazyCompaniesHouseDetailsQuery();

  const { data: errorData } = (error as CommonError) || {};

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) {
      return [];
    }

    const response = await postCompaniesHouseSearchItems({ searchQuery: inputValue }).unwrap();
    if (!response) {
      return [];
    }

    const options = response.result.items.map((item) => ({
      value: item.companyNumber,
      label: `${item.title} / ${item.companyNumber}`,
    }));

    return options;
  };

  const handleOptionClick = async (item: OptionType | null) => {
    setIsSelected(true);
    setIsLoading(true);

    await fetchCompanyDetails(item?.value?.toString() || '');
  };

  const fetchCompanyDetails = async (companyNumber: string) => {
    try {
      const response = await getCompaniesHouseDetails(companyNumber);
      if (response.data) {
        const companyDetails: CompaniesHouseDetailsResponse = {
          result: response.data.result,
        };
        setCompanyDetailsResponse(companyDetails);
      }
    } catch (error) {
      handleOpenErrorModal();
      setCompanyDetailsResponse(null);
    }
    setIsLoading(false);
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />
      <div className="w-full" data-cy="companies-house-search" data-tooltip-id="tooltip">
        <div className="pb-5">
          <Tooltip id="tooltip" place="left" positionStrategy="fixed" className="max-w-[170px] font-light z-30">
            {t('PleaseEnterNSymbolsToSearch', { 0: 1 })}
          </Tooltip>
          <AsyncSelectComponent
            isClearable
            loadOptions={loadOptions}
            placeholder={t('SearchCompany')}
            onClickHandler={handleOptionClick}
          />
        </div>
      </div>
    </>
  );
};

export default CompaniesHouseSearch;
