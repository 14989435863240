import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { STRING_WITHOUT_TAGS_PATTERN } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { BASE_WYSIWYG_FIELD_NAMES } from 'interfaces/shared.interface';
import { Button } from 'shared-components';

interface Props {
  isLoading: boolean;
  submitButtonText?: string;
}
export const BottomButtonsContainer = ({ isLoading, submitButtonText }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { watch } = useFormContext();

  const watchTextEditor = watch(BASE_WYSIWYG_FIELD_NAMES.TEXT_EDITOR);
  const textEditorLength = watchTextEditor && watchTextEditor.replace(STRING_WITHOUT_TAGS_PATTERN, '').length;

  const handleClickCancel = () => {
    navigate(ROUTES.emailTemplates);
  };

  return (
    <div className="flex justify-between mt-12">
      <Button dataCy="cancel-button" onClick={handleClickCancel} type="button">
        {t('Cancel')}
      </Button>
      <Button dataCy="save-button" disabled={!textEditorLength} isLoading={isLoading}>
        {t(submitButtonText || 'Save')}
      </Button>
    </div>
  );
};
