import {
  GetAuthLinkResponse,
  GetConnectionDetailsResponse,
  GetContactDetailsResponse,
  GetContactsResponse,
  GetInvoiceDetailsResponse,
  GetInvoiceResponse,
  GetStatusAndTypesResponse,
} from 'interfaces/integrations/sage.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const sageApi = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    getSageAuthLink: build.query<GetAuthLinkResponse, void>({
      query: () => ({
        url: `Sage/GetAuthLink`,
        method: 'GET',
      }),
    }),
    getSageConnectionDetails: build.query<GetConnectionDetailsResponse, void>({
      query: () => ({
        url: `Sage/GetConnectionDetails`,
        method: 'GET',
      }),
    }),
    postSageDisconnectAccount: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `Sage/Disconnect`,
        method: 'POST',
      }),
    }),
    getInvoicesStatuses: build.query<GetStatusAndTypesResponse, void>({
      query: () => ({
        url: `Sage/GetInvoiceStatuses`,
        method: 'GET',
      }),
    }),
    getSageInvoices: build.query<GetInvoiceResponse, string>({
      query: (params) => ({
        url: `Sage/GetInvoices?${params}`,
        method: 'GET',
      }),
    }),
    getContactTypes: build.query<GetStatusAndTypesResponse, void>({
      query: () => ({
        url: `Sage/GetContactTypes`,
        method: 'GET',
      }),
    }),
    getSageContacts: build.query<GetContactsResponse, string>({
      query: (params) => ({
        url: `Sage/GetContacts?${params}`,
        method: 'GET',
      }),
    }),
    getSageInvoiceDetails: build.query<GetInvoiceDetailsResponse, string>({
      query: (params) => {
        return {
          url: `Sage/GetInvoice?invoiceId=${params}`,
          method: 'GET',
        };
      },
    }),
    getSageContactDetails: build.query<GetContactDetailsResponse, string>({
      query: (params) => {
        return {
          url: `Sage/GetContact?contactId=${params}`,
          method: 'GET',
        };
      },
    }),
    postSageConnection: build.mutation<CommonResponse, { connectionCode: string }>({
      query: (data) => {
        return {
          url: `Sage/Connect`,
          method: 'POST',
          data,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetSageAuthLinkQuery,
  useGetSageConnectionDetailsQuery,
  usePostSageDisconnectAccountMutation,
  useGetInvoicesStatusesQuery,
  useGetSageInvoicesQuery,
  useGetContactTypesQuery,
  useGetSageContactsQuery,
  useGetSageInvoiceDetailsQuery,
  useGetSageContactDetailsQuery,
  usePostSageConnectionMutation,
} = sageApi;
