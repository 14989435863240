import { ReactNode, MouseEvent } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DashboardWidgetItems } from 'constants/dashboard';
import { Button } from 'shared-components';

interface Props {
  onDelete: (widgetId: DashboardWidgetItems) => () => void;
  isEditMode: boolean;
  widgetId: DashboardWidgetItems;
  children: ReactNode;
}

export const DashboardWidgetWrapper = ({ children, widgetId, isEditMode, onDelete }: Props) => {
  const preventWidgetSelecting = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      {children}
      {isEditMode && (
        <Button
          className="absolute top-0 right-0 p-1.5 rounded-bl-2lg max-h-6 bg-red dark:bg-red flex items-center justify-center"
          onMouseDown={preventWidgetSelecting}
          onClick={onDelete(widgetId)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      )}
    </>
  );
};
