import { v4 as uuidv4 } from 'uuid';

import { useFilingHistoryQuery } from 'apis/companies-house.api';
import { FILING_HISTORY_TABLE_COLUMNS } from 'constants/companies-house';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CompaniesHouseDetailsResponse } from 'interfaces/integrations/companies-house.interface';
import { LoadingOverlay, Pagination, Table } from 'shared-components';

import FilingHistoryRow from './FilingHistoryRow';

interface Props {
  companyDetailsResponse: CompaniesHouseDetailsResponse;
}

const FilingHistory = ({ companyDetailsResponse }: Props) => {
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const { data, isLoading, isFetching } = useFilingHistoryQuery(
    `${companyDetailsResponse?.result?.companyNumber}&${sortingParams}${paginationParams}`
  );

  return (
    <div data-cy="filing-history-page" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />
      <Table
        dataCy="filing-history-table"
        columns={FILING_HISTORY_TABLE_COLUMNS}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        sortingType={sortingType}
        isLoading={isLoading}
        isTableEmpty={!data?.result.items.length}
      >
        {data?.result.items.map((item) => <FilingHistoryRow {...item} key={uuidv4()} />)}
      </Table>
      <Pagination
        totalCount={data?.result.totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default FilingHistory;
