import { useFormContext } from 'react-hook-form';

import { confirmPasswordValidation, PASSWORD_VALIDATION } from 'constants/sign-up';
import { useOpen } from 'hooks/useOpen';
import { ProfileSettingsFormValues } from 'interfaces/profile-settings.interface';
import { BASE_USER_SETTINGS_FORM_FIELDS } from 'interfaces/user.interface';
import { Input } from 'shared-components';
import PasswordStrengthWidget from 'shared-components/PasswordStrengthWidget';
import { cn } from 'utils/global';

const ProfileSettingsPasswordInputs = () => {
  const [isShownPasswordWidget, handleShowPasswordWidget, handleHidePasswordWidget] = useOpen();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<ProfileSettingsFormValues>();

  const watchPassword = watch(BASE_USER_SETTINGS_FORM_FIELDS.password);

  return (
    <div>
      <Input
        {...register(BASE_USER_SETTINGS_FORM_FIELDS.password, PASSWORD_VALIDATION)}
        error={errors[BASE_USER_SETTINGS_FORM_FIELDS.password]?.message}
        onFocus={handleShowPasswordWidget}
        onBlur={handleHidePasswordWidget}
        type="password"
        label="Password"
        inputClassName="w-full"
        className="mb-4"
        required
      />
      <PasswordStrengthWidget
        password={watchPassword || ''}
        className={cn('p-0 mb-4', { block: isShownPasswordWidget, hidden: !isShownPasswordWidget })}
        elementClassName="first:pt-0 last:pb-0 sm:ml-10"
      />

      <Input
        {...register(BASE_USER_SETTINGS_FORM_FIELDS.repeatPassword, confirmPasswordValidation(watchPassword, true))}
        error={errors[BASE_USER_SETTINGS_FORM_FIELDS.repeatPassword]?.message}
        label="RepeatPassword"
        type="password"
        inputClassName="w-full"
        className="mb-4"
      />
    </div>
  );
};

export default ProfileSettingsPasswordInputs;
