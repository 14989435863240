import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDeleteInvoiceMutation, useDownloadInvoiceMutation } from 'apis/invoices.api';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { InvoicesResult } from 'interfaces/invoices.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { DropdownMenu, ErrorModal, TableData, TableRow, WarningModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { downloadDocument } from 'utils/global';
import { successNotify } from 'utils/notifications';
import { getInvoiceDetailsLink } from 'utils/url';

interface Props {
  invoicesData: InvoicesResult;
  refetchInvoices: VoidFunction;
}

const InvoicesRow = ({ invoicesData, refetchInvoices }: Props) => {
  const { t } = useTranslation();

  const [isOpenWarningModal, handleOnOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const permissions = useAppSelector(selectUserPermissions);

  const navigate = useNavigate();

  const { id, supplierName, invoiceNumber, totalAmount, issuedDate, dueDate } = invoicesData;
  const [deleteInvoice, { error: deleteCodatAccountingError }] = useDeleteInvoiceMutation();

  const [DownloadInvoiceFile, { error: downloadInvoiceError }] = useDownloadInvoiceMutation();
  const errorDeleteCodatAccountingData = (deleteCodatAccountingError as CommonError)?.data || {};
  const errorDownloadInvoiceErrorData = (downloadInvoiceError as CommonError)?.data || {};
  const errorData = errorDeleteCodatAccountingData || errorDownloadInvoiceErrorData;

  const menuItemsAction = [
    {
      dataCy: 'invoices-menu-item-details-button',
      name: t('Details'),
      actionOnClick: () => {
        navigate(getInvoiceDetailsLink(id));
      },
    },
    {
      dataCy: 'invoices-menu-item-download-button',
      name: t('Download'),
      actionOnClick: () => handleDownloadDocument(),
    },
    {
      dataCy: 'invoices-menu-item-delete-button',
      name: 'Delete',
      actionOnClick: handleOnOpenWarningModal,
      hidden: !permissions[Permissions.PAGES_TENANT_INVOICES_DELETE],
    },
  ];

  const handleDeleteInvoice = async () => {
    try {
      await deleteInvoice(invoicesData.id).unwrap();
      handleCloseWarningModal();
      refetchInvoices();
      successNotify(t('SuccessfullyDeleted'));
    } catch {
      handleOpenErrorModal();
    }
  };

  const handleDownloadDocument = async () => {
    try {
      const invoiceId = invoicesData.id;
      const response = await DownloadInvoiceFile(invoiceId);

      if ('data' in response) {
        const result = response.data;
        downloadDocument(result.result.content, result.result.fileName);
      } else {
        console.error('Error:', response.error);
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('InvoiceDeleteWarningMessage', 'Invoice', { 0: invoicesData.invoiceNumber })}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDeleteInvoice}
      />
      <TableRow dataCy="invoices-table-row">
        <TableData dataCy="invoices-table-row-item-supplier-name">{supplierName}</TableData>
        <TableData>{invoiceNumber}</TableData>
        <TableData>{totalAmount}</TableData>
        <TableData>{getFormattedDate(issuedDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}</TableData>
        <TableData>{getFormattedDate(dueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}</TableData>

        <TableData>
          <DropdownMenu dataCy="invoices-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default InvoicesRow;
