import { useEffect, useState } from 'react';

import { endOfDay, startOfDay } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLazyGetAccountTransactionsQuery, useLazyGetCardTransactionsQuery } from 'apis/truelayer.api';
import {
  ACCOUNT_TRANSACTIONS_FILTER_FORM_FIELDS,
  ACCOUNT_TRANSACTIONS_FILTERS_FIELDS_DEFAULT_VALUES,
  ACCOUNTS_TRANSACTIONS_TABLE_COLUMNS,
} from 'constants/truelayer';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { useTableSorting } from 'hooks/useTableSorting';
import { AccountTransactionsFilterFormValues } from 'interfaces/integrations/truelayer.interface';
import { CommonError } from 'interfaces/shared.interface';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

import AccountTransactionsRow from './TransactionsRow';

interface Props {
  accountId?: string;
  cardId?: string;
  title: string;
}

const Transactions = ({ accountId, cardId, title }: Props) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { applyFilters, filterParams } = useFilters();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [maxResultCount, setMaxResultCount] = useState(10);

  const [
    getAccountTransactions,
    { data: accountData, error: accountError, isLoading: isAccountLoading, isFetching: isAccountFetching },
  ] = useLazyGetAccountTransactionsQuery();

  const [
    getCardTransactions,
    { data: cardData, error: cardError, isLoading: isCardLoading, isFetching: isCardFetching },
  ] = useLazyGetCardTransactionsQuery();

  const { data: getTransactionsErrorData } =
    (accountId ? (accountError as CommonError) : (cardError as CommonError)) || {};

  const onSubmitFilters = ({ from, to, ...data }: AccountTransactionsFilterFormValues) => {
    applyFilters({
      ...data,
      From: from ? new Date(startOfDay(new Date(from))).toISOString() : '',
      To: to ? new Date(endOfDay(new Date(to))).toISOString() : '',
    });
    setCurrentPage(1);
  };

  const transactionsFiltersMethods = useForm<AccountTransactionsFilterFormValues>({
    defaultValues: ACCOUNT_TRANSACTIONS_FILTERS_FIELDS_DEFAULT_VALUES,
  });

  const handleClearFields = () => {
    transactionsFiltersMethods.reset(ACCOUNT_TRANSACTIONS_FILTERS_FIELDS_DEFAULT_VALUES);
    if (accountId) {
      getAccountTransactions(`${accountId}`);
    } else {
      getCardTransactions(`${cardId}`);
    }
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetMaxResultCount = (count: number) => {
    setMaxResultCount(count);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * maxResultCount;
  const endIndex = startIndex + maxResultCount;

  const paginatedTransactions = accountId
    ? accountData?.result.data.transactions.slice(startIndex, endIndex)
    : cardData?.result.data.transactions.slice(startIndex, endIndex);

  useEffect(() => {
    if (accountId) {
      getAccountTransactions(`${accountId}&${filterParams}${sortingParams}`);
    } else {
      getCardTransactions(`${cardId}&${filterParams}${sortingParams}`);
    }
    if (getTransactionsErrorData?.error?.message) {
      handleOpenErrorModal();
    }
  }, [
    filterParams,
    sortingParams,
    getAccountTransactions,
    getCardTransactions,
    accountId,
    cardId,
    handleOpenErrorModal,
    getTransactionsErrorData?.error?.message,
  ]);

  useEffect(() => {
    if (getTransactionsErrorData) {
      handleOpenErrorModal();
    }
  }, [getTransactionsErrorData, handleOpenErrorModal, isAccountLoading, isCardLoading]);

  return (
    <>
      <ErrorModal
        errorMessage={getTransactionsErrorData?.error?.message}
        description={getTransactionsErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />

      <div data-cy="transactions-section" className="font-medium text-lg">
        {t(title)}
      </div>
      <div className="relative">
        <LoadingOverlay isLoading={(isAccountFetching && !!accountData) || (isCardFetching && !!cardData)} />
        <FormProvider {...transactionsFiltersMethods}>
          <form onSubmit={transactionsFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="transactions-filtering"
              placeHolder="SearchWithThreeDotUsernameAuditLog"
              handleClearFields={handleClearFields}
              isSearchFieldName={false}
              openFilter={true}
              isLoading={isCardLoading || isAccountLoading}
            >
              <div className="w-full">
                <FilterFormContentWithoutPermissions
                  fields={ACCOUNT_TRANSACTIONS_FILTER_FORM_FIELDS}
                  className="flex pr-4"
                />
              </div>
            </FilterForm>
          </form>
        </FormProvider>

        <Table
          isLoading={isAccountLoading || isCardLoading}
          dataCy="account-transactions-table"
          columns={ACCOUNTS_TRANSACTIONS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={accountId ? !accountData : !cardData}
          sortingType={sortingType}
        >
          {paginatedTransactions?.map((item) => <AccountTransactionsRow key={item.id} response={item} />)}
        </Table>
        <Pagination
          totalCount={
            accountId ? accountData?.result.data.transactions.length : cardData?.result.data.transactions.length || 0
          }
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default Transactions;
