export const convertHexToRgba = (hex: string, opacity: number): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  let a = Math.round(opacity * 255).toString(16);

  if (a.length < 2) {
    a = '0' + a;
  }

  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}${a}`;
};
