import { t } from 'i18next';

import { TabItem, TableColumn } from 'interfaces/shared.interface';
import { PAYMENT_HISTORY_COLUMNS, PER_FREQUENCY } from 'interfaces/subscriptions-tenant.interface';
import PaymentHistory from 'page-components/subscriptions-tenant/payment-history/PaymentHistory';
import SubscriptionInformation from 'page-components/subscriptions-tenant/subscription-information/SubscriptionInformation';

import { getFormatPrice } from './global';

export const SUBSCRIPTION_TABS: TabItem[] = [
  {
    dataCy: 'subscription-information-tab',
    id: 'subscription-information',
    title: 'SubscriptionInformation',
    component: SubscriptionInformation,
  },
  {
    dataCy: 'payment-history-tab',
    id: 'payment-history',
    title: 'PaymentHistory',
    component: PaymentHistory,
  },
];

export const PAYMENT_HISTORY_COLUMNS_TITLES = {
  [PAYMENT_HISTORY_COLUMNS.SUBSCRIPTION]: 'Subscription',
  [PAYMENT_HISTORY_COLUMNS.PROCESS_TIME]: 'ProcessTime',
  [PAYMENT_HISTORY_COLUMNS.AMOUNT]: 'Amount',
  [PAYMENT_HISTORY_COLUMNS.STATUS]: 'Status',
  [PAYMENT_HISTORY_COLUMNS.PERIOD]: 'Period',
  [PAYMENT_HISTORY_COLUMNS.ACTIONS]: '',
};

export const PAYMENT_HISTORY_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: PAYMENT_HISTORY_COLUMNS.SUBSCRIPTION,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[PAYMENT_HISTORY_COLUMNS.SUBSCRIPTION],
    width: 'w-3/12',
  },
  {
    columnId: PAYMENT_HISTORY_COLUMNS.PROCESS_TIME,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[PAYMENT_HISTORY_COLUMNS.PROCESS_TIME],
    width: 'w-2/12',
  },

  {
    columnId: PAYMENT_HISTORY_COLUMNS.AMOUNT,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[PAYMENT_HISTORY_COLUMNS.AMOUNT],
    width: 'w-2/12',
  },
  {
    columnId: PAYMENT_HISTORY_COLUMNS.STATUS,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[PAYMENT_HISTORY_COLUMNS.STATUS],
    width: 'w-2/12',
    className: 'm-auto',
  },
  {
    columnId: PAYMENT_HISTORY_COLUMNS.PERIOD,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[PAYMENT_HISTORY_COLUMNS.PERIOD],
    width: 'w-2/12',
    className: 'm-auto',
  },
  {
    columnId: PAYMENT_HISTORY_COLUMNS.ACTIONS,
    title: PAYMENT_HISTORY_COLUMNS_TITLES[PAYMENT_HISTORY_COLUMNS.ACTIONS],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const RESPONSIVE_CAROUSEL_OPTIONS = [
  {
    breakpoint: '1536px',
    numVisible: 3,
    numScroll: 1,
  },
  {
    breakpoint: '1280px',
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: '1024px',
    numVisible: 1,
    numScroll: 1,
  },
];

export const DATA_CY_REGEX_PATTERN = /\s+/g;

export const getPlanLabelAndValue = (plan: { price: number | null; periodId: number }, isFree: boolean) => {
  let label = '';
  let value = '';

  if (plan.price) {
    label = `${getFormatPrice(plan.price)}`;
    value = `${t(PER_FREQUENCY[plan.periodId])}`;
  } else if (isFree) {
    value = t('Free');
  }

  return { label, value };
};

export const SHARED_MODAL_BUTTON_WRAPPER_STYLES =
  'dark:bg-darkBlue w-full p-6 rounded-b-md dark:border-t-1 dark:border-darkGray3 w-full block h-full';

export const SHARED_MODAL_BUTTON_STYLES = 'w-full block h-full';
