import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { useGetAccountBalanceQuery, useGetAccountInformationQuery } from 'apis/truelayer.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import TrueLayerDetails from 'page-components/integrations/truelayer/details/TrueLayerDetailsLayout';
import { ErrorModal } from 'shared-components';

interface CypressProps {
  accountId?: string;
}

const TrueLayerAccountDetailsPage = ({ accountId: propAccountId }: CypressProps) => {
  const { accountId: routeAccountId } = useParams();
  const accountId = propAccountId || routeAccountId;

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const {
    data: accountInformationData,
    isLoading: isAccountInformationLoading,
    error: accountInformationError,
  } = useGetAccountInformationQuery(accountId ?? '');

  const {
    data: accountBalanceData,
    isLoading: isAccountBalanceLoading,
    error: accountBalanceError,
  } = useGetAccountBalanceQuery(accountId ?? '');

  const informationErrorData = (accountInformationError as CommonError)?.data;
  const balanceErrorData = (accountBalanceError as CommonError)?.data;

  const errorMessage = informationErrorData?.error?.message || balanceErrorData?.error?.message;
  const errorDetails = informationErrorData?.error?.details || balanceErrorData?.error?.details;

  useEffect(() => {
    if (informationErrorData || balanceErrorData) {
      handleOpenErrorModal();
    }
  }, [informationErrorData, balanceErrorData, handleOpenErrorModal]);

  return (
    <>
      <ErrorModal
        errorMessage={errorMessage}
        description={errorDetails}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <TrueLayerDetails
        informationData={accountInformationData?.result.data}
        balanceData={accountBalanceData?.result.data}
        accountId={accountId}
        isLoading={isAccountBalanceLoading || isAccountInformationLoading}
        informationTitle="AccountInformation"
        balanceTitle="AccountBalance"
        transactionsTitle="AccountTransactions"
      />
    </>
  );
};

export default TrueLayerAccountDetailsPage;
