import { DAY_FORMAT_WITH_DASH_WITH_TIME, getFormatPrice } from 'constants/global';
import { TransactionsData } from 'interfaces/integrations/truelayer.interface';
import { TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

interface Props {
  response?: TransactionsData;
}

const TransactionsRow = ({ response }: Props) => {
  return (
    <TableRow dataCy="transactions-table-row">
      <TableData dataCy="account-transactions-date">
        {getFormattedDate(response?.date, DAY_FORMAT_WITH_DASH_WITH_TIME)}
      </TableData>
      <TableData dataCy="account-transactions-description">{response?.description}</TableData>
      <TableData dataCy="account-transactions-amount">
        {getFormatPrice(response?.amount || 0, response?.currency)}
      </TableData>
      <TableData dataCy="account-transactions-type">{response?.type}</TableData>
      <TableData dataCy="account-transactions-category">{response?.category}</TableData>
      <TableData dataCy="account-transactions-running-balance">
        {getFormatPrice(response?.runningBalanceAmount || 0, response?.currency)}
      </TableData>
    </TableRow>
  );
};

export default TransactionsRow;
