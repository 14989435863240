import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { getTrialSubscriptionPurchase } from 'utils/url';

interface SubscriptionLinkProps {
  isPurchaseLink: boolean;
  tenantId?: number;
  subscriptionId?: number;
}

const SubscriptionLink = ({ isPurchaseLink, tenantId, subscriptionId }: SubscriptionLinkProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const purchaseLink = getTrialSubscriptionPurchase(tenantId ?? 0, subscriptionId ?? 0);

  const handleClick = () => {
    const route = isPurchaseLink ? purchaseLink : ROUTES.subscriptionSelect;
    navigate(route, { state: { isBackButton: !isPurchaseLink } });
  };

  return (
    <a className="text-blue2 cursor-pointer hover:text-darkBlue16" onClick={handleClick}>
      {t('ClickHere')}
    </a>
  );
};

export default SubscriptionLink;
