import { useFormContext, UseFormRegister, UseFormWatch } from 'react-hook-form';

import { PASSWORD_VALIDATION } from 'constants/sign-up';
import { useOpen } from 'hooks/useOpen';
import { BASE_TENANT_FORM_FIELDS, CreateTenantFormValues } from 'interfaces/tenants.interface';
import { Checkbox, Input } from 'shared-components';
import PasswordStrengthWidget from 'shared-components/PasswordStrengthWidget';
import { cn } from 'utils/global';

interface TenantInputsCheckboxesProps {
  register: UseFormRegister<CreateTenantFormValues>;
  watch: UseFormWatch<CreateTenantFormValues>;
}

const TenantInputsCheckboxes = ({ register, watch }: TenantInputsCheckboxesProps) => {
  const {
    formState: { errors },
  } = useFormContext<CreateTenantFormValues>();

  const watchPassword = watch(BASE_TENANT_FORM_FIELDS.ADMIN_PASSWORD);
  const watchSetRandomPassword = watch(BASE_TENANT_FORM_FIELDS.SET_RANDOM_PASSWORD);

  const [isShownPasswordWidget, handleShowPasswordWidget, handleHidePasswordWidget] = useOpen();

  const registeredAdminPasswordField = register(BASE_TENANT_FORM_FIELDS.ADMIN_PASSWORD, PASSWORD_VALIDATION);

  return (
    <>
      <Checkbox
        {...register(BASE_TENANT_FORM_FIELDS.SET_RANDOM_PASSWORD)}
        label="Set random password"
        dataCy="set-random-password-checkbox"
        className="mb-4"
        tooltipContent="Hint_RandomGeneratedPassword"
      />
      {!watchSetRandomPassword && (
        <>
          <Input
            {...registeredAdminPasswordField}
            error={errors[BASE_TENANT_FORM_FIELDS.ADMIN_PASSWORD]?.message}
            onFocus={handleShowPasswordWidget}
            onBlur={(e) => {
              registeredAdminPasswordField.onBlur(e);
              handleHidePasswordWidget();
            }}
            dataCy="password-field"
            type="password"
            label="AdminPassword"
            inputClassName="w-full"
            disabled={watchSetRandomPassword}
          />
          <PasswordStrengthWidget
            password={watchPassword || ''}
            className={cn('p-0 mb-4', { block: isShownPasswordWidget, hidden: !isShownPasswordWidget })}
          />
        </>
      )}
    </>
  );
};

export default TenantInputsCheckboxes;
