import { FC, MouseEvent, useState, useMemo, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowLeftSvg } from 'assets/svg/arrow-left.svg';
import { SubmenuItem } from 'interfaces/routes.interfaces';
import { MenuItem } from 'interfaces/sidebar.interfaces';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { SidebarSubmenuItem } from 'shared-components';
import { cn } from 'utils/global';

import SidebarMenuItemWrapper from './SidebarMenuItemWrapper';

interface Props extends MenuItem {
  dropdownIcon?: boolean;
  submenu?: SubmenuItem[];
  isSidebarVisible?: boolean;
  dataCy: string | undefined;
}

const SidebarMenuItem: FC<Props> = ({
  icon,
  label,
  dropdownIcon,
  className,
  onClick,
  active,
  path,
  submenu,
  labelClassName,
  isSidebarVisible,
  dataCy,
}) => {
  const { pathname } = useLocation();
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const submenuRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const permissions = useAppSelector(selectUserPermissions);
  const navigate = useNavigate();

  const isSubmenuActive = useMemo(
    () => submenu?.some(({ path }) => pathname.startsWith(path || '/integrations')),
    [pathname, submenu]
  );

  const isActive = active || isSubmenuActive;

  const toggleSubmenu = () => {
    if (!dropdownIcon) {
      return;
    }

    setIsSubmenuOpen((prev) => !prev);
  };

  const handleSubmenuItemClick = (e: MouseEvent<HTMLDivElement>, path: string) => {
    e.stopPropagation();
    navigate(path);
  };

  useEffect(() => {
    if (isSubmenuActive) {
      setIsSubmenuOpen(true);
    }
  }, [isSubmenuActive]);

  return (
    <div>
      <SidebarMenuItemWrapper path={path}>
        <div className="sidebar-menu-item flex flex-col items-center cursor-pointer hover:dark:bg-darkGray10 hover:bg-darkGray8">
          <div
            data-cy={dataCy}
            onClick={dropdownIcon ? toggleSubmenu : onClick}
            className={cn(
              'flex items-center justify-between py-2.5',
              { 'pl-9': !icon },
              { 'fill-white text-white bg-darkGray8': isSubmenuOpen && !isActive },
              { 'bg-blue2 hover:bg-blue2 fill-white text-white': isActive },
              className
            )}
          >
            <div className="flex items-center h-4">
              {icon && (
                <FontAwesomeIcon
                  icon={icon}
                  className={cn('text-gray3 dark:text-lightBlue', { 'text-white dark:text-white': isActive })}
                />
              )}
              {isSidebarVisible && (
                <p
                  className={cn(
                    'text-sm text-gray3 dark:text-lightBlue ml-3 whitespace-nowrap',
                    { 'text-white dark:text-white': isActive },
                    labelClassName
                  )}
                >
                  {t(label || '')}
                </p>
              )}
            </div>

            {dropdownIcon && isSidebarVisible && (
              <ArrowLeftSvg
                className={cn('w-2 h-3 mr-3 fill-darkGray6 dark:fill-darkGray3 transition-transform', {
                  'rotate-[-90deg] duration-200': isSubmenuOpen,
                })}
              />
            )}
          </div>
        </div>
      </SidebarMenuItemWrapper>

      <div
        className={cn('overflow-hidden transition-all duration-300', {
          'max-h-0 ': !isSubmenuOpen,
          'max-h-[1000px]': isSubmenuOpen,
        })}
      >
        <div ref={submenuRef} className="bg-darkGray9 dark:bg-darkBlue10 cursor-pointer">
          {submenu
            ?.filter((item) => (item.permissionTag ? permissions[item.permissionTag] : true))
            .map((item) => (
              <SidebarSubmenuItem
                icon={item.icon}
                label={t(item.title)}
                path={item.path}
                key={item.title}
                submenu={item.submenu}
                active={pathname.startsWith(item.path || '/integrations/typless')}
                handleSubmenuItemClick={(e) => handleSubmenuItemClick(e, item.path || '')}
                isSidebarVisible={isSidebarVisible}
                dataCy={item.dataCy}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarMenuItem;
