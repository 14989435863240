import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDeleteDocumentTypeMutation } from 'apis/typless.apis';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { DocumentStatus, STATUS_LABELS } from 'constants/typless';
import { useOpen } from 'hooks/useOpen';
import { DocumentTypes } from 'interfaces/integrations/typless.interface';
import { CommonError } from 'interfaces/shared.interface';
import { TableData, TableRow, DropdownMenu, WarningModal, ErrorModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';
import { getDocumentTypeDetailsLink } from 'utils/url';

interface Props {
  documentTypesData: DocumentTypes;
}

const TyplessDocumentTypesRow: FC<Props> = ({ documentTypesData }) => {
  const { t } = useTranslation();
  const { id, name, status, numberOfTrainings, createdDate } = documentTypesData;
  const [isOpenWarningModal, handleOnOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [deleteDocumentType, { error: deleteDocumentTypeError }] = useDeleteDocumentTypeMutation();
  const errorData = (deleteDocumentTypeError as CommonError)?.data || {};

  const navigate = useNavigate();

  const menuItemsAction = [
    {
      dataCy: 'train-button',
      name: 'Train',
      actionOnClick: () => {
        navigate(getDocumentTypeDetailsLink(id.toString()));
      },
      disabled: status === 3,
    },
    {
      dataCy: 'delete-button',
      name: 'Delete',
      actionOnClick: handleOnOpenWarningModal,
    },
  ];

  const statusLabel = (status: DocumentStatus): string => {
    return t(STATUS_LABELS[status] || '');
  };

  const handleDeleteDocumentType = async () => {
    try {
      await deleteDocumentType(id).unwrap();
      handleCloseWarningModal();
      successNotify(t('SuccessfullyDeleted'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('DocumentTypeDeleteWarningMessage')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDeleteDocumentType}
      />
      <TableRow dataCy="typless-table-row">
        <TableData dataCy="table-data-typless-name">{name}</TableData>
        <TableData dataCy="table-data-typless-status">{statusLabel(status)}</TableData>
        <TableData dataCy="table-data-typless-number-of-trainings">{numberOfTrainings}</TableData>
        <TableData dataCy="table-data-typless-created-date">
          {getFormattedDate(createdDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData>
          <DropdownMenu elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default TyplessDocumentTypesRow;
