import { UploadDocumentPayload } from 'interfaces/integrations/typless.interface';
import {
  InvoiceLineItemsResponse,
  InvoiceResponse,
  InvoicesResponse,
  UploadInvoicePayload,
} from 'interfaces/invoices.interface';
import { CommonResponse, DownloadFileResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const invoices = coreSplitApis
  .enhanceEndpoints({ addTagTypes: ['Invoices', 'Invoice', 'InvoiceLineItems'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getInvoices: build.query<InvoicesResponse, string>({
        query: (params) => ({
          url: `Invoice/GetInvoices?${params}`,
          method: 'GET',
        }),
        providesTags: ['Invoices'],
      }),
      uploadInvoice: build.mutation<CommonResponse, UploadInvoicePayload | UploadDocumentPayload>({
        query: (data) => ({
          url: 'Invoice/Upload',
          method: 'POST',
          data,
        }),
      }),
      deleteInvoice: build.mutation<void, number>({
        query: (params) => ({
          url: `Invoice/Delete?invoiceId=${params}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_, error) => (error ? [] : ['Invoices']),
      }),
      downloadInvoice: build.mutation<DownloadFileResponse, number>({
        query: (params) => ({
          url: `Invoice/DownloadInvoiceFile?invoiceId=${params}`,
          method: 'POST',
          responseHandler: (response: Response) => response.blob(),
        }),
      }),
      getInvoice: build.query<InvoiceResponse, string | undefined>({
        query: (id) => ({
          url: `Invoice/Get?id=${id}`,
          method: 'GET',
        }),
        providesTags: ['Invoice'],
      }),
      getInvoiceLineItems: build.query<InvoiceLineItemsResponse, string | number>({
        query: (params) => {
          return {
            url: `Invoice/GetInvoiceLineItems?invoiceId=${params}`,
            method: 'GET',
          };
        },
        providesTags: ['InvoiceLineItems'],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useUploadInvoiceMutation,
  useDeleteInvoiceMutation,
  useDownloadInvoiceMutation,
  useGetInvoiceQuery,
  useGetInvoiceLineItemsQuery,
} = invoices;
