import { useEffect } from 'react';

import { endOfDay, startOfDay, subDays } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useGetSigningRequestsQuery } from 'apis/docusign.api';
import {
  DOCUSIGN_FILTER_FORM_FIELDS,
  DOCUSIGN_FILTERS_FIELDS_DEFAULT_VALUES,
  DOCUSIGN_TABLE_COLUMNS,
} from 'constants/docusign';
import { SEARCH_GOCARDLESS_PLACEHOLDER } from 'constants/gocardless';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { DocusignFilterFormValues } from 'interfaces/integrations/docusign.interface';
import { CommonError } from 'interfaces/shared.interface';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

import DocusignSigningRequestsRow from './DocusignSigningRequestsRow';

const DEFAULT_FILTERS = {
  CreationStartDate: new Date(startOfDay(subDays(new Date(), 7))).toISOString(),
  CreationEndDate: new Date(endOfDay(new Date())).toISOString(),
};

const DocusignSigningRequestsPage = () => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { filterParams, applyFilters, isSameFilters } = useFilters(DEFAULT_FILTERS);
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const {
    data,
    isFetching,
    isLoading,
    error: errorDocusignData,
    refetch,
  } = useGetSigningRequestsQuery(`${filterParams}${paginationParams}`);

  const { result } = data || {};

  const { error: errorData } = (errorDocusignData as CommonError)?.data || {};

  const docusignFiltersMethods = useForm<DocusignFilterFormValues>({
    defaultValues: DOCUSIGN_FILTERS_FIELDS_DEFAULT_VALUES(t('SelectStatus')),
  });

  const onSubmitFilters = async (data: DocusignFilterFormValues) => {
    const { creationStartDate, creationEndDate, status, filter } = data;
    const newFiltersToApply = {
      searchQuery: filter,
      CreationStartDate: creationStartDate ? new Date(startOfDay(new Date(creationStartDate))).toISOString() : '',
      CreationEndDate: creationEndDate ? new Date(endOfDay(new Date(creationEndDate))).toISOString() : '',
      Status: status ? status.value : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    docusignFiltersMethods.reset(DOCUSIGN_FILTERS_FIELDS_DEFAULT_VALUES(t('SelectStatus')));
    onSubmitFilters(DOCUSIGN_FILTERS_FIELDS_DEFAULT_VALUES(t('SelectStatus')));
  };

  useEffect(() => {
    if (errorDocusignData) {
      handleOpenErrorModal();
    }
  }, [errorDocusignData, handleOpenErrorModal]);

  useEffect(() => {
    docusignFiltersMethods.reset(DOCUSIGN_FILTERS_FIELDS_DEFAULT_VALUES(t('SelectStatus')));
  }, [t, docusignFiltersMethods]);

  return (
    <div data-cy="signing-requests-page">
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />

      <div>
        <FormProvider {...docusignFiltersMethods}>
          <form onSubmit={docusignFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="docusign-page-search"
              placeHolder={SEARCH_GOCARDLESS_PLACEHOLDER}
              handleClearFields={handleClearFields}
              isSearchFieldName={false}
              alwaysOpenFilters={true}
            >
              <FilterFormContentWithoutPermissions className="flex" fields={DOCUSIGN_FILTER_FORM_FIELDS} />
            </FilterForm>
          </form>
        </FormProvider>
      </div>
      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />
        <Table
          dataCy="requests-table"
          columns={DOCUSIGN_TABLE_COLUMNS}
          isLoading={isLoading}
          isTableEmpty={!result?.totalCount}
        >
          {result?.items.map((item) => <DocusignSigningRequestsRow key={uuidv4()} signingRequestsData={item} />)}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default DocusignSigningRequestsPage;
