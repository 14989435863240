import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useForgotPasswordMutation } from 'apis/auth.api';
import { ButtonVariants } from 'constants/common';
import {
  FORGOT_PASSWORD_FORM_INPUTS,
  FORGOT_PASSWORD_PAGE_BACK_BUTTON,
  FORGOT_PASSWORD_PAGE_SUBMIT_BUTTON,
  FORGOT_PASSWORD_SUCCESS_MESSAGE,
  FORGOT_PASSWORD_SUCCESS_MESSAGE_DESCRIPTION,
} from 'constants/forgot-password';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { FORGOT_PASSWORD_FROM_FIELDS, ForgotPasswordFormValues } from 'interfaces/forgot-password.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Button, FormContent, ErrorModal, SuccessModal } from 'shared-components';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [forgotPassword, { error, isLoading }] = useForgotPasswordMutation();
  const [isOpenSuccessModal, handleOnOpenSuccessModal, handleCloseSuccessModal] = useOpen();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormValues>({ mode: 'onBlur' });

  const { error: errorData } = (error as CommonError)?.data || {};

  const closeSuccessModal = () => {
    handleCloseSuccessModal();
    navigate(ROUTES.signIn);
  };

  const onSubmit = async (data: ForgotPasswordFormValues) => {
    try {
      const forgotPasswordResponse = await forgotPassword({
        emailAddress: data[FORGOT_PASSWORD_FROM_FIELDS.userEmail],
      }).unwrap();

      if (forgotPasswordResponse.success) {
        handleOnOpenSuccessModal();
      }
    } catch (error) {
      handleOnOpenErrorModal();
    }
  };

  return (
    <>
      <SuccessModal
        successMessage={FORGOT_PASSWORD_SUCCESS_MESSAGE}
        description={FORGOT_PASSWORD_SUCCESS_MESSAGE_DESCRIPTION}
        isOpen={isOpenSuccessModal}
        handleClose={closeSuccessModal}
      />
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
      <form data-cy="forgot-password-form" onSubmit={handleSubmit(onSubmit)} className="w-4/4 m-auto mt-7">
        <FormContent fields={FORGOT_PASSWORD_FORM_INPUTS} register={register} errors={errors} />

        <div className="flex items-center justify-between">
          <Button type="button" variant={ButtonVariants.BORDERED}>
            <Link to={ROUTES.signIn} className="text-sm">
              {t(FORGOT_PASSWORD_PAGE_BACK_BUTTON)}
            </Link>
          </Button>

          <Button isLoading={isLoading} dataCy="submit-button" type="submit">
            {t(FORGOT_PASSWORD_PAGE_SUBMIT_BUTTON)}
          </Button>
        </div>
      </form>
    </>
  );
};

export default ForgotPasswordForm;
