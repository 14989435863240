import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetSupportRequestsQuery } from 'apis/tenant-support-requests.api';
import {
  getSupportRequestsFiltersFieldsDefaultValues,
  SUPPORT_REQUESTS_FILTERS_FORM_FIELDS,
  SUPPORT_REQUESTS_TABLE_COLUMNS,
} from 'constants/support-requests';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { SupportRequestsFilterFormValues } from 'interfaces/tenant-support-requests.interface';
import CreateSupportRequestModal from 'page-components/support-requests/CreateSupportRequestModal';
import SupportRequestsRow from 'page-components/tenant-support-requests/SupportRequestsRow';
import { Button, FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';
import FilterFormContentWithoutPermissions from 'shared-components/filter/FilterFormContentWithoutPermissions';

const SupportRequests = () => {
  const { t } = useTranslation();

  const { applyFilters, filterParams, isSameFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { data, isLoading, isFetching, refetch } = useGetSupportRequestsQuery(
    `${filterParams}${sortingParams}${paginationParams}`
  );
  const [isOpenCreateSupportRequestModal, handleOpenCreateSupportRequestModal, handleCloseCreateSupportRequestModal] =
    useOpen();

  const { result } = data || {};

  const SupportRequestsFiltersMethods = useForm<SupportRequestsFilterFormValues>({
    defaultValues: getSupportRequestsFiltersFieldsDefaultValues(t('SelectStatus')),
  });

  const onSubmitFilters = (data: SupportRequestsFilterFormValues) => {
    const { statusId, filter } = data;

    const newFiltersToApply = {
      filter: filter,
      statusId: statusId.value !== '' ? (statusId.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    SupportRequestsFiltersMethods.reset(getSupportRequestsFiltersFieldsDefaultValues(t('SelectStatus')));
    onSubmitFilters(getSupportRequestsFiltersFieldsDefaultValues(t('SelectStatus')));
  };

  useEffect(() => {
    SupportRequestsFiltersMethods.reset(getSupportRequestsFiltersFieldsDefaultValues(t('SelectStatus')));
  }, [t, SupportRequestsFiltersMethods]);

  return (
    <div data-cy="support-requests-page" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />

      <CreateSupportRequestModal
        handleClose={handleCloseCreateSupportRequestModal}
        isOpen={isOpenCreateSupportRequestModal}
        refetchListTenants={refetch}
      />

      <FormProvider {...SupportRequestsFiltersMethods}>
        <form onSubmit={SupportRequestsFiltersMethods.handleSubmit(onSubmitFilters)}>
          <FilterForm
            dataCy="support-requests-page-search"
            placeHolder={t('SearchWithThreeDot')}
            handleClearFields={handleClearFields}
            rightTopActionButton={
              <Button
                data-cy="create-request-support-button"
                className="h-10 ml-3.5"
                type="button"
                onClick={handleOpenCreateSupportRequestModal}
              >
                {t('CreateRequest')}
              </Button>
            }
          >
            <FilterFormContentWithoutPermissions fields={SUPPORT_REQUESTS_FILTERS_FORM_FIELDS} />
          </FilterForm>
        </form>
      </FormProvider>

      <Table
        dataCy="support-requests-table"
        columns={SUPPORT_REQUESTS_TABLE_COLUMNS}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        isTableEmpty={!result?.items.length}
        sortingType={sortingType}
        isLoading={isLoading}
      >
        {result?.items.map((requests) => (
          <SupportRequestsRow key={requests.id} supportRequestsData={requests} refetchRequests={refetch} />
        ))}
      </Table>

      <Pagination
        totalCount={result?.totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default SupportRequests;
