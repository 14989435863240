import { ReactNode } from 'react';

import { convertHexToRgba } from 'utils/colors';
import { cn } from 'utils/global';

interface Props {
  tooltipId?: string;
  color: string;
  percentage: number;
  children?: ReactNode;
  iconClassName?: string;
}

const ProgressCircle = ({ color = '#a8385d', percentage, tooltipId, children, iconClassName }: Props) => {
  return (
    <div className="relative w-fit outline-0">
      <svg className={cn('size-full w-32 h-32', iconClassName)} viewBox="0 0 36 36">
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          style={{ color: convertHexToRgba(color, 0.4) }}
          className="stroke-current"
          strokeWidth="1.5"
        ></circle>
        <g className="origin-center -rotate-90 transform hover:opacity-50 transition-all" data-tooltip-id={tooltipId}>
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            style={{ color }}
            className="stroke-current"
            strokeWidth="1.5"
            strokeDasharray="100"
            strokeDashoffset={100 - percentage || 0}
            strokeLinecap="butt"
          ></circle>
        </g>
      </svg>
      {children}
    </div>
  );
};

export default ProgressCircle;
