import { FC, useEffect } from 'react';

import { useLazyGetChangeLogPropertiesQuery } from 'apis/os-audit-logs.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal } from 'shared-components';

import AdvancedFilterHandler from '../AdvanceFilterHandler';

interface Props {
  onSearch: (filters: any) => void;
  handleClear: VoidFunction;
}

const ChangeLogsFilter: FC<Props> = ({ onSearch, handleClear }) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [
    getChangeLogsProperties,
    {
      data: getChangeLogsPropertiesData,
      isLoading: isChangeLogsPropertiesLoading,
      isFetching: isChangeLogsPropertiesFetching,
      error: getChangeLogsPropertiesError,
    },
  ] = useLazyGetChangeLogPropertiesQuery();

  const errorData = (getChangeLogsPropertiesError as CommonError)?.data || {};
  const { result } = getChangeLogsPropertiesData || {};

  useEffect(() => {
    if (getChangeLogsPropertiesError) {
      handleOpenErrorModal();
    }
  }, [getChangeLogsPropertiesError, handleOpenErrorModal]);

  useEffect(() => {
    getChangeLogsProperties();
  }, [getChangeLogsProperties]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <AdvancedFilterHandler
        onSearch={onSearch}
        handleClear={handleClear}
        result={result}
        isLoading={isChangeLogsPropertiesLoading || isChangeLogsPropertiesFetching}
      />
    </>
  );
};

export default ChangeLogsFilter;
