import { FC, useMemo } from 'react';

import { usePostDownloadDocumentMutation } from 'apis/docusign.api';
import { STATUS_FILTERS_OPTIONS } from 'constants/docusign';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { SigningRequestsDetails } from 'interfaces/integrations/docusign.interface';
import { CommonError } from 'interfaces/shared.interface';
import { TableData, TableRow, DropdownMenu, ErrorModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { downloadDocument } from 'utils/global';

interface Props {
  signingRequestsData: SigningRequestsDetails;
}

const DocusignSigningRequestsRow: FC<Props> = ({ signingRequestsData }: Props) => {
  const { createdDate, statusChangedDate, documents, recipients, status, envelopeId } = signingRequestsData;
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [docusignDownloadDocument, { error: downloadDocumentError }] = usePostDownloadDocumentMutation();
  const errorUnlockUserErrorData = (downloadDocumentError as CommonError)?.data || {};

  const handleDownloadDocument = async (documentIdParameter: string) => {
    try {
      const documentId = documentIdParameter;
      const response = await docusignDownloadDocument({
        documentId,
        envelopeId,
      }).unwrap();

      if (response && response.result) {
        downloadDocument(response.result, envelopeId, 'application/pdf');
      } else {
        console.error('Error:', response.error);
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const menuItemsAction = useMemo(() => {
    const items = [
      {
        dataCy: 'download-document-button',
        name: 'DownloadDocument',
        actionOnClick: () => handleDownloadDocument('1'),
      },
    ];

    if (status.toString() !== '4') {
      items.push({
        dataCy: 'download-summary-button',
        name: 'DownloadSummary',
        actionOnClick: () => handleDownloadDocument('certificate'),
      });
    }

    return items;
  }, [status]);

  const recipientDetails = useMemo(
    () =>
      recipients.map((recipient, index) => (
        <div key={recipient.email}>
          {recipient.name}
          <br />
          {recipient.email}
          {index < recipients.length - 1 && <hr className="my-3 opacity-40" />}
        </div>
      )),
    [recipients]
  );

  const getStatusLabel = (statusValue: string) => {
    const statusOption = STATUS_FILTERS_OPTIONS.find((option) => option.value === statusValue);
    return statusOption?.label;
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorUnlockUserErrorData?.error?.message}
        description={errorUnlockUserErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <TableRow dataCy="signing-requests-table-row">
        <TableData dataCy="table-data-creation-date">
          {getFormattedDate(createdDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="table-data-creation-date">
          {statusChangedDate ? getFormattedDate(statusChangedDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES) : '-'}
        </TableData>
        <TableData dataCy="table-data-signingDate">{documents[0].name}</TableData>
        <TableData dataCy="table-data-repayments-total-amount">{recipientDetails}</TableData>
        <TableData dataCy="table-data-repayments-status">{getStatusLabel(status.toString())}</TableData>
        <TableData>
          <DropdownMenu elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default DocusignSigningRequestsRow;
