import { PAYMENTS_TABLE_COLUMNS } from 'constants/gocardless';
import { RepaymentDetails } from 'interfaces/integrations/gocardless.interface';
import { Table } from 'shared-components';

import PaymentsRow from './PaymentsRow';

interface Props {
  isError?: boolean;
  data?: RepaymentDetails;
  isLoading: boolean;
}

const Payments = ({ data, isLoading }: Props) => {
  const result = data?.payments;

  return (
    <div className="p-4">
      <Table
        dataCy="payments-table"
        columns={PAYMENTS_TABLE_COLUMNS}
        isLoading={isLoading}
        isTableEmpty={!result?.length}
      >
        {result?.map((item, index) => {
          const orderNumber = index + 1;
          return <PaymentsRow {...item} key={item.id} orderNumber={orderNumber} />;
        })}
      </Table>
    </div>
  );
};

export default Payments;
