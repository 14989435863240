import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { NameValueResult } from 'interfaces/shared.interface';
import { LabelWithValue } from 'shared-components';
import { cn, formatDataCy } from 'utils/global';

interface Props {
  featureValues?: NameValueResult[];
  allFeaturesLookup?: Record<string, string>;
  isLoading: boolean;
  headingClassName?: string;
  bodyClassName?: string;
}

const SubscriptionFeature = ({
  featureValues,
  allFeaturesLookup,
  isLoading,
  headingClassName,
  bodyClassName,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div data-cy="whats-included-section">
      <div className={cn(headingClassName, 'mb-2')}>{t('WhatsIncluded')}</div>
      <div className={cn(bodyClassName)}>
        {featureValues &&
          featureValues
            .filter((feature) => feature.value !== 'false')
            .map((feature) => {
              const displayName = allFeaturesLookup ? allFeaturesLookup[feature.name] : feature.name;

              return (
                <div key={feature.name} className="mb-2">
                  <LabelWithValue
                    icon={faCheckCircle}
                    iconClassName="bg-transparent text-green2 flex pr-1"
                    bodyClassName="pr-1 flex items-center flex-row pb-0 mb-0"
                    labelClassName="dark:text-white"
                    valueClassName="dark:text-white"
                    withColon={true}
                    label={displayName}
                    value={feature.value === 'true' ? '' : feature.value}
                    isLoading={isLoading}
                    skeletonClassName="w-40"
                    dataCy={formatDataCy(displayName)}
                  />
                  {feature.value === 'true' && (
                    <div className="text-sm sm:flex-row mb-2 pr-1 flex items-center flex-row">
                      <FontAwesomeIcon className={'bg-transparent text-green2 flex pr-1'} icon={faCheckCircle} />
                      <p>{displayName}</p>
                    </div>
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default SubscriptionFeature;
