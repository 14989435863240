import { useTranslation } from 'react-i18next';

import { SUBSCRIPTION_PAYMENT_STATUS_LABELS, SUBSCRIPTION_PLAN_PERIOD_LABELS } from 'constants/subscriptions-admin';
import {
  SUBSCRIPTION_PAYMENT_STATUSES,
  SubscriptionPayment,
  SUBSCRIPTION_PLAN_PERIODS,
} from 'interfaces/subscriptions-admin.interface';
import { TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

interface Props {
  payment: SubscriptionPayment;
}

const TenantSubscriptionPaymentRow = ({ payment }: Props) => {
  const { t } = useTranslation();

  return (
    <TableRow dataCy="tenant-subscription-payment-row">
      <TableData>{payment.subscriptionName}</TableData>
      <TableData>{payment.amount}</TableData>
      <TableData dataCy="tenant-subscription-payment-status">
        {t(SUBSCRIPTION_PAYMENT_STATUS_LABELS[payment.status as SUBSCRIPTION_PAYMENT_STATUSES])}
      </TableData>
      <TableData dataCy="tenant-subscription-payment-period">
        {t(SUBSCRIPTION_PLAN_PERIOD_LABELS[payment.subscriptionPlanPeriodId as SUBSCRIPTION_PLAN_PERIODS])}
      </TableData>
      <TableData>{getFormattedDate(payment.creationTime)}</TableData>
    </TableRow>
  );
};

export default TenantSubscriptionPaymentRow;
