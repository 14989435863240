import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useGetOrganizationUnitsQuery, useGetOrganizationUnitsRolesQuery } from 'apis/organization-units.api';
import { ROLES_COLUMNS_TITLES, ROLES_TABLE_COLUMNS } from 'constants/organization-units';
import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { ROLES_COLUMNS, SelectedUnitInfo } from 'interfaces/organization-units.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Button, LoadingOverlay, Pagination, Table } from 'shared-components';

import RolesTabRow from './RolesTabRow';
import SkeletonMembersTabRow from '../SkeletonTabRow';

type Props = {
  selectedUnit: SelectedUnitInfo | null;
  handleOpenAddRoleModal: VoidFunction;
};

const RolesTab = ({ selectedUnit, handleOpenAddRoleModal }: Props) => {
  const { t } = useTranslation();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { filterParams } = useFilters();
  const permissions = useAppSelector(selectUserPermissions);
  const { data: treeData } = useGetOrganizationUnitsQuery();
  const { data, isLoading, isFetching, refetch } = useGetOrganizationUnitsRolesQuery(
    {
      roleId: selectedUnit?.id,
      params: `${filterParams}${sortingParams}${paginationParams}`,
    },
    {
      skip: !selectedUnit?.id,
    }
  );
  const { result } = data || {};

  const COLUMNS = permissions[Permissions.PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_ROLES]
    ? [
        {
          columnId: ROLES_COLUMNS.DELETE,
          title: ROLES_COLUMNS_TITLES[ROLES_COLUMNS.DELETE],
          width: 'w-3/12',
        },
        ...ROLES_TABLE_COLUMNS,
      ]
    : ROLES_TABLE_COLUMNS;

  return (
    <div data-cy="roles-tab" className="relative">
      {isLoading && (
        <Table columns={COLUMNS} isLoading={true}>
          <SkeletonMembersTabRow />
        </Table>
      )}
      {!isLoading && treeData?.result?.items.length ? (
        <>
          <LoadingOverlay isLoading={isFetching && !!data} />

          {permissions[Permissions.PAGES_TENANT_ORGANIZATION_UNITS_MANAGE_ROLES] && (
            <Button
              className="absolute right-0 -top-7 -translate-y-[100%]"
              dataCy="roles-tab-add-role-button"
              type="button"
              onClick={handleOpenAddRoleModal}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" className="text-white mr-1" />
              {t('AddRole')}
            </Button>
          )}

          <Table
            dataCy="roles-table"
            columns={COLUMNS}
            handleSetTabName={handleSetTabName}
            sortingColumnId={sortingColumnId}
            isTableEmpty={!result?.items.length}
            sortingType={sortingType}
            isLoading={isLoading}
          >
            {result?.items.map((item) => (
              <RolesTabRow key={item.id} userData={item} refetchRoles={refetch} selectedUnit={selectedUnit} />
            ))}
          </Table>

          <Pagination
            totalCount={result?.totalCount}
            handleSetMaxResultCount={handleSetMaxResultCount}
            maxResultCount={maxResultCount}
            handleSetCurrentPage={handleSetCurrentPage}
            currentPage={currentPage}
          />
        </>
      ) : (
        <p className="text-gray2 text-sm">{t('SelectAnOrganizationUnitToSeeRoles')}</p>
      )}
    </div>
  );
};

export default RolesTab;
