import {
  CODAT_INVOICE_FORM_FIELDS,
  INVOICES_COLUMNS,
  SUPPLIERS_COLUMNS,
} from 'interfaces/integrations/codat-accounting.interface';
import {
  BaseFilterValues,
  FieldTypes,
  FormItem,
  SHARED_SEARCH_FILTERS_FIELD_NAME,
  TabItem,
  TableColumn,
} from 'interfaces/shared.interface';
import Invoices from 'page-components/integrations/codat-accounting/invoices/Invoices';
import Suppliers from 'page-components/integrations/codat-accounting/suppliers/Suppliers';
import DatePickerInput from 'shared-components/DatePickerInput';

export enum CONNECTION_STATUS {
  PENDING_AUTH = 'PendingAuth',
  LINKED = 'Linked',
  UNLINKED = 'Unlinked',
}

export const mapConnectionStatus = (status: CONNECTION_STATUS) => {
  switch (status) {
    case CONNECTION_STATUS.PENDING_AUTH:
      return 'Pending Auth';
    case CONNECTION_STATUS.LINKED:
      return 'Linked';
    case CONNECTION_STATUS.UNLINKED:
      return 'Unlinked';
    default:
      return status;
  }
};

export const sharedButtonStyle = 'mr-4 mt-2';

export const CODAT_ACCOUNTING_TABS: TabItem[] = [
  {
    id: 'invoices',
    title: 'Invoices',
    component: Invoices,
  },
  {
    dataCy: 'suppliers-tab-button',
    id: 'suppliers',
    title: 'Suppliers',
    component: Suppliers,
  },
];

export const INVOICES_COLUMNS_TITLES = {
  [INVOICES_COLUMNS.ISSUE_DATE]: 'IssueDate',
  [INVOICES_COLUMNS.DUE_DATE]: 'DueDate',
  [INVOICES_COLUMNS.INVOICE_NUMBER]: 'InvoiceNumber',
  [INVOICES_COLUMNS.AMOUNT_DUE]: 'AmountDue',
  [INVOICES_COLUMNS.TOTAL]: 'Total',
};

export const INVOICES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICES_COLUMNS.ISSUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.ISSUE_DATE],
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.DUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.DUE_DATE],
    sorting: true,
  },

  {
    columnId: INVOICES_COLUMNS.INVOICE_NUMBER,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.INVOICE_NUMBER],
  },
  {
    columnId: INVOICES_COLUMNS.AMOUNT_DUE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.AMOUNT_DUE],
    sorting: true,
  },
  {
    columnId: INVOICES_COLUMNS.TOTAL,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.TOTAL],
    sorting: true,
  },
];

export const sharedTableRowLabelClassName = 'font-bold dark:text-white ';

export const sharedTableRowBodyClassName = 'm-0';

export const CODAT_INVOICE_FORM_INPUTS: FormItem[] = [
  {
    name: CODAT_INVOICE_FORM_FIELDS.ISSUE_DATE_FROM,
    type: FieldTypes.DATE,
    className: 'ml-10 w-64 col-start-1 col-end-4 md:col-start-1 md:col-end-2 md:row-start-2 md:row-end-3',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
    label: 'IssueDateFrom',
    component: DatePickerInput,
    dataCy: 'issue-date-from-field',
  },
  {
    name: CODAT_INVOICE_FORM_FIELDS.ISSUE_DATE_TO,
    type: FieldTypes.DATE,
    className: 'ml-6 w-64 col-start-1 col-end-4 md:col-start-1 md:col-end-2 md:row-start-2 md:row-end-3',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
    label: 'IssueDateTo',
    component: DatePickerInput,
    dataCy: 'issue-date-to-field',
  },
];

export const SEARCH_CODAT_INVOICES_PLACEHOLDER = 'SearchByInvoiceNumber';

export enum CODAT_INVOICES_FILTERS_FORM_FIELD_NAMES {
  ISSUE_DATE_FROM = 'IssueDateFrom',
  ISSUE_DATE_TO = 'IssueDateTo',
}

export enum CODAT_INVOICES_FILTERS_FIELD_NAMES {
  FROM_DATE = 'IssueDateFrom',
  TO_DATE = 'IssueDateTo',
}

export interface CodatInvoicesFilterFormValues extends BaseFilterValues {
  [CODAT_INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_FROM]: string;
  [CODAT_INVOICES_FILTERS_FORM_FIELD_NAMES.ISSUE_DATE_TO]: string;
  [SHARED_SEARCH_FILTERS_FIELD_NAME.SEARCH_FIELD]: string;
}

export const CODAT_INVOICES_FILTERS_FIELDS_DEFAULT_VALUES = {
  [CODAT_INVOICES_FILTERS_FIELD_NAMES.FROM_DATE]: '',
  [CODAT_INVOICES_FILTERS_FIELD_NAMES.TO_DATE]: '',
  [SHARED_SEARCH_FILTERS_FIELD_NAME.SEARCH_FIELD]: '',
};

export interface CodatSuppliersFilterFormValues extends BaseFilterValues {
  [SHARED_SEARCH_FILTERS_FIELD_NAME.SEARCH_FIELD]: string;
}

export const CODAT_SUPPLIERS_FILTERS_FIELDS_DEFAULT_VALUES = {
  [SHARED_SEARCH_FILTERS_FIELD_NAME.SEARCH_FIELD]: '',
};

export const SUPPLIERS_COLUMNS_TITLES = {
  [SUPPLIERS_COLUMNS.NAME]: 'Name',
  [SUPPLIERS_COLUMNS.CONTACT_NAME]: 'ContactName',
  [SUPPLIERS_COLUMNS.EMAIL_ADDRESS]: 'EmailAddress',
  [SUPPLIERS_COLUMNS.REGISTRATION_NUMBER]: 'RegistrationNumber',
};

export const SUPPLIERS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: SUPPLIERS_COLUMNS.NAME,
    title: SUPPLIERS_COLUMNS_TITLES[SUPPLIERS_COLUMNS.NAME],
    sorting: true,
  },
  {
    columnId: SUPPLIERS_COLUMNS.CONTACT_NAME,
    title: SUPPLIERS_COLUMNS_TITLES[SUPPLIERS_COLUMNS.CONTACT_NAME],
    sorting: true,
  },
  {
    columnId: SUPPLIERS_COLUMNS.EMAIL_ADDRESS,
    title: SUPPLIERS_COLUMNS_TITLES[SUPPLIERS_COLUMNS.EMAIL_ADDRESS],
  },
  {
    columnId: SUPPLIERS_COLUMNS.REGISTRATION_NUMBER,
    title: SUPPLIERS_COLUMNS_TITLES[SUPPLIERS_COLUMNS.REGISTRATION_NUMBER],
  },
];

export const SEARCH_CODAT_SUPPLIERS_PLACEHOLDER = 'SearchBySupplierOrContactName';
