import { useTranslation } from 'react-i18next';

import { NO_DATA } from 'constants/common';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { PaymentsDetails } from 'interfaces/integrations/gocardless.interface';
import { TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { useFormatPriceFunction } from 'utils/global';

type Props = PaymentsDetails & { orderNumber: number };

const PaymentsRow = ({ orderNumber, amount, status, createdDate, chargeDate }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <TableRow>
        <TableData dataCy="payment-order-number-table-data">{orderNumber || NO_DATA}</TableData>
        <TableData dataCy="payment-amount-table-data">{useFormatPriceFunction(amount || 0)}</TableData>
        <TableData dataCy="payment-status-table-data">{t(status || NO_DATA)}</TableData>
        <TableData dataCy="payment-created-date-table-data">
          {getFormattedDate(createdDate || NO_DATA, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="payment-charge-date-table-data">
          {getFormattedDate(chargeDate || NO_DATA, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
      </TableRow>
    </>
  );
};

export default PaymentsRow;
