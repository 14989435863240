import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

interface Props {
  children?: ReactNode;
  title?: string;
  titlePrefix?: string;
  customHeader?: ReactNode;
  headerClassName?: string;
  bodyClassName?: string;
  childrenClassName?: string;
}

const AppContentLayout: FC<Props> = ({
  children,
  title,
  headerClassName,
  customHeader,
  titlePrefix,
  bodyClassName,
  childrenClassName,
}) => {
  const { t } = useTranslation();
  const customStyle = {
    minHeight: `calc(100vh - 50px)`,
  };
  return (
    <div
      className={cn('py-6 px-10 bg-lightGray3 min-h-screen min-w-screen dark:bg-darkBlue4 mt-auto', bodyClassName)}
      style={customStyle}
    >
      <div className={cn('flex items-center justify-between', headerClassName)}>
        {title && (
          <h2 data-cy="page-title" className="text-3xl mb-5 text-darkGray dark:text-white">
            {titlePrefix} {t(title)}
          </h2>
        )}

        {customHeader}
      </div>

      <div className={(cn('my-8 mx-4'), childrenClassName)}>
        <div>{children}</div>
      </div>
    </div>
  );
};
export default AppContentLayout;
