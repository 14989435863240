import { useTranslation } from 'react-i18next';

import { useDeleteConnectionMutation } from 'apis/codat-accounting.api';
import { sharedButtonStyle } from 'constants/codat-accounting';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Button, ErrorModal, WarningModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

interface Props {
  triggerRefetch: () => void;
  isLoading: boolean;
}

const DeleteConnectionButton = ({ triggerRefetch, isLoading }: Props) => {
  const { t } = useTranslation();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [deleteConnection, { error: deleteCodatAccountingError, isLoading: isDeleteConnectionLoading }] =
    useDeleteConnectionMutation();

  const errorDeleteAccountData = (deleteCodatAccountingError as CommonError)?.data || {};

  const handleDeleteConnection = async () => {
    try {
      await deleteConnection().unwrap();
      triggerRefetch();
      handleCloseWarningModal();
      successNotify(t('ConnectionDeleted'));
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorDeleteAccountData?.error?.message}
        description={errorDeleteAccountData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('AreYouSureYouWantToDeleteAccountingConnection')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDeleteConnection}
        isLoading={isDeleteConnectionLoading}
      />

      <Button
        isLoading={isLoading || isDeleteConnectionLoading}
        className={sharedButtonStyle}
        data-cy="delete-connection-button"
        onClick={handleOpenWarningModal}
      >
        {t('DeleteConnection')}
      </Button>
    </>
  );
};

export default DeleteConnectionButton;
