import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { InvoiceInformation } from 'interfaces/invoices.interface';
import { Box, LabelWithValue, LoadingOverlay } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

interface Props {
  data: InvoiceInformation | undefined;
  fetching: boolean;
  isLoading: boolean;
}

const InvoiceData = ({ data, fetching, isLoading }: Props) => {
  const noDate = '-';

  const {
    supplierName,
    clientName,
    totalAmount,
    supplierAddress,
    clientAddress,
    totalTax,
    supplierEmailAddress,
    clientEmailAddress,
    issuedDate,
    supplierPhoneNumber,
    clientPhoneNumber,
    dueDate,
  } = data || {};

  const labelValueMap: { [key: string]: string | number | undefined } = {
    SupplierName: supplierName,
    ClientName: clientName,
    TotalAmount: totalAmount || 0,
    SupplierAddress: supplierAddress,
    ClientAddress: clientAddress,
    TotalTax: totalTax || 0,
    SupplierEmailAddress: supplierEmailAddress,
    ClientEmailAddress: clientEmailAddress,
    IssuedDate: getFormattedDate(issuedDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    SupplierPhoneNumber: supplierPhoneNumber,
    ClientPhoneNumber: clientPhoneNumber,
    DueDate: getFormattedDate(dueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
  };

  return (
    <div className="relative">
      <LoadingOverlay isLoading={fetching && !!data} />
      <Box data-cy="invoice-data" className="grid grid-cols-3 grid-rows-4 gap-4 p-4">
        {Object.entries(labelValueMap).map(([label, value]) => (
          <LabelWithValue
            isLoading={isLoading}
            key={label}
            labelClassName="w-1/2 items-center flex"
            label={label}
            value={value || noDate}
          />
        ))}
      </Box>
    </div>
  );
};

export default InvoiceData;
