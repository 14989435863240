import {
  useGetCurrentLoginInformationsQuery,
  useGetCurrentUserDetailsQuery,
  useGetUserConfigQuery,
} from 'apis/user.api';

const useBaseApiLoading = () => {
  const { isLoading: isGetCurrentUserDetailsLoading } = useGetCurrentUserDetailsQuery();

  const { isLoading: isGetCurrentLoginInformationsLoading } = useGetCurrentLoginInformationsQuery();

  const { isLoading: isGetUserConfigLoading } = useGetUserConfigQuery();

  const isLoading = isGetCurrentUserDetailsLoading || isGetCurrentLoginInformationsLoading || isGetUserConfigLoading;

  return {
    isLoading,
  };
};

export default useBaseApiLoading;
