import { useTranslation } from 'react-i18next';

import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { ChangeLog } from 'interfaces/integrations/os-audit-logs.interface';
import { DropdownMenu, Modal, TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

import ChangeLogsDetailsModalContent from './ChangeLogsDetailsModalContent';

interface Props {
  logData: ChangeLog;
}
const ChangeLogsRow = ({ logData }: Props) => {
  const [isOpenDetailsModal, handleOpenDetailsModal, handleCloseDetailsModal] = useOpen();
  const { t } = useTranslation();
  const { userEmail, changeTypeName, changeTime, entityTypeFullName } = logData;
  const menuActions = [
    {
      dataCy: 'menu-item-details-button',
      name: 'ShowDetails',
      actionOnClick: handleOpenDetailsModal,
    },
  ];

  return (
    <>
      <Modal
        isOpen={isOpenDetailsModal}
        onClose={handleCloseDetailsModal}
        heading="Details"
        dataCy="change-log-details-modal"
      >
        <ChangeLogsDetailsModalContent onClose={handleCloseDetailsModal} {...logData} />
      </Modal>

      <TableRow dataCy="change-logs-table-row">
        <TableData>{t(changeTypeName)}</TableData>
        <TableData>{entityTypeFullName}</TableData>
        <TableData dataCy="table-data-change-logs-user-email">{userEmail}</TableData>
        <TableData>{getFormattedDate(changeTime, DAY_FORMAT_WITH_TIME)}</TableData>

        <TableData>
          <DropdownMenu elements={menuActions} />
        </TableData>
      </TableRow>
    </>
  );
};

export default ChangeLogsRow;
