import { TabItem, TableColumn } from 'interfaces/shared.interface';
import { SUPPORT_TICKETS_COLUMNS } from 'interfaces/support-tickets.interfaces';
import AssignedToMe from 'page-components/support-tickets/assigned-to-me/AssignedToMeTab';
import AvailableTickets from 'page-components/support-tickets/available-tickets/AvailableTicketsTab';
import ResolvedTickets from 'page-components/support-tickets/resolved-tickets/ResolvedTicketsTab';

export const SUPPORT_TICKETS_TABS: TabItem[] = [
  {
    dataCy: 'assigned-tickets-tab-button',
    id: 'assigned-tickets',
    title: 'MyActiveAssignedTickets',
    component: AssignedToMe,
  },
  {
    dataCy: 'available-tickets-tab-button',
    id: 'available-tickets',
    title: 'AvailableUnassignedTickets',
    component: AvailableTickets,
  },
  {
    dataCy: 'resolved-tickets-tab-button',
    id: 'resolved-tickets',
    title: 'ResolvedTickets',
    component: ResolvedTickets,
  },
];

export const RESOLVED_TICKETS_COLUMNS_TITLES = {
  [SUPPORT_TICKETS_COLUMNS.REQUESTER]: 'Requester',
  [SUPPORT_TICKETS_COLUMNS.ISSUE]: 'Issue',
  [SUPPORT_TICKETS_COLUMNS.RESOLVER]: 'Resolver',
  [SUPPORT_TICKETS_COLUMNS.CREATION_TIME]: 'CreationTime',
  [SUPPORT_TICKETS_COLUMNS.ACTIONS]: '',
};

export const SUPPORT_TICKETS_TABLE_COLUMNS = (showResolverColumn?: boolean): TableColumn[] => [
  {
    columnId: SUPPORT_TICKETS_COLUMNS.REQUESTER,
    title: RESOLVED_TICKETS_COLUMNS_TITLES[SUPPORT_TICKETS_COLUMNS.REQUESTER],
    width: 'w-3/12',
  },
  {
    columnId: SUPPORT_TICKETS_COLUMNS.ISSUE,
    title: RESOLVED_TICKETS_COLUMNS_TITLES[SUPPORT_TICKETS_COLUMNS.ISSUE],
    width: 'w-6/12',
  },
  ...(showResolverColumn
    ? [
        {
          columnId: SUPPORT_TICKETS_COLUMNS.RESOLVER,
          title: RESOLVED_TICKETS_COLUMNS_TITLES[SUPPORT_TICKETS_COLUMNS.RESOLVER],
          width: 'w-1/12',
        },
      ]
    : []),
  {
    columnId: SUPPORT_TICKETS_COLUMNS.CREATION_TIME,
    title: RESOLVED_TICKETS_COLUMNS_TITLES[SUPPORT_TICKETS_COLUMNS.CREATION_TIME],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: SUPPORT_TICKETS_COLUMNS.ACTIONS,
    title: RESOLVED_TICKETS_COLUMNS_TITLES[SUPPORT_TICKETS_COLUMNS.ACTIONS],
    width: 'w-1/12',
    className: 'm-auto',
  },
];
