import { COMPANIES_HOUSE_TABS } from 'constants/companies-house';
import { CompaniesHouseDetailsResponse } from 'interfaces/integrations/companies-house.interface';
import Tabs from 'shared-components/Tabs';

interface Props {
  companyDetailsResponse: CompaniesHouseDetailsResponse | null;
}

const CompaniesHouseTabs = ({ companyDetailsResponse }: Props) => {
  return (
    <div>
      <Tabs tabs={COMPANIES_HOUSE_TABS} componentProps={{ companyDetailsResponse: companyDetailsResponse }} />
    </div>
  );
};

export default CompaniesHouseTabs;
