import { ReactNode, useEffect } from 'react';

import { I18nextProvider } from 'react-i18next';

import i18n from 'configs/i18n';
import { useUserConfig } from 'hooks/useUserConfig';

interface Props {
  children: ReactNode;
}

const I18NextLayout = ({ children }: Props) => {
  const { handleGetUserConfig, userConfigData, isLoading } = useUserConfig();

  useEffect(() => {
    if (userConfigData) {
      return;
    }

    handleGetUserConfig();
  }, [handleGetUserConfig, userConfigData]);

  if (!userConfigData || isLoading) {
    return null;
  }

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export default I18NextLayout;
