import { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { MenuItem } from 'interfaces/sidebar.interfaces';

interface Props extends Pick<MenuItem, 'path'> {
  children: ReactNode;
}

const SidebarMenuWrapper = ({ children, path }: Props) => {
  if (!path) {
    return <>{children}</>;
  }

  return <Link to={path}>{children}</Link>;
};

export default SidebarMenuWrapper;
