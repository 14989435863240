import { BaseFilterValues, CommonResponse, OptionType } from './shared.interface';

export enum SUPPORT_REQUESTS_COLUMNS {
  ISSUE = 'issue',
  STATUS = 'status',
  CREATION_TIME = 'creationTime',
  ACTIONS = 'actions',
}

export enum SUPPORT_REQUESTS_STATUSES {
  REQUESTED = 1,
  IN_PROGRESS = 2,
  RESOLVED = 3,
}

export enum SUPPORT_REQUESTS_FILTERS_FORM_FIELD_NAMES {
  SELECT_STATUS = 'statusId',
}

export enum TENANT_SUPPORT_REQUEST_FORM_FIELDS {
  SUBJECT = 'subject',
  DESCRIPTION = 'description',
}

export interface SupportRequestsFilterFormValues extends BaseFilterValues {
  [SUPPORT_REQUESTS_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: OptionType;
}

export interface CreateSupportRequestPayload {
  subject: string;
  description: string;
}

export interface SupportRequests {
  id: number;
  supportResponderId: number;
  requestSubject: string;
  description: string;
  responderName: string;
  statusId: number;
  creationTime: string;
}

export interface SupportRequestsResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: SupportRequests[];
  };
}
