import { useEffect } from 'react';

import { addDays, endOfDay, startOfDay } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetEntityHistoryObjectTypesQuery,
  useLazyGetEntityChangesQuery,
  useLazyGetEntityChangesToExcelQuery,
} from 'apis/audit-logs.api';
import {
  CHANGE_LOGS_TABLE_COLUMNS,
  getChangeLogsFilterFormFields,
  getChangeLogsFiltersFieldsDefaultValues,
} from 'constants/audit-logs';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { ChangeLogsFilterFormValues } from 'interfaces/audit-logs.interface';
import { CommonError } from 'interfaces/shared.interface';
import {
  Button,
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';
import { downloadDocument } from 'utils/global';

import ChangeLogsRow from './ChangeLogsRow';

const DEFAULT_FILTERS = {
  StartDate: new Date(startOfDay(new Date())).toISOString(),
  EndDate: new Date(endOfDay(addDays(new Date(), 1))).toISOString(),
};

const ChangeLogs = () => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { filterParams, applyFilters, isSameFilters } = useFilters(DEFAULT_FILTERS);

  const [getEntityChanges, { data: changeLogsData, isLoading, isFetching }] = useLazyGetEntityChangesQuery();
  const { data: entityTypesData } = useGetEntityHistoryObjectTypesQuery();

  const [getEntityChangesToExcel, { isLoading: isEntityChangesToExcelLoading, error: entityChangesError }] =
    useLazyGetEntityChangesToExcelQuery();
  const errorData = (entityChangesError as CommonError)?.data || {};

  const changeLogsFiltersMethods = useForm<ChangeLogsFilterFormValues>({
    defaultValues: getChangeLogsFiltersFieldsDefaultValues(t('SelectAType')),
  });

  const { result: changeLogsResult } = changeLogsData || {};
  const { result: entityTypesResult } = entityTypesData || {};

  const entityTypesOptions = entityTypesResult?.map((item) => ({ label: item.name, value: item.value }));

  const onSubmitFilters = ({ filter, EndDate, StartDate, EntityTypeFullName }: ChangeLogsFilterFormValues) => {
    const newFiltersToApply = {
      UserName: filter,
      StartDate: StartDate ? new Date(startOfDay(new Date(StartDate))).toISOString() : '',
      EndDate: EndDate ? new Date(endOfDay(new Date(EndDate))).toISOString() : '',
      EntityTypeFullName: EntityTypeFullName?.value,
    };

    if (isSameFilters(newFiltersToApply)) {
      getEntityChanges(`${filterParams}${sortingParams}${paginationParams}`);
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    changeLogsFiltersMethods.reset(getChangeLogsFiltersFieldsDefaultValues(t('SelectAType')));
    onSubmitFilters(getChangeLogsFiltersFieldsDefaultValues(t('SelectAType')));
  };

  useEffect(() => {
    getEntityChanges(`${filterParams}${sortingParams}${paginationParams}`);
  }, [filterParams, getEntityChanges, paginationParams, sortingParams]);

  const handleDownloadDocument = async () => {
    try {
      const currentFilters = `${filterParams}${sortingParams}${paginationParams}`;
      const { result: newData } = await getEntityChangesToExcel(currentFilters).unwrap();

      if (newData) {
        downloadDocument(newData.context, newData.fileType, newData.fileName);
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };
  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="change-logs-tab" className="relative">
        <LoadingOverlay isLoading={isFetching && !!changeLogsData} />
        <div>
          <FormProvider {...changeLogsFiltersMethods}>
            <form onSubmit={changeLogsFiltersMethods.handleSubmit(onSubmitFilters)}>
              <FilterForm
                dataCy="change-logs-filtering"
                placeHolder="SearchWithThreeDotUsernameAuditLog"
                handleClearFields={handleClearFields}
                rightTopActionButton={
                  <Button
                    isLoading={isEntityChangesToExcelLoading}
                    data-cy="download-change-logs-button"
                    className="h-10 ml-3.5"
                    type="button"
                    onClick={handleDownloadDocument}
                  >
                    {t('Download')}
                  </Button>
                }
              >
                <div className="w-full xl:w-[67%]">
                  <FilterFormContentWithoutPermissions
                    fields={getChangeLogsFilterFormFields(entityTypesOptions)}
                    className="flex justify-between px-12 py-6 w-4/5"
                  />
                </div>
              </FilterForm>
            </form>
          </FormProvider>
        </div>

        <Table
          dataCy="change-logs-table"
          columns={CHANGE_LOGS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!changeLogsResult?.items.length}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {changeLogsResult?.items.map((item) => <ChangeLogsRow key={item.id} logData={item} />)}
        </Table>

        <Pagination
          totalCount={changeLogsResult?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default ChangeLogs;
