import { ExpiringTenant } from 'interfaces/tenants.interface';
import { TableData, TableRow } from 'shared-components';

interface Props {
  tenant: ExpiringTenant;
}

export const ExpiringTenantsTableRow = ({ tenant: { tenantName, remainingDayCount } }: Props) => {
  return (
    <TableRow dataCy="expiring-tenants-table-row">
      <TableData>{tenantName}</TableData>
      <TableData>{remainingDayCount}</TableData>
    </TableRow>
  );
};
