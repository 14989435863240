import { t } from 'i18next';

import { FormItem, TabItem } from 'interfaces/shared.interface';
import { BASE_USER_SETTINGS_FORM_FIELDS } from 'interfaces/user.interface';
import ManagePersonalInformationSetting from 'page-components/profile-settings/manage-personal-information/ManagePersonalInfromationSetting';
import ProfileInformationSettings from 'page-components/profile-settings/ProfileInformationSettings';
import ProfileRolesSettings from 'page-components/profile-settings/ProfileRolesSettings';
import TwoFactorAuthentication from 'page-components/profile-settings/two-factor-authentication/TwoFactorAuthentication';
import { Checkbox, Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';

export const getProfileSettingsTabs = (
  rolesCount?: number,
  isTwoFactorEnabled?: boolean,
  isTenant?: boolean
): TabItem[] => [
  {
    id: 'user-information',
    title: 'UserInformation',
    component: ProfileInformationSettings,
    dataCy: 'user-information-tab',
  },
  ...(isTwoFactorEnabled
    ? [
        {
          id: 'two-factor-authentication',
          title: 'Two-factor authentication',
          component: TwoFactorAuthentication,
          dataCy: 'two-factor-authentication-tab',
        },
      ]
    : []),
  {
    id: 'roles',
    title: t('Roles') + ` ${rolesCount || ''}`,
    component: ProfileRolesSettings,
  },
  ...(isTenant
    ? [
        {
          id: 'manage-personal-information',
          title: t('ManagePersonalInformation'),
          component: ManagePersonalInformationSetting,
          dataCy: 'manage-personal-information-tab',
        },
      ]
    : []),
];

export const PROFILE_NAMES_FORM_FIELDS: FormItem[] = [
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.name,
    label: 'Name',
    required: true,
    inputClassName: 'w-full',
    dataCy: 'name-field',
    className: 'mb-4',
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.surname,
    label: 'Surname',
    required: true,
    component: Input,
    dataCy: 'surname-field',
    inputClassName: 'w-full',
    className: 'mb-4',
    validation: {
      required: 'ThisFieldIsRequired',
    },
  },
];

export const PROFILE_INFORMATION_SETTINGS_FIELDS = [
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.email,
    label: 'EmailAddress',
    dataCy: 'email-field',
    required: true,
    component: Input,
    validation: {
      required: 'EmailRequired',
      pattern: { message: 'InvalidEmailAddress', value: EMAIL_PATTERN },
    },
    inputClassName: 'w-full',
  },
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.phone,
    label: 'PhoneNumber',
    component: Input,
    inputClassName: 'w-full',
    dataCy: 'phone-number-field',
  },
];

export const getProfileInformationSettingsFormFields = (): FormItem[] => [
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.sendActivationEmail,
    label: 'SendActivationEmail',
    component: Checkbox,
  },
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.isActive,
    label: 'Active',
    component: Checkbox,
  },
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.isTwoFactorEnabled,
    label: 'IsTwoFactorEnabled',
    component: Checkbox,
    dataCy: 'two-factor-enabling-field',
  },
  {
    name: BASE_USER_SETTINGS_FORM_FIELDS.isLockoutEnabled,
    label: 'IsLockoutEnabled',
    component: Checkbox,
  },
];

export const USER_NO_ROLES_MESSAGE = 'UserShouldHaveAtLeastOneRole';
