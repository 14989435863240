import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const selectState = (state: RootState) => state.user;

export const selectUserDetailsWithRoles = createSelector(selectState, ({ data: user, roles, profilePictureId }) => ({
  user,
  roles,
  profilePictureId,
}));

export const selectCurrentUserId = createSelector(selectState, ({ data }) => data?.id);

export const selectTenantId = createSelector(selectState, ({ tenantId }) => tenantId);

export const selectProfilePictureId = createSelector(selectState, ({ profilePictureId }) => profilePictureId);

export const selectLanguagesSettings = createSelector(selectState, ({ localization }) => ({
  currentLanguage: localization?.currentLanguage,
  languages: localization?.languages,
}));

export const selectUserPermissions = createSelector(
  selectState,
  ({ permissions: { grantedPermissions, isManagingUsersAccess } }) => ({
    ...grantedPermissions,
    isManagingUsersAccess,
  })
);
