import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProfileSettingsFormValues } from 'interfaces/profile-settings.interface';
import { BASE_USER_SETTINGS_FORM_FIELDS, UserRole } from 'interfaces/user.interface';
import { Checkbox } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';

interface Props {
  roles: UserRole[];
  handleClose: VoidFunction;
  isLoading: boolean;
}

const ProfileRolesSettings = ({ roles, handleClose, isLoading }: Props) => {
  const { register } = useFormContext<ProfileSettingsFormValues>();
  const { t } = useTranslation();

  return (
    <>
      <div className="p-5">
        {roles?.length
          ? roles?.map(({ roleName, roleDisplayName }) => (
              <Checkbox
                key={roleName}
                className="mb-2"
                label={t(roleDisplayName)}
                {...register(`${BASE_USER_SETTINGS_FORM_FIELDS.roles}.${roleName}`)}
                isLoading={isLoading}
              />
            ))
          : null}
      </div>
      <ModalControlButtons isShownSubmit className="pb-5" isLoading={isLoading} onClose={handleClose} />
    </>
  );
};

export default ProfileRolesSettings;
