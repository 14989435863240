import { ROUTES } from 'constants/routes';

export const getTenantDetailsLink = (id: number) => ROUTES.tenantDetails.replace(':id', String(id));

export const getTenantUsersLink = (id: number) => ROUTES.tenantUsers.replace(':tenantId', String(id));

export const getTenantRolesLink = (id: number) => ROUTES.tenantRoles.replace(':tenantId', String(id));

export const getEditEmailTemplateLink = (id: number) =>
  ROUTES.editEmailTemplate.replace(':emailTemplateId', String(id));

export const getCloneEmailTemplateLink = (id: number) =>
  ROUTES.cloneEmailTemplate.replace(':emailTemplateId', String(id));

export const getSendEmailTemplateLink = (id: number) =>
  ROUTES.sendEmailTemplateById.replace(':emailTemplateId', String(id));

export const getSupportRequestChatLink = (id: number) => ROUTES.supportTicketsChat.replace(':ticketId', String(id));

export const getInvoiceDetailsLink = (id: number) => ROUTES.invoiceDetailsPage.replace(':invoiceId', String(id));

export const getTrueLayerLink = (link: string) => {
  return link.replace(/redirect_uri=([^&]*)/, `redirect_uri=${window.location.origin + ROUTES.trueLayer}`);
};

export const getTrueLayerAccountDetails = (id: string) =>
  ROUTES.trueLayerAccountDetails.replace(':accountId', String(id));

export const getTrueLayerCardDetails = (id: string) => ROUTES.trueLayerCardDetails.replace(':cardId', String(id));

export const getSageLink = (link: string) => {
  return link.replace(`/[?&]$/`, `${window.location.origin + ROUTES.sage}`);
};

export const getCustomerDetailsLink = (id: string) => ROUTES.customerDetailsPage.replace(':customerId', String(id));

export const getInvoiceQuickBooksDetailsLink = (id: string) =>
  ROUTES.invoiceQuickBooksDetailsPage.replace(':invoiceId', String(id));

export const getSageInvoiceDetails = (id: string) => ROUTES.sageInvoiceDetails.replace(':invoiceId', String(id));

export const getSageContactDetails = (id: string) => ROUTES.sageContactDetails.replace(':contactId', String(id));

export const getDocumentTypeDetailsLink = (id: string) =>
  ROUTES.typlessTrainDocumentPage.replace(':documentId', String(id));

export const getSubscriptionUpgrade = (subscriptionId: number) => {
  const baseRoute = ROUTES.subscriptionUpgrade.replace(':subscriptionId', String(subscriptionId));
  const queryParams = `upgradeSubscriptionId=${subscriptionId}&onboarding=false`;
  return `${baseRoute}?${queryParams}`;
};

export const getSubscriptionPurchase = (tenantId: number, subscriptionId: number, startType?: number) => {
  const baseRoute = ROUTES.subscriptionPurchase.replace(':subscriptionId', String(subscriptionId));
  const queryParams = `tenantId=${tenantId}&subscriptionId=${subscriptionId}&subscriptionStartType=${startType}&onboarding=false`;
  return `${baseRoute}?${queryParams}`;
};

export const getSubscriptionStripePurchase = (paymentId: number) => {
  const baseRoute = ROUTES.subscriptionStripePurchase.replace(':paymentId', String(paymentId));
  const queryParams = `paymentId=${paymentId}&redirectUrl=app%2Fmain%2Fsubscriptions&onboarding=false`;
  return `${baseRoute}?${queryParams}`;
};

export const getStripeCancelServices = (paymentId: string) => {
  const baseRoute = ROUTES.subscriptionPaymentNotCompleted.replace(':paymentId', String(paymentId));
  const queryParams = `paymentId=${paymentId}`;
  return `${baseRoute}?${queryParams}`;
};

export const getTrialSubscriptionPurchase = (tenantId: number, subscriptionId: number) => {
  const baseRoute = ROUTES.subscriptionPurchase.replace(':subscriptionId', String(subscriptionId));
  const queryParams = `subscriptionId=${subscriptionId}&tenantId=${tenantId}`;
  return `${baseRoute}?${queryParams}`;
};
