import React, { forwardRef } from 'react';

import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

import { Props as RadioInputSetProps } from './RadioInputSet';

interface Props extends Omit<RadioInputSetProps, 'error' | 'legendClassName' | 'radioLegend' | 'errorClassName'> {
  radioLegend?: string;
  dataCy?: string;
}

const RadioInputSetV1 = forwardRef<HTMLInputElement, Props>(
  ({ radioLegend, fieldSetClassName, radioOptions, className, disabled, ...inputProps }: Props, ref) => {
    const { t } = useTranslation();

    return (
      <fieldset className={cn('flex', fieldSetClassName)}>
        {radioLegend && <legend>{radioLegend}</legend>}
        {radioOptions.map(({ value, label, id, checked, dataCy }) => (
          <div
            className="radio-input-set-v1 inline-flex items-center [&_label]:first:rounded-l [&_label]:last:rounded-r"
            key={id}
            data-cy={dataCy}
          >
            <input
              id={`${inputProps.name}-${id}`}
              ref={ref}
              className={cn('hidden', className)}
              type="radio"
              value={value}
              defaultChecked={checked}
              disabled={disabled}
              {...inputProps}
            />

            {label && (
              <label
                htmlFor={`${inputProps.name}-${id}`}
                className={cn(
                  'checked:bg-black text-sm text-white bg-gray2 py-1.5 px-2.5 hover:bg-lightGray13 border border-gray2 hover:border-lightGray13 flex items-center justify-center transition-all cursor-pointer',
                  {
                    'bg-gray16': checked,
                  }
                )}
              >
                {t(label)}
              </label>
            )}
          </div>
        ))}
      </fieldset>
    );
  }
);

export default RadioInputSetV1;

RadioInputSetV1.displayName = 'RadioInputSetV1';
