import { COOKIE_KEYS } from 'constants/common';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAppSelector } from 'modules/store';
import { selectLanguagesSettings } from 'modules/user/selector';
import { cn } from 'utils/global';

import Button from './button/Button';

const FooterForm = () => {
  const { handleGetUserConfig, isLoading } = useUserConfig();

  const { currentLanguage, languages } = useAppSelector(selectLanguagesSettings);

  const onChangeLanguage = async (languageName: string) => {
    try {
      document.cookie = `${COOKIE_KEYS.LOCALIZATION_CULTURE_NAME}=${languageName}`;

      await handleGetUserConfig();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div data-cy="footer-form" className="flex place-content-center">
      {languages
        ?.filter((language) => language.name !== currentLanguage?.name)
        .map((language) => (
          <Button
            isLoading={isLoading}
            key={language.name}
            className="h-[42px] flex justify-center items-center bg-lightGray"
            onClick={() => onChangeLanguage(language.name)}
          >
            <div className={cn(language.icon, 'w-5 fill-darkGray6 dark:fill-darkGray3')} />
          </Button>
        ))}
    </div>
  );
};

export default FooterForm;
