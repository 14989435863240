export enum SIGN_UP_FROM_FIELDS {
  tenantName = 'tenantName',
  firstName = 'firstName',
  lastName = 'lastName',
  userEmail = 'userEmail',
  password = 'password',
  confirmPassword = 'confirmPassword',
  termsAndConditions = 'termsAndConditions',
}
export type SignUpFormValues = Omit<Record<SIGN_UP_FROM_FIELDS, string>, SIGN_UP_FROM_FIELDS.termsAndConditions> & {
  [SIGN_UP_FROM_FIELDS.termsAndConditions]: boolean;
};
export interface SignUpPayload {
  adminEmailAddress: string;
  adminFirstName: string;
  adminLastName: string;
  adminPassword?: string;
  isAdminAgreeToTermsAndConditions: boolean;
  tenantName: string;
}
