import { useTranslation } from 'react-i18next';

import { NO_DATA } from 'constants/common';
import { AccountsData, CardsData } from 'interfaces/integrations/truelayer.interface';
import { LabelWithValue } from 'shared-components';

interface Props {
  accountId?: string;
  data?: CardsData | AccountsData;
  title: string;
  isLoading: boolean;
}

const Information = ({ accountId, data, title, isLoading }: Props) => {
  const { t } = useTranslation();

  const topLabelValueMap = {
    Name: data?.name,
    Type: data?.type,
    ...(accountId ? {} : { Network: (data as CardsData)?.network }),
    Currency: data?.currency,
  };

  const bottomLabelValueMap = accountId
    ? {
        IBAN: (data as AccountsData)?.iban,
        Number: (data as AccountsData)?.number,
        SortCode: (data as AccountsData)?.sortCode,
        SwiftBic: (data as AccountsData)?.swiftBic,
      }
    : {
        NameOnCard: (data as CardsData)?.nameOnCard,
        CardNumber: `*${(data as CardsData)?.last4Digits}`,
        ValidFrom: (data as CardsData)?.validFrom,
        ValidTo: (data as CardsData)?.validTo,
      };

  return (
    <>
      <div data-cy="information-section">
        <div className="pb-3 font-medium text-lg">{t(title)}</div>
        <div className="grid lg:flex pb-3 md:grid-cols-3 flex-row break-all sm:grid-cols-2">
          {Object.entries(topLabelValueMap).map(([label, value]) => (
            <LabelWithValue
              bodyClassName="block pr-1"
              key={label}
              labelClassName="mr-3"
              valueClassName="mr-5"
              withColon={false}
              label={label}
              value={value || NO_DATA}
              isLoading={isLoading}
              skeletonClassName="w-40"
            />
          ))}
        </div>
        <div className="grid lg:flex pb-3 md:grid-cols-3 lg:grid-cols-4 flex-row break-all sm:grid-cols-2">
          {Object.entries(bottomLabelValueMap).map(([label, value]) => (
            <LabelWithValue
              bodyClassName="block pr-1"
              key={label}
              labelClassName="mr-3"
              valueClassName="mr-5"
              withColon={false}
              label={label}
              value={value || NO_DATA}
              isLoading={isLoading}
              skeletonClassName="w-40"
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Information;
