import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonVariants } from 'constants/common';
import { SIGN_IN_FORM_INPUTS } from 'constants/sign-in';
import { useHandleSignIn } from 'hooks/useHandleSignIn';
import { SignInPayload } from 'interfaces/auth.interface';
import { CommonError } from 'interfaces/shared.interface';
import { SIGN_IN_FORM_FIELDS, SignInFormValues } from 'interfaces/sign-in.interface';
import { Button, ErrorModal, FormContent } from 'shared-components';

const SignInForm = () => {
  const { handleSignIn, isLoading, error, isOpenErrorModal, handleOnCloseErrorModal } = useHandleSignIn();
  const { data: errorData } = (error as CommonError) || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({ mode: 'onBlur' });

  const onSubmit = async (data: SignInFormValues) => {
    const payloadData = {
      password: data[SIGN_IN_FORM_FIELDS.password],
      userNameOrEmailAddress: data[SIGN_IN_FORM_FIELDS.userName],
      rememberClient: data[SIGN_IN_FORM_FIELDS.rememberMe],
    } as SignInPayload;

    try {
      await handleSignIn(payloadData);
    } catch (error) {
      console.error(error);
    }
  };
  const { t } = useTranslation();

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />

      <form data-cy="sign-in-form" onSubmit={handleSubmit(onSubmit)} className="w-4/4 m-auto mt-9">
        <FormContent fields={SIGN_IN_FORM_INPUTS} register={register} errors={errors} />
        <div className="flex items-center justify-between space-x-4">
          <div className="flex items-center" />
          <Button
            data-cy="submit-button"
            isLoading={isLoading}
            className="text-[14px]"
            variant={ButtonVariants.MAIN}
            type="submit"
          >
            {t('LogIn')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SignInForm;
