import { CommonResponse } from './shared.interface';

export enum RESET_PASSWORD_FROM_FIELDS {
  newPassword = 'newPassword',
  confirmPassword = 'confirmPassword',
}
export interface SetPasswordFormValues {
  [RESET_PASSWORD_FROM_FIELDS.newPassword]: string;
  [RESET_PASSWORD_FROM_FIELDS.confirmPassword]: string;
}

export interface ResetPasswordPayload {
  c: string;
  newPassword: string;
}

export interface ResetResponse extends CommonResponse {
  result: null;
}
