import {
  AllUserEmailsResponse,
  EmailTemplateFormPayload,
  EmailTemplatesResponse,
  EmailTemplateByIdResponse,
  SendCustomEmailPayload,
  EditEmailTemplatePayload,
} from 'interfaces/email-templates.interface';

import { coreSplitApis } from './core.api';

export const emailTemplatesApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['EmailTemplates'] }).injectEndpoints({
  endpoints: (build) => ({
    getTemplates: build.query<EmailTemplatesResponse, string>({
      query: (params) => ({
        url: `EmailTemplate/GetAll?${params}`,
        method: 'GET',
      }),
      providesTags: ['EmailTemplates'],
    }),
    deleteEmailTemplate: build.mutation<void, number>({
      query: (emailTemplateId) => ({
        url: `EmailTemplate/Delete?Id=${emailTemplateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EmailTemplates']),
    }),
    getAllUserEmails: build.query<AllUserEmailsResponse, void>({
      query: () => ({
        url: 'EmailTemplate/GetAllUserEmails',
        method: 'GET',
      }),
    }),
    sendCustomEmail: build.mutation<void, SendCustomEmailPayload>({
      query: (data) => ({
        url: 'EmailTemplate/SendCustomEmail',
        method: 'POST',
        data: data,
      }),
    }),
    createTemplate: build.mutation<void, EmailTemplateFormPayload>({
      query: (data) => ({
        url: 'EmailTemplate/Create',
        method: 'POST',
        data: data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EmailTemplates']),
    }),
    getEmailTemplateById: build.query<EmailTemplateByIdResponse, string | undefined>({
      query: (id) => ({
        url: `EmailTemplate/Get?emailTemplateId=${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    updateTemplate: build.mutation<void, EditEmailTemplatePayload>({
      query: (data) => ({
        url: 'EmailTemplate/Update',
        method: 'PUT',
        data: data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EmailTemplates']),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useDeleteEmailTemplateMutation,
  useGetAllUserEmailsQuery,
  useSendCustomEmailMutation,
  useGetEmailTemplateByIdQuery,
  useUpdateTemplateMutation,
  useLazyGetEmailTemplateByIdQuery,
} = emailTemplatesApi;
