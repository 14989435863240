import { v4 as uuidv4 } from 'uuid';

import { useOfficersQuery } from 'apis/companies-house.api';
import { OFFICERS_TABLE_COLUMNS } from 'constants/companies-house';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CompaniesHouseDetailsResponse } from 'interfaces/integrations/companies-house.interface';
import { LoadingOverlay, Pagination, Table } from 'shared-components';

import OfficersRow from './OfficersRow';

interface Props {
  companyDetailsResponse: CompaniesHouseDetailsResponse;
}

const Officers = ({ companyDetailsResponse }: Props) => {
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const {
    data: result,
    isLoading,
    isFetching,
  } = useOfficersQuery(`${companyDetailsResponse?.result?.companyNumber}&${sortingParams}${paginationParams}`);

  return (
    <div data-cy="officers-page" className="relative">
      <LoadingOverlay isLoading={isFetching && !!result} />
      <Table
        dataCy="officers-table"
        columns={OFFICERS_TABLE_COLUMNS}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        sortingType={sortingType}
        isLoading={isLoading}
        isTableEmpty={!result?.result.items.length}
      >
        {result?.result.items.map((item) => <OfficersRow {...item} key={uuidv4()} />)}
      </Table>
      <Pagination
        totalCount={result?.result.totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Officers;
