import { CONTACTS_COLUMNS, INVOICES_COLUMNS } from 'interfaces/integrations/xero.interface';
import { TabItem, TableColumn } from 'interfaces/shared.interface';
import Contacts from 'page-components/integrations/xero/contacts/Contacts';
import Invoices from 'page-components/integrations/xero/invoices/Invoices';

export const XERO_TABS: TabItem[] = [
  {
    dataCy: 'invoices-tab-button',
    id: 'invoices-tab',
    title: 'XeroInvoices',
    component: Invoices,
  },
  {
    dataCy: 'contacts-tab-button',
    id: 'contacts-tab',
    title: 'XeroContacts',
    component: Contacts,
  },
];

export const INVOICES_COLUMNS_TITLES = {
  [INVOICES_COLUMNS.DUE_DATE]: 'DueDate',
  [INVOICES_COLUMNS.REFERENCE]: 'Reference',
  [INVOICES_COLUMNS.INVOICE_NUMBER]: 'InvoiceNumber',
  [INVOICES_COLUMNS.AMOUNT]: 'Amount',
  [INVOICES_COLUMNS.TOTAL]: 'Total',
};

export const INVOICES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: INVOICES_COLUMNS.DUE_DATE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.DUE_DATE],
    width: 'w-2/12',
  },
  {
    columnId: INVOICES_COLUMNS.REFERENCE,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.REFERENCE],
    width: 'w-2/12',
  },
  {
    columnId: INVOICES_COLUMNS.INVOICE_NUMBER,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.INVOICE_NUMBER],
    width: 'w-2/12',
  },
  {
    columnId: INVOICES_COLUMNS.AMOUNT,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.AMOUNT],
    width: 'w-3/12',
    className: 'm-auto',
  },
  {
    columnId: INVOICES_COLUMNS.TOTAL,
    title: INVOICES_COLUMNS_TITLES[INVOICES_COLUMNS.TOTAL],
    width: 'w-3/12',
    className: 'm-auto',
  },
];

export const CONTACTS_COLUMNS_TITLES = {
  [CONTACTS_COLUMNS.NAME]: 'Name',
  [CONTACTS_COLUMNS.CONTACT_NAME]: 'ContactName',
  [CONTACTS_COLUMNS.EMAIL_ADDRESS]: 'EmailAddress',
};

export const CONTACTS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: CONTACTS_COLUMNS.NAME,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.NAME],
    width: 'w-4/12',
  },
  {
    columnId: CONTACTS_COLUMNS.CONTACT_NAME,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.CONTACT_NAME],
    width: 'w-4/12',
  },
  {
    columnId: CONTACTS_COLUMNS.EMAIL_ADDRESS,
    title: CONTACTS_COLUMNS_TITLES[CONTACTS_COLUMNS.EMAIL_ADDRESS],
    width: 'w-4/12',
  },
];
