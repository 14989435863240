import { useTranslation } from 'react-i18next';

const Overview = () => {
  const { t } = useTranslation();

  return <div>{t('OverviewPage')}</div>;
  // TODO need to change this Overview page localization
};

export default Overview;
