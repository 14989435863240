import { useEffect } from 'react';

import { startOfDay, endOfDay, addDays } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLazyGetAuditLogsQuery, useLazyGetAuditLogsToExcelQuery } from 'apis/audit-logs.api';
import {
  OPERATION_LOGS_FILTER_FORM_FIELDS,
  OPERATION_LOGS_FILTERS_FIELDS_DEFAULT_VALUES,
  OPERATION_LOGS_TABLE_COLUMNS,
} from 'constants/audit-logs';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { OperationLogsFilterFormValues } from 'interfaces/audit-logs.interface';
import { CommonError } from 'interfaces/shared.interface';
import {
  Button,
  FilterForm,
  LoadingOverlay,
  Pagination,
  Table,
  FilterFormContentWithoutPermissions,
  ErrorModal,
} from 'shared-components';
import { downloadDocument } from 'utils/global';

import OperationLogsRow from './OperationLogsRow';

const DEFAULT_FILTERS = {
  StartDate: new Date(startOfDay(new Date())).toISOString(),
  EndDate: new Date(endOfDay(addDays(new Date(), 1))).toISOString(),
};

const OperationLogs = () => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const operationLogsFiltersMethods = useForm<OperationLogsFilterFormValues>({
    defaultValues: OPERATION_LOGS_FILTERS_FIELDS_DEFAULT_VALUES,
  });
  const { filterParams, applyFilters, isSameFilters } = useFilters(DEFAULT_FILTERS);
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const [getAuditLogs, { data, isLoading, isFetching, error: getAuditLogsError }] = useLazyGetAuditLogsQuery();

  const [getAuditLogsToExcel, { isLoading: isAuditLogsToExcelLoading, error: AuditLogsToExcelError }] =
    useLazyGetAuditLogsToExcelQuery();

  const errorGetAuditLogsError = (getAuditLogsError as CommonError)?.data || {};

  const errorAuditLogsToExcelData = (AuditLogsToExcelError as CommonError)?.data || {};

  const errorData = errorGetAuditLogsError || errorAuditLogsToExcelData;

  const { result } = data || {};

  const onSubmitFilters = ({ StartDate, EndDate, HasException, filter, ...data }: OperationLogsFilterFormValues) => {
    const newFiltersToApply = {
      ...data,
      UserEmail: filter,
      StartDate: StartDate ? new Date(startOfDay(new Date(StartDate))).toISOString() : '',
      EndDate: EndDate ? new Date(endOfDay(new Date(EndDate))).toISOString() : '',
      HasException: HasException !== null ? (HasException?.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      getAuditLogs(`${filterParams}${sortingParams}${paginationParams}`);
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    operationLogsFiltersMethods.reset(OPERATION_LOGS_FILTERS_FIELDS_DEFAULT_VALUES);
    onSubmitFilters(OPERATION_LOGS_FILTERS_FIELDS_DEFAULT_VALUES);
  };

  useEffect(() => {
    getAuditLogs(`${filterParams}${sortingParams}${paginationParams}`);
  }, [filterParams, paginationParams, sortingParams, getAuditLogs]);

  const handleDownloadDocument = async () => {
    try {
      const currentFilters = `${filterParams}${sortingParams}${paginationParams}`;

      const { result: newData } = await getAuditLogsToExcel(currentFilters).unwrap();
      if (newData) {
        downloadDocument(newData.context, newData.fileType, newData.fileName);
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="operation-logs-tab" className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />
        <div>
          <FormProvider {...operationLogsFiltersMethods}>
            <form onSubmit={operationLogsFiltersMethods.handleSubmit(onSubmitFilters)}>
              <FilterForm
                dataCy="operation-logs-filtering"
                placeHolder="SearchWithThreeDotUsernameAuditLog"
                handleClearFields={handleClearFields}
                rightTopActionButton={
                  <Button
                    isLoading={isAuditLogsToExcelLoading}
                    data-cy="download-operation-log-button"
                    className="h-10 ml-3.5"
                    type="button"
                    onClick={handleDownloadDocument}
                  >
                    {t('Download')}
                  </Button>
                }
              >
                <div className="w-full xl:w-[67%]">
                  <FilterFormContentWithoutPermissions
                    fields={OPERATION_LOGS_FILTER_FORM_FIELDS}
                    className="mx-10 grid grid-cols-12 md:grid-cols-6 md:grid-rows-3 gap-4"
                  />
                </div>
              </FilterForm>
            </form>
          </FormProvider>
        </div>

        <Table
          dataCy="operation-logs-table"
          columns={OPERATION_LOGS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!result?.items.length}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {result?.items.map((item) => <OperationLogsRow key={item.id} logData={item} />)}
        </Table>

        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default OperationLogs;
