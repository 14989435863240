import Highcharts from 'highcharts';

import { PieChartConfig, PieChartData } from 'interfaces/pie-chart.interface';

export const MULTI_VALUE_STYLES = 'ml-2 px-2 py-1 border border-gray-300 rounded-md !max-h-8 custom-input text-sm';

export const getSharedStackedAreaChartOptions = ({
  categories,
  series,
  showGrid,
  height = 200,
}: {
  categories: string[];
  series: { name: string; data: number[]; color: string; showInLegend?: boolean }[];
  showGrid?: boolean;
  height?: number;
}) => ({
  chart: {
    type: 'area',
    backgroundColor: 'transparent',
    height,
    spacingTop: 10,
    spacingBottom: 10,
    spacingLeft: 60,
    spacingRight: 10,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  xAxis: {
    min: 0,
    categories,
    labels: {
      autoRotation: [-45],
      style: {
        whiteSpace: 'nowrap',
        color: '#FFF',
        fontSize: '0.75rem',
      },
    },
    lineWidth: 0,
    gridLineWidth: showGrid ? 1 : 0,
  },
  yAxis: {
    title: {
      text: '',
    },
    stackLabels: {
      enabled: false,
    },
    endOnTick: false,
    labels: {
      style: {
        color: '#FFF',
        fontSize: '0.75rem',
      },
      formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
        return this.value;
      },
      x: showGrid ? 0 : 15,
    },
    gridLineWidth: showGrid ? 1 : 0,
  },
  tooltip: {
    shared: true,
    useHTML: true,
    shape: 'shape',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    headerFormat: '',
    padding: 10,
    style: {
      color: '#FFF',
      borderRadius: '0.25rem',
    },
    pointFormat: `<div style="display: flex; align-items:center;height: 1.25rem;">
                      <div style="backgroundColor: {series.color}; width: 0.7rem; height: 0.7rem; margin-right: 0.5rem; border-radius: 20%;" ></div>
                       <p>{series.name}: {point.y} </p>
                    </div>`,
  },
  plotOptions: {
    area: {
      stacking: 'normal',
      marker: {
        enabled: false,
        symbol: 'circle',
        lineColor: null,
        radius: 2,
        hover: {
          enabled: true,
          lineWidth: 0,
        },
      },
    },
  },
  series,
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 200,
        },
        chartOptions: {
          chart: {
            spacingBottom: 15,
            spacingTop: 15,
            spacingLeft: 60,
            spacingRight: 10,
          },
          series: [
            {
              pointStart: 0,
            },
          ],
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
          },
        },
      },
    ],
  },
});

export const getSharedSimplifiedLineChartOptions = (data: number[], color?: string) => ({
  chart: {
    type: 'line',
    backgroundColor: 'transparent',
    borderWidth: 0,
    plotBorderWidth: 0,
    height: 40,
  },
  title: {
    text: null,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      lineWidth: 2,
      color,
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      data,
    },
  ],
});

export const getSharedSimplifiedColumnChartOptions = (data: number[], color?: string) => ({
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    borderWidth: 0,
    plotBorderWidth: 0,
    height: 225,
  },
  title: {
    text: null,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  tooltip: {
    shared: true,
    useHTML: true,
    shape: 'shape',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    headerFormat: '',
    padding: 10,
    style: {
      color: '#FFF',
      borderRadius: '0.25rem',
    },
    pointFormat: `<div style="display: flex; flex-direction: column; align-items:center;height: 1.5rem;">
                       <p>Day {add point.x 1}</p>
                       <p> {point.y}</p>
                    </div>`,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      enableMouseTracking: true,
      marker: {
        enabled: false,
      },
    },
    column: {
      color,
      borderWidth: 0,
      borderRadius: 6,
      pointPadding: 0.1,
      groupPadding: 0.1,
      states: {
        hover: {
          brightness: -0.1,
        },
      },
    },
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 768,
        },
        chartOptions: {
          plotOptions: {
            column: {
              pointPadding: 0.2,
            },
          },
        },
      },
    ],
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      data,
    },
  ],
});

export const getPieChartOptions = (
  data: PieChartData[],
  { colors, donutRadius, withTooltip, withLegend, height }: PieChartConfig,
  isDarkmode?: boolean
) => ({
  chart: {
    type: 'pie',
    backgroundColor: 'transparent',
    plotBorderWidth: 0,
    height: height ?? 270,
  },
  title: {
    text: null,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    visible: false,
  },
  legend: {
    enabled: withLegend,
    title: {
      text: 'Legend',
      style: {
        color: isDarkmode ? '#fff' : '#495057',
        fontSize: '0.875rem',
        fontFamily: 'Inter, sans-serif',
      },
    },
    align: 'right',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: 10,
    verticalAlign: 'middle',
    layout: 'vertical',
    itemStyle: {
      color: isDarkmode ? '#fff' : '#495057',
      fontFamily: 'Inter, sans-serif',
      fontSize: '0.75rem',
    },
    symbolRadius: 2,
    events: {
      itemClick: () => false,
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      showInLegend: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      borderWidth: 0,
      borderRadius: 0,
      colors,
      events: {
        click: () => false,
      },
    },
  },
  tooltip: {
    enabled: withTooltip,
    shared: true,
    useHTML: true,
    shape: 'shape',
    backgroundColor: 'rgba(0,0,0, 0.5)',
    headerFormat: '',
    padding: 10,
    style: {
      color: '#FFF',
      borderRadius: '0.25rem',
    },
    pointFormat: `<div style="display: flex; flex-direction: column; align-items:center;">
                       <p>{point.name}</p>
                       <p>{point.y}</p>
                    </div>`,
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      data: data.map(({ y, label }) => ({
        y,
        name: label,
      })),
      innerSize: donutRadius ?? '75%',
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          brightness: -0.1,
          halo: {
            size: 0,
          },
        },
      },
    },
  ],
});
