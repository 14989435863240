import { ActivateEmailPayload, ActivateResponse } from 'interfaces/activate-email.interface';
import { AuthResponse, RegisterResponse, SignInPayload } from 'interfaces/auth.interface';
import { ConfirmEmailPayload } from 'interfaces/confirm-email.interface';
import { ForgotPasswordPayload, ForgotResponse } from 'interfaces/forgot-password.interface';
import { ResetPasswordPayload, ResetResponse } from 'interfaces/reset-password.interface';
import { CommonResponse } from 'interfaces/shared.interface';
import { SignUpPayload } from 'interfaces/sign-up.interface';
import { SendAuthCodePayload, TwoFactorAuthResponse } from 'interfaces/two-factor-authentication.interface';

import { coreSplitApis } from './core.api';

export const auth = coreSplitApis.injectEndpoints({
  endpoints: (build) => ({
    authenticate: build.mutation<AuthResponse, SignInPayload>({
      query: (credentials) => ({ url: `api/TokenAuth/Authenticate`, method: 'POST', data: credentials }),
    }),
    registration: build.mutation<RegisterResponse, SignUpPayload>({
      query: (registerData) => ({ url: `TenantRegistration/RegisterTenant`, method: 'POST', data: registerData }),
    }),
    emailActivate: build.mutation<ActivateResponse, ActivateEmailPayload>({
      query: (emailAddress) => ({ url: `Account/SendEmailActivationLink`, method: 'POST', data: emailAddress }),
    }),
    forgotPassword: build.mutation<ForgotResponse, ForgotPasswordPayload>({
      query: (emailAddress) => ({ url: `Account/SendResetPasswordEmail`, method: 'POST', data: emailAddress }),
    }),
    resetPassword: build.mutation<ResetResponse, ResetPasswordPayload>({
      query: (resetData) => ({ url: `Account/ResetPassword`, method: 'POST', data: resetData }),
    }),
    confirmEmail: build.mutation<ActivateResponse, ConfirmEmailPayload>({
      query: (payload) => ({ url: `Account/ActivateEmail`, method: 'POST', data: payload }),
    }),
    twoFactorAuthenticator: build.query<TwoFactorAuthResponse, void>({
      query: () => ({
        url: `TwoFactorAuthentication/GetDataForSetUpApplication?qrCodeWidth=300&qrCodeHeight=300`,
        method: 'GET',
      }),
    }),
    sendAuthCode: build.mutation<CommonResponse, SendAuthCodePayload>({
      query: (code) => ({ url: `TwoFactorAuthentication/SendCode`, method: 'POST', data: code }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAuthenticateMutation,
  useRegistrationMutation,
  useEmailActivateMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useConfirmEmailMutation,
  useTwoFactorAuthenticatorQuery,
  useSendAuthCodeMutation,
} = auth;
