import { ORDERS_COLUMNS, PAY_FORM_FIELDS } from 'interfaces/integrations/paypal.interface';
import { FieldTypes, FormItem, TabItem, TableColumn } from 'interfaces/shared.interface';
import Orders from 'page-components/integrations/paypal/orders/Orders';
import { InputNumberFormat } from 'shared-components';

export const PAYPAL_TAB: TabItem[] = [
  {
    dataCy: 'orders-tab-button',
    id: 'orders-tab',
    title: 'Orders',
    component: Orders,
  },
];

export const ORDERS_COLUMNS_TITLES = {
  [ORDERS_COLUMNS.BUYER_NAME]: 'BuyerName',
  [ORDERS_COLUMNS.BUYER_EMAIL]: 'BuyerEmail',
  [ORDERS_COLUMNS.AMOUNT]: 'Amount',
  [ORDERS_COLUMNS.DATE]: 'Date',
};

export const ORDERS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: ORDERS_COLUMNS.BUYER_NAME,
    title: ORDERS_COLUMNS_TITLES[ORDERS_COLUMNS.BUYER_NAME],
    width: 'w-2/12',
  },
  {
    columnId: ORDERS_COLUMNS.BUYER_EMAIL,
    title: ORDERS_COLUMNS_TITLES[ORDERS_COLUMNS.BUYER_EMAIL],
    width: 'w-4/12',
  },
  {
    columnId: ORDERS_COLUMNS.AMOUNT,
    title: ORDERS_COLUMNS_TITLES[ORDERS_COLUMNS.AMOUNT],
    width: 'w-3/12',
    sorting: true,
  },
  {
    columnId: ORDERS_COLUMNS.DATE,
    title: ORDERS_COLUMNS_TITLES[ORDERS_COLUMNS.DATE],
    width: 'w-3/12',
    sorting: true,
  },
];

export const getPaymentPayPalFormFields: FormItem[] = [
  {
    name: PAY_FORM_FIELDS.AMOUNT,
    label: 'Amount',
    type: FieldTypes.NUMBER,
    required: true,
    inputClassName: 'w-full',
    dataCy: 'payment-amount-field',
    className: 'mb-4',
    component: InputNumberFormat,
    isShownArrowButtons: true,
    validation: {
      required: 'ThisFieldIsRequired',
      min: {
        value: 1,
        message: 'InputMinNumber',
      },
    },
    maxLength: 11,
    minValue: '1',
    decimalSeparator: '.',
    fixedDecimalLength: 2,
    groupSeparator: ',',
  },
];

export const SEARCH_ORDERS_PLACEHOLDER = 'SearchByBuyerNameOrEmail';
