import { FORGOT_PASSWORD_FROM_FIELDS } from 'interfaces/forgot-password.interface';
import { FieldTypes, FormItem } from 'interfaces/shared.interface';
import { Input } from 'shared-components';

import { EMAIL_PATTERN } from './global';

export const FORGOT_PASSWORD_PAGE_TITLE = 'ForgotPassword';

export const FORGOT_PASSWORD_PAGE_BACK_BUTTON = 'Back';

export const FORGOT_PASSWORD_PAGE_SUBMIT_BUTTON = 'Submit';

export const FORGOT_PASSWORD_FORM_INPUTS: FormItem[] = [
  {
    name: FORGOT_PASSWORD_FROM_FIELDS.userEmail,
    type: FieldTypes.TEXT,
    inputClassName: 'w-full',
    required: true,
    className: 'mb-4 mt-7',
    requiredPlaceholder: true,
    placeholder: `EmailAddress`,
    component: Input,
    validation: {
      required: 'ThisFieldIsRequired',
      pattern: { message: 'InvalidEmail', value: EMAIL_PATTERN },
    },
    dataCy: 'email-field',
  },
];

export const FORGOT_PASSWORD_SUCCESS_MESSAGE = 'MailSent';
export const FORGOT_PASSWORD_SUCCESS_MESSAGE_DESCRIPTION = 'PasswordResetMailSentMessage';
