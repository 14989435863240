import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

const INSTRUCTIONS_CLASSES = 'text-darkGray text-sm my-2 dark:text-white';

interface Props {
  className: string;
}

const TemplateInstructions = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'w-full shadow-md border border-lightGray3 rounded-md p-4 h-fit dark:border-none dark:bg-darkBlue4',
        className
      )}
    >
      <div className="font-semibold text-2xl text-darkGray  dark:text-white">{t('Instructions')}</div>
      <ol>
        <li className={INSTRUCTIONS_CLASSES}>{t('EmailVariableInstructions1')}</li>
        <li className={INSTRUCTIONS_CLASSES}>{t('EmailVariableInstructions2')}</li>
        <li className={INSTRUCTIONS_CLASSES}>{t('EmailVariableInstructions3')}</li>
        <li className={INSTRUCTIONS_CLASSES}>{t('EmailVariableInstructions4')}</li>
      </ol>
    </div>
  );
};

export default TemplateInstructions;
