import { Currency } from 'constants/global';

import { BaseFilterValues, CommonResponse, OptionType } from '../shared.interface';

export interface PaymentTypes {
  id: number;
  name: string;
}
export interface PaymentTypesResponse extends CommonResponse {
  result: PaymentTypes[];
}

export interface Repayments {
  createdDate: string;
  currency: Currency;
  id: number;
  paymentType: string;
  referenceId: string;
  status: string;
  totalAmount: number;
}
export interface RepaymentsResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: Repayments[];
  };
}

export interface CalculateRepaymentPayload {
  amount: number;
  loanLengthMonths: number;
  paymentType: number;
}

export enum GOCARDLESS_COLUMNS {
  REPAYMENT_REF = 'repaymentRef',
  PAYMENT_TYPE = 'paymentType',
  AMOUNT = 'amount',
  STATUS = 'status',
  CREATION_TIME = 'creationTime',
  ACTION = 'action',
}

export enum GOCARDLESS_FILTERS_FORM_FIELD_NAMES {
  STATUS = 'status',
  PAYMENT_TYPE = 'paymentType',
}

export interface GoCardlessFilterFormValues extends BaseFilterValues {
  [GOCARDLESS_FILTERS_FORM_FIELD_NAMES.STATUS]: OptionType;
  [GOCARDLESS_FILTERS_FORM_FIELD_NAMES.PAYMENT_TYPE]: OptionType;
}

export interface RepaymentDetailsResponse extends CommonResponse {
  result: RepaymentDetails;
}

export interface RepaymentDetails extends Repayments {
  schedule: ScheduleDetails;
  mandate: MandateDetails;
  payments: PaymentsDetails[];
}

export interface ScheduleDetails {
  createdDate: string;
  externalId: string;
  id: number;
  status: string;
}

export interface MandateDetails {
  createdDate: string;
  id: number;
  mandate: string;
  status: string;
}

export interface PaymentsDetails {
  amount: number;
  chargeDate: string;
  createdDate: string;
  currency: Currency;
  id: number;
  status: string;
}

export enum PAYMENTS_COLUMNS {
  ORDER = 'order',
  AMOUNT = 'amount',
  STATUS = 'status',
  CREATED_DATE = 'createdDate',
  CHARGE_DATE = 'chargeDate',
}

export enum PAY_FORM_FIELDS {
  AMOUNT = 'amount',
  PAYMENT_TYPE = 'paymentType',
  LOAN_LENGTH_MONTHS = 'loanLengthMonths',
}

export interface SavePayPayload {
  amount: number;
  paymentType: number;
  loanLengthMonths: number;
  totalAmount?: number;
  firstPaymentAmount?: number;
  recurringPaymentAmount?: number;
  repaymentTermLength?: number;
}

export enum PAY_SUMMARY_FORM_FIELDS {
  FIRST_PAYMENT_AMOUNT = 'firstPaymentAmount',
  RECURRING_PAYMENT_AMOUNT = 'recurringPaymentAmount',
  REPAYMENT_TERM_LENGTH = 'repaymentTermLength',
  TOTAL_AMOUNT = 'totalAmount',
}

export interface CalculateRepaymentResponse extends CommonResponse {
  result: CalculateRepaymentResponseData;
}

export interface CalculateRepaymentResponseData {
  firstPaymentAmount: number;
  recurringPaymentAmount: number;
  totalAmount: number;
  repaymentTermLength: number;
}

export interface CreateRepaymentResponse extends CommonResponse {
  result: string;
}
