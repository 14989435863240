import React from 'react';

import Skeleton from 'shared-components/skeleton/Skeleton';

const TopStatsSkeleton = () => {
  return (
    <div data-cy="top-stats-skeleton" className="grid grid-cols-4 gap-2 h-48 col-span-full">
      {Array.from(Array(4).keys()).map((key) => (
        <Skeleton key={key} />
      ))}
    </div>
  );
};

export default TopStatsSkeleton;
