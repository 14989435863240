import { BaseFilterValues, CommonResponse, OptionsTypeValue, OptionType } from './shared.interface';
export enum OPERATION_LOGS_COLUMNS {
  STATE = 'state',
  USER_EMAIL = 'userEmail',
  SERVICE = 'service',
  EXECUTION_DURATION = 'executionDuration',
  BROWSER = 'browser',
  EXECUTION_TIME = 'executionTime',
  ACTIONS = 'actions',
}

export enum SHARED_AUDIT_LOGS_FILTERS_FIELD_NAMES {
  START_DATE = 'StartDate',
  END_DATE = 'EndDate',
}

export enum OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES {
  SEARCH_FIELD = 'filter',
  BROWSER_INFO = 'BrowserInfo',
  SERVICE_NAME = 'ServiceName',
  METHOD_NAME = 'MethodName',
  MIN_DURATION = 'MinExecutionDuration',
  MAX_DURATION = 'MaxExecutionDuration',
  HAS_EXCEPTION = 'HasException',
}

export interface AuditLog {
  id: string;
  browserInfo: string;
  clientIpAddress: string;
  clientName: string | null;
  customData: string | null;
  exception: string | null;
  executionDuration: number;
  executionTime: string;
  impersonatorEmail: string | null;
  impersonatorFullName: string | null;
  impersonatorTenantId: string | null;
  impersonatorUserId: string | null;
  methodName: string;
  parameters: string;
  returnValue: string | null;
  serviceName: string;
  tenantId: number | null;
  userId: number;
  userEmail: string;
}

export interface AuditLogsResult {
  items: AuditLog[];
  totalCount: number;
}

export interface AuditLogsResponse extends CommonResponse {
  result: AuditLogsResult;
}

export interface OperationLogsFilterFormValues extends BaseFilterValues {
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.SEARCH_FIELD]: string;
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.BROWSER_INFO]: string;
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.SERVICE_NAME]: string;
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.METHOD_NAME]: string;
  [SHARED_AUDIT_LOGS_FILTERS_FIELD_NAMES.START_DATE]: string;
  [SHARED_AUDIT_LOGS_FILTERS_FIELD_NAMES.END_DATE]: string;
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.MIN_DURATION]: string;
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.MAX_DURATION]: string;
  [OPERATION_LOGS_FILTERS_FORM_FIELD_NAMES.HAS_EXCEPTION]: OptionType | null;
}

export interface EntityHistoryObjectResponse extends CommonResponse {
  result: { name: string; value: string }[];
}

export interface ChangeLog {
  tenantId: number | null;
  userId: number;
  userEmail: string;
  changeTime: string;
  entityId: string;
  entityTypeFullName: string;
  changeTypeName: string;
  entityChangeSetId: number;
  clientIpAddress: string;
  clientName: string | null;
  browserInfo: string;
  impersonatorTenantId: number | null;
  impersonatorUserId: number | null;
  impersonatorEmail: string | null;
  impersonatorFullName: string | null;
  extensionData: string | null;
  reason: string;
  id: number;
}

export interface ChangeLogsResult {
  items: ChangeLog[];
  totalCount: number;
}

export interface ChangeLogsResponse extends CommonResponse {
  result: ChangeLogsResult;
}

export interface EntityPropertyChange {
  entityChangeId: number;
  newValue: string;
  originalValue: string;
  propertyName: string;
  propertyTypeFullName: string;
  tenantId: number | null;
  id: number;
}
export interface EntityPropertyChangeResponse extends CommonResponse {
  result: EntityPropertyChange[];
}

export enum CHANGE_LOGS_FILTERS_FORM_FIELD_NAMES {
  ENTITY_TYPE_FULL_NAME = 'EntityTypeFullName',
}

export enum CHANGE_LOGS_COLUMNS {
  ACTION = 'action',
  OBJECT = 'object',
  USER_EMAIL = 'userEmail',
  TIME = 'changeTime',
  ACTIONS = 'actions',
}

export interface ChangeLogsFilterFormValues extends BaseFilterValues {
  [CHANGE_LOGS_FILTERS_FORM_FIELD_NAMES.ENTITY_TYPE_FULL_NAME]: OptionsTypeValue | null;
  [SHARED_AUDIT_LOGS_FILTERS_FIELD_NAMES.START_DATE]: string;
  [SHARED_AUDIT_LOGS_FILTERS_FIELD_NAMES.END_DATE]: string;
}

export enum CHANGE_LOG_DETAILS_COLUMNS {
  ENTITY_CHANGE_ID = 'entityChangeId',
  PROPERTY_NAME = 'propertyName',
  PROPERTY_TYPE = 'propertyTypeFullName',
  ORIGINAL_VALUE = 'originalValue',
  NEW_VALUE = 'newValue',
}

export interface AuditLogsToExcelResult {
  context: string;
  fileName: string;
  fileType: string;
}

export interface AuditLogsToExcelResponse extends CommonResponse {
  result: AuditLogsToExcelResult;
}
