import { useFormContext } from 'react-hook-form';

import { CREATE_ROLE_FORM_FIELDS } from 'constants/roles';
import { FormContent } from 'shared-components';
import ColoredBlock from 'shared-components/ColoredBlock';

interface Props {
  editingId: number | null;
  isLoading?: boolean;
}

const RoleNameTabContent = ({ editingId, isLoading }: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <FormContent fields={CREATE_ROLE_FORM_FIELDS} isLoading={isLoading} register={register} errors={errors} />
      {editingId && <ColoredBlock className="mt-5" text="Note_RefreshPageForPermissionChanges" />}
    </div>
  );
};

export default RoleNameTabContent;
