import { useMemo } from 'react';

import { useGetCurrentLoginInformationsQuery } from 'apis/user.api';
import { UserTypes } from 'constants/user';

const useUserRole = () => {
  const { data: currentLoginInfoData } = useGetCurrentLoginInformationsQuery();

  const userRole = useMemo(
    () => (currentLoginInfoData?.tenant ? UserTypes.TENANT : UserTypes.ADMIN),
    [currentLoginInfoData]
  );

  return {
    userRole,
    isAdmin: userRole === UserTypes.ADMIN,
    isTenant: userRole === UserTypes.TENANT,
  };
};

export default useUserRole;
