import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GetCustomers } from 'interfaces/integrations/quickBooks.interface';
import { DropdownMenu, TableData, TableRow } from 'shared-components';
import { getCustomerDetailsLink } from 'utils/url';

type Props = {
  customersData: GetCustomers;
};

const CustomersRow = ({ customersData }: Props) => {
  const { id, name, emailAddress, billingAddress, isActive } = customersData;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuItemsAction = [
    {
      dataCy: 'quickbooks-menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        navigate(getCustomerDetailsLink(id));
      },
    },
  ];

  return (
    <>
      <TableRow dataCy="customers-table-row">
        <TableData dataCy="customers-name">{name}</TableData>
        <TableData dataCy="customers-email-address">{emailAddress}</TableData>
        <TableData dataCy="customers-billing-address">{billingAddress}</TableData>
        <TableData dataCy="customers-status">{t(isActive ? 'Active' : 'NotActive')}</TableData>
        <TableData>
          <DropdownMenu dataCy="invoice-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default CustomersRow;
