import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import {
  useGetOrganizationUnitsQuery,
  useGetOrganizationUnitsRolesQuery,
  useGetOrganizationUnitsUsersQuery,
} from 'apis/organization-units.api';
import { ORGANIZATION_UNITS_TABS } from 'constants/organization-units';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { SelectedUnitInfo } from 'interfaces/organization-units.interface';
import { Skeleton } from 'shared-components';
import Tabs from 'shared-components/Tabs';

import AddMemberModal from './members-tab/AddMemberModal';
import MembersTab from './members-tab/MembersTab';
import AddRoleModal from './roles-tab/AddRoleModal';
import RolesTab from './roles-tab/RolesTab';

interface Props {
  dataCy?: string;
  className: string;
  selectedUnit: SelectedUnitInfo | null;
}

const getTabsComponents = (
  selectedUnit: SelectedUnitInfo | null,
  handleOpenAddMemberModal: () => void,
  handleOpenAddRoleModal: () => void
) => [
  {
    component: () => (
      <MembersTab handleOpenAddMemberModal={handleOpenAddMemberModal} selectedUnit={selectedUnit} key="0" />
    ),
  },
  {
    component: () => <RolesTab handleOpenAddRoleModal={handleOpenAddRoleModal} selectedUnit={selectedUnit} key="1" />,
  },
];

const OrganizationUnitsTableBlock = ({ dataCy, className, selectedUnit }: Props) => {
  const { sortingParams } = useTableSorting();
  const { paginationParams } = usePagination();
  const { filterParams } = useFilters();
  const { data: treeData, refetch, isLoading } = useGetOrganizationUnitsQuery();

  useGetOrganizationUnitsUsersQuery(
    {
      userId: selectedUnit?.id,
      params: `${filterParams}${sortingParams}${paginationParams}`,
    },
    {
      skip: !selectedUnit?.id,
    }
  );

  useGetOrganizationUnitsRolesQuery(
    {
      roleId: selectedUnit?.id,
      params: `${filterParams}${sortingParams}${paginationParams}`,
    },
    {
      skip: !selectedUnit?.id,
    }
  );

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isOpenAddMemberModal, handleOpenAddMemberModal, handleCloseAddMemberModal] = useOpen();
  const [isOpenAddRoleModal, handleOpenAddRoleModal, handleCloseAddRoleModal] = useOpen();

  const tabsComponents = getTabsComponents(selectedUnit, handleOpenAddMemberModal, handleOpenAddRoleModal);

  return (
    <>
      <AddMemberModal
        handleClose={handleCloseAddMemberModal}
        isOpen={isOpenAddMemberModal}
        refetchListRoot={refetch}
        data-cy="add-member-modal"
        selectedUnit={selectedUnit}
      />

      <AddRoleModal
        handleClose={handleCloseAddRoleModal}
        isOpen={isOpenAddRoleModal}
        refetchListRoot={refetch}
        data-cy="add-role-modal"
        selectedUnit={selectedUnit}
      />

      <div
        data-cy={dataCy}
        className={twMerge(
          'w-full bg-white dark:bg-darkBlue p-6 rounded-b-md lg:rounded-l-none lg:rounded-r-md shadow-centerLight relative',
          className
        )}
      >
        {isLoading && <Skeleton className="h-full w-full" />}
        {!isLoading && treeData?.result?.items.length ? (
          <>
            <p className="text-2xl mb-3">{selectedUnit?.displayName}</p>
            <Tabs
              tabs={ORGANIZATION_UNITS_TABS.map((tab) => ({
                ...tab,
                component: tabsComponents[activeTabIndex].component,
              }))}
              onActiveTabChange={setActiveTabIndex}
            />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default OrganizationUnitsTableBlock;
