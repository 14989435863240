import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUploadFileMutation } from 'apis/support-tickets.api';
import { BASE_TEXT_AREA_FIELDS } from 'constants/common';
import useFileUpload from 'hooks/useFileUpload';
import { useOpen } from 'hooks/useOpen';
import { BaseTextAreaValue } from 'interfaces/shared.interface';
import { SendMessageContent } from 'interfaces/support-tickets.interfaces';
import { Button, ErrorModal, TextArea } from 'shared-components';
import FileUploaderButton from 'shared-components/FileUploaderButton';

interface ChatMessageFormProps {
  sendMessage: (payload: SendMessageContent) => void;
  isSendingMessage?: boolean;
}

const ChatMessageForm = ({ sendMessage, isSendingMessage }: ChatMessageFormProps) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { handleFileUpload, error } = useFileUpload();
  const chatFormMethods = useForm<BaseTextAreaValue>();

  const [uploadFile] = useUploadFileMutation();

  const handleUploadFile = async (files: FileList) => {
    const uploadResult = await handleFileUpload(files);

    if (!uploadResult) {
      handleOpenErrorModal();
      return;
    }

    const { fileBase64String, fileName, fileType } = uploadResult;

    try {
      const { result } = await uploadFile({
        fileBase64String,
        fileName,
      }).unwrap();

      const payload: SendMessageContent = {
        message: null,
        messageAttachmentId: result.uniqueFileId,
        messageAttachmentName: fileName,
        messageAttachmentType: fileType,
      };

      sendMessage(payload);
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const onSubmit = (data: BaseTextAreaValue) => {
    const payload = {
      message: data[BASE_TEXT_AREA_FIELDS.TEXT_AREA_FIELD],
      messageAttachmentId: null,
      messageAttachmentName: null,
      messageAttachmentType: null,
    };

    sendMessage(payload);
    chatFormMethods.reset();
  };

  return (
    <>
      <ErrorModal errorMessage={error} isOpen={isErrorModalOpen} handleClose={handleCloseErrorModal} />
      <FormProvider {...chatFormMethods}>
        <form onSubmit={chatFormMethods.handleSubmit(onSubmit)} className="px-4 py-2.5 dark:bg-darkBlue10">
          <TextArea
            dataCy="chat-input"
            textAreaClassName="w-full text-red-500"
            placeholder={t('TypeAMessageHere')}
            numberOfCharacters={1200}
            {...chatFormMethods.register(BASE_TEXT_AREA_FIELDS.TEXT_AREA_FIELD)}
          />
          <div className="flex justify-between mt-10">
            <FileUploaderButton
              buttonText="AddAttachment"
              handleUpload={handleUploadFile}
              icon={<FontAwesomeIcon className="px-1.5" icon={faPaperclip} />}
            />
            <Button dataCy="chat-send-button" isLoading={isSendingMessage}>
              {t('Send')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default ChatMessageForm;
