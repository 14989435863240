import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { EntityPropertyChange } from 'interfaces/audit-logs.interface';
import { TableData, TableRow } from 'shared-components';
import { getFormattedDate, isDateTime } from 'utils/dates';

type Props = EntityPropertyChange;

const TABLE_DATA_STYLES = 'whitespace-normal break-all leading-normal border border-lightGray8 font-normal';
const ChangeLogsDetailsRow = ({
  entityChangeId,
  originalValue,
  newValue,
  propertyTypeFullName,
  propertyName,
}: Props) => {
  const safeFormatDate = (value: string, formatType: string) => {
    try {
      return getFormattedDate(value, formatType);
    } catch (error) {
      return value;
    }
  };

  const formatValue = (value: string | null, formatType: string) => {
    if (!value) {
      return '';
    }

    const cleanValue = value ? value.replace(/(^"|"$)/g, '') : '';

    return isDateTime(cleanValue) ? safeFormatDate(cleanValue, formatType) : cleanValue;
  };

  return (
    <TableRow>
      <TableData dataCy="entity-id" className={TABLE_DATA_STYLES}>
        {entityChangeId}
      </TableData>
      <TableData dataCy="property-name" className={TABLE_DATA_STYLES}>
        {propertyName}
      </TableData>
      <TableData dataCy="prop-type-full-name" className={TABLE_DATA_STYLES}>
        {propertyTypeFullName}
      </TableData>
      <TableData dataCy="original-value" className={TABLE_DATA_STYLES}>
        {formatValue(originalValue, DAY_FORMAT_WITH_TIME)}
      </TableData>
      <TableData dataCy="new-value" className={TABLE_DATA_STYLES}>
        {formatValue(newValue, DAY_FORMAT_WITH_TIME)}
      </TableData>
    </TableRow>
  );
};

export default ChangeLogsDetailsRow;
