import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDeleteEmailTemplateMutation } from 'apis/email-templates.api';
import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { EmailTemplate } from 'interfaces/email-templates.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { DropdownMenu, ErrorModal, TableData, TableRow, WarningModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';
import { getCloneEmailTemplateLink, getEditEmailTemplateLink, getSendEmailTemplateLink } from 'utils/url';

interface Props {
  templatesData: EmailTemplate;
}

const EmailTemplatesRow = ({ templatesData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenWarningModal, handleOnOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [deleteEmailTemplate, { error }] = useDeleteEmailTemplateMutation();
  const { error: errorData } = (error as CommonError)?.data || {};

  const menuItemsAction = [
    {
      dataCy: 'menu-item-edit-button',
      name: 'Edit',
      actionOnClick: () => {
        navigate(getEditEmailTemplateLink(templatesData.id));
      },
      hidden: !permissions[Permissions.PAGES_HOST_EMAIL_TEMPLATES_EDIT],
    },
    {
      dataCy: 'menu-item-sent-button',
      name: 'Send',
      actionOnClick: () => {
        navigate(getSendEmailTemplateLink(templatesData.id));
      },
      hidden: !permissions[Permissions.PAGES_HOST_EMAIL_TEMPLATES_SEND],
    },
    {
      dataCy: 'menu-item-clone-button',
      name: 'Clone',
      actionOnClick: () => {
        navigate(getCloneEmailTemplateLink(templatesData.id));
      },
      hidden: !permissions[Permissions.PAGES_HOST_EMAIL_TEMPLATES_EDIT],
    },
    {
      dataCy: 'menu-item-delete-button',
      name: 'Delete',
      actionOnClick: handleOnOpenWarningModal,
      hidden: !permissions[Permissions.PAGES_HOST_EMAIL_TEMPLATES_DELETE],
    },
  ];

  const handleDeleteEmailTemplate = async () => {
    try {
      await deleteEmailTemplate(templatesData.id).unwrap();
      handleCloseWarningModal();
      successNotify(t('SuccessfullyDeleted'));
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('AreYouSureYouWantToDeleteEmailTemplate')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDeleteEmailTemplate}
      />
      <TableRow dataCy="email-templates-table-row">
        <TableData dataCy="table-data-email-template-name">{templatesData.templateName}</TableData>
        <TableData>{getFormattedDate(templatesData.creationTime, DAY_FORMAT_WITH_TIME)}</TableData>
        <TableData>
          <DropdownMenu elements={menuItemsAction} />
        </TableData>
      </TableRow>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};
export default EmailTemplatesRow;
