import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const selectState = (state: RootState) => state.auth;

export const selectIsAuth = createSelector(selectState, (authState) => authState.isAuth);

export const selectAuthCredentials = createSelector(selectState, (authState) => authState.authCredentials);

export const selectUserId = createSelector(selectState, (authState) => authState.userId);

export const selectTwoFactorAuthenticationType = createSelector(
  selectState,
  (authState) => authState.twoFactorAuthenticationType
);
