import { CommonResponse } from 'interfaces/shared.interface';
import { TenantDetailsNamePayload, TenantDetailsResponse } from 'interfaces/tenant-details.interface';

import { coreSplitApis } from './core.api';

export const tenantDetailsApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['TenantDetails'] }).injectEndpoints({
  endpoints: (build) => ({
    getForgetMyTenantRequestDetails: build.query<TenantDetailsResponse, void>({
      query: () => ({
        url: `ForgetTenantRequest/GetForgetMyTenantRequestDetails`,
        method: 'GET',
      }),
      providesTags: ['TenantDetails'],
    }),
    requestToBeforgotten: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `ForgetTenantRequest/Create`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['TenantDetails']),
    }),
    updateTenantName: build.mutation<void, TenantDetailsNamePayload>({
      query: (data) => ({
        url: `Tenant/Update`,
        method: 'PUT',
        data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetForgetMyTenantRequestDetailsQuery, useRequestToBeforgottenMutation, useUpdateTenantNameMutation } =
  tenantDetailsApi;
