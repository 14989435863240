import { v4 as uuidv4 } from 'uuid';

import { usePersonsWithSignificantControlQuery } from 'apis/companies-house.api';
import { BENEFICIAL_OWNERS_TABLE_COLUMNS } from 'constants/companies-house';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CompaniesHouseDetailsResponse } from 'interfaces/integrations/companies-house.interface';
import { LoadingOverlay, Pagination, Table } from 'shared-components';

import BeneficialOwnersRow from './BeneficialOwnersRow';

interface Props {
  companyDetailsResponse: CompaniesHouseDetailsResponse;
}

const BeneficialOwners = ({ companyDetailsResponse }: Props) => {
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const { data, isLoading, isFetching } = usePersonsWithSignificantControlQuery(
    `${companyDetailsResponse?.result?.companyNumber}&${sortingParams}${paginationParams}`
  );

  return (
    <div data-cy="beneficial-owners-page" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />
      <Table
        dataCy="beneficial-owners-table"
        columns={BENEFICIAL_OWNERS_TABLE_COLUMNS}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        sortingType={sortingType}
        isLoading={isLoading}
        isTableEmpty={!data?.result.items.length}
      >
        {data?.result.items.map((item) => <BeneficialOwnersRow {...item} key={uuidv4()} />)}
      </Table>
      <Pagination
        totalCount={data?.result.totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default BeneficialOwners;
