import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useTenantResolveTicketMutation } from 'apis/tenant-support-requests.api';
import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { SUPPORT_REQUESTS_STATUS_LABELS } from 'constants/support-requests';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { SUPPORT_REQUESTS_STATUSES, SupportRequests } from 'interfaces/tenant-support-requests.interface';
import { DropdownMenu, ErrorModal, TableData, TableRow, WarningModal } from 'shared-components';
import TableDataPair from 'shared-components/table/TableDataPair';
import { getFormattedDate } from 'utils/dates';
import { successNotify } from 'utils/notifications';
import { getSupportRequestChatLink } from 'utils/url';

interface Props {
  supportRequestsData: SupportRequests;
  refetchRequests: VoidFunction;
}

const SupportRequestsRow = ({ supportRequestsData }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { id, requestSubject, description, statusId } = supportRequestsData;
  const statusKey = statusId as SUPPORT_REQUESTS_STATUSES;
  const statusLabel = SUPPORT_REQUESTS_STATUS_LABELS[statusKey];

  const [resolveTicket, { error: resolveTicketError }] = useTenantResolveTicketMutation();

  const errorData = (resolveTicketError as CommonError)?.data || {};

  const menuItemsAction = [];

  if (statusId !== 1) {
    menuItemsAction.push({
      dataCy: 'support-requests-menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        navigate(getSupportRequestChatLink(id));
      },
    });
  }
  if (statusId !== 3) {
    menuItemsAction.push({
      dataCy: 'support-requests-menu-item-approve-button',
      name: 'Resolve',
      actionOnClick: handleOpenInfoModal,
    });
  }

  const handleResolveTicket = async () => {
    try {
      await resolveTicket(id).unwrap();
      handleCloseInfoModal();
      successNotify(t('SuccessfullyResolved'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('AreYouSureToResolveThisTicket')}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleResolveTicket}
      />
      <TableRow dataCy="support-requests-table-row">
        <TableData>
          <TableDataPair dataCy="support-requests-table-row-item-tenant" labelClassName={'font-bold'} label="Subject">
            {requestSubject}
          </TableDataPair>
          <TableDataPair labelClassName={'font-bold'} label="Description">
            {description}
          </TableDataPair>
        </TableData>
        <TableData>{t(statusLabel)}</TableData>
        <TableData>{getFormattedDate(supportRequestsData.creationTime, DAY_FORMAT_WITH_TIME)}</TableData>
        <TableData>
          <DropdownMenu dataCy="support-requests-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default SupportRequestsRow;
