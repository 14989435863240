import { useEffect, useState, useRef } from 'react';

interface UseCountUpProps {
  duration: number;
  targetValue: number;
}

function useCountUp({ duration, targetValue }: UseCountUpProps): [number, () => void] {
  const [value, setValue] = useState<number>(0);
  const [refresh, setRefresh] = useState<boolean>(false);
  const startTime = useRef<number | null>(null);

  const increment = targetValue / duration;

  useEffect(() => {
    const step = (timestamp: number) => {
      if (!startTime.current) {
        startTime.current = timestamp;
      }

      const progress = timestamp - startTime.current;
      const newValue = Math.min(progress * increment, targetValue);

      setValue(Math.floor(newValue));

      if (newValue < targetValue) {
        requestAnimationFrame(step);
      }
    };

    startTime.current = null;
    requestAnimationFrame(step);

    return () => {
      startTime.current = null;
    };
  }, [duration, targetValue, increment, refresh]);

  const startCountUp = () => {
    setValue(0);
    setRefresh((prev) => !prev);
  };

  return [value, startCountUp];
}

export default useCountUp;
