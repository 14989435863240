import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { auth } from 'apis/auth.api';
import { AuthState } from 'interfaces/auth.interface';

import { getAuthCredentials, getAuthState, userLogout } from './action';

export function getAuthorizationReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addMatcher(auth.endpoints.authenticate.matchFulfilled, (state, { payload: response }) => {
    if (response?.result) {
      state.userId = response.result.userId;
      state.twoFactorAuthenticationType = response.result.twoFactorAuthenticationType;
    }
    if (response?.result?.accessToken) {
      state.accessToken = response.result.accessToken;
      state.encAuthToken = response.result.encryptedAccessToken;
      state.isAuth = true;
    }
  });
}

export function setIsAuthReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(getAuthState, (state, { payload }) => {
    state.isAuth = payload.isAuth;
    state.accessToken = payload?.accessToken;
    state.encAuthToken = payload?.encAuthToken;
  });
}

export function getAuthCredentialsReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(getAuthCredentials, (state, { payload }) => {
    state.authCredentials = { ...payload };
  });
}

export function getLogoutReducer(builder: ActionReducerMapBuilder<AuthState>) {
  builder.addCase(userLogout, (state) => {
    state.isAuth = false;
    state.accessToken = null;
    state.encAuthToken = null;
  });
}
