import { CommonResponse } from './shared.interface';

export enum SUPPORT_TICKETS_COLUMNS {
  REQUESTER = 'requester',
  ISSUE = 'issue',
  RESOLVER = 'resolver',
  CREATION_TIME = 'creationTime',
  ACTIONS = 'actions',
}

export interface SupportTickets {
  id: number;
  supportResponderId: number;
  supportResponderName: string;
  requestSubject: string;
  description: string;
  supportRequesterName: string;
  supportRequesterTenantName: string;
  supportRequesterTenantId: number;
  statusId: number;
  creationTime: string;
}

export interface SupportTicketsResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: SupportTickets[];
  };
}

export interface SupportTicketChatDetails {
  ticketId: number;
  chatCompanionId: number;
  chatCompanionTenantId: number;
  chatCompanionTenantName: string | null;
  chatCompanionProfileImageId: string | null;
  chatCompanionFullName: string;
  statusId: number;
  description: string;
  subject: string;
}

export interface SupportTicketChatDetailsPayload {
  ticketId?: string;
  isAdmin?: boolean;
}
export interface SupportTicketChatDetailsResponse extends CommonResponse {
  result: SupportTicketChatDetails;
}

export interface UserChatMessagesPayload {
  userId?: number;
  ticketId?: string;
}

export interface UserChatMessageData {
  sourceUserId: number;
  sourceUserName: string;
  tenantId: number;
  targetUserId: number;
  targetTenantId: number | null;
  targetUserName: string | null;
  supportTicketId: number;
  side: number;
  senderReadState: number;
  receiverReadState: number;
  message: string;
  messageAttachmentId: string | null;
  messageAttachmentName: string | null;
  messageAttachmentType: string | null;
  creationTime: string;
  sharedMessageId: string;
  id: number;
}

export interface UserChatMessagesResponse extends CommonResponse {
  result: {
    items: UserChatMessageData[];
  };
}

export interface DownloadFilePayload {
  fileId: string;
  fileName: string;
}

export interface UploadFileResponse extends CommonResponse {
  result: {
    uniqueFileId: string;
  };
}

export interface SendMessagePayload {
  userId: number;
  ticketId: number;
  tenantId: number;
  message: string | null;
  messageAttachmentId: string | null;
  messageAttachmentName: string | null;
  messageAttachmentType: string | null;
}

export type SendMessageContent = Omit<SendMessagePayload, 'userId' | 'ticketId' | 'tenantId'>;
