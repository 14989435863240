import { CommonResponse } from './shared.interface';

export enum FORGOT_PASSWORD_FROM_FIELDS {
  userEmail = 'userEmail',
}
export interface ForgotPasswordFormValues {
  [FORGOT_PASSWORD_FROM_FIELDS.userEmail]: string;
}

export interface ForgotPasswordPayload {
  emailAddress: string;
}

export interface ForgotResponse extends CommonResponse {
  result: null;
}
