import { NO_DATA } from 'constants/common';
import { useOpen } from 'hooks/useOpen';
import { FilingHistory } from 'interfaces/integrations/companies-house.interface';
import { DropdownMenu, ErrorModal, TableData, TableRow } from 'shared-components';

type Props = FilingHistory;

const FilingHistoryRow = ({ date, type, description, fileLink }: Props) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const menuActions = [
    {
      dataCy: 'view-item-details-button',
      name: 'View',
      actionOnClick: () => handleView(),
    },
  ];

  const handleView = () => {
    if (fileLink) {
      window.open(fileLink);
    } else {
      handleOpenErrorModal();
      console.error('File does not exist');
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={'Error'}
        description={'YouCanFindDetailedErrorInLogs'}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <TableRow>
        <TableData dataCy="filing-history-date-table-row">{date || NO_DATA}</TableData>
        <TableData dataCy="filing-history-type-table-row">{type || NO_DATA}</TableData>
        <TableData dataCy="filing-history-description-table-row">{description || NO_DATA}</TableData>
        <TableData>
          <DropdownMenu elements={menuActions} />
        </TableData>
      </TableRow>
    </>
  );
};

export default FilingHistoryRow;
