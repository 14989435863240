import { CommonResponse } from 'interfaces/shared.interface';

export interface StartOnboardingResponse {
  result: {
    redirectUrl: string;
  };
}

export interface GetConnectionDetailsResponse extends CommonResponse {
  result: {
    isConnected: boolean;
  };
}

export enum ORDERS_COLUMNS {
  BUYER_NAME = 'buyerDate',
  BUYER_EMAIL = 'buyerEmail',
  AMOUNT = 'amount',
  DATE = 'id',
}

export interface GetPayPalOrders extends CommonResponse {
  id: number;
  tenantId: number;
  transactionId: string;
  currencyCode: string;
  amount: number;
  fee: number;
  netAmount: number;
  payerId: string;
  payerFirstName: string;
  payerLastName: string;
  payerEmail: string;
  dateCreated: string;
}

export interface GetPayPalOrdersResponse extends CommonResponse {
  result: {
    totalCount: number;
    orders: GetPayPalOrders[];
  };
}

export interface PostCreateOrderResponce extends CommonResponse {
  result: {
    orderId: string;
  };
}

export interface PayPalRepaymentPayload {
  amount: number;
}

export interface PayPalCapturePayload {
  orderId: string;
}

export enum PAY_FORM_FIELDS {
  AMOUNT = 'amount',
}
