import { useTranslation } from 'react-i18next';

import { Currency, getFormatPrice } from 'constants/global';
import { PRICE_FREQUENCY } from 'interfaces/subscriptions-tenant.interface';

interface Props {
  price: number;
  periodId: number;
}

const LabelComponent = ({ price, periodId }: Props) => {
  const { t } = useTranslation();

  return (
    <div data-cy="plan-information" className="flex font-normal">
      {t(PRICE_FREQUENCY[periodId])} {getFormatPrice(price, Currency.GBP)}
      <div className="text-blue ml-1">{t('ExcludingVat')}</div>
    </div>
  );
};

export default LabelComponent;
