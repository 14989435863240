import { faFolder, faFolderOpen, faListUl } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useDeleteOrganizationUnitMutation, useGetOrganizationUnitsQuery } from 'apis/organization-units.api';
import { ReactComponent as ArrowSvg } from 'assets/svg/arrow-right.svg';
import { ButtonVariants } from 'constants/common';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { CreateRootUnitModal, UpdateOrganizationUnitModal } from 'page-components/organization-units';
import { cn } from 'utils/global';
import { successNotify } from 'utils/notifications';

import { Button, DropdownMenu, ErrorModal, LoadingOverlay, WarningModal } from './index';

interface Props {
  node: any;
  isBranch: boolean | undefined;
  isExpanded?: boolean;
  handleExpand: () => void;
  numberOfMembers: number;
  numberOfRoles: number;
  handleSelect: () => void;
  isSelected?: boolean;
}

const OrganizationUnitsTreeItem = ({
  node,
  isBranch,
  isExpanded,
  handleExpand,
  numberOfMembers,
  numberOfRoles,
  isSelected,
  handleSelect,
}: Props) => {
  const { t } = useTranslation();
  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [deleteOrganizationUnit, { error: deleteOrganizationError, isLoading: isDeleteOrganizationUnitLoading }] =
    useDeleteOrganizationUnitMutation();
  const { refetch, isFetching: isOrganizationUnitFetching } = useGetOrganizationUnitsQuery();
  const [isOpenCreateRootUnitModal, handleOpenCreateRootUnitModal, handleCloseCreateRootUnitModal] = useOpen();
  const [
    isOpenUpdateOrganizationUnitModal,
    handleOpenUpdateOrganizationUnitModal,
    handleCloseUpdateOrganizationUnitModal,
  ] = useOpen();

  const errorData = (deleteOrganizationError as CommonError)?.data || {};

  const menuItemsAction = [
    {
      dataCy: 'menu-item-edit-button',
      name: 'Edit',
      actionOnClick: handleOpenUpdateOrganizationUnitModal,
    },
    {
      dataCy: 'menu-item-add-sub-unit-button',
      name: 'AddSubUnit',
      actionOnClick: handleOpenCreateRootUnitModal,
    },
    {
      dataCy: 'menu-item-delete-button',
      name: 'Delete',
      actionOnClick: handleOpenInfoModal,
    },
  ];

  const handleResolveTicket = async () => {
    try {
      await deleteOrganizationUnit(node.key).unwrap();
      refetch();
      handleCloseInfoModal();
      successNotify(t('SuccessfullyDeleted'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <LoadingOverlay isLoading={isDeleteOrganizationUnitLoading || isOrganizationUnitFetching} isFixed />

      <UpdateOrganizationUnitModal
        handleClose={handleCloseUpdateOrganizationUnitModal}
        isOpen={isOpenUpdateOrganizationUnitModal}
        refetchListRoot={refetch}
        selectedUnitId={node.key}
        selectedUnitLabel={node.label}
        data-cy="update-unit-modal"
      />

      <CreateRootUnitModal
        handleClose={handleCloseCreateRootUnitModal}
        isOpen={isOpenCreateRootUnitModal}
        refetchListRoot={refetch}
        selectedUnitId={node.key}
        data-cy="create-unit-modal"
      />

      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('OrganizationUnitDeleteWarningMessage', { 0: node.label })}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleResolveTicket}
      />

      <div style={{ marginLeft: `${15 * node.level}px` }} data-cy="tree-item" draggable="true" onClick={handleSelect}>
        <div
          className={cn(
            'rounded transition-all cursor-pointer text-darkgray text-sm px-1 py-1 w-full my-1 border-transparent focus:border-transparent focus:ring-0',
            {
              'bg-lightBlue1 dark:bg-blue5': isSelected,
              'hover:bg-lightGray6 dark:hover:bg-darkBlue10': !isSelected,
            }
          )}
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              {isBranch ? (
                <Button
                  variant={ButtonVariants.TRANSPARENT}
                  type="button"
                  onClick={handleExpand}
                  dataCy="arrow-button"
                  className={
                    'p-0 w-7 h-7 flex items-center justify-center rounded-full hover:!bg-lightGray6 dark:!bg-transparent'
                  }
                >
                  <ArrowSvg
                    className={cn('fill-black dark:fill-darkGray3 dark:hover:fill-white transition-all', {
                      'rotate-90': isExpanded,
                      'dark:fill-white': isSelected,
                    })}
                  />
                </Button>
              ) : (
                <div className="w-7 h-7" />
              )}
              <FontAwesomeIcon
                className={cn('mx-2 text-gray2', {
                  'dark:text-white': isSelected,
                })}
                icon={isExpanded ? faFolderOpen : faFolder}
              />
              <div className="flex flex-wrap">
                <p className="">{node.label}</p>
                <p className="ml-1 text-darkGray dark:text-white opacity-50">
                  {numberOfMembers}
                  &nbsp;
                  {t('Members')},
                </p>
                <p className="ml-1 text-darkGray dark:text-white  opacity-50">
                  {numberOfRoles}
                  &nbsp;
                  {t('Roles')}
                </p>
              </div>
            </div>

            <Button dataCy="dropdown-button" type="button" className="w-9 h-10 flex items-center justify-center">
              <DropdownMenu elements={menuItemsAction}>
                <FontAwesomeIcon className="text-white" icon={faListUl} />
              </DropdownMenu>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationUnitsTreeItem;
