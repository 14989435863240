import { useGetInvoicesQuery } from 'apis/invoices.api';
import { INVOICES_TABLE_COLUMNS } from 'constants/invoices';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import InvoicesRow from 'page-components/Invoices/InvoiceRow';
import UploadInvoiceHandler from 'page-components/Invoices/UploadInvoiceHandler';
import { LoadingOverlay, Pagination, Table } from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';

const Invoices = () => {
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { data, isLoading, refetch, isFetching } = useGetInvoicesQuery(`${sortingParams}${paginationParams}`);

  const { result } = data || {};

  const permissions = useAppSelector(selectUserPermissions);

  return (
    <div data-cy="invoices-page">
      <AppContentLayout
        title="Invoices"
        customHeader={
          permissions[Permissions.PAGES_TENANT_INVOICES_CREATE] ? (
            <UploadInvoiceHandler isLoading={isFetching} refetch={refetch} />
          ) : null
        }
      >
        <div className="relative">
          <LoadingOverlay isLoading={isFetching && !!data} />
          <Table
            dataCy="invoices-table"
            columns={INVOICES_TABLE_COLUMNS}
            handleSetTabName={handleSetTabName}
            sortingColumnId={sortingColumnId}
            isTableEmpty={!result?.items.length}
            sortingType={sortingType}
            isLoading={isLoading}
          >
            {result?.items.map((requests) => (
              <InvoicesRow key={requests.id} invoicesData={requests} refetchInvoices={refetch} />
            ))}
          </Table>
          <Pagination
            totalCount={result?.totalCount}
            handleSetMaxResultCount={handleSetMaxResultCount}
            maxResultCount={maxResultCount}
            handleSetCurrentPage={handleSetCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </AppContentLayout>
    </div>
  );
};

export default Invoices;
