import { LineItems } from 'interfaces/invoices.interface';
import { TableData, TableRow } from 'shared-components';

interface Props {
  invoiceLineItemsData: LineItems;
  refetchInvoice: VoidFunction;
}

const InvoiceLineItemsRow = ({ invoiceLineItemsData }: Props) => {
  const { description, quantity, price, amount, tax } = invoiceLineItemsData;

  return (
    <>
      <TableRow dataCy="invoice-line-item-table-row">
        <TableData dataCy="invoice-line-items-row-item-description">{description}</TableData>
        <TableData>{quantity}</TableData>
        <TableData>{price}</TableData>
        <TableData>{tax}</TableData>
        <TableData>{amount}</TableData>
      </TableRow>
    </>
  );
};

export default InvoiceLineItemsRow;
