import {
  CREATE_EMAIL_TEMPLATES_FIELDS_NAMES,
  EMAIL_TEMPLATES_COLUMNS,
  SEND_EMAIL_TEMPLATE_FIELDS_NAMES,
  SHARED_EMAIL_TEMPLATES_FIELDS_NAMES,
} from 'interfaces/email-templates.interface';
import {
  BASE_WYSIWYG_FIELD_NAMES,
  FieldTypes,
  FormItem,
  OptionsTypeValue,
  TableColumn,
} from 'interfaces/shared.interface';
import { TENANTS_COLUMNS } from 'interfaces/tenants.interface';
import { Input, MultiSelectFormInput } from 'shared-components';

export const EMAIL_TEMPLATES_COLUMNS_TITLES = {
  [EMAIL_TEMPLATES_COLUMNS.TEMPLATE_NAME]: 'TemplateName',
  [EMAIL_TEMPLATES_COLUMNS.CREATION_TIME]: 'CreationTime',
  [EMAIL_TEMPLATES_COLUMNS.ACTIONS]: '',
};
export const EMAIL_TEMPLATES_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: EMAIL_TEMPLATES_COLUMNS.TEMPLATE_NAME,
    title: EMAIL_TEMPLATES_COLUMNS_TITLES[EMAIL_TEMPLATES_COLUMNS.TEMPLATE_NAME],
    width: 'w-10/12',
  },
  {
    columnId: EMAIL_TEMPLATES_COLUMNS.CREATION_TIME,
    title: EMAIL_TEMPLATES_COLUMNS_TITLES[EMAIL_TEMPLATES_COLUMNS.CREATION_TIME],
    sorting: true,
  },
  { columnId: TENANTS_COLUMNS.ACTIONS, title: EMAIL_TEMPLATES_COLUMNS_TITLES[EMAIL_TEMPLATES_COLUMNS.ACTIONS] },
];

export const EMAIL_TEMPLATES_SEARCH_PLACEHOLDER = 'SearchByEmailTemplateName';

export const SHARED_EMAIL_TEMPLATES_FORM_FIELDS: FormItem[] = [
  {
    name: SHARED_EMAIL_TEMPLATES_FIELDS_NAMES.SUBJECT,
    label: 'Subject',
    dataCy: 'subject-input',
    className: 'mt-8',
    labelClassName: 'text-base text-darkGray dark:text-white',
    inputClassName: 'w-4/12 text-sm',
    component: Input,
    type: FieldTypes.TEXT,
    validation: {
      required: 'FieldRequired',
    },
  },
];

export const EMAIL_TEMPLATE_FORM_FIELDS: FormItem[] = [
  {
    name: CREATE_EMAIL_TEMPLATES_FIELDS_NAMES.TEMPLATE_NAME,
    label: 'TemplateName',
    dataCy: 'template-name-input',
    component: Input,
    className: 'mt-2',
    labelClassName: 'text-base text-darkGray dark:text-white',
    inputClassName: 'w-4/12 text-sm',
    type: FieldTypes.TEXT,
    validation: {
      required: 'FieldRequired',
    },
  },
  ...SHARED_EMAIL_TEMPLATES_FORM_FIELDS,
];

export const getSendEmailTemplateFormFields = (options?: OptionsTypeValue[]): FormItem[] => {
  return [
    ...SHARED_EMAIL_TEMPLATES_FORM_FIELDS,
    {
      name: SEND_EMAIL_TEMPLATE_FIELDS_NAMES.RECIPIENTS,
      label: 'ChooseRecipients',
      dataCy: 'recipient-select',
      className: 'mt-8',
      labelClassName: 'text-base text-darkGray dark:text-white',
      inputClassName: 'w-4/12 mt-2',
      component: MultiSelectFormInput,
      isSearchable: true,
      options: options,
      type: FieldTypes.SELECT,
      validation: {
        required: 'FieldRequired',
      },
    },
  ];
};

export const DEFAULT_EMAIL_TEMPLATE_FORM_VALUES = {
  [CREATE_EMAIL_TEMPLATES_FIELDS_NAMES.TEMPLATE_NAME]: '',
  [SHARED_EMAIL_TEMPLATES_FIELDS_NAMES.SUBJECT]: '',
  [BASE_WYSIWYG_FIELD_NAMES.TEXT_EDITOR]: '',
};

export const DEFAULT_SEND_EMAIL_TEMPLATE_FORM_VALUES = {
  [SHARED_EMAIL_TEMPLATES_FIELDS_NAMES.SUBJECT]: '',
  [BASE_WYSIWYG_FIELD_NAMES.TEXT_EDITOR]: '',
};
