import { useTranslation } from 'react-i18next';

import { useLazyGetUserConfigQuery } from 'apis/user.api';
import { useGetUserPermissionsQuery, useUpdateUserPermissionsMutation } from 'apis/users.api';
import { useOpen } from 'hooks/useOpen';
import useTenantId from 'hooks/useTenantId';
import { useTreeViewSelect } from 'hooks/useTreeViewSelect';
import useIsAdmin from 'hooks/useUserRole';
import { CommonError } from 'interfaces/shared.interface';
import { useAppSelector } from 'modules/store';
import { selectCurrentUserId } from 'modules/user/selector';
import PermissionsTabContent from 'page-components/roles/permissions/PermissionsTabContent';
import { Button, ErrorModal } from 'shared-components';
import { cn } from 'utils/global';
import { successNotify } from 'utils/notifications';

interface Props {
  userId?: number;
  handleClose: () => void;
  isError?: boolean;
  triggerRefetch: () => void;
}
const UserChangePermissionsModalContent = ({ userId, handleClose, isError, triggerRefetch }: Props) => {
  const { t } = useTranslation();
  const { isAdmin } = useIsAdmin();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const currentUserId = useAppSelector(selectCurrentUserId);

  const { tenantId, tenantError } = useTenantId({
    onError: handleOpenErrorModal,
  });
  const {
    data,
    isFetching: isFetchingPermissions,
    refetch,
    isLoading: isLoadingFetchPermissions,
  } = useGetUserPermissionsQuery({
    params: tenantId ? `userId=${userId}&tenantId=${tenantId}` : `id=${userId}`,
    isAdmin: !!tenantId,
  });
  const [updateUserPermissions, { isLoading: isUpdateUserPermissionLoading, error: updatePermissionError }] =
    useUpdateUserPermissionsMutation();
  const { error: updateErrorData } = (updatePermissionError as CommonError)?.data || {};

  const error = updateErrorData || tenantError;

  const [fetchUserConfig] = useLazyGetUserConfigQuery();

  const { permissions = [], grantedPermissionNames } = data?.result || {};

  const { handleChangeSelectedIds, selectedIds } = useTreeViewSelect(grantedPermissionNames);

  const onReset = () => {
    refetch();
  };

  const onSubmit = async () => {
    if (!userId) {
      return;
    }

    try {
      const payload = {
        grantedPermissionNames: selectedIds,
        id: userId,
        tenantId: tenantId,
      };

      await updateUserPermissions({ isAdmin, data: payload }).unwrap();
      triggerRefetch();
      successNotify('SavedSuccessfully');

      if (userId === currentUserId) {
        fetchUserConfig();
      }
      handleClose();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <div>
      <ErrorModal
        errorMessage={error?.message}
        description={error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      {isFetchingPermissions && !!data ? (
        <div className="flex items-center justify-center my-16">
          <div className="loader w-20 h-20 opacity-60 " />
        </div>
      ) : (
        <PermissionsTabContent
          defaultSelectedIds={grantedPermissionNames}
          permissions={permissions}
          handleChangeSelectedIds={handleChangeSelectedIds}
          isLoading={isLoadingFetchPermissions}
        />
      )}

      <div className="p-4 bg-orange rounded text-xs text-darkBlue12 my-4">
        {t('Note_RefreshPageForPermissionChanges')}
      </div>
      <div className="flex justify-between mt-8">
        <Button
          type="button"
          onClick={handleClose}
          className={cn(
            'pb-3 border rounded self-center background-transparent text-sm outline-none transition-all duration-150 ',
            { 'bg-blue4 border-0 hover:bg-linearGradient text-white': isError },
            {
              'text-blue3 dark:text-gray dark:hover:bg-lightGray3 hover:bg-lightGray3 dark:hover:bg-opacity-5 bg-white dark:bg-transparent':
                !isError,
            }
          )}
        >
          {t('Cancel')}
        </Button>
        <div>
          <Button className="mr-4" type="button" isLoading={isFetchingPermissions} onClick={onReset}>
            {t('ResetSpecialPermissions')}
          </Button>
          <Button type="button" onClick={onSubmit} isLoading={isUpdateUserPermissionLoading}>
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserChangePermissionsModalContent;
