import { getFormattedPriceWithMoveCurrencyToEnd } from 'constants/global';
import { SALES_SUMMARY_METRICS } from 'constants/sales-summary';
import { SalesSummary } from 'interfaces/sales-summary.interface';
import { LabelWithValue } from 'shared-components';

interface Props {
  metrics: Omit<SalesSummary, 'salesSummary'>;
}

const SalesSummaryMetrics = ({ metrics }: Props) => {
  return (
    <div data-cy="sales-summary-metrics" className="grid grid-cols-4 gap-2">
      {SALES_SUMMARY_METRICS?.map(({ id, label, className }) => (
        <LabelWithValue
          dataCy="sales-summary-metrics-label"
          key={id}
          labelClassName="pr-0 mb-2"
          bodyClassName="sm:flex-col items-center text-center text-xs md:text-sm"
          valueClassName={className}
          label={label}
          value={getFormattedPriceWithMoveCurrencyToEnd(metrics?.[id] || 0)}
          withColon={false}
        />
      ))}
    </div>
  );
};

export default SalesSummaryMetrics;
