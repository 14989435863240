import { cn } from 'utils/global';

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      data-cy="skeleton"
      className={cn('w-full h-full animate-pulse rounded-md bg-neutral-100 dark:bg-neutral-800', className)}
      {...props}
    />
  );
}

export default Skeleton;
