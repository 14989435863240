import { InputHTMLAttributes, forwardRef } from 'react';

import { useTranslation } from 'react-i18next';

import { Skeleton } from 'shared-components';
import { cn } from 'utils/global';

import ErrorMessage from './ErrorMessage';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  inputClassName?: string;
  labelClassName?: string;
  required?: boolean;
  className?: string;
  errorClassName?: string;
  tooltipContent?: string;
  dataCy?: string;
  requiredPlaceholder?: boolean;
  isLoading?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      labelClassName,
      onBlur,
      onFocus,
      label,
      className,
      required,
      placeholder,
      errorClassName,
      inputClassName,
      error,
      dataCy,
      requiredPlaceholder,
      isLoading,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    const inputStyles = 'custom-input text-xs rounded text-gray-700 dark:placeholder:text-white';

    return (
      <div data-cy={dataCy} className={className}>
        {label && (
          <label className={cn('block text-gray-700 mb-2 text-xs font-normal dark:text-gray', labelClassName)}>
            {t(label)}
            {label && required && ' *'}
          </label>
        )}

        {isLoading ? (
          <Skeleton className={cn(inputClassName, inputStyles)} />
        ) : (
          <input
            placeholder={`${t(placeholder ? placeholder : '')} ${requiredPlaceholder ? '*' : ''}`}
            onFocus={onFocus}
            onBlur={onBlur}
            className={cn(
              cn('', {
                '!border-error focus:ring': !!error,
              }),
              inputClassName,
              inputStyles
            )}
            ref={ref}
            {...props}
          />
        )}

        {error && (
          <ErrorMessage className={errorClassName} dataCy="error-message">
            {t(error)}
          </ErrorMessage>
        )}
      </div>
    );
  }
);

export default Input;

Input.displayName = 'Input';
