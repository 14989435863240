import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowRightSvg } from 'assets/svg/arrow-right.svg';
import { cn } from 'utils/global';

interface Props {
  image?: React.ReactNode;
  label?: string;
  dropdownIcon?: boolean;
  labelClassName?: string;
  className?: string;
  children?: ReactNode;
  onClick?: VoidFunction;
  dataCy?: string;
}

const MenuItem: FC<Props> = ({ image, label, labelClassName, dropdownIcon, className, children, onClick, dataCy }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        data-cy={dataCy}
        onClick={onClick}
        className={cn(
          'menu-item flex items-center px-3 py-1.5 dark:bg-darkBlue4 hover:bg-lightGray5 dark:hover:bg-darkBlue7 dark:hover:fill-white',
          { 'pl-9': !image },
          className
        )}
      >
        {image}
        <p className={cn('text-sm text-darkGray7 dark:text-white ml-1', labelClassName)}>{t(label || '')}</p>
      </div>
      {dropdownIcon && <ArrowRightSvg className="w-[5px] fill-darkGray6 dark:fill-darkGray3" />}
      {children}
    </>
  );
};

export default MenuItem;
