import { ReactNode, useEffect, useState } from 'react';

import { Tree, TreeDragDropEvent } from 'primereact/tree';
// eslint-disable-next-line import/no-unresolved
import { TreeNode } from 'primereact/treenode';
import { useTranslation } from 'react-i18next';

import { useGetOrganizationUnitsQuery, useMoveRootUnitRequestMutation } from 'apis/organization-units.api';
import { useOpen } from 'hooks/useOpen';
import { MoveRootUnitPayload, OrganizationTreeUnit, SelectedUnitInfo } from 'interfaces/organization-units.interface';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, LoadingOverlay, Skeleton, WarningModal } from 'shared-components';
import OrganizationUnitsTreeItem from 'shared-components/OrganizationUnitsTreeItem';
import { successNotify } from 'utils/notifications';

interface Props {
  setSelectedUnit: (unit: SelectedUnitInfo) => void;
  selectedUnit: SelectedUnitInfo | null;
}

const OrganizationTree = ({ setSelectedUnit, selectedUnit }: Props) => {
  const { t } = useTranslation();

  const { data, isLoading, refetch } = useGetOrganizationUnitsQuery();
  const [moveOrganizationUnit, { isLoading: moveLogicLoading, error: moveLogicError }] =
    useMoveRootUnitRequestMutation();
  const [treeNodes, setTreeNodes] = useState<TreeNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Record<string, boolean>>({});
  const [selectedKey, setSelectedKey] = useState<number | null>(selectedUnit?.id || null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [dragAndDropKeys, setDragAndDropKeys] = useState<{ dragNodeKey: number; dropNodeKey: number | null } | null>(
    null
  );
  const [dragDropLabels, setDragDropLabels] = useState({ dragLabel: '', dropLabel: '' });

  const errorData = (moveLogicError as CommonError)?.data || {};

  const handleSelectNode = (node: TreeNode) => {
    setSelectedKey(node.key as number);
    setSelectedUnit({
      id: node.key as number,
      displayName: node.label || '',
    });
  };

  const handleExpand = (key: string | number) => {
    setExpandedKeys((prevExpandedKeys) => {
      const keyStr = key.toString();
      const newExpandedKeys = { ...prevExpandedKeys };
      if (keyStr in prevExpandedKeys) {
        delete newExpandedKeys[keyStr];
      } else {
        newExpandedKeys[keyStr] = true;
      }
      return newExpandedKeys;
    });
  };

  const formatTreeData = (items: OrganizationTreeUnit[], parentKey: number | null = null, level = 0): TreeNode[] => {
    return items
      .filter((item) => item.parentId === parentKey)
      .map((item) => {
        return {
          key: item.id,
          label: item.displayName,
          children: formatTreeData(items, item.id, level + 1),
          level,
          data: {
            memberCount: item.memberCount || 0,
            roleCount: item.roleCount || 0,
          },
        };
      });
  };

  const nodeTemplate = (node: TreeNode): ReactNode => {
    return (
      <OrganizationUnitsTreeItem
        node={node}
        isBranch={node.children && node.children.length > 0}
        isExpanded={expandedKeys[node.key || '']}
        handleExpand={() => handleExpand(node.key || '')}
        numberOfMembers={node.data.memberCount}
        numberOfRoles={node.data.roleCount}
        handleSelect={() => handleSelectNode(node)}
        isSelected={selectedKey === node.key}
        data-cy="organization-tree-item"
      />
    );
  };

  const onDragDrop = (event: TreeDragDropEvent) => {
    handleOpenInfoModal();
    const { dragNode, dropNode } = event;
    const dragNodeKey = dragNode.key as number;
    const dropNodeKey = dropNode.key as number;
    const dragNodeLabel = dragNode.label || '';
    const dropNodeLabel = dropNode.label || '';
    setDragAndDropKeys({ dragNodeKey, dropNodeKey });
    setDragDropLabels({ dragLabel: dragNodeLabel, dropLabel: dropNodeLabel });
  };

  const moveOrganizatonUnit = async () => {
    if (dragAndDropKeys) {
      const { dragNodeKey, dropNodeKey } = dragAndDropKeys;
      const payload: MoveRootUnitPayload = {
        id: dragNodeKey,
        newParentId: dropNodeKey,
      };
      try {
        await moveOrganizationUnit(payload).unwrap();
        refetch();
        handleCloseInfoModal();
        successNotify(t('SuccessfullyMoved'));
      } catch (error) {
        handleOpenErrorModal();
      }
    }
  };

  const selectFirstNode = () => {
    if (treeNodes.length > 0) {
      const firstNode = treeNodes[0];
      handleSelectNode(firstNode);
    }
  };

  useEffect(() => {
    if (data?.result?.items) {
      const transformedNodes = formatTreeData(data.result.items);
      setTreeNodes(transformedNodes);

      if (isFirstLoad && transformedNodes.length > 0) {
        selectFirstNode();
        setIsFirstLoad(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (selectedUnit) {
      setSelectedKey(selectedUnit.id);
    } else if (treeNodes.length > 0) {
      selectFirstNode();
    }
  }, [selectedUnit, treeNodes]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <LoadingOverlay isLoading={moveLogicLoading} isFixed />

      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('OrganizationUnitMoveConfirmMessage', {
          0: dragDropLabels.dragLabel,
          1: dragDropLabels.dropLabel,
        })}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={moveOrganizatonUnit}
      />
      {isLoading ? (
        <Skeleton className="w-100 h-12" />
      ) : (
        <Tree
          value={treeNodes}
          nodeTemplate={nodeTemplate}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          emptyMessage={t('NoResultsFound')}
          data-cy="organization-tree"
          dragdropScope="organization"
          onDragDrop={onDragDrop}
        />
      )}
    </>
  );
};

export default OrganizationTree;
