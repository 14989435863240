import { useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddWidgetMutation, useGetDashboardDefinitionQuery } from 'apis';
import { ADD_WIDGET_FORM_FIELDS } from 'constants/dashboard';
import { useOpen } from 'hooks/useOpen';
import useUserRole from 'hooks/useUserRole';
import { AddWidgetForm, AddWidgetPayload, DashboardWidget } from 'interfaces/dashboard.interface';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, FormContent, Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { getDashboardName } from 'utils/dashboard';
import { errorNotify, successNotify } from 'utils/notifications';

interface Props {
  handleClose: VoidFunction;
  isOpen: boolean;
  widgets: DashboardWidget[];
  pageId: string;
}
export const AddWidgetModal = ({ handleClose, isOpen, widgets, pageId }: Props) => {
  const { t } = useTranslation();

  const { isAdmin } = useUserRole();
  const dashboardName = getDashboardName(isAdmin);

  const DEFAULT_WIDTH = isAdmin ? 7 : 6;
  const DEFAULT_HEIGHT = 10;

  const { data: roleWidgetsData } = useGetDashboardDefinitionQuery(dashboardName);
  const roleWidgets = roleWidgetsData?.result?.widgets;

  const missingWidgetOptions = useMemo(() => {
    if (roleWidgets?.length) {
      const widgetIds = widgets.map(({ widgetId }) => widgetId);
      const missingWidgets = roleWidgets.filter((widget) => !widgetIds.includes(widget.id));

      return missingWidgets?.map(({ id, name }) => ({ label: t(name), value: id }));
    }

    return [];
  }, [widgets, roleWidgets]);

  const [addWidgetRequest, { isLoading, error }] = useAddWidgetMutation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const addWidgetMethods = useForm<AddWidgetForm>();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = addWidgetMethods;

  const { data: errorData } = (error as CommonError) || {};

  const onCloseModal = () => {
    reset();
    handleClose();
  };

  const onSubmit = async ({ widget }: AddWidgetForm) => {
    const payload: AddWidgetPayload = {
      widgetId: widget?.value,
      pageId,
      dashboardName,
      width: DEFAULT_WIDTH,
      height: DEFAULT_HEIGHT,
    };

    try {
      await addWidgetRequest(payload);
      onCloseModal();
      successNotify(t('SavedSuccessfully'));
    } catch (error) {
      handleOpenErrorModal();
      errorNotify('ErrorWhileRequest');
    }
  };

  return (
    <>
      <Modal dataCy="add-widget-modal" isOpen={isOpen} onClose={onCloseModal} heading="AddWidget">
        <FormProvider {...addWidgetMethods}>
          <form data-cy="add-widget-modal-form" onSubmit={handleSubmit(onSubmit)}>
            {missingWidgetOptions?.length ? (
              <FormContent fields={ADD_WIDGET_FORM_FIELDS(missingWidgetOptions)} register={register} errors={errors} />
            ) : (
              t('NoWidgetAvailableMessage')
            )}
            <ModalControlButtons
              isShownSubmit
              className="pb-5"
              saveButtonText="Save"
              closeButtonText="Cancel"
              isLoading={isLoading}
              onClose={onCloseModal}
            />
          </form>
        </FormProvider>
      </Modal>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
    </>
  );
};
