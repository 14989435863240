import { useTranslation } from 'react-i18next';

import { getFormatPrice } from 'constants/global';

interface Props {
  value: string;
  price: number;
}

const SubscriptionPrice = ({ value, price }: Props) => {
  const { t } = useTranslation();

  if (!price) {
    return null;
  }

  return (
    <p>
      {getFormatPrice(price)} / {t(value)}
    </p>
  );
};

export default SubscriptionPrice;
