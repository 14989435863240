import { CommonResponse } from './shared.interface';

export interface OrganizationTreeUnit {
  parentId: number;
  code: string;
  displayName: string;
  memberCount: number;
  roleCount: number;
  lastModificationTime: string;
  lastModifierUserId: number;
  creationTime: string;
  creatorUserId: number;
  id: number;
}

export interface OrganizationUnitsResponse extends CommonResponse {
  result: {
    items: OrganizationTreeUnit[];
  };
}

export enum ORGANIZATION_UNITS_REQUEST_FORM_FIELDS {
  DISPLAY_NAME = 'displayName',
}

export interface CreateRootUnitPayload {
  displayName: string;
  parentId?: number | null;
}

export interface UpdateOrganizationUnitPayload {
  displayName: string;
  id: number;
}

export interface MoveRootUnitPayload {
  id: number;
  newParentId: number | null;
}

export enum MEMBERS_COLUMNS {
  DELETE = 'delete',
  USER_NAME = 'userName',
  ADDITION_TIME = 'additionTime',
}

export interface OrganizationUnitsUsersResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: [
      {
        name: string;
        surname: string;
        userName: string;
        emailAddress: string;
        profilePictureId: null;
        addedTime: string;
        id: number;
      },
    ];
  };
}

export interface OrganizationUnitsUsersPayload {
  userId?: number | null;
  params?: string;
}

export interface SharedMembersData {
  id: number;
  name: string;
  surname: string;
  addedTime: string;
}

export interface OrganizationUnitsDeleteUsersPayload {
  userId: number;
  unitId: number;
}

export enum ADD_NEW_MEMBER_COLUMNS {
  CHECKBOX = 'checkbox',
  NAME = 'name',
}

export interface AddNewMemberResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: [
      {
        name: string;
        value: string;
      },
    ];
  };
}

export interface AddNewMemberPayload {
  filter: string;
  maxResultCount: string;
  organizationUnitId?: number | null;
  skipCount: number;
}

export interface AddMemberTableItem {
  name: string;
  value: string;
}

export interface SelectedUnitInfo {
  id: number;
  displayName: string;
}

export interface AddNewMembersPayload {
  organizationUnitId?: number | null;
  userIds: number[];
}

export interface ExtendedAddNewMembersPayload extends AddNewMembersPayload {
  search: string;
}

export enum ROLES_COLUMNS {
  DELETE = 'delete',
  ROLE = 'role',
  ADDITION_TIME = 'additionTime',
}

export interface OrganizationUnitsRolesPayload {
  roleId?: number | null;
  params?: string;
}

export interface OrganizationUnitsRolesResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: [
      {
        displayName: string;
        name: string;
        userName: string;
        addedTime: string;
        id: number;
      },
    ];
  };
}

export interface OrganizationUnitsDeleteRolesPayload {
  roleId: number;
  unitId: number;
}

export interface AddNewRolesPayload {
  organizationUnitId?: number | null;
  roleIds: number[];
}

export interface ExtendedAddNewRolesPayload extends AddNewRolesPayload {
  search: string;
}

export interface SharedRolesData {
  id: number;
  displayName: string;
  addedTime: string;
}
