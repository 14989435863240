import { AdminTopStatsResponse } from 'interfaces/admin-top-starts.interface';
import { DailySalesResponse } from 'interfaces/daily-sales.interface';
import {
  AddPagePayload,
  AddWidgetPayload,
  DashboardLayout,
  DeletePageQueries,
  GetDashboardDefinition,
  GetDashboardLayoutResponse,
  RenamePagePayload,
  TenantTopStatsResponse,
} from 'interfaces/dashboard.interface';
import { GeneralStatsResponse } from 'interfaces/general-stats.interface';
import { GetIncomeStatisticsQuery, IncomeStatisticsResponse } from 'interfaces/income-statistics.interface';
import { MemberActivitiesResponse } from 'interfaces/member-activities.interface';
import { ProfitSharesResponse } from 'interfaces/profit-share.interface';
import { RegionalStatsResponse } from 'interfaces/regional-stats.interface';
import { SalesSummaryResponse } from 'interfaces/sales-summary.interface';
import { DateRangeQueries } from 'interfaces/shared.interface';
import { SubscriptionStatisticsResponse } from 'interfaces/subscriptions-admin.interface';
import { ExpiringTenantsResponse, RecentTenantsResponse } from 'interfaces/tenants.interface';

import { coreSplitApis } from './core.api';

const coreSplitApisWithTag = coreSplitApis.enhanceEndpoints({ addTagTypes: ['DashboardLayout'] });

export const dashboardApi = coreSplitApisWithTag.injectEndpoints({
  endpoints: (build) => ({
    tenantGetTopStats: build.query<TenantTopStatsResponse, void>({
      query: () => ({
        url: 'TenantDashboard/GetTopStats',
        method: 'GET',
      }),
    }),
    tenantGetSalesSummary: build.query<SalesSummaryResponse, string>({
      query: (salesSummaryDatePeriod) => ({
        url: `TenantDashboard/GetSalesSummary?SalesSummaryDatePeriod=${salesSummaryDatePeriod}`,
        method: 'GET',
      }),
    }),
    tenantGetRegionalStats: build.query<RegionalStatsResponse, void>({
      query: () => ({
        url: 'TenantDashboard/GetRegionalStats',
        method: 'GET',
      }),
    }),
    tenantGetMemberActivity: build.query<MemberActivitiesResponse, void>({
      query: () => ({
        url: 'TenantDashboard/GetMemberActivity',
        method: 'GET',
      }),
    }),
    tenantGetGeneralStats: build.query<GeneralStatsResponse, void>({
      query: () => ({
        url: 'TenantDashboard/GetGeneralStats',
        method: 'GET',
      }),
    }),
    tenantGetProfitShare: build.query<ProfitSharesResponse, void>({
      query: () => ({
        url: 'TenantDashboard/GetProfitShare',
        method: 'GET',
      }),
    }),
    tenantGetDailySales: build.query<DailySalesResponse, void>({
      query: () => ({
        url: 'TenantDashboard/GetDailySales',
        method: 'GET',
      }),
    }),
    adminGetTopStats: build.query<AdminTopStatsResponse, DateRangeQueries>({
      query: ({ startDate, endDate }) => ({
        url: `HostDashboard/GetTopStatsData?StartDate=${startDate}&EndDate=${endDate}`,
        method: 'GET',
      }),
    }),
    adminGetIncomeStatistics: build.query<IncomeStatisticsResponse, GetIncomeStatisticsQuery>({
      query: ({ dateInterval, startDate, endDate }) => ({
        url: `HostDashboard/GetIncomeStatistics?IncomeStatisticsDateInterval=${dateInterval}&StartDate=${startDate}&EndDate=${endDate}`,
        method: 'GET',
      }),
    }),
    adminGetSubscriptionStatistics: build.query<SubscriptionStatisticsResponse, DateRangeQueries>({
      query: ({ startDate, endDate }) => ({
        url: `HostDashboard/GetSubscriptionTenantStatistics?StartDate=${startDate}&EndDate=${endDate}`,
        method: 'GET',
      }),
    }),
    adminGetExpiringTenants: build.query<ExpiringTenantsResponse, void>({
      query: () => ({
        url: 'HostDashboard/GetSubscriptionExpiringTenantsData',
        method: 'GET',
      }),
    }),
    adminGetRecentTenantsData: build.query<RecentTenantsResponse, void>({
      query: () => ({
        url: 'HostDashboard/GetRecentTenantsData',
        method: 'GET',
      }),
    }),
    saveDashboardLayout: build.mutation<void, DashboardLayout>({
      query: (data) => ({
        url: `DashboardCustomization/SavePage`,
        method: 'POST',
        data,
      }),
    }),
    getDashboardLayout: build.query<GetDashboardLayoutResponse, string>({
      query: (dashboardName) => ({
        url: `DashboardCustomization/GetUserDashboard?DashboardName=${dashboardName}`,
        method: 'get',
      }),
      providesTags: ['DashboardLayout'],
    }),
    deleteDashboardPage: build.mutation<void, DeletePageQueries>({
      query: (queries) => ({
        url: `DashboardCustomization/DeletePage?Id=${queries.id}&DashboardName=${queries.dashboardName}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DashboardLayout'],
    }),
    getDashboardDefinition: build.query<GetDashboardDefinition, string>({
      query: (dashboardName) => ({
        url: `DashboardCustomization/GetDashboardDefinition?DashboardName=${dashboardName}`,
        method: 'get',
      }),
    }),
    addWidget: build.mutation<void, AddWidgetPayload>({
      query: (data) => ({
        url: `DashboardCustomization/AddWidget`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['DashboardLayout'],
    }),
    addPage: build.mutation<void, AddPagePayload>({
      query: (data) => ({
        url: `DashboardCustomization/AddNewPage`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['DashboardLayout'],
    }),
    renamePage: build.mutation<void, RenamePagePayload>({
      query: (data) => ({
        url: `DashboardCustomization/RenamePage`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['DashboardLayout'],
    }),
  }),
});

export const {
  useLazyAdminGetIncomeStatisticsQuery,
  useLazyAdminGetTopStatsQuery,
  useAdminGetTopStatsQuery,
  useTenantGetTopStatsQuery,
  useTenantGetProfitShareQuery,
  useTenantGetDailySalesQuery,
  useLazyTenantGetSalesSummaryQuery,
  useTenantGetRegionalStatsQuery,
  useTenantGetMemberActivityQuery,
  useTenantGetGeneralStatsQuery,
  useAdminGetSubscriptionStatisticsQuery,
  useAdminGetExpiringTenantsQuery,
  useAdminGetRecentTenantsDataQuery,
  useSaveDashboardLayoutMutation,
  useGetDashboardLayoutQuery,
  useDeleteDashboardPageMutation,
  useGetDashboardDefinitionQuery,
  useAddWidgetMutation,
  useAddPageMutation,
  useRenamePageMutation,
} = dashboardApi;
