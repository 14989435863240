import { getPlanLabelAndValue } from 'constants/subscription-tenant';
import { SubscriptionPlansResult } from 'interfaces/subscriptions-tenant.interface';
import { LabelWithValue } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  plans: SubscriptionPlansResult[];
  isLoading: boolean;
  isFree: boolean;
}

const SubscriptionPlans = ({ plans, isLoading, isFree }: Props) => {
  if (!plans?.length) {
    return null;
  }

  return (
    <h6 className={cn('px-4 py-3', { 'text-center': isFree })} data-cy="plans">
      {plans.map((plan) => {
        const { label, value } = getPlanLabelAndValue(plan, isFree);

        return (
          <LabelWithValue
            key={plan.id}
            bodyClassName={cn('dark:text-white flex-row mb-0', { 'inline-block': !plan.price })}
            valueClassName="dark:text-white"
            withColon={false}
            label={label}
            value={value}
            isLoading={isLoading}
            skeletonClassName="w-40"
            dataCy={`plan-${plan.id}`}
          />
        );
      })}
    </h6>
  );
};

export default SubscriptionPlans;
