import { createSlice } from '@reduxjs/toolkit';

import { DashboardState } from 'interfaces/dashboard.interface';

import { setIsEditModeReducer } from './reducers';

const initialState: DashboardState = {
  isEditMode: false,
};

const dashboardSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setIsEditModeReducer(builder);
  },
});

export default dashboardSlice.reducer;
