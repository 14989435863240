import { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { FieldTypes } from 'interfaces/shared.interface';
import TableSkeleton from 'shared-components/table/TableSkeleton';

interface Props {
  templateVariables: Record<string, string>;
  handleChangeInputValue: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}
const FillTemplateVariables = ({ templateVariables, handleChangeInputValue, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mt-8" data-cy="fill-template-variables">
      <div className="text-md font-medium text-darkGray mb-2 dark:text-white">{t('FillTemplateVariables')}</div>
      <div className="flex border-y border-lightGray5 px-4 dark:bg-darkBlue3 dark:border-none">
        <div className="text-xs font-semibold py-4 text-darkGray w-1/2 dark:text-white dark:border-r-2 dark:border-darkBlue4">
          {t('Name')}
        </div>
        <div className="text-xs text-darkGray p-4 font-semibold dark:text-white">{t('VariableValue')}</div>
      </div>
      {isLoading ? (
        <TableSkeleton
          tableDataClassName="w-1/2"
          tableRowClassName="inline-table w-full"
          skeletonClassName="p-0 w-full"
          columns={2}
        />
      ) : (
        Object.entries(templateVariables).map(([key, value], index) => (
          <div
            key={key}
            className={`flex px-4 items-center border-b border-lightGray5 dark:border-none ${
              index % 2 === 0 ? 'dark:bg-darkBlue4' : ''
            }`}
          >
            <div
              data-cy="variables-name"
              className="w-1/2 text-xs font-normal text-darkGray py-4 dark:text-white dark:border-r-2 dark:border-darkBlue4"
            >
              {key}
            </div>

            <div className="w-1/2 pr-4">
              <input
                data-cy="template-variable-input"
                value={value}
                name={key}
                onChange={(e) => handleChangeInputValue(e)}
                className="custom-input rounded w-full ml-4 text-sm"
                type={FieldTypes.TEXT}
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default FillTemplateVariables;
