import { GDPR_ADMIN_COLUMNS, GDPR_TENANT_COLUMNS, GDPR_FILTERS_FORM_FIELD_NAMES } from 'interfaces/gdpr.interface';
import { FieldTypes, KeyWithString, KeyWithStringDictionary, TableColumn } from 'interfaces/shared.interface';
import { SelectFormInput } from 'shared-components';

import { BASE_FILTER_FIELDS } from './common';

export const GDPR_ADMIN_COLUMNS_TITLES = {
  [GDPR_ADMIN_COLUMNS.TENANT_ID]: 'TenantId',
  [GDPR_ADMIN_COLUMNS.TENANT_NAME]: 'TenantName',
  [GDPR_ADMIN_COLUMNS.STATUS]: 'Status',
  [GDPR_ADMIN_COLUMNS.CREATION_TIME]: 'CreationTime',
  [GDPR_ADMIN_COLUMNS.ACTIONS]: '',
};

export const GDPR_TENANT_COLUMNS_TITLES = {
  [GDPR_TENANT_COLUMNS.USER_ID]: 'UserId',
  [GDPR_TENANT_COLUMNS.USER_NAME]: 'UserName',
  [GDPR_TENANT_COLUMNS.STATUS]: 'Status',
  [GDPR_TENANT_COLUMNS.CREATION_TIME]: 'CreationTime',
  [GDPR_TENANT_COLUMNS.ACTIONS]: '',
};

export const GDPR_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: GDPR_TENANT_COLUMNS.STATUS,
    title: GDPR_TENANT_COLUMNS_TITLES[GDPR_TENANT_COLUMNS.STATUS],
    width: 'w-3/12',
  },
  {
    columnId: GDPR_TENANT_COLUMNS.CREATION_TIME,
    title: GDPR_TENANT_COLUMNS_TITLES[GDPR_TENANT_COLUMNS.CREATION_TIME],
    width: 'w-1/12',
    sorting: true,
  },
  {
    columnId: GDPR_TENANT_COLUMNS.ACTIONS,
    title: GDPR_TENANT_COLUMNS_TITLES[GDPR_TENANT_COLUMNS.ACTIONS],
    width: 'w-1/12',
    className: 'm-auto',
  },
];

export const GDPR_ADMIN_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: GDPR_ADMIN_COLUMNS.TENANT_ID,
    title: GDPR_ADMIN_COLUMNS_TITLES[GDPR_ADMIN_COLUMNS.TENANT_ID],
    width: 'w-3/12',
  },
  {
    columnId: GDPR_ADMIN_COLUMNS.TENANT_NAME,
    title: GDPR_ADMIN_COLUMNS_TITLES[GDPR_ADMIN_COLUMNS.TENANT_NAME],
    width: 'w-3/12',
  },
  ...GDPR_TABLE_COLUMNS,
];

export const GDPR_TENANT_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: GDPR_TENANT_COLUMNS.USER_ID,
    title: GDPR_TENANT_COLUMNS_TITLES[GDPR_TENANT_COLUMNS.USER_ID],
    width: 'w-3/12',
  },
  {
    columnId: GDPR_TENANT_COLUMNS.USER_NAME,
    title: GDPR_TENANT_COLUMNS_TITLES[GDPR_TENANT_COLUMNS.USER_NAME],
    width: 'w-3/12',
  },
  ...GDPR_TABLE_COLUMNS,
];

const SHARED_FILTERS_OPTIONS = [
  { label: 'Requested', value: '1' },
  { label: 'Completed', value: '2' },
];

export const GDPR_FILTERS_FORM_FIELDS = [
  {
    name: GDPR_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS,
    label: 'SelectStatus',
    className: 'ml-10 w-40',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-active-select',
    component: SelectFormInput,
    options: [...SHARED_FILTERS_OPTIONS],
  },
];

export const getGDPRFiltersFieldsDefaultValues = (selectStatusLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [GDPR_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: { label: selectStatusLabel, value: '' },
});

export const SEARCH_GDPR_USER_FIRST_LAST_NAME = 'SearchByUserFirstOrLastName';
export const SEARCH_GDPR_TENANT_NAME_PLACEHOLDER = 'SearchByTenantName';

export const WARNING_FORGET_USER_REQUEST = 'AreYouSureToApproveForgetUserRequestDescription';
export const WARNING_FORGET_TENANT_REQUEST = 'AreYouSureToApproveForgetTenantRequestDescription';

export const FILTER_PLACEHOLDER: KeyWithString = {
  tenant: SEARCH_GDPR_USER_FIRST_LAST_NAME,
  admin: SEARCH_GDPR_TENANT_NAME_PLACEHOLDER,
};

export const TABLE_COLUMNS: KeyWithStringDictionary<TableColumn[]> = {
  tenant: GDPR_TENANT_TABLE_COLUMNS,
  admin: GDPR_ADMIN_TABLE_COLUMNS,
};

export const WARNING_MODAL_DESCRIPTION: KeyWithString = {
  tenant: WARNING_FORGET_USER_REQUEST,
  admin: WARNING_FORGET_TENANT_REQUEST,
};
