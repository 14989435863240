import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetQuickBooksCustomersQuery } from 'apis/quickBooks.api';
import {
  CUSTOMERS_FILTERS_FORM_FIELDS,
  CUSTOMERS_TABLE_COLUMNS,
  getCustomersFiltersFieldsDefaultValues,
} from 'constants/quickBooks';
import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CustomersFilterFormValues } from 'interfaces/integrations/quickBooks.interface';
import { FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';
import FilterFormContentWithoutPermissions from 'shared-components/filter/FilterFormContentWithoutPermissions';

import CustomersRow from './CustomersRow';

interface Props {
  setIsReconnectionRequired: (isReconnectionRequired: boolean) => void;
}

const Customers = ({ setIsReconnectionRequired }: Props) => {
  const { t } = useTranslation();

  const { applyFilters, filterParams, isSameFilters } = useFilters();

  const { handleSetTabName, customSortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { quickBookPaginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { data, isLoading, isFetching, refetch } = useGetQuickBooksCustomersQuery(
    `${filterParams}${customSortingParams}${quickBookPaginationParams}`
  );

  const { result } = data || {};
  const response = data?.result?.data?.customers;

  setIsReconnectionRequired(data?.result.isReconnectionRequired || false);

  const CustomersFiltersMethods = useForm<CustomersFilterFormValues>({
    defaultValues: getCustomersFiltersFieldsDefaultValues(t('SelectStatus')),
  });

  const onSubmitFilters = (data: CustomersFilterFormValues) => {
    const { IsActive, filter } = data;

    const newFiltersToApply = {
      CustomerName: filter,
      IsActive: IsActive.value !== '' ? (IsActive.value as boolean).toString() : null,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    CustomersFiltersMethods.reset(getCustomersFiltersFieldsDefaultValues(t('SelectStatus')));
    onSubmitFilters(getCustomersFiltersFieldsDefaultValues(t('SelectStatus')));
  };

  useEffect(() => {
    CustomersFiltersMethods.reset(getCustomersFiltersFieldsDefaultValues(t('SelectStatus')));
  }, [t, CustomersFiltersMethods]);

  return (
    <div data-cy="customers-tab">
      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <FormProvider {...CustomersFiltersMethods}>
          <form onSubmit={CustomersFiltersMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="customers-search"
              placeHolder={t('SearchByCustomerName')}
              handleClearFields={handleClearFields}
            >
              <FilterFormContentWithoutPermissions fields={CUSTOMERS_FILTERS_FORM_FIELDS} />
            </FilterForm>
          </form>
        </FormProvider>
        <Table
          dataCy="customers-table"
          columns={CUSTOMERS_TABLE_COLUMNS}
          isTableEmpty={!result?.data?.totalCount}
          isLoading={isLoading}
          sortingColumnId={sortingColumnId}
          sortingType={sortingType}
          handleSetTabName={handleSetTabName}
        >
          {response?.map((item) => <CustomersRow key={item.id} customersData={item} />)}
        </Table>

        <Pagination
          totalCount={result?.data?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Customers;
