import { AREA_CHART_FILTERS_FORM_FIELDS_NAMES } from 'interfaces/area-chart.interface';

export const AREA_CHART_FILTER_FORM_FILTER_OPTIONS = [
  {
    id: 'daily',
    label: 'Daily',
    value: '1',
    dataCy: 'filter-daily',
  },
  {
    id: 'weekly',
    label: 'Weekly',
    value: '2',
    dataCy: 'filter-weekly',
  },
  {
    id: 'monthly',
    label: 'Monthly',
    value: '3',
    dataCy: 'filter-monthly',
  },
];

export const AREA_CHART_FILTER_FORM_DEFAULT_VALUES = {
  [AREA_CHART_FILTERS_FORM_FIELDS_NAMES.AREA_CHART_DATE_PERIOD]: '1',
};
