import { useState } from 'react';

import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { useLazyGetOsAuditLogsToExcelQuery, useLazyGetOsEntityChangesToExcelQuery } from 'apis/os-audit-logs.api';
import { ButtonVariants } from 'constants/common';
import { OS_AUDIT_LOGS_TABS, CURRENT_FILTERS } from 'constants/os-audit-logs';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { DropdownMenu, ErrorModal, LoadingOverlay } from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';
import Tabs from 'shared-components/Tabs';
import { downloadDocument } from 'utils/global';

interface CypressProps {
  isTesting?: boolean;
}

const OSAuditLogs = ({ isTesting = false }: CypressProps) => {
  const { t } = useTranslation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isLoading, setIsLoading] = useState(false);

  const [getEntityChangesToExcel, { error: entityChangesError }] = useLazyGetOsEntityChangesToExcelQuery();

  const [getAuditLogsToExcel, { error: AuditLogsToExcelError }] = useLazyGetOsAuditLogsToExcelQuery();

  const errorGetAuditLogsError = (entityChangesError as CommonError)?.data || {};

  const errorAuditLogsToExcelData = (AuditLogsToExcelError as CommonError)?.data || {};

  const errorData = errorGetAuditLogsError || errorAuditLogsToExcelData;

  const handleDownloadEntityChanges = async () => {
    setIsLoading(true);
    try {
      const { result: newData } = await getAuditLogsToExcel(CURRENT_FILTERS).unwrap();

      if (newData) {
        downloadDocument(newData.context, newData.fileName, newData.fileType);
      }
    } catch (error) {
      handleOpenErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadAuditLogs = async () => {
    setIsLoading(true);
    try {
      const { result: newData } = await getEntityChangesToExcel(CURRENT_FILTERS).unwrap();

      if (newData) {
        downloadDocument(newData.context, newData.fileName, newData.fileType);
      }
    } catch (error) {
      handleOpenErrorModal();
    } finally {
      setIsLoading(false);
    }
  };

  const menuActions = [
    {
      className: 'items-center',
      icon: faFileExcel,
      iconClassName: '!text-darkGray3 pr-3',
      dataCy: 'operation-logs-button',
      name: 'OperationLogs',
      actionOnClick: () => {
        handleDownloadEntityChanges();
      },
    },
    {
      className: 'items-center',
      icon: faFileExcel,
      iconClassName: '!text-darkGray3 pr-3',
      dataCy: 'change-logs-button',
      name: 'ChangeLogs',
      actionOnClick: () => {
        handleDownloadAuditLogs();
      },
    },
  ];

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <AppContentLayout
        title="OsAuditLogs"
        customHeader={
          <>
            <DropdownMenu
              icon={faChevronDown}
              buttonVariant={ButtonVariants.MAIN}
              dataCy="download-dropdown-menu"
              elements={menuActions}
              className="!block"
              disabled={isLoading}
            >
              {t('Download')}
            </DropdownMenu>
          </>
        }
      >
        <div className="relative">
          <LoadingOverlay isLoading={isLoading} />
          <Tabs componentProps={{ isTesting }} tabs={OS_AUDIT_LOGS_TABS} />
        </div>
      </AppContentLayout>
    </>
  );
};

export default OSAuditLogs;
