import { CommonResponse } from './shared.interface';

export interface TenantDetailsResponse extends CommonResponse {
  result: {
    isRequested: boolean;
    dateCreated: string;
  };
}

export interface TenantDetailsNamePayload {
  name: string | undefined;
  isActive: boolean;
  id: number | undefined;
}

export enum UPDATE_TENANT_NAME_FORM_FIELD {
  DISPLAY_NAME = 'displayName',
}

export interface UpdateTenantNameFormValues {
  [UPDATE_TENANT_NAME_FORM_FIELD.DISPLAY_NAME]: string;
}
