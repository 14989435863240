import { useTranslation } from 'react-i18next';

import { sharedButtonStyle } from 'constants/codat-accounting';
import { useOpen } from 'hooks/useOpen';
import { AccountingDetails } from 'interfaces/integrations/codat-accounting.interface';
import { Button, ErrorModal } from 'shared-components';

interface Props {
  triggerRefetch: () => void;
  connectionData?: AccountingDetails;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const ResumeConnectionButton = ({ triggerRefetch, connectionData, isLoading, setIsLoading }: Props) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const handleResumeConnection = async () => {
    try {
      window.location.href = connectionData?.linkUrl || '';
      triggerRefetch();
    } catch (error) {
      setIsLoading(false);
      handleOpenErrorModal();
      console.error(error);
    }
    setIsLoading(true);
  };

  return (
    <>
      <ErrorModal description={'UnexpectedError'} isOpen={isErrorModalOpen} handleClose={handleCloseErrorModal} />
      <Button
        isLoading={isLoading}
        className={sharedButtonStyle}
        data-cy="resume-connection-button"
        onClick={handleResumeConnection}
      >
        {t('ResumeConnection')}
      </Button>
    </>
  );
};

export default ResumeConnectionButton;
