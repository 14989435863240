import {
  CreateDocumentTypePayload,
  CreateDocumentTypeResponse,
  DocumentsTypesResponse,
  GetDocumentTypeDetailsResponse,
  ParseDocumentPayload,
  ParseDocumentResponse,
  TrainDocumentTypePayload,
  TrainedDocumentTypesResponse,
} from 'interfaces/integrations/typless.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const typlessApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['DocumentTypes'] }).injectEndpoints({
  endpoints: (build) => ({
    getDocumentsTypes: build.query<DocumentsTypesResponse, string>({
      query: (params) => ({
        url: `DocumentType/GetAll?${params}`,
        method: 'GET',
      }),
      providesTags: ['DocumentTypes'],
    }),
    postCreateDocumentType: build.mutation<CreateDocumentTypeResponse, CreateDocumentTypePayload>({
      query: (data) => ({
        url: `DocumentType/Create`,
        method: 'POST',
        data,
      }),
    }),
    getDocumentDetails: build.query<GetDocumentTypeDetailsResponse, number>({
      query: (params) => ({
        url: `DocumentType/Get?id=${params}`,
        method: 'GET',
      }),
    }),
    deleteDocumentType: build.mutation<void, number>({
      query: (params) => ({
        url: `DocumentType/Delete?id=${params}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DocumentTypes']),
    }),
    postAddDocumentForTraining: build.mutation<CommonResponse, TrainDocumentTypePayload>({
      query: (data) => ({
        url: `DocumentType/AddDocumentForTraining`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DocumentTypes']),
    }),
    postStartTraining: build.mutation<CommonResponse, number>({
      query: (params) => ({
        url: `DocumentType/StartTraining?id=${params}`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DocumentTypes']),
    }),
    postParseDocument: build.mutation<ParseDocumentResponse, ParseDocumentPayload>({
      query: (data) => ({
        url: `DocumentType/ParseDocument`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DocumentTypes']),
    }),
    getAllTrained: build.query<TrainedDocumentTypesResponse, void>({
      query: () => ({
        url: `DocumentType/GetAllTrained`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDocumentsTypesQuery,
  usePostCreateDocumentTypeMutation,
  useGetDocumentDetailsQuery,
  useDeleteDocumentTypeMutation,
  usePostAddDocumentForTrainingMutation,
  usePostStartTrainingMutation,
  usePostParseDocumentMutation,
  useGetAllTrainedQuery,
} = typlessApi;
