import { FC, useEffect } from 'react';

import { useLazyGetAuditLogPropertiesQuery } from 'apis/os-audit-logs.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal } from 'shared-components';

import AdvancedFilterHandler from '../AdvanceFilterHandler';

interface Props {
  onSearch: (filters: any) => void;
  handleClear: VoidFunction;
  isTesting: boolean;
}

const OperationLogsFilter: FC<Props> = ({ onSearch, handleClear, isTesting }) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [
    getAuditLogsProperties,
    {
      data: getAuditLogsPropertiesData,
      isLoading: isAuditLogsPropertiesLoading,
      isFetching: isAuditLogsPropertiesFetching,
      error: getAuditLogsPropertiesError,
    },
  ] = useLazyGetAuditLogPropertiesQuery();

  const errorData = (getAuditLogsPropertiesError as CommonError)?.data || {};
  const { result } = getAuditLogsPropertiesData || {};

  useEffect(() => {
    if (getAuditLogsPropertiesError && !isTesting) {
      handleOpenErrorModal();
    }
  }, [getAuditLogsPropertiesError, handleOpenErrorModal, isTesting]);

  useEffect(() => {
    getAuditLogsProperties();
  }, [getAuditLogsProperties]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <AdvancedFilterHandler
        onSearch={onSearch}
        handleClear={handleClear}
        result={result}
        isLoading={isAuditLogsPropertiesLoading || isAuditLogsPropertiesFetching}
      />
    </>
  );
};

export default OperationLogsFilter;
