import { useNavigate } from 'react-router-dom';

import { useLazyGetInvoicePdfQuery } from 'apis/quickBooks.api';
import { Currency, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { GetInvoices } from 'interfaces/integrations/quickBooks.interface';
import { DropdownMenu, ErrorModal, TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { downloadDocument, useFormatPriceFunction } from 'utils/global';
import { getInvoiceQuickBooksDetailsLink } from 'utils/url';

type Props = {
  invoicesData: GetInvoices;
  setIsFetching?: (isFetching: boolean) => void;
};

const InvoicesRow = ({ invoicesData, setIsFetching }: Props) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { id, reference, customerName, issueDate, dueDate, total } = invoicesData;
  const [getInvoiceToPdf, { data }] = useLazyGetInvoicePdfQuery();
  const navigate = useNavigate();

  const menuItemsAction = [
    {
      dataCy: 'quickbooks-menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        navigate(getInvoiceQuickBooksDetailsLink(id));
      },
    },
    {
      dataCy: 'quickbooks-menu-item-download-button',
      name: 'Download',
      actionOnClick: () => handleDownloadDocument(),
    },
  ];

  const handleDownloadDocument = async () => {
    try {
      setIsFetching?.(true);
      const invoiceId = invoicesData.id;
      const response = await getInvoiceToPdf(invoiceId);

      if (response.data?.result.data.content && response.data?.result.data.fileName) {
        downloadDocument(response.data.result.data.content, response.data.result.data.fileName);
      }
    } catch (error) {
      handleOpenErrorModal();
    } finally {
      setIsFetching?.(false);
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={data?.error?.message}
        description={data?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />

      <TableRow dataCy="invoices-table-row">
        <TableData dataCy="invoices-reference">{reference}</TableData>
        <TableData dataCy="invoices-customer-name">{customerName}</TableData>
        <TableData dataCy="invoices-issue-date">
          {getFormattedDate(issueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="invoices-due-date">
          {getFormattedDate(dueDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
        </TableData>
        <TableData dataCy="invoices-total">{useFormatPriceFunction(total, Currency.USD)}</TableData>
        <TableData>
          <DropdownMenu dataCy="invoice-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default InvoicesRow;
