import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useCreateOrUpdateRoleMutation, useGetRoleForEditQuery } from 'apis/roles.api';
import { CREATE_ROLE_TABS } from 'constants/roles';
import { useOpen } from 'hooks/useOpen';
import { useTreeViewSelect } from 'hooks/useTreeViewSelect';
import { CreateRolesFormValues } from 'interfaces/roles.interface';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';

interface Props {
  handleClose: VoidFunction;
  refetchRoles: VoidFunction;
  editingId: number | null;
}

const ManageRoleModalContent = ({ handleClose, refetchRoles, editingId }: Props) => {
  const { data: roleDetails, isLoading: isGetRoleForEditLoading } = useGetRoleForEditQuery({ id: editingId });
  const [updateOrCreateRole, { error, isLoading: isCreateOrUpdateRoleLoading }] = useCreateOrUpdateRoleMutation();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { permissions, role, grantedPermissionNames = [] } = roleDetails?.result || {};

  const { reset, ...manageRolesFormMethods } = useForm<CreateRolesFormValues>();

  const { selectedIds: selectedPermissionsIds, handleChangeSelectedIds } = useTreeViewSelect();

  const { data: errorData } = (error as CommonError) || {};

  const onSubmit = async (values: CreateRolesFormValues) => {
    try {
      await updateOrCreateRole({
        role: {
          id: editingId || null,
          ...values,
        },
        grantedPermissionNames: selectedPermissionsIds?.length ? selectedPermissionsIds : grantedPermissionNames,
      }).unwrap();
      refetchRoles();
      handleClose();
      successNotify();
    } catch (e) {
      console.error(e);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    const { displayName, isDefault } = role || {};
    reset({ displayName, isDefault });
  }, [reset, role]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <FormProvider reset={reset} {...manageRolesFormMethods}>
        <form onSubmit={manageRolesFormMethods.handleSubmit(onSubmit)} data-cy="manage-role-modal-form">
          <Tabs
            tabs={CREATE_ROLE_TABS}
            componentProps={{
              permissions,
              handleChangeSelectedIds,
              defaultSelectedIds: grantedPermissionNames,
              editingId,
              isGetRoleForEditLoading,
            }}
          />
          <ModalControlButtons
            isLoading={isCreateOrUpdateRoleLoading}
            isShownSubmit
            className="pb-5"
            onClose={handleClose}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default ManageRoleModalContent;
