import { Currency } from 'constants/global';
import { CommonResponse, KeyNameData } from 'interfaces/shared.interface';

export interface GetAccountingDetailsResponse extends CommonResponse {
  result: AccountingDetails;
}

export interface AccountingDetails {
  accountingCompanyName: string | null;
  accountingPlatformName: string | null;
  connectionStatus: string | null;
  isAccountingConnectionCreated: boolean;
  linkUrl: string;
}

export interface GetAccountingPlatformsResponse extends CommonResponse {
  result: KeyNameData[];
}

export interface CodatLinkResponse extends CommonResponse {
  result: {
    linkUrl?: string;
  };
}

export interface CodatLinkPost {
  platformKey: string;
}

export interface InvoicesResponse extends CommonResponse {
  result: {
    data: { invoices: InvoiceData[]; totalCount: number };
    isDatasetSyncNotCompleted: boolean;
  };
}

export interface InvoiceData {
  amountDue: number;
  currency: Currency;
  dueDate: string;
  id: string;
  invoiceNumber: string;
  issueDate: string;
  subTotal: number;
  total: number;
  totalTax: number;
}

export enum INVOICES_COLUMNS {
  ISSUE_DATE = 'IssueDate',
  DUE_DATE = 'DueDate',
  INVOICE_NUMBER = 'InvoiceNumber',
  AMOUNT_DUE = 'AmountDue',
  TOTAL = 'totalAmount',
}

export enum CODAT_INVOICE_FORM_FIELDS {
  ISSUE_DATE_FROM = 'IssueDateFrom',
  ISSUE_DATE_TO = 'IssueDateTo',
}

export interface SuppliersResponse extends CommonResponse {
  result: {
    data: { suppliers: SupplierData[]; totalCount: number };
    isDatasetSyncNotCompleted: boolean;
  };
}

export interface SupplierData {
  name: number | null;
  contactName: string | null;
  emailAddress: string | null;
  id: string | null;
  registrationNumber: string | null;
}

export enum SUPPLIERS_COLUMNS {
  NAME = 'Name',
  CONTACT_NAME = 'ContactName',
  EMAIL_ADDRESS = 'EmailAddress',
  REGISTRATION_NUMBER = 'RegistrationNumber',
}
