import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCancelSubscriptionMutation, useRenewSubscriptionMutation } from 'apis/subscriptions-tenant.api';
import { NO_DATA } from 'constants/common';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import useSubscriptionInformation from 'hooks/useSubscriptionInformation';
import { CommonError } from 'interfaces/shared.interface';
import { PERIOD } from 'interfaces/subscriptions-tenant.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { DropdownMenuButton, ErrorModal, LabelWithValue, WarningModal } from 'shared-components';
import { getFormattedDate } from 'utils/dates';
import { formatDataCy, useFormatPriceFunction } from 'utils/global';
import { successNotify } from 'utils/notifications';
import { getTrialSubscriptionPurchase } from 'utils/url';

const SubscriptionInformationSection = () => {
  const {
    subscriptionPlan,
    subscriptionStartDateUtc,
    subscriptionEndDateUtc,
    periodId,
    isSubscriptionDeactivated,
    nextSubscriptionPaymentAmount,
    subscriptionAwaitingCancellation,
    isInTrialPeriod,
    subscriptionId,
    expiringSubscriptionName,
    displayName,
    subscriptionPrice,
    tenantId,
    refetch,
  } = useSubscriptionInformation();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const permissions = useAppSelector(selectUserPermissions);

  const [isWarningModalOpen, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();

  const periodName = periodId && PERIOD[periodId] ? PERIOD[periodId] : 'Unknown';

  const [cancelSubscription, { error: cancelSubscriptionError }] = useCancelSubscriptionMutation();

  const [renewSubscription, { error: renewSubscriptionError }] = useRenewSubscriptionMutation();

  const errorData =
    (cancelSubscriptionError as CommonError)?.data?.error || (renewSubscriptionError as CommonError)?.data?.error;

  const purchaseLink = getTrialSubscriptionPurchase(tenantId ?? 0, subscriptionId ?? 0);

  const isValidIdAndPrice = subscriptionId && subscriptionPrice;

  const getEndDateKey = () => {
    if (isInTrialPeriod) {
      return 'TrialPeriodEndDate';
    }
    if (subscriptionAwaitingCancellation) {
      return 'EndDate';
    }
    return 'NextPaymentDate';
  };

  const formattedPrice = useFormatPriceFunction(nextSubscriptionPaymentAmount || 0);

  const leftLabelValueMap = {
    SubscriptionPlan: `${subscriptionPlan}  (${periodName})`,
    StartDate: getFormattedDate(subscriptionStartDateUtc, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    ...(subscriptionEndDateUtc && {
      [getEndDateKey()]: getFormattedDate(subscriptionEndDateUtc, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES),
    }),
    ...(nextSubscriptionPaymentAmount &&
      !subscriptionAwaitingCancellation && {
        NextPaymentAmount: formattedPrice,
      }),
  };

  const isSubscriptionManager = !!permissions[Permissions.PAGES_TENANT_SUBSCRIPTION_MANAGEMENT];

  const getDescriptionKey = () => {
    if (subscriptionAwaitingCancellation) {
      return 'SubscriptionRenewWarningMessage';
    } else {
      return isSubscriptionManager
        ? 'TenantWillBeDeactivatedAfterCanceledSubscriptionWillExpireWarningMessage'
        : 'SubscriptionCancelWarningMessage';
    }
  };

  const descriptionKey = getDescriptionKey();

  const descriptionVars = isSubscriptionManager
    ? { 0: expiringSubscriptionName }
    : { 0: displayName, 1: expiringSubscriptionName };

  const createMenuActions = () => {
    const actions = [];
    if (isInTrialPeriod) {
      actions.push({
        dataCy: 'menu-item-buy-now-button',
        name: 'BuyNow',
        actionOnClick: () => navigate(purchaseLink, { state: { backButtonRedirect: ROUTES.subscriptionSelect } }),
      });
    }
    actions.push({
      dataCy: 'menu-item-change-button',
      name: 'Change',
      actionOnClick: () => navigate(ROUTES.subscriptionSelect, { state: { isBackButton: true } }),
    });
    if (isValidIdAndPrice && !subscriptionAwaitingCancellation) {
      actions.push({
        dataCy: 'menu-item-cancel-button',
        name: 'Cancel',
        actionOnClick: handleOpenWarningModal,
      });
    }
    if (isValidIdAndPrice && subscriptionAwaitingCancellation && !isSubscriptionDeactivated) {
      actions.push({
        dataCy: 'menu-item-renew-button',
        name: 'Renew',
        actionOnClick: handleOpenWarningModal,
      });
    }
    return actions;
  };

  const menuItemsAction = createMenuActions();

  const handleWarningConfirmation = async (subscriptionAwaitingCancellation?: boolean) => {
    try {
      if (subscriptionAwaitingCancellation) {
        await renewSubscription().unwrap();
        successNotify(t('SubscriptionSuccessfullyRenewed'));
      } else {
        await cancelSubscription().unwrap();
        successNotify(t('SubscriptionSuccessfullyCanceled'));
      }
      handleCloseWarningModal();
      refetch?.();
    } catch {
      handleOnOpenErrorModal();
    }
  };

  return (
    <>
      <WarningModal
        isOpen={isWarningModalOpen}
        description={t(descriptionKey, descriptionVars)}
        warningMessage={t('AreYouSure')}
        handleClose={handleCloseWarningModal}
        handleConfirm={() => handleWarningConfirmation(subscriptionAwaitingCancellation)}
      />
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
      <div
        className="sm:flex block sm:flex-col sm:w-1/2 w-full sm:pr-4 pr-0 pb-4 sm:pb-0"
        data-cy="subscription-information"
      >
        {isSubscriptionDeactivated && (
          <h2 className="mb-5 text-sm text-red1 items-start font-bold">
            {t('SubscriptionIsNoLongerAvailable', { 0: subscriptionPlan })}
          </h2>
        )}
        {Object.entries(leftLabelValueMap).map(([label, value]) => (
          <LabelWithValue
            bodyClassName="pr-1 flex-row"
            key={label}
            labelClassName="w-2/3"
            valueClassName="w-1/3"
            withColon={false}
            label={label}
            value={value || NO_DATA}
            skeletonClassName="w-40"
            dataCy={formatDataCy(label)}
          />
        ))}
        <DropdownMenuButton menuItemsAction={menuItemsAction} dropdownClassName="!block w-min" />
      </div>
    </>
  );
};

export default SubscriptionInformationSection;
