import { cn } from '../utils/global';

interface Props {
  className?: string;
  progress: number;
  progressClassName?: string;
}

const ProgressLine = ({ className, progressClassName, progress }: Props) => (
  <div className={cn('w-full h-[0.438rem] bg-lightGray5 rounded-[0.063rem]', className)}>
    <div style={{ width: progress + '%' }} className={cn('h-full bg-blue', progressClassName)} />
  </div>
);

export default ProgressLine;
