import { useTranslation } from 'react-i18next';

import { useOpen } from 'hooks/useOpen';
import Button from 'shared-components/button/Button';

import UploadInvoiceModal from './UploadInvoiceModal';

interface Props {
  refetch: VoidFunction;
  isLoading: boolean;
}

const UploadInvoiceHandler = ({ refetch, isLoading }: Props) => {
  const [isOpen, handleOpen, handleClose] = useOpen();

  const { t } = useTranslation();
  return (
    <>
      <Button data-cy="upload-invoice-button" onClick={handleOpen}>
        {t('UploadInvoice')}
      </Button>
      <UploadInvoiceModal isOpen={isOpen} refetch={refetch} handleClose={handleClose} isLoading={isLoading} />
    </>
  );
};

export default UploadInvoiceHandler;
