import { useCallback, useEffect } from 'react';

import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { useConfirmEmailMutation } from 'apis/auth.api';
import { ACTIVATE_EMAIL_SUCCESS_MESSAGE } from 'constants/confirm-email';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { ErrorModal, SuccessModal } from 'shared-components';

const ConfirmEmailForm = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [isOpenSuccessModal, handleOnOpenSuccessModal, handleCloseSuccessModal] = useOpen();
  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();

  const [confirmEmail, { data }] = useConfirmEmailMutation();

  const { c: searchCode } = qs.parse(search);

  const closeModal = () => {
    handleCloseSuccessModal();
    handleOnCloseErrorModal();
    navigate(ROUTES.signIn);
  };

  const uploadConfirmEmail = useCallback(async () => {
    if (!searchCode) {
      handleOnOpenErrorModal();
      return;
    }

    try {
      const confirmResponse = await confirmEmail({ c: searchCode as string }).unwrap();

      if (confirmResponse.success) {
        handleOnOpenSuccessModal();
      }
    } catch (error) {
      handleOnOpenErrorModal();
    }
  }, [searchCode, handleOnOpenErrorModal, confirmEmail, handleOnOpenSuccessModal]);

  useEffect(() => {
    uploadConfirmEmail();
  }, [uploadConfirmEmail]);

  return (
    <>
      <SuccessModal
        successMessage={ACTIVATE_EMAIL_SUCCESS_MESSAGE}
        isOpen={isOpenSuccessModal}
        handleClose={closeModal}
      />
      <ErrorModal
        errorMessage={data?.error?.message}
        description={data?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={closeModal}
      />
      <div className="text-center mt-4">Please wait...</div>
    </>
  );
};

export default ConfirmEmailForm;
