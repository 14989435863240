import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetInvoiceDetailsQuery } from 'apis/quickBooks.api';
import { INVOICE_TABLE_COLUMNS } from 'constants/quickBooks';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import InvoiceData from 'page-components/integrations/quickBooks/invoices/InvoiceData';
import InvoiceRow from 'page-components/integrations/quickBooks/invoices/InvoiceRow';
import { ErrorModal, LoadingOverlay, Pagination, Table } from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';

const InvoiceQuickBooksDetailsPage = () => {
  const { invoiceId } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { data, isLoading: invoiceLoading, error } = useGetInvoiceDetailsQuery(invoiceId);
  const { t } = useTranslation();
  const response = data?.result?.data?.lineItems;
  const { result } = data || {};
  const { error: errorData } = (error as CommonError)?.data || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [maxResultCount, setMaxResultCount] = useState(10);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetMaxResultCount = (count: number) => {
    setMaxResultCount(count);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (error) {
      handleOpenErrorModal();
    }
  }, [error, handleOpenErrorModal]);

  return (
    <div data-cy="invoice-details-page">
      <AppContentLayout title="InvoiceDetails" headerClassName="text-3xl text-darkGray dark:text-white">
        <LoadingOverlay isLoading={isFetching} isFixed />
        <div className="relative">
          <ErrorModal
            isOpen={isErrorModalOpen}
            handleClose={handleCloseErrorModal}
            errorMessage={errorData?.message}
            description={errorData?.details}
          />

          <InvoiceData data={data?.result.data} isLoading={invoiceLoading} setIsFetching={setIsFetching} />

          <h2 className="mt-10 text-2xl">{t('LineItems')}</h2>
          <Table
            className="mt-10"
            dataCy="invoice-table"
            columns={INVOICE_TABLE_COLUMNS}
            isTableEmpty={!result?.data}
            isLoading={invoiceLoading}
          >
            {response?.map((item) => <InvoiceRow key={item.id} invoiceData={item} />)}
          </Table>
          <Pagination
            totalCount={response?.length}
            handleSetMaxResultCount={handleSetMaxResultCount}
            maxResultCount={maxResultCount}
            handleSetCurrentPage={handleSetCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </AppContentLayout>
    </div>
  );
};

export default InvoiceQuickBooksDetailsPage;
