import { BannerProps } from 'interfaces/subscriptions-tenant.interface';
import SubscriptionLink from 'page-components/subscriptions-tenant/banner/SubscriptionLink';

import BuildMessageWithLinks from './BannerMessageBuilder';

const TrialBanner = ({
  isSubscriptionManagementPermission,
  displayName,
  tenantId,
  subscriptionId,
  expiringSubscriptionName,
  loading,
  daysAmount,
  subscriptionIsExpiringSoon,
  isSubscriptionDeactivated,
}: BannerProps) => {
  const buildTrialMessage = () => {
    return (
      <BuildMessageWithLinks
        messageKey="TrialSubscriptionNotification"
        values={{ 0: `${displayName}` }}
        splits={[
          {
            index: 1,
            component: <SubscriptionLink isPurchaseLink={true} tenantId={tenantId} subscriptionId={subscriptionId} />,
          },
        ]}
      />
    );
  };

  const buildTrialExpireMessageForUser = () => {
    return (
      <BuildMessageWithLinks messageKey="TrialExpireNotificationForUser" values={{ 0: `${daysAmount}` }} splits={[]} />
    );
  };

  const buildTrialExpirationMessage = () => {
    return (
      <BuildMessageWithLinks
        messageKey="TrialExpireNotification"
        values={{
          0: `${daysAmount}`,
          1: `${expiringSubscriptionName}`,
        }}
        splits={[
          {
            index: 2,
            component: <SubscriptionLink isPurchaseLink={true} tenantId={tenantId} subscriptionId={subscriptionId} />,
          },
          {
            index: 3,
            component: <SubscriptionLink isPurchaseLink={false} />,
          },
        ]}
      />
    );
  };

  const buildTrialDeactivatedExpirationMessage = () => {
    return (
      <BuildMessageWithLinks
        messageKey="TenantWillBeDeactivatedAfterSubscriptionTrialExpiresNotification"
        values={{ 0: `${daysAmount}` }}
        splits={[
          {
            index: 1,
            component: <SubscriptionLink isPurchaseLink={true} tenantId={tenantId} subscriptionId={subscriptionId} />,
          },
          {
            index: 2,
            component: <SubscriptionLink isPurchaseLink={false} />,
          },
        ]}
      />
    );
  };

  const handleTrialExpireNotification = () => {
    if (isSubscriptionManagementPermission) {
      if (expiringSubscriptionName) {
        return buildTrialExpirationMessage();
      } else {
        return buildTrialDeactivatedExpirationMessage();
      }
    }
    return buildTrialExpireMessageForUser();
  };

  const handleTrialSubscriptionNotification = () => {
    if (isSubscriptionManagementPermission) {
      return buildTrialMessage();
    }
    return buildTrialExpireMessageForUser();
  };

  const buildMessage = () => {
    if (subscriptionIsExpiringSoon && !isSubscriptionDeactivated) {
      return handleTrialExpireNotification();
    }
    return handleTrialSubscriptionNotification();
  };

  const message = buildMessage();

  return !loading && message ? (
    <div data-cy="banner" className="w-full block text-center py-2 bg-blue9 text-white font-bold">
      {message}
    </div>
  ) : null;
};

export default TrialBanner;
