import { Box } from 'shared-components';

import SubscriptionInformationIncludedSection from './SubscriptionInformationIncludedSection';
import SubscriptionInformationSection from './SubscriptionInformationSection';

const SubscriptionInformation = () => {
  return (
    <Box className="sm:flex w-full block" dataCy="subscription-information-block">
      <SubscriptionInformationSection />
      <SubscriptionInformationIncludedSection />
    </Box>
  );
};

export default SubscriptionInformation;
