import { NO_DATA } from 'constants/common';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { PreviousCompanyNames } from 'interfaces/integrations/companies-house.interface';
import { TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

interface Props {
  companyData: PreviousCompanyNames;
}
const PreviousCompanyNamesRow = ({ companyData }: Props) => {
  const { name, ceasedOn, effectiveFrom } = companyData;

  return (
    <>
      <TableRow dataCy="previous-company-names-table-row">
        <TableData>{name || NO_DATA}</TableData>
        <TableData>
          {`${getFormattedDate(effectiveFrom, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)} - 
          ${getFormattedDate(ceasedOn, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}
          ` || NO_DATA}
        </TableData>
      </TableRow>
    </>
  );
};

export default PreviousCompanyNamesRow;
