import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldValues, useFormContext, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BASE_FILTER_FIELDS } from 'constants/common';
import { FilterFieldsContainer, Button, Input } from 'shared-components/index';
import { cn } from 'utils/global';

interface Props {
  dataCy?: string;
  children?: ReactNode;
  rightTopActionButton?: ReactNode;
  handleClearFields?: VoidFunction;
  placeHolder: string;
  searchFieldName?: string;
  withFilter?: boolean;
  isSearchFieldName?: boolean;
  openFilter?: boolean;
  isLoading?: boolean;
  alwaysOpenFilters?: boolean;
}

const FilterForm = <T extends FieldValues>({
  children,
  dataCy,
  placeHolder,
  rightTopActionButton,
  handleClearFields,
  isSearchFieldName = true,
  searchFieldName,
  withFilter = true,
  isLoading,
  openFilter,
  alwaysOpenFilters,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const [isFiltersOpen, setIsFiltersOpen] = useState(openFilter || false);

  const onClickFiltersButton = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const { register } = useFormContext<T>();

  useEffect(() => {
    if (alwaysOpenFilters) {
      setIsFiltersOpen(true);
    }
  }, [alwaysOpenFilters]);

  return (
    <div data-cy={dataCy}>
      <div className="flex mb-4 justify-between">
        {isSearchFieldName && (
          <div className="flex">
            <Input
              isLoading={isLoading}
              {...register((searchFieldName || BASE_FILTER_FIELDS.SEARCH_FIELD) as Path<T>)}
              type="text"
              placeholder={placeHolder}
              inputClassName={cn(
                'w-96 h-10 text-sm dark:bg-darkBlue dark:placeholder:text-white dark:hover:border-gray2 dark:focus:ring-0 dark:focus:border-gray2',
                {
                  'rounded-e-none': !isFiltersOpen,
                }
              )}
            />
            {!isFiltersOpen && (
              <Button dataCy="submit-search-filter" type="submit" className="h-10 rounded-s-none">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            )}

            {withFilter && (
              <Button
                dataCy="filter-button"
                type="button"
                className="flex ml-4 h-10 items-center"
                onClick={onClickFiltersButton}
              >
                <FontAwesomeIcon icon={faFilter} />
                <div className="ml-4 ">{t('Filters')}</div>
              </Button>
            )}
          </div>
        )}

        {rightTopActionButton}
      </div>

      {handleClearFields && (
        <FilterFieldsContainer openFilter={openFilter} handleClearFields={handleClearFields} isOpen={isFiltersOpen}>
          {children}
        </FilterFieldsContainer>
      )}
    </div>
  );
};

export default FilterForm;
