import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import FooterForm from './FooterForm';

export interface Props {
  children: ReactNode;
  title: string;
  containerClassName?: string;
}

const AuthFormContainer: FC<Props> = ({ title, children, containerClassName }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-lightGray3 dark:bg-darkBlue4">
      <div className="shadow rounded-md dark:rounded-md">
        <div
          className={`w-[315px] sm:w-[435px] p-[17.5px] rounded-t-md bg-white dark:bg-darkBlue animate-padding ${containerClassName}`}
        >
          <h1 className="text-[32px] text-darkGray dark:text-white">{t(title)}</h1>
          {children}
        </div>
        <FooterForm />
      </div>
    </div>
  );
};

export default AuthFormContainer;
