import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useDashboardLayout } from 'hooks/useDashboardLayout';
import { useOpen } from 'hooks/useOpen';
import useUserRole from 'hooks/useUserRole';
import { DashboardPage } from 'interfaces/dashboard.interface';
import { WarningModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';
import { convertWidgetIdToTranslationKey } from 'utils/dashboard';

import { DashboardWidgetsLayout } from './DashboardWidgetsLayout';

export const DashboardWidgets = () => {
  const { t } = useTranslation();

  const { userRole, isAdmin } = useUserRole();

  const [isDeleteWidgetModal, handleOpenDeleteWidgetModal, handleCloseDeleteWidgetModal] = useOpen();

  const {
    page,
    layouts,
    dashboardLayout,
    widgetId,
    handlePageChange,
    handleLayoutChange,
    handleDeleteWidget,
    handleConfirmDelete,
  } = useDashboardLayout(handleOpenDeleteWidgetModal, handleCloseDeleteWidgetModal);

  const widgetTranslationKey = convertWidgetIdToTranslationKey(widgetId);
  const widgetDeleteMessage = t('WidgetDeleteWarningMessage', { 0: t(widgetTranslationKey), 1: page?.name });

  const tabs = useMemo(() => {
    return dashboardLayout?.pages.map(({ widgets, ...page }) => ({
      id: page.id,
      title: page.name,
      component: (
        <DashboardWidgetsLayout
          isAdmin={isAdmin}
          layouts={layouts}
          dashboardLayout={dashboardLayout}
          userRole={userRole}
          page={page}
          handleLayoutChange={handleLayoutChange}
          onDeleteWidget={handleDeleteWidget}
          widgets={widgets}
        />
      ),
    }));
  }, [dashboardLayout, isAdmin, layouts, userRole]);

  const onActiveTabChange = (_: number, id?: string) => {
    const selectedPage = dashboardLayout?.pages.find(({ id: pageId }) => pageId === id);

    if (selectedPage) {
      handlePageChange(selectedPage);
    }
  };

  return (
    <>
      <Tabs
        tabs={tabs ?? []}
        onActiveTabChange={onActiveTabChange}
        dynamicTabIndex={dashboardLayout?.pages.indexOf(page ?? ({} as DashboardPage))}
        hideOnSingleTab
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={widgetDeleteMessage}
        isOpen={isDeleteWidgetModal}
        handleClose={handleCloseDeleteWidgetModal}
        handleConfirm={handleConfirmDelete}
      />
    </>
  );
};
