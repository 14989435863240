import { Modal } from 'shared-components';

import UserChangePermissionsModalContent from './UserChangePermissionsModalContent';
interface Props {
  isOpen: boolean;
  handleClose: () => void;
  userId?: number;
  heading?: string;
  triggerRefetch: () => void;
}
const UserChangePermissionsModal = ({ isOpen, handleClose, userId, heading, triggerRefetch }: Props) => {
  return (
    <Modal heading={heading} isOpen={isOpen} onClose={handleClose}>
      <UserChangePermissionsModalContent triggerRefetch={triggerRefetch} userId={userId} handleClose={handleClose} />
    </Modal>
  );
};

export default UserChangePermissionsModal;
