import { FC } from 'react';

import { AddMemberTableItem } from 'interfaces/organization-units.interface';
import { Checkbox, TableData, TableRow } from 'shared-components';

interface Props {
  roleData: AddMemberTableItem;
  onToggleSelect: (id: number) => void;
  isSelected: boolean;
}

const AddRoleModalRow: FC<Props> = ({ roleData, onToggleSelect, isSelected }) => {
  const { name, value } = roleData;

  return (
    <>
      <TableRow dataCy="add-role-table-row">
        <TableData>
          <Checkbox
            dataCy="select-user-checkbox"
            checked={isSelected}
            onChange={() => onToggleSelect(parseInt(value))}
          />
        </TableData>
        <TableData dataCy="table-data-role-name">{name}</TableData>
      </TableRow>
    </>
  );
};

export default AddRoleModalRow;
