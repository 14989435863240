import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useGetSubscriptionQuery,
  useCreatePaymentMutation,
  useHasAnyPaymentMutation,
  useLazyGetInfoUpgradeSubscriptionQuery,
  useLazyGetLastCompletedQuery,
} from 'apis/subscriptions-tenant.api';
import { ButtonVariants } from 'constants/common';
import { ROUTES } from 'constants/routes';
import { SHARED_MODAL_BUTTON_STYLES } from 'constants/subscription-tenant';
import { useOpen } from 'hooks/useOpen';
import useSubscriptionInformation from 'hooks/useSubscriptionInformation';
import { CommonError } from 'interfaces/shared.interface';
import { CreatePaymentPayload } from 'interfaces/subscriptions-tenant.interface';
import TenantSubscriptionModal from 'page-components/subscriptions-tenant/modal/TenantSubscriptionModal';
import { Button, ErrorModal, LabelWithValue } from 'shared-components';
import { useFormatPriceFunction } from 'utils/global';
import { getSubscriptionStripePurchase } from 'utils/url';

const SubscriptionUpgrade = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const navigate = useNavigate();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);

  const searchParams = new URLSearchParams(location.search);
  const subscriptionId = searchParams.get('upgradeSubscriptionId');

  const {
    data: subscriptionData,
    isLoading: isGetSubscriptionQueryLoading,
    error: getSubscriptionQueryError,
  } = useGetSubscriptionQuery(`${subscriptionId}`);

  const { periodId } = useSubscriptionInformation();

  const [createPayment, { error: createPaymentError }] = useCreatePaymentMutation();

  const [hasAnyPayment, { isLoading: isHasAnyPaymentLoading, error: hasAnyPaymentError }] = useHasAnyPaymentMutation();

  const [getInfoUpgradeSubscription, { data: infoUpgradedData, error: infoUpgradeSubscriptionError }] =
    useLazyGetInfoUpgradeSubscriptionQuery();

  const [lastCompleted, { data: lastCompletedData, isLoading: isLastCompletedLoading, error: lastCompletedError }] =
    useLazyGetLastCompletedQuery();

  const hasAnyPaymentErrorData = (hasAnyPaymentError as CommonError)?.data || {};
  const infoUpgradeSubscriptionErrorData = (infoUpgradeSubscriptionError as CommonError)?.data || {};
  const createPaymentErrorData = (createPaymentError as CommonError)?.data || {};
  const getSubscriptionQueryErrorData = (getSubscriptionQueryError as CommonError)?.data || {};
  const lastCompletedErrorData = (lastCompletedError as CommonError)?.data || {};

  const errorArray = [
    hasAnyPaymentErrorData,
    lastCompletedErrorData,
    createPaymentErrorData,
    getSubscriptionQueryErrorData,
    infoUpgradeSubscriptionErrorData,
  ];

  const errorData = errorArray.find((error) => error.error);

  const isLoadingArray = [
    isGetSubscriptionQueryLoading,
    isLastCompletedLoading,
    isHasAnyPaymentLoading,
    infoUpgradedData,
    isLastCompletedLoading,
    lastCompletedData,
  ];

  const isLoadingEvent = isLoadingArray.some((loading) => loading === true);

  const subscriptionPlans = subscriptionData?.result?.subscriptionPlans || [];

  const findAndSetMatchingPlan = () => {
    if (!selectedPlanId) {
      const matchingPlan = subscriptionPlans.find((plan) => plan.periodId === periodId);
      setSelectedPlanId(matchingPlan?.id ?? null);
    }
  };

  const onSubmit = async () => {
    if (!selectedPlanId) {
      handleOpenErrorModal();
      return;
    }

    const payload: CreatePaymentPayload = {
      subscriptionPaymentGatewayType: 0,
      targetSubscriptionPlanId: selectedPlanId,
    };

    try {
      const response = await createPayment(payload).unwrap();
      const paymentId = response?.result;

      if (paymentId) {
        navigate(getSubscriptionStripePurchase(paymentId));
        return;
      }
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    hasAnyPayment().unwrap();
    getInfoUpgradeSubscription(`${subscriptionId}`).unwrap();
    lastCompleted().unwrap();
  }, [getInfoUpgradeSubscription, hasAnyPayment, lastCompleted, subscriptionId]);

  useEffect(() => {
    if (subscriptionPlans.length && periodId) {
      findAndSetMatchingPlan();
    }
  }, [subscriptionPlans, periodId]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="subscription-purchase-page" className="p-6">
        <TenantSubscriptionModal
          bodyClassName="text-left"
          wrapperClassName="pb-0 px-5"
          dataCy="payment-info-subscription-modal"
          isPaymentInfo
          headingClassName="text-left mb-0"
          heading="PaymentInfo"
          contentClassName="px-8 mb-0"
          isLoading={isLoadingEvent}
          onClose={() => navigate(ROUTES.tenantSubscription)}
          endChild={
            <div className={'dark:bg-darkBlue w-full p-6 rounded-b-md bg-white'}>
              <Button
                dataCy="checkout-button"
                isLoading={isLoadingEvent}
                className={SHARED_MODAL_BUTTON_STYLES}
                onClick={() => onSubmit()}
                variant={ButtonVariants.CONFIRM}
              >
                {t('CheckoutWithStripe')}
              </Button>
            </div>
          }
        >
          <p className="text-xl pb-3">{t('UpgradeEditionDescription', { 0: subscriptionData?.result.displayName })}</p>
          <LabelWithValue
            label="Total"
            value={useFormatPriceFunction(infoUpgradedData?.result.additionalPrice ?? 0)}
            dataCy="total"
            bodyClassName="place-content-between"
          />
        </TenantSubscriptionModal>
      </div>
    </>
  );
};

export default SubscriptionUpgrade;
