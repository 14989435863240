import { useGetCurrentLoginInformationsQuery } from 'apis/user.api';

const useSubscriptionInformation = () => {
  const { data: currentLoginInfoData, error, isLoading, refetch } = useGetCurrentLoginInformationsQuery();

  if (isLoading) {
    return { isLoading: true, isSubscription: false };
  }

  if (error) {
    console.error('Error fetching subscription information:', error);
    return { isLoading: false, isSubscription: false };
  }

  const tenantSubscriptionLoginData = currentLoginInfoData?.tenant?.subscription;

  const subscriptionId = tenantSubscriptionLoginData?.id;
  const subscriptionPlan = tenantSubscriptionLoginData?.displayName;
  const subscriptionStartDateUtc = tenantSubscriptionLoginData?.subscriptionStartDateUtc;
  const subscriptionEndDateUtc = tenantSubscriptionLoginData?.subscriptionEndDateUtc;
  const periodId = tenantSubscriptionLoginData?.periodId;
  const isSubscriptionDeactivated = tenantSubscriptionLoginData?.isSubscriptionDeactivated;
  const nextSubscriptionPaymentAmount = tenantSubscriptionLoginData?.nextSubscriptionPaymentAmount;
  const subscriptionAwaitingCancellation = tenantSubscriptionLoginData?.subscriptionAwaitingCancellation;
  const isInTrialPeriod = tenantSubscriptionLoginData?.isInTrialPeriod;
  const subscriptionPrice = tenantSubscriptionLoginData?.price;
  const tenantId = currentLoginInfoData?.tenant?.id;
  const displayName = tenantSubscriptionLoginData?.displayName;
  const trialDayCount = tenantSubscriptionLoginData?.trialDayCount;
  const isSubscription = !!tenantSubscriptionLoginData;
  const expiringSubscriptionName = tenantSubscriptionLoginData?.expiringSubscriptionName;

  return {
    isLoading: false,
    subscriptionId,
    subscriptionPlan,
    subscriptionStartDateUtc,
    subscriptionEndDateUtc,
    periodId,
    isSubscriptionDeactivated,
    nextSubscriptionPaymentAmount,
    subscriptionAwaitingCancellation,
    isInTrialPeriod,
    subscriptionPrice,
    tenantId,
    displayName,
    trialDayCount,
    isSubscription,
    expiringSubscriptionName,
    refetch,
  };
};

export default useSubscriptionInformation;
