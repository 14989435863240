import { TableVariants } from 'constants/table';
import { MemberActivity } from 'interfaces/member-activities.interface';
import { TableData, TableRow } from 'shared-components';

interface Props {
  activity: MemberActivity;
}

export const MemberActivityTableRow = ({ activity }: Props) => {
  const { name, earnings, cases, closed, rate } = activity;

  return (
    <TableRow variant={TableVariants.SECONDARY} dataCy="member-activity-table-row">
      <TableData>{name}</TableData>
      <TableData>{earnings}</TableData>
      <TableData>{cases}</TableData>
      <TableData>{closed}</TableData>
      <TableData>{rate}</TableData>
    </TableRow>
  );
};
