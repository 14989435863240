import { cn } from 'utils/global';

type Props = {
  isLoading?: boolean;
  isFixed?: boolean;
  className?: string;
  spinnerWrapper?: string;
};

const LoadingOverlay = ({ isLoading, isFixed, className, spinnerWrapper }: Props) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      data-cy="loading-overlay"
      className={cn(className, 'absolute w-full h-full z-30 flex justify-center items-center left-0 top-0', {
        'fixed left-0 top-0': isFixed,
      })}
    >
      <div className={cn(spinnerWrapper, 'bg-black opacity-40 w-full h-full absolute')} />
      <div className="loader w-20 h-20 opacity-60" />
    </div>
  );
};

export default LoadingOverlay;
