import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useGetResolvedTicketsQuery } from 'apis/support-tickets.api';
import { SUPPORT_TICKETS_TABLE_COLUMNS } from 'constants/support-tickets';
import { useFilters } from 'hooks/useFilters';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { BaseFilterValues } from 'interfaces/shared.interface';
import { FilterForm, LoadingOverlay, Pagination, Table } from 'shared-components';

import ResolvedTicketsRow from './ResolvedTicketsTabRow';

const ResolvedTicketsTab = () => {
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();
  const resolvedTicketsFormMethods = useForm<BaseFilterValues>();
  const { applyFilters, filterParams, isSameFilters } = useFilters();

  const { data, isLoading, isFetching, refetch } = useGetResolvedTicketsQuery(
    `${filterParams}${sortingParams}${paginationParams}`
  );

  const { result } = data || {};

  const [showResolverColumn] = useState(true);

  const onSubmitFilters = (data: BaseFilterValues) => {
    applyFilters(data);

    if (isSameFilters(applyFilters)) {
      refetch();
    } else {
      applyFilters(applyFilters);
    }
  };

  return (
    <div data-cy="resolved-tickets-tab" className="relative">
      <LoadingOverlay isLoading={isFetching && !!data} />

      <div>
        <FormProvider {...resolvedTicketsFormMethods}>
          <form onSubmit={resolvedTicketsFormMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm dataCy="resolved-tickets-input" withFilter={false} placeHolder="SearchWithThreeDot" />
          </form>
        </FormProvider>
      </div>

      <Table
        dataCy="resolved-tickets-table"
        columns={SUPPORT_TICKETS_TABLE_COLUMNS(showResolverColumn)}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        isTableEmpty={!result?.items.length}
        sortingType={sortingType}
        isLoading={isLoading}
      >
        {result?.items.map((item) => <ResolvedTicketsRow key={item.id} supportTicketsData={item} />)}
      </Table>

      <Pagination
        totalCount={result?.totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default ResolvedTicketsTab;
