import { FC } from 'react';

import { FieldError } from 'react-hook-form';
import ReactSelect, { Props as SelectProps, StylesConfig } from 'react-select';

import useTheme from 'hooks/useTheme';
import { FilterStateParams, OptionType } from 'interfaces/shared.interface';
import { ThemeConfig } from 'interfaces/user.interface';

const DEFAULT_OPTION = { value: '10', label: '10' };

const getBackgroundColor = (theme: ThemeConfig, state: FilterStateParams): string => {
  if (theme === ThemeConfig.dark && state.isSelected) {
    return '#3b82f6';
  }
  if (theme === ThemeConfig.dark) {
    return '#111827';
  }
  if (state.isSelected) {
    return 'rgba(59, 130, 246, 0.25)';
  }
  if (state.isFocused) {
    return 'rgba(123,136,159,0.25)';
  }
  return '';
};

const getHoverBackgroundColor = (theme: ThemeConfig, state: FilterStateParams): string => {
  if (theme === ThemeConfig.dark && state.isSelected) {
    return '#3b82f6';
  }
  if (theme === ThemeConfig.dark) {
    return '#334155';
  }
  if (state.isSelected) {
    return 'rgba(59, 130, 246, 0.25)';
  }
  return 'rgba(123,136,159,0.25)';
};

const getBorderColor = (theme: ThemeConfig, state: FilterStateParams, error?: FieldError): string => {
  if (error) {
    return '#e53e3e';
  }
  if (theme === ThemeConfig.dark) {
    return '#CED4DA';
  }
  if (state.isFocused) {
    return 'rgba(59, 130, 246, 0.5)';
  }
  return '#d1d5db';
};

const getHoverBorderColor = (theme: ThemeConfig, state: FilterStateParams): string => {
  if (theme === ThemeConfig.dark) {
    return '#CBD5E1';
  }
  if (state.isFocused) {
    return 'rgba(59, 130, 246, 0.5)';
  }
  return '#d1d5db';
};

const getOptionColor = (theme: ThemeConfig): string => {
  return theme === ThemeConfig.dark ? 'white' : 'black';
};

export const getBackgroundColorForControl = (theme: ThemeConfig, isFilter?: boolean): string => {
  if (theme === ThemeConfig.light) {
    return 'white';
  }
  if (theme === ThemeConfig.dark) {
    return isFilter ? 'transparent' : '#111827';
  }

  return 'transparent';
};

const getIndicatorsBackgroundColor = (isClearable?: boolean, isMulti?: boolean) => {
  if (!isClearable) {
    return '';
  }
  return isMulti ? '#2196f3' : '#1f2937';
};

export const getSelectStyles = (
  theme: ThemeConfig,
  isMulti?: boolean,
  error?: FieldError,
  isAsync?: boolean,
  isClearable?: boolean
): StylesConfig<OptionType> => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: '2.5rem',
    width: '100%',
    cursor: isAsync ? 'text' : 'pointer',
    fontSize: '0.875rem',
    borderStyle: 'solid',
    color: 'rgba(222,22,22,0.75)',
    boxShadow: state.isFocused ? '0 0 2px 3px rgba(56, 134, 259, 0.5)' : '',
    borderColor: isAsync ? 'transparent' : getBorderColor(theme, state, error),
    '&:hover': isAsync
      ? 'transparent'
      : {
          borderColor: getHoverBorderColor(theme, state),
        },
    backgroundColor: getBackgroundColorForControl(theme),
  }),

  option: (provided, state) => {
    const isDisabled = state.isDisabled;
    return {
      ...provided,
      fontSize: '0.875rem',
      backgroundColor: getBackgroundColor(theme, state),
      color: getOptionColor(theme),
      opacity: isDisabled ? '.65' : '1',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      '&:hover': {
        backgroundColor: isDisabled ? '' : getHoverBackgroundColor(theme, state),
      },
    };
  },
  menu: (provided) => ({
    ...provided,
    backgroundColor: theme === ThemeConfig.dark ? '#111827' : 'white',
    fontSize: '0.875rem',
    zIndex: 20,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme === ThemeConfig.dark || isMulti ? 'white' : 'rgba(50,51,52,0.25)',
    '&:hover': {
      color: theme === ThemeConfig.dark || isMulti ? 'white' : 'rgba(50,51,52,0.5)',
    },
    cursor: isAsync && !isMulti ? 'pointer' : '',
    display: isAsync && !isMulti ? 'none' : '',
    padding: '0 0.5rem',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: isClearable ? '0 10px' : '0',
    display: isAsync && isMulti ? 'none' : '',
    color: theme === ThemeConfig.dark || isMulti ? 'white' : 'rgba(50,51,52,0.25)',
    '&:hover': {
      color: theme === ThemeConfig.dark || isMulti ? 'white' : 'rgba(50,51,52,0.5)',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme === ThemeConfig.dark || isMulti ? 'white' : '#6C757D',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    backgroundColor: getIndicatorsBackgroundColor(isClearable, isMulti),
    '&:hover': {
      backgroundColor: '#0d89ec',
    },
    borderRadius: isMulti || isClearable ? '0 2px 2px 0' : '',
    cursor: 'pointer',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: theme === ThemeConfig.dark ? '#2196f3' : '#e3f2fd',
    cursor: 'default',
    borderRadius: '4px',
    alignItems: 'center',
    fontSize: '1rem',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: theme === ThemeConfig.dark ? 'white' : '#6C757D',
    fontSize: '100%',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme === ThemeConfig.dark ? '#2196f3' : '#e3f2fd',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme === ThemeConfig.dark ? 'white' : '#495057',
  }),
  input: (provided) => ({
    ...provided,
    color: theme === ThemeConfig.dark ? 'white' : 'black',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '100%',
    overflowY: 'unset',
    borderColor: isAsync ? '#CED4DA' : 'transparent',
    borderRadius: isAsync ? '0.125rem' : 'unset',
    borderBottomRightRadius: !isMulti && isAsync ? '0.125rem' : '0',
    borderTopRightRadius: !isMulti && isAsync ? '0.125rem' : '0',
    borderWidth: isAsync ? '1px' : '0',
    transitionDuration: '200ms',
    ':hover': {
      borderColor: isAsync && theme === ThemeConfig.dark ? '#6C757D' : '#3B82F6',
    },
  }),
});

const Select: FC<SelectProps<OptionType>> = ({ options, onChange, ...props }) => {
  const { theme } = useTheme();
  return (
    <ReactSelect
      id="react-select-component"
      styles={getSelectStyles(theme)}
      defaultValue={DEFAULT_OPTION}
      options={options}
      onChange={onChange}
      menuPlacement="top"
      maxMenuHeight={200}
      {...props}
    />
  );
};

export default Select;
