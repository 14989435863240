import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetConnectionQuickBooksDetailsQuery, usePostAuthenticationTokenConnectMutation } from 'apis/quickBooks.api';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import QuickBooksConnectionHandler from 'page-components/integrations/quickBooks/QuickBooksConnectionHandler';
import { ErrorModal, InfoModal, LoadingOverlay } from 'shared-components';
import { successNotify } from 'utils/notifications';

const QuickBooks = () => {
  const { t } = useTranslation();

  const [isReconnectionRequired, setIsReconnectionRequired] = useState<boolean>(false);

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [postAuthenticationTokenConnect, { isLoading: isLoadingPostFetch, error: postFetchError }] =
    usePostAuthenticationTokenConnectMutation();
  const {
    data: connectionData,
    isLoading: isLoadingGetConnection,
    isFetching,
    error: getConnectionError,
    refetch,
  } = useGetConnectionQuickBooksDetailsQuery();

  const isLoading = isLoadingPostFetch || isLoadingGetConnection;

  const navigate = useNavigate();

  const { data: postFetchErrorData } = (postFetchError as CommonError) || {};
  const { data: getConnectionErrorData } = (getConnectionError as CommonError) || {};

  const [params] = useSearchParams();
  const connectionCode = params.get('code');
  const companyId = params.get('realmId');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!companyId || !connectionCode) {
          return;
        }
        await postAuthenticationTokenConnect({ connectionCode, companyId }).unwrap();
        refetch();
        navigate(ROUTES.quickBooks);
        successNotify(t('SuccessfullyConnected'));
      } catch (error) {
        handleOpenErrorModal();
      }
    };

    if (connectionData?.result?.isConnectionExpired || isReconnectionRequired) {
      handleOpenInfoModal();
      return;
    }
    fetchData();
  }, [isReconnectionRequired]);

  return (
    <>
      <ErrorModal
        errorMessage={postFetchErrorData?.error?.message || getConnectionErrorData?.error?.message}
        description={postFetchErrorData?.error?.details || getConnectionErrorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <InfoModal
        infoMessage={'ConnectionExpired'}
        infoDescription={'QuickBooksConnectionExpiredDescription'}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
      />
      <div className="relative" data-cy="quickbooks-page">
        <LoadingOverlay isLoading={isFetching && !!connectionData} />
        {!isLoading && !isFetching ? (
          <QuickBooksConnectionHandler
            setIsReconnectionRequired={(isReconnectionRequired) => setIsReconnectionRequired(isReconnectionRequired)}
            isConnect={connectionData?.result.isConnected || false}
            connectionData={connectionData}
            triggerRefetch={refetch}
            isLoading={isFetching}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default QuickBooks;
