import { Modal } from 'shared-components';

import UserSettingsModalContent from './UserSettingsModalContent';
interface Props {
  handleClose: VoidFunction;
  isOpen: boolean;
  refetchListUsers: VoidFunction;
  userId?: number;
  heading?: string;
}
const UserSettingsModal = ({ userId, handleClose, isOpen, refetchListUsers, heading }: Props) => {
  return (
    <Modal dataCy="user-settings-modal" isOpen={isOpen} onClose={handleClose} heading={heading}>
      <UserSettingsModalContent handleClose={handleClose} refetchListUsers={refetchListUsers} userId={userId} />
    </Modal>
  );
};

export default UserSettingsModal;
