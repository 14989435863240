import { FC, ReactNode, useRef, useState } from 'react';

import { ReactComponent as ArrowDownSvg } from 'assets/svg/arrow-down.svg';
import { ReactComponent as BurgerSvg } from 'assets/svg/burger.svg';
import useOutsideClick from 'hooks/useOutsideClick';
import { UserProfilePictureResponse } from 'interfaces/user.interface';
import { joinMimeTypeAndDataToBase64 } from 'utils/files';
import { cn } from 'utils/global';

import Avatar from './Avatar';
import Banner from './Banner';
import Button from './button/Button';

interface Props {
  onClick: VoidFunction;
  children?: ReactNode;
  isSidebarVisible: boolean;
  isProfilePictureLoading: boolean;
  pictureData?: UserProfilePictureResponse;
}

const Header: FC<Props> = ({ onClick, children, isSidebarVisible, pictureData, isProfilePictureLoading }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const modalRef = useRef(null);

  const { profilePicture, mimeType } = pictureData?.result || {};
  const profileAvatar = joinMimeTypeAndDataToBase64({ data: profilePicture, mimeType });

  const toggleModal = () => {
    if (!isModalVisible) {
      setIsModalVisible(true);
    }
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  useOutsideClick(modalRef, handleClose);

  return (
    <div className="sticky z-20 w-full">
      <Banner />

      <div
        className={cn('flex items-center justify-between bg-darkBlue10 h-[3.125rem] duration-200', {
          'ml-0': isSidebarVisible,
          'ml-0 duration-0': !isSidebarVisible,
        })}
      >
        <Button
          dataCy="open-sidebar"
          type="button"
          onClick={onClick}
          className="hover:bg-inherit bg-inherit dark:hover:bg-inherit dark:bg-inherit"
        >
          <BurgerSvg className="w-4 ml-2 cursor-pointer focus:outline-none fill-gray3 dark:fill-lightBlue" />
        </Button>
        <div data-cy="settings-dropdown" onClick={toggleModal} className="flex items-center mr-5 cursor-pointer">
          <Avatar imagePath={profileAvatar} isLoading={isProfilePictureLoading} className="mr-2" />
          <ArrowDownSvg className="fill-gray3 dark:fill-lightBlue" />
          <div ref={modalRef}>{isModalVisible && children}</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
