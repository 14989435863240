import { useEffect } from 'react';

import { useGetQuickBooksInvoicesQuery } from 'apis/quickBooks.api';
import { INVOICES_TABLE_COLUMNS } from 'constants/quickBooks';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, LoadingOverlay, Pagination, Table } from 'shared-components';

import InvoicesRow from './InvoicesRow';
import QuickBooksInvoicesSearch from './QuickBooksInvoiceSearch';

interface Props {
  setIsReconnectionRequired: (isReconnectionRequired: boolean) => void;
}

const Invoices = ({ setIsReconnectionRequired }: Props) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const { filterParams, applyFilters, isSameFilters } = useFilters();
  const { handleSetTabName, customSortingParams, sortingColumnId, sortingType } = useTableSorting();
  const { quickBookPaginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();

  const { data, isLoading, isFetching, refetch, error } = useGetQuickBooksInvoicesQuery(
    `${filterParams}${customSortingParams}${quickBookPaginationParams}`
  );

  const response = data?.result?.data?.invoices;
  const { error: errorData } = (error as CommonError)?.data || {};
  const { result } = data || {};

  setIsReconnectionRequired(data?.result.isReconnectionRequired || false);

  const onFetchInvoices = async (customerId: string | null) => {
    const searchParams = customerId ? { customerId } : {};
    applyFilters(searchParams);

    if (isSameFilters(applyFilters)) {
      refetch();
    } else {
      applyFilters(applyFilters);
    }
  };

  useEffect(() => {
    if (error) {
      handleOpenErrorModal();
    }
  }, [error, handleOpenErrorModal]);

  return (
    <div data-cy="invoices-tab">
      <ErrorModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />

      <div className="relative">
        <LoadingOverlay isLoading={isFetching && !!data} />

        <QuickBooksInvoicesSearch onSearchApply={onFetchInvoices} />

        <Table
          dataCy="invoices-table"
          columns={INVOICES_TABLE_COLUMNS}
          isTableEmpty={!result?.data?.totalCount}
          isLoading={isLoading}
          sortingColumnId={sortingColumnId}
          sortingType={sortingType}
          handleSetTabName={handleSetTabName}
        >
          {response?.map((item) => <InvoicesRow key={item.id} invoicesData={item} />)}
        </Table>
        <Pagination
          totalCount={result?.data?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default Invoices;
