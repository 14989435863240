import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { BoxVariants, BoxVariantsStyles } from 'constants/common';
import { cn } from 'utils/global';

interface Props {
  dataCy?: string;
  children: ReactNode;
  customHeader?: ReactNode;
  customSubHeader?: ReactNode;
  className?: string;
  headerClassName?: string;
  headerWrapperClassName?: string;
  titleClassName?: string;
  variant?: BoxVariants;
  title?: string;
}

const Box = ({
  dataCy,
  children,
  className,
  headerClassName,
  customSubHeader,
  headerWrapperClassName,
  titleClassName,
  title,
  customHeader,
  variant = BoxVariants.MAIN,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div data-cy={dataCy} className={cn('p-3.5 rounded-[3px]', BoxVariantsStyles[variant], className)}>
      <div className={cn('flex items-center justify-between', headerClassName)}>
        <div className={cn('flex', headerWrapperClassName)}>
          {title && <h3 className={cn('text-2xl font-medium mb-3', titleClassName)}>{t(title)}</h3>}
          {customSubHeader}
        </div>
        {customHeader}
      </div>
      {children}
    </div>
  );
};

export default Box;
