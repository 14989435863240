import { BannerProps } from 'interfaces/subscriptions-tenant.interface';
import SubscriptionLink from 'page-components/subscriptions-tenant/banner/SubscriptionLink';

import BuildMessageWithLinks from './BannerMessageBuilder';

const SubscriptionExpiredBanner = ({
  isSubscriptionManagementPermission,
  expiringSubscriptionName,
  loading,
  daysAmount,
}: BannerProps) => {
  const buildStandardExpirationMessage = () => {
    return (
      <BuildMessageWithLinks
        messageKey="TenantWillBeDeactivatedAfterSubscriptionExpiresNotification"
        values={{ 0: `${daysAmount}` }}
        splits={[{ index: 1, component: <SubscriptionLink isPurchaseLink={false} /> }]}
      />
    );
  };

  const buildSubscriptionExpireNotification = () => {
    return (
      <BuildMessageWithLinks
        messageKey="SubscriptionExpireNotification"
        values={{
          0: `${daysAmount}`,
          1: `${expiringSubscriptionName}`,
        }}
        splits={[{ index: 2, component: <SubscriptionLink isPurchaseLink={false} /> }]}
      />
    );
  };

  const buildSubscriptionExpireNotificationForUser = () => {
    return (
      <BuildMessageWithLinks
        messageKey="SubscriptionExpireNotificationForUser"
        values={{ 0: `${daysAmount}` }}
        splits={[]}
      />
    );
  };

  const handleAwaitingCancellation = () => {
    if (isSubscriptionManagementPermission) {
      if (expiringSubscriptionName) {
        return buildSubscriptionExpireNotification();
      } else {
        return buildStandardExpirationMessage();
      }
    }
    return buildSubscriptionExpireNotificationForUser();
  };

  const buildMessage = () => {
    return handleAwaitingCancellation();
  };

  const message = buildMessage();

  return !loading && message ? (
    <div data-cy="banner" className="w-full block text-center py-2 bg-blue9 text-white font-bold">
      {message}
    </div>
  ) : null;
};

export default SubscriptionExpiredBanner;
