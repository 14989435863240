import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';

import App from './App';

import 'react-tooltip/dist/react-tooltip.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
