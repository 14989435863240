import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';

import { getFormattedDate } from './dates';

export const getFormattedChartDate = (date: string) => {
  if (date.includes('W')) {
    return date;
  }

  return getFormattedDate(date, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES);
};
