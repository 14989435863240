import { useCallback, useState } from 'react';

import { getAppliedFilters } from 'utils/string';

export const useFilters = (defaultFilters?: unknown) => {
  const [filterParams, setFilterParams] = useState(defaultFilters ? getAppliedFilters(defaultFilters) : '');

  const applyFilters = useCallback((filters: unknown) => {
    setFilterParams(getAppliedFilters(filters));
  }, []);

  const isSameFilters = (filters: unknown) => getAppliedFilters(filters) === filterParams;

  return {
    applyFilters,
    filterParams,
    isSameFilters,
  };
};
