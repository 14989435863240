import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants/routes';

const SignUpCheckboxLabel = () => {
  const { t } = useTranslation();

  return (
    <div className="text-sm dark:text-white">
      {t('IAgreeToThe')}
      <Link to={ROUTES.termsAndConditions} target="_blank" className="text-blue2 font-light hover:text-darkBlue5">
        {' '}
        {t('TermsAndConditions')}{' '}
      </Link>
      {t('And')}
      <Link to={ROUTES.privacyPolicy} target="_blank" className="text-blue2 font-light hover:text-darkBlue5">
        {' '}
        {t('PrivacyPolicy')}
      </Link>
    </div>
  );
};

export default SignUpCheckboxLabel;
