import { AccountsData, BalanceData, CardsData } from 'interfaces/integrations/truelayer.interface';
import { Balance, Information, Transactions } from 'page-components/integrations/truelayer/details';
import { Box } from 'shared-components';

type Props = {
  isLoading: boolean;
  informationData?: AccountsData | CardsData;
  balanceData?: BalanceData;
  accountId?: string;
  cardId?: string;
  informationTitle: string;
  balanceTitle: string;
  transactionsTitle: string;
};

const TrueLayerDetailsLayout = ({
  isLoading,
  informationData,
  balanceData,
  accountId,
  cardId,
  informationTitle,
  balanceTitle,
  transactionsTitle,
}: Props) => {
  return (
    <div data-cy="details-tab">
      <Box>
        <div className="2xl:flex justify-between md:block">
          <Information isLoading={isLoading} data={informationData} accountId={accountId} title={informationTitle} />
          <Balance isLoading={isLoading} data={balanceData} accountId={accountId} title={balanceTitle} />
        </div>
        <Transactions accountId={accountId} cardId={cardId} title={transactionsTitle} />
      </Box>
    </div>
  );
};

export default TrueLayerDetailsLayout;
