import { FC, useEffect, useState } from 'react';

import generatePicker from 'antd/es/date-picker/generatePicker';
import { parseISO, isValid, format, parse, isDate } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DAY_FORMAT_WITH_TIME,
  DAY_FORMAT_WITH_TIME_WITH_SLASHES,
  DAY_MONTH_YEAR_FORMAT_WITH_SLASHES,
} from 'constants/global';
import { OptionTypeAdvancedFilter } from 'interfaces/integrations/os-audit-logs.interface';
import { cn } from 'utils/global';

const DatePicker = generatePicker(dateFnsGenerateConfig);

export interface Props {
  control: Control;
  onInputChange: (optionValue: string, value: string) => void;
  inputValues: { [key: string]: string };
  data: OptionTypeAdvancedFilter;
  isDateAndTimePickerOpen: boolean;
  setIsDateAndTimePickerOpen: (isOpen: boolean) => void;
  className?: string;
  dataCy: string;
}

const DateTimePicker: FC<Props> = ({
  control,
  onInputChange,
  data,
  inputValues,
  isDateAndTimePickerOpen,
  setIsDateAndTimePickerOpen,
  className,
  dataCy,
}) => {
  const { t } = useTranslation();
  const selectedOption = data.value as string;
  const value = inputValues[selectedOption] || '';

  const [internalValue, setInternalValue] = useState<Date | null>(isValid(parseISO(value)) ? parseISO(value) : null);

  useEffect(() => {
    setInternalValue(isValid(parseISO(value)) ? parseISO(value) : null);
  }, [value]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateFormatted = format(date, DAY_FORMAT_WITH_TIME_WITH_SLASHES);
      onInputChange(selectedOption, dateFormatted);
    } else {
      onInputChange(selectedOption, '');
    }
    setIsDateAndTimePickerOpen(false);
  };

  const handleManualInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const parsedDate = parse(inputValue, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES, new Date());
      if (isDate(parsedDate) && isValid(parsedDate)) {
        const dateWithTime = format(parsedDate, "yyyy-MM-dd'T'00:00:00");
        onInputChange(selectedOption, dateWithTime);
        setInternalValue(parsedDate);
      }
    }
  };

  return (
    <Controller
      name={selectedOption}
      control={control}
      render={() => (
        <DatePicker
          data-cy={dataCy}
          value={internalValue}
          onChange={handleDateChange}
          showTime
          format={DAY_FORMAT_WITH_TIME}
          placeholder={t('SelectDateAndTime')}
          className={cn(
            className,
            'py-[7px] px-2.5 rounded !text-darkGray date-picker dark:bg-darkBlue4 dark:hover:bg-darkBlue4'
          )}
          open={isDateAndTimePickerOpen}
          onOpenChange={(open) => setIsDateAndTimePickerOpen(open)}
          onKeyDown={handleManualInput as any}
          inputReadOnly={false}
        />
      )}
    />
  );
};

export default DateTimePicker;
