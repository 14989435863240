import {
  GetConnectionDetailsResponse,
  GetPayPalOrdersResponse,
  PayPalCapturePayload,
  PayPalRepaymentPayload,
  PostCreateOrderResponce,
  StartOnboardingResponse,
} from 'interfaces/integrations/paypal.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const payPalApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['Orders'] }).injectEndpoints({
  endpoints: (build) => ({
    postStartOnboarding: build.mutation<StartOnboardingResponse, void>({
      query: () => ({
        url: `PayPal/StartOnboarding`,
        method: 'POST',
      }),
    }),
    getConnectionPayPalDetails: build.query<GetConnectionDetailsResponse, void>({
      query: () => ({
        url: `PayPal/GetConnectionDetails`,
        method: 'GET',
      }),
    }),
    postConnect: build.mutation<CommonResponse, { merchantId: string }>({
      query: (data) => ({
        url: `PayPal/Connect`,
        method: 'POST',
        data,
      }),
    }),
    getPayPalOrders: build.query<GetPayPalOrdersResponse, string>({
      query: (params) => ({
        url: `PayPal/GetOrders?${params}`,
        method: 'GET',
      }),
      providesTags: ['Orders'],
    }),
    postDisconnectPayPalAccount: build.mutation<CommonResponse, void>({
      query: () => ({
        url: `PayPal/Disconnect`,
        method: 'POST',
      }),
    }),
    postCreateOrder: build.mutation<PostCreateOrderResponce, PayPalRepaymentPayload>({
      query: (data) => ({
        url: `PayPal/CreateOrder`,
        method: 'POST',
        data,
      }),
    }),
    postCapturePayment: build.mutation<CommonResponse, PayPalCapturePayload>({
      query: (data) => ({
        url: `PayPal/CapturePayment`,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Orders']),
    }),
  }),
  overrideExisting: false,
});

export const {
  usePostStartOnboardingMutation,
  useGetConnectionPayPalDetailsQuery,
  usePostConnectMutation,
  useGetPayPalOrdersQuery,
  usePostDisconnectPayPalAccountMutation,
  usePostCreateOrderMutation,
  usePostCapturePaymentMutation,
} = payPalApi;
