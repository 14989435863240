import { useContext } from 'react';

import SupportRequestChatContext from 'contexts/SupportRequestChatContext';

export const useChatContext = () => {
  const context = useContext(SupportRequestChatContext);

  if (context === undefined) {
    throw new Error('SupportRequestChatContext must be used within a SupportRequestChatContext');
  }

  return context;
};
