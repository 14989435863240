import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useOpen } from 'hooks/useOpen';
import { DashboardWidget } from 'interfaces/dashboard.interface';
import { Button } from 'shared-components';

import { AddWidgetModal } from './AddWidgetModal';

interface Props {
  widgets: DashboardWidget[];
  pageId: string;
}

export const AddWidget = ({ widgets, pageId }: Props) => {
  const [isOpenAddWidgetModal, handleOnOpenAddWidgetModal, handleOnCloseAddWidgetModal] = useOpen();

  const { t } = useTranslation();

  return (
    <>
      <Button className="flex gap-1 items-center" onClick={handleOnOpenAddWidgetModal} data-cy="add-widget-button">
        <FontAwesomeIcon icon={faPlus} />
        {t('AddWidget')}
      </Button>
      <AddWidgetModal
        widgets={widgets}
        pageId={pageId}
        handleClose={handleOnCloseAddWidgetModal}
        isOpen={isOpenAddWidgetModal}
      />
    </>
  );
};
