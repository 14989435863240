import { CommonResponse } from './shared.interface';

export enum TWO_FACTOR_AUTH_FORM_FIELDS {
  twoFactorAuthenticationCode = 'twoFactorAuthenticationCode',
}
export enum TWO_FACTOR_AUTHENTICATION_TYPES {
  email = 'email',
  authenticator = 'authenticator',
  sms = 'sms',
}

export interface TwoFactorAuthResponse extends CommonResponse {
  result: {
    manualEntryKey: string;
    qrCodeSetupImageUrl: string;
  };
}

export interface TwoFactorAuthValues {
  [TWO_FACTOR_AUTH_FORM_FIELDS.twoFactorAuthenticationCode]: string | number;
}

export interface SendAuthCodePayload {
  userId: number;
  twoFactorAuthenticationType: number;
}
