import { ChangeEventHandler, ElementType, FunctionComponent, ReactNode } from 'react';

import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { RegisterOptions } from 'react-hook-form';

import { BASE_FILTER_FIELDS, BASE_TEXT_AREA_FIELDS } from 'constants/common';

export enum FieldTypes {
  TEXT = 'text',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  NUMBER = 'number',
  DATE = 'date',
}

export interface FormItem {
  name: string;
  label?: FunctionComponent<any> | ReactNode | string;
  pureName?: string;
  type?: FieldTypes;
  inputClassName?: string;
  labelClassName?: string;
  errorClassName?: string;
  required?: boolean;
  validation?: RegisterOptions;
  placeholder?: string;
  className?: string;
  component: FunctionComponent<any>;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
  dataCy?: string;
  options?: OptionType[];
  disabled?: boolean;
  defaultValue?: OptionType | string;
  isClearable?: boolean;
  isSearchable?: boolean;
  icon?: IconDefinition;
  maxLength?: number;
  checked?: boolean;
  tooltipContent?: string;
  requiredPlaceholder?: boolean;
  style?: object;
  checkboxLabel?: string;
  fixedDecimalLength?: number | null;
  minValue?: number | string;
  maxValue?: number | string;
  numberOfCharacters?: number;
  textAreaClassName?: string;
  isShownArrowButtons?: boolean;
  allowNegativeValue?: boolean;
  decimalSeparator?: string;
  isLoading?: boolean;
  isFilter?: boolean;
  placeHolder?: string;
  onFilterChange?: () => void;
  groupSeparator?: string;
}

export interface TabItem {
  id: string;
  title: string;
  component?: ElementType | ReactNode;
  dataCy?: string;
}

export interface ErrorInfo {
  code: boolean;
  details: string;
  message: string;
  validationErrors: null;
}

export interface CommonResponse {
  targetUrl: null;
  success: boolean;
  error: ErrorInfo | null;
  unAuthorizedRequest: boolean;
  __abp: boolean;
}

export interface CommonError {
  data: CommonResponse;
  status: number;
}

export interface TableColumn {
  columnId: string;
  title?: string;
  width?: string;
  className?: string;
  component?: ElementType;
  sorting?: boolean;
  dataCy?: string;
  hidden?: boolean;
  required?: boolean;
}

export interface OptionType {
  label: string;
  value: any;
  isDisabled?: boolean;
}

export interface OptionsTypeValue {
  value: string;
  label: string;
}

export interface BaseFilterValues {
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: string;
}

export interface DropdownMenuItems {
  name: string;
  actionOnClick: VoidFunction;
  dataCy?: string;
  handleConfirm?: VoidFunction;
  hidden?: boolean;
  description?: string;
  disabled?: boolean;
  icon?: IconProp;
  iconClassName?: string;
  className?: string;
}

export interface RadioOption {
  id: string;
  label: string;
  value: string;
  checked?: boolean;
  dataCy?: string;
}

export enum BASE_RADIO_BUTTON_RADIO_VALUES {
  YES = 'yes',
  NO = 'no',
}

export interface KeyWithString {
  [key: string]: string;
}

export interface KeyWithStringDictionary<T> {
  [key: string]: T;
}
export interface GetListSharedPayload {
  params: string;
  isAdmin: boolean;
}

export interface FilterStateParams {
  isSelected?: boolean;
  isFocused?: boolean;
}

export enum BASE_WYSIWYG_FIELD_NAMES {
  TEXT_EDITOR = 'textEditor',
}

export interface BaseTextAreaValue {
  [BASE_TEXT_AREA_FIELDS.TEXT_AREA_FIELD]: string;
}

export interface BaseWysiwygValues {
  [BASE_WYSIWYG_FIELD_NAMES.TEXT_EDITOR]: string;
}

export interface UploadFilePayload {
  fileBase64String: string;
  fileName: string;
}

export interface DownloadFileResponse extends CommonResponse {
  result: {
    fileName: string;
    content: Blob;
  };
}

export interface KeyNameData {
  key?: string;
  name?: string;
}

export enum SHARED_START_END_DATE_FILTERS_FIELD_NAMES {
  START_DATE = 'StartDate',
  END_DATE = 'EndDate',
}

export enum SHARED_SEARCH_FILTERS_FIELD_NAME {
  SEARCH_FIELD = 'filter',
}

export interface FileData {
  fileUrl: string | null;
  fileBase64: string;
  fileName: string;
}

export interface AdvancedFilterOptionType {
  label: string;
  value: string;
  type: string;
  uuid?: string;
}

export interface NameValueResult {
  name: string;
  value: string;
}

export interface DateRangeQueries {
  startDate: string;
  endDate: string;
}
