import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ButtonVariants } from 'constants/common';
import DropdownMenu from 'shared-components/DropdownMenu';

interface MenuItemAction {
  dataCy: string;
  name: string;
  actionOnClick: () => void;
}

interface Props {
  menuItemsAction: MenuItemAction[];
  hidden?: boolean;
  dropdownClassName?: string;
}

const DropdownMenuButton = ({ menuItemsAction, hidden, dropdownClassName }: Props) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu
      visibleDropdownClassName="!right-auto"
      className={dropdownClassName}
      buttonVariant={ButtonVariants.MAIN}
      elements={menuItemsAction}
      hidden={hidden}
    >
      <div className="flex justify-between">
        <div>{t('Actions')}</div>
        <FontAwesomeIcon className="mt-0.5 ml-2" icon={faChevronDown} />
      </div>
    </DropdownMenu>
  );
};

export default DropdownMenuButton;
