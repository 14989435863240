import { forwardRef, InputHTMLAttributes, ReactElement, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { RadioOption } from 'interfaces/shared.interface';
import { cn } from 'utils/global';

import ErrorMessage from '../ErrorMessage';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  radioOptions: RadioOption[];
  radioLegend: string | FC;
  fieldSetClassName?: string;
  legendClassName?: string;
  errorClassName?: string;
  error?: string;
}

const RadioInputSet = forwardRef<HTMLInputElement, Props>(
  (
    {
      radioLegend,
      fieldSetClassName,
      errorClassName,
      legendClassName,
      radioOptions,
      error,
      className,
      disabled,
      ...inputProps
    },
    ref
  ) => {
    const { t } = useTranslation();
    const renderLegend = (): ReactElement | null => {
      if (!radioLegend) {
        return null;
      }

      if (typeof radioLegend === 'function') {
        const Component = radioLegend;
        return <Component />;
      }

      return <legend className={cn('mb-2 text-sm font-normal', legendClassName)}>{t(radioLegend)}</legend>;
    };

    return (
      <fieldset className={cn('mb-2', fieldSetClassName)}>
        {renderLegend()}

        {radioOptions.map(({ value, label, id, checked, dataCy }) => (
          <div className="inline-flex items-center mr-4" key={id}>
            <input
              id={`${inputProps.name}-${id}`}
              ref={ref}
              className={cn(
                'radio-input-unset-dimensions w-5 h-5',
                { 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled, 'radio-input-highlighted': !!error },
                className
              )}
              type="radio"
              value={value}
              defaultChecked={checked}
              data-cy={dataCy}
              disabled={disabled}
              {...inputProps}
            />

            {label && (
              <label htmlFor={`${inputProps.name}-${id}`} className={cn('pl-2 text-sm')}>
                {t(label)}
              </label>
            )}
          </div>
        ))}

        {error && (
          <ErrorMessage className={errorClassName} dataCy="error-message">
            {t(error)}
          </ErrorMessage>
        )}
      </fieldset>
    );
  }
);

export default RadioInputSet;

RadioInputSet.displayName = 'RadioInputSet';
